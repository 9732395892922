import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setRequestError } from "redux/system/system.actions";
import {
    addItem,
    editItem,
    deleteItem,
    adjustItemStockQty,
    updatePrescriptionNote
} from "redux/items/item.actions";

import { Tabs, Button, Table, Icon, Input, message, Modal } from "antd";

import Highlighter from "react-highlight-words";
import ContentLoader from "globalComponents/ContentLoader";
import ActionMenu from "globalComponents/ActionMenu";
import Drawer from "globalComponents/Drawer";
import ModalDetails from "globalComponents/ModalDetails";
import ItemForm from "./components/Forms/ItemForm";
import ServiceForm from "./components/Forms/ServiceForm";
import StockQtyAdjustForm from "./components/Forms/StockQtyAdjustForm";
import PrescriptionNoteForm from "./components/Forms/PrescriptionNoteForm";
import ItemDetailsModalContent from "./components/ItemDetailsModalContent";

import { b64ImgToFileHelper } from "helpers/image";
import { generateString } from "helpers/randomStringGenerator";
import { formatNumber } from "helpers/numberFormatter";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Items = ({
    breakpoint,
    items,
    clinicID,
    ownerID,
    uploadServer,
    uploadEndpoint,
    plan,
    setRequestError,
    addItem,
    editItem,
    adjustItemStockQty,
    updatePrescriptionNote,
    deleteItem
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [isItemsTableLoading, setIsItemsTableLoading] = useState(false);

    const [isItemFormVisible, setIsItemFormVisible] = useState(false);
    const [isServiceFormVisible, setIsServiceFormVisible] = useState(false);
    const [isAdjustStockQtyFormVisible, setIsAdjustStockQtyFormVisible] =
        useState(false);
    const [isPrescriptionNoteFormVisible, setIsPrescriptionNoteFormVisible] =
        useState(false);
    const [isItemDetailsVisible, setIsItemDetailsVisible] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    const [itemUnits, setItemUnits] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);

    // antd default search table default
    const [searchText, setSearchText] = useState("");
    let searchInput = React.createRef();
    // end of antd default search table default

    const { TabPane } = Tabs;
    const { confirm } = Modal;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const itemUnitResponse = await http.get(`${apiUrl}/itemUnits`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                });
                setItemUnits(itemUnitResponse.data.itemUnits);
                const itemGroupResponse = await http.get(
                    `${apiUrl}/itemGroups`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                setItemGroups(itemGroupResponse.data.itemGroups);
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );
        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const toggleItemFormVisible = () => {
        setIsItemFormVisible(!isItemFormVisible);
    };

    const toggleServiceFormVisible = () => {
        setIsServiceFormVisible(!isServiceFormVisible);
    };

    const togglePrescriptionNoteFormVisible = () => {
        setIsPrescriptionNoteFormVisible(!isPrescriptionNoteFormVisible);
    };

    const toggleAdjustStockQtyFormVisible = () => {
        setIsAdjustStockQtyFormVisible(!isAdjustStockQtyFormVisible);
    };

    const toggleItemDetailsVisible = () => {
        setIsItemDetailsVisible(!isItemDetailsVisible);
    };

    const viewItemDetails = selectedRecord => {
        setSelectedItem(selectedRecord);
        toggleItemDetailsVisible();
    };

    const showItemForm = (selectedRecord = null) => {
        setSelectedItem(selectedRecord);
        toggleItemFormVisible();
    };

    const showServiceForm = (selectedRecord = null) => {
        setSelectedService(selectedRecord);
        toggleServiceFormVisible();
    };

    const showAdjustStockQtyForm = selectedRecord => {
        setSelectedItem(selectedRecord);
        toggleAdjustStockQtyFormVisible();
    };

    const showPrescriptionNoteForm = selectedRecord => {
        setSelectedItem(selectedRecord);
        togglePrescriptionNoteFormVisible();
    };

    const handleItemUnitSubmit = data => {
        switch (data.operation) {
            case "add":
                setItemUnits([...itemUnits, data.newItem]);
                break;
            case "edit":
                const newItemUnits = itemUnits.map(itemUnit => {
                    if (itemUnit.item_unit_id === data.itemID) {
                        return { ...itemUnit, name: data.name };
                    }
                    return itemUnit;
                });
                setItemUnits(newItemUnits);
                break;
            case "delete":
                setItemUnits([
                    ...itemUnits.filter(
                        itemUnit => itemUnit.item_unit_id !== data.itemID
                    )
                ]);
                break;
            default:
                return;
        }
    };

    const handleItemGroupSubmit = data => {
        switch (data.operation) {
            case "add":
                setItemGroups([...itemGroups, data.newItem]);
                break;
            case "edit":
                const newItemGroups = itemGroups.map(itemGroup => {
                    if (itemGroup.item_group_id === data.itemID) {
                        return { ...itemGroup, name: data.name };
                    }
                    return itemGroup;
                });
                setItemGroups(newItemGroups);
                break;
            case "delete":
                setItemGroups([
                    ...itemGroups.filter(
                        itemGroup => itemGroup.item_group_id !== data.itemID
                    )
                ]);
                break;
            default:
                return;
        }
    };

    const handleItemFormSubmit = async formData => {
        try {
            let newFormData = { ...formData };
            let itemImg = newFormData.itemImg;
            let itemImgSize = newFormData.img_size;
            const isItemImgChanged = newFormData.isItemImgChanged;

            delete newFormData.itemImg;
            delete newFormData.isItemImgChanged;

            toggleItemFormVisible();

            //upload only if there's a selected image
            if (isItemImgChanged) {
                setIsItemsTableLoading(true);
                //create filename
                const itemOrigSizeImgFilename = Date.now() + ".jpeg";
                const itemCardSizeImgFilename = itemOrigSizeImgFilename.replace(
                    ".jpeg",
                    "-card.jpeg"
                );
                const itemThumbnailImgFilename =
                    itemOrigSizeImgFilename.replace(".jpeg", "-thumb.jpeg");

                //convert b64 img to file
                const itemOrigSizeImgFile = b64ImgToFileHelper(
                    itemImg.origSizeImg,
                    itemOrigSizeImgFilename
                );
                const itemCardSizeImgFile = b64ImgToFileHelper(
                    itemImg.cardSizeImg,
                    itemCardSizeImgFilename
                );
                const itemThumbnailSizeImgFile = b64ImgToFileHelper(
                    itemImg.thumbnailSizeImg,
                    itemThumbnailImgFilename
                );

                //get file size
                const itemOrigSizeImgFileSize = itemOrigSizeImgFile.size;
                const itemCardSizeImgFileSize = itemCardSizeImgFile.size;
                const itemThumbnailSizeImgFileSize =
                    itemThumbnailSizeImgFile.size;

                itemImgSize =
                    itemOrigSizeImgFileSize +
                    itemCardSizeImgFileSize +
                    itemThumbnailSizeImgFileSize;

                //prepare to upload
                let fd = new FormData();
                fd.append("uniFile", itemOrigSizeImgFile);
                fd.append("uniFile", itemCardSizeImgFile);
                fd.append("uniFile", itemThumbnailSizeImgFile);

                const uploadResponse = await http.post(
                    `${apiUrl}/itemImage`,
                    fd,
                    {
                        params: {
                            space_server: uploadServer,
                            space_bucket_name: uploadEndpoint,
                            root_dir: cwaUploadRootDir,
                            owner_id: ownerID,
                            clinic_id: clinicID
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                if (uploadResponse.data.error) {
                    message.error(
                        "Failed to save item data. Upload image error"
                    );
                    setIsItemsTableLoading(false);
                    return false;
                } else {
                    newFormData.img_path = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${uploadResponse.data.datePath}/${itemOrigSizeImgFilename}`;
                    newFormData.img_size = itemImgSize;
                    setIsItemsTableLoading(false);
                }
            }

            //get item units and groups
            const itemUnitName = itemUnits.find(
                itemUnit => itemUnit.item_unit_id === newFormData.item_unit_id
            );
            const itemGroupName = itemGroups.find(
                itemGroup =>
                    itemGroup.item_group_id === newFormData.item_group_id
            );

            if (selectedItem) {
                //edit

                editItem({
                    ...newFormData,
                    item_unit_name: itemUnitName
                        ? itemUnitName.name
                        : undefined,
                    item_group_name: itemGroupName
                        ? itemGroupName.name
                        : undefined
                });
                message.success("Item successfully updated!");

                //update dbb
                await http.put(
                    `${apiUrl}/item/${newFormData.item_id}`,
                    newFormData
                );
            } else {
                //add
                let itemID = generateString(10, "i");
                newFormData.item_id = itemID;

                //update redux
                //its ok to have a user_id property in list of items in redux
                addItem({
                    ...newFormData,
                    item_unit_name: itemUnitName
                        ? itemUnitName.name
                        : undefined,
                    item_group_name: itemGroupName
                        ? itemGroupName.name
                        : undefined
                });
                message.success("Item successfully added!");

                //update db
                await http.post(`${apiUrl}/item/`, newFormData);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleServiceFormSubmit = async formData => {
        try {
            toggleServiceFormVisible();
            if (selectedService) {
                //edit
                editItem(formData);
                message.success("Service successfully updated!");

                //update dbb
                await http.put(`${apiUrl}/item/${formData.item_id}`, formData);
            } else {
                //add
                let itemID = generateString(10, "i");
                formData.item_id = itemID;

                //update redux
                //its ok to have a user_id property in list of items in redux
                addItem(formData);
                message.success("Service successfully added!");

                //update db
                await http.post(`${apiUrl}/item/`, formData);
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleItemDelete = ({ item_id, name }) => {
        confirm({
            title: `Are you sure do you want to archive ${name}?`,
            content: (
                <div>
                    <div>Archiving this data will results to:</div>
                    <div style={{ marginTop: 15 }}>
                        <div>
                            {" "}
                            - A case note that contains this data cannot be
                            copied
                        </div>
                        <div>
                            {" "}
                            - A case note that contains this data cannot be
                            saved
                        </div>
                        <div>
                            {" "}
                            - A saved case note that contains this data cannot
                            be use
                        </div>
                    </div>
                </div>
            ),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve(deleteItem(item_id));
                    try {
                        await http.put(`${apiUrl}/deleteItem/${item_id}`);
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleStockQtyAdjustFormSubmit = async formData => {
        try {
            adjustItemStockQty(formData);
            toggleAdjustStockQtyFormVisible();
            message.success("Item stock quantity successfully adjusted!");

            await http.post(`${apiUrl}/itemLedger`, formData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handlePrescriptionNoteFormSave = async formData => {
        try {
            updatePrescriptionNote(formData);
            togglePrescriptionNoteFormVisible();
            message.success("Prescription note saved!");

            const itemID = formData.item_id;
            const stringPrescriptionNote = JSON.stringify(
                formData.prescription_note
            );

            if (selectedItem.prescription_note) {
                //update db
                await http.put(`${apiUrl}/prescriptionNote/${itemID}`, {
                    prescription_note: stringPrescriptionNote
                });
            } else {
                //add to db
                await http.post(`${apiUrl}/prescriptionNote`, {
                    item_id: itemID,
                    prescription_note: stringPrescriptionNote
                });
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    // antd default search table default
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };
    //end of antd default search table default

    const itemTableActionMenu = {
        title: "",
        key: "action",
        width: 50,
        className: "cursorPointer",
        render: (text, record) => {
            const menu = [
                {
                    label: "Edit",
                    icon: "edit",
                    onClick: () => showItemForm(record)
                },
                {
                    label: "Prescription Note",
                    icon: "medicine-box",
                    onClick: () => showPrescriptionNoteForm(record)
                },
                {
                    label: "Adjust Stock Qty.",
                    icon: "retweet",
                    onClick: () => showAdjustStockQtyForm(record)
                },

                {
                    label: "Archive",
                    icon: "container",
                    onClick: () => handleItemDelete(record)
                }
            ];

            if (plan === "basic") {
                menu.splice(2, 1);
            }

            return <ActionMenu menu={menu} layout="compress" />;
        }
    };

    const serviceTableActionMenu = {
        title: "",
        key: "action",
        width: breakpoint.isNormalMobile ? 50 : 170,
        className: "cursorPointer",
        render: (text, record) => (
            <ActionMenu
                menu={[
                    {
                        label: "Edit",
                        icon: "edit",
                        onClick: () => showServiceForm(record)
                    },
                    {
                        label: "Archive",
                        icon: "container",
                        onClick: () => handleItemDelete(record)
                    }
                ]}
                layout="responsive"
                breakpoint={breakpoint.isNormalMobile}
            />
        )
    };

    const itemTableColumns = [
        {
            title: "Item Name",
            dataIndex: "name",
            className: "cursorPointer",
            ...getColumnSearchProps("name")
        },
        {
            title: "Generic Names",
            dataIndex: "generic_name",
            className: "cursorPointer",
            ...getColumnSearchProps("generic_name"),
            render: (text, record) => (text ? text : <span>&mdash;</span>)
        },
        {
            title: "Stock Qty.",
            dataIndex: "stock_qty",
            className: "cursorPointer"
        },
        {
            title: "Item Price.",
            dataIndex: "price",
            className: "cursorPointer",
            render: price => formatNumber(price)
        },
        {
            ...itemTableActionMenu
        }
    ];

    const itemTableColumnsMobile = [
        {
            title: "Items",
            key: "items",
            className: "cursorPointer",
            ...getColumnSearchProps("name"),
            render: (text, record) => {
                const { name, generic_name, stock_qty, price } = record;
                return (
                    <div>
                        <div>
                            <strong>{name}</strong>
                        </div>
                        <div>{generic_name}</div>
                        <div>
                            Stock: {stock_qty} &nbsp;&nbsp;&nbsp;Price:{" "}
                            {formatNumber(price)}
                        </div>
                    </div>
                );
            }
        },
        {
            ...itemTableActionMenu
        }
    ];

    const serviceTableColumns = [
        {
            title: "Service Name",
            dataIndex: "name"
        },
        {
            title: "Description",
            dataIndex: "description"
        },
        {
            title: "Price",
            dataIndex: "price",
            render: price => formatNumber(price)
        },
        {
            ...serviceTableActionMenu
        }
    ];

    const serviceTableColumnsMobile = [
        {
            title: "Services",
            key: "services",
            className: "cursorPointer",
            render: (text, record) => {
                const { name, description, price } = record;
                return (
                    <div>
                        <div>
                            <strong>{name}</strong>
                        </div>
                        <div>{description}</div>
                        <div>Price: {formatNumber(price)}</div>
                    </div>
                );
            }
        },
        {
            ...serviceTableActionMenu
        }
    ];

    if (plan === "basic") {
        itemTableColumns.splice(2, 2);
        serviceTableColumns.splice(2, 1);
    }

    return (
        <>
            {isContentLoading ? (
                <ContentLoader />
            ) : (
                <>
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab="Items" key="1">
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    style={{ marginBottom: 10 }}
                                    onClick={() => showItemForm()}
                                >
                                    <Icon type="plus" /> Add Items
                                </Button>
                            </div>
                            <div style={{ backgroundColor: "#fff" }}>
                                <Table
                                    columns={
                                        breakpoint.isNormalMobile
                                            ? itemTableColumnsMobile
                                            : itemTableColumns
                                    }
                                    dataSource={items.filter(
                                        item =>
                                            item.item_type === "product" &&
                                            item.clinic_id === clinicID &&
                                            item.status === "active"
                                    )}
                                    rowKey="item_id"
                                    loading={{
                                        spinning: isItemsTableLoading,
                                        tip: "Saving item...."
                                    }}
                                    pagination={{
                                        simple: breakpoint.isNormalMobile
                                            ? true
                                            : false
                                    }}
                                    onRow={record => ({
                                        onClick: () => {
                                            viewItemDetails(record);
                                        }
                                    })}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="Services" key="2">
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    style={{ marginBottom: 10 }}
                                    onClick={() => showServiceForm()}
                                >
                                    <Icon type="plus" /> Add Services
                                </Button>
                            </div>
                            <div style={{ backgroundColor: "#fff" }}>
                                <Table
                                    columns={
                                        breakpoint.isNormalMobile
                                            ? serviceTableColumnsMobile
                                            : serviceTableColumns
                                    }
                                    dataSource={items.filter(
                                        item =>
                                            item.item_type === "service" &&
                                            item.clinic_id === clinicID &&
                                            item.status === "active"
                                    )}
                                    pagination={{
                                        simple: breakpoint.isNormalMobile
                                            ? true
                                            : false
                                    }}
                                    rowKey="item_id"
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                    <Drawer
                        title={selectedItem ? "Edit Item" : "Create Item"}
                        placement="right"
                        visible={isItemFormVisible}
                        onClose={toggleItemFormVisible}
                        destroyOnClose={true}
                        width={
                            breakpoint.isNormalMobile
                                ? 320
                                : breakpoint.isTabletPortrait
                                ? 400
                                : 700
                        }
                    >
                        <ItemForm
                            data={selectedItem}
                            onSubmit={handleItemFormSubmit}
                            itemUnits={itemUnits}
                            itemGroups={itemGroups}
                            onItemUnitSubmit={handleItemUnitSubmit}
                            onItemGroupSubmit={handleItemGroupSubmit}
                        />
                    </Drawer>
                    <Drawer
                        title={
                            selectedService ? "Edit Service" : "Create Service"
                        }
                        placement="right"
                        visible={isServiceFormVisible}
                        onClose={toggleServiceFormVisible}
                        destroyOnClose={true}
                        width={breakpoint.isNormalMobile ? 320 : 400}
                    >
                        <ServiceForm
                            data={selectedService}
                            onSubmit={handleServiceFormSubmit}
                        />
                    </Drawer>
                    <Drawer
                        title="Adjust Stock Quantity"
                        placement="right"
                        visible={isAdjustStockQtyFormVisible}
                        onClose={toggleAdjustStockQtyFormVisible}
                        destroyOnClose={true}
                        width={breakpoint.isNormalMobile ? 320 : 400}
                    >
                        <StockQtyAdjustForm
                            data={selectedItem}
                            onSubmit={handleStockQtyAdjustFormSubmit}
                        />
                    </Drawer>
                    <Drawer
                        title="Prescription Note"
                        placement="right"
                        visible={isPrescriptionNoteFormVisible}
                        onClose={togglePrescriptionNoteFormVisible}
                        destroyOnClose={true}
                        width={breakpoint.isNormalMobile ? 320 : 400}
                    >
                        <PrescriptionNoteForm
                            item={selectedItem}
                            saveOnly
                            onSave={handlePrescriptionNoteFormSave}
                            onAdd={null}
                        />
                    </Drawer>

                    <ModalDetails
                        visible={isItemDetailsVisible}
                        width={700}
                        footer={null}
                        destroyOnClose={true}
                        style={{ top: 20 }}
                        onCancel={toggleItemDetailsVisible}
                    >
                        <ItemDetailsModalContent
                            selectedItem={selectedItem}
                            ownerID={ownerID}
                            clinicID={clinicID}
                            plan={plan}
                        />
                    </ModalDetails>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    items: state.Items,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    ownerID: state.UserDetails.owner_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
    plan: state.UserDetails.subscriptionDetails.plan
});

const mapDispatchToProps = dispatch => ({
    addItem: newItem => dispatch(addItem(newItem)),
    editItem: editedItem => dispatch(editItem(editedItem)),
    deleteItem: itemID => dispatch(deleteItem(itemID)),
    adjustItemStockQty: item => dispatch(adjustItemStockQty(item)),
    updatePrescriptionNote: prescriptionNote =>
        dispatch(updatePrescriptionNote(prescriptionNote)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Items);
