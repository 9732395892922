import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import CreateCaseForm from "./components/Forms/CreateCaseForm";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const CreateCase = ({ setRequestError, ...props }) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [isValidAppointmentID, setIsValidAppointmentID] = useState(true);

    const [appointmentDateTime, setAppointmentDateTime] = useState(
        moment().format("YYYY-MM-DD HH:mm")
    );
    const [appointmentDoctor, setAppointmentDoctor] = useState(null);
    const [appointmentDetails, setAppointmentDetails] = useState("");
    const [isFromCwapp, setIsFromCwapp] = useState(0);

    const patientID = props.match.params.patient_id
        ? props.match.params.patient_id
        : null;
    const appointmentID = props.match.params.appointment_id
        ? props.match.params.appointment_id
        : null;

    useEffect(() => {
        const checkAppointment = async () => {
            try {
                const response = await http.get(
                    `${apiUrl}/checkAppointment/${appointmentID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                //console.log(response)

                if (!response.data.appointmentExist) {
                    setIsValidAppointmentID(false);
                } else {
                    setAppointmentDateTime(
                        moment(
                            response.data.appointment.appointment_datetime
                        ).format("YYYY-MM-DD HH:mm")
                    );
                    setAppointmentDoctor({
                        id: response.data.appointment.doctor_id,
                        name: response.data.appointment.doctor_name
                    });
                    setAppointmentDetails(response.data.appointment.details);
                    setIsFromCwapp(response.data.appointment.is_from_cwapp);
                }
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        if (appointmentID) {
            checkAppointment();
        } else {
            setIsContentLoading(false);
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    return (
        <>
            {isContentLoading ? (
                <ContentLoader />
            ) : isValidAppointmentID ? (
                <CreateCaseForm
                    patientID={patientID}
                    appointmentID={appointmentID}
                    appointmentDateTime={appointmentDateTime}
                    appointmentDoctor={appointmentDoctor}
                    appointmentDetails={appointmentDetails}
                    isFromCwapp={isFromCwapp}
                    {...props}
                />
            ) : (
                "Appointment not found!"
            )}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(CreateCase);
