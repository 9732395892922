import React, { useState, useEffect } from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Form,
    Input,
    Button,
    Result,
    message,
} from "antd";

import { checkIsUserLogin } from "helpers/viewsProtector";
import http from "services/httpService";
import { apiUrl } from "config.json";
import logo from "assets/img/logo.png";

const ForgotPassword = (props) => {
    const [formData, setFormData] = useState({
        email: "",
    });
    const [formErrors, setFormErrors] = useState({
        email: "",
    });
    const [isVerifying, setIsVerifying] = useState(false);
    const [
        isSuccessChangePassVisible,
        setIsSuccessChangePassVisible,
    ] = useState(false);

    //first element focus refs
    const txtUsername = React.createRef();

    useEffect(() => {
        /*
         * back to dashboard if the user attempt to go to forgot password page
         * even the user was currently logged-in
         */
        const isUserLogin = checkIsUserLogin();
        if (isUserLogin) {
            return props.history.push(`/dashboard`);
        }
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { email } = formData;
        let errors = {};
        if (email === "") {
            errors.email = "Email is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            setIsVerifying(true);
            try {
                const response = await http.post(`${apiUrl}/resetPassword`, {
                    email: formData.email,
                });

                if (response.data.notFound) {
                    setFormErrors({
                        email:
                            "The email that you've enter was not found on our system",
                    });
                    setIsVerifying(false);
                } else {
                    setIsSuccessChangePassVisible(true);
                }
            } catch (error) {
                message.error(error.message || "Something went wrong");
                setIsVerifying(false);
            }
        }
    };

    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xl={8} md={6} xs={1} />
                <Col xl={8} md={12} xs={22}>
                    <Card
                        style={{
                            width: "100%",
                        }}
                    >
                        {isSuccessChangePassVisible ? (
                            <Result
                                status="success"
                                title="Your password was successfully reset!"
                                subTitle="Please check your email for your new password"
                                extra={[
                                    <Button
                                        type="link"
                                        key="resend"
                                        onClick={() =>
                                            props.history.push("/login")
                                        }
                                    >
                                        Go to Login Page
                                    </Button>,
                                ]}
                            />
                        ) : (
                            <div>
                                <div
                                    style={{
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            width: 100,
                                            height: 100,
                                        }}
                                    />
                                    <h2>Forgot your password?</h2>
                                </div>
                                <p>
                                    Don&rsquo;t worry. Resetting your password
                                    is easy, just tell us the email address you
                                    registered with Clinic Web Assistant
                                </p>
                                <div
                                    style={{
                                        maxHeight: 320,
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                >
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Item
                                            label="Enter your email"
                                            hasFeedback
                                            validateStatus={
                                                formErrors.email && "error"
                                            }
                                            help={
                                                formErrors.email &&
                                                formErrors.email
                                            }
                                        >
                                            <Input
                                                name="email"
                                                ref={txtUsername}
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                onBlur={handleInputBlur}
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                        <div style={{ textAlign: "center" }}>
                                            <br />
                                            <Button
                                                type="primary"
                                                size="large"
                                                htmlType="submit"
                                                loading={isVerifying}
                                            >
                                                {isVerifying
                                                    ? "Sending"
                                                    : "Send"}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>
                <Col xl={8} md={6} xs={1} />
            </Row>
        </Layout>
    );
};

export default ForgotPassword;
