import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Form } from "antd";

const AddTYPlaylistForm = ({ userID, clinicID, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: "",
        url: "",
        user_id: userID,
        clinic_id: clinicID,
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
        url: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name, url } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Name is required";
        }

        if (!/https:\/\/www\.youtube\.com\/playlist\?list=.*?$/.test(url)) {
            errors.url = "Invalid youtube url";
        }

        if (url.trim() === "") {
            errors.url = "Url is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <Form>
            <Form.Item
                label="Enter playlist name"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.name && "error"}
                help={formErrors.name && formErrors.name}
            >
                <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item
                label="Enter Youtube url"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.url && "error"}
                help={formErrors.url && formErrors.url}
            >
                <Input
                    name="url"
                    value={formData.url}
                    placeholder={"https://www.youtube.com/playlist?list=...."}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

export default connect(mapStateToProps)(AddTYPlaylistForm);
