import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Spin,
    message,
    Icon,
    Button,
    Modal,
    Empty,
    Tooltip,
    Menu,
    Dropdown
} from "antd";

import ContentLoader from "globalComponents/ContentLoader";

import SearchPatient from "./components/SearchPatient/Index";
import PatientInfo from "./components/PatientInfo/Index";
import PatientCases from "./components/PatientCases/Index";
import PatientImgGallery from "./components/PatientImgGallery/Index";
import PatientAttachments from "./components/PatientAttachments/Index";
import Notes from "./components/Notes/Index";
import CaseDetails from "./components/CaseDetails/Index";
import AcquireCase from "./components/AcquireCase/Index";
import VideoCaller from "./components/VideoCaller/Index";

import { findUserName } from "helpers/userNameFinder";
import { convertToSolidColor } from "helpers/colorConverter";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

let caseDivRef = React.createRef();

const CaseNotes = ({
    breakpoint,
    setRequestError,
    userID,
    userDegree,
    userType,
    clinicID,
    ownerID,
    doctors,
    defaultDisplayedCaseNote,
    userPrivileges,
    caseListSettings,
    ...props
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [isCaseReadOnly, setIsCaseReadOnly] = useState(false);
    const [isCaseNotFound, setCaseIsNotFound] = useState(false);
    const [isNoSelectedCase, setIsNoSelectedCase] = useState(false);
    const [isCaseNoDoctor, setIsCaseNoDoctor] = useState(false);

    const [caseLoading, setCaseLoading] = useState({
        spin: false,
        spinLabel: "Loading Case ..."
    });

    const [patientDetails, setPatientDetails] = useState(null);
    const [caseDetails, setCaseDetails] = useState(null);

    const { confirm } = Modal;

    useEffect(() => {
        const fetchData = async () => {
            const caseID = props.match.params.case_id;
            try {
                const caseDetailsResponse = await http.get(
                    `${apiUrl}/caseDetails/${caseID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                const { isNotFound, caseDetails } = caseDetailsResponse.data;

                if (isNotFound) {
                    setCaseIsNotFound(true);
                } else {
                    const patientID = caseDetails.patient_id;
                    const patientDetailsResponse = await http.get(
                        `${apiUrl}/patientDetails/${patientID}`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    setPatientDetails(
                        patientDetailsResponse.data.patientDetails
                    );
                    setCaseDetails(caseDetails);

                    if (userType === "staff") {
                        setIsCaseReadOnly(
                            userPrivileges.isAccessSOAP ? false : true
                        );
                    } else if (caseDetails.doctor_id !== userID) {
                        setIsCaseReadOnly(true);
                    }

                    if (caseDetails.doctor_id === "any") {
                        setIsCaseNoDoctor(true);
                    }

                    // console.log(
                    //     "patientDetails",
                    //     patientDetailsResponse.data.patientDetails
                    // );
                    // console.log("caseDetails", caseDetails);
                }
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleCaseAquire = async doctorID => {
        try {
            setCaseLoading({
                spin: true,
                spinLabel: "Aquiring Case ..."
            });

            const response = await http.put(
                `${apiUrl}/aquireCase/${caseDetails.case_id}`,
                { doctor_id: doctorID },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            if (response.data.error) {
                message.info(response.data.errorMsg);
            } else {
                const doctorName = doctors.find(
                    doctor => doctor.doctor_id === doctorID
                ).doctor_name;
                const { case_id, clinic_id, chief_complaint } = caseDetails;
                const { lastname, firstname, middlename } = patientDetails;
                const patientFullname = `${lastname}, ${firstname} ${middlename}`;

                socket.emit("caseAcquired", {
                    caseID: case_id,
                    doctorID,
                    doctorName,
                    chiefComplaint: chief_complaint,
                    patientFullname,
                    clinicID: clinic_id
                });

                setCaseDetails({
                    ...caseDetails,
                    doctor_id: doctorID
                });
                setIsCaseNoDoctor(false);
                setIsCaseReadOnly(false);

                if (userType === "staff") {
                    setIsCaseReadOnly(
                        userPrivileges.isAccessSOAP ? false : true
                    );
                }
            }

            setCaseLoading({
                spin: false,
                spinLabel: "Aquiring Case ..."
            });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleCaseDetailsChange = (prop, value, multiDetails = null) => {
        if (multiDetails) {
            setCaseDetails({
                ...caseDetails,
                ...multiDetails
            });
        } else {
            setCaseDetails({
                ...caseDetails,
                [prop]: value
            });
        }
    };

    const handleCaseChange = async caseID => {
        try {
            setCaseLoading({
                spin: true,
                spinLabel: "Loading Case ..."
            });

            const response = await http.get(`${apiUrl}/caseDetails/${caseID}`, {
                cancelToken: new CancelToken(function executor(c) {
                    CancelRequest = c;
                })
            });
            const { isNotFound, caseDetails } = response.data;

            if (isNotFound) {
                setCaseLoading({
                    spin: false,
                    spinLabel: "Loading Case ..."
                });
                message.error("Case not found!");
            } else {
                caseDivRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });

                setCaseDetails(caseDetails);

                //set read-only
                if (userType === "staff") {
                    setIsCaseReadOnly(
                        userPrivileges.isAccessSOAP ? false : true
                    );
                } else if (caseDetails.doctor_id !== userID) {
                    setIsCaseReadOnly(true);
                } else {
                    setIsCaseReadOnly(false);
                }

                if (caseDetails.doctor_id === "any") {
                    setIsCaseNoDoctor(true);
                } else {
                    setIsCaseNoDoctor(false);
                }

                setIsNoSelectedCase(false);

                setCaseLoading({
                    spin: false,
                    spinLabel: "Loading Case ..."
                });
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleCaseStatusChange = async status => {
        try {
            const {
                case_id,
                status: oldStatus,
                clinic_id,
                chief_complaint
            } = caseDetails;

            const patientFullname = `${patientDetails.lastname} ${patientDetails.firstname} ${patientDetails.middlename}`;

            setCaseLoading({
                spin: true,
                spinLabel: "Changing Case Status ..."
            });

            await http.put(
                `${apiUrl}/case/${case_id}`,
                { status },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            setCaseLoading({
                spin: false,
                spinLabel: "Changing Case Status ..."
            });

            socket.emit("changeCaseStatus", {
                caseID: case_id,
                chiefComplaint: chief_complaint,
                patientFullname,
                oldStatus,
                newStatus: status,
                clinicID: clinic_id
            });

            message.success(
                `Case status successfully changed from ${oldStatus} to ${status}`
            );

            setCaseDetails({
                ...caseDetails,
                status
            });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleCaseDelete = async caseId => {
        confirm({
            title: `Are you sure do you want to archive this case?`,
            //content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    setCaseLoading({
                        spin: true,
                        spinLabel: "Deleting case ..."
                    });

                    resolve();
                    try {
                        const { firstname, middlename, lastname } =
                            patientDetails;
                        const { chief_complaint, doctor_id, clinic_id } =
                            caseDetails;
                        const patient_fullname = `${firstname} ${middlename} ${lastname}`;

                        await http.put(`${apiUrl}/case/${caseId}`, {
                            status: "inactive"
                        });

                        socket.emit("caseDeleted", {
                            doctorName: findUserName(doctor_id),
                            chiefComplaint: chief_complaint,
                            patientFullname: patient_fullname,
                            clinicID: clinic_id
                        });
                        message.success("Case successfully archived!");

                        setCaseDetails({
                            ...caseDetails,
                            case_id: null
                        });
                        setPatientDetails({
                            ...patientDetails,
                            cases: patientDetails.cases.filter(
                                patientCase => patientCase.case_id !== caseId
                            )
                        });
                        setIsNoSelectedCase(true);

                        setCaseLoading({
                            spin: false,
                            spinLabel: "Deleting case ..."
                        });
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleCaseCancel = async () => {
        confirm({
            title: `Are you sure do you want to cancel this case?`,
            content:
                "cancelling case will clear all the case note content and change the assigned doctor to any",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    setCaseLoading({
                        spin: true,
                        spinLabel: "Cancelling case ..."
                    });

                    resolve();
                    try {
                        const {
                            case_id,
                            clinic_id,
                            doctor_id,
                            chief_complaint
                        } = caseDetails;
                        const patientFullname = `${patientDetails.lastname}, ${patientDetails.firstname} ${patientDetails.middlename}`;
                        const doctorName = doctors.find(
                            doctor => doctor.doctor_id === doctor_id
                        ).doctor_name;
                        await http.put(`${apiUrl}/cancelCase/${case_id}`, {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        });

                        //emit
                        socket.emit("caseCancelled", {
                            caseID: case_id,
                            doctorName: doctorName,
                            chiefComplaint: chief_complaint,
                            patientFullname,
                            clinicID: clinic_id
                        });

                        setIsCaseNoDoctor(true);

                        setCaseDetails({
                            ...caseDetails,
                            doctor_id: "any",
                            subjective: null,
                            objective: null,
                            assessment: null,
                            plan: null,
                            otherPlans: [],
                            appointmentDetails: null,
                            vitals: [],
                            sketchs: []
                        });

                        setCaseLoading({
                            spin: false,
                            spinLabel: "Cancelling case ..."
                        });
                    } catch (error) {
                        if (!error.message.responseCancelled) {
                            setRequestError({
                                errorMessage:
                                    "Something went wrong on your last operation :(",
                                errorSubMessage: error.message
                            });
                        }
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleSearchPatient = async patientID => {
        try {
            setCaseLoading({
                spin: true,
                spinLabel: "Loading ..."
            });

            const patientDetailsResponse = await http.get(
                `${apiUrl}/patientDetails/${patientID}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            setIsNoSelectedCase(true);
            setIsCaseNoDoctor(false);
            setCaseDetails({
                ...caseDetails,
                case_id: null
            });
            setPatientDetails(patientDetailsResponse.data.patientDetails);

            setCaseLoading({
                spin: false,
                spinLabel: "Loading ..."
            });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleCreateCase = () => {
        props.history.push(`/case/create/${patientDetails.patient_id}`);
    };

    const handleDurationChange = duration => {
        setCaseDetails({
            ...caseDetails,
            call_duration: duration
        });
    };

    const handleEndVisitClick = async action => {
        try {
            setCaseLoading({
                spin: true,
                spinLabel: "Finishing ..."
            });

            //get durationon redux

            const {
                case_id,
                status: oldStatus,
                clinic_id,
                chief_complaint,
                call_duration,
                appointment_id,
                //patient_id,
                appointment_video_call_room_id,
                is_from_cwapp
            } = caseDetails;
            const patientFullname = `${patientDetails.lastname} ${patientDetails.firstname} ${patientDetails.middlename}`;

            await http.put(
                `${apiUrl}/case/${case_id}`,
                { status: "done" },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            //update cwapp
            if (is_from_cwapp) {
                await http.post(
                    `${apiUrl}/cwappData`,
                    {
                        call_duration,
                        appointment_id,
                        chief_complaint
                    },
                    {
                        headers: {
                            conn: "pp"
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
            }

            socket.emit("changeCaseStatus", {
                caseID: case_id,
                chiefComplaint: chief_complaint,
                patientFullname,
                oldStatus,
                newStatus: "done",
                clinicID: clinic_id
            });
            socket.emit("reloadPPAppointment", appointment_video_call_room_id);
            socket.emit("reloadPPRecordList", appointment_video_call_room_id);
            socket.emit("reloadPPRecordView", appointment_video_call_room_id);

            if (action === "end") {
                props.history.push("/cases");
            } else {
                setCaseDetails({
                    ...caseDetails,
                    status: "done"
                });
                setCaseLoading({
                    spin: false,
                    spinLabel: "Finishing ..."
                });
                message.success("Case done!");
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const EndVisitMenu = (
        <Menu>
            <Menu.Item
                key="1"
                onClick={() => handleEndVisitClick("endAndStay")}
            >
                End Visit and Stay
            </Menu.Item>
        </Menu>
    );

    return isContentLoading ? (
        <ContentLoader />
    ) : isCaseNotFound ? (
        <div style={{ marginTop: 20, textAlign: "center" }}>No Case Found</div>
    ) : (
        <Spin spinning={caseLoading.spin} tip={caseLoading.spinLabel}>
            <SearchPatient
                breakpoint={breakpoint}
                onSelect={handleSearchPatient}
            />
            <div ref={caseDivRef}>
                <Row gutter={16}>
                    <Col xl={8} lg={8}>
                        <Row gutter={12}>
                            <Col xl={24} lg={24} md={12}>
                                {caseDetails.is_from_cwapp &&
                                userType !== "staff" &&
                                caseDetails.doctor_id === userID ? (
                                    <VideoCaller
                                        patientDetails={patientDetails}
                                        caseDetails={caseDetails}
                                        onDurationChange={handleDurationChange}
                                    />
                                ) : null}
                                <PatientInfo
                                    patientDetails={patientDetails}
                                    readOnly={isCaseReadOnly}
                                    caseStatus={caseDetails.status}
                                    breakpoint={breakpoint}
                                />
                                <PatientCases
                                    patientDetails={patientDetails}
                                    caseID={caseDetails.case_id}
                                    onSelectCase={handleCaseChange}
                                    onDeleteCase={handleCaseDelete}
                                />
                            </Col>
                            <Col xl={24} lg={24} md={12}>
                                {!isNoSelectedCase && !isCaseNoDoctor && (
                                    <>
                                        <PatientImgGallery
                                            patientDetails={patientDetails}
                                            caseID={caseDetails.case_id}
                                            onSelectCase={handleCaseChange}
                                        />
                                        <PatientAttachments
                                            patientDetails={patientDetails}
                                            caseID={caseDetails.case_id}
                                            breakpoint={breakpoint}
                                            onSelectCase={handleCaseChange}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={16} lg={16}>
                        {isCaseNoDoctor ? (
                            <AcquireCase
                                caseDetails={caseDetails}
                                onAcquire={handleCaseAquire}
                            />
                        ) : isNoSelectedCase ? (
                            <div style={{ marginTop: 64 }}>
                                <Empty description="No Selected Case">
                                    <Button
                                        type="primary"
                                        onClick={handleCreateCase}
                                    >
                                        <Icon type="plus" /> Create Case
                                    </Button>
                                </Empty>
                            </div>
                        ) : (
                            <>
                                <CaseDetails
                                    caseDetails={caseDetails}
                                    readOnly={isCaseReadOnly}
                                    onCaseStatusChange={handleCaseStatusChange}
                                    onCaseDelete={handleCaseDelete}
                                    onCaseCancel={handleCaseCancel}
                                />
                                <Notes
                                    caseDetails={caseDetails}
                                    patientDetails={patientDetails}
                                    readOnly={isCaseReadOnly}
                                    breakpoint={breakpoint}
                                    onChange={handleCaseDetailsChange}
                                />

                                {!isCaseReadOnly && (
                                    <div style={{ marginBottom: 30 }}>
                                        <Dropdown.Button
                                            type="primary"
                                            size="large"
                                            icon={<Icon type="down" />}
                                            style={{
                                                marginRight: 5
                                            }}
                                            onClick={() =>
                                                handleEndVisitClick("end")
                                            }
                                            overlay={EndVisitMenu}
                                        >
                                            <span
                                                style={{
                                                    width: breakpoint.isNormalMobile
                                                        ? "100%"
                                                        : 130
                                                }}
                                            >
                                                End Visit
                                            </span>
                                        </Dropdown.Button>
                                        {caseDetails.status === "done" ? (
                                            <Tooltip title="Case status was already done">
                                                <Button
                                                    size="large"
                                                    disabled
                                                    style={{
                                                        width: breakpoint.isNormalMobile
                                                            ? 150
                                                            : 200
                                                    }}
                                                >
                                                    Hold Case
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                size="large"
                                                style={{
                                                    width: breakpoint.isNormalMobile
                                                        ? "49%"
                                                        : 200,
                                                    backgroundColor:
                                                        convertToSolidColor(
                                                            caseListSettings.holdStatusColor
                                                        ),
                                                    color: "#fff",
                                                    borderColor:
                                                        convertToSolidColor(
                                                            caseListSettings.holdStatusColor
                                                        )
                                                }}
                                                onClick={() =>
                                                    handleCaseStatusChange(
                                                        "hold"
                                                    )
                                                }
                                            >
                                                Hold Case
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </Spin>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    userDegree: state.UserDetails.degree,
    userType: state.UserDetails.type,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    ownerID: state.UserDetails.owner_id,
    doctors: state.UserDetails.doctors,
    defaultDisplayedCaseNote: state.Settings.defaultDisplayedCaseNote,
    userPrivileges: state.Settings.userPrivileges,
    caseListSettings: state.Settings.caseList
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseNotes);
