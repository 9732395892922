import React, { useState } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";

import { Input, Button, Select, message, Tooltip } from "antd";
import { generateString } from "helpers/randomStringGenerator";
import http from "services/httpService";
import { apiUrl } from "config.json";

const SelectWithCRUD = ({
    data: items,
    dataKey,
    selectedValue,
    apiRoute,
    width,
    disabled,
    onChange,
    onBlur,
    onActionClick,
    onSubmit,
    setRequestError
}) => {
    const [isItemModifying, setIsItemModifying] = useState(false);
    const [isItemEditing, setIsItemEditing] = useState(false);
    const [itemName, setItemName] = useState("");

    const InputGroup = Input.Group;
    const ButtonGroup = Button.Group;
    const { Option } = Select;

    const showInput = itemOperation => {
        switch (itemOperation) {
            case "adding":
                setIsItemEditing(false);
                setIsItemModifying(true);
                onActionClick("addButtonClick");
                break;
            case "editing":
                if (selectedValue) {
                    setIsItemEditing(true);
                    const item = items.filter(
                        item => item[dataKey] === selectedValue
                    );
                    setItemName(item[0].name);
                    setIsItemModifying(true);
                    onActionClick("editButtonClick");
                } else {
                    message.error("Please select an item");
                }
                break;
            default:
                return;
        }
    };

    const inputOk = async itemOperation => {
        try {
            onActionClick("okButtonClick");
            switch (itemOperation) {
                case "add":
                    if (itemName.trim() !== "") {
                        const itemUnitID = generateString(5);
                        const newItem = {
                            [dataKey]: itemUnitID,
                            name: itemName,
                            status: "active"
                        };
                        onChange(itemUnitID);
                        onSubmit({
                            operation: "add",
                            newItem
                        });
                        setItemName("");
                        setIsItemModifying(false);
                        await http.post(`${apiUrl}/${apiRoute}`, newItem);
                    } else {
                        message.error("Please enter an item");
                    }
                    break;
                case "edit":
                    if (itemName.trim() !== "") {
                        onChange(selectedValue);
                        onSubmit({
                            operation: "edit",
                            itemID: selectedValue,
                            name: itemName
                        });
                        setItemName("");
                        setIsItemModifying(false);

                        await http.put(
                            `${apiUrl}/${apiRoute}/${selectedValue}`,
                            { name: itemName }
                        );
                    } else {
                        message.error("Please enter an item");
                    }
                    break;
                default:
                    return;
            }
        } catch (error) {
            setRequestError({
                errorMessage: "500",
                errorSubMessage:
                    "Something went wrong on your last operation :("
            });
        }
    };

    const handleCancel = () => {
        setIsItemModifying(false);
        onActionClick("cancelButtonClick");
    };

    return (
        <>
            <InputGroup compact>
                {isItemModifying ? (
                    <>
                        <Input
                            style={{ width: 193 }}
                            ref={c => c && c.input.focus()}
                            value={itemName}
                            onChange={e => setItemName(e.target.value)}
                        />
                        <ButtonGroup>
                            <Tooltip title="OK">
                                <Button
                                    type="primary"
                                    icon="check"
                                    onClick={() =>
                                        inputOk(isItemEditing ? "edit" : "add")
                                    }
                                ></Button>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <Button
                                    icon="close"
                                    onClick={handleCancel}
                                ></Button>
                            </Tooltip>
                        </ButtonGroup>
                    </>
                ) : (
                    <>
                        <Select
                            style={{ width }}
                            value={selectedValue}
                            disabled={disabled}
                            onChange={(e, option) => onChange(e)}
                            onBlur={e => onBlur(dataKey, e)}
                        >
                            {items.map(item => (
                                <Option
                                    key={item[dataKey]}
                                    value={item[dataKey]}
                                >
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                        <ButtonGroup>
                            <Tooltip title="Add Item">
                                <Button
                                    icon="plus"
                                    type="primary"
                                    disabled={disabled}
                                    onClick={() => showInput("adding")}
                                ></Button>
                            </Tooltip>
                            <Tooltip title="Edit Item">
                                <Button
                                    icon="edit"
                                    disabled={disabled}
                                    onClick={() => showInput("editing")}
                                ></Button>
                            </Tooltip>
                        </ButtonGroup>
                    </>
                )}
            </InputGroup>
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(SelectWithCRUD);
