import React, { useState, useEffect } from "react";
import { Form, AutoComplete } from "antd";

const FormItemInput = ({ data, error, onInputChange }) => {
    const { name, label, value, placeholder, options, required } = data;
    const [formData, setFormData] = useState({
        [name]: value
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error
    });

    useEffect(() => {
        if (value) {
            setFormData({ [name]: value });
        } else {
            setFormData({ [name]: "" });
        }
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleInputChange = (name, value) => {
        let newValue = value;
        // if (transform !== "default") {
        //     switch (transform) {
        //         case "titlecase":
        //             newValue = titleCase(value);
        //             break;
        //         case "uppercase":
        //             newValue = upperCase(value);
        //             break;
        //         case "lowercase":
        //             newValue = lowerCase(value);
        //             break;
        //         default:
        //             newValue = value;
        //     }
        // }

        setFormData({
            ...formData,
            [name]: newValue || ""
        });
    };

    const handleInputBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
        onInputChange(name, formData[name]);
    };

    return (
        <Form.Item
            label={label}
            required={required}
            hasFeedback
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <AutoComplete
                defaultValue={value}
                value={formData[name]}
                dataSource={options}
                placeholder={placeholder}
                filterOption={(inputValue, option) =>
                    option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear
                onChange={value => handleInputChange(name, value)}
                onBlur={value => handleInputBlur(name, value)}
            />
        </Form.Item>
    );
};

export default FormItemInput;
