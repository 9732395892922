import React, { useState } from "react";
import { connect } from "react-redux";

import { addGroup, editGroup, deleteGroup } from "redux/groups/group.actions";
import { setRequestError } from "redux/system/system.actions";

import { Button, Icon, Empty, Table, Modal, message } from "antd";
import Drawer from "globalComponents/Drawer";
import ActionMenu from "globalComponents/ActionMenu";
import GroupForm from "./components/Forms/GroupForm";

import { generateString } from "helpers/randomStringGenerator";
import http from "services/httpService";
import { apiUrl } from "config.json";

const Groups = ({
    breakpoint,
    groups,
    addGroup,
    editGroup,
    deleteGroup,
    setRequestError
}) => {
    const [isGroupFormVisible, setIsGroupFormVisible] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const { confirm } = Modal;

    const toggleGroupFormVisible = () => {
        setIsGroupFormVisible(!isGroupFormVisible);
    };

    const showGroupForm = (selectedRecord = null) => {
        setSelectedGroup(selectedRecord);
        toggleGroupFormVisible();
    };

    const handleGroupFormSubmit = async formData => {
        try {
            if (selectedGroup) {
                //edit
                editGroup(formData);
                toggleGroupFormVisible();
                message.success("Group successfully updated!");
                await http.put(
                    `${apiUrl}/group/${formData.group_id}`,
                    formData
                );
            } else {
                //add
                const groupID = generateString(5);
                const newFormData = { group_id: groupID, ...formData };
                addGroup(newFormData);
                toggleGroupFormVisible();
                message.success("Group successfully added!");
                await http.post(`${apiUrl}/group`, newFormData);
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleGroupDelete = ({ group_id, name }) => {
        confirm({
            title: `Are you sure do you want to delete group ${name}?`,
            content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve(deleteGroup(group_id));
                    try {
                        await http.put(`${apiUrl}/group/${group_id}`, {
                            status: "inactive"
                        });
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const groupsTableColumns = [
        {
            title: "Group Name",
            dataIndex: "name"
        },
        {
            title: "",
            key: "action",
            width: breakpoint.isNormalMobile ? 50 : 170,
            render: (text, record) => (
                <ActionMenu
                    menu={[
                        {
                            label: "Edit",
                            icon: "edit",
                            onClick: () => showGroupForm(record)
                        },
                        {
                            label: "Delete",
                            icon: "delete",
                            onClick: () => handleGroupDelete(record)
                        }
                    ]}
                    layout="responsive"
                    breakpoint={breakpoint.isNormalMobile}
                />
            )
        }
    ];

    return (
        <>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    onClick={() => showGroupForm()}
                    style={{ marginBottom: 10 }}
                >
                    <Icon type="plus" />
                    Add Group
                </Button>
            </div>

            {groups.length === 0 ? (
                <Empty description="No accredited Group found." />
            ) : (
                <div style={{ backgroundColor: "#fff" }}>
                    <Table
                        columns={groupsTableColumns}
                        dataSource={groups}
                        rowKey="group_id"
                    />
                </div>
            )}

            <Drawer
                title={selectedGroup ? "Edit Group" : "Create Group"}
                placement="right"
                visible={isGroupFormVisible}
                onClose={toggleGroupFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <GroupForm
                    data={selectedGroup}
                    onSubmit={handleGroupFormSubmit}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = state => ({
    groups: state.Groups
});

const mapDispatchToProps = dispatch => ({
    addGroup: newGroup => dispatch(addGroup(newGroup)),
    editGroup: editedGroup => dispatch(editGroup(editedGroup)),
    deleteGroup: groupID => dispatch(deleteGroup(groupID)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
