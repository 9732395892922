import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Avatar, Badge, Spin, Popover, Button } from "antd";

import ChatBox from "./ChatBox";
import { Sound, isSoundOn } from "globalComponents/ToggleSound";

import http from "services/httpService";
import { socket } from "layouts/Main";
import { apiUrl } from "config.json";

import chatSound from "assets/sound/for-sure.mp3";

const Chat = (props) => {
    const {
        breakpoint,
        currentUserID,
        doctors,
        staffs,
        clinicID,
        serverDate,
    } = props;

    const [chatVisible, setChatVisible] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [
        selectedConversationRecentMsg,
        setSelectedConversationRecentMsg,
    ] = useState(null);
    const [isConversationLoading, setIsConversationLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(
                    `${apiUrl}/chat/conversations/${clinicID}/${currentUserID}`
                );

                const {
                    latestReceivedConvoMessages,
                    latestSentConvoMessages,
                    totalUnreadMessages,
                } = response.data;

                //build the list of conversation
                let newConversations = [];
                doctors
                    .filter((doctor) => doctor.status !== "inactive")
                    .forEach((doctor) => {
                        if (doctor.doctor_id !== currentUserID) {
                            newConversations.push({
                                user_id: doctor.doctor_id,
                                name: doctor.doctor_name,
                                img_path: "",
                                recentMessage: null,
                                totalUnreadMessage: 0,
                            });
                        }
                    });
                staffs
                    .filter((staff) => staff.status !== "inactive")
                    .forEach((staff) => {
                        if (staff.staff_id !== currentUserID) {
                            newConversations.push({
                                user_id: staff.staff_id,
                                name: staff.staff_name,
                                img_path: "",
                                recentMessage: null,
                                totalUnreadMessage: 0,
                            });
                        }
                    });
                newConversations.push({
                    user_id: "support",
                    name: "CWA Support Team",
                    img_path: "",
                    recentMessage: null,
                    totalUnreadMessage: 0,
                });

                //fill each conversation of their respective recent messages and conversation unread mesg
                newConversations = newConversations.map((conversation) => {
                    const latestReceiveMessage = latestReceivedConvoMessages.filter(
                        (message) =>
                            message.sender_id === conversation.user_id ||
                            message.receiver_id === conversation.user_id
                    );
                    const latestSentConvoMessage = latestSentConvoMessages.filter(
                        (message) =>
                            message.sender_id === conversation.user_id ||
                            message.receiver_id === conversation.user_id
                    );

                    const totalUnreadMessage = totalUnreadMessages.filter(
                        (totalUnreadMsg) =>
                            totalUnreadMsg.sender_id === conversation.user_id
                    );

                    let newConversation = conversation;

                    if (
                        latestReceiveMessage.length !== 0 &&
                        latestSentConvoMessage.length !== 0
                    ) {
                        //determine if the recent message was received or sent message
                        if (
                            latestReceiveMessage[0].message_id >
                            latestSentConvoMessage[0].message_id
                        ) {
                            newConversation = {
                                ...conversation,
                                recentMessage: latestReceiveMessage[0],
                            };
                        } else {
                            newConversation = {
                                ...conversation,
                                recentMessage: latestSentConvoMessage[0],
                            };
                        }
                    } else if (latestReceiveMessage.length !== 0) {
                        newConversation = {
                            ...conversation,
                            recentMessage: latestReceiveMessage[0],
                        };
                    } else if (latestSentConvoMessage.length !== 0) {
                        newConversation = {
                            ...conversation,
                            recentMessage: latestSentConvoMessage[0],
                        };
                    }

                    return {
                        ...newConversation,
                        totalUnreadMessage:
                            totalUnreadMessage.length !== 0
                                ? totalUnreadMessage[0].total_unread_messages
                                : 0,
                    };
                });

                setConversations(newConversations);
                setIsConversationLoading(false);
            } catch (error) {}
        };

        fetchData();
    }, [doctors, staffs]);

    useEffect(() => {
        socket.on("messageReceived", async function (messageData) {
            if (currentUserID === messageData.receiver_id) {
                let isUpdateMessageStatus = false;
                const newConversations = conversations.map((conversation) => {
                    if (conversation.user_id === messageData.sender_id) {
                        let totalUnreadMessage = 0;
                        let is_read = 1;

                        if (!selectedConversation) {
                            totalUnreadMessage =
                                conversation.totalUnreadMessage + 1;
                            is_read = 0;
                        } else if (
                            selectedConversation.user_id !==
                            messageData.sender_id
                        ) {
                            totalUnreadMessage =
                                conversation.totalUnreadMessage + 1;
                            is_read = 0;
                        } else {
                            isUpdateMessageStatus = true;
                        }

                        return {
                            ...conversation,
                            recentMessage: { ...messageData, is_read },
                            totalUnreadMessage,
                        };
                    }
                    return conversation;
                });

                setSelectedConversationRecentMsg(messageData);
                setConversations(newConversations);

                if (isSoundOn) {
                    Sound.src = chatSound;
                    Sound.play();
                }

                try {
                    if (isUpdateMessageStatus) {
                        const { sender_id, receiver_id } = messageData;
                        await http.put(`${apiUrl}/chat/update/message`, {
                            sender_id,
                            receiver_id,
                            clinic_id: clinicID,
                        });
                    }
                } catch (error) {}
            }
        });

        return () => {
            socket.off("messageReceived");
        };
    }, [conversations, selectedConversation]);

    const toggleChatVisible = () => {
        setChatVisible(!chatVisible);
    };

    const handleSelectConversation = (selectedRecord) => {
        if (selectedRecord) {
            const newConversations = conversations.map((conversation) => {
                if (conversation.user_id === selectedRecord.user_id) {
                    return {
                        ...conversation,
                        recentMessage: conversation.recentMessage
                            ? {
                                  ...conversation.recentMessage,
                                  is_read: 1,
                              }
                            : null,
                        totalUnreadMessage: 0,
                    };
                }
                return conversation;
            });
            setSelectedConversationRecentMsg(null);
            setConversations(newConversations);
        }
        setSelectedConversation(selectedRecord);
    };

    const handleMessageSent = (messageData) => {
        //update recent message of selected conversation
        const newConversations = conversations.map((conversation) => {
            if (conversation.user_id === selectedConversation.user_id) {
                return {
                    ...conversation,
                    recentMessage: messageData,
                };
            }
            return conversation;
        });
        setSelectedConversationRecentMsg(messageData);
        setConversations(newConversations);
    };

    const totalUnreadMessage = conversations.reduce(
        (total, conversation) => total + conversation.totalUnreadMessage,
        0
    );

    return (
        <>
            {/* {chatVisible ? (
                <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        right: breakpoint.isSmallMobile
                            ? 0
                            : breakpoint.isNormalMobile
                            ? 10
                            : 50,
                        //boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.20)",
                        zIndex: 5,
                    }}
                >
                    <ChatBox
                        currentUserID={currentUserID}
                        clinicID={clinicID}
                        conversations={conversations}
                        selectedConvoRecentMsg={selectedConversationRecentMsg}
                        serverDate={serverDate}
                        onSelectConversation={handleSelectConversation}
                        onMessageSent={handleMessageSent}
                        onChatClose={toggleChatVisible}
                    />
                </div>
            ) : (
                <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        right: breakpoint.isNormalMobile ? 10 : 50,
                        //boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.20)",
                        zIndex: 5,
                    }}
                >
                    <Button
                        size="large"
                        style={{ width: 170 }}
                        onClick={toggleChatVisible}
                        loading={isConversationLoading}
                    >
                        {!isConversationLoading && <Icon type="mail" />}{" "}
                        Messages&nbsp;&nbsp;
                        <Badge count={totalUnreadMessage}></Badge>
                    </Button>
                </div>
            )} */}

            <Popover
                content={
                    isConversationLoading ? (
                        <div style={{ textAlign: "center", paddingTop: 100 }}>
                            <Spin tip="Loading conversations ..." />
                        </div>
                    ) : (
                        <ChatBox
                            currentUserID={currentUserID}
                            clinicID={clinicID}
                            conversations={conversations}
                            selectedConvoRecentMsg={
                                selectedConversationRecentMsg
                            }
                            serverDate={serverDate}
                            breakpoint={breakpoint}
                            onSelectConversation={handleSelectConversation}
                            onMessageSent={handleMessageSent}
                            onChatClose={toggleChatVisible}
                        />
                    )
                }
                trigger="click"
                placement="bottomRight"
                overlayClassName="cwaChat"
                visible={chatVisible}
            >
                <Button
                    style={{
                        height: 55,
                        border: "none",
                    }}
                    onClick={() => {
                        toggleChatVisible();
                    }}
                >
                    <Badge count={totalUnreadMessage} overflowCount={9}>
                        <Avatar
                            icon="message"
                            style={{
                                fontSize: "1.2rem",
                                color: "#595959",
                                backgroundColor: "#fff",
                            }}
                            className="cursorPointer"
                        />
                    </Badge>
                </Button>
                {/* <div style={{ padding: "0 16px" }}>
                    <Badge count={totalUnreadMessage} dot>
                        <Avatar
                            icon="message"
                            style={{
                                //marginLeft: 20,
                                fontSize: "1.1rem",
                                color: "#595959",
                                backgroundColor: "#fff",
                            }}
                            onClick={() => {
                                toggleChatVisible();
                            }}
                            className="cursorPointer"
                        />
                    </Badge>
                </div> */}
            </Popover>
        </>
    );
};

const mapStateToProps = (state) => ({
    currentUserID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    doctors: state.UserDetails.doctors,
    staffs: state.UserDetails.staffs,
    serverDate: state.UserDetails.serverDate,
});

export default connect(mapStateToProps)(Chat);
