import React, { useState } from "react";
import { Button, Icon, Tooltip } from "antd";
import Drawer from "globalComponents/Drawer";
import AppointmentForm from "views/Appointments/components/Forms/AppointmentForm";

const AppointmentDrawer = ({
    caseID,
    doctorID,
    isFromCWAPP,
    patientDetails,
    appointmentDetails,
    breakpoint,
    onAppointmentSet
}) => {
    const [isAppointmentFormVisible, setIsAppointmentFormVisible] =
        useState(false);

    const toggleAppointmentFormVisible = () => {
        setIsAppointmentFormVisible(!isAppointmentFormVisible);
    };

    const handleAppointmentFormSubmit = (formData, isSaveOnPatientPortal) => {
        toggleAppointmentFormVisible();
        onAppointmentSet(formData, isSaveOnPatientPortal);
    };

    // let isShowIconText = true;
    // if (breakpoint.isNormalMobile) {
    //     isShowIconText = false;
    // } else if (breakpoint.isTabletPortrait) {
    //     isShowIconText = true;
    // } else if (breakpoint.isTabletLandscape) {
    //     isShowIconText = false;
    // }

    return appointmentDetails ? (
        <Tooltip title="Patient was already appointed">
            <Button size="small" type="link" block disabled>
                <Icon type="calendar" />
                <br />
                Appoint Patient
            </Button>
        </Tooltip>
    ) : (
        <>
            <Drawer
                title="Appointment Form"
                placement="right"
                visible={isAppointmentFormVisible}
                onClose={toggleAppointmentFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <AppointmentForm
                    data={{
                        patient_id: patientDetails.patient_id,
                        patient_fullname: `${patientDetails.lastname} ${patientDetails.firstname} ${patientDetails.middlename}`,
                        patient_img_path: patientDetails.img_path,
                        appointment_datetime: null,
                        details: "Consultation - Checkup",
                        doctor_id: doctorID,
                        case_id: caseID,
                        status: "pending",
                        is_from_cwapp: isFromCWAPP
                    }}
                    caseID={caseID}
                    breakpoint={breakpoint}
                    onSubmit={handleAppointmentFormSubmit}
                />
            </Drawer>

            <div onClick={toggleAppointmentFormVisible}>
                <Icon type="calendar" />
                <br />
                {breakpoint.isNormalMobile ? "Appoint" : "Appoint Patient"}
            </div>

            {/* <Button type="link" block onClick={toggleAppointmentFormVisible}>
                <Icon type="calendar" /> Appoint Patient
            </Button> */}
            {/* <Tooltip title="Set Appointment">
                <Button size="large" onClick={toggleAppointmentFormVisible}>
                    <Icon type="calendar" />
                </Button>
            </Tooltip> */}
        </>
    );
};

export default AppointmentDrawer;
