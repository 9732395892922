import {
    SET_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_SUCCESS,
} from "./settings.types";

export const setSettings = (settings) => async (dispatch) => {
    const {
        age_format,
        theme,
        default_displayed_case_note,
        pnform_autocomplete_auto_add,
        patient_form,
        patient_history_form,
        patient_card_items,
        vital_signs_form,
        prescription_note_form,
        prescription_writing,
        soap_enabled_img_manager,
        previleges: user_previleges,
        case_list,
        appointment_list,
        notifications,
    } = settings;

    const newSettings = {
        theme,
        ageFormat: age_format,
        isSoundEnabled: true,
        pnformAutoCompleteAutoAdd: pnform_autocomplete_auto_add,
        defaultDisplayedCaseNote: default_displayed_case_note,
        patientForm: JSON.parse(patient_form),
        patientHistoryForm: JSON.parse(patient_history_form),
        vitalSignsForm: JSON.parse(vital_signs_form),
        patientCardItems: JSON.parse(patient_card_items),
        prescriptionNoteForm: JSON.parse(prescription_note_form),
        prescriptionWriting: JSON.parse(prescription_writing),
        soapEnabledImgManager: JSON.parse(soap_enabled_img_manager),
        userPrivileges: JSON.parse(user_previleges),
        caseList: JSON.parse(case_list),
        appointmentList: JSON.parse(appointment_list),
        notifications: JSON.parse(notifications),
    };

    dispatch({
        type: SET_SETTINGS_SUCCESS,
        payload: settings ? { newSettings } : null,
    });
};

export const updateSettings = (updatedSettings) => async (dispatch) => {
    dispatch({
        type: UPDATE_SETTINGS_SUCCESS,
        payload: { updatedSettings },
    });
};
