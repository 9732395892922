import React from "react";
import { Layout } from "antd";

const Footer = ({ breakpoint }) => {
    const { Footer } = Layout;

    return (
        <Footer
            style={
                breakpoint.isNormalMobile
                    ? { padding: "40px 12px 12px" }
                    : { padding: "12px 16px" }
            }
        >
            {/* <Row type="flex" justify="space-between">
                <Col>©2020 CWA. All rights reserved.</Col>
                <Col>
                    <a href="https://ckeditor.com/legal/">Legal</a> |{" "}
                    <a href="https://ckeditor.com/legal/privacy-policy/">
                        Private Policy
                    </a>
                </Col>
            </Row> */}
            <div
                style={{
                    textAlign: breakpoint.isNormalMobile ? "center" : "left",
                }}
            >
                &copy; 2020 CWA. All rights reserved.&nbsp;&nbsp;
                {breakpoint.isNormalMobile && <br />}
                <a href="https://cwassistant.com/src/docs/terms%20of%20agreement.pdf">
                    Legal
                </a>{" "}
                |{" "}
                <a href="https://cwassistant.com/src/docs/privacy%20policy.pdf">
                    Private Policy
                </a>
            </div>
        </Footer>
    );
};

export default Footer;
