import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    DatePicker,
    Table,
    Button,
    Input,
    Icon,
    Tag,
    Tooltip,
    Card,
    Divider,
} from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const AppointmentsReport = ({
    doctors,
    breakpoint,
    clinicID,
    appointmentListSettings,
    setRequestError,
}) => {
    const [
        appointmentsReportTableLoading,
        setAppointmentsReportTableLoading,
    ] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const { RangePicker } = DatePicker;
    // const ButtonGroup = Button.Group;

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleAppointmentDateRangeChange = async (date, dateString) => {
        const [dateStart, dateEnd] = dateString;
        if (dateStart !== "" && dateEnd !== "") {
            try {
                setAppointmentsReportTableLoading(true);
                const response = await http.get(
                    `${apiUrl}/report/appointments/${clinicID}/${dateStart}/${dateEnd}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                setAppointments(response.data.appointments);
                setTableData(response.data.appointments);
                setAppointmentsReportTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        } else {
            setAppointments([]);
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setTableData(extra.currentDataSource);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const appointmentsReportColumns = [
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            ...getColumnSearchProps("patient_name"),
            sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Appointment Date",
            dataIndex: "appointment_datetime",
            sorter: (a, b) =>
                new Date(moment(a.appointment_datetime).format("LLL")) -
                new Date(moment(b.appointment_datetime).format("LLL")),
            render: (text, record) =>
                moment(record.appointment_datetime).format("LLL"),
        },
        {
            title: "Details",
            dataIndex: "details",
        },
        {
            title: "Doctor",
            dataIndex: "doctor_id",
            render: (doctor_id) => findUserName(doctor_id),
            filters: [
                ...doctors
                    .filter((doctor) => doctor.status === "active")
                    .map((doctor) => ({
                        text: doctor.doctor_name,
                        value: doctor.doctor_id,
                    })),
            ],
            //   filterMultiple: false,
            onFilter: (value, record) => {
                return record.doctor_id === value;
            },
            //sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
            //sortDirections: ["descend", "ascend"]
        },
        {
            title: "Created By",
            dataIndex: "user_id",
            render: (user_id) => findUserName(user_id),
        },
        {
            title: "Date Created",
            dataIndex: "created_at",
            sorter: (a, b) =>
                new Date(moment(a.created_at).format("LLL")) -
                new Date(moment(b.created_at).format("LLL")),
            render: (text, record) => moment(record.created_at).format("LLL"),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (status) => {
                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor =
                            appointmentListSettings.pendingStatusColor;
                        break;
                    case "canceled":
                        statusColor =
                            appointmentListSettings.canceledStatusColor;
                        break;
                    case "confirmed":
                        statusColor =
                            appointmentListSettings.confirmedStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }

                return (
                    <span>
                        <Tag color={statusColor}>{status}</Tag>
                    </span>
                );
            },
            filters: [
                {
                    text: "Pending",
                    value: "pending",
                },
                {
                    text: "Confirmed",
                    value: "confirmed",
                },
                {
                    text: "Canceled",
                    value: "canceled",
                },
            ],
            onFilter: (value, record) => record.status === value,
        },
    ];

    const CSVHeaders = [
        {
            label: "Patient Name",
            key: "patient_name",
        },
        {
            label: "Appointment Date",
            key: "appointment_datetime",
        },
        {
            label: "Details",
            key: "details",
        },
        {
            label: "Doctor",
            key: "doctor_name",
        },
        {
            label: "Created By",
            key: "created_by",
        },
        {
            label: "Date Created",
            key: "created_at",
        },
        {
            label: "Status",
            key: "status",
        },
    ];

    const CSVData = tableData.map((appointment) => ({
        ...appointment,
        appointment_datetime: moment(appointment.appointment_datetime).format(
            "LL"
        ),
        created_at: moment(appointment.created_at).format("LL"),
    }));

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            <div style={{ marginBottom: 16 }}>
                {isMobileBreakpoint ? (
                    <div
                        style={{
                            marginBottom: 5,
                        }}
                    >
                        Select Date Range:
                    </div>
                ) : (
                    "Select Date Range: "
                )}

                <RangePicker onChange={handleAppointmentDateRangeChange} />
            </div>
            <div
                style={{
                    marginBottom: 16,
                    minWidth: 320,
                    overflow: "auto",
                    backgroundColor: "#fff",
                }}
            >
                <Table
                    columns={appointmentsReportColumns}
                    dataSource={appointments}
                    rowKey={"appointment_id"}
                    loading={appointmentsReportTableLoading}
                    pagination={{
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        simple: isMobileBreakpoint ? true : false,
                    }}
                    className="reportsTable"
                    onChange={handleTableChange}
                />
            </div>

            <div style={{ textAlign: "right" }}>
                <CSVLink
                    data={CSVData}
                    headers={CSVHeaders}
                    filename={"appointments.csv"}
                >
                    <Tooltip title="Export to Excel">
                        <Button
                            style={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                border: "1px solid #4caf50",
                            }}
                        >
                            <Icon type="download" /> Export to CSV
                        </Button>
                    </Tooltip>
                </CSVLink>
            </div>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    doctors: state.UserDetails.doctors,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    appointmentListSettings: state.Settings.appointmentList,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppointmentsReport)
);
