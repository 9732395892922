function titleCase(str) {
    return str
        .toLowerCase()
        .split(" ")
        .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
}

function upperCase(str) {
    return str.toUpperCase();
}

function lowerCase(str) {
    return str.toLowerCase();
}

export { titleCase, upperCase, lowerCase };
