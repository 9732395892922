import React, { useState } from "react";
import { Table, Checkbox } from "antd";

const PrescriptionWritingTable = ({ writings, onRowSelect }) => {
    const [inputCheckboxes, setInputCheckboxes] = useState({});

    const handleCheckboxChange = e => {
        const { name, checked } = e.target;
        const newInputCheckboxes = {};
        Object.keys(inputCheckboxes).forEach(checkboxName => {
            newInputCheckboxes[checkboxName] = false;
        });
        setInputCheckboxes({ ...newInputCheckboxes, [name]: checked });
        onRowSelect(checked ? name : null);
    };

    const PrescriptionWritingTableColumns = [
        {
            title: "",
            key: "checkbox",
            width: 50,
            render: (text, record) => {
                return (
                    <Checkbox
                        name={record.name}
                        checked={inputCheckboxes[record.name]}
                        onChange={handleCheckboxChange}
                    />
                );
            }
        },
        {
            title: "Label",
            dataIndex: "label"
        },
        {
            title: "Sample Value",
            dataIndex: "value"
        },
        {
            title: "Prefix",
            dataIndex: "prefix"
        },
        {
            title: "Suffix",
            dataIndex: "suffix"
        },
        {
            title: "Style",
            dataIndex: "style"
            // key: "action",
            // width: breakpoint.isNormalMobile ? 50 : 170,
            // render: (text, record) => (
            //     <ActionMenu
            //         menu={[
            //             {
            //                 label: "Edit",
            //                 icon: "edit",
            //                 onClick: () => showHmoForm(record)
            //             },
            //             {
            //                 label: "Delete",
            //                 icon: "delete",
            //                 onClick: () => handleHmoDelete(record)
            //             }
            //         ]}
            //         layout="responsive"
            //         breakpoint={breakpoint.isNormalMobile}
            //     />
            // )
        }
    ];

    return (
        <Table
            columns={PrescriptionWritingTableColumns}
            dataSource={writings}
            pagination={false}
            size="small"
            rowKey="name"
        />
    );
};

export default PrescriptionWritingTable;
