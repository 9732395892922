import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import Media from "react-media";

import Login from "views/Login/Index";
import Register from "views/Register/Index";
import ForgotPassword from "views/ForgotPassword";
import EmailVerified from "views/EmailVerified";
import Queueing from "views/Queueing/Index";
import Main from "layouts/Main";

import MyAccount from "views/MyAccount/Index";
import ProfileCompletion from "views/MyAccount/components/ProfileCompletion/Index";

import Dashboard from "views/Dashboard/Index";
import Cases from "views/Cases/Index";
import CaseCreate from "views/Cases/CaseCreate";
import CaseNote from "views/CaseNote/Index";
import Patients from "views/Patients/Index";
import PatientCreate from "views/Patients/PatientCreate";
import PatientEdit from "views/Patients/PatientEdit";
import PatientCaseList from "views/Patients/PatientCaseList";
import Appointments from "views/Appointments/Index";
import Items from "views/Items/Index";
import Billing from "views/Billing/Index";
import Hmo from "views/Hmo/Index";
import Groups from "views/Groups/Index";
import Papers from "views/Papers/Index";

import Reports from "views/Reports/Index";
import CasesReport from "views/Reports/Cases/Index";
import AppointmentsReport from "views/Reports/Appointment/Index";
import PatientsReport from "views/Reports/Patient/Index";
import ItemsReport from "views/Reports/Item/Index";
import ItemLedgerReport from "views/Reports/ItemLedger/Index";
import BillingReport from "views/Reports/Billing/Index";
import HMOReport from "views/Reports/Hmo/Index";
import ActivityLogReport from "views/Reports/ActivityLog/Index";
import PatientPortalPayment from "views/Reports/PatientPortalPayment/Index";
import PatientPortalPayout from "views/Reports/PatientPortalPayout/Index";

import Settings from "views/Settings/Index";
import GeneralSettings from "views/Settings/General/Index";
import PatientFormSettings from "views/Settings/PatientForm/Index";
import PatientHistoryFormSettings from "views/Settings/PatientHistoryForm/Index";
import VitalSignsSettings from "views/Settings/VitalSigns/Index";
import PatientCardSettings from "views/Settings/PatientCard/Index";
import PrescriptionNoteSettings from "views/Settings/PrescriptionNote/Index";
import PrescriptionWritingSettings from "views/Settings/PrescriptionWriting/Index";
import UserPriviledgesSettings from "views/Settings/UserPrevileges/Index";
import SOAPImageManager from "views/Settings/SOAPImageManager/Index";
import DoctorSignature from "views/Settings/DoctorSignature/Index";
import QueueingSettings from "views/Settings/Queueing/Index";
import CaseListSettings from "views/Settings/CaseList/Index";
import AppointmentListSettings from "views/Settings/AppointmentList/Index";
import NotificationsSettings from "views/Settings/Notifications/Index";

import Notifications from "views/Notifications";
import ChangeLog from "views/ChangeLog";

import PageNotFound from "views/PageNotFound";
import PrivateRoute from "globalComponents/ProtectedRoute";

import { appBasePath } from "config.json";

const App = () => {
    const mainRoutes = [
        {
            title: "My Account",
            path: "/account",
            component: MyAccount
        },
        {
            title: "My Profile",
            path: "/profile",
            component: ProfileCompletion
        },
        {
            title: "Dashboard",
            path: "/dashboard",
            component: Dashboard
        },
        {
            title: "Case List",
            path: "/cases",
            component: Cases
        },
        {
            title: "Create Case",
            path: "/case/create",
            component: CaseCreate
        },
        {
            title: "Create Case",
            path: "/case/create/:patient_id",
            component: CaseCreate
        },
        {
            title: "Create Case",
            path: "/case/create/:patient_id/:appointment_id",
            component: CaseCreate
        },
        {
            title: "Case Note",
            path: "/casenote/:case_id",
            component: CaseNote
        },
        {
            title: "Patients",
            path: "/patients",
            component: Patients
        },
        {
            title: "Create Patient",
            path: "/patient/create",
            component: PatientCreate
        },
        {
            title: "Edit Patient",
            path: "/patient/edit/:id",
            component: PatientEdit
        },
        {
            title: "Patient Cases",
            path: "/patient/cases/:patient_id",
            component: PatientCaseList
        },
        {
            title: "Appointments",
            path: "/appointments",
            component: Appointments
        },
        {
            title: "Items and Services",
            path: "/items",
            component: Items
        },
        {
            title: "Billing",
            path: "/billing",
            component: Billing
        },
        {
            title: "Billing",
            path: "/billing/:patient_id",
            component: Billing
        },
        {
            title: "Billing",
            path: "/billing/:patient_id/:bill_id",
            component: Billing
        },
        {
            title: "Billing",
            path: "/billing/:patient_id/case/:case_id",
            component: Billing
        },
        {
            title: "Accredited Hmo",
            path: "/hmo",
            component: Hmo
        },
        {
            title: "Patient Groups",
            path: "/groups",
            component: Groups
        },
        {
            title: "Papers",
            path: "/papers",
            component: Papers
        },
        {
            title: "Reports",
            path: "/reports",
            component: Reports
        },
        {
            title: "Settings Menu",
            path: "/settings",
            component: Settings
        },
        {
            title: "General Settings",
            path: "/settings/generalSettings",
            component: GeneralSettings
        },
        {
            title: "Patient Form Settings",
            path: "/settings/patientForm",
            component: PatientFormSettings
        },
        {
            title: "Patient History Form Settings",
            path: "/settings/patientHistoryForm",
            component: PatientHistoryFormSettings
        },
        {
            title: "Vital Signs Settings",
            path: "/settings/vitalSigns",
            component: VitalSignsSettings
        },
        {
            title: "Patient Card Settings",
            path: "/settings/patientCard",
            component: PatientCardSettings
        },
        {
            title: "Prescription Note Settings",
            path: "/settings/prescriptionNote",
            component: PrescriptionNoteSettings
        },
        {
            title: "Prescription Writing Settings",
            path: "/settings/prescriptionWriting",
            component: PrescriptionWritingSettings
        },
        {
            title: "SOAP Image Manager",
            path: "/settings/soapImgManager",
            component: SOAPImageManager
        },
        {
            title: "User Privileges",
            path: "/settings/userPrivileges",
            component: UserPriviledgesSettings
        },
        {
            title: "Doctor's Signature",
            path: "/settings/doctorsSignature",
            component: DoctorSignature
        },
        {
            title: "Queueing Display Settings",
            path: "/settings/queueing",
            component: QueueingSettings
        },
        {
            title: "Case List Settings",
            path: "/settings/caseList",
            component: CaseListSettings
        },
        {
            title: "Appointment List Settings",
            path: "/settings/appointmentList",
            component: AppointmentListSettings
        },
        {
            title: "Notifications Settings",
            path: "/settings/notifications",
            component: NotificationsSettings
        },
        {
            title: "Cases Report",
            path: "/reports/cases",
            component: CasesReport
        },
        {
            title: "Patients Report",
            path: "/reports/patients",
            component: PatientsReport
        },
        {
            title: "Appointments Report",
            path: "/reports/appointments",
            component: AppointmentsReport
        },
        {
            title: "Items Report",
            path: "/reports/items",
            component: ItemsReport
        },
        {
            title: "Item Ledger Report",
            path: "/reports/itemLedger",
            component: ItemLedgerReport
        },
        {
            title: "Billing Report",
            path: "/reports/billing",
            component: BillingReport
        },
        {
            title: "HMO Report",
            path: "/reports/hmo",
            component: HMOReport
        },
        {
            title: "Activity Log Report",
            path: "/reports/activityLog",
            component: ActivityLogReport
        },
        {
            title: "Patient Portal Payments",
            path: "/reports/pp/payments",
            component: PatientPortalPayment
        },
        {
            title: "Patient Portal Payouts",
            path: "/reports/pp/payouts",
            component: PatientPortalPayout
        },
        {
            title: "Notifications",
            path: "/notifications",
            component: Notifications
        },
        {
            title: "Change Log",
            path: "/changeLog",
            component: ChangeLog
        }
    ];

    return (
        <Media
            queries={{
                xxs: "(max-width: 320px)", // iphone 5s (small)
                xs: "(max-width: 480px)", // 5.5 inches phones (medium)
                sm: "(max-width: 576px)", // 5.5 inches phones (medium)
                md: "(max-width: 768px)", // tablet portrait
                lg: "(max-width: 992px)", // tablet portrait
                xl: "(max-width: 1200px)", // tablet landscape
                xxl: "(max-width: 1600px)" //desktops
            }}
        >
            {matches => (
                <Router basename={appBasePath}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect from="/" to="/login" />
                        </Route>
                        <Route path="/login" component={Login} />
                        <Route
                            path="/forgotPassword"
                            component={ForgotPassword}
                        />
                        <Route path="/register/:plan" component={Register} />
                        <Route
                            path="/verifyEmail/:verification_id"
                            component={EmailVerified}
                        />
                        <Route
                            path="/queueing/:owner_id/:clinic_id/:doctor_id"
                            component={Queueing}
                        />
                        <Route path="/not-found" component={PageNotFound} />
                        <Main
                            breakpoint={{
                                isSmallMobile: matches.xxs,
                                isNormalMobile: matches.xs || matches.sm,
                                isTabletPortrait: matches.md || matches.lg,
                                isTabletLandscape: matches.xl
                            }}
                        >
                            <Switch>
                                {mainRoutes.map((route, index) => (
                                    <PrivateRoute
                                        key={index}
                                        exact
                                        path={route.path}
                                        component={route.component}
                                        title={route.title}
                                        breakpoint={{
                                            isSmallMobile: matches.xxs,
                                            isNormalMobile:
                                                matches.xs || matches.sm,
                                            isTabletPortrait:
                                                matches.md || matches.lg,
                                            isTabletLandscape: matches.xl
                                        }}
                                    />
                                ))}
                                <Route
                                    render={() => <Redirect to="/not-found" />}
                                />
                            </Switch>
                        </Main>
                    </Switch>
                </Router>
            )}
        </Media>
    );
};

export default App;
