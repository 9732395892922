import React from "react";
import PatientForm from "./components/Forms/PatientForm/Index";

const PatientCreate = ({ breakpoint }) => {
    return (
        <>
            <PatientForm
                patientData={null}
                dataEditing={false}
                breakpoint={breakpoint}
            />
            &nbsp;
        </>
    );
};

export default PatientCreate;
