import React from "react";
import { connect } from "react-redux";
import { Select } from "antd";

const ItemSearchBar = (props) => {
    const { items, onChange, disabled, clinicID } = props;
    const { Option, OptGroup } = Select;
    const products = items.filter(
        (item) => item.item_type === "product" && clinicID === item.clinic_id
    );
    const services = items.filter(
        (item) => item.item_type === "service" && clinicID === item.clinic_id
    );

    const handleSelectChange = (value) => {
        if (value) {
            onChange(value);
        }
    };
    return (
        <Select
            showSearch
            placeholder="Select Item to add"
            optionLabelProp="label"
            optionFilterProp="label"
            allowClear={true}
            onChange={handleSelectChange}
            style={{ width: "100%" }}
            disabled={disabled}
        >
            <OptGroup label="ITEMS" key="product">
                {products
                    .filter((item) => item.status === "active")
                    .map((item) => (
                        <Option
                            key={item.item_id}
                            value={item.item_id}
                            label={`${item.name} (${item.generic_name})  `}
                            disabled={item.stock_qty < 1}
                        >
                            <div>
                                <h4
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                >
                                    {item.name}
                                </h4>
                                {item.generic_name.trim()
                                    ? `(${item.generic_name})`
                                    : null}
                                <div style={{ color: "red" }}>
                                    {item.stock_qty < 1 && "Out of stock"}
                                </div>
                            </div>
                        </Option>
                    ))}
            </OptGroup>

            <OptGroup label="SERVICES" key="service">
                {services
                    .filter((item) => item.status === "active")
                    .map((item) => (
                        <Option
                            key={item.item_id}
                            value={item.item_id}
                            label={item.name}
                        >
                            <div>
                                <h4
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                >
                                    {item.name}
                                </h4>
                            </div>
                        </Option>
                    ))}
            </OptGroup>
        </Select>
    );
};

const mapStateToProps = (state) => ({
    items: state.Items,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

export default connect(mapStateToProps)(ItemSearchBar);
