import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Button, Divider, message, Switch, Card } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const SOAPImageManager = ({
    SEnabledImgManager,
    clinicID,
    updateSettings,
    setRequestError,
}) => {
    const [soapEnabledImgManager, setSoapEnabledImgManager] = useState(
        SEnabledImgManager
    );
    const [
        isSEnabledImgManagerEdited,
        setIsSEnabledImgManagerEdited,
    ] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isSEnabledImgManagerEdited]);

    function beforeunload(e) {
        if (isSEnabledImgManagerEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleSwitchChange = (name, checked) => {
        setSoapEnabledImgManager({
            ...soapEnabledImgManager,
            [name]: checked ? true : false,
        });
        setIsSEnabledImgManagerEdited(true);
    };

    const handleSOAPEnabledImgManagersSave = async () => {
        try {
            updateSettings({ soapEnabledImgManager });
            setIsSEnabledImgManagerEdited(false);
            message.success("SOAP image manager settings successfully saved!");
            const data = JSON.stringify(soapEnabledImgManager);
            await http.put(`${apiUrl}/settings/${clinicID}`, {
                soap_enabled_img_manager: data,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message,
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 150 }}>Subjective</td>
                        <td>
                            <Switch
                                checked={soapEnabledImgManager.subjective}
                                onChange={(checked) =>
                                    handleSwitchChange("subjective", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Objective</td>
                        <td>
                            <Switch
                                checked={soapEnabledImgManager.objective}
                                onChange={(checked) =>
                                    handleSwitchChange("objective", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Assessment</td>
                        <td>
                            <Switch
                                checked={soapEnabledImgManager.assessment}
                                onChange={(checked) =>
                                    handleSwitchChange("assessment", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Plan</td>
                        <td>
                            <Switch
                                checked={soapEnabledImgManager.plan}
                                onChange={(checked) =>
                                    handleSwitchChange("plan", checked)
                                }
                            />
                        </td>
                    </tr>
                    {/* <tr>
                        <td>Plain Notes</td>
                        <td>
                            <Switch
                                checked={soapEnabledImgManager.plainNotes}
                                onChange={(checked) =>
                                    handleSwitchChange("plainNotes", checked)
                                }
                            />
                        </td>
                    </tr> */}
                </tbody>
            </table>

            <Divider />

            <Button
                type="primary"
                size="large"
                onClick={handleSOAPEnabledImgManagersSave}
            >
                Save SOAP Image Manager
            </Button>

            <Prompt
                when={isSEnabledImgManagerEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    SEnabledImgManager: state.Settings.soapEnabledImgManager,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOAPImageManager);
