import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Button, Divider, message, Card, Input, Form, InputNumber } from "antd";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Announcement = ({
    breakpoint,
    queueingDetails,
    clinicID,
    setRequestError,
}) => {
    const [announcementText, setAnnouncementText] = useState("");
    const [announcementSpeed, setAnnouncementSpeed] = useState(1);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setAnnouncementText(queueingDetails.data.announcement_text);
        setAnnouncementSpeed(queueingDetails.data.announcement_speed);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, [queueingDetails]);

    const handleInputChange = (e) => {
        setAnnouncementText(e.target.value);
    };

    const handleInputNumberChange = (value) => {
        setAnnouncementSpeed(value);
    };

    const saveAndApply = async () => {
        try {
            setIsSaving(true);
            await http.put(
                `${apiUrl}/queueDetails/${clinicID}`,
                {
                    announcement_text: announcementText,
                    announcement_speed: announcementSpeed,
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                }
            );
            setIsSaving(false);
            message.success("Successfully Saved");

            socket.emit("reloadQueueing", clinicID);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    return (
        <Card title="Announcement" style={{ marginBottom: 10 }}>
            <Form
                layout="horizontal"
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
            >
                <Form.Item label="Announcement Text">
                    <Input
                        style={{
                            width: breakpoint.isNormalMobile ? "100%" : 400,
                        }}
                        value={announcementText}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Announcement Speed">
                    <InputNumber
                        value={announcementSpeed}
                        min={1}
                        onChange={handleInputNumberChange}
                    />
                    <br />
                    (The higher the slower)
                </Form.Item>
            </Form>
            {/* <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td>Announcement Text:</td>
                        <td>
                            <Input
                                style={{ width: 400 }}
                                value={announcementText}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Announcement Speed:</td>
                        <td>
                            <InputNumber
                                value={announcementSpeed}
                                min={1}
                                onChange={handleInputNumberChange}
                            />
                            <br />
                            (The higher the slower)
                        </td>
                    </tr>
                </tbody>
            </table> */}
            <Divider />
            <Button
                type="primary"
                size="large"
                loading={isSaving}
                onClick={saveAndApply}
            >
                {isSaving ? "Saving" : "Save and Apply"}
            </Button>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
