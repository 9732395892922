import React, { useState } from "react";
import { connect } from "react-redux";

import { addHmo, editHmo, deleteHmo } from "redux/hmo/hmo.actions";
import { setRequestError } from "redux/system/system.actions";

import { Button, Icon, Empty, Table, Modal, message } from "antd";
import Drawer from "globalComponents/Drawer";
import ActionMenu from "globalComponents/ActionMenu";
import HmoForm from "./components/Forms/HmoForm";

import { generateString } from "helpers/randomStringGenerator";
import http from "services/httpService";
import { apiUrl } from "config.json";

const Hmo = ({
    breakpoint,
    accreditedHmo,
    addHmo,
    editHmo,
    deleteHmo,
    setRequestError
}) => {
    const [isHmoFormVisible, setIsHmoFormVisible] = useState(false);
    const [selectedHmo, setSelectedHmo] = useState(null);

    const { confirm } = Modal;

    const toggleHmoFormVisible = () => {
        setIsHmoFormVisible(!isHmoFormVisible);
    };

    const showHmoForm = (selectedRecord = null) => {
        setSelectedHmo(selectedRecord);
        toggleHmoFormVisible();
    };

    const handleHmoFormSubmit = async formData => {
        try {
            if (selectedHmo) {
                //edit
                editHmo(formData);
                toggleHmoFormVisible();
                message.success("Hmo successfully updated!");
                await http.put(`${apiUrl}/hmo/${formData.hmo_id}`, formData);
            } else {
                //add
                const hmoID = generateString(5);
                const newFormData = { hmo_id: hmoID, ...formData };
                addHmo(newFormData);
                toggleHmoFormVisible();
                message.success("Hmo successfully added!");
                await http.post(`${apiUrl}/hmo`, newFormData);
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleHmoDelete = ({ hmo_id, name }) => {
        confirm({
            title: `Are you sure do you want to delete hmo ${name}?`,
            content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve(deleteHmo(hmo_id));
                    try {
                        await http.put(`${apiUrl}/hmo/${hmo_id}`, {
                            status: "inactive"
                        });
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const accreditedHmoTableColumns = [
        {
            title: "HMO Name",
            dataIndex: "name"
        },
        {
            title: "",
            key: "action",
            width: breakpoint.isNormalMobile ? 50 : 170,
            render: (text, record) => (
                <ActionMenu
                    menu={[
                        {
                            label: "Edit",
                            icon: "edit",
                            onClick: () => showHmoForm(record)
                        },
                        {
                            label: "Delete",
                            icon: "delete",
                            onClick: () => handleHmoDelete(record)
                        }
                    ]}
                    layout="responsive"
                    breakpoint={breakpoint.isNormalMobile}
                />
            )
        }
    ];

    return (
        <>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    onClick={() => showHmoForm()}
                    style={{ marginBottom: 10 }}
                >
                    <Icon type="plus" />
                    Add Hmo
                </Button>
            </div>

            {accreditedHmo.length === 0 ? (
                <Empty description="No accredited Hmo found." />
            ) : (
                <div style={{ backgroundColor: "#fff" }}>
                    <Table
                        columns={accreditedHmoTableColumns}
                        dataSource={accreditedHmo}
                        rowKey="hmo_id"
                    />
                </div>
            )}

            <Drawer
                title={selectedHmo ? "Edit Hmo" : "Create Hmo"}
                placement="right"
                visible={isHmoFormVisible}
                onClose={toggleHmoFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <HmoForm data={selectedHmo} onSubmit={handleHmoFormSubmit} />
            </Drawer>
        </>
    );
};

const mapStateToProps = state => ({
    accreditedHmo: state.Hmo
});

const mapDispatchToProps = dispatch => ({
    addHmo: newHmo => dispatch(addHmo(newHmo)),
    editHmo: editedHmo => dispatch(editHmo(editedHmo)),
    deleteHmo: hmoID => dispatch(deleteHmo(hmoID)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Hmo);
