import React from "react";
import { Avatar, Button, Icon, Upload } from "antd";

const ImageUpload = ({ image, previewImage, width }) => {
    const ButtonGroup = Button.Group;

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleBeforeUpload = (file) => {
        return false;
    };

    const handleChange = (info) => {
        getBase64(
            info.fileList[info.fileList.length - 1].originFileObj,
            (imageUrl) => {
                previewImage(imageUrl);
            }
        );
    };

    return (
        <div style={{ textAlign: "center", marginBottom: 15 }}>
            {image ? (
                <Avatar shape="square" size={width || 150} src={image} />
            ) : (
                <Avatar shape="square" size={width || 150} icon="picture" />
            )}
            <br />
            <ButtonGroup style={{ marginTop: 5 }}>
                <Upload
                    onChange={handleChange}
                    beforeUpload={handleBeforeUpload}
                    showUploadList={false}
                >
                    <Button>
                        <Icon type="upload" /> Upload
                    </Button>
                </Upload>
            </ButtonGroup>
        </div>
    );
};

export default ImageUpload;
