import {
    SET_USER_DETAILS_SUCCESS,
    ADD_DOCTOR_SUCCESS,
    EDIT_DOCTOR_SUCCESS,
    EDIT_DOCTOR_PREVILEGES,
    DELETE_DOCTOR_SUCCESS,
    ADD_STAFF_SUCCESS,
    EDIT_STAFF_SUCCESS,
    DELETE_STAFF_SUCCESS,
    EDIT_CLINIC_SUCCESS,
    EDIT_USER_DETAILS_SUCCESS,
} from "./userDetails.types";

const INIT_STATE = {
    user_id: "",
    firstname: "",
    lastname: "",
    img_path: "",
    clinicDetails: {},
    masterSettings: {},
    subscriptionDetails: {},
    uploadDetails: {},
};

const userDetailsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USER_DETAILS_SUCCESS:
            return action.payload.userDetails;
        case ADD_DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: [...state.doctors, action.payload.newDoctor],
            };
        case EDIT_DOCTOR_SUCCESS:
            if (state.user_id === action.payload.editedDoctor.doctor_id) {
                return {
                    ...state,
                    firstname: action.payload.editedDoctor.firstname,
                    lastname: action.payload.editedDoctor.lastname,
                    degree: action.payload.editedDoctor.degree,
                    is_data_completed:
                        action.payload.editedDoctor.is_data_completed,
                    doctors: state.doctors.map((doctor) => {
                        if (
                            doctor.doctor_id ===
                            action.payload.editedDoctor.doctor_id
                        ) {
                            return {
                                ...doctor,
                                ...action.payload.editedDoctor,
                            };
                        }
                        return doctor;
                    }),
                };
            } else {
                return {
                    ...state,
                    doctors: state.doctors.map((doctor) => {
                        if (
                            doctor.doctor_id ===
                            action.payload.editedDoctor.doctor_id
                        ) {
                            return {
                                ...doctor,
                                ...action.payload.editedDoctor,
                            };
                        }
                        return doctor;
                    }),
                };
            }
        case EDIT_DOCTOR_PREVILEGES:
            return {
                ...state,
                doctors: state.doctors.map((doctor) => {
                    if (doctor.doctor_id === action.payload.doctorID) {
                        return {
                            ...doctor,
                            settings: {
                                ...doctor.settings,
                                previleges: action.payload.newPrevileges,
                            },
                        };
                    }
                    return doctor;
                }),
            };
        case DELETE_DOCTOR_SUCCESS:
            return {
                ...state,
                doctors: state.doctors.filter(
                    (doctor) => doctor.doctor_id !== action.payload.doctorID
                ),
            };
        case ADD_STAFF_SUCCESS:
            return {
                ...state,
                staffs: [...state.staffs, action.payload.newStaff],
            };
        case EDIT_STAFF_SUCCESS:
            return {
                ...state,
                staffs: state.staffs.map((staff) => {
                    if (
                        staff.staff_id === action.payload.editedStaff.staff_id
                    ) {
                        return {
                            ...staff,
                            ...action.payload.editedStaff,
                        };
                    }
                    return staff;
                }),
            };
        case DELETE_STAFF_SUCCESS:
            return {
                ...state,
                staffs: state.staffs.filter(
                    (staff) => staff.staff_id !== action.payload.staffID
                ),
            };
        case EDIT_CLINIC_SUCCESS:
            return {
                ...state,
                clinicDetails: {
                    ...state.clinicDetails,
                    ...action.payload.editedClinic,
                },
            };
        case EDIT_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ...action.payload.editedUserDetails,
            };
        default:
            return state;
    }
};

export default userDetailsReducer;
