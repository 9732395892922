import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import {
    Card,
    Avatar,
    Tag,
    Skeleton,
    message,
    Empty,
    Icon,
    Tooltip
} from "antd";

import Drawer from "globalComponents/Drawer";
import ContentLoader from "globalComponents/ContentLoader";
import PatientForm from "views/Patients/components/Forms/PatientForm/Index";

import moment from "moment";
import { formatAge } from "helpers/ageFormatter";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PatientCard = ({
    patientID,
    moreDetailsVisible,
    showEditPatientInfoBtn,
    bordered: isBordered,
    extraActions,
    breakpoint,
    onNotFound,
    onRequestDone,
    patientCardItems,
    ageFormat,
    clinicID,
    plan
}) => {
    const [patientDetails, setPatientDetails] = useState({
        patient_id: patientID,
        other_info: {}
    });
    const [isPatientDetailsLoading, setIsPatientDetailsLoading] =
        useState(false);
    const [isMoreDetailsVisible, setIsMoreDetailsVisible] =
        useState(moreDetailsVisible);
    const [isNoPatient, setIsNoPatient] = useState(false);

    //for patient edit form
    const [patientData, setPatientData] = useState(null);
    const [isPatientFormContentLoading, setIsPatientFormContentLoading] =
        useState(false);
    const [isPatientFormVisible, setIsPatientFormVisible] = useState(false);

    useEffect(() => {
        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, [patientID]);

    async function fetchData() {
        try {
            if (patientID) {
                setIsPatientDetailsLoading(true);

                const response = await http.get(
                    `${apiUrl}/patientCardDetails/${patientID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                const { patientDetails } = response.data;

                if (patientDetails) {
                    patientDetails.other_info = JSON.parse(
                        patientDetails.other_info
                    );
                    setPatientDetails(patientDetails);
                    setIsNoPatient(false);
                    setIsPatientDetailsLoading(false);
                    //used in billing and modals
                    if (onRequestDone) {
                        onRequestDone(patientDetails);
                    }
                } else {
                    onNotFound();
                    setIsNoPatient(true);
                    message.error("Patient not found!");
                }
            } else {
                setIsNoPatient(true);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                message.error(error.message);
            }
        }
    }

    const toggleMorePatientDetailsVisible = () => {
        setIsMoreDetailsVisible(!isMoreDetailsVisible);
    };

    const togglePatientFormVisible = () => {
        setIsPatientFormVisible(!isPatientFormVisible);
    };

    const showPatientEditForm = async () => {
        try {
            togglePatientFormVisible();
            setIsPatientFormContentLoading(true);
            const response = await http.get(
                `${apiUrl}/patient/edit/${patientID}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );
            if (response.data.error) {
                message.error(response.data.errorMsg);
            } else {
                let {
                    patientInfo,
                    patientOtherInfo,
                    patientGroups,
                    patientHmo
                } = response.data;

                const otherInfo = JSON.parse(patientOtherInfo.other_info);

                setPatientData({
                    PIFormValues: { ...patientInfo, ...otherInfo },
                    PHFormValues: JSON.parse(patientOtherInfo.history),
                    groups: patientGroups,
                    hmo: patientHmo
                });

                setIsPatientFormContentLoading(false);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                message.error(error.message);
                setIsPatientFormContentLoading(false);
            }
        }
    };

    const handlePatientFormSubmitSuccess = () => {
        togglePatientFormVisible();
        fetchData();
    };

    const callPatientQueueingScreen = () => {
        const { firstname, middlename, lastname } = patientDetails;
        socket.emit("callPatientonQueueScreen", {
            clinicID,
            firstname,
            middlename,
            lastname
        });
    };

    const PatientImage = () => {
        const { img_path, is_from_legacy, patient_id } = patientDetails;
        return (
            <div style={{ marginBottom: 10, textAlign: "center" }}>
                {img_path ? (
                    <Avatar
                        size={110}
                        shape="circle"
                        src={
                            is_from_legacy
                                ? img_path.replace(
                                      `${patient_id}-`,
                                      `thumb/${patient_id}-`
                                  )
                                : img_path.replace(".jpeg", "-card.jpeg")
                        }
                        alt="Patient Image"
                    />
                ) : (
                    <Avatar icon="user" size={110} shape="circle" />
                )}
            </div>
        );
    };

    const PatientName = () => {
        const { patient_id, firstname, middlename, lastname, nickname } =
            patientDetails;

        let patientFullName = "-";
        if (patient_id) {
            patientFullName = `${lastname}, ${firstname} ${middlename}`;
        }

        return (
            <>
                <h3
                    style={{
                        marginBottom: 0,
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1.4,
                        textAlign: "center"
                    }}
                >
                    {patientFullName}
                </h3>
                <h4
                    style={{
                        marginBottom: "0.8em",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: 1.4,
                        textAlign: "center"
                    }}
                >
                    {nickname ? `Nickname - ${nickname}` : "-"}
                </h4>
            </>
        );
    };

    const PatientDisplayData = () => {
        return patientCardItems.map(item => {
            const { type, name, label, target, highlightColor } = item;
            let textValue = null;
            switch (target) {
                case "fixed":
                    if (patientDetails[name]) {
                        textValue = patientDetails[name];
                    }
                    break;
                case "notFixed":
                    if (patientDetails.other_info[name]) {
                        textValue = patientDetails.other_info[name];
                    }
                    break;
                case "custom":
                    if (name === "age") {
                        textValue = formatAge(
                            ageFormat,
                            patientDetails.birthday
                        );
                    }
                    break;
                default:
                    textValue = "";
            }

            if (textValue && type === "datepicker") {
                textValue = moment(textValue).format("ll");
            }

            if (textValue && type === "array") {
                textValue = textValue.map(item => item.name).join(", ");
            }

            let value = null;
            if (highlightColor) {
                value = (
                    <span>
                        <Tag color={highlightColor}>
                            {textValue || <span>&mdash;</span>}
                        </Tag>
                    </span>
                );
            } else {
                value = <span>{textValue || <span>&mdash;</span>}</span>;
            }

            return (
                <div key={name}>
                    {type === "divider" ? (
                        <div style={{ padding: 10 }}></div>
                    ) : (
                        <div style={{ marginTop: 3 }}>
                            {label}: {value}
                        </div>
                    )}
                </div>
            );
        });
    };

    let cardActions = [];
    if (!isPatientDetailsLoading && !isNoPatient) {
        cardActions = [
            <Tooltip
                title={isMoreDetailsVisible ? "Less details" : "More details"}
            >
                <Icon
                    type={isMoreDetailsVisible ? "arrow-up" : "arrow-down"}
                    onClick={toggleMorePatientDetailsVisible}
                />
            </Tooltip>,
            <Tooltip title="Edit Patient Details">
                <Icon type="edit" onClick={showPatientEditForm} />
            </Tooltip>,
            <Tooltip title="Call on Queueing Screen">
                <Icon type="notification" onClick={callPatientQueueingScreen} />
            </Tooltip>
        ];

        if (!showEditPatientInfoBtn) {
            cardActions.splice(1, 1);
        }

        if (plan === "basic") {
            if (showEditPatientInfoBtn) {
                cardActions.splice(2, 1);
            } else {
                cardActions.splice(1, 1);
            }
        }

        if (extraActions) {
            cardActions = [...cardActions, ...extraActions];
        }
    }

    return (
        <Card bordered={isBordered} actions={cardActions}>
            {isPatientDetailsLoading ? (
                <Skeleton active />
            ) : isNoPatient ? (
                <Empty description="No Patient" />
            ) : (
                <>
                    <PatientImage />
                    <PatientName />
                    {isMoreDetailsVisible && (
                        <>
                            <PatientDisplayData />
                            {/* {showEditPatientInfoBtn && (
                                <div style={{ marginTop: 16 }}>
                                    <Button
                                        type="primary"
                                        icon="edit"
                                        onClick={showPatientEditForm}
                                    >
                                        Edit Patient Info
                                    </Button>
                                </div>
                            )} */}
                        </>
                    )}

                    <Drawer
                        title="Edit Patient"
                        placement="right"
                        visible={isPatientFormVisible}
                        onClose={togglePatientFormVisible}
                        destroyOnClose={true}
                        width={
                            breakpoint.isNormalMobile
                                ? 320
                                : breakpoint.isTabletPortrait
                                ? 700
                                : 1000
                        }
                        bodyStyle={
                            breakpoint.isNormalMobile
                                ? { padding: "24px 12px" }
                                : null
                        }
                    >
                        {isPatientFormContentLoading ? (
                            <ContentLoader />
                        ) : (
                            <PatientForm
                                patientData={patientData}
                                dataEditing={true}
                                breakpoint={breakpoint}
                                inline={true}
                                onEditSuccess={handlePatientFormSubmitSuccess}
                            />
                        )}
                    </Drawer>
                </>
            )}
        </Card>
    );
};

const mapStateToProps = state => ({
    patientCardItems: state.Settings.patientCardItems,
    ageFormat: state.Settings.ageFormat,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    plan: state.UserDetails.subscriptionDetails.plan
});

export default connect(mapStateToProps)(memo(PatientCard));
