import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Tag, Carousel } from "antd";
import moment from "moment";
import YouTube from "react-youtube";

import CurrentTime from "./components/CurrentTime";

import { titleCase } from "helpers/textTransformer";
import logo from "assets/img/logo.png";
import queueingNextPatient from "assets/img/queueing-next-patient.png";
import closeClinic from "assets/img/close_clinic.jpg";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

import doorbellSound from "assets/sound/doorbell.mp3";
let Sound = new Audio(doorbellSound);

const Queueing = props => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [queueDetails, setQueueDetails] = useState(null);

    const [isNextPatientVisible, setIsNextPatientVisible] = useState(false);
    const [patientName, setPatientName] = useState("");

    const [selectedYTPlaylistUrl, setSelectedYTPlaylistUrl] = useState("");
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [selectedMessage, setSelectedMessage] = useState(null);

    const [uploadedVideoVolume, setUploadedVideoVolume] = useState(0);
    const [ytPlayer, setYTPlayer] = useState(null);

    const [connectionStatus, setConnectionStatus] = useState(false);

    const dayOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];

    const { Title } = Typography;

    useEffect(() => {
        const ownerID = props.match.params.owner_id;
        const clinicID = props.match.params.clinic_id;
        const doctorID = props.match.params.doctor_id;
        socket.emit("addUser", `queueing_${clinicID}`, clinicID);

        const fetchData = async () => {
            const response = await http.get(
                `${apiUrl}/queueing/${clinicID}/${doctorID}`,
                {
                    headers: {
                        conn: ownerID,
                        qd: "qqq"
                    }
                }
            );

            const {
                clinic,
                doctor,
                queueDetails: data,
                YTPlaylist,
                uploadedVids,
                uploadedPhotos,
                messages
            } = response.data;

            const selectedYTPlaylistUrl = YTPlaylist.find(
                ytp =>
                    ytp.queueing_yt_playlist_id === data.queueing_yt_playlist_id
            );
            const selectedVideoUrl = uploadedVids.find(
                vid =>
                    vid.queueing_uploaded_vid_id ===
                    data.queueing_uploaded_vid_id
            );

            let YTPlaylistUrl = "";
            let videoUrl = "";
            if (selectedYTPlaylistUrl) {
                YTPlaylistUrl = selectedYTPlaylistUrl.url.replace(
                    "https://www.youtube.com/playlist?list=",
                    ""
                );
            }
            if (selectedVideoUrl) {
                videoUrl = selectedVideoUrl.url;
            }

            const selectedMessage = messages.find(
                msg => msg.queueing_message_id === data.queueing_message_id
            );

            setSelectedYTPlaylistUrl(YTPlaylistUrl);
            setSelectedVideoUrl(videoUrl);
            setSelectedMessage(selectedMessage);
            setUploadedVideoVolume(data.video_volume / 100);

            setQueueDetails({
                clinic,
                doctor,
                data,
                YTPlaylist,
                uploadedVids,
                uploadedPhotos: uploadedPhotos.filter(
                    pic => pic.is_selected === 1
                ),
                messages
            });

            setIsContentLoading(false);
        };

        fetchData();

        socket.on("reloadQueueing", function () {
            window.location.reload();
        });

        return () => {
            socket.off("reloadQueueing");
        };
    }, []);

    useEffect(() => {
        socket.on("connect", () => {
            setConnectionStatus(true);
        });
        socket.on("disconnect", () => {
            setConnectionStatus(false);
        });
    }, [connectionStatus]);

    useEffect(() => {
        if (queueDetails && queueDetails.data.is_clinic_closed) {
            setIsNextPatientVisible(false);
        }

        socket.on("queueingNewDetails", function (queueingNewDetails) {
            setQueueDetails({
                ...queueDetails,
                data: {
                    ...queueDetails.data,
                    ...queueingNewDetails
                }
            });

            if (!queueDetails.data.is_use_photo) {
                if (selectedYTPlaylistUrl && ytPlayer) {
                    ytPlayer.setVolume(queueingNewDetails.video_volume);
                }
                if (selectedVideoUrl) {
                    setUploadedVideoVolume(
                        queueingNewDetails.video_volume / 100
                    );
                }
            }
        });

        return () => {
            socket.off("queueingNewDetails");
        };
    }, [queueDetails, ytPlayer, uploadedVideoVolume]);

    useEffect(() => {
        socket.on("showNotifCallPatientQueueScreen", function (data) {
            if (!queueDetails.data.is_clinic_closed) {
                const { firstname, lastname } = data;
                const fullname = `${firstname} ${lastname}`;
                setIsNextPatientVisible(true);
                setPatientName(fullname);

                if (!queueDetails.data.is_use_photo) {
                    if (selectedYTPlaylistUrl && ytPlayer) {
                        ytPlayer.mute();
                        Sound.play();
                        setTimeout(() => {
                            ytPlayer.unMute();
                        }, 3000);
                    } else if (selectedVideoUrl) {
                        setUploadedVideoVolume(0);
                        Sound.play();
                        setTimeout(() => {
                            setUploadedVideoVolume(
                                queueDetails.data.video_volume / 100
                            );
                        }, 3000);
                    } else {
                        Sound.play();
                    }
                } else {
                    Sound.play();
                }
            }
        });

        return () => {
            socket.off("showNotifCallPatientQueueScreen");
        };
    }, [patientName, queueDetails, ytPlayer, uploadedVideoVolume]);

    function onPlayerReady(event) {
        // access to player in all event handlers via event.target
        const player = event.target;
        player.loadPlaylist({
            list: selectedYTPlaylistUrl,
            listType: "playlist"
        });
        player.setLoop(true);
        player.setVolume(queueDetails.data.video_volume);

        setYTPlayer(player);
    }

    const opts = {
        width: "100%",
        height: "100%",
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 0,
            showinfo: 0,
            loop: 1,
            rel: 0
        }
    };

    return isContentLoading ? (
        <div style={{ textAlign: "center", paddingTop: 90 }}>
            <div style={{ marginBottom: 30 }}>
                <img src={logo} alt="logo" />
            </div>
            <Title level={4}>Loading ...</Title>
        </div>
    ) : (
        <Row>
            <Col span={12}>
                {/* TV Info */}
                <div
                    style={{
                        height: 50,
                        padding: 12,
                        backgroundColor: "#046fa5"
                    }}
                >
                    <Row type="flex" justify="space-between">
                        <Col>
                            <span
                                style={{
                                    color: "#fff",
                                    fontWeight: "bold"
                                }}
                            >
                                Status:{" "}
                                {connectionStatus
                                    ? "Connected"
                                    : "Disconnected"}
                            </span>
                        </Col>
                        <Col>
                            {/* <span
                                style={{
                                    color: "#fff",
                                    fontWeight: "bold",
                                }}
                            >
                                TV ID: 001
                            </span> */}
                            <span
                                style={{
                                    color: "#fff",
                                    fontWeight: "bold"
                                }}
                            >
                                v1
                            </span>
                        </Col>
                    </Row>
                </div>
                {/* CWA Logo and Date */}
                <div style={{ height: 90, padding: "10px 30px" }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    width: 65,
                                    height: 65
                                }}
                            />
                        </Col>
                        <Col>
                            <div style={{ textAlign: "center" }}>
                                <div>
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "1.5rem"
                                        }}
                                    >
                                        {moment().format("LL")}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            fontSize: "1.3rem"
                                        }}
                                    >
                                        {dayOfWeek[moment().day()]}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Multimedia */}
                <div
                    style={{
                        height: "calc(100vh - 226px)",
                        backgroundColor: "#000"
                    }}
                >
                    {queueDetails.data.is_use_photo ? (
                        <>
                            {queueDetails.uploadedPhotos.length !== 0 ? (
                                <Carousel
                                    className="queueingCarousel"
                                    autoplay
                                    autoplaySpeed={20000}
                                >
                                    {queueDetails.uploadedPhotos.map(photo => (
                                        <div
                                            key={
                                                photo.queueing_uploaded_photo_id
                                            }
                                        >
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    backgroundColor: "#fff"
                                                }}
                                            >
                                                <img
                                                    src={photo.url}
                                                    style={{
                                                        width: "100%",
                                                        height: "calc(100vh - 226px)"
                                                    }}
                                                    alt="slide"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            ) : (
                                <div
                                    style={{
                                        textAlign: "center",
                                        paddingTop: 50
                                    }}
                                >
                                    <Title level={3}>
                                        <span style={{ color: "#fff" }}>
                                            No Photos
                                        </span>
                                    </Title>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {queueDetails.data.is_use_video ? (
                                <>
                                    {selectedVideoUrl ? (
                                        <video
                                            ref={node => {
                                                if (node) {
                                                    node.volume =
                                                        uploadedVideoVolume;
                                                }
                                            }}
                                            width="100%"
                                            height="100%"
                                            autoPlay={true}
                                            loop={true}
                                        >
                                            <source
                                                src={selectedVideoUrl}
                                                type="video/mp4"
                                            />
                                            <span style={{ color: "#fff" }}>
                                                Your browser does not support
                                                the video tag.
                                            </span>
                                        </video>
                                    ) : (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                paddingTop: 50
                                            }}
                                        >
                                            <Title level={3}>
                                                <span style={{ color: "#fff" }}>
                                                    No Video Found
                                                </span>
                                            </Title>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {selectedYTPlaylistUrl ? (
                                        <YouTube
                                            //videoId="oXhVTueHZKQ"
                                            opts={opts}
                                            onReady={onPlayerReady}
                                            containerClassName="youtubeContainer"
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                paddingTop: 50
                                            }}
                                        >
                                            <Title level={3}>
                                                <span style={{ color: "#fff" }}>
                                                    No YT Playlist Found
                                                </span>
                                            </Title>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                {/* Current Time and Announcement */}
                <div
                    style={{
                        height: 50,
                        backgroundColor: "#313131"
                    }}
                >
                    <Row>
                        <Col span={6}>
                            <div
                                style={{
                                    height: 50,
                                    textAlign: "center",
                                    backgroundColor: "#3e3e3e",
                                    padding: "10px 16px 0px"
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "1.3rem",
                                        fontWeight: "bold",
                                        color: "#fff"
                                    }}
                                >
                                    <CurrentTime />
                                </span>
                            </div>
                        </Col>
                        <Col span={18}>
                            <div
                                style={{
                                    height: 50,
                                    width: "100%",
                                    padding: "10px 16px 0px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    boxSizing: "border-box"
                                }}
                            >
                                {queueDetails.data.is_announcement_show ? (
                                    <span
                                        style={{
                                            fontSize: "1.3rem",
                                            fontWeight: "bold",
                                            color: "#fff",
                                            display: "inline-block",
                                            paddingLeft: "100%",
                                            animation: `marquee ${queueDetails.data.announcement_speed}s linear infinite`
                                        }}
                                    >
                                        {queueDetails.data.announcement_text}
                                    </span>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Footer */}
                <div
                    style={{
                        height: 35,
                        backgroundColor: "#212121",
                        padding: 6
                    }}
                >
                    <Row type="flex" justify="space-between">
                        <Col>
                            <span style={{ color: "#fff" }}>
                                Powered by ITMG Enterprises
                            </span>
                        </Col>
                        <Col>
                            <span style={{ color: "#fff" }}>
                                www.cwassistant.com
                            </span>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={12}>
                <div style={{ padding: 20 }}>
                    {/* clinic info */}
                    <div
                        style={{
                            padding: "16px 16px 0",
                            textAlign: "center"
                        }}
                    >
                        <Title level={1} style={{ marginBottom: 0 }}>
                            {queueDetails.clinic.name}
                        </Title>
                        <div>
                            <span style={{ fontSize: "1.2rem" }}>
                                {queueDetails.clinic.schedule ||
                                    queueDetails.clinic.address}
                            </span>
                        </div>
                    </div>
                    <Divider />

                    {/* message */}
                    <div
                        style={{
                            padding: "30px 16px 16px",
                            borderLeft: "4px solid #046fa5",
                            height: "calc(100vh - 353px)"
                        }}
                    >
                        {queueDetails.data.is_clinic_closed ? (
                            <div style={{ textAlign: "center" }}>
                                <Title level={1}>We're Closed</Title>
                                <img src={closeClinic} alt="clinic closed" />
                            </div>
                        ) : queueDetails.data.is_message_show ? (
                            <div style={{ textAlign: "center" }}>
                                {selectedMessage ? (
                                    <div>
                                        <Title level={1}>
                                            <span
                                                style={{
                                                    textDecoration: "underline"
                                                }}
                                            >
                                                {selectedMessage.title}
                                            </span>
                                        </Title>
                                        <Title level={2}>
                                            {selectedMessage.message}
                                        </Title>
                                    </div>
                                ) : (
                                    <Title level={2}>No selected message</Title>
                                )}
                            </div>
                        ) : isNextPatientVisible ? (
                            <div style={{ textAlign: "center" }}>
                                <div>
                                    <div>
                                        <Title level={3}>Next Patient</Title>
                                    </div>
                                    <div>
                                        <Title level={1}>{patientName}</Title>
                                    </div>
                                </div>
                                <div style={{ marginTop: 70 }}>
                                    <img
                                        style={{ width: 150, height: 100 }}
                                        src={queueingNextPatient}
                                        alt="next patient"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div style={{ textAlign: "center" }}>
                                <Title level={1}>We're Open</Title>
                            </div>
                        )}
                    </div>

                    <Divider />

                    {/* doctor info */}
                    <div
                        style={{
                            padding: "0 16px"
                        }}
                    >
                        <Title
                            level={3}
                            style={{ marginTop: 0, marginBottom: 0 }}
                        >
                            {queueDetails.doctor.firstname}{" "}
                            {queueDetails.doctor.lastname}{" "}
                            {queueDetails.doctor.degree}
                        </Title>
                        <div>
                            <span style={{ fontSize: "1.1rem" }}>
                                {titleCase(queueDetails.doctor.aop_name)}
                            </span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {queueDetails.data.is_doctor_available ? (
                                <Tag
                                    color="#87d068"
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Doctor Inside
                                </Tag>
                            ) : (
                                <Tag
                                    color="#f44336"
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Doctor Not Available
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Queueing;
