import React, { useState, useEffect } from "react";
import RichTextEditor from "globalComponents/RichTextEditor";

const Objective = ({ caseDetails, readOnly: isCaseReadOnly, onChange }) => {
    const [objectiveContent, setObjectiveContent] = useState("");

    useEffect(() => {
        setObjectiveContent(caseDetails.objective);
    }, [caseDetails]);

    const handleEditorSave = async value => {
        setObjectiveContent(value);
        onChange("objective", value);
    };

    return (
        <RichTextEditor
            value={objectiveContent}
            readOnly={isCaseReadOnly}
            onSave={handleEditorSave}
        />
    );
};

export default Objective;
