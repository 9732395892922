import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Divider,
    Radio,
    Form,
    Input,
    Select,
    Button,
    message,
    Checkbox,
} from "antd";
import { generateString } from "helpers/randomStringGenerator";
import http from "services/httpService";
import { apiUrl } from "config.json";

const Register = ({
    aops,
    aopsSub,
    plan,
    onSuccess,
    setRequestError,
    ...props
}) => {
    const [formData, setFormData] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        degree: "",
        aop_id: "",
        aop_sub_ids: [],
        license_number: "",
        ptr: "",
        clinic_name: "",
        clinic_address: "",
        email: "",
        password: "",
        confirm_password: "",
        verification_id: "",
        status: "unverified",
        plan,
    });

    const [formErrors, setFormErrors] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        degree: "",
        aop_id: "",
        license_number: "",
        ptr: "",
        clinic_name: "",
        clinic_address: "",
        email: "",
        password: "",
        confirm_password: "",
    });

    const [isAcceptTerms, setIsAcceptTerms] = useState(false);
    const [isAccountCreating, setIsAccountCreating] = useState(false);

    const { Option } = Select;

    useEffect(() => {}, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        let errors = { ...formErrors };
        if (value.toString().trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const {
            firstname,
            middlename,
            lastname,
            degree,
            aop_id,
            license_number,
            ptr,
            clinic_name,
            clinic_address,
            email,
            password,
            confirm_password,
        } = formData;
        let errors = {};
        let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

        if (firstname.trim() === "") {
            errors.firstname = "Firstname is required";
        }
        if (middlename.trim() === "") {
            errors.middlename = "Middlename is required";
        }
        if (lastname.trim() === "") {
            errors.lastname = "Lastname is required";
        }
        if (degree.trim() === "") {
            errors.degree = "Degree is required";
        }
        if (aop_id === "") {
            errors.aop_id = "Area of Practice is required";
        }
        if (license_number.trim() === "") {
            errors.license_number = "PRC Number is required";
        }
        if (ptr.trim() === "") {
            errors.ptr = "PTR Number is required";
        }
        if (clinic_name.trim() === "") {
            errors.clinic_name = "Clinic Name is required";
        }
        if (clinic_address.trim() === "") {
            errors.clinic_address = "Clinic Address is required";
        }
        if (!emailRegEx.test(String(email).toLowerCase())) {
            errors.email = "Invalid Email";
        }
        if (email.trim() === "") {
            errors.email = "Email is required";
        }
        if (password.trim() === "") {
            errors.password = "Password is required";
        }
        if (confirm_password.trim() === "") {
            errors.confirm_password = "Confirm Password is required";
        }
        if (password.trim() !== confirm_password.trim()) {
            errors.confirm_password = "Password Mismatched";
        }
        if (password.length < 6) {
            errors.password = "Password must be atleast 6 characters";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleAcceptTermChange = (e) => {
        setIsAcceptTerms(e.target.checked);
    };

    const handleSubmit = async () => {
        const errors = validateInput();

        if (errors) {
            setFormErrors(errors);
        } else {
            setIsAccountCreating(true);
            try {
                const newFormData = {
                    ...formData,
                    verification_id: generateString(19),
                    aop_sub_ids: JSON.stringify(formData.aop_sub_ids),
                };
                delete newFormData.confirm_password;
                const response = await http.post(
                    `${apiUrl}/register`,
                    newFormData
                );

                if (response.data.emailExist) {
                    setFormErrors({
                        ...formErrors,
                        email: "This email was already in use",
                    });
                } else {
                    //setIsShowConfirmEmail(true);
                    onSuccess(newFormData.email);
                }
                //insert
                //send link
                setIsAccountCreating(false);
            } catch (error) {
                message.error(error.message || "Something went wrong! :(");
                setIsAccountCreating(false);
            }
        }
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            //onSubmit={() => console.log("boo")}
        >
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Form.Item
                        label="First name"
                        required
                        hasFeedback
                        validateStatus={formErrors.firstname && "error"}
                        help={formErrors.firstname && formErrors.firstname}
                    >
                        <Input
                            name="firstname"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.firstname}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Middle name"
                        required
                        hasFeedback
                        validateStatus={formErrors.middlename && "error"}
                        help={formErrors.middlename && formErrors.middlename}
                    >
                        <Input
                            name="middlename"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.middlename}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Last name"
                        required
                        hasFeedback
                        validateStatus={formErrors.lastname && "error"}
                        help={formErrors.lastname && formErrors.lastname}
                    >
                        <Input
                            name="lastname"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.lastname}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Degree"
                        required
                        hasFeedback
                        validateStatus={formErrors.degree && "error"}
                        help={formErrors.degree && formErrors.degree}
                    >
                        <Input
                            name="degree"
                            value={formData.degree}
                            placeholder="MD, MS, ENT, OB/GYN, PharmD"
                            autoComplete="off"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Area of Practice"
                        required
                        hasFeedback
                        validateStatus={formErrors.aop_id && "error"}
                        help={formErrors.aop_id && formErrors.aop_id}
                    >
                        <Select
                            onChange={(value) =>
                                handleInputChange({
                                    target: {
                                        name: "aop_id",
                                        value,
                                    },
                                })
                            }
                            onBlur={(value) =>
                                handleInputBlur({
                                    target: {
                                        name: "aop_id",
                                        value,
                                    },
                                })
                            }
                            value={formData.aop_id}
                        >
                            {aops.map((aop) => (
                                <Option key={aop.aop_id} value={aop.aop_id}>
                                    {aop.aop_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Sub Area of Practice">
                        <Select
                            showSearch
                            value={formData.aop_sub_ids}
                            mode="multiple"
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                                handleInputChange({
                                    target: {
                                        name: "aop_sub_ids",
                                        value,
                                    },
                                })
                            }
                        >
                            {aopsSub.map((aopSub) => (
                                <Option
                                    key={aopSub.aop_sub_id}
                                    value={aopSub.aop_sub_id}
                                >
                                    {aopSub.aop_sub_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="PRC Number"
                        required
                        hasFeedback
                        validateStatus={formErrors.license_number && "error"}
                        help={
                            formErrors.license_number &&
                            formErrors.license_number
                        }
                    >
                        <Input
                            name="license_number"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.license_number}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label="PTR"
                        required
                        hasFeedback
                        validateStatus={formErrors.ptr && "error"}
                        help={formErrors.ptr && formErrors.ptr}
                    >
                        <Input
                            name="ptr"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.ptr}
                            autoComplete="off"
                        />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12}>
                    <Form.Item
                        label="Clinic Name"
                        required
                        hasFeedback
                        validateStatus={formErrors.clinic_name && "error"}
                        help={formErrors.clinic_name && formErrors.clinic_name}
                    >
                        <Input
                            name="clinic_name"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.clinic_name}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Clinic Address"
                        required
                        hasFeedback
                        validateStatus={formErrors.clinic_address && "error"}
                        help={
                            formErrors.clinic_address &&
                            formErrors.clinic_address
                        }
                    >
                        <Input
                            name="clinic_address"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={formData.clinic_address}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <div style={{ marginTop: 10 }}>
                        <Form.Item
                            label="Email"
                            required
                            hasFeedback
                            validateStatus={formErrors.email && "error"}
                            help={formErrors.email && formErrors.email}
                        >
                            <Input
                                name="email"
                                type="email"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={formData.email}
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            required
                            hasFeedback
                            validateStatus={formErrors.password && "error"}
                            help={formErrors.password && formErrors.password}
                        >
                            <Input
                                name="password"
                                type="password"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={formData.password}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            required
                            hasFeedback
                            validateStatus={
                                formErrors.confirm_password && "error"
                            }
                            help={
                                formErrors.confirm_password &&
                                formErrors.confirm_password
                            }
                        >
                            <Input
                                name="confirm_password"
                                type="password"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                value={formData.confirm_password}
                            />
                        </Form.Item>
                    </div>
                    <div
                        style={{
                            marginTop: 30,
                        }}
                    >
                        <Form.Item label="Choose your Plan">
                            <Radio.Group
                                defaultValue={formData.plan}
                                //buttonStyle="outline"
                                size="large"
                                name="plan"
                                onChange={handleInputChange}
                            >
                                <Radio
                                    style={{
                                        display: "block",
                                        height: "30px",
                                        lineHeight: "30px",
                                    }}
                                    value="free"
                                >
                                    FREE
                                </Radio>

                                <Radio
                                    style={{
                                        display: "block",
                                        height: "30px",
                                        lineHeight: "30px",
                                    }}
                                    value="basic"
                                >
                                    BASIC
                                </Radio>

                                <Radio
                                    style={{
                                        display: "block",
                                        height: "30px",
                                        lineHeight: "30px",
                                    }}
                                    value="standard"
                                >
                                    STANDARD
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Col>
            </Row>
            <Divider />
            <div
                style={{
                    textAlign: "center",
                    // marginTop: 40
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <Checkbox onChange={handleAcceptTermChange}>
                        {" "}
                        I agree to the{" "}
                        <a
                            href="https://cwassistant.com/src/docs/terms%20of%20agreement.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Agreement
                        </a>{" "}
                        and{" "}
                        <a
                            href="https://cwassistant.com/src/docs/privacy%20policy.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Private Policy
                        </a>
                    </Checkbox>
                </div>
                <Button
                    type="primary"
                    size="large"
                    loading={isAccountCreating}
                    disabled={!isAcceptTerms}
                    onClick={handleSubmit}
                >
                    {isAccountCreating ? "Creating Account" : "Create Account"}
                </Button>
            </div>
        </Form>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(null, mapDispatchToProps)(Register);
