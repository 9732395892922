import React, { useState, useEffect } from "react";
import RichTextEditor from "globalComponents/RichTextEditor";

const Assessment = ({ caseDetails, readOnly: isCaseReadOnly, onChange }) => {
    const [assessmentContent, setAssessmentContent] = useState("");

    useEffect(() => {
        setAssessmentContent(caseDetails.assessment);
    }, [caseDetails]);

    const handleEditorSave = async value => {
        setAssessmentContent(value);
        onChange("assessment", value);
    };

    return (
        <RichTextEditor
            value={assessmentContent}
            readOnly={isCaseReadOnly}
            onSave={handleEditorSave}
        />
    );
};

export default Assessment;
