import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    DatePicker,
    Table,
    Row,
    Col,
    Button,
    Input,
    Icon,
    Tag,
    Statistic,
    Card,
    Tooltip,
    Divider
} from "antd";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import ActionMenu from "globalComponents/ActionMenu";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import { findUserName } from "helpers/userNameFinder";
import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const BillingReport = ({ breakpoint, clinicID, setRequestError, ...props }) => {
    const [billsReportTableLoading, setBillsReportTableLoading] = useState(
        false
    );
    const [bills, setBills] = useState([]);
    const [billsSummary, setBillsSummary] = useState({
        subTotal: 0,
        discount: 0,
        grandTotal: 0,
        amountPaid: 0,
        balance: 0,
        totalItems: 0
    });
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const { RangePicker } = DatePicker;

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleBillingDateRangeChange = async (date, dateString) => {
        const [dateStart, dateEnd] = dateString;
        if (dateStart !== "" && dateEnd !== "") {
            try {
                setBillsReportTableLoading(true);
                const response = await http.get(
                    `${apiUrl}/report/bills/${clinicID}/${dateStart}/${dateEnd}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                const { bills } = response.data;

                let subTotal = 0;
                let discount = 0;
                let grandTotal = 0;
                let amountPaid = 0;
                let balance = 0;
                let totalItems = 0;

                bills.forEach(bill => {
                    subTotal += bill.sub_total;
                    discount += bill.discount;
                    grandTotal += bill.grand_total;
                    amountPaid += bill.amount_paid;
                    balance += bill.amount_balance;
                    totalItems += bill.item_count;
                });

                setBillsSummary({
                    subTotal,
                    discount,
                    grandTotal,
                    amountPaid,
                    balance,
                    totalItems
                });
                setBills(bills);
                setTableData(bills);
                setBillsReportTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        } else {
            setBillsSummary({
                subTotal: 0,
                discount: 0,
                grandTotal: 0,
                amountPaid: 0,
                balance: 0,
                totalItems: 0
            });
            setBills([]);
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        const statusFilters = filters.status;

        if (statusFilters) {
            let subTotal = 0;
            let discount = 0;
            let grandTotal = 0;
            let amountPaid = 0;
            let balance = 0;
            let totalItems = 0;

            bills.forEach(bill => {
                if (
                    statusFilters.indexOf(bill.status) !== -1 ||
                    statusFilters.length === 0
                ) {
                    subTotal += bill.sub_total;
                    discount += bill.discount;
                    grandTotal += bill.grand_total;
                    amountPaid += bill.amount_paid;
                    balance += bill.amount_balance;
                    totalItems += bill.item_count;
                }
            });

            setBillsSummary({
                subTotal,
                discount,
                grandTotal,
                amountPaid,
                balance,
                totalItems
            });
        }

        setTableData(extra.currentDataSource);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const billingReportTableColumns = [
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            ...getColumnSearchProps("patient_name")
        },
        {
            title: "Bill / Invoice No.",
            dataIndex: "bill_id",
            ...getColumnSearchProps("bill_id")
        },
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            sorter: (a, b) =>
                new Date(moment(a.bill_date).format("LLL")) -
                new Date(moment(b.bill_date).format("LLL")),
            render: (text, record) => moment(record.bill_date).format("LL")
        },
        {
            title: "Total Items",
            dataIndex: "item_count"
        },
        {
            title: "Sub Total",
            dataIndex: "sub_total",
            render: subTotal => formatNumber(subTotal)
        },
        {
            title: "Discount",
            dataIndex: "discount",
            render: discount => formatNumber(discount)
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: grandTotal => formatNumber(grandTotal)
        },
        {
            title: "Status",
            dataIndex: "status",
            render: status => {
                let statusColor = "red";
                if (status === "partial") {
                    statusColor = "green";
                } else if (status === "unpaid") {
                    statusColor = "orange";
                } else if (status === "paid") {
                    statusColor = "green";
                }

                return (
                    <span>
                        <Tag color={statusColor}>{status}</Tag>
                    </span>
                );
            },
            filters: [
                {
                    text: "Unpaid",
                    value: "unpaid"
                },
                {
                    text: "Partial",
                    value: "partial"
                },
                {
                    text: "Paid",
                    value: "paid"
                },
                {
                    text: "Deleted",
                    value: "deleted"
                }
            ],
            onFilter: (value, record) => {
                return record.status === value;
            }
        },
        {
            title: "Amount Paid",
            dataIndex: "amount_paid",
            render: amountPaid => formatNumber(amountPaid)
        },
        {
            title: "HMO Covered",
            dataIndex: "hmo_amount_covered",
            render: hmoAmountCover => formatNumber(hmoAmountCover)
        },
        {
            title: "HMO Name",
            dataIndex: "hmo_name",
            render: hmoName => (hmoName ? hmoName : <span>&mdash;</span>)
        },
        {
            title: "Remaining Balance",
            dataIndex: "amount_balance",
            sorter: (a, b) => a.amount_balance - b.amount_balance,
            render: remainingBalance => formatNumber(remainingBalance)
        },
        {
            title: "Notes",
            dataIndex: "bill_notes",
            render: billNotes => (billNotes ? billNotes : <span>&mdash;</span>)
        },
        {
            title: "Date Created",
            dataIndex: "created_at",
            sorter: (a, b) =>
                new Date(moment(a.created_at).format("LLL")) -
                new Date(moment(b.created_at).format("LLL")),
            render: (text, record) => moment(record.created_at).format("LL")
        },
        {
            title: "Created By",
            dataIndex: "user_id",
            render: user_id => findUserName(user_id)
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
                <ActionMenu
                    menu={[
                        {
                            label: "Open bill",
                            icon: "folder-open",
                            onClick: () =>
                                props.history.push(
                                    `/billing/${record.patient_id}/${record.bill_id}`
                                )
                        },
                        {
                            label: "View patient bill records",
                            icon: "solution",
                            onClick: () =>
                                props.history.push(
                                    `/billing/${record.patient_id}`
                                )
                        }
                    ]}
                    layout="compress"
                />
            )
        }
    ];

    const CSVHeaders = [
        {
            label: "Patient Name",
            key: "patient_name"
        },
        {
            label: "Bill / Invoice No.",
            key: "bill_id"
        },
        {
            label: "Bill Date",
            key: "bill_date"
        },
        {
            label: "Total Items",
            key: "item_count"
        },
        {
            label: "Sub Total",
            key: "sub_total"
        },
        {
            label: "Discount",
            key: "discount"
        },
        {
            label: "Grand Total",
            key: "grand_total"
        },
        {
            label: "Status",
            key: "status"
        },
        {
            label: "Amount Paid",
            key: "amount_paid"
        },
        {
            label: "HMO Covered",
            key: "hmo_amount_covered"
        },
        {
            label: "HMO Name",
            key: "hmo_name"
        },
        {
            label: "Balance",
            key: "amount_balance"
        },
        {
            label: "Notes",
            key: "bill_notes"
        },
        {
            label: "Created By",
            key: "created_by"
        },
        {
            label: "Date Created",
            key: "created_at"
        }
    ];

    const CSVData = tableData.map(bill => ({
        ...bill,
        bill_date: moment(bill.bill_date).format("LL"),
        created_at: moment(bill.created_at).format("LL")
    }));

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            <div style={{ marginBottom: 16 }}>
                {isMobileBreakpoint ? (
                    <div
                        style={{
                            marginBottom: 5
                        }}
                    >
                        Select Date Range:
                    </div>
                ) : (
                    "Select Date Range: "
                )}
                <RangePicker onChange={handleBillingDateRangeChange} />
            </div>

            <div style={{ marginBottom: 16 }}>
                <Row gutter={16}>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Item Count"
                                value={billsSummary.totalItems}
                            />
                        </Card>
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Sub Total"
                                value={billsSummary.subTotal}
                            />
                        </Card>
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Discount"
                                value={billsSummary.discount}
                            />
                        </Card>
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Grand Total"
                                value={billsSummary.grandTotal}
                            />
                        </Card>
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Amount Paid"
                                value={billsSummary.amountPaid}
                            />
                        </Card>
                    </Col>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Balance"
                                value={billsSummary.balance}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

            <div
                style={{
                    marginBottom: 16,
                    minWidth: 320,
                    overflow: "auto",
                    backgroundColor: "#fff"
                }}
            >
                <Table
                    columns={billingReportTableColumns}
                    dataSource={bills}
                    onChange={handleTableChange}
                    rowKey={"bill_id"}
                    loading={billsReportTableLoading}
                    pagination={{
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        simple: isMobileBreakpoint ? true : false
                    }}
                    className="reportsTable"
                />
            </div>

            <div style={{ marginTop: 10, textAlign: "right" }}>
                <CSVLink
                    data={CSVData}
                    headers={CSVHeaders}
                    filename={"billing.csv"}
                >
                    <Tooltip title="Export to Excel">
                        <Button
                            style={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                border: "1px solid #4caf50"
                            }}
                        >
                            <Icon type="download" /> Export to CSV
                        </Button>
                    </Tooltip>
                </CSVLink>
            </div>
        </Card>
    );
};

const mapStateToProps = state => ({
    doctors: state.UserDetails.doctors,
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BillingReport)
);
