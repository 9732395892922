import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    DatePicker,
    Table,
    Button,
    Input,
    Icon,
    Tooltip,
    Card,
    Divider
} from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ItemLedgerReport = ({ breakpoint, clinicID, setRequestError }) => {
    const [
        itemLedgerReportTableLoading,
        setitemLedgerReportTableLoading
    ] = useState(false);
    const [itemLedger, setitemLedger] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const { RangePicker } = DatePicker;
    // const ButtonGroup = Button.Group;

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleItemLedgerDateRangeChange = async (date, dateString) => {
        const [dateStart, dateEnd] = dateString;
        if (dateStart !== "" && dateEnd !== "") {
            try {
                setitemLedgerReportTableLoading(true);
                const response = await http.get(
                    `${apiUrl}/report/itemLedger/${clinicID}/${dateStart}/${dateEnd}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                setitemLedger(response.data.itemLedger);
                setTableData(response.data.itemLedger);
                setitemLedgerReportTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        } else {
            setitemLedger([]);
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setTableData(extra.currentDataSource);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const itemLedgerReportColumns = [
        {
            title: "Code",
            dataIndex: "ledger_code"
        },
        {
            title: "Ref. Number",
            dataIndex: "ref_number",
            render: refNumber => (refNumber ? refNumber : <span>&mdash;</span>)
        },
        {
            title: "Item Name",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Generic Name",
            dataIndex: "generic_name",
            ...getColumnSearchProps("generic_name"),
            sorter: (a, b) => a.generic_name.localeCompare(b.generic_name),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Stock Qty.",
            dataIndex: "stock_qty"
        },
        {
            title: "Additional Qty.",
            dataIndex: "additional_qty"
        },
        {
            title: "New Qty",
            dataIndex: "new_qty"
        },
        {
            title: "Reason",
            dataIndex: "reason"
        },
        {
            title: "Date Adjusted",
            dataIndex: "created_at",
            sorter: (a, b) =>
                new Date(moment(a.created_at).format("LLL")) -
                new Date(moment(b.created_at).format("LLL")),
            render: (text, record) => moment(record.created_at).format("LLL")
        },
        {
            title: "User",
            dataIndex: "user_id",
            render: user_id => findUserName(user_id)
        }
    ];

    const CSVHeaders = [
        {
            label: "Code",
            key: "ledger_code"
        },
        {
            label: "Ref. Number",
            key: "ref_number"
        },
        {
            label: "Item Name",
            key: "name"
        },
        {
            label: "Generic Name",
            key: "generic_name"
        },
        {
            label: "Stock Qty.",
            key: "stock_qty"
        },
        {
            label: "Additional Qty.",
            key: "additional_qty"
        },
        {
            label: "New Qty",
            key: "new_qty"
        },
        {
            label: "Reason",
            key: "reason"
        },
        {
            label: "User",
            key: "created_by"
        },
        {
            label: "Date Adjusted",
            key: "created_at"
        }
    ];

    const CSVData = tableData.map(ledger => ({
        ...ledger,
        created_at: moment(ledger.created_at).format("LL")
    }));

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            <div style={{ marginBottom: 16 }}>
                {isMobileBreakpoint ? (
                    <div
                        style={{
                            marginBottom: 5
                        }}
                    >
                        Select Date Range:
                    </div>
                ) : (
                    "Select Date Range: "
                )}

                <RangePicker onChange={handleItemLedgerDateRangeChange} />
            </div>
            <div
                style={{
                    marginBottom: 16,
                    minWidth: 320,
                    overflow: "auto",
                    backgroundColor: "#fff"
                }}
            >
                <Table
                    columns={itemLedgerReportColumns}
                    dataSource={itemLedger}
                    rowKey={"item_ledger_id"}
                    loading={itemLedgerReportTableLoading}
                    pagination={{
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        simple: isMobileBreakpoint ? true : false
                    }}
                    className="reportsTable"
                    onChange={handleTableChange}
                />
            </div>
            <div style={{ textAlign: "right" }}>
                <CSVLink
                    data={CSVData}
                    headers={CSVHeaders}
                    filename={"item ledger.csv"}
                >
                    <Tooltip title="Export to Excel">
                        <Button
                            style={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                border: "1px solid #4caf50"
                            }}
                        >
                            <Icon type="download" /> Export to CSV
                        </Button>
                    </Tooltip>
                </CSVLink>
            </div>
        </Card>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemLedgerReport);
