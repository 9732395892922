export const SET_ITEM_SUCCESS = "SET_ITEM_SUCCESS";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const ADJUST_ITEM_STOCK_SUCCESS = "ADJUST_ITEM_STOCK_SUCCESS";

export const UPDATE_PRESCRIPTION_NOTE_SUCCESS =
    "UPDATE_PRESCRIPTION_NOTE_SUCCESS";

export const ADJUST_BULK_ITEM_STOCK_SUCCESS = "ADJUST_BULK_ITEM_STOCK_SUCCESS";
