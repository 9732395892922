import React from "react";
import { Select } from "antd";

const HmoForm = ({ hmo, hmoIDs, breakpoint, onHmoChange }) => {
    const { Option } = Select;

    return (
        <div style={{ paddingLeft: breakpoint.isNormalMobile ? 0 : 20 }}>
            <Select
                placeholder="Please select patient's HMO"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                onChange={onHmoChange}
                value={hmoIDs}
                style={{ display: "block" }}
            >
                {hmo.map(h => (
                    <Option key={h.hmo_id} value={h.hmo_id}>
                        {h.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default HmoForm;
