import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    addSavedCaseNote,
    deleteSavedCaseNote
} from "redux/savedCaseNotes/savedCaseNotes.actions";
import { setCopiedCaseNote } from "redux/copiedCaseNote/copiedCaseNote.actions";
import {
    Button,
    Dropdown,
    Tooltip,
    Menu,
    Card,
    Icon,
    Modal,
    Timeline,
    Spin,
    message,
    Alert
} from "antd";
import ReactHtmlParser from "react-html-parser";

import Drawer from "globalComponents/Drawer";

import ChiefComplaintEditor from "./components/ChiefComplaintEditor";
import Subjective from "./components/Subjective";
import Objective from "./components/Objective";
import Assessment from "./components/Assessment";
import Plan from "./components/Plan";
import PlainNotes from "./components/PlainNotes";
import ImageManager from "./components/ImageManager/Index";
import VitalSigns from "./components/VitalSigns/Index";
import OtherPlans from "./components/OtherPlans/Index";

import SaveCaseNoteForm from "./components/Forms/SaveCaseNoteForm";
import SavedCaseNotesList from "./components/SavedCaseNotesList";

import http from "services/httpService";
import { apiUrl } from "config.json";

//import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Notes = ({
    caseDetails,
    patientDetails,
    readOnly: isCaseReadOnly,
    breakpoint,
    onChange,
    userID,
    clinicID,
    defaultDisplayedCaseNote,
    savedCaseNotes,
    copiedCaseNote,
    items,
    papers,
    soapEnabledImgManager,
    addSavedCaseNote,
    deleteSavedCaseNote,
    setCopiedCaseNote,
    setRequestError
}) => {
    const [notesLoading, setNotesLoading] = useState({
        spin: false,
        spinLabel: "Loading ..."
    });
    const [isSaveCaseNoteFormVisible, setIsSaveCaseNoteFormVisible] =
        useState(false);
    const [isSavedCaseNotesListVisible, setIsSavedCaseNotesListVisible] =
        useState(false);
    const [displayedCaseNote, setDisplayedCaseNote] = useState(
        defaultDisplayedCaseNote
    );

    const [appointmentItems, setAppointmentItems] = useState([]);

    const { confirm } = Modal;

    useEffect(() => {
        const fetchData = async () => {
            setNotesLoading({
                spin: true,
                spinLabel: "Loading Case..."
            });
            const response = await http.get(
                `${apiUrl}/appointmentItems/${caseDetails.appointment_id}`,
                {
                    headers: {
                        conn: "pp"
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            const { appointmentItems } = response.data;
            setAppointmentItems(
                appointmentItems.filter(item => item.item_id !== "consultation")
            );

            setNotesLoading({
                spin: false,
                spinLabel: "Loading Case..."
            });
        };

        setAppointmentItems([]);
        if (caseDetails.appointment_id && caseDetails.is_from_cwapp) {
            fetchData();
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, [caseDetails.appointment_id]);

    const toggleSaveCaseNoteFormVisible = () => {
        if (!isSaveCaseNoteFormVisible) {
            //prevent if there's a delete data in this case
            const deletedItems = items
                .filter(
                    item =>
                        item.item_type === "product" &&
                        item.status === "inactive"
                )
                .reduce((acc, item) => {
                    acc.push(item.item_id);
                    return acc;
                }, []);
            const deletedService = items
                .filter(
                    item =>
                        item.item_type === "service" &&
                        item.status === "inactive"
                )
                .reduce((acc, item) => {
                    acc.push(item.item_id);
                    return acc;
                }, []);
            const deletedPapers = papers
                .filter(paper => paper.status === "inactive")
                .reduce((acc, item) => {
                    acc.push(item.paper_id);
                    return acc;
                }, []);

            const { otherPlans } = caseDetails;
            const currentItems = otherPlans.filter(i => i.type === "item");
            const currentServices = otherPlans.filter(
                i => i.type === "service"
            );
            const currentPapers = otherPlans.filter(
                i => i.type === "paper" || i.type === "labRequest"
            );

            const isThereItemDeleted = currentItems.find(
                item => deletedItems.indexOf(item.id) !== -1
            );
            const isThereServiceDeleted = currentServices.find(
                item => deletedService.indexOf(item.id) !== -1
            );
            const isTherePaperDeleted = currentPapers.find(
                item => deletedPapers.indexOf(item.id) !== -1
            );

            if (
                isThereItemDeleted ||
                isThereServiceDeleted ||
                isTherePaperDeleted
            ) {
                message.error(
                    `There were deleted/archived items on this case! Saving this case note is not allowed`
                );
                setCopiedCaseNote(null);
            } else {
                setIsSaveCaseNoteFormVisible(!isSaveCaseNoteFormVisible);
            }
        } else {
            setIsSaveCaseNoteFormVisible(!isSaveCaseNoteFormVisible);
        }
    };

    const toggleSavedCaseNotesListVisible = () => {
        setIsSavedCaseNotesListVisible(!isSavedCaseNotesListVisible);
    };

    const handleDisplayedCaseNoteChange = () => {
        if (displayedCaseNote === "soap") {
            setDisplayedCaseNote("plain");
        } else {
            setDisplayedCaseNote("soap");
        }
    };

    const handleSOAPChange = async (target, value) => {
        onChange(target, value);
        try {
            await http.put(`${apiUrl}/soap/${caseDetails.case_id}`, {
                [target]: value
            });

            // if (caseDetails.is_from_cwapp && target === "assessment") {
            //     await http.put(
            //         `${apiUrl}/ppassessment`,
            //         {
            //             appointment_id: caseDetails.appointment_id,
            //             assessment: value,
            //         },
            //         { headers: { conn: "pp" } }
            //     );

            //     socket.emit("reloadPPRecordList", caseDetails.patient_id);
            //     socket.emit("reloadPPRecordView", caseDetails.patient_id);
            // }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleSaveCaseNoteFormSubmit = async formData => {
        try {
            toggleSaveCaseNoteFormVisible();

            const {
                subjective,
                objective,
                assessment,
                plan,
                plain_notes,
                otherPlans
            } = caseDetails;

            setNotesLoading({
                spin: true,
                spinLabel: "Saving Case Note..."
            });
            const response = await http.post(
                `${apiUrl}/savedCaseNote`,
                {
                    noteName: formData.name,
                    userID: userID,
                    clinicID: clinicID,
                    note: {
                        subjective,
                        objective,
                        assessment,
                        plan,
                        plain_notes,
                        other_plans: JSON.stringify(otherPlans)
                    }
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );
            message.success("Case note successfully saved!");
            addSavedCaseNote(response.data.newSavedCaseNote);
            setNotesLoading({
                spin: false,
                spinLabel: "Saving Case Note..."
            });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const replaceHTML = HTMLString => {
        if (HTMLString) {
            HTMLString = HTMLString.replace(/<p><br><\/p>/g, "");
            HTMLString = HTMLString.replace(/<p>/g, "<div>");
            HTMLString = HTMLString.replace(/<\/p>/g, "</div>");
            //HTMLString = HTMLString.replace(/></g, "><@newline@><");
            return HTMLString;
        } else {
            return "";
        }
    };

    const loadCaseNote = async caseNote => {
        try {
            setNotesLoading({
                spin: true,
                spinLabel: "Loading Case Note..."
            });

            const {
                subjective,
                objective,
                assessment,
                plan,
                plain_notes,
                other_plans
            } = caseNote;

            const otherPlans = JSON.parse(other_plans);

            const deletedItems = items
                .filter(
                    item =>
                        item.item_type === "product" &&
                        item.status === "inactive"
                )
                .reduce((acc, item) => {
                    acc.push(item.item_id);
                    return acc;
                }, []);
            const deletedService = items
                .filter(
                    item =>
                        item.item_type === "service" &&
                        item.status === "inactive"
                )
                .reduce((acc, item) => {
                    acc.push(item.item_id);
                    return acc;
                }, []);
            const deletedPapers = papers
                .filter(paper => paper.status === "inactive")
                .reduce((acc, item) => {
                    acc.push(item.paper_id);
                    return acc;
                }, []);

            const currentItems = otherPlans.filter(i => i.type === "item");
            const currentServices = otherPlans.filter(
                i => i.type === "service"
            );
            const currentPapers = otherPlans.filter(
                i => i.type === "paper" || i.type === "labRequest"
            );

            const isThereItemDeleted = currentItems.find(
                item => deletedItems.indexOf(item.id) !== -1
            );
            const isThereServiceDeleted = currentServices.find(
                item => deletedService.indexOf(item.id) !== -1
            );
            const isTherePaperDeleted = currentPapers.find(
                item => deletedPapers.indexOf(item.id) !== -1
            );

            if (
                isThereItemDeleted ||
                isThereServiceDeleted ||
                isTherePaperDeleted
            ) {
                message.error(
                    `There were deleted/archived items on this case! Loading this case note is not allowed`
                );
            } else {
                let caseOtherPlans = {
                    fixedValues: {
                        case_id: caseDetails.case_id,
                        patient_id: caseDetails.patient_id
                    },
                    values: {
                        id: [],
                        value: [],
                        type: []
                    }
                };
                otherPlans.forEach(otherPlan => {
                    caseOtherPlans = {
                        ...caseOtherPlans,
                        values: {
                            id: [...caseOtherPlans.values.id, otherPlan.id],
                            value: [
                                ...caseOtherPlans.values.value,
                                otherPlan.value
                            ],
                            type: [
                                ...caseOtherPlans.values.type,
                                otherPlan.type
                            ]
                        }
                    };
                });
                await http.put(
                    `${apiUrl}/caseNote/${caseDetails.case_id}`,
                    {
                        subjective,
                        objective,
                        assessment,
                        plan,
                        plain_notes,
                        caseOtherPlans:
                            otherPlans.length !== 0 ? caseOtherPlans : null
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                message.success("Case note successfully loaded!");
                onChange(null, null, {
                    subjective,
                    objective,
                    assessment,
                    plan,
                    plain_notes,
                    otherPlans
                });
            }

            setNotesLoading({
                spin: false,
                spinLabel: "Loading Case Note..."
            });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleCaseNoteUse = selectedRecord => {
        toggleSavedCaseNotesListVisible();
        loadCaseNote(selectedRecord);
    };

    const handleSavedCaseNoteDelete = async savedCaseNoteID => {
        confirm({
            title: `Are you sure do you want to delete this case note`,
            content: `this cannot be undone`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();
                    deleteSavedCaseNote(savedCaseNoteID);
                    try {
                        await http.delete(
                            `${apiUrl}/savedCaseNote/${savedCaseNoteID}`
                        );
                    } catch (error) {
                        console.log("asdasd");
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleCaseNoteCopy = () => {
        //prevent if there's a delete data in this case
        const deletedItems = items
            .filter(
                item =>
                    item.item_type === "product" && item.status === "inactive"
            )
            .reduce((acc, item) => {
                acc.push(item.item_id);
                return acc;
            }, []);
        const deletedService = items
            .filter(
                item =>
                    item.item_type === "service" && item.status === "inactive"
            )
            .reduce((acc, item) => {
                acc.push(item.item_id);
                return acc;
            }, []);
        const deletedPapers = papers
            .filter(paper => paper.status === "inactive")
            .reduce((acc, item) => {
                acc.push(item.paper_id);
                return acc;
            }, []);

        const { otherPlans } = caseDetails;
        const currentItems = otherPlans.filter(i => i.type === "item");
        const currentServices = otherPlans.filter(i => i.type === "service");
        const currentPapers = otherPlans.filter(
            i => i.type === "paper" || i.type === "labRequest"
        );

        const isThereItemDeleted = currentItems.find(
            item => deletedItems.indexOf(item.id) !== -1
        );
        const isThereServiceDeleted = currentServices.find(
            item => deletedService.indexOf(item.id) !== -1
        );
        const isTherePaperDeleted = currentPapers.find(
            item => deletedPapers.indexOf(item.id) !== -1
        );

        if (
            isThereItemDeleted ||
            isThereServiceDeleted ||
            isTherePaperDeleted
        ) {
            message.error(
                `There were deleted/archived items on this case! Copying this case note is not allowed`
            );
            setCopiedCaseNote(null);
        } else {
            setCopiedCaseNote(caseDetails);
            message.success("Case note copied!");
        }
    };

    const handleCopiedNotePaste = () => {
        const {
            subjective,
            objective,
            assessment,
            plan,
            plain_notes,
            otherPlans
        } = copiedCaseNote;

        confirm({
            title: `Are you sure do you want to paste below Case Note content to this case?`,
            content: (
                <div>
                    <div style={{ marginTop: 16 }}>
                        <strong>Subjective:</strong>{" "}
                        {ReactHtmlParser(replaceHTML(subjective))}
                    </div>
                    <div>
                        <strong>Objective:</strong>{" "}
                        {ReactHtmlParser(replaceHTML(objective))}
                    </div>
                    <div>
                        <strong>Assessment:</strong>{" "}
                        {ReactHtmlParser(replaceHTML(assessment))}
                    </div>
                    <div>
                        <strong>Plan:</strong>{" "}
                        {ReactHtmlParser(replaceHTML(plan))}
                    </div>
                    <div>
                        <strong>Plain Notes:</strong>{" "}
                        {ReactHtmlParser(replaceHTML(plain_notes))}
                    </div>
                </div>
            ),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                loadCaseNote({
                    subjective,
                    objective,
                    assessment,
                    plan,
                    plain_notes,
                    other_plans: JSON.stringify(otherPlans)
                });
            }
        });
    };

    const NotesMenu = (
        <Menu>
            {!isCaseReadOnly && (
                <Menu.Item key="1" onClick={handleCaseNoteCopy}>
                    <Icon type="copy" /> Copy this Note
                </Menu.Item>
            )}
            {!isCaseReadOnly && (
                <Menu.Item
                    key="2"
                    disabled={copiedCaseNote ? false : true}
                    onClick={handleCopiedNotePaste}
                >
                    <Icon type="snippets" /> Paste copied Notes
                </Menu.Item>
            )}

            {!isCaseReadOnly && <Menu.Divider />}
            {!isCaseReadOnly && (
                <Menu.Item key="3" onClick={toggleSaveCaseNoteFormVisible}>
                    <Icon type="save" /> Save this Case Note
                </Menu.Item>
            )}
            {!isCaseReadOnly && (
                <Menu.Item key="4" onClick={toggleSavedCaseNotesListVisible}>
                    <Icon type="file-sync" /> Open Saved Notes
                </Menu.Item>
            )}
            {!isCaseReadOnly && <Menu.Divider />}
            <Menu.Item key="5" onClick={handleDisplayedCaseNoteChange}>
                <Icon type="file-text" /> Show{" "}
                {displayedCaseNote === "plain" ? (
                    "SOAP Notes"
                ) : (
                    <span>
                        Plain Notes
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <small>(Assessment Only)</small>
                    </span>
                )}
            </Menu.Item>
        </Menu>
    );

    const TimelineTitle = ({ title }) => {
        return (
            <h3
                style={{
                    marginBottom: "0.5em",
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: 1.4
                }}
            >
                {title}
            </h3>
        );
    };

    return (
        <Spin spinning={notesLoading.spin} tip={notesLoading.spinLabel}>
            <Card
                title="Note"
                className="caseNoteCard"
                extra={
                    <Dropdown overlay={NotesMenu} trigger={["click"]}>
                        <Tooltip title="Notes Options">
                            <Button>
                                <Icon type="more" />
                            </Button>
                        </Tooltip>
                    </Dropdown>
                }
                bodyStyle={{
                    padding: breakpoint.isNormalMobile
                        ? "24px 8px 0 8px"
                        : "24px 24px 0 24px"
                }}
                style={{ borderBottom: "none" }}
            >
                {displayedCaseNote === "soap" ? (
                    <Timeline>
                        <Timeline.Item>
                            <TimelineTitle title="Subjective" />
                            <ChiefComplaintEditor
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                onChange={onChange}
                            />
                            <Subjective
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                onChange={handleSOAPChange}
                            />
                            {soapEnabledImgManager.subjective && (
                                <ImageManager
                                    target="s"
                                    caseDetails={caseDetails}
                                    readOnly={isCaseReadOnly}
                                    breakpoint={breakpoint}
                                    onChange={onChange}
                                />
                            )}
                        </Timeline.Item>
                        <Timeline.Item>
                            <TimelineTitle title="Objective" />
                            <VitalSigns
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                breakpoint={breakpoint}
                                onChange={onChange}
                            />
                            <Objective
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                onChange={handleSOAPChange}
                            />
                            {soapEnabledImgManager.objective && (
                                <ImageManager
                                    target="o"
                                    caseDetails={caseDetails}
                                    readOnly={isCaseReadOnly}
                                    breakpoint={breakpoint}
                                    onChange={onChange}
                                />
                            )}
                        </Timeline.Item>
                        <Timeline.Item>
                            <TimelineTitle title="Assessment" />
                            <Assessment
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                onChange={handleSOAPChange}
                            />
                            {soapEnabledImgManager.assessment && (
                                <ImageManager
                                    target="a"
                                    caseDetails={caseDetails}
                                    readOnly={isCaseReadOnly}
                                    breakpoint={breakpoint}
                                    onChange={onChange}
                                />
                            )}
                        </Timeline.Item>
                        <Timeline.Item>
                            <TimelineTitle title="Plan" />
                            <Plan
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                onChange={handleSOAPChange}
                            />
                            {soapEnabledImgManager.plan && (
                                <ImageManager
                                    target="p"
                                    caseDetails={caseDetails}
                                    readOnly={isCaseReadOnly}
                                    breakpoint={breakpoint}
                                    onChange={onChange}
                                />
                            )}
                        </Timeline.Item>
                    </Timeline>
                ) : (
                    <div style={{ marginBottom: 20 }}>
                        <ChiefComplaintEditor
                            caseDetails={caseDetails}
                            readOnly={isCaseReadOnly}
                            onChange={onChange}
                        />
                        <PlainNotes
                            caseDetails={caseDetails}
                            readOnly={isCaseReadOnly}
                            onChange={handleSOAPChange}
                        />
                        {soapEnabledImgManager.assessment && (
                            <ImageManager
                                target="a"
                                caseDetails={caseDetails}
                                readOnly={isCaseReadOnly}
                                breakpoint={breakpoint}
                                onChange={onChange}
                            />
                        )}
                    </div>
                )}
            </Card>

            {appointmentItems.length !== 0 && (
                <div style={{ padding: "0 24px", backgroundColor: "#fff" }}>
                    <Alert
                        message="The patient paid the following:"
                        description={
                            <div>
                                {appointmentItems.map(item => (
                                    <div key={item.item_id}>
                                        &mdash; {item.item_name}
                                    </div>
                                ))}
                            </div>
                        }
                        showIcon
                    />
                </div>
            )}

            <OtherPlans
                caseDetails={caseDetails}
                patientDetails={patientDetails}
                breakpoint={breakpoint}
                readOnly={isCaseReadOnly}
                onChange={onChange}
            />

            <Modal
                title="Save Your Note"
                visible={isSaveCaseNoteFormVisible}
                footer={
                    <Button onClick={toggleSaveCaseNoteFormVisible}>
                        Cancel
                    </Button>
                }
                onCancel={toggleSaveCaseNoteFormVisible}
                destroyOnClose={true}
            >
                <SaveCaseNoteForm onSubmit={handleSaveCaseNoteFormSubmit} />
            </Modal>

            <Drawer
                title="Saved Notes"
                placement="right"
                visible={isSavedCaseNotesListVisible}
                onClose={toggleSavedCaseNotesListVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <SavedCaseNotesList
                    savedCaseNotes={savedCaseNotes}
                    userID={userID}
                    onUse={handleCaseNoteUse}
                    onDelete={handleSavedCaseNoteDelete}
                />
            </Drawer>
        </Spin>
    );
};

const mapStateToProps = state => ({
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    userID: state.UserDetails.user_id,
    defaultDisplayedCaseNote: state.Settings.defaultDisplayedCaseNote,
    savedCaseNotes: state.SavedCaseNotes,
    copiedCaseNote: state.CopiedCaseNote,
    items: state.Items,
    papers: state.Papers,
    soapEnabledImgManager: state.Settings.soapEnabledImgManager
    //caseNoteTemplates: state.CaseNoteTemplates,
});

const mapDispatchToProps = dispatch => ({
    addSavedCaseNote: savedCaseNote =>
        dispatch(addSavedCaseNote(savedCaseNote)),
    deleteSavedCaseNote: savedCaseNoteID =>
        dispatch(deleteSavedCaseNote(savedCaseNoteID)),
    setCopiedCaseNote: copiedCaseNote =>
        dispatch(setCopiedCaseNote(copiedCaseNote)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
