const batchInsertQueryFormatter = arr => {
    let objKeys = Object.keys(arr[0]);
    let newObj = {};
    objKeys.forEach(item => {
        newObj[item] = [];
    });
    arr.forEach(item => {
        Object.entries(item).forEach(([key, value]) => {
            newObj[key].push(value);
        });
    });
    return newObj;
};
export { batchInsertQueryFormatter };
