import React from "react";
import { Row, Col, Avatar, Button, Icon } from "antd";

const StreamHeader = ({
    videoCallDetails,
    streamWindowState,
    onStreamWindowStateChange,
}) => {
    const { img_path: patientImgPath } = videoCallDetails.patientDetails;

    const handleFullscreenClick = () => {
        let windowState = "";
        if (streamWindowState === "fullscreen") {
            windowState = "normal";
        } else {
            windowState = "fullscreen";
        }
        onStreamWindowStateChange(windowState);
    };

    const handleSwitcherClick = () => {
        onStreamWindowStateChange("minimized");
    };

    return (
        <div
            style={{
                padding: 10,
                backgroundColor: "#2b2b2b",
            }}
        >
            <Row type="flex" justify="space-between">
                <Col>
                    <div style={{ color: "#fff" }}>
                        {patientImgPath ? (
                            <Avatar
                                size={32}
                                shape="circle"
                                src={patientImgPath.replace(
                                    ".jpeg",
                                    "-thumb.jpeg"
                                )}
                                alt="Patient Image"
                            />
                        ) : (
                            <Avatar icon="user" size={32} shape="circle" />
                        )}
                        &nbsp;&nbsp;&nbsp;
                        <Icon type="video-camera" theme="filled" />{" "}
                        <span
                            style={{
                                color: "#87d068",
                                marginRight: 10,
                            }}
                        >
                            &bull;
                        </span>
                    </div>
                </Col>
                <Col>
                    {streamWindowState !== "fullscreen" && (
                        <Button
                            type="link"
                            icon="switcher"
                            style={{ marginRight: 5 }}
                            onClick={handleSwitcherClick}
                        />
                    )}
                    {streamWindowState !== "maximized" && (
                        <Button
                            type="link"
                            icon={
                                streamWindowState === "fullscreen"
                                    ? "fullscreen-exit"
                                    : "fullscreen"
                            }
                            onClick={handleFullscreenClick}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default StreamHeader;
