import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Row, Col, Typography, message } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const ChiefComplaintEditor = ({
    caseDetails,
    readOnly: isCaseReadOnly,
    onChange,
    setRequestError
}) => {
    const [chiefComplaint, setChiefComplaint] = useState("");

    const { Paragraph } = Typography;

    useEffect(() => {
        setChiefComplaint(caseDetails.chief_complaint);
    }, [caseDetails]);

    const handleChange = async string => {
        if (string.trim() === "") {
            message.error("Cheif complaint is required!");
        } else {
            if (string !== chiefComplaint) {
                setChiefComplaint(string);
                onChange("chief_complaint", string);

                try {
                    await http.put(`${apiUrl}/case/${caseDetails.case_id}`, {
                        chief_complaint: string
                    });
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        }
    };

    return (
        <Row type="flex">
            <Col>
                <h4 className="ant-list-item-meta-title">
                    Chief Complaint:&nbsp;&nbsp;&nbsp;
                </h4>
            </Col>
            <Col style={{ flexGrow: 1 }}>
                <i>
                    {isCaseReadOnly ? (
                        chiefComplaint
                    ) : (
                        <Paragraph
                            editable={{
                                onChange: handleChange
                            }}
                        >
                            {chiefComplaint}
                        </Paragraph>
                    )}
                </i>
            </Col>
        </Row>
    );
};

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(ChiefComplaintEditor);
