import React, { useState } from "react";
import { connect } from "react-redux";

import { addPaper, editPaper, deletePaper } from "redux/papers/paper.actions";
import { setRequestError } from "redux/system/system.actions";

import { Button, Icon, Empty, Table, Modal, Tag, Popover, message } from "antd";
import Drawer from "globalComponents/Drawer";
import ActionMenu from "globalComponents/ActionMenu";
import PaperForm from "./components/Forms/PaperForm";

import { generateString } from "helpers/randomStringGenerator";
import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

const Papers = ({
    breakpoint,
    papers,
    doctors,
    addPaper,
    editPaper,
    deletePaper,
    setRequestError
}) => {
    const [isPaperFormVisible, setIsPaperFormVisible] = useState(false);
    const [selectedPaper, setSelectedPaper] = useState(null);

    const { confirm } = Modal;

    const togglePaperFormVisible = () => {
        setIsPaperFormVisible(!isPaperFormVisible);
    };

    const showPaperForm = (selectedRecord = null) => {
        setSelectedPaper(selectedRecord);
        togglePaperFormVisible();
    };

    const handlePaperFormSubmit = async formData => {
        try {
            if (selectedPaper) {
                //edit
                editPaper(formData);
                togglePaperFormVisible();
                message.success("Paper successfully updated!");
                await http.put(
                    `${apiUrl}/paper/update/${formData.paper_id}`,
                    formData
                );
            } else {
                //add
                //checking
                let isPrescriptionPaperExist = papers.find(
                    paper =>
                        paper.category === "Prescription" &&
                        paper.doctor_id === formData.doctor_id &&
                        paper.status === "active"
                );
                let isInvoicePaperExist = papers.find(
                    paper =>
                        paper.category === "Invoice" &&
                        paper.doctor_id === formData.doctor_id &&
                        paper.status === "active"
                );
                let isLabRequestPaperExist = papers.find(
                    paper =>
                        paper.category === "Lab Request" &&
                        paper.doctor_id === formData.doctor_id &&
                        paper.status === "active"
                );
                let isRadiologyRequestPaperExist = papers.find(
                    paper =>
                        paper.category === "Radiology Request" &&
                        paper.doctor_id === formData.doctor_id &&
                        paper.status === "active"
                );
                let isOtherRequestPaperExist = papers.find(
                    paper =>
                        paper.category === "Other Request" &&
                        paper.doctor_id === formData.doctor_id &&
                        paper.status === "active"
                );

                if (
                    formData.category === "Prescription" &&
                    isPrescriptionPaperExist
                ) {
                    message.error(
                        `Prescription paper for doctor ${findUserName(
                            formData.doctor_id
                        )} was already added`
                    );
                } else if (
                    formData.category === "Invoice" &&
                    isInvoicePaperExist
                ) {
                    message.error(
                        `Invoice paper for doctor ${findUserName(
                            formData.doctor_id
                        )} was already added`
                    );
                } else if (
                    formData.category === "Lab Request" &&
                    isLabRequestPaperExist
                ) {
                    message.error(
                        `Lab Request paper for doctor ${findUserName(
                            formData.doctor_id
                        )} was already added`
                    );
                } else if (
                    formData.category === "Radiology Request" &&
                    isRadiologyRequestPaperExist
                ) {
                    message.error(
                        `Radiology Request paper for doctor ${findUserName(
                            formData.doctor_id
                        )} was already added`
                    );
                } else if (
                    formData.category === "Other Request" &&
                    isOtherRequestPaperExist
                ) {
                    message.error(
                        `Other Request paper for doctor ${findUserName(
                            formData.doctor_id
                        )} was already added`
                    );
                } else {
                    const paperID = generateString(5);
                    const newFormData = { paper_id: paperID, ...formData };
                    addPaper(newFormData);
                    togglePaperFormVisible();
                    message.success("Paper successfully added!");
                    await http.post(`${apiUrl}/paper`, newFormData);
                }
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handlePaperDelete = ({ paper_id, name }) => {
        confirm({
            title: `Are you sure do you want to archive paper ${name}?`,
            content: (
                <div>
                    <div>Archiving this paper will results to:</div>
                    <div style={{ marginTop: 15 }}>
                        <div>
                            {" "}
                            - A case note that contains this paper cannot be
                            copied
                        </div>
                        <div>
                            {" "}
                            - A case note that contains this paper cannot be
                            saved
                        </div>
                        <div>
                            {" "}
                            - A saved case note that contains this paper cannot
                            be used
                        </div>
                    </div>
                </div>
            ),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve(deletePaper(paper_id));
                    try {
                        await http.put(`${apiUrl}/paper/delete/${paper_id}`);
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const paperTableActionMenu = {
        title: "",
        key: "action",
        width: 50,
        render: (text, record) => {
            return (
                <ActionMenu
                    menu={[
                        {
                            label: "Edit",
                            icon: "edit",
                            onClick: () => showPaperForm(record)
                        },
                        {
                            label: "Archive",
                            icon: "container",
                            onClick: () => handlePaperDelete(record)
                        }
                    ]}
                    layout="compress"
                />
            );
        }
    };

    const papersTableColumns = [
        {
            title: "Paper Name",
            dataIndex: "name"
        },
        {
            title: "Category",
            dataIndex: "category",
            width: 150,
            sorter: (a, b) => a.category.localeCompare(b.category),
            sortDirections: ["descend", "ascend"]
        },
        {
            title: "Doctor",
            dataIndex: "doctor_id",
            width: 200,
            render: (text, record) => {
                return findUserName(record.doctor_id);
            },
            filters: [
                ...doctors.map(doctor => ({
                    text: doctor.doctor_name,
                    value: doctor.doctor_id
                }))
            ],
            onFilter: (value, record) => {
                return record.doctor_id === value;
            }
        },
        {
            title: "Layout",
            width: 150,
            render: (text, record) => {
                if (
                    record.is_customized === 0 &&
                    record.print_available === 0
                ) {
                    return (
                        <div>
                            No Layout{" "}
                            <Popover
                                placement="top"
                                content={
                                    <div
                                        style={{
                                            textAlign: "center",
                                            width: 250
                                        }}
                                    >
                                        <Icon
                                            type="info-circle"
                                            theme="twoTone"
                                        />{" "}
                                        Please send the photo of your desire
                                        paper layout to us. We will layout it
                                        using our Form Builder and notify you
                                        once done.
                                    </div>
                                }
                            >
                                <Icon type="info-circle" theme="twoTone" />
                            </Popover>
                        </div>
                    );
                } else if (record.is_customized === 0) {
                    return (
                        <div>
                            Default{" "}
                            <Popover
                                placement="top"
                                content={
                                    <div
                                        style={{
                                            textAlign: "center",
                                            width: 250
                                        }}
                                    >
                                        <Icon
                                            type="info-circle"
                                            theme="twoTone"
                                        />{" "}
                                        If you don't want the default paper
                                        layout, please send the photo of your
                                        desire paper layout to us. We will
                                        layout it using our Form Builder and
                                        notify you once done.
                                    </div>
                                }
                            >
                                <Icon type="info-circle" theme="twoTone" />
                            </Popover>
                        </div>
                    );
                } else {
                    return "Customized";
                }
            }
        },
        {
            title: "Print Available",
            width: 150,
            render: (text, record) => (
                <>
                    <Tag color={record.print_available ? "green" : "red"}>
                        {record.print_available ? "Yes" : "No"}
                    </Tag>
                </>
            )
        },
        {
            ...paperTableActionMenu
        }
    ];

    const papersTableColumnsMobile = [
        {
            title: "Papers",
            key: "papers",
            render: (text, record) => {
                const { name, category, doctor_id } = record;

                return (
                    <div>
                        <div>
                            <strong>{name}</strong>
                        </div>
                        <div>{category}</div>
                        <div>
                            <Tag style={{ marginTop: 5 }}>
                                {findUserName(doctor_id)}
                            </Tag>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            Print Available:{" "}
                            <span>
                                <Tag
                                    color={
                                        record.print_available ? "green" : "red"
                                    }
                                >
                                    {record.print_available ? "Yes" : "No"}
                                </Tag>
                                {!record.print_available && (
                                    <Popover
                                        placement="top"
                                        content={
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    width: 250
                                                }}
                                            >
                                                <Icon
                                                    type="info-circle"
                                                    theme="twoTone"
                                                />{" "}
                                                Please send the photo of this
                                                paper to us. We will layout it
                                                using our Form Builder and
                                                notify you once done.
                                            </div>
                                        }
                                    >
                                        <Icon
                                            type="info-circle"
                                            theme="twoTone"
                                        />
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                );
            }
        },
        {
            ...paperTableActionMenu
        }
    ];

    let defaultPapersTableColumns = papersTableColumns;
    if (breakpoint.isNormalMobile) {
        defaultPapersTableColumns = papersTableColumnsMobile;
    }

    return (
        <>
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    onClick={() => showPaperForm()}
                    style={{ marginBottom: 10 }}
                >
                    <Icon type="plus" />
                    Add Paper
                </Button>
            </div>

            {papers.length === 0 ? (
                <Empty description="No Paper found." />
            ) : (
                <div style={{ backgroundColor: "#fff" }}>
                    <Table
                        columns={defaultPapersTableColumns}
                        dataSource={papers.filter(
                            paper => paper.status === "active"
                        )}
                        rowKey="paper_id"
                    />
                </div>
            )}

            <Drawer
                title={selectedPaper ? "Edit Paper" : "Create Paper"}
                placement="right"
                visible={isPaperFormVisible}
                onClose={togglePaperFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <PaperForm
                    data={selectedPaper}
                    onSubmit={handlePaperFormSubmit}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = state => ({
    papers: state.Papers,
    doctors: state.UserDetails.doctors.filter(
        doctor => doctor.status === "active"
    )
});

const mapDispatchToProps = dispatch => ({
    addPaper: newPaper => dispatch(addPaper(newPaper)),
    editPaper: editedPaper => dispatch(editPaper(editedPaper)),
    deletePaper: paperID => dispatch(deletePaper(paperID)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Papers);
