import {
    SET_GROUP_SUCCESS,
    ADD_GROUP_SUCCESS,
    EDIT_GROUP_SUCCESS,
    DELETE_GROUP_SUCCESS
} from "./group.types";

export const setGroups = group => async dispatch => {
    dispatch({
        type: SET_GROUP_SUCCESS,
        payload: { group }
    });
};

export const addGroup = newGroup => async dispatch => {
    dispatch({
        type: ADD_GROUP_SUCCESS,
        payload: { newGroup }
    });
};

export const editGroup = editedGroup => async dispatch => {
    dispatch({
        type: EDIT_GROUP_SUCCESS,
        payload: { editedGroup }
    });
};

export const deleteGroup = groupID => async dispatch => {
    dispatch({
        type: DELETE_GROUP_SUCCESS,
        payload: { groupID }
    });
};
