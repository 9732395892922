import React from "react";
import { Row, Col, Checkbox } from "antd";

const GroupForm = ({ groups, groupIDs, breakpoint, onGroupChange }) => {
    return (
        <Row style={{ paddingLeft: breakpoint.isNormalMobile ? 0 : 20 }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                {groups.map((group, index) => {
                    const { group_id, name } = group;
                    const isChecked =
                        groupIDs.indexOf(group_id) === -1 ? false : true;
                    return (
                        index < groups.length / 2 && (
                            <div key={group_id}>
                                <Checkbox
                                    checked={isChecked}
                                    name={group_id.toString()}
                                    onChange={onGroupChange}
                                >
                                    {name}
                                </Checkbox>
                            </div>
                        )
                    );
                })}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                {groups.map((group, index) => {
                    const { group_id, name } = group;
                    const isChecked =
                        groupIDs.indexOf(group_id) === -1 ? false : true;

                    return (
                        index >= groups.length / 2 && (
                            <div key={group_id}>
                                <Checkbox
                                    checked={isChecked}
                                    name={group_id.toString()}
                                    onChange={onGroupChange}
                                >
                                    {name}
                                </Checkbox>
                            </div>
                        )
                    );
                })}
            </Col>
        </Row>
    );
};

export default GroupForm;
