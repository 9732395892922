import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Empty,
    Button,
    Checkbox,
    Collapse,
    Divider,
    message,
    Input
} from "antd";
import moment from "moment";

import TimeManager from "./components/TimeManager";

const TeleSchedBuilder = ({ portalSchedule, onOk }) => {
    const [schedules, setSchedules] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [schedDescription, setSchedDescription] = useState("");

    useEffect(() => {
        if (portalSchedule) {
            console.log(portalSchedule);
            setSchedules(portalSchedule.schedules);
            setSchedDescription(portalSchedule.description);
            setSelectedDays(portalSchedule.schedules.map(s => s.day));
        }
    }, [portalSchedule]);

    const { Panel } = Collapse;
    const { TextArea } = Input;

    function generateTimeSlots(
        opStartTime,
        opEndTime,
        duration,
        gap,
        selectedTimeSlots
    ) {
        const startTime = moment(opStartTime, "HH:mm:ss");
        const endTime = moment(opEndTime, "HH:mm:ss");

        //split time
        const splittedTime = [];
        var current = moment(startTime);
        let counter = 0;
        while (current <= endTime) {
            splittedTime.push(current.format("HH:mm:ss"));
            if (counter === 1) {
                current.add(gap, "minutes");
                counter = 0;
            } else {
                current.add(duration, "minutes");
                counter++;
            }
        }

        //group time
        let timeSlots = [];
        let timeSlot = {};
        let timeSlotKeys = [];
        counter = 0;
        splittedTime.forEach((time, index) => {
            if (counter === 2) {
                timeSlots.push(timeSlot);
                timeSlot = {};
                timeSlot.start = time;
                counter = 1;
            } else {
                if (counter === 1) {
                    timeSlot.end = time;
                    timeSlot.duration = duration;
                    timeSlot.key = index;
                    timeSlotKeys.push(index);
                } else {
                    timeSlot.start = time;
                }
                counter++;
            }
        });

        //remove unselected time slots
        const newTimeSlots = timeSlots.filter(timeSlot => {
            return selectedTimeSlots.indexOf(timeSlot.key) !== -1;
        });

        return newTimeSlots;
    }

    const handleDaySelect = values => {
        const dayNames = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];

        if (values.length > schedules.length) {
            //add new one
            //find newly added day
            const newDay = [...values, ...selectedDays]
                .sort()
                .filter(function (v, _, a) {
                    return a.indexOf(v) === a.lastIndexOf(v);
                })[0];

            const newSchedules = [
                ...schedules,
                {
                    day: newDay,
                    label: dayNames[newDay],
                    operation: [undefined, undefined],
                    timeSlotDuration: 15,
                    timeSlotGap: 5,
                    timeSlots: []
                }
            ];
            //sort
            newSchedules.sort((a, b) => (a.day > b.day ? 1 : -1));

            setSchedules(newSchedules);
        } else {
            const selectedDay = [...values, ...selectedDays]
                .sort()
                .filter(function (v, _, a) {
                    return a.indexOf(v) === a.lastIndexOf(v);
                })[0];

            const newSchedules = schedules.filter(
                sched => sched.day !== selectedDay
            );

            setSchedules(newSchedules);
        }

        setSelectedDays(values);
    };

    const handleScheduleChange = (
        day,
        operation,
        timeSlotDuration,
        timeSlotGap,
        selectedTimeSlots
    ) => {
        const newSchedules = schedules.map(schedule => {
            if (schedule.day === day) {
                return {
                    ...schedule,
                    operation,
                    timeSlotDuration,
                    timeSlotGap,
                    timeSlots: generateTimeSlots(
                        ...operation,
                        timeSlotDuration,
                        timeSlotGap,
                        selectedTimeSlots
                    )
                };
            }
            return schedule;
        });

        setSchedules(newSchedules);
    };

    const handleSaveSchedule = () => {
        //error checking
        const errors = [];

        schedules.forEach(schedule => {
            if (schedule.timeSlots.length === 0) {
                errors.push({
                    day: schedule.label,
                    message: "Timeslot cannot be empty."
                });
            }
        });

        if (schedules.length === 0) {
            message.error("Please select a Day");
        } else if (errors.length !== 0) {
            message.error(`Time slots cannot be empty on ${errors[0].day}`);
        } else if (schedDescription.trim() === "") {
            message.error("Please enter a summary of your schedule");
        } else {
            onOk({ description: schedDescription, schedules: schedules });
        }
    };

    const handleSchedDescChange = e => {
        setSchedDescription(e.target.value);
    };
    return (
        <div>
            <div>
                <h4>Describe the summary of your schedule</h4>
                <TextArea
                    value={schedDescription}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    placeholder="e.g. Weekdays 8am to 5pm"
                    onChange={handleSchedDescChange}
                />
            </div>
            <Divider />
            <Row gutter={16}>
                <Col lg={8} md={8}>
                    <h4>Select Day(s)</h4>
                    <Checkbox.Group
                        onChange={handleDaySelect}
                        value={selectedDays}
                    >
                        <div>
                            <Checkbox value={1}>Monday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={2}>Tuesday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={3}>Wednesday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={4}>Thursday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={5}>Friday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={6}>Saturday</Checkbox>
                        </div>
                        <div>
                            <Checkbox value={0}>Sunday</Checkbox>
                        </div>
                    </Checkbox.Group>
                </Col>
                <Col lg={16} md={16}>
                    <h4>Manage Time</h4>
                    {schedules.length === 0 ? (
                        <Empty description="No Selected Day(s)" />
                    ) : (
                        <Collapse>
                            {schedules.map(schedule => (
                                <Panel
                                    header={schedule.label}
                                    key={schedule.day}
                                >
                                    <TimeManager
                                        schedule={schedule}
                                        onChange={handleScheduleChange}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    )}
                </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
                <Button
                    type="primary"
                    style={{ marginTop: 20 }}
                    onClick={handleSaveSchedule}
                >
                    Save Schedule
                </Button>
            </div>
        </div>
    );
};

export default TeleSchedBuilder;
