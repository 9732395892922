import React, { useState } from "react";

import { Button, Checkbox } from "antd";

const VitalSignsForm = ({ form, onSubmit }) => {
    const [selectedVitalSigns, setSelectedVitalSigns] = useState([]);

    const handleCheckboxChange = checkedValules => {
        setSelectedVitalSigns(checkedValules);
    };

    const handleVitalSignsSelectOK = () => {
        onSubmit(selectedVitalSigns);
    };

    return (
        <div>
            <p>Select your needed vitals:</p>
            {form.length !== 0 ? (
                <Checkbox.Group onChange={handleCheckboxChange}>
                    {form.map(formInput => (
                        <div key={formInput.name}>
                            <Checkbox
                                value={formInput.name}
                            >{`${formInput.label} (${formInput.placeholder})`}</Checkbox>
                        </div>
                    ))}
                </Checkbox.Group>
            ) : (
                <div style={{ textAlign: "center" }}>No Vitals Form</div>
            )}
            <div style={{ marginTop: 10 }}>
                <Button type="primary" onClick={handleVitalSignsSelectOK}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default VitalSignsForm;
