import {
    SET_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_SUCCESS,
} from "./settings.types";

const INIT_STATE = {
    theme: "default",
    ageFormat: "YSYO",
    isSoundEnabled: true,
    pnformAutoCompleteAutoAdd: 1,
    defaultDisplayedCaseNote: "soap",
    patientForm: [],
    patientHistoryForm: [],
    vitalSignsForm: [],
    patientCardItems: [],
    prescriptionNoteForm: [],
    prescriptionWriting: [],
    soapEnabledImgManager: {},
    userPrivileges: {},
    caseList: {},
    appointmentList: {},
    notifications: {},
};

const settingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SETTINGS_SUCCESS:
            if (action.payload) {
                return {
                    ...state,
                    ...action.payload.newSettings,
                };
            } else {
                return state;
            }
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.payload.updatedSettings,
            };
        default:
            return state;
    }
};

export default settingsReducer;
