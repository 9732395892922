import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import ProfileManagement from "./components/ProfileManagement/Index";
import UserManagement from "./components/UserManagement/Index";
import PasswordManagement from "./components/PasswordManagement/Index";
import SubscriptionManagement from "./components/SubscriptionManagement/Index";

const MyAccount = ({ userDetails, breakpoint }) => {
    return (
        <Row gutter={16}>
            <Col xl={15}>
                <ProfileManagement
                    userDetails={userDetails}
                    breakpoint={breakpoint}
                />
                {userDetails.type === "doctor&owner" && (
                    <UserManagement
                        userDetails={userDetails}
                        breakpoint={breakpoint}
                    />
                )}
                <PasswordManagement userDetails={userDetails} />
            </Col>
            <Col xl={9}>
                <SubscriptionManagement userDetails={userDetails} />
            </Col>
        </Row>
    );
};

const mapStateToProps = state => ({
    userDetails: state.UserDetails
});

export default connect(mapStateToProps)(MyAccount);
