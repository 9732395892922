import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { editUserDetails } from "redux/userDetails/userDetails.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Button, Divider, message, Card } from "antd";
import ImageUploader from "globalComponents/Uploaders/ImageUploader";
import { resizeImgHelper } from "helpers/image";
import { b64ImgToFileHelper } from "helpers/image";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const DoctorSignature = ({
    ownerID,
    userID,
    userType,
    isSignatureUploaded,
    uploadServer,
    uploadEndpoint,
    editUserDetails,
    setRequestError,
}) => {
    const [doctorSignatureImg, setDoctorSignatureImg] = useState({
        origSizeImg: "",
        thumbnailSizeImg: "",
    });
    const [isSignatureSaving, setIsSignatureSaving] = useState(false);
    const [isSignatureDeleting, setIsSignatureDeleting] = useState(false);

    const [isSignatureChanged, setIsSignatureChanged] = useState(false);

    const [isDSEdited, setIsDSEdited] = useState(false);

    useEffect(() => {
        if (isSignatureUploaded) {
            setDoctorSignatureImg({
                origSizeImg: `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/images/signatures/${userID}.jpeg`,
                thumbnailSizeImg: `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/images/signatures/${userID}-thumb.jpeg`,
            });
        } else {
            setDoctorSignatureImg({
                origSizeImg: "",
                thumbnailSizeImg: "",
            });
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isDSEdited]);

    function beforeunload(e) {
        if (isDSEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const previewImage = (imageSrc) => {
        let origSizeImg = "";
        let thumbnailSizeImg = "";

        resizeImgHelper(imageSrc, 100, "img/jpg", function (base64Img) {
            thumbnailSizeImg = base64Img;
            resizeImgHelper(imageSrc, 200, "img/jpg", function (base64Img) {
                origSizeImg = base64Img;
                setDoctorSignatureImg({
                    origSizeImg,
                    thumbnailSizeImg,
                });
                setIsSignatureChanged(true);
                setIsDSEdited(true);
            });
        });
    };

    const saveSignature = async () => {
        //upload only if there's a selected image
        try {
            if (isSignatureChanged) {
                setIsSignatureSaving(true);

                //create filename
                const signatureOrigSizeImgFilename = userID + ".jpeg";
                const signatureThumbnailImgFilename = userID + "-thumb.jpeg";

                //convert b64 img to file
                const signatureOrigSizeImgFile = b64ImgToFileHelper(
                    doctorSignatureImg.origSizeImg,
                    signatureOrigSizeImgFilename
                );
                const signatureThumbnailSizeImgFile = b64ImgToFileHelper(
                    doctorSignatureImg.thumbnailSizeImg,
                    signatureThumbnailImgFilename
                );

                //get file size
                const signatureOrigSizeImgFileSize =
                    signatureOrigSizeImgFile.size;
                const signatureThumbnailSizeImgFileSize =
                    signatureThumbnailSizeImgFile.size;

                const signatureImgSize =
                    signatureOrigSizeImgFileSize +
                    signatureThumbnailSizeImgFileSize;

                //prepare to upload
                let fd = new FormData();
                fd.append("uniFile", signatureOrigSizeImgFile);
                fd.append("uniFile", signatureThumbnailSizeImgFile);

                const uploadResponse = await http.post(
                    `${apiUrl}/doctorSignatureImage`,
                    fd,
                    {
                        params: {
                            space_server: uploadServer,
                            space_bucket_name: uploadEndpoint,
                            root_dir: cwaUploadRootDir,
                            owner_id: ownerID,
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                if (uploadResponse.data.error) {
                    message.error(
                        "Failed to save signature. Upload image error"
                    );
                    setIsSignatureSaving(false);
                    return false;
                } else {
                    await http.put(
                        `${apiUrl}/signature/${userID}`,
                        {
                            is_signature_uploaded: 1,
                            signature_img_size: signatureImgSize,
                        },
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            }),
                        }
                    );
                    editUserDetails({ is_signature_uploaded: 1 });
                    setIsSignatureSaving(false);
                    setIsDSEdited(false);
                    message.success("Signature successfully saved");
                }
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const removeSignature = async () => {
        try {
            //update to 1
            setIsSignatureDeleting(true);
            await http.put(
                `${apiUrl}/signature/${userID}`,
                { is_signature_uploaded: 0 },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                }
            );
            setDoctorSignatureImg({
                origSizeImg: "",
                thumbnailSizeImg: "",
            });
            setIsSignatureDeleting(false);
            editUserDetails({ is_signature_uploaded: 0 });
            setIsDSEdited(false);
            message.success("Signature successfully removed");
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    return (
        <>
            <Card>
                <div style={{ marginBottom: 10 }}>
                    <Link to="/settings">
                        <Icon type="arrow-left" /> Back to Settings Menu
                    </Link>
                    <Divider />
                    {userType !== "staff" ? (
                        <div>
                            <ImageUploader
                                image={doctorSignatureImg.origSizeImg}
                                previewImage={previewImage}
                                width={150}
                            />
                            <div style={{ textAlign: "center" }}>
                                <p>
                                    This will be shown on papers that need of
                                    signature. We resize image and we don't crop
                                    it. If the signature was not fit on the box
                                    its ok no worries you may try it. If the
                                    signature was not reflected after changed
                                    please{" "}
                                    <a
                                        href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        clear the cache of the browser.
                                    </a>
                                </p>
                                <Button
                                    loading={isSignatureSaving}
                                    disabled={isSignatureDeleting}
                                    type="primary"
                                    style={{ marginRight: 5 }}
                                    onClick={saveSignature}
                                >
                                    {isSignatureSaving
                                        ? "Saving Signature"
                                        : "Save Signature"}
                                </Button>
                                <Button
                                    loading={isSignatureDeleting}
                                    disabled={isSignatureSaving}
                                    type="danger"
                                    onClick={removeSignature}
                                >
                                    {isSignatureDeleting
                                        ? "Removing Signature"
                                        : "Remove Signature"}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>Not Applicable for Staffs</p>
                        </div>
                    )}
                </div>
            </Card>
            <Prompt
                when={isDSEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    // SEnabledImgManager: state.Settings.soapEnabledImgManager,
    // clinicID: state.UserDetails.clinicDetails.clinic_id,
    ownerID: state.UserDetails.owner_id,
    userID: state.UserDetails.user_id,
    userType: state.UserDetails.type,
    isSignatureUploaded: state.UserDetails.is_signature_uploaded,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    editUserDetails: (editedUserDetails) =>
        dispatch(editUserDetails(editedUserDetails)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSignature);
