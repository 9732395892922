import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Layout,
    Menu,
    Icon,
    Dropdown,
    Button,
    Row,
    Col,
    Drawer,
    Avatar,
} from "antd";
import SidebarMenu from "./SidebarMenu";
import ToggleSound from "globalComponents/ToggleSound";
import Chat from "globalComponents/Chat/Chat";
import Notification from "globalComponents/Notification/Index";

import http from "services/httpService";
import { apiUrl, appBasePath, platform } from "config.json";
import logo from "assets/img/logo50x50.png";

const TopBar = ({
    title,
    breakpoint,
    userID,
    userFirstname,
    userLastname,
    degree,
    clinicName,
    ...props
}) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const { Header } = Layout;

    const toggleSidebarVisible = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleLogout = async () => {
        await http.post(`${apiUrl}/activityLog`, {
            system_activity_id: 2,
            data: null,
            user_id: userID,
        });

        localStorage.clear();
        if (platform === "web") {
            window.location = `${appBasePath}/`;
        } else {
            window.location.reload();
        }
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => props.history.push("/dashboard")}>
                <div style={{ textAlign: "center", color: "#595959" }}>
                    You are on:
                    <br />
                    <Icon type="home" theme="twoTone" />{" "}
                    <strong>{clinicName}</strong>
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => props.history.push("/account")}>
                <Icon type="user" /> My Account
            </Menu.Item>
            <Menu.Item onClick={() => props.history.push("/settings")}>
                <Icon type="setting" /> Settings
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={handleLogout}>
                <Icon type="logout" /> Logout
            </Menu.Item>
        </Menu>
    );

    const SidebarLogo = (
        <div style={{ padding: 5 }}>
            <Row type="flex" justify="space-between">
                <Col>
                    <div
                        style={{
                            paddingTop: 4,
                            paddingLeft: 13,
                        }}
                    >
                        <img src={logo} alt="logo" />
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingTop: 4,
                            paddingRight: 30,
                            color: "#265992",
                        }}
                    >
                        Clinic Web
                        <br />
                        Assistant
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <>
            <Header
                style={{
                    background: "#fff",
                    borderLeft: "1px solid #e8e8e8",
                    padding: 0,
                }}
            >
                <Row type="flex" justify="space-between">
                    <Col>
                        {breakpoint.isTabletPortrait ? (
                            <Icon
                                className="sideBarCollapseTrigger"
                                type={"menu-fold"}
                                onClick={toggleSidebarVisible}
                            />
                        ) : (
                            <div style={{ paddingLeft: 20 }}>
                                <h1>{title}</h1>
                            </div>
                        )}
                    </Col>
                    <Col>
                        {/* dont render if no userdetails */}
                        {/* {userID && (
                            
                        )} */}
                        <Row type="flex" gutter={6}>
                            <Col>
                                <ToggleSound breakpoint={breakpoint} />
                            </Col>
                            <Col>
                                <Chat breakpoint={breakpoint} />
                            </Col>
                            <Col>
                                <Notification breakpoint={breakpoint} />
                            </Col>
                            <Col>
                                <Dropdown
                                    overlay={menu}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                    overlayStyle={{ width: 250 }}
                                >
                                    <Button
                                        style={{
                                            marginRight: breakpoint.isNormalMobile
                                                ? 2
                                                : 10,
                                            height: 55,
                                            border: "none",
                                        }}
                                    >
                                        <Avatar
                                            icon="user"
                                            style={{
                                                marginRight: breakpoint.isNormalMobile
                                                    ? 0
                                                    : 8,
                                                color: "#2196F3",
                                                backgroundColor: "#ddf0ff",
                                            }}
                                        />

                                        {!breakpoint.isNormalMobile &&
                                            `${userFirstname} ${userLastname} ${
                                                degree ? degree : ""
                                            }`}
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Drawer
                    placement="left"
                    onClose={toggleSidebarVisible}
                    visible={isSidebarVisible}
                >
                    {SidebarLogo}
                    <SidebarMenu
                        activeMenu={title}
                        breakpoint={breakpoint}
                        onSelect={toggleSidebarVisible}
                    />
                </Drawer>
            </Header>
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    userFirstname: state.UserDetails.firstname,
    userLastname: state.UserDetails.lastname,
    degree: state.UserDetails.degree,
    clinicName: state.UserDetails.clinicDetails.name,
});

export default withRouter(connect(mapStateToProps)(TopBar));
