import React from "react";
import { Divider } from "antd";
import logo from "assets/img/logo50x50.png";
import itmgLogo from "assets/img/itmg_logo.png";

const ContactContent = () => {
    return (
        <div>
            <img src={logo} alt="logo" />
            <br />
            <br />
            <p>Dear Sir/ madam,</p>
            <p>Thank you using Clinic Web Assistant!</p>
            <p>Online Payment is temporarily undergoing construction.</p>
            <p>
                However, you may call us via this number{" "}
                <strong>+63 49 306 8597</strong>,<strong>+63 9176432516</strong>
                , for payment and subscription activation guidelines.
            </p>
            <br />
            <p>Thank You!</p>
            <p>
                CWA Team
                <br />
                cwa@itmgent.net
            </p>
            <Divider />
            <div>
                Created By:
                <br />
                <img src={itmgLogo} alt="itmg logo" />
                <br />
                <a
                    href="https://itmgent.net"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://itmgent.net
                </a>
            </div>
        </div>
    );
};

export default ContactContent;
