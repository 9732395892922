import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Row, Col, Table, Tag, Button, Icon, Modal } from "antd";

import moment from "moment";
import ContentLoader from "globalComponents/ContentLoader";
import ActionMenu from "globalComponents/ActionMenu";
import PatientCard from "globalComponents/PatientCard";
import ModalDetails from "globalComponents/ModalDetails";
import PatientCaseDetails from "views/CaseNote/components/PatientCases/components/PatientCaseDetails";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PatientCaseList = ({
    breakpoint,
    doctors,
    userID,
    setRequestError,
    plan,
    caseListSettings,
    ...props
}) => {
    const [isContentLoading, setIsContentLoading] = useState(false);
    // const [patientCardDetails, setPatientCardDetails] = useState({
    //     patient_id: null
    // });
    const [patientID, setPatientID] = useState(null);
    const [isPatientNotFound, setIsPatientNotFound] = useState(false);
    const [patientCases, setPatientCases] = useState([]);
    const [selectedCase, setSelectedCase] = useState(null);

    const [isCaseDetailsVisible, setIsCaseDetailsVisible] = useState(false);

    const { confirm } = Modal;

    useEffect(() => {
        const patientID = props.match.params.patient_id;

        const fetchCases = async () => {
            try {
                setIsContentLoading(true);
                const response = await http.get(
                    `${apiUrl}/patientCases/${patientID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                setPatientCases(response.data.patientCases);
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        if (patientID) {
            setPatientID(patientID);
            //setPatientCardDetails({ patient_id: patientID });
            fetchCases();
        } else {
            setIsPatientNotFound(true);
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const toggleCaseDetailsVisible = () => {
        setIsCaseDetailsVisible(!isCaseDetailsVisible);
    };

    const handlePatientNotFound = () => {
        setIsPatientNotFound(true);
    };

    const showCaseDetails = selectedRecord => {
        setSelectedCase(selectedRecord);
        toggleCaseDetailsVisible();
    };

    const handleCaseOpen = caseID => {
        props.history.push(`/casenote/${caseID}`);
    };

    const handleCaseDelete = async selectedRecord => {
        const {
            case_id,
            patient_fullname,
            chief_complaint,
            doctor_id,
            clinic_id
        } = selectedRecord;
        confirm({
            title: `Are you sure do you want to archive this case?`,
            //content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    setPatientCases([
                        ...patientCases.filter(
                            patientCase => patientCase.case_id !== case_id
                        )
                    ]);

                    resolve();
                    try {
                        await http.put(`${apiUrl}/case/${case_id}`, {
                            status: "inactive"
                        });

                        socket.emit("caseDeleted", {
                            doctorName: findUserName(doctor_id),
                            chiefComplaint: chief_complaint,
                            patientFullname: patient_fullname,
                            clinicID: clinic_id
                        });
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const caseTableColumns = [
        {
            title: "Cases",
            key: "patient",
            className: "cursorPointer",
            render: (text, record, index) => {
                const {
                    chief_complaint,
                    complaint_datetime,
                    doctor_id,
                    status,
                    appointment_id,
                    is_from_cwapp
                } = record;

                const TimeAndDoctorDetails = () => {
                    return (
                        <span>
                            {/* TIME */}
                            <span>
                                <Icon
                                    type="clock-circle"
                                    theme="twoTone"
                                    style={{ marginRight: 8 }}
                                />
                                {moment(complaint_datetime).format("LLL")}
                            </span>

                            {/* DIVIDER */}
                            {!breakpoint.isNormalMobile && doctors.length > 1 && (
                                <span
                                    style={{
                                        color: "#e8e8e8",
                                        margin: "0 10px"
                                    }}
                                >
                                    |
                                </span>
                            )}
                            {breakpoint.isNormalMobile && <br />}

                            {/* DOCTOR */}
                            {doctors.length > 1 && (
                                <span>
                                    <Icon
                                        type="user"
                                        style={{ marginRight: 8 }}
                                    />
                                    {findUserName(doctor_id)}
                                </span>
                            )}
                            <div style={{ marginTop: 5 }}>
                                {is_from_cwapp ? (
                                    <Tag color="purple">
                                        <Icon type="pushpin" /> From Patient
                                        Portal
                                    </Tag>
                                ) : (
                                    ""
                                )}
                            </div>
                        </span>
                    );
                };

                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor = caseListSettings.pendingStatusColor;
                        break;
                    case "hold":
                        statusColor = caseListSettings.holdStatusColor;
                        break;
                    case "no-show":
                        statusColor = caseListSettings.noShowStatusColor;
                        break;
                    case "done":
                        statusColor = caseListSettings.doneStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }

                return (
                    <div>
                        {/* CHIEF COMPLAINT */}
                        <div>
                            {index + 1}.{" "}
                            <span style={{ fontStyle: "italic" }}>
                                &ldquo;
                                {chief_complaint}
                                &rdquo;
                            </span>
                            {appointment_id && (
                                <Icon
                                    type="calendar"
                                    theme="twoTone"
                                    style={{ marginLeft: 5 }}
                                />
                            )}
                        </div>

                        <div>
                            {/* TIME AND DOCTOR */}
                            <div
                                style={{
                                    marginBottom: 5,
                                    marginTop: breakpoint.isNormalMobile ? 8 : 5
                                }}
                            >
                                <TimeAndDoctorDetails />
                            </div>

                            {/* STATUS */}
                            {breakpoint.isNormalMobile && (
                                <div style={{ marginTop: 8 }}>
                                    <Tag color={statusColor}>{status}</Tag>
                                </div>
                            )}

                            {/* <div style={{ marginTop: 12 }}>
                                        <Tag color={statusColor}>{status}</Tag>
                                    </div> */}
                        </div>
                    </div>
                );
            },
            filters: [
                ...doctors.map(doctor => ({
                    text: doctor.doctor_name,
                    value: doctor.doctor_id
                }))
            ],
            onFilter: (value, record) => {
                return record.doctor_id === value;
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            className: "cursorPointer",
            render: status => {
                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor = caseListSettings.pendingStatusColor;
                        break;
                    case "hold":
                        statusColor = caseListSettings.holdStatusColor;
                        break;
                    case "no-show":
                        statusColor = caseListSettings.noShowStatusColor;
                        break;
                    case "done":
                        statusColor = caseListSettings.doneStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }
                return <Tag color={statusColor}>{status}</Tag>;
            },
            filters: [
                {
                    text: "Pending",
                    value: "pending"
                },
                {
                    text: "Done",
                    value: "done"
                },
                {
                    text: "Hold",
                    value: "hold"
                },
                {
                    text: "No Show",
                    value: "no-show"
                }
            ],
            onFilter: (value, record) => record.status === value
        },
        {
            title: "",
            key: "action",
            width: breakpoint.isNormalMobile ? 50 : 162,
            className: "cursorPointer",
            render: (text, record) => {
                let menu = [
                    {
                        label: "Open Case",
                        icon: "folder-open",
                        onClick: () =>
                            props.history.push(`/casenote/${record.case_id}`)
                    },
                    {
                        label: "Manage Billing",
                        icon: "credit-card",
                        onClick: () => {
                            props.history.push(
                                `/billing/${record.patient_id}/case/${record.case_id}`
                            );
                        }
                    },
                    {
                        label: "Archive",
                        icon: "container",
                        onClick: () => handleCaseDelete(record)
                    }
                ];

                if (plan !== "basic") {
                    if (record.status !== "done") {
                        menu.splice(1, 1);
                    }
                } else {
                    menu.splice(1, 1);
                }

                if (record.doctor_id !== userID) {
                    menu = menu.filter(m => m.label !== "Archive");
                }
                if (!breakpoint.isNormalMobile) {
                    menu.splice(0, 1);
                }

                return (
                    <>
                        {!breakpoint.isNormalMobile && (
                            <Button
                                type="link"
                                style={{ marginRight: 8 }}
                                onClick={() =>
                                    props.history.push(
                                        `/casenote/${record.case_id}`
                                    )
                                }
                            >
                                <Icon type="folder-open" /> Open
                            </Button>
                        )}
                        {menu.length !== 0 && (
                            <ActionMenu menu={menu} layout="compress" />
                        )}
                    </>
                );
            }
        }
    ];

    if (breakpoint.isNormalMobile) {
        caseTableColumns.splice(1, 1);
    }

    return (
        <>
            {isPatientNotFound ? (
                "No Patient Found"
            ) : isContentLoading ? (
                <ContentLoader />
            ) : (
                <>
                    <Row gutter={16}>
                        <Col xl={8} lg={8}>
                            <div style={{ marginBottom: 20 }}>
                                <PatientCard
                                    patientID={patientID}
                                    moreDetailsVisible={true}
                                    bordered={true}
                                    breakpoint={breakpoint}
                                    showEditPatientInfoBtn={true}
                                    onNotFound={handlePatientNotFound}
                                />
                            </div>
                        </Col>
                        <Col xl={16} lg={16}>
                            <div style={{ backgroundColor: "#fff" }}>
                                <Table
                                    columns={caseTableColumns}
                                    dataSource={patientCases}
                                    rowKey="case_id"
                                    onRow={record => ({
                                        onClick: () => {
                                            showCaseDetails(record);
                                        }
                                    })}
                                    // pagination={{
                                    //     simple: breakpoint.isNormalMobile
                                    //         ? true
                                    //         : false,
                                    //     showTotal: (total, range) =>
                                    //         `${range[0]}-${range[1]} of ${total} cases`,
                                    // }}
                                    pagination={false}
                                />
                            </div>
                        </Col>
                    </Row>
                    <ModalDetails
                        visible={isCaseDetailsVisible}
                        footer={null}
                        destroyOnClose={true}
                        style={{ top: 20 }}
                        width={320}
                        onCancel={toggleCaseDetailsVisible}
                    >
                        <PatientCaseDetails
                            selectedCase={selectedCase}
                            onCaseOpen={handleCaseOpen}
                        />
                    </ModalDetails>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    doctors: state.UserDetails.doctors.filter(
        doctor => doctor.status === "active"
    ),
    userID: state.UserDetails.user_id,
    plan: state.UserDetails.subscriptionDetails.plan,
    caseListSettings: state.Settings.caseList
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientCaseList);
