import React from "react";
import { connect } from "react-redux";
import {
    Icon,
    Tag,
    Button,
    Menu,
    Dropdown,
    Tooltip,
    Card,
    Typography,
} from "antd";
import moment from "moment";
import { findUserName } from "helpers/userNameFinder";

const CaseDetails = ({
    caseDetails,
    readOnly: isCaseReadOnly,
    onCaseStatusChange,
    onCaseDelete,
    onCaseCancel,
    doctors,
    caseListSettings,
}) => {
    const { Text } = Typography;

    let doctorName = "";
    let complaintDateTime = "";
    let lastUpdate = "";
    let statusText = "";
    let statusColor = "#ccc";
    let isFromCwapp = 0;

    if (caseDetails) {
        const {
            complaint_datetime,
            doctor_id,
            status,
            is_from_cwapp,
            updated_at,
        } = caseDetails;
        doctorName = findUserName(doctor_id);
        complaintDateTime = moment(complaint_datetime).format("LLL");
        lastUpdate = moment(updated_at).format("LLL");
        isFromCwapp = is_from_cwapp;

        statusText = status;

        switch (status) {
            case "pending":
                statusColor = caseListSettings.pendingStatusColor;
                break;
            case "hold":
                statusColor = caseListSettings.holdStatusColor;
                break;
            case "no-show":
                statusColor = caseListSettings.noShowStatusColor;
                break;
            case "done":
                statusColor = caseListSettings.doneStatusColor;
                break;
            default:
                statusColor = "#ccc";
        }
    }

    const CaseDetailsMenu = (
        <Menu>
            <Menu.Item
                key="1"
                disabled={
                    !(
                        caseDetails &&
                        caseDetails.status !== "done" &&
                        !isCaseReadOnly
                    )
                }
                onClick={() => onCaseStatusChange("hold")}
            >
                Hold Case
            </Menu.Item>

            <Menu.Item
                key="2"
                disabled={
                    !(
                        caseDetails &&
                        caseDetails.status !== "done" &&
                        !isCaseReadOnly
                    )
                }
                onClick={() => onCaseStatusChange("no-show")}
            >
                Patient No Show
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="3"
                disabled={isCaseReadOnly}
                onClick={() => onCaseDelete(caseDetails.case_id)}
            >
                Archive Case
            </Menu.Item>

            {caseDetails &&
                caseDetails.status === "pending" &&
                !isCaseReadOnly &&
                doctors.length > 1 && (
                    <Menu.Item key="4" onClick={onCaseCancel}>
                        Cancel Case
                    </Menu.Item>
                )}
        </Menu>
    );

    return (
        <Card
            style={{ marginBottom: 16 }}
            title="Case Info"
            extra={
                <Dropdown
                    overlay={CaseDetailsMenu}
                    trigger={["click"]}
                    placement="bottomRight"
                >
                    <Tooltip title="Case Options">
                        <Button type="link" size="small">
                            Options <Icon type="down" />
                        </Button>
                    </Tooltip>
                </Dropdown>
            }
        >
            <div>
                <Tooltip title="Assigned Doctor" placement="left">
                    <h4 className="ant-list-item-meta-title">
                        <Icon type="user" style={{ marginRight: 8 }} />{" "}
                        {doctorName}
                    </h4>
                </Tooltip>
                <Tooltip title="Complaint Date and Time" placement="left">
                    <div>
                        <Icon type="clock-circle" style={{ marginRight: 8 }} />{" "}
                        {complaintDateTime}
                    </div>
                </Tooltip>
                <Tooltip title="Case Status" placement="left">
                    <div style={{ marginTop: 5 }}>
                        <Icon type="tag" style={{ marginRight: 12 }} />
                        <Tag color={statusColor}>{statusText}</Tag>
                        {isCaseReadOnly && <Tag color="blue">read-only</Tag>}
                    </div>
                </Tooltip>
            </div>
            <div style={{ marginTop: 16 }}>
                {isFromCwapp ? (
                    <Tag color="purple">
                        <Icon type="pushpin" /> From Patient Portal
                    </Tag>
                ) : (
                    ""
                )}
            </div>
            <div style={{ marginTop: 16 }}>
                <Text type="secondary">Last update: {lastUpdate}</Text>
            </div>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    caseListSettings: state.Settings.caseList,
});

export default connect(mapStateToProps)(CaseDetails);
