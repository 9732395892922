import React, { useState } from "react";
import { Modal } from "antd";
import Webcam from "react-webcam";
import PatientImageUploader from "globalComponents/Uploaders/PatientImageUploader";
import isMobile from "helpers/deviceDetector";
import { resizeImgHelper } from "helpers/image";

const PatientImage = ({ image, breakpoint, onImageChange }) => {
    const [isCameraVisible, setIsCameraVisible] = useState(false);

    //react-webcam ref
    let webcam = null;
    const setRef = (wc) => {
        webcam = wc;
    };

    const toggleCameraVisible = () => {
        setIsCameraVisible(!isCameraVisible);
    };

    const previewImage = (imageSrc) => {
        let origSizeImg = "";
        let cardSizeImg = "";
        let thumbnailSizeImg = "";

        resizeImgHelper(imageSrc, 50, "img/jpg", function (base64Img) {
            thumbnailSizeImg = base64Img;
            resizeImgHelper(imageSrc, 120, "img/jpg", function (base64Img) {
                cardSizeImg = base64Img;
                resizeImgHelper(imageSrc, 200, "img/jpg", function (base64Img) {
                    origSizeImg = base64Img;
                    onImageChange({
                        origSizeImg,
                        cardSizeImg,
                        thumbnailSizeImg,
                    });
                });
            });
        });
    };

    const capture = () => {
        const imageSrc = webcam.getScreenshot();
        previewImage(imageSrc);
        toggleCameraVisible();
    };

    return (
        <>
            <div
                style={{
                    marginBottom: 20,
                    textAlign: breakpoint.isNormalMobile ? "center" : "left",
                }}
            >
                <PatientImageUploader
                    image={image}
                    toggleModal={toggleCameraVisible}
                    previewImage={previewImage}
                    mobile={isMobile}
                />
            </div>

            <Modal
                title="Patient Image"
                visible={isCameraVisible}
                okText="Capture"
                style={{ top: 20 }}
                onCancel={toggleCameraVisible}
                onOk={capture}
                destroyOnClose={true}
            >
                <div style={{ textAlign: "center" }}>
                    <Webcam
                        style={{ border: "1px solid #e4e4e4" }}
                        height={300}
                        width={300}
                        screenshotFormat="image/jpeg"
                        screenshotWidth={300}
                        screenshotQuality={0.9}
                        ref={setRef}
                        videoConstraints={
                            isMobile.any() && {
                                facingMode: { exact: "environment" },
                            }
                        }
                    />
                </div>
            </Modal>
        </>
    );
};

export default PatientImage;
