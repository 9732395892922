import React, { useState, useEffect, memo } from "react";
import {
    Row,
    Col,
    Empty,
    Checkbox,
    Form,
    Card,
    Icon,
    Tooltip,
    message,
    Modal,
    Typography
} from "antd";
import moment from "moment";

import Drawer from "globalComponents/Drawer";
import FormItemInput from "./components/Inputs/FormItemInput";
import FormItemTextarea from "./components/Inputs/FormItemTextarea";
import FormItemAutoComplete from "./components/Inputs/FormItemAutoComplete";
import FormItemSelect from "./components/Inputs/FormItemSelect";
import FormItemRadio from "./components/Inputs/FormItemRadio";
import FormItemCheckbox from "./components/Inputs/FormItemCheckbox";
import FormItemDatePicker from "./components/Inputs/FormItemDatePicker";
import FormInputBirthday from "./components/Inputs/FormItemInputBirthday";
import FormInputForm from "./components/Forms/FormInputForm";

import { generateString } from "helpers/randomStringGenerator";

const DynamicForm = ({
    mode,
    form: formStructure,
    errors,
    layout,
    breakpoint, //used in builder mode only
    controls,
    onInputChange,
    onStructureChange, //used in builder mode only
    onSave //used in builder mode only
}) => {
    const [form, setForm] = useState(formStructure);
    const [selectedFormInput, setSelectedFormInput] = useState(null);
    const [isFormInputFormVisible, setIsFormInputFormVisible] = useState(false);
    const [formInputFormMode, setFormInputFormMode] = useState("add");
    const [inputCheckboxes, setInputCheckboxes] = useState({});

    const { confirm } = Modal;
    const { Title } = Typography;

    useEffect(() => {
        setForm(formStructure);
    }, [formStructure]);

    const handleCheckboxChange = e => {
        const { name, checked } = e.target;
        const newInputCheckboxes = {};
        Object.keys(inputCheckboxes).forEach(checkboxName => {
            newInputCheckboxes[checkboxName] = false;
        });
        setInputCheckboxes({ ...newInputCheckboxes, [name]: checked });

        setSelectedFormInput(
            checked ? form.find(formInput => formInput.name === name) : null
        );
    };

    const toggleFormInputFormVisible = () => {
        setIsFormInputFormVisible(!isFormInputFormVisible);
    };

    const showFormInputForm = action => {
        if (action === "add") {
            setFormInputFormMode("add");
            toggleFormInputFormVisible();
        } else {
            if (selectedFormInput) {
                setFormInputFormMode("edit");
                toggleFormInputFormVisible();
            } else {
                message.error("Please select Form Input");
            }
        }
    };

    const handleInputChange = (name, value) => {
        if (onInputChange) {
            onInputChange(name, value.trim());
        }
        const newForm = form.map(formInput => {
            if (formInput.name === name) {
                return { ...formInput, value };
            }
            return formInput;
        });
        setForm(newForm);
        if (onStructureChange) {
            onStructureChange();
        }
    };

    const handleFormInputFormSubmit = formData => {
        const newFormData = { ...formData };

        //filter and populate options
        if (newFormData.type === "autocomplete") {
            const options = newFormData.options
                .split("\n")
                .map(o => o.trim())
                .filter(o => o !== "")
                .map(o => o);
            newFormData.options = options;
        } else if (newFormData.options !== "") {
            const options = newFormData.options
                .split("\n")
                .map(o => o.trim())
                .filter(o => o !== "")
                .map(o => ({ label: o, value: o }));

            newFormData.options = options;
        }

        if (formInputFormMode === "add") {
            newFormData.name = generateString(8, "i");
            setForm([...form, newFormData]);
            toggleFormInputFormVisible();
        } else {
            //check if type was changed.
            let isTypeChanged = false;
            form.forEach(formInput => {
                if (formInput.name === newFormData.name) {
                    if (formInput.type !== newFormData.type) {
                        isTypeChanged = true;
                    }
                }
            });

            if (isTypeChanged) {
                confirm({
                    title: `Form Input Type Changed`,
                    content:
                        "If you changed the type of existing form input, the system will delete it and create a new one instead. The newly created one will not recognize the existing data to it if there's any. Are you sure do you want to change the type of this form input?",
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                        const newForm = form.map(formInput => {
                            if (formInput.name === newFormData.name) {
                                const { type } = newFormData;
                                newFormData.name = generateString(8, "i");
                                return {
                                    ...newFormData,
                                    value: "",
                                    options:
                                        type === "select" ||
                                        type === "radio" ||
                                        type === "checkbox" ||
                                        type === "autocomplete"
                                            ? newFormData.options
                                            : []
                                };
                            }
                            return formInput;
                        });

                        setForm(newForm);
                        setSelectedFormInput(null);
                        toggleFormInputFormVisible();
                    }
                });
            } else {
                const newForm = form.map(formInput => {
                    if (formInput.name === newFormData.name) {
                        return {
                            ...formInput,
                            ...newFormData
                        };
                    }
                    return formInput;
                });
                setForm(newForm);
                setSelectedFormInput(newFormData);
                toggleFormInputFormVisible();
            }
        }
        onStructureChange();
    };

    const handleFormInputDelete = () => {
        if (selectedFormInput) {
            if (selectedFormInput.fixed) {
                message.error("Fixed form input cannot be deleted");
            } else {
                confirm({
                    title: `Are you sure do you want to delete the selected input?`,
                    content: "This cannot be undone",
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                        const newForm = form.filter(
                            formInput =>
                                formInput.name !== selectedFormInput.name
                        );

                        setForm(newForm);
                        setSelectedFormInput(null);
                        setInputCheckboxes({});
                        onStructureChange();
                    }
                });
            }
        } else {
            message.error("Please select Form Input");
        }
    };

    const moveFormInput = direction => {
        if (selectedFormInput) {
            const newForm = [...form];

            let formIndex = 0;
            newForm.forEach((formInput, index) => {
                if (formInput.name === selectedFormInput.name) {
                    formIndex = index;
                }
            });

            if (direction === "up" && formIndex !== 0) {
                const removedItem = newForm.splice(formIndex, 1)[0];
                newForm.splice(formIndex - 1, 0, removedItem);

                //update
                setForm(newForm);
                onStructureChange();
            } else if (
                direction === "down" &&
                formIndex !== newForm.length - 1
            ) {
                const removedItem = newForm.splice(formIndex, 1)[0];
                newForm.splice(formIndex + 1, 0, removedItem);

                //update
                setForm(newForm);
                onStructureChange();
            }
        } else {
            message.error("Please select Form Input");
        }
    };

    const handleDefaultValueClear = () => {
        setForm(form.map(formInput => ({ ...formInput, value: "" })));
        onStructureChange();
    };

    const handleFormSave = () => {
        onSave(form);
        setInputCheckboxes({});
    };

    const DisplayText = ({ data }) => {
        const { type, label, value } = data;
        switch (type) {
            case "checkbox":
                const values = value.split("X@X").join(",");
                return (
                    <Row>
                        <Col span={8}>{label}:</Col>
                        <Col span={16}>{values || <span>&mdash;</span>}</Col>
                    </Row>
                );
            case "datepicker":
            case "birthday":
                return (
                    <Row>
                        <Col span={8}>{label}:</Col>
                        <Col span={16}>
                            {value ? (
                                moment(value).format("LL")
                            ) : (
                                <span>&mdash;</span>
                            )}
                        </Col>
                    </Row>
                );
            case "title":
                return (
                    <Title level={4} style={{ fontSize: 16 }}>
                        {label}
                    </Title>
                );
            default:
                return (
                    <Row>
                        <Col span={8}>{label}:</Col>
                        <Col span={16}>{value || <span>&mdash;</span>}</Col>
                    </Row>
                );
        }
    };

    const renderFormItems = () => {
        return form.map(formItem => {
            const inputType = formItem.type;
            const name = formItem.name;
            const error = errors[name];

            const checkbox = (
                <div style={{ paddingTop: 10 }}>
                    <Checkbox
                        name={name}
                        checked={inputCheckboxes[name]}
                        onChange={handleCheckboxChange}
                    />
                </div>
            );

            let formItemComponent = null;

            switch (inputType) {
                case "input":
                    formItemComponent = (
                        <FormItemInput
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "textarea":
                    formItemComponent = (
                        <FormItemTextarea
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "autocomplete":
                    formItemComponent = (
                        <FormItemAutoComplete
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "select":
                    formItemComponent = (
                        <FormItemSelect
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "radio":
                    formItemComponent = (
                        <FormItemRadio
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "checkbox":
                    formItemComponent = (
                        <FormItemCheckbox
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "datepicker":
                    formItemComponent = (
                        <FormItemDatePicker
                            data={formItem}
                            error={error}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "birthday":
                    formItemComponent = (
                        <FormInputBirthday
                            data={formItem}
                            error={error}
                            breakpoint={breakpoint}
                            onInputChange={handleInputChange}
                        />
                    );
                    break;
                case "verticalSpace":
                    formItemComponent = (
                        <div
                            key={name}
                            style={{ padding: 10, textAlign: "center" }}
                        >
                            {mode === "builder" && "--vertical space--"}
                        </div>
                    );
                    break;
                case "title":
                    formItemComponent = (
                        <div key={name}>
                            <Title level={4} style={{ fontSize: 16 }}>
                                {formItem.label}
                            </Title>
                        </div>
                    );
                    break;
                default:
                    return null;
            }

            return mode === "builder" ? (
                <Row gutter={12} key={name}>
                    <Col span={2}>{checkbox}</Col>
                    <Col span={22}>{formItemComponent}</Col>
                </Row>
            ) : mode === "text" ? (
                <DisplayText data={formItem} key={name} />
            ) : (
                <div key={name}>{formItemComponent}</div>
            );
        });
    };

    const builderModeCardMenu = [
        <Tooltip title="Add Form Input">
            <Icon type="plus" onClick={() => showFormInputForm("add")} />
        </Tooltip>,
        <Tooltip title="Edit Form Input">
            <Icon type="edit" onClick={() => showFormInputForm("edit")} />
        </Tooltip>,
        <Tooltip title="Delete Form Input">
            <Icon type="delete" onClick={handleFormInputDelete} />
        </Tooltip>,
        <Tooltip title="Move Form Input Up">
            <Icon type="up" onClick={() => moveFormInput("up")} />
        </Tooltip>,
        <Tooltip title="Move Form Input Down">
            <Icon type="down" onClick={() => moveFormInput("down")} />
        </Tooltip>,
        <Tooltip title="Clear Default Values">
            <Icon type="minus-circle" onClick={handleDefaultValueClear} />
        </Tooltip>,
        <Tooltip title="Save Form">
            <Icon type="save" onClick={handleFormSave} />
        </Tooltip>
    ];

    if (controls && controls.add.disabled) {
        builderModeCardMenu[0] = null;
    }
    if (controls && controls.edit.disabled) {
        builderModeCardMenu[1] = null;
    }
    if (controls && controls.delete.disabled) {
        builderModeCardMenu[2] = null;
    }
    if (controls && controls.moveUp.disabled) {
        builderModeCardMenu[3] = null;
    }
    if (controls && controls.moveDown.disabled) {
        builderModeCardMenu[4] = null;
    }
    if (controls && controls.clearDefaultValues.disabled) {
        builderModeCardMenu[5] = null;
    }

    return (
        <>
            {mode === "builder" ? (
                <Card
                    actions={builderModeCardMenu.filter(menu => menu)}
                    bodyStyle={{
                        maxHeight: 422,
                        overflow: "auto"
                    }}
                >
                    {form.length === 0 ? (
                        <Empty />
                    ) : (
                        <Form
                            layout="horizontal"
                            labelCol={{
                                span: layout ? layout.labelCol.span : 8
                            }}
                            wrapperCol={{
                                span: layout ? layout.wrapperCol.span : 16
                            }}
                            style={{ width: "100%" }}
                        >
                            {renderFormItems()}
                        </Form>
                    )}

                    <Drawer
                        title="Form Input"
                        placement="right"
                        visible={isFormInputFormVisible}
                        onClose={toggleFormInputFormVisible}
                        destroyOnClose={true}
                        width={breakpoint.isNormalMobile ? 320 : 400}
                    >
                        <FormInputForm
                            mode={formInputFormMode}
                            data={selectedFormInput}
                            onSubmit={handleFormInputFormSubmit}
                        />
                    </Drawer>
                </Card>
            ) : form.length === 0 ? (
                <Empty />
            ) : (
                <Form
                    layout="horizontal"
                    labelCol={{
                        span: layout ? layout.labelCol.span : 8
                    }}
                    wrapperCol={{
                        span: layout ? layout.wrapperCol.span : 16
                    }}
                    style={{ width: "100%" }}
                >
                    {renderFormItems()}
                </Form>
            )}
        </>
    );
};

export default memo(DynamicForm);
