import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Icon,
    Checkbox,
    Divider,
    Tooltip,
    message,
    Card
} from "antd";

import ContentLoader from "globalComponents/ContentLoader";
import Drawer from "globalComponents/Drawer";
import VitalSignsForm from "./components/Forms/VitalSignsForm";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const VitalSigns = ({
    VSForm,
    breakpoint,
    clinicID,
    updateSettings,
    setRequestError
}) => {
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [vitalSignsForm, setVitalSignsForm] = useState([]);
    const [selectedVitalsSignsInputForm, setSelectedVitalsSignsInputForm] =
        useState(VSForm);
    const [selectedVitalSign, setSelectedVitalSign] = useState(null);
    const [isVitalSignsListVisible, setIsVitalSignsListVisible] =
        useState(false);
    const [inputCheckboxes, setInputCheckboxes] = useState({});
    const [isVSEdited, setIsVSEdited] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsContentLoading(true);
                const response = await http.get(`${apiUrl}/vitalSigns`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                });
                const vitalSignsForm = response.data.vitalSigns.map(
                    vitalSign => ({
                        type: "input",
                        name: vitalSign.vital_sign_id,
                        label: vitalSign.name,
                        placeholder: vitalSign.measurement,
                        value: "",
                        options: "",
                        required: false,
                        transform: "default",
                        validationRules: ""
                    })
                );
                setVitalSignsForm(vitalSignsForm);
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isVSEdited]);

    function beforeunload(e) {
        if (isVSEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleCheckboxChange = e => {
        const { name, checked } = e.target;
        const newInputCheckboxes = {};
        Object.keys(inputCheckboxes).forEach(checkboxName => {
            newInputCheckboxes[checkboxName] = false;
        });
        setInputCheckboxes({ ...newInputCheckboxes, [name]: checked });
        setSelectedVitalSign(
            selectedVitalsSignsInputForm.find(
                formInput => formInput.name === name
            )
        );
    };

    const toggleVitalSignsListVisible = () => {
        setIsVitalSignsListVisible(!isVitalSignsListVisible);
    };

    const moveVitalSign = direction => {
        if (selectedVitalSign) {
            const newSelectedVitalSigns = [...selectedVitalsSignsInputForm];

            let formIndex = 0;
            newSelectedVitalSigns.forEach((formInput, index) => {
                if (formInput.name === selectedVitalSign.name) {
                    formIndex = index;
                }
            });

            if (direction === "up" && formIndex !== 0) {
                const removedItem = newSelectedVitalSigns.splice(
                    formIndex,
                    1
                )[0];
                newSelectedVitalSigns.splice(formIndex - 1, 0, removedItem);

                //update
                setSelectedVitalsSignsInputForm(newSelectedVitalSigns);
                setIsVSEdited(true);
            } else if (
                direction === "down" &&
                formIndex !== newSelectedVitalSigns.length - 1
            ) {
                const removedItem = newSelectedVitalSigns.splice(
                    formIndex,
                    1
                )[0];
                newSelectedVitalSigns.splice(formIndex + 1, 0, removedItem);

                //update
                setSelectedVitalsSignsInputForm(newSelectedVitalSigns);
                setIsVSEdited(true);
            }
        } else {
            message.error("Please select Vitals");
        }
    };

    const handleVitalSignFormSubmit = vitalSignList => {
        setSelectedVitalsSignsInputForm(
            vitalSignsForm.filter(
                formInput => vitalSignList.indexOf(formInput.name) !== -1
            )
        );
        toggleVitalSignsListVisible();
        setIsVSEdited(true);
    };

    const handleVitalSignsFormSave = async () => {
        try {
            setInputCheckboxes({});
            updateSettings({ vitalSignsForm: selectedVitalsSignsInputForm });
            setIsVSEdited(false);
            message.success("Vitals signs settings successfully saved!");
            const VSFrom = JSON.stringify(selectedVitalsSignsInputForm);
            await http.put(`${apiUrl}/settings/${clinicID}`, {
                vital_signs_form: VSFrom
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message
            });
        }
    };

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <div style={{ width: breakpoint.isNormalMobile ? "100%" : 600 }}>
                <Card
                    actions={[
                        <Tooltip title="Change Vital Sign List">
                            <Icon
                                type="unordered-list"
                                onClick={toggleVitalSignsListVisible}
                            />
                        </Tooltip>,
                        <Tooltip title="Move Up">
                            <Icon
                                type="up"
                                onClick={() => moveVitalSign("up")}
                            />
                        </Tooltip>,
                        <Tooltip title="Move Down">
                            <Icon
                                type="down"
                                onClick={() => moveVitalSign("down")}
                            />
                        </Tooltip>,
                        <Tooltip title="Save Vital Signs">
                            <Icon
                                type="save"
                                onClick={handleVitalSignsFormSave}
                            />
                        </Tooltip>
                    ]}
                    bodyStyle={{
                        maxHeight: 422,
                        overflow: "auto"
                    }}
                >
                    {selectedVitalsSignsInputForm.length !== 0 ? (
                        selectedVitalsSignsInputForm.map(formInput => (
                            <Row key={formInput.name}>
                                <Col span={2}>
                                    <Checkbox
                                        name={formInput.name}
                                        checked={
                                            inputCheckboxes[formInput.name]
                                        }
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>
                                <Col
                                    span={22}
                                >{`${formInput.label} (${formInput.placeholder})`}</Col>
                            </Row>
                        ))
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            No Vitals Form Selected
                        </div>
                    )}
                </Card>
            </div>

            <Drawer
                title="List of Vitals"
                placement="right"
                visible={isVitalSignsListVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={toggleVitalSignsListVisible}
            >
                <VitalSignsForm
                    form={vitalSignsForm}
                    onSubmit={handleVitalSignFormSubmit}
                />
            </Drawer>

            <Prompt
                when={isVSEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    VSForm: state.Settings.vitalSignsForm,
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    updateSettings: updatedSettings =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(VitalSigns);
