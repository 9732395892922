import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Divider, Icon, message, Card, Switch } from "antd";

import DynamicForm from "globalComponents/DynamicForm/Index";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientForm = ({
    breakpoint,
    prescriptionNoteForm,
    itemAutoAdd,
    clinicID,
    updateSettings,
    setRequestError,
}) => {
    const [isItemAutoAdd, setIsItemAutoAdd] = useState(
        itemAutoAdd === 1 ? true : false
    );
    const [isPNEdited, setIsPNEdited] = useState(false); //patient form edited?

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isPNEdited]);

    function beforeunload(e) {
        if (isPNEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleStuctureChange = () => {
        setIsPNEdited(true);
    };

    const handlePrescriptionNoteFormSave = async (form) => {
        try {
            updateSettings({ prescriptionNoteForm: form });
            setIsPNEdited(false);
            message.success("Prescription note settings successfully saved!");
            const PNForm = JSON.stringify(form);
            await http.put(`${apiUrl}/settings/${clinicID}`, {
                prescription_note_form: PNForm,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message,
            });
        }
    };

    const handleSwitchChange = async (checked) => {
        try {
            setIsItemAutoAdd(checked);
            updateSettings({
                pnformAutoCompleteAutoAdd: checked ? 1 : 0,
            });
            setIsPNEdited(false);
            await http.put(`${apiUrl}/settings/${clinicID}`, {
                pnform_autocomplete_auto_add: checked ? 1 : 0,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message,
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>
            <Divider />
            <div style={{ width: breakpoint.isNormalMobile ? "100%" : 600 }}>
                <DynamicForm
                    mode="builder"
                    form={prescriptionNoteForm}
                    errors={{}}
                    layout={null}
                    breakpoint={breakpoint}
                    controls={{
                        add: { disabled: true },
                        edit: { disabled: false },
                        delete: { disabled: true },
                        moveUp: { disabled: false },
                        moveDown: { disabled: false },
                        clearDefaultValues: { disabled: true },
                    }}
                    onInputChange={null}
                    onStructureChange={handleStuctureChange}
                    onSave={handlePrescriptionNoteFormSave}
                />
            </div>
            <Divider />
            <Switch
                checked={isItemAutoAdd}
                onChange={handleSwitchChange}
            />{" "}
            Your input text will be automatically added on input options.
            <Prompt
                when={isPNEdited}
                message="Changes you made on Patient Form may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    prescriptionNoteForm: state.Settings.prescriptionNoteForm,
    itemAutoAdd: state.Settings.pnformAutoCompleteAutoAdd,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
