import React, { useState } from "react";
import { Button } from "antd";
import DynamicForm from "globalComponents/DynamicForm/Index";

const VitalsForm = props => {
    const { form, onSubmit } = props;
    const [vitalSignForm, setVitalSignForm] = useState(form);

    const handleVitalSignsInputChange = (name, value) => {
        setVitalSignForm(
            vitalSignForm.map(formInput => {
                if (formInput.name === name) {
                    return { ...formInput, value };
                }
                return formInput;
            })
        );
    };

    const handleSubmit = async e => {
        e.preventDefault();
        onSubmit(vitalSignForm);
    };

    return (
        <>
            <DynamicForm
                mode="form"
                form={vitalSignForm}
                errors={{}}
                layout={null}
                onInputChange={handleVitalSignsInputChange}
            />

            <Button
                type="primary"
                style={{ marginLeft: 164, marginTop: 10 }}
                onClick={handleSubmit}
            >
                Save
            </Button>
        </>
    );
};

export default VitalsForm;
