import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Button,
    Divider,
    message,
    Card,
    Radio,
    Menu,
    Dropdown,
    Icon
} from "antd";

import ModalDetails from "globalComponents/ModalDetails";
import AddMessageForm from "./components/Forms/AddMessageForm";

import { generateString } from "helpers/randomStringGenerator";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Messages = ({ queueingDetails, clinicID, setRequestError }) => {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [isMessageFormVisible, setIsMessageFormVisible] = useState(false);

    useEffect(() => {
        setMessages(queueingDetails.messages);
        setSelectedMessage(queueingDetails.data.queueing_message_id);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, [queueingDetails]);

    const toggleMessageFormVisible = () => {
        setIsMessageFormVisible(!isMessageFormVisible);
    };

    const handleSelectedMessageChange = e => {
        setSelectedMessage(e.target.value);
    };

    const handleMessageFormSubmit = async formData => {
        const messageID = generateString(11);
        const newFormData = {
            queueing_message_id: messageID,
            ...formData
        };

        setMessages([...messages, newFormData]);
        toggleMessageFormVisible();

        try {
            await http.post(`${apiUrl}/queueingMessage`, newFormData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleMessageDelete = async () => {
        if (selectedMessage) {
            const newMessages = messages.filter(
                message => message.queueing_message_id !== selectedMessage
            );

            setMessages(newMessages);
            setSelectedMessage("");

            try {
                await http.delete(
                    `${apiUrl}/queueingMessage/${selectedMessage}`
                );
                if (newMessages.length === 0) {
                    await http.put(`${apiUrl}/queueDetails/${clinicID}`, {
                        queueing_message_id: ""
                    });
                }
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("No Selected Playlist");
        }
    };

    const saveAndApply = async () => {
        try {
            if (selectedMessage) {
                setIsSaving(true);
                await http.put(
                    `${apiUrl}/queueDetails/${clinicID}`,
                    {
                        queueing_message_id: selectedMessage
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                setIsSaving(false);
                message.success("Successfully Saved");

                socket.emit("reloadQueueing", clinicID);
            } else {
                message.error("Please select message");
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px"
    };

    const menu = (
        <Menu>
            <Menu.Item key="0" onClick={toggleMessageFormVisible}>
                Add Message
            </Menu.Item>
            <Menu.Item key="1" onClick={handleMessageDelete}>
                Delete Selected
            </Menu.Item>
        </Menu>
    );

    return (
        <Card
            title="Messages"
            style={{ marginBottom: 10 }}
            extra={
                <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                        type="link"
                        size="small"
                        onClick={e => e.preventDefault()}
                    >
                        Options <Icon type="down" />
                    </Button>
                </Dropdown>
            }
        >
            {messages.length !== 0 ? (
                <>
                    <div>
                        <strong>Select Message</strong>
                    </div>
                    <Radio.Group
                        value={selectedMessage}
                        onChange={handleSelectedMessageChange}
                    >
                        {messages.map(message => (
                            <Radio
                                key={message.queueing_message_id}
                                value={message.queueing_message_id}
                                style={radioStyle}
                            >
                                {message.title} &mdash; {message.message}
                            </Radio>
                        ))}
                    </Radio.Group>
                </>
            ) : (
                <div>No Messages</div>
            )}

            <Divider />
            <Button
                type="primary"
                size="large"
                loading={isSaving}
                onClick={saveAndApply}
            >
                {isSaving ? "Saving" : "Save and Apply"}
            </Button>

            <ModalDetails
                title="Screen Message"
                visible={isMessageFormVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                width={320}
                onCancel={toggleMessageFormVisible}
            >
                <AddMessageForm onSubmit={handleMessageFormSubmit} />
            </ModalDetails>
        </Card>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
