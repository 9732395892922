import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Button, Icon, Table, Input, Row, Col, Avatar, Tag } from "antd";

import ContentLoader from "globalComponents/ContentLoader";
import ActionMenu from "globalComponents/ActionMenu";

import { formatAge } from "helpers/ageFormatter";
import http from "services/httpService.js";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Patients = ({
    breakpoint,
    ageFormat,
    plan,
    setRequestError,
    ...props
}) => {
    const [isContentLoading, setContentLoading] = useState(true);
    const [isPatientTableLoading, setIsPatientTableLoading] = useState(false);

    const [patients, setPatients] = useState([]);
    const [isPatientSearch, setIsPatientSearch] = useState(false);
    const [tablePagination, setTablePagination] = useState({
        pageSize: 10,
        showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        simple: breakpoint.isNormalMobile ? true : false,
    });

    const { Search } = Input;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setContentLoading(true);
                const response = await getPatients({
                    countPatients: true,
                    limit: tablePagination.pageSize,
                    searchingPatientTable: false,
                });

                setPatients(response.data.patients);
                setTablePagination({
                    ...tablePagination,
                    current: 1,
                    total: response.data.patientCount,
                });
                setContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    async function getPatients(SSPTableParams) {
        return http.get(`${apiUrl}/patients`, {
            params: {
                ...SSPTableParams,
            },
            cancelToken: new CancelToken(function executor(c) {
                CancelRequest = c;
            }),
        });
    }

    const handleTableChange = async (pagination, filters, sorter) => {
        setTablePagination({
            ...tablePagination,
            current: pagination.current,
        });

        try {
            setIsPatientTableLoading(true);
            const response = await getPatients({
                countPatients: false,
                limit: tablePagination.pageSize,
                currentPage: pagination.current,
                searchingPatientTable: false,
            });
            setPatients(response.data.patients);
            setIsPatientTableLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const handleTableSearch = async (patientName) => {
        try {
            setIsPatientSearch(true);
            setIsPatientTableLoading(true);

            const response = await http.get(`${apiUrl}/search/patients`, {
                params: {
                    search: patientName,
                },
                cancelToken: new CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            });

            setPatients(response.data.patients);
            setTablePagination({
                ...tablePagination,
                current: 1,
                total: response.data.patientCount,
            });
            setIsPatientTableLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const handleTableSearchKeyUp = async (e) => {
        if (e.target.value.trim() === "") {
            try {
                setIsPatientSearch(false);
                setIsPatientTableLoading(true);
                const response = await getPatients({
                    countPatients: true,
                    limit: tablePagination.pageSize,
                    currentPage: 1,
                    searchingPatientTable: false,
                });

                setPatients(response.data.patients);
                setTablePagination({
                    ...tablePagination,
                    current: 1,
                    total: response.data.patientCount,
                });

                setIsPatientTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        }
    };

    const patientTableColumns = [
        {
            title: "Patient Name",
            key: "patient",
            render: (text, record) => {
                const {
                    patient_id,
                    fullname,
                    gender,
                    birthday,
                    img_path,
                    is_from_cwapp,
                    is_from_legacy,
                } = record;

                const avatarProps = img_path
                    ? {
                          size: 32,
                          src: is_from_legacy
                              ? img_path.replace(
                                    `${patient_id}-`,
                                    `thumb/${patient_id}-`
                                )
                              : img_path.replace(".jpeg", "-thumb.jpeg"),
                      }
                    : { size: 32, icon: "user" };

                return (
                    <div>
                        <Row
                            type="flex"
                            justify="start"
                            style={{ flexWrap: "nowrap" }}
                        >
                            <Col>
                                <span style={{ marginRight: 16 }}>
                                    <Avatar {...avatarProps} />
                                </span>
                            </Col>
                            <Col>
                                {/* PATIENT NAME */}
                                <h4 className="ant-list-item-meta-title">
                                    {fullname}
                                </h4>
                                <div>
                                    {gender}, {formatAge(ageFormat, birthday)}
                                </div>

                                {is_from_cwapp ? (
                                    <div style={{ marginTop: 8 }}>
                                        <Tag color="purple">
                                            <Icon type="pushpin" /> From Patient
                                            Portal
                                        </Tag>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </div>
                );
            },
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => {
                const menu = [
                    {
                        label: "Edit",
                        icon: "edit",
                        onClick: () =>
                            props.history.push(
                                `/patient/edit/${record.patient_id}`
                            ),
                    },
                    {
                        label: "Create case",
                        icon: "folder-add",
                        onClick: () =>
                            props.history.push(
                                `/case/create/${record.patient_id}`
                            ),
                    },
                    {
                        label: "View Cases",
                        icon: "folder",
                        onClick: () =>
                            props.history.push(
                                `/patient/cases/${record.patient_id}`
                            ),
                    },
                    {
                        label: "View Billing Records",
                        icon: "file-text",
                        onClick: () =>
                            props.history.push(`/billing/${record.patient_id}`),
                    },
                ];

                if (plan === "basic") {
                    menu.splice(3, 1);
                }

                return <ActionMenu menu={menu} layout="compress" />;
            },
        },
    ];

    const isNormalMobile = breakpoint.isNormalMobile;

    return (
        <>
            {isContentLoading ? (
                <ContentLoader />
            ) : (
                <>
                    <Row type="flex" justify="space-between">
                        <Col order={isNormalMobile ? 2 : 1}>
                            <Search
                                placeholder="Search patient name"
                                enterButton
                                onKeyUp={handleTableSearchKeyUp}
                                onSearch={(value) =>
                                    handleTableSearch(value.trim())
                                }
                                style={{
                                    width: isNormalMobile
                                        ? "calc(100vw - 10px)"
                                        : 400,
                                    marginBottom: isNormalMobile ? 10 : 0,
                                }}
                            />
                        </Col>
                        <Col order={isNormalMobile ? 1 : 2}>
                            <div
                                style={{
                                    textAlign: "right",
                                    width: isNormalMobile
                                        ? "calc(100vw - 10px)"
                                        : "auto",
                                }}
                            >
                                <Button
                                    type="primary"
                                    style={{
                                        marginBottom: isNormalMobile ? 20 : 10,
                                    }}
                                    onClick={() =>
                                        props.history.push("/patient/create")
                                    }
                                >
                                    <Icon type="plus" /> Create Patient
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ backgroundColor: "#fff" }}>
                        <Table
                            columns={patientTableColumns}
                            rowKey="patient_id"
                            dataSource={patients}
                            pagination={
                                isPatientSearch
                                    ? false
                                    : {
                                          ...tablePagination,
                                          simple: isNormalMobile ? true : false,
                                      }
                            }
                            loading={isPatientTableLoading}
                            onChange={handleTableChange}
                        />
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    ageFormat: state.Settings.ageFormat,
    plan: state.UserDetails.subscriptionDetails.plan,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
