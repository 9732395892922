import React, { useState } from "react";
import { Button, Input, Form, message } from "antd";

const SaveCaseNoteForm = (props) => {
    const { onSubmit } = props;

    const [formData, setFormData] = useState({
        name: "",
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            name: e.target.value,
        });
    };

    const validateInput = () => {
        const { name } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Name is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            //check if note name already exist (no code)
            const noteNameFound = [];

            if (noteNameFound.length !== 0) {
                message.error("Template name already exist");
            } else {
                onSubmit(formData);
            }
        }
    };

    return (
        <Form>
            <Form.Item
                label="Enter note name"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.name && "error"}
                help={formErrors.name && formErrors.name}
            >
                <Input
                    ref={(c) => c && c.input.focus()}
                    onChange={handleChange}
                    value={formData.name}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SaveCaseNoteForm;
