import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";

import { Button, Row, Col, Typography, Spin } from "antd";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

import { convertToSolidColor } from "helpers/colorConverter";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

let calendarRef = React.createRef();

const AppointmentCalendar = ({
    breakpoint,
    newAppointment,
    onCalendarEventClick,
    clinicID,
    appointmentListSettings,
    setRequestError,
}) => {
    const [calendarMonth, setCalendarMonth] = useState(
        moment().format("MMMM YYYY")
    );
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isCalendarLoading, setIsCalendarLoading] = useState(false);

    const ButtonGroup = Button.Group;
    const { Title } = Typography;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsCalendarLoading(true);
                const yearMonth = moment().format("YYYY-MM");
                const response = await getCalendarEvents(yearMonth);
                const calendarEvents = buildCalendarEvents(
                    response.data.appointments
                );
                setCalendarEvents(calendarEvents);
                setIsCalendarLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    useEffect(() => {
        if (newAppointment) {
            const {
                patient_fullname,
                appointment_datetime,
                appointment_id,
                status,
            } = newAppointment;

            let color = "";
            switch (status) {
                case "pending":
                    color = appointmentListSettings.pendingStatusColor;
                    break;
                case "canceled":
                    color = appointmentListSettings.canceledStatusColor;
                    break;
                case "confirmed":
                    color = appointmentListSettings.confirmedStatusColor;
                    break;
                default:
                    color = "#ccc";
            }

            color = convertToSolidColor(color);

            setCalendarEvents([
                ...calendarEvents,
                {
                    title: patient_fullname,
                    date: appointment_datetime,
                    id: appointment_id,
                    extendedProps: {
                        ...newAppointment,
                    },
                    backgroundColor: color,
                    borderColor: color,
                },
            ]);
        }
    }, [newAppointment]);

    function getCalendarEvents(yearMonth) {
        return http.get(`${apiUrl}/monthAppointment/${yearMonth}/${clinicID}`, {
            cancelToken: new CancelToken(function executor(c) {
                CancelRequest = c;
            }),
        });
    }

    function buildCalendarEvents(appointments) {
        const calendarEvents = [];
        appointments.forEach((appointment) => {
            const {
                patient_fullname,
                appointment_datetime,
                appointment_id,
                status,
            } = appointment;

            let color = "";
            switch (status) {
                case "pending":
                    color = appointmentListSettings.pendingStatusColor;
                    break;
                case "canceled":
                    color = appointmentListSettings.canceledStatusColor;
                    break;
                case "confirmed":
                    color = appointmentListSettings.confirmedStatusColor;
                    break;
                default:
                    color = "#ccc";
            }

            color = convertToSolidColor(color);

            calendarEvents.push({
                title: patient_fullname,
                date: appointment_datetime,
                id: appointment_id,
                extendedProps: {
                    ...appointment,
                },
                backgroundColor: color,
                borderColor: color,
            });
        });

        return calendarEvents;
    }

    const handleCalendarMonthChange = async (action) => {
        try {
            let calendar = calendarRef.current.getApi();
            if (action === "next") {
                calendar.next();
            } else {
                calendar.prev();
            }

            setCalendarEvents([]);
            setIsCalendarLoading(true);
            const currentCalendarDate = calendar.getDate();
            setCalendarMonth(moment(currentCalendarDate).format("MMMM YYYY"));
            const yearMonth = moment(currentCalendarDate).format("YYYY-MM");
            const response = await getCalendarEvents(yearMonth);
            const calendarEvents = buildCalendarEvents(
                response.data.appointments
            );
            setCalendarEvents(calendarEvents);
            setIsCalendarLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const handleCalendarViewChange = (view) => {
        let calendar = calendarRef.current.getApi();
        calendar.changeView(view);
    };

    return (
        <div>
            <Row type="flex" justify="space-between">
                <Col>
                    <Title level={3}>{calendarMonth}</Title>
                </Col>
                <Col>
                    <ButtonGroup
                        style={{
                            marginRight: 10,
                            marginBottom: breakpoint.isNormalMobile ? 10 : 0,
                        }}
                    >
                        <Button
                            icon="left"
                            disabled={isCalendarLoading}
                            onClick={() => handleCalendarMonthChange("prev")}
                        />
                        <Button
                            icon="right"
                            disabled={isCalendarLoading}
                            onClick={() => handleCalendarMonthChange("next")}
                        />
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button
                            onClick={() =>
                                handleCalendarViewChange("dayGridMonth")
                            }
                        >
                            Month
                        </Button>
                        <Button
                            onClick={() =>
                                handleCalendarViewChange("dayGridWeek")
                            }
                        >
                            Week
                        </Button>
                        <Button
                            onClick={() => handleCalendarViewChange("listWeek")}
                        >
                            Week List
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Spin tip="Loading..." spinning={isCalendarLoading}>
                <div style={{ backgroundColor: "#fff" }}>
                    <FullCalendar
                        header={false}
                        ref={calendarRef}
                        defaultView="dayGridMonth"
                        plugins={[dayGridPlugin, listPlugin]}
                        events={calendarEvents}
                        eventTimeFormat={{
                            hour: "2-digit",
                            minute: "2-digit",
                            meridiem: "long",
                        }}
                        eventClick={(info) =>
                            onCalendarEventClick(info.event._def.extendedProps)
                        }
                    />
                </div>
            </Spin>
        </div>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    appointmentListSettings: state.Settings.appointmentList,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppointmentCalendar);
