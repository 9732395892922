import React, { useState, useEffect } from "react";
import { Badge, Button, Tooltip, Slider, Popover, Popconfirm } from "antd";
import { CompactPicker } from "react-color";
import mergeImages from "merge-images";
import CanvasDraw from "react-canvas-draw";

import SketchBackgroundUploader from "./components/BackgroundUploader";

const SketchCanvas = React.forwardRef((props, _canvas) => {
    const { selectedSketch, breakpoint, onSave } = props;

    const [canvasSettings, setCanvasSettings] = useState({
        loadTimeOffset: 5,
        lazyRadius: 0,
        brushRadius: 4,
        brushColor: "#0062B1",
        backgroundColor: "#fff",
        catenaryColor: "#0a0302",
        //gridColor: "rgba(150,150,150,0.17)",
        hideGrid: true,
        disabled: false,
        canvasWidth: breakpoint.isNormalMobile ? "100%" : 600,
        casvasHeight: breakpoint.isNormalMobile ? "100%" : 600,
        imgSrc: "",
        saveData: null,
        immediateLoading: false
    });

    const [eraseSettings, setEraseSettings] = useState({
        eraseMode: false,
        brushColor: "#FFF"
    });

    useEffect(() => {
        if (selectedSketch) {
            _load(selectedSketch.img_path);
        }
    }, []);

    function _load(imgSrc) {
        //------------------------------------ custom image load
        const canvasElement = document.getElementsByTagName("canvas")[3];

        const ctx = canvasElement.getContext("2d");

        var canvasImg = new Image();

        ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        canvasImg.onload = () => {
            const scale = Math.min(
                canvasElement.width / canvasImg.width,
                canvasElement.height / canvasImg.height
            );
            var x = canvasElement.width / 2 - (canvasImg.width / 2) * scale;
            var y = canvasElement.height / 2 - (canvasImg.height / 2) * scale;

            ctx.drawImage(
                canvasImg,
                x,
                y,
                canvasImg.width * scale,
                canvasImg.height * scale
            );
        };
        canvasImg.crossOrigin = "Anonymous";
        canvasImg.src = imgSrc;
        //------------------------------------ custom image load
    }

    const _erase = () => {
        setEraseSettings({ ...eraseSettings, eraseMode: true });
    };

    const _edit = () => {
        setEraseSettings({ ...eraseSettings, eraseMode: false });
    };
    const _clear = () => {
        _canvas.clear();
    };

    const _undo = () => {
        _canvas.undo();
    };

    const _save = () => {
        //  indeicate where to save subj, obj , ass, plan  - item contains category key  see _menuSave function
        const sketchData = document.getElementsByTagName("canvas")[1];
        const sketchBackground = document.getElementsByTagName("canvas")[3];

        const sketchDataB64 = sketchData.toDataURL();
        const sketchBackgroundB64 = sketchBackground.toDataURL();

        mergeImages([sketchBackgroundB64, sketchDataB64]).then(base64Img =>
            onSave(base64Img)
        );
    };

    const _sliderOnChange = value => {
        setCanvasSettings({
            ...canvasSettings,
            brushRadius: value
        });
    };

    const _lineWidth = (
        <>
            <Slider
                min={1}
                max={20}
                onChange={_sliderOnChange}
                value={canvasSettings.brushRadius}
                style={{ marginBottom: 10 }}
            />
        </>
    );

    const _colorPickerContent = (
        <>
            <CompactPicker
                color={canvasSettings.brushColor}
                onChange={color =>
                    setCanvasSettings({
                        ...canvasSettings,
                        brushColor: color.hex
                    })
                }
                style={{ marginBottom: 10 }}
            />
        </>
    );

    const _imageList = (
        <div>
            <SketchBackgroundUploader onSelectBgImg={_load} />
        </div>
    );

    // const _menuSave = (
    //     <Menu style={{ width: 150 }}>
    //         {displayedCaseNote === "soap" && (
    //             <Menu.Item key="s" onClick={() => _save("s")}>
    //                 <Icon type="right" /> Subjective
    //             </Menu.Item>
    //         )}
    //         {displayedCaseNote === "soap" && (
    //             <Menu.Item key="o" onClick={() => _save("o")}>
    //                 <Icon type="right" /> Objective
    //             </Menu.Item>
    //         )}
    //         {displayedCaseNote === "soap" && (
    //             <Menu.Item key="a" onClick={() => _save("a")}>
    //                 <Icon type="right" /> Assessment
    //             </Menu.Item>
    //         )}
    //         {displayedCaseNote === "soap" && (
    //             <Menu.Item key="p" onClick={() => _save("p")}>
    //                 <Icon type="right" /> Plan
    //             </Menu.Item>
    //         )}
    //         {displayedCaseNote === "plain" && (
    //             <Menu.Item key="pn" onClick={() => _save("pn")}>
    //                 <Icon type="right" /> Plain Notes
    //             </Menu.Item>
    //         )}
    //     </Menu>
    // );

    return (
        <>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Tooltip placement="top" title="Drawing">
                    <Button
                        type="dashed"
                        icon="edit"
                        shape="circle"
                        style={styles.buttonSpacer}
                        onClick={_edit}
                        size="large"
                    />
                </Tooltip>

                <Tooltip placement="top" title="Erase">
                    <Button
                        type="dashed"
                        icon="highlight"
                        shape="circle"
                        style={styles.buttonSpacer}
                        onClick={_erase}
                        size="large"
                    />
                </Tooltip>

                <Tooltip placement="top" title="Select Background">
                    <Popover
                        placement="bottomLeft"
                        title="Select Background"
                        content={_imageList}
                        trigger="click"
                        overlayClassName="cwaSketchBackgroundContentList"
                    >
                        <Button
                            type="dashed"
                            icon="file-image"
                            shape="circle"
                            style={styles.buttonSpacer}
                            size="large"
                        />
                    </Popover>
                </Tooltip>

                <Tooltip placement="top" title="Line Weight">
                    <Popover
                        placement="bottomLeft"
                        title="Line Weight"
                        content={_lineWidth}
                        trigger="click"
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            style={styles.buttonSpacer}
                            size="large"
                        >
                            {canvasSettings.brushRadius}
                        </Button>
                    </Popover>
                </Tooltip>

                <Tooltip placement="top" title="Color Picker">
                    <Popover
                        placement="bottomLeft"
                        title="Color Picker"
                        content={_colorPickerContent}
                        trigger="click"
                    >
                        <Button
                            type="dashed"
                            shape="circle"
                            style={styles.buttonSpacer}
                            size="large"
                        >
                            <Badge
                                count={1}
                                style={{
                                    color: canvasSettings.brushColor,
                                    backgroundColor: canvasSettings.brushColor,
                                    marginTop: -5
                                }}
                            />
                        </Button>
                    </Popover>
                </Tooltip>

                <Tooltip placement="top" title="Undo">
                    <Button
                        type="dashed"
                        icon="undo"
                        shape="circle"
                        style={styles.buttonSpacer}
                        onClick={_undo}
                        size="large"
                    />
                </Tooltip>

                <Tooltip placement="top" title="Save">
                    <Button
                        type="dashed"
                        icon="save"
                        shape="circle"
                        style={styles.buttonSpacer}
                        size="large"
                        onClick={_save}
                    />
                </Tooltip>

                <Tooltip placement="top" title="Clear">
                    <Popconfirm
                        placement="bottom"
                        title="Are your sure to Clear Sketch?"
                        onConfirm={_clear}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="dashed"
                            icon="delete"
                            shape="circle"
                            style={styles.buttonSpacer}
                            size="large"
                        />
                    </Popconfirm>
                </Tooltip>
            </div>

            <CanvasDraw
                ref={canvasDraw => (_canvas = canvasDraw)}
                style={styles.sketchAnddropArea}
                brushColor={
                    eraseSettings.eraseMode
                        ? eraseSettings.brushColor
                        : canvasSettings.brushColor
                }
                brushRadius={canvasSettings.brushRadius}
                hideGrid
                lazyRadius={canvasSettings.lazyRadius}
                canvasWidth={canvasSettings.canvasWidth}
                canvasHeight={canvasSettings.canvasHeight}
            />
        </>
    );
});

const styles = {
    buttonSpacer: {
        marginRight: 6
    },
    sketchAnddropArea: {
        border: "2px dashed rgb(204, 204, 204)",
        borderStyle: "dashed",
        borderRadius: "5px",
        textAlign: "center",
        backgroundColor: "#fff"
    }
};

export default SketchCanvas;
