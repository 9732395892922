import React, { useState, useEffect } from "react";

import { Form, Input, Button, message } from "antd";

const GroupForm = ({ data, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: "",
        status: "active",
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
    });

    const txtGroupName = React.createRef();

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
        txtGroupName.current.input.focus();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Group name is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{
                    xl: { span: 8 },
                    lg: { span: 9 },
                }}
                wrapperCol={{
                    xl: { span: 16 },
                    lg: { span: 15 },
                }}
                style={{
                    width: "100%",
                }}
            >
                <Form.Item
                    label="Group Name"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.name && "error"}
                    help={formErrors.name && formErrors.name}
                >
                    <Input
                        name="name"
                        autoComplete="off"
                        ref={txtGroupName}
                        value={formData.name}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label=" ">
                    <Button type="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default GroupForm;
