import React, { useState } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Card, Upload, message, Icon, Button, Modal, Row, Col } from "antd";

import Lightbox from "react-image-lightbox";

import moment from "moment";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

const PatientImgGallery = ({
    patientDetails,
    caseID,
    onSelectCase,
    ownerID,
    userID,
    clinicID,
    uploadServer,
    uploadEndpoint,
    setRequestError,
}) => {
    const [patientImgGallery, setPatientImgGallery] = useState(
        patientDetails.imgGallery ? JSON.parse(patientDetails.imgGallery) : []
    );

    const [isPreviewImgVisible, setIsPreviewImgVisible] = useState(false);
    const [selectedImgIndex, setSelectedImgIndex] = useState(0);

    const { confirm } = Modal;

    const togglePreviewImgVisible = () => {
        setIsPreviewImgVisible(!isPreviewImgVisible);
        //remove scollbar when lightbox is open
        if (!isPreviewImgVisible) {
            document.body.style.position = "relative";
            document.body.style.overflow = "hidden";
            document.body.style.width = "100%";
        } else {
            document.body.removeAttribute("style");
        }
    };

    const handleCaseIDClick = (caseID) => {
        togglePreviewImgVisible();
        setTimeout(() => onSelectCase(caseID), 0);
    };

    const handleImgDelete = (cb) => {
        confirm({
            title: `Are you sure do you want to remove this image?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                cb(true);
            },
            onCancel() {
                cb(false);
            },
        });
    };

    const uploadProps = {
        name: "uniFile",
        //accept: ".png,.jpg,.jpeg",
        action: `${apiUrl}/patientImgGalleryUpload?space_server=${uploadServer}&space_bucket_name=${uploadEndpoint}&root_dir=${cwaUploadRootDir}&owner_id=${ownerID}&clinic_id=${clinicID}`,
        headers: {
            authorization: localStorage.getItem("token"),
        },
        listType: "picture-card",
        showUploadList: { showDownloadIcon: false },
        async onChange(info) {
            let newPatientImgGallery = [...info.fileList];
            newPatientImgGallery = newPatientImgGallery.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    const datePath = file.response.datePath;
                    const filename = file.response.newFilename;
                    const fileExt = file.name.split(".").slice(-1);
                    const createdAt = file.response.createdAt;
                    file.url = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${datePath}/${filename}.${fileExt}`;
                    file.addedBy = userID;
                    file.createdAt = createdAt;
                    file.caseID = caseID;
                }
                return file;
            });

            if (info.file.status) {
                setPatientImgGallery(newPatientImgGallery);
            }

            if (info.file.status === "done") {
                //refine attachments array and save it to database
                const files = [];
                newPatientImgGallery.forEach((file) => {
                    const {
                        uid,
                        name,
                        url,
                        size,
                        status,
                        addedBy,
                        createdAt,
                        caseID,
                    } = file;
                    files.push({
                        uid,
                        url,
                        size,
                        name,
                        status,
                        addedBy,
                        createdAt,
                        caseID,
                    });
                });

                try {
                    await http.post(`${apiUrl}/patientImgGallery`, {
                        patient_id: patientDetails.patient_id,
                        images: JSON.stringify(files),
                    });
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        },
        beforeUpload(file) {
            const isValidFile =
                file.type === "image/jpeg" || file.type === "image/png";

            if (!isValidFile) {
                message.error("You can only upload JPEG/PNG file!");
            }
            const isValidSize = file.size / 1024 / 1024 < 20;
            if (!isValidSize) {
                message.error("File must smaller than 20MB!");
            }
            return isValidFile && isValidSize;
        },
        onPreview(file) {
            let imgIndex = 0;

            patientImgGallery.forEach((img, index) => {
                if (img.uid === file.uid) {
                    imgIndex = index;
                }
            });

            setSelectedImgIndex(imgIndex);
            togglePreviewImgVisible();
        },
        onRemove(file) {
            if (file.addedBy === userID) {
                return new Promise(async (resolve, reject) => {
                    handleImgDelete(async function (isConfirmed) {
                        if (isConfirmed) {
                            resolve();
                            const newPatientImgGallery = patientImgGallery
                                .map((img) => {
                                    const {
                                        uid,
                                        name,
                                        url,
                                        size,
                                        status,
                                        addedBy,
                                        createdAt,
                                        caseID,
                                    } = img;
                                    return {
                                        uid,
                                        name,
                                        url,
                                        size,
                                        status,
                                        addedBy,
                                        createdAt,
                                        caseID,
                                    };
                                })
                                .filter((img) => img.uid !== file.uid);

                            try {
                                await http.post(`${apiUrl}/patientImgGallery`, {
                                    patient_id: patientDetails.patient_id,
                                    images: JSON.stringify(
                                        newPatientImgGallery
                                    ),
                                });
                            } catch (error) {
                                setRequestError({
                                    errorMessage:
                                        "Something went wrong on your last operation :(",
                                    errorSubMessage: error.message,
                                });
                            }
                        }
                    });
                });
            } else {
                message.error("Only the person who attached it can delete it!");
                return false;
            }
        },
    };

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const { lastname, firstname, middlename } = patientDetails;
    const patientFullname = `${lastname}, ${firstname} ${middlename}`;
    const lightboxImgs = patientImgGallery.map((img) => ({
        src: img.url,
        caption: (
            <Row type="flex" gutter={24}>
                <Col>Added By: {findUserName(img.addedBy)}</Col>
                <Col>Create At: {moment(img.createdAt).format("LLL")}</Col>
                <Col>
                    Size:{" "}
                    {img.size ? (
                        <span>
                            {img.size / 1024 > 1024
                                ? `${(img.size / 1024 / 1024).toFixed(1)} MB`
                                : `${(img.size / 1024).toFixed(1)} KB`}
                        </span>
                    ) : (
                        "0 KB"
                    )}
                </Col>
                <Col>
                    Case ID:{" "}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => handleCaseIDClick(img.caseID)}
                    >
                        {img.caseID}
                    </Button>
                </Col>
            </Row>
        ),
    }));

    return (
        <div style={{ marginBottom: 16 }}>
            <Card title="Imaging" bodyStyle={{ padding: "20px 16px 8px 24px" }}>
                <div className="clearfix">
                    <Upload {...uploadProps} fileList={patientImgGallery}>
                        {uploadButton}
                    </Upload>
                </div>
            </Card>

            {/* <Modal
                visible={isPreviewImgVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 0 }}
                closable={false}
                bodyStyle={{ padding: 0 }}
                mask={false}
            > */}
            {isPreviewImgVisible && (
                <Lightbox
                    mainSrc={lightboxImgs[selectedImgIndex].src}
                    nextSrc={
                        lightboxImgs[
                            (selectedImgIndex + 1) % lightboxImgs.length
                        ].src
                    }
                    prevSrc={
                        lightboxImgs[
                            (selectedImgIndex + lightboxImgs.length - 1) %
                                lightboxImgs.length
                        ].src
                    }
                    imageTitle={patientFullname}
                    imageCaption={lightboxImgs[selectedImgIndex].caption}
                    onCloseRequest={togglePreviewImgVisible}
                    onMovePrevRequest={() =>
                        setSelectedImgIndex(
                            (selectedImgIndex + lightboxImgs.length - 1) %
                                lightboxImgs.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setSelectedImgIndex(
                            (selectedImgIndex + 1) % lightboxImgs.length
                        )
                    }
                    clickOutsideToClose={false}
                />
            )}
            {/* </Modal> */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    ownerID: state.UserDetails.owner_id,
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientImgGallery);
