import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Link } from "react-router-dom";
import {
    Button,
    DatePicker,
    Table,
    Icon,
    Row,
    Col,
    Tag,
    Badge,
    Tooltip,
    Avatar,
    Modal,
    Switch,
    message
} from "antd";

import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import ActionMenu from "globalComponents/ActionMenu";
import ModalDetails from "globalComponents/ModalDetails";
import PatientCaseDetails from "./components/PatientCaseDetails";
import CaseCalendar from "./components/CaseCalendar";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Case = ({
    breakpoint,
    clinicID,
    doctors,
    userID,
    plan,
    caseListSettings,
    setRequestError,
    ...props
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);

    const [cases, setCases] = useState([]);
    const [selectedCase, setSelectedCase] = useState({
        chief_complaint: "",
        complaint_datetime: "",
        status: "",
        appointment_id: null
    });
    const [selectedDate, setSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );

    const [isCasesTableLoading, setIsCasesTableLoading] = useState(false);

    const [caseCount, setCaseCount] = useState({
        total: 0,
        pending: 0,
        done: 0,
        hold: 0,
        noShow: 0
    });

    const [isCaseDetailsVisible, setIsCaseDetailsVisible] = useState(false);

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const ButtonGroup = Button.Group;
    const { confirm } = Modal;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCases(selectedDate);
                setCasesStatistics(response.data.cases);
                setCases(response.data.cases);
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    useEffect(() => {
        socket.on("changeCaseStatus", function (data) {
            const newCases = cases.map(patientCase => {
                if (patientCase.case_id === data.caseID) {
                    return {
                        ...patientCase,
                        status: data.newStatus
                    };
                }
                return patientCase;
            });
            setCasesStatistics(newCases);
            setCases(newCases);
        });
        socket.on("caseAcquired", async function (data) {
            const newCases = cases.map(patientCase => {
                if (patientCase.case_id === data.caseID) {
                    return {
                        ...patientCase,
                        doctor_id: data.doctorID
                    };
                }
                return patientCase;
            });
            setCases(newCases);
        });
        socket.on("caseCancelled", async function (data) {
            const newCases = cases.map(patientCase => {
                if (patientCase.case_id === data.caseID) {
                    return {
                        ...patientCase,
                        doctor_id: "any"
                    };
                }
                return patientCase;
            });
            setCases(newCases);
        });
        socket.on("reloadCases", async function (data) {
            setIsContentLoading(true);
            try {
                const response = await getCases(selectedDate);
                setCasesStatistics(response.data.cases);
                setCases(response.data.cases);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
            setIsContentLoading(false);
        });
        return () => {
            socket.off("changeCaseStatus");
            socket.off("caseAcquired");
            socket.off("caseCancelled");
            socket.off("reloadCases");
        };
    }, [cases]);

    function getCases(date) {
        return http.get(`${apiUrl}/dayCases/${date}/${clinicID}`, {
            cancelToken: new CancelToken(function executor(c) {
                CancelRequest = c;
            })
        });
    }

    function setCasesStatistics(cases) {
        let totalPending = 0;
        let totalDone = 0;
        let totalHold = 0;
        let totalNoShow = 0;

        cases.forEach(patientCase => {
            switch (patientCase.status) {
                case "pending":
                    totalPending++;
                    break;
                case "done":
                    totalDone++;
                    break;
                case "hold":
                    totalHold++;
                    break;
                case "no-show":
                    totalNoShow++;
                    break;
                default:
                    return;
            }
        });

        setCaseCount({
            total: cases.length,
            pending: totalPending,
            done: totalDone,
            hold: totalHold,
            noShow: totalNoShow
        });
    }

    const toggleCalendarVisible = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };

    const toggleCaseDetailsVisible = () => {
        setIsCaseDetailsVisible(!isCaseDetailsVisible);
    };

    const handleDateChange = async selectedDate => {
        try {
            if (!isCasesTableLoading) {
                setIsCasesTableLoading(true);
                const date = moment(selectedDate).format("YYYY-MM-DD");
                setSelectedDate(date);
                const response = await getCases(date);
                setCasesStatistics(response.data.cases);
                setCases(response.data.cases);
                setIsCasesTableLoading(false);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const showCaseDetails = selectedRecord => {
        setSelectedCase(selectedRecord);
        toggleCaseDetailsVisible();
    };

    const showCaseCountDetails = () => {
        Modal.info({
            title: "Case Count Details",
            centered: true,
            maskClosable: true,
            content: (
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: 80 }}>
                                <strong>Pending:</strong>
                            </td>
                            <td>{caseCount.pending}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Done:</strong>
                            </td>
                            <td>{caseCount.done}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Hold:</strong>
                            </td>
                            <td>{caseCount.hold}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>No Show:</strong>
                            </td>
                            <td>{caseCount.noShow}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Total:</strong>
                            </td>
                            <td>{caseCount.total}</td>
                        </tr>
                    </tbody>
                </table>
            )
        });
    };

    const handleCaseDelete = async selectedRecord => {
        const {
            case_id,
            patient_fullname,
            chief_complaint,
            doctor_id,
            clinic_id
        } = selectedRecord;
        confirm({
            title: `Are you sure do you want to archive this case?`,
            //content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    setCases([
                        ...cases.filter(
                            patientCase => patientCase.case_id !== case_id
                        )
                    ]);

                    resolve();
                    try {
                        await http.put(`${apiUrl}/case/${case_id}`, {
                            status: "inactive"
                        });

                        await http.post(`${apiUrl}/activityLog`, {
                            system_activity_id: 4,
                            data: JSON.stringify({
                                id: case_id
                            }),
                            user_id: userID
                        });

                        socket.emit("caseDeleted", {
                            doctorName: findUserName(doctor_id),
                            chiefComplaint: chief_complaint,
                            patientFullname: patient_fullname,
                            clinicID: clinic_id
                        });
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handlePatientCall = case_id => {
        let patientFullname = "";
        let chiefComplaint = "";

        cases.forEach(patientCase => {
            if (patientCase.case_id === case_id) {
                chiefComplaint = patientCase.chief_complaint;
                patientFullname = patientCase.patient_fullname;
                return false;
            }
        });

        socket.emit("callPatient", {
            chiefComplaint,
            patientFullname,
            clinicID
        });
    };

    const handleCaseStatusChange = async (apiRoute, case_id) => {
        try {
            //update
            let patientFullname = "";
            let oldStatus = "";
            let chiefComplaint = "";

            let caseStatus = "hold";
            if (apiRoute === "noshow") {
                caseStatus = "no-show";
            }

            const newCases = cases.map(patientCase => {
                if (patientCase.case_id === case_id) {
                    chiefComplaint = patientCase.chief_complaint;
                    patientFullname = patientCase.patient_fullname;
                    oldStatus = patientCase.status;
                    return {
                        ...patientCase,
                        status: caseStatus
                    };
                }
                return patientCase;
            });

            setCasesStatistics(newCases);
            setCases(newCases);
            toggleCaseDetailsVisible();

            message.success(
                `Case status successfully changed from ${oldStatus} to ${apiRoute}`
            );

            if (apiRoute === "noshow") {
                await http.put(`${apiUrl}/case/${case_id}`, {
                    status: "no-show"
                });
                socket.emit("changeCaseStatus", {
                    caseID: case_id,
                    chiefComplaint,
                    patientFullname,
                    oldStatus,
                    newStatus: "no-show",
                    clinicID
                });
            } else if (apiRoute === "hold") {
                await http.put(`${apiUrl}/case/${case_id}`, {
                    status: "hold"
                });
                socket.emit("changeCaseStatus", {
                    caseID: case_id,
                    chiefComplaint,
                    patientFullname,
                    oldStatus,
                    newStatus: "hold",
                    clinicID
                });
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const caseTableColumns = [
        {
            title: "Cases",
            key: "patient",
            className: "cursorPointer",
            render: (text, record, index) => {
                const {
                    patient_img_path,
                    patient_fullname,
                    chief_complaint,
                    complaint_datetime,
                    doctor_id,
                    status,
                    appointment_id,
                    patient_id,
                    is_from_cwapp,
                    is_patient_from_legacy
                } = record;

                const avatarProps = patient_img_path
                    ? {
                          size: 32,
                          src: is_patient_from_legacy
                              ? patient_img_path.replace(
                                    `${patient_id}-`,
                                    `thumb/${patient_id}-`
                                )
                              : patient_img_path.replace(".jpeg", "-thumb.jpeg")
                      }
                    : {
                          size: 32,
                          icon: "user"
                      };

                const TimeAndDoctorDetails = () => {
                    return (
                        <span>
                            {/* TIME */}
                            {breakpoint.isTabletLandscape && (
                                <span>
                                    <Icon
                                        type="clock-circle"
                                        theme="twoTone"
                                        style={{ marginRight: 8 }}
                                    />
                                    {moment(complaint_datetime).format(
                                        "hh:mm A"
                                    )}
                                </span>
                            )}

                            {/* DIVIDER */}
                            {breakpoint.isTabletLandscape &&
                                !breakpoint.isNormalMobile &&
                                doctors.length > 1 && (
                                    <span
                                        style={{
                                            color: "#e8e8e8",
                                            margin: "0 10px"
                                        }}
                                    >
                                        |
                                    </span>
                                )}
                            {breakpoint.isNormalMobile && <br />}

                            {/* DOCTOR */}
                            {doctors.length > 1 &&
                                caseListSettings.isShowDoctor && (
                                    <span>
                                        <Icon
                                            type="user"
                                            style={{ marginRight: 8 }}
                                        />
                                        {findUserName(doctor_id)}
                                    </span>
                                )}
                        </span>
                    );
                };

                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor = caseListSettings.pendingStatusColor;
                        break;
                    case "hold":
                        statusColor = caseListSettings.holdStatusColor;
                        break;
                    case "no-show":
                        statusColor = caseListSettings.noShowStatusColor;
                        break;
                    case "done":
                        statusColor = caseListSettings.doneStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }

                return (
                    <div>
                        <Row
                            type="flex"
                            justify="start"
                            style={{ flexWrap: "nowrap" }}
                        >
                            <Col>
                                {caseListSettings.isShowImage && (
                                    <span style={{ marginRight: 16 }}>
                                        <Avatar {...avatarProps} />
                                    </span>
                                )}
                            </Col>
                            <Col>
                                {/* PATIENT NAME */}
                                <h4 className="ant-list-item-meta-title">
                                    {index + 1}. {patient_fullname}{" "}
                                    {appointment_id && (
                                        <Icon
                                            type="calendar"
                                            theme="twoTone"
                                            style={{ marginLeft: 5 }}
                                        />
                                    )}
                                </h4>

                                <div>
                                    {/* CASE DETAILS */}
                                    {caseListSettings.isShowChiefComplaint && (
                                        <div>
                                            <span
                                                style={{ fontStyle: "italic" }}
                                            >
                                                &mdash; &ldquo;
                                                {chief_complaint}
                                                &rdquo;
                                            </span>
                                        </div>
                                    )}

                                    {/* TIME AND DOCTOR */}
                                    <div
                                        style={{
                                            marginTop:
                                                breakpoint.isTabletLandscape
                                                    ? 8
                                                    : doctors.length > 1
                                                    ? 5
                                                    : 0
                                        }}
                                    >
                                        <TimeAndDoctorDetails />
                                    </div>

                                    {/* FROM CWAPP INDICATOR */}
                                    {is_from_cwapp ? (
                                        <div style={{ marginTop: 5 }}>
                                            <Tag color="purple">
                                                <Icon type="pushpin" /> From
                                                Patient Portal
                                            </Tag>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {/* STATUS */}
                                    {breakpoint.isNormalMobile && (
                                        <div style={{ marginTop: 5 }}>
                                            <Tag color={statusColor}>
                                                {status}
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            },
            filters: [
                ...doctors.map(doctor => ({
                    text: doctor.doctor_name,
                    value: doctor.doctor_id
                }))
            ],
            onFilter: (value, record) => {
                return record.doctor_id === value;
            }
        },
        {
            title: "Time",
            dataIndex: "complaint_datetime",
            width: 130,
            render: time => (
                <span>
                    <Icon
                        type="clock-circle"
                        theme="twoTone"
                        style={{ marginRight: 8 }}
                    />
                    {moment(time).format("hh:mm A")}
                </span>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            className: "cursorPointer",
            render: status => {
                let statusColor = "";

                switch (status) {
                    case "pending":
                        statusColor = caseListSettings.pendingStatusColor;
                        break;
                    case "hold":
                        statusColor = caseListSettings.holdStatusColor;
                        break;
                    case "no-show":
                        statusColor = caseListSettings.noShowStatusColor;
                        break;
                    case "done":
                        statusColor = caseListSettings.doneStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }
                return <Tag color={statusColor}>{status}</Tag>;
            },
            filters: [
                {
                    text: "Pending",
                    value: "pending"
                },
                {
                    text: "Done",
                    value: "done"
                },
                {
                    text: "Hold",
                    value: "hold"
                },
                {
                    text: "No Show",
                    value: "no-show"
                }
            ],
            onFilter: (value, record) => record.status === value
        },
        {
            title: "",
            key: "action",
            width: breakpoint.isNormalMobile ? 50 : 162,
            className: "cursorPointer",
            render: (text, record) => {
                let menu = [
                    {
                        label: "Open Case",
                        icon: "folder-open",
                        onClick: () =>
                            props.history.push(`/casenote/${record.case_id}`)
                    },
                    {
                        label: "Manage Billing",
                        icon: "credit-card",
                        onClick: () => {
                            props.history.push(
                                `/billing/${record.patient_id}/case/${record.case_id}`
                            );
                        }
                    },
                    {
                        label: "Archive",
                        icon: "container",
                        onClick: () => handleCaseDelete(record)
                    }
                ];

                if (plan !== "basic") {
                    if (record.status !== "done") {
                        menu.splice(1, 1);
                    }
                } else {
                    menu.splice(1, 1);
                }

                if (record.doctor_id !== userID) {
                    menu = menu.filter(m => m.label !== "Archive");
                }

                if (!breakpoint.isNormalMobile) {
                    menu.splice(0, 1);
                }

                return (
                    <>
                        {!breakpoint.isNormalMobile && (
                            <Button
                                type="link"
                                style={{ marginRight: 8 }}
                                onClick={() =>
                                    props.history.push(
                                        `/casenote/${record.case_id}`
                                    )
                                }
                            >
                                <Icon type="folder-open" /> Open
                            </Button>
                        )}
                        <ActionMenu menu={menu} layout="compress" />
                    </>
                );
            }
        }
    ];

    if (breakpoint.isNormalMobile) {
        caseTableColumns.splice(1, 2);
    } else if (breakpoint.isTabletLandscape) {
        caseTableColumns.splice(1, 1);
    }

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <div>
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 16 }}
            >
                <Col>
                    {!breakpoint.isNormalMobile && "Calendar View "}
                    <Switch
                        checkedChildren={
                            breakpoint.isNormalMobile ? (
                                <Icon type="calendar" />
                            ) : null
                        }
                        unCheckedChildren={
                            breakpoint.isNormalMobile ? (
                                <Icon type="unordered-list" />
                            ) : null
                        }
                        checked={isCalendarVisible}
                        onChange={toggleCalendarVisible}
                    />
                </Col>
                <Col>
                    <Link to="/case/create">
                        <Button type="primary">
                            <Icon type="plus" /> Create Case
                        </Button>
                    </Link>
                </Col>
            </Row>
            {isCalendarVisible ? (
                <CaseCalendar
                    breakpoint={breakpoint}
                    cases={cases} //refresh calendar if there's a changes in cases
                    onCalendarEventClick={showCaseDetails}
                />
            ) : (
                <div>
                    <Row
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>
                            <DatePicker
                                format="dddd, MMMM DD, YYYY"
                                allowClear={false}
                                style={{
                                    width: breakpoint.isNormalMobile
                                        ? "calc(100vw - 78px)"
                                        : 270
                                }}
                                defaultValue={moment(selectedDate)}
                                value={moment(selectedDate)}
                                onChange={handleDateChange}
                            />
                            <ButtonGroup
                                style={{
                                    marginLeft: 5
                                }}
                            >
                                <Tooltip title="Previous Date">
                                    <Button
                                        icon="left"
                                        onClick={() =>
                                            handleDateChange(
                                                moment(selectedDate).subtract(
                                                    1,
                                                    "day"
                                                )
                                            )
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title="Next Date">
                                    <Button
                                        icon="right"
                                        onClick={() =>
                                            handleDateChange(
                                                moment(selectedDate).add(
                                                    1,
                                                    "day"
                                                )
                                            )
                                        }
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    marginTop: breakpoint.isNormalMobile
                                        ? 10
                                        : 0,
                                    marginLeft: 5
                                }}
                            >
                                <span>
                                    Pending:{" "}
                                    <Badge
                                        count={caseCount.pending}
                                        style={{
                                            backgroundColor: "#ff5500",
                                            color: "#fff"
                                        }}
                                    />
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                    Total Case:{" "}
                                    <Badge
                                        count={caseCount.total}
                                        style={{
                                            backgroundColor: "#673AB7",
                                            color: "#fff"
                                        }}
                                    />
                                </span>
                                <Button
                                    type="link"
                                    onClick={showCaseCountDetails}
                                >
                                    More details
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ backgroundColor: "#fff" }}>
                        <Table
                            columns={caseTableColumns}
                            dataSource={cases}
                            loading={isCasesTableLoading}
                            pagination={false}
                            rowKey="case_id"
                            onRow={record => ({
                                onClick: () => {
                                    showCaseDetails(record);
                                }
                            })}
                        />
                    </div>
                </div>
            )}

            <ModalDetails
                visible={isCaseDetailsVisible}
                width={700}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleCaseDetailsVisible}
            >
                <PatientCaseDetails
                    selectedCase={selectedCase}
                    userID={userID}
                    breakpoint={breakpoint}
                    onPatientCall={handlePatientCall}
                    onCaseStatusChange={handleCaseStatusChange}
                />
            </ModalDetails>
        </div>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    doctors: state.UserDetails.doctors.filter(
        doctor => doctor.status === "active"
    ),
    userID: state.UserDetails.user_id,
    plan: state.UserDetails.subscriptionDetails.plan,
    caseListSettings: state.Settings.caseList
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Case);
