const INIT_STATE = null;

const copiedCaseNoteReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "UPDATE_COPIED_CASE_NOTE":
            return action.payload.copiedCaseNote;
        default:
            return state;
    }
};

export default copiedCaseNoteReducer;
