import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { message, Modal, Card } from "antd";

import PrescriptionList from "./components/PrescriptionList";
import ServiceList from "./components/ServiceList";
import LabRequestList from "./components/LabRequestList";
import RadiologyRequestList from "./components/RadiologyRequestList";
import OtherRequestList from "./components/OtherRequestList";
import PaperList from "./components/PaperList";
import AppointmentDetails from "./components/AppointmentDetails";

import PrescriptionNoteDrawer from "./components/Drawers/PrescriptionNoteDrawer";
import ServiceDrawer from "./components/Drawers/ServiceDrawer";
import PaperDrawer from "./components/Drawers/PaperDrawer";
import AppointmentDrawer from "./components/Drawers/AppointmentDrawer";

import { generateString } from "helpers/randomStringGenerator";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

const OtherPlans = ({
    caseDetails,
    patientDetails,
    breakpoint,
    readOnly: isCaseReadOnly,
    onChange,
    items,
    setRequestError
}) => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [services, setServices] = useState([]);
    const [labRequests, setLabRequests] = useState([]);
    const [radiologyRequests, setRadiologyRequests] = useState([]);
    const [otherRequests, setOtherRequests] = useState([]);
    const [papers, setPapers] = useState([]);
    const [appointmentDetails, setAppointmentDetails] = useState(null);

    const { confirm } = Modal;

    useEffect(() => {
        const prescriptions = [];
        const services = [];
        const papers = [];
        let labRequests = [];
        let radiologyRequests = [];
        let otherRequests = [];

        //fill prescriptions, services, papers and lab request if any
        //map items
        const mappedItems = {};
        items.forEach(item => {
            mappedItems[item.item_id] = item;
        });

        try {
            caseDetails.otherPlans.forEach(otherPlan => {
                const { type, id, value } = otherPlan;
                switch (type) {
                    case "item":
                        prescriptions.push({
                            item_id: id,
                            item: mappedItems[id],
                            note: JSON.parse(value)
                        });
                        break;
                    case "service":
                        services.push({
                            item_id: id,
                            name: value
                        });
                        break;
                    case "paper":
                        papers.push(JSON.parse(value));
                        break;
                    case "labRequest":
                        labRequests = JSON.parse(value);
                        break;
                    case "radiologyRequest":
                        radiologyRequests = JSON.parse(value);
                        break;
                    case "otherRequest":
                        otherRequests = JSON.parse(value);
                        break;
                    default:
                        break;
                }
            });

            setPrescriptions(prescriptions);
            setServices(services);
            setLabRequests(labRequests);
            setRadiologyRequests(radiologyRequests);
            setOtherRequests(otherRequests);
            setPapers(papers);
            setAppointmentDetails(caseDetails.appointmentDetails);
        } catch (error) {
            message.error(error.message);
        }
    }, [caseDetails]);

    const handlePrescriptionAdd = async selectedPrescription => {
        const prescriptionFound = prescriptions.filter(
            p => p.item_id === selectedPrescription.item_id
        );

        if (prescriptionFound.length === 0) {
            const newPrescription = {
                case_id: caseDetails.case_id,
                patient_id: caseDetails.patient_id,
                id: selectedPrescription.item_id,
                value: JSON.stringify(selectedPrescription.note),
                type: "item"
            };

            setPrescriptions([...prescriptions, selectedPrescription]);
            onChange("otherPlans", [
                ...caseDetails.otherPlans,
                newPrescription
            ]);

            message.success("Prescription added!");

            try {
                await http.post(`${apiUrl}/otherPlan/`, newPrescription);
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("The item was already on the presciption list ");
        }
    };

    const handlePrescriptionRemove = (itemID, itemName) => {
        confirm({
            title: `Are you sure do you want to remove ${itemName} prescription?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();

                    const newPrescriptions = [
                        ...prescriptions.filter(
                            prescription => prescription.item_id !== itemID
                        )
                    ];

                    const newOtherPlans = caseDetails.otherPlans.filter(
                        otherPlan => otherPlan.id !== itemID
                    );

                    setPrescriptions(newPrescriptions);
                    onChange("otherPlans", newOtherPlans);

                    try {
                        await http.delete(
                            `${apiUrl}/otherPlan/${itemID}/${caseDetails.case_id}`
                        );

                        if (
                            newPrescriptions.length === 0 &&
                            caseDetails.is_from_cwapp
                        ) {
                            await http.put(
                                `${apiUrl}/ppPaper`,
                                {
                                    appointment_id: caseDetails.appointment_id,
                                    paperId: null,
                                    type: "Prescription"
                                },
                                {
                                    headers: { conn: "pp" }
                                }
                            );
                            socket.emit(
                                "reloadPPRecordList",
                                caseDetails.appointment_video_call_room_id
                            );
                            socket.emit(
                                "reloadPPRecordView",
                                caseDetails.appointment_video_call_room_id
                            );
                        }
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            }
        });
    };

    const handleServiceAdd = async selectedService => {
        const serviceFound = services.filter(
            service => service.item_id === selectedService.item_id
        );
        if (serviceFound.length === 0) {
            const { item_id, name } = selectedService;
            const newService = {
                case_id: caseDetails.case_id,
                patient_id: caseDetails.patient_id,
                id: item_id,
                value: name,
                type: "service"
            };

            setServices([...services, selectedService]);
            onChange("otherPlans", [...caseDetails.otherPlans, newService]);

            message.success("Service added!");

            try {
                await http.post(`${apiUrl}/otherPlan/`, newService);
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("The service was already on the list ");
        }
    };

    const handleServiceRemove = (serviceID, serviceName) => {
        confirm({
            title: `Are you sure do you want to remove ${serviceName} service?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();

                    const newServices = [
                        ...services.filter(
                            service => service.item_id !== serviceID
                        )
                    ];

                    const newOtherPlans = caseDetails.otherPlans.filter(
                        otherPlan => otherPlan.id !== serviceID
                    );

                    setServices(newServices);
                    onChange("otherPlans", newOtherPlans);

                    try {
                        await http.delete(
                            `${apiUrl}/otherPlan/${serviceID}/${caseDetails.case_id}`
                        );
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            }
        });
    };

    const handlePaperAdd = async (selectedPaper, isRequestPaper) => {
        const paperFound = papers.filter(
            p => p.paper_id === selectedPaper.paper_id
        );

        if (paperFound.length !== 0) {
            message.error("The paper was already on the list ");
        } else {
            const { category: selectedPaperCategory } = selectedPaper;

            if (isRequestPaper) {
                let selectedRequests = "";
                let type = "";
                if (selectedPaperCategory === "Lab Request") {
                    selectedRequests = JSON.stringify(
                        selectedPaper.selectedLabRequests
                    );
                    type = "labRequest";
                } else if (selectedPaperCategory === "Radiology Request") {
                    selectedRequests = JSON.stringify(
                        selectedPaper.selectedRadiologyRequests
                    );
                    type = "radiologyRequest";
                } else if (selectedPaperCategory === "Other Request") {
                    selectedRequests = JSON.stringify(
                        selectedPaper.selectedOtherRequests
                    );
                    type = "otherRequest";
                }

                const requestPaper = {
                    case_id: caseDetails.case_id,
                    patient_id: caseDetails.patient_id,
                    id: "",
                    value: selectedRequests,
                    type: type
                };

                const newOtherPlans = caseDetails.otherPlans.filter(
                    item => item.type !== type
                );
                newOtherPlans.push(requestPaper);

                if (type === "labRequest") {
                    setLabRequests(selectedPaper.selectedLabRequests);
                } else if (type === "radiologyRequest") {
                    setRadiologyRequests(
                        selectedPaper.selectedRadiologyRequests
                    );
                } else if (type === "otherRequest") {
                    setOtherRequests(selectedPaper.selectedOtherRequests);
                }

                onChange("otherPlans", newOtherPlans);

                message.success(`${selectedPaperCategory} added!`);

                try {
                    await http.post(`${apiUrl}/otherPlanRequestPaper/`, {
                        type,
                        requestPaper
                    });
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }

                // const newOtherPlans = caseDetails.otherPlans.map(
                //     (otherPlan) => {
                //         if (otherPlan.type === "labRequest" || otherPlan.type === "radiologyRequest" || otherPlan.type === "otherRequest") {
                //             return {
                //                 ...otherPlan,
                //                 value: selectedRequests,
                //             };
                //         }
                //         return otherPlan;
                //     }
                // );

                // const labRequestItemCount = newOtherPlans.filter(
                //     (otherPlan) => otherPlan.type === "labRequest"
                // ).length;

                // setLabRequests(selectedPaper.selectedLabRequests);
                // onChange(
                //     "otherPlans",
                //     labRequestItemCount !== 0
                //         ? newOtherPlans
                //         : [...caseDetails.otherPlans, newLabRequests]
                // );

                // message.success(`${selectedPaperCategory} added!`);

                // try {
                //     await http.post(
                //         `${apiUrl}/otherPlanLabReq/`,
                //         newLabRequests
                //     );
                // } catch (error) {
                //     setRequestError({
                //         errorMessage:
                //             "Something went wrong on your last operation :(",
                //         errorSubMessage: error.message,
                //     });
                // }
            } else {
                delete selectedPaper.selectedLabRequests;

                const { paper_id, name, category, print_available, doctor_id } =
                    selectedPaper;

                const newPapers = {
                    case_id: caseDetails.case_id,
                    patient_id: caseDetails.patient_id,
                    id: paper_id,
                    value: JSON.stringify({
                        paper_id,
                        name,
                        category,
                        print_available,
                        doctor_id
                    }),
                    type: "paper"
                };

                setPapers([...papers, selectedPaper]);
                onChange("otherPlans", [...caseDetails.otherPlans, newPapers]);

                message.success(`${selectedPaperCategory} added!`);

                try {
                    await http.post(`${apiUrl}/otherPlan/`, newPapers);
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        }
    };

    const handlePaperRemove = (paperID, paperName) => {
        confirm({
            title: `Are you sure do you want to remove ${paperName} paper?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();

                    const newPapers = [
                        ...papers.filter(paper => paper.paper_id !== paperID)
                    ];

                    const newOtherPlans = caseDetails.otherPlans.filter(
                        otherPlan => otherPlan.id !== paperID
                    );

                    setPapers(newPapers);
                    onChange("otherPlans", newOtherPlans);

                    try {
                        await http.delete(
                            `${apiUrl}/otherPlan/${paperID}/${caseDetails.case_id}`
                        );

                        if (caseDetails.is_from_cwapp) {
                            await http.put(
                                `${apiUrl}/ppPaper`,
                                {
                                    appointment_id: caseDetails.appointment_id,
                                    paperId: paperID,
                                    type: null
                                },
                                {
                                    headers: { conn: "pp" }
                                }
                            );
                            socket.emit(
                                "reloadPPRecordList",
                                caseDetails.appointment_video_call_room_id
                            );
                            socket.emit(
                                "reloadPPRecordView",
                                caseDetails.appointment_video_call_room_id
                            );
                        }
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            }
        });
    };

    const handleRequestPaperItemRemove = (request, type) => {
        confirm({
            title: `Are you sure do you want to remove ${request} request?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();

                    let requests = [];
                    let paperCategory = "";
                    if (type === "labRequest") {
                        requests = [...labRequests];
                        requests.splice(requests.indexOf(request), 1);
                        paperCategory = "Lab Request";
                    } else if (type === "radiologyRequest") {
                        requests = [...radiologyRequests];
                        requests.splice(requests.indexOf(request), 1);
                        paperCategory = "Radiology Request";
                    } else if (type === "otherRequest") {
                        requests = [...otherRequests];
                        requests.splice(requests.indexOf(request), 1);
                        paperCategory = "Other Request";
                    }

                    const newOtherPlans = caseDetails.otherPlans.map(
                        otherPlan => {
                            if (otherPlan.type === type) {
                                return {
                                    ...otherPlan,
                                    value: JSON.stringify(requests)
                                };
                            }
                            return otherPlan;
                        }
                    );

                    if (type === "labRequest") {
                        setLabRequests(requests);
                    } else if (type === "radiologyRequest") {
                        setRadiologyRequests(requests);
                    } else if (type === "otherRequest") {
                        setOtherRequests(requests);
                    }

                    onChange("otherPlans", newOtherPlans);

                    try {
                        await http.post(`${apiUrl}/otherPlanRequestPaper`, {
                            type,
                            requestPaper: {
                                case_id: caseDetails.case_id,
                                value: JSON.stringify(requests)
                            }
                        });

                        if (
                            requests.length === 0 &&
                            caseDetails.is_from_cwapp
                        ) {
                            await http.put(
                                `${apiUrl}/ppPaper`,
                                {
                                    appointment_id: caseDetails.appointment_id,
                                    paperId: null,
                                    type: paperCategory
                                },
                                {
                                    headers: { conn: "pp" }
                                }
                            );
                            socket.emit(
                                "reloadPPRecordList",
                                caseDetails.appointment_video_call_room_id
                            );
                            socket.emit(
                                "reloadPPRecordView",
                                caseDetails.appointment_video_call_room_id
                            );
                        }
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            }
        });
    };

    const handleAppointmentSet = async (formData, isSaveOnPatientPortal) => {
        try {
            const appointmentID = generateString(10, "a");
            const newFormData = {
                ...formData,
                appointment_id: appointmentID,
                status: isSaveOnPatientPortal ? "followup" : "pending",
                is_from_cwapp: 0
            };

            setAppointmentDetails(newFormData);
            onChange("appointmentDetails", newFormData);

            message.success("Patient successfully appointed");
            await http.post(`${apiUrl}/appointment/`, newFormData);
            if (isSaveOnPatientPortal) {
                await http.post(
                    `${apiUrl}/ppafollowup/`,
                    {
                        ...newFormData,
                        followup_id: generateString(10, "f"),
                        prev_appointment_id: caseDetails.appointment_id
                    },
                    {
                        headers: {
                            conn: "pp"
                        }
                    }
                );

                socket.emit(
                    "reloadPPffConsultation",
                    caseDetails.appointment_video_call_room_id
                );
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleAppointmentDelete = async () => {
        confirm({
            title: `Are you sure do you want to remove patient appointment?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();
                    setAppointmentDetails(null);
                    onChange("appointmentDetails", null);
                    try {
                        const appointmentID = appointmentDetails.appointment_id;

                        await http.put(
                            `${apiUrl}/deleteAppointment/${appointmentID}`
                        );
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            }
        });
    };

    let isShowDivider = false;
    if (
        prescriptions.length !== 0 ||
        services.length !== 0 ||
        labRequests.length !== 0 ||
        radiologyRequests.length !== 0 ||
        otherRequests.length !== 0 ||
        papers.length !== 0 ||
        appointmentDetails !== null
    ) {
        isShowDivider = true;
    }

    return (
        <Card
            actions={
                !isCaseReadOnly
                    ? [
                          <PrescriptionNoteDrawer
                              breakpoint={breakpoint}
                              onPrescriptionAdd={handlePrescriptionAdd}
                          />,
                          <ServiceDrawer
                              breakpoint={breakpoint}
                              onServiceAdd={handleServiceAdd}
                          />,
                          <PaperDrawer
                              breakpoint={breakpoint}
                              onPaperAdd={handlePaperAdd}
                          />,
                          <AppointmentDrawer
                              caseID={caseDetails.case_id}
                              doctorID={caseDetails.doctor_id}
                              isFromCWAPP={caseDetails.is_from_cwapp}
                              patientDetails={patientDetails}
                              appointmentDetails={appointmentDetails}
                              breakpoint={breakpoint}
                              onAppointmentSet={handleAppointmentSet}
                          />
                      ]
                    : []
            }
            bodyStyle={{
                padding: breakpoint.isNormalMobile
                    ? "0 16px 0 24px"
                    : "0 24px 0 42px"
            }}
            style={{ borderTop: "none", marginBottom: 16 }}
        >
            {isShowDivider && (
                <div
                    style={{
                        borderTop: "1px solid #e8e8e8",
                        marginBottom: 16
                    }}
                ></div>
            )}

            <PrescriptionList
                prescriptions={prescriptions}
                caseDetails={caseDetails}
                patientDetails={patientDetails}
                readOnly={isCaseReadOnly}
                onRemove={handlePrescriptionRemove}
            />
            <ServiceList
                services={services}
                readOnly={isCaseReadOnly}
                onRemove={handleServiceRemove}
            />
            <LabRequestList
                labRequests={labRequests}
                caseDetails={caseDetails}
                patientDetails={patientDetails}
                readOnly={isCaseReadOnly}
                onRemove={handleRequestPaperItemRemove}
            />
            <RadiologyRequestList
                radiologyRequests={radiologyRequests}
                caseDetails={caseDetails}
                patientDetails={patientDetails}
                readOnly={isCaseReadOnly}
                onRemove={handleRequestPaperItemRemove}
            />
            <OtherRequestList
                otherRequests={otherRequests}
                caseDetails={caseDetails}
                patientDetails={patientDetails}
                readOnly={isCaseReadOnly}
                onRemove={handleRequestPaperItemRemove}
            />
            <PaperList
                selectedPapers={papers}
                patientDetails={patientDetails}
                caseDetails={caseDetails}
                readOnly={isCaseReadOnly}
                onRemove={handlePaperRemove}
            />
            <AppointmentDetails
                details={appointmentDetails}
                readOnly={isCaseReadOnly}
                onRemove={handleAppointmentDelete}
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    items: state.Items
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherPlans);
