import React from "react";
import { Link } from "react-router-dom";
import { DatePicker, Table, Icon, Card, Divider, Badge, Row, Col } from "antd";

const PatientPortalPayment = () => {
    const { RangePicker } = DatePicker;

    const paymentsTableColumns = [
        {
            title: "Payment Id",
        },
        {
            title: "Amount",
        },
        {
            title: "Account Number",
        },
        {
            title: "Date Created",
        },
        {
            title: "",
            width: 50,
        },
    ];

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            <div style={{ marginBottom: 16 }}>
                {/* {isMobileBreakpoint ? (
                    <div
                        style={{
                            marginBottom: 5
                        }}
                    >
                        Select Date Range:
                    </div>
                ) : (
                    "Select Date Range: "
                )}
                <RangePicker onChange={handleBillingDateRangeChange} /> */}
                <Row type="flex" justify="space-between">
                    <Col>
                        Select Date Range: <RangePicker />
                    </Col>
                    <Col>
                        Total Amount:{" "}
                        <Badge
                            showZero
                            count={0}
                            style={{
                                backgroundColor: "#52c41a",
                                color: "#fff",
                            }}
                        />
                    </Col>
                </Row>
            </div>

            <div style={{ marginBottom: 16 }}>
                <Table
                    columns={paymentsTableColumns}
                    dataSource={[]}
                    // onChange={handleTableChange}
                    // rowKey={"bill_id"}
                    // loading={billsReportTableLoading}
                    // pagination={{
                    //     showTotal: (total, range) =>
                    //         `${range[0]}-${range[1]} of ${total} items`,
                    //     simple: isMobileBreakpoint ? true : false
                    // }}
                    className="reportsTable"
                />
            </div>
        </Card>
    );
};

export default PatientPortalPayment;
