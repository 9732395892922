import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Form, Input, InputNumber, Button, message } from "antd";

const ServiceForm = (props) => {
    const { data, onSubmit, clinicID, userID, plan } = props;

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        generic_name: "",
        formulation: "",
        stock_qty: 0,
        reorder_qty: 0,
        price: 0,
        item_type: "service",
        item_unit_id: "",
        item_group_id: "",
        clinic_id: clinicID,
        user_id: userID,
        img_path: "",
        status: "active",
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        price: "",
    });

    //first element focus refs
    const txtServiceName = React.createRef();

    useEffect(() => {
        if (data) {
            delete data.user_id;
            setFormData(data);
        }

        txtServiceName.current.input.focus();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputNumberChange = (value) => {
        setFormData({
            ...formData,
            price: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name, price } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Service name is required";
        }
        if (price === null) {
            errors.price = "Service price is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{
                    xl: { span: 8 },
                    lg: { span: 9 },
                }}
                wrapperCol={{
                    xl: { span: 16 },
                    lg: { span: 15 },
                }}
                style={{
                    width: "100%",
                }}
            >
                <Form.Item
                    label="Service Name"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.name && "error"}
                    help={formErrors.name && formErrors.name}
                >
                    <Input
                        ref={txtServiceName}
                        name="name"
                        autoComplete="off"
                        value={formData.name}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        autoComplete="off"
                        value={formData.description}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                {plan !== "basic" && (
                    <Form.Item
                        label="Price"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.price && "error"}
                        help={formErrors.price && formErrors.price}
                    >
                        <InputNumber
                            value={formData.price}
                            formatter={(value) =>
                                `${value}`
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .replace(/[^0-9.,]/g, "")
                            }
                            onChange={(value) => handleInputNumberChange(value)}
                            onBlur={handleInputBlur}
                            style={{
                                width: "100%",
                            }}
                        />
                    </Form.Item>
                )}

                <Form.Item label=" ">
                    <Button
                        type="primary"
                        style={{ marginTop: 10 }}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    userID: state.UserDetails.user_id,
    plan: state.UserDetails.subscriptionDetails.plan,
});

export default connect(mapStateToProps)(ServiceForm);
