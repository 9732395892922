import React from "react";
import { Avatar, Button, Icon } from "antd";

const ContactPatient = ({
    breakpoint,
    videoCallDetails,
    onCancelPatientCall,
}) => {
    const {
        firstname: patientFirstname,
        lastname: patientLastname,
        img_path: patientImgPath,
    } = videoCallDetails.patientDetails;

    return (
        <div
            style={{
                position: breakpoint.isNormalMobile ? "fixed" : "fixed",
                top: 0,
                left: breakpoint.isNormalMobile ? 0 : "calc(50% - 200px)",
                width: breakpoint.isNormalMobile ? "100%" : 400,
                height: breakpoint.isNormalMobile ? "100vh" : "auto",
                padding: "50px 10px",
                backgroundColor: "#000",
            }}
        >
            <div style={{ textAlign: "center" }}>
                {patientImgPath ? (
                    <Avatar
                        size={110}
                        shape="circle"
                        src={patientImgPath.replace(".jpeg", "-card.jpeg")}
                        alt="Patient Image"
                    />
                ) : (
                    <Avatar icon="user" size={110} shape="circle" />
                )}
                <h3
                    style={{
                        color: "#fff",
                        margin: "10px 0 0.5em",
                        fontWeight: 600,
                        fontSize: 18,
                        lineHeight: 1.4,
                    }}
                >
                    {patientLastname}, {patientFirstname}
                </h3>
            </div>

            <div
                style={{
                    color: "#fff",
                    textAlign: "center",
                }}
            >
                <div>
                    <Icon type="loading" />
                    <br />
                    Contacting Patient ...
                </div>
                <div style={{ marginTop: 20 }}>
                    <Button type="danger" onClick={onCancelPatientCall}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContactPatient;
