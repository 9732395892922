import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Tooltip, Menu, Table, Icon } from "antd";
import moment from "moment";
import PatientCard from "globalComponents/PatientCard";
import DynamicForm from "globalComponents/DynamicForm/Index";
import Drawer from "globalComponents/Drawer";

import { formatNumberWithSign } from "helpers/numberFormatter";

const PatientInfo = ({
    patientDetails,
    readOnly: isReadOnly,
    breakpoint,
    caseStatus,
    //onPatientCallClick,
    //onPatientNoShowClick,
    PHForm,
    plan,
}) => {
    const [isPatientHistoryVisible, setIsPatientHistoryVisible] = useState(
        false
    );
    const [isPatientBillsVisible, setIsPatientBillsVisible] = useState(false);
    const [patientID, setPatientID] = useState(null);
    const [patientHistoryForm, setPatientHistoryForm] = useState(PHForm);

    useEffect(() => {
        const PHFormValues = patientDetails.history
            ? JSON.parse(patientDetails.history)
            : {};

        //supply values to patient history form
        setPatientHistoryForm(
            patientHistoryForm.map((formInput) => ({
                ...formInput,
                value: PHFormValues[formInput.name]
                    ? PHFormValues[formInput.name]
                    : "",
            }))
        );

        setPatientID(patientDetails.patient_id);
    }, [patientDetails.patient_id]);

    const togglePatientHistoryVisible = () => {
        setIsPatientHistoryVisible(!isPatientHistoryVisible);
    };

    const togglePatientBillsVisible = () => {
        setIsPatientBillsVisible(!isPatientBillsVisible);
    };

    // const handlePatientCall = () => {
    //     onPatientCallClick();
    // };
    // const handlePatientNoShow = () => {
    //     onPatientNoShowClick("no-show");
    // };

    const PatientCardMenu = (
        <Menu>
            {/* {caseStatus !== "done" && !isReadOnly && (
                <Menu.Item key="1" onClick={handlePatientNoShow}>
                    Patient No Show
                </Menu.Item>
            )} */}
            <Menu.Item key="2" onClick={togglePatientHistoryVisible}>
                Show Patient History
            </Menu.Item>
            {plan !== "basic" && (
                <Menu.Item key="3" onClick={togglePatientBillsVisible}>
                    Show Billing History
                </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key="4">
                <Link to={`/case/create/${patientDetails.patient_id}`}>
                    Create Case
                </Link>
            </Menu.Item>
        </Menu>
    );

    const patientBillsTableColumns = [
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            render: (text, record) => moment(record.bill_date).format("LL"),
        },
        {
            title: "Remaining Bal.",
            dataIndex: "amount_balance",
            render: (text, record) => formatNumberWithSign(text),
        },
    ];

    const extraActions = [
        <Dropdown
            overlay={PatientCardMenu}
            placement="bottomRight"
            trigger={["click"]}
        >
            <Tooltip title="More">
                <Icon type="more" />
            </Tooltip>
        </Dropdown>,
    ];

    return (
        <>
            <div style={{ border: "1px solid #e8e8e8", marginBottom: 16 }}>
                {/* <div
                    style={{
                        padding: 10,
                        backgroundColor: "#fff",
                        borderBottom: "1px solid #e8e8e8"
                    }}
                >
                    <Row type="flex" justify="space-between">
                        <Col>
                            <ButtonGroup>
                                <Tooltip title="Call Patient">
                                    <Button
                                        icon="notification"
                                        onClick={handlePatientCall}
                                    ></Button>
                                </Tooltip>
                                {caseStatus !== "done" && !isReadOnly && (
                                    <Tooltip title="Patient No show">
                                        <Button
                                            icon="user-delete"
                                            onClick={handlePatientNoShow}
                                        ></Button>
                                    </Tooltip>
                                )}
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <Dropdown
                                overlay={PatientCardMenu}
                                placement="bottomRight"
                                trigger={["click"]}
                            >
                                <Tooltip title="Patient Other Info">
                                    <Button icon="more"></Button>
                                </Tooltip>
                            </Dropdown>
                        </Col>
                    </Row>
                </div> */}
                <div>
                    <PatientCard
                        patientID={patientID}
                        moreDetailsVisible={true}
                        bordered={false}
                        extraActions={extraActions}
                        breakpoint={breakpoint}
                        showEditPatientInfoBtn={true}
                    />
                </div>
            </div>

            <Drawer
                title="Patient History"
                visible={isPatientHistoryVisible}
                width={breakpoint.isNormalMobile ? 320 : 700}
                onClose={togglePatientHistoryVisible}
            >
                <DynamicForm
                    mode="text"
                    form={patientHistoryForm}
                    errors={{}}
                    layout={null}
                />
            </Drawer>

            <Drawer
                title="Patient Bills"
                visible={isPatientBillsVisible}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={togglePatientBillsVisible}
            >
                <Table
                    columns={patientBillsTableColumns}
                    dataSource={patientDetails.bills}
                    rowKey="bill_id"
                    pagination={false}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    PHForm: state.Settings.patientHistoryForm,
    plan: state.UserDetails.subscriptionDetails.plan,
});

export default connect(mapStateToProps)(PatientInfo);
