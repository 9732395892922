import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Button,
    Icon,
    Divider,
    Row,
    Col,
    message,
    Modal,
    Upload,
    Spin
} from "antd";
import Webcam from "react-webcam";

import CustomPictureWall from "./components/CustomPictureWall";
import SketchCanvas from "./components/SketchCanvas/Index";

import { b64ImgToFileHelper, resizeImgHelper } from "helpers/image";
import isMobile from "helpers/deviceDetector";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ImageManager = ({
    target,
    caseDetails,
    readOnly: isCaseReadOnly,
    breakpoint,
    onChange,
    userID,
    ownerID,
    clinicID,
    uploadServer,
    uploadEndpoint,
    setRequestError
}) => {
    const [SOAPImgs, setSOAPImgs] = useState([]);

    const [isUploading, setIsUploading] = useState(false);
    const [imageSrc, setImageSrc] = useState("");
    const [selectedSOAPImg, setSelectedSOAPImg] = useState(null);

    const [isUploaderVisible, setIsUploaderVisible] = useState(false);
    const [isCameraVisible, setIsCameraVisible] = useState(false);
    const [isSketchVisible, setIsSketchVisible] = useState(false);

    const { confirm } = Modal;

    //react-webcam ref
    let webcam = null;
    const setRef = wc => {
        webcam = wc;
    };

    useEffect(() => {
        setSOAPImgs(caseDetails.SOAPImgs);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, [caseDetails]);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const toggleUploaderVisible = () => {
        setIsUploaderVisible(!isUploaderVisible);
    };

    const toggleCameraVisible = () => {
        setIsCameraVisible(!isCameraVisible);
    };

    const toggleSketchVisible = () => {
        setIsSketchVisible(!isSketchVisible);
    };

    const showSketch = (selectedRecord = null) => {
        setSelectedSOAPImg(selectedRecord);
        toggleSketchVisible();
    };

    const handleCameraCapture = () => {
        const imageSrc = webcam.getScreenshot();
        setImageSrc(imageSrc);
        toggleCameraVisible();
    };

    const handleUploadCompBeforeUpload = file => {
        const isValidFile =
            file.type === "image/jpeg" || file.type === "image/png";
        if (!isValidFile) {
            message.error("You can only upload JPEG/PNG  file!");
        }
        const isValidSize = file.size / 1024 / 1024 < 3;
        if (!isValidSize) {
            message.error("File must smaller than 3MB!");
        }
        return isValidFile && isValidSize;
    };

    const handleUploadCompChange = info => {
        if (info.file.type === "image/png" || info.file.type === "image/jpeg") {
            getBase64(
                info.fileList[info.fileList.length - 1].originFileObj,
                imageUrl => {
                    setImageSrc(imageUrl);
                }
            );
        }
    };

    const uploadImgNew = () => {
        if (!imageSrc) {
            message.error("Please select image!");
        } else {
            resizeImgHelper(
                imageSrc,
                150,
                "img/jpg",
                async function (base64Img) {
                    try {
                        setIsUploading(true);
                        //create filename
                        const filename = Date.now();
                        const origImgFilename = `${filename}.jpeg`;
                        const resizeImgFilename = `${filename}-thumb.jpeg`;

                        //convert b64 img to file
                        const origImgFile = b64ImgToFileHelper(
                            imageSrc,
                            origImgFilename
                        );
                        const resizedImgFile = b64ImgToFileHelper(
                            base64Img,
                            resizeImgFilename
                        );

                        //get file size
                        const origImgFileSize = origImgFile.size;
                        const resizedImgFileSize = resizedImgFile.size;

                        const totalImgSize =
                            origImgFileSize + resizedImgFileSize;

                        //prepare to upload
                        let fd = new FormData();
                        fd.append("uniFile", origImgFile);
                        fd.append("uniFile", resizedImgFile);

                        const uploadResponse = await http.post(
                            `${apiUrl}/soapImage`,
                            fd,
                            {
                                params: {
                                    space_server: uploadServer,
                                    space_bucket_name: uploadEndpoint,
                                    root_dir: cwaUploadRootDir,
                                    owner_id: ownerID,
                                    clinic_id: clinicID
                                },
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                })
                            }
                        );

                        if (uploadResponse.data.error) {
                            message.error("failed to upload image");
                        } else {
                            //insert to db
                            const datePath = uploadResponse.data.datePath;
                            let SOAPImgData = {
                                target,
                                filename,
                                date_path: datePath,
                                img_path: `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${datePath}/${origImgFilename}`,
                                img_size: totalImgSize,
                                case_id: caseDetails.case_id,
                                user_id: userID,
                                is_from_legacy: 0,
                                endpoint: uploadEndpoint,
                                status: "active"
                            };

                            //insert
                            const response = await http.post(
                                `${apiUrl}/soapImg`,
                                SOAPImgData,
                                {
                                    cancelToken: new CancelToken(
                                        function executor(c) {
                                            CancelRequest = c;
                                        }
                                    )
                                }
                            );

                            const { case_soap_img_id, created_at, updated_at } =
                                response.data.newSOAPImg;

                            const newSOAPImgs = [
                                ...SOAPImgs,
                                {
                                    ...SOAPImgData,
                                    case_soap_img_id,
                                    created_at,
                                    updated_at
                                }
                            ];

                            setSOAPImgs(newSOAPImgs);
                            setImageSrc("");
                            setSelectedSOAPImg(null);
                            toggleUploaderVisible();
                            setIsUploading(false);
                            onChange("SOAPImgs", newSOAPImgs);
                        }
                    } catch (error) {
                        if (!error.message.responseCancelled) {
                            setRequestError({
                                errorMessage:
                                    "Something went wrong on your last operation :(",
                                errorSubMessage: error.message
                            });
                        }
                    }
                }
            );
        }
    };

    const uploadImgUpdate = imageSrc => {
        resizeImgHelper(imageSrc, 150, "img/jpg", async function (base64Img) {
            try {
                setIsUploading(true);

                //create filename
                const filename =
                    selectedSOAPImg.endpoint !== uploadEndpoint
                        ? Date.now()
                        : selectedSOAPImg.filename;
                const resizeImgFilename = `${filename}-thumb.jpeg`;
                const origImgFilename = `${filename}.jpeg`;

                //convert b64 img to file
                const origImgFile = b64ImgToFileHelper(
                    imageSrc,
                    origImgFilename
                );
                const resizedImgFile = b64ImgToFileHelper(
                    base64Img,
                    resizeImgFilename
                );

                //get file size
                const origImgFileSize = origImgFile.size;
                const resizedImgFileSize = resizedImgFile.size;

                const totalImgSize = origImgFileSize + resizedImgFileSize;

                //prepare to upload
                let fd = new FormData();
                fd.append("uniFile", origImgFile);
                fd.append("uniFile", resizedImgFile);

                let uploadResponse = "";
                if (selectedSOAPImg.endpoint !== uploadEndpoint) {
                    uploadResponse = await http.post(
                        `${apiUrl}/soapImage`,
                        fd,
                        {
                            params: {
                                space_server: uploadServer,
                                space_bucket_name: uploadEndpoint,
                                root_dir: cwaUploadRootDir,
                                owner_id: ownerID,
                                clinic_id: clinicID
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );
                } else {
                    uploadResponse = await http.post(
                        `${apiUrl}/updateSoapImage`,
                        fd,
                        {
                            params: {
                                space_server: uploadServer,
                                space_bucket_name: uploadEndpoint,
                                root_dir: cwaUploadRootDir,
                                owner_id: ownerID,
                                clinic_id: clinicID,
                                datePath: selectedSOAPImg.date_path
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );
                }

                if (uploadResponse.data.error) {
                    message.error("failed to upload image");
                } else {
                    //update db
                    const datePath = uploadResponse.data.datePath;
                    let SOAPImgData = {
                        target,
                        filename,
                        date_path: datePath,
                        img_path: `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${datePath}/${origImgFilename}`,
                        img_size: totalImgSize,
                        case_id: caseDetails.case_id,
                        user_id: userID,
                        endpoint: uploadEndpoint,
                        status: "active"
                    };

                    //update
                    await http.put(
                        `${apiUrl}/soapImg/`,
                        {
                            ...selectedSOAPImg,
                            ...SOAPImgData
                        },
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    const newSOAPImgs = SOAPImgs.map(SOAPImg => {
                        if (
                            SOAPImg.case_soap_img_id ===
                            selectedSOAPImg.case_soap_img_id
                        ) {
                            return {
                                ...SOAPImg,
                                ...SOAPImgData,
                                img_path: `${
                                    SOAPImgData.img_path
                                }?${new Date().getTime()}`
                            };
                        }
                        return SOAPImg;
                    });

                    setSOAPImgs(newSOAPImgs);
                    setImageSrc("");
                    setSelectedSOAPImg(null);
                    setIsUploading(false);
                    onChange("SOAPImgs", newSOAPImgs);
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        });
    };

    const handleSketchSave = imageSrc => {
        if (selectedSOAPImg) {
            toggleSketchVisible();
            uploadImgUpdate(imageSrc);
        } else {
            setImageSrc(imageSrc);
            toggleSketchVisible();
        }
    };

    const handleSOAPImgDelete = imgID => {
        confirm({
            title: `Are you sure do you want to delete this image`,
            content: `this cannot be undone`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    resolve();

                    const newSOAPImgs = caseDetails.SOAPImgs.filter(
                        SOAPImg => SOAPImg.case_soap_img_id !== imgID
                    );

                    setSOAPImgs(newSOAPImgs);
                    onChange("SOAPImgs", newSOAPImgs);

                    try {
                        await http.delete(
                            `${apiUrl}/soapImg/${imgID}/${caseDetails.case_id}`
                        );
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    return (
        <div>
            <Spin spinning={isUploading} tip="Uploading...">
                <CustomPictureWall
                    target={target}
                    SOAPImgs={SOAPImgs}
                    onEdit={showSketch}
                    onDelete={handleSOAPImgDelete}
                />

                {isUploaderVisible && (
                    <div>
                        <Divider />
                        <Row gutter={16}>
                            <Col xl={12} lg={12} md={12}>
                                {imageSrc ? (
                                    <img
                                        src={imageSrc}
                                        alt="soap pic"
                                        style={{
                                            width: 300,
                                            height: 300,
                                            border: "1px solid #d0d0d0"
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            marginTop: 30,
                                            textAlign: "center"
                                        }}
                                    >
                                        <Icon
                                            type="picture"
                                            style={{ fontSize: "3rem" }}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col xl={12} lg={12} md={12}>
                                <div style={{ textAlign: "center" }}>
                                    <div
                                        style={{
                                            marginBottom: 15,
                                            marginTop: 16
                                        }}
                                    >
                                        <Button.Group>
                                            <Button
                                                onClick={toggleCameraVisible}
                                            >
                                                <Icon type="camera" /> Camera
                                            </Button>
                                            <Button
                                                onClick={() => showSketch()}
                                            >
                                                <Icon type="form" /> Draw
                                            </Button>
                                            <Upload
                                                onChange={
                                                    handleUploadCompChange
                                                }
                                                beforeUpload={
                                                    handleUploadCompBeforeUpload
                                                }
                                                showUploadList={false}
                                            >
                                                <Button>
                                                    <Icon type="file-image" />{" "}
                                                    Select File
                                                </Button>
                                            </Upload>
                                        </Button.Group>
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            onClick={uploadImgNew}
                                        >
                                            <Icon type="upload" /> Upload
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                    </div>
                )}
                {!isCaseReadOnly && (
                    <div style={{ marginTop: isUploaderVisible ? 0 : 16 }}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={toggleUploaderVisible}
                        >
                            {isUploaderVisible ? "Close" : "Open"} Uploader
                        </Button>
                    </div>
                )}
            </Spin>

            <Modal
                title="Camera"
                visible={isCameraVisible}
                okText="Capture"
                style={{ top: 20 }}
                onCancel={toggleCameraVisible}
                onOk={handleCameraCapture}
                destroyOnClose={true}
                bodyStyle={breakpoint.isNormalMobile ? { padding: 0 } : null}
            >
                <div style={{ textAlign: "center" }}>
                    <Webcam
                        style={{ border: "1px solid #e4e4e4" }}
                        height={350}
                        width={breakpoint.isNormalMobile ? "100%" : 450}
                        screenshotFormat="image/jpeg"
                        screenshotWidth={300}
                        screenshotQuality={0.9}
                        ref={setRef}
                        videoConstraints={
                            isMobile.any() && {
                                facingMode: { exact: "environment" }
                            }
                        }
                    />
                </div>
            </Modal>

            <Modal
                title="Sketch"
                visible={isSketchVisible}
                footer={<Button onClick={toggleSketchVisible}>Cancel</Button>}
                onCancel={toggleSketchVisible}
                centered
                destroyOnClose={true}
                width={650}
            >
                <SketchCanvas
                    selectedSketch={selectedSOAPImg}
                    breakpoint={breakpoint}
                    onSave={handleSketchSave}
                />
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageManager);
