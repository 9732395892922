import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Progress, Icon, message, Tooltip } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const StorageChecker = ({ clinics, doctors, maxStorage }) => {
    const [isLoading, setLoading] = useState(true);
    const [totalGB, setTotalGB] = useState(0);
    const [storagePercent, setStoragePercent] = useState(0);
    const [maxGBStorage, setMaxGBStorage] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`${apiUrl}/storageUsed`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                });

                const {
                    patient,
                    item,
                    hmo,
                    caseSoap,
                    queueingImg,
                    queueingVid,
                    patientFiles,
                    patientImgGallery,
                    sketchBackgroundImgs,
                } = response.data;

                const totalPatientImgSize = patient[0].img_size || 0;
                const totalItemImgSize = item[0].img_size || 0;
                const totalHMOImgSize = hmo[0].img_size || 0;
                const totalCaseSOAPImgImgSize = caseSoap[0].img_size || 0;
                const totalQueueingUploadedImgSize =
                    queueingImg[0].img_size || 0;
                const totalQueueingVideoSize = queueingVid[0].img_size || 0;

                let totalPatientFileImgSize = 0;
                if (patientFiles.length !== 0) {
                    totalPatientFileImgSize = patientFiles.reduce(
                        (acc, item) => {
                            const items = JSON.parse(item.files);
                            const itemsImgSize = items.reduce((acc, item) => {
                                return acc + (item.size || 0);
                            }, 0);
                            return acc + itemsImgSize;
                        },
                        0
                    );
                }

                let totalPatientImgGalleryImgSize = 0;
                if (patientImgGallery.length !== 0) {
                    totalPatientImgGalleryImgSize = patientImgGallery.reduce(
                        (acc, item) => {
                            const items = JSON.parse(item.images);
                            const itemsImgSize = items.reduce((acc, item) => {
                                return acc + (item.size || 0);
                            }, 0);
                            return acc + itemsImgSize;
                        },
                        0
                    );
                }

                let sketchBackgroundImgsSize = 0;
                if (sketchBackgroundImgs.length !== 0) {
                    sketchBackgroundImgsSize = sketchBackgroundImgs.reduce(
                        (acc, item) => {
                            const items = JSON.parse(item.sketch_backgrounds);
                            const itemsImgSize = items.reduce((acc, item) => {
                                return acc + (item.size || 0);
                            }, 0);
                            return acc + itemsImgSize;
                        },
                        0
                    );
                }

                const totalDoctorsImgSize = doctors.reduce(
                    (acc, item) => acc + item.img_size,
                    0
                );
                const totalClinicsImgSize = clinics.reduce(
                    (acc, item) => acc + item.img_size,
                    0
                );

                const totalBytes =
                    totalPatientImgSize +
                    totalItemImgSize +
                    totalHMOImgSize +
                    totalCaseSOAPImgImgSize +
                    totalQueueingUploadedImgSize +
                    totalQueueingVideoSize +
                    totalPatientFileImgSize +
                    totalPatientImgGalleryImgSize +
                    sketchBackgroundImgsSize +
                    totalDoctorsImgSize +
                    totalClinicsImgSize;

                const totalGB = totalBytes / 1024 / 1024 / 1024;
                const maxGBStorage = maxStorage / 1024;
                const storagePercent = (totalGB / maxGBStorage) * 100;

                setTotalGB(totalGB);
                setStoragePercent(storagePercent);
                setMaxGBStorage(maxGBStorage);
                setLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error(error.message || "Error getting storage");
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    return isLoading ? (
        <div style={{ textAlign: "center" }}>
            <div>
                <Icon type="loading" />
            </div>
            <div>loading</div>
        </div>
    ) : (
        <div>
            <Tooltip title={`${totalGB.toString().substring(0, 7)} GB`}>
                <div>
                    {totalGB.toString().substring(0, 4)} GB of {maxGBStorage} GB
                    used
                </div>
            </Tooltip>
            <Progress percent={storagePercent} showInfo={false} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    clinics: state.UserDetails.clinics,
    doctors: state.UserDetails.doctors,
    maxStorage: state.UserDetails.maxStorageSize,
});

export default connect(mapStateToProps)(StorageChecker);
