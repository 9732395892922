import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Button, Table, Input, Icon, Tooltip, Card, Divider } from "antd";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import ContentLoader from "globalComponents/ContentLoader";
import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ItemsReport = ({ breakpoint, clinicID, setRequestError }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [itemReportTableLoading, setItemReportTableLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [itemUnits, setItemUnits] = useState([]);
    const [itemGroups, setItemGroups] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFetching(true);
                const itemUnitResponse = await http.get(`${apiUrl}/itemUnits`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                });
                setItemUnits(itemUnitResponse.data.itemUnits);
                const itemGroupResponse = await http.get(
                    `${apiUrl}/itemGroups`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                setItemGroups(itemGroupResponse.data.itemGroups);
                setIsFetching(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleShowItems = async () => {
        try {
            setItemReportTableLoading(true);
            const response = await http.get(
                `${apiUrl}/report/all/items/${clinicID}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );
            setItems(response.data.items);
            setTableData(response.data.items);
            setItemReportTableLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setTableData(extra.currentDataSource);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const itemReportColumns = [
        { title: "Name", dataIndex: "name", ...getColumnSearchProps("name") },
        {
            title: "Generic Name",
            dataIndex: "generic_name",
            ...getColumnSearchProps("generic_name")
        },
        {
            title: "Group",
            dataIndex: "item_group_name",
            filters: [
                ...itemGroups.map(itemGroup => ({
                    text: itemGroup.name,
                    value: itemGroup.item_group_id
                }))
            ],
            //   filterMultiple: false,
            onFilter: (value, record) => {
                return record.item_group_id === value;
            }
        },
        {
            title: "Unit",
            dataIndex: "item_unit_name",
            filters: [
                ...itemUnits.map(itemUnit => ({
                    text: itemUnit.name,
                    value: itemUnit.item_unit_id
                }))
            ],
            //   filterMultiple: false,
            onFilter: (value, record) => {
                return record.item_unit_id === value;
            }
        },
        {
            title: "Stock Qty.",
            dataIndex: "stock_qty",
            filters: [
                {
                    text: "Below 0",
                    value: 0
                },
                {
                    text: "Below 10",
                    value: 10
                },
                {
                    text: "Below 20",
                    value: 20
                },
                {
                    text: "Below 50",
                    value: 50
                },
                {
                    text: "Below 100",
                    value: 100
                },
                {
                    text: "Above 100",
                    value: 101
                }
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                if (value === 101) {
                    return record.stock_qty >= value;
                } else {
                    return record.stock_qty < value;
                }
            }
        },
        { title: "Reorder Qty.", dataIndex: "reorder_qty" },
        { title: "Price", dataIndex: "price" }
    ];

    const CSVHeaders = [
        { label: "Name", key: "name" },
        {
            label: "Generic Name",
            key: "generic_name"
        },
        {
            label: "Group",
            key: "item_group_name"
        },
        {
            label: "Unit",
            key: "item_unit_name"
        },
        {
            label: "Stock Qty.",
            key: "stock_qty"
        },
        { label: "Reorder Qty.", key: "reorder_qty" },
        { label: "Price", key: "price" }
    ];

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            {isFetching ? (
                <ContentLoader />
            ) : (
                <>
                    <div style={{ marginBottom: 16 }}>
                        <Button type="primary" onClick={handleShowItems}>
                            Show Items
                        </Button>
                    </div>
                    <div
                        style={{
                            marginBottom: 16,
                            minWidth: 320,
                            overflow: "auto",
                            backgroundColor: "#fff"
                        }}
                    >
                        <Table
                            columns={itemReportColumns}
                            dataSource={items}
                            rowKey={"item_id"}
                            loading={itemReportTableLoading}
                            pagination={{
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`,
                                simple: isMobileBreakpoint ? true : false
                            }}
                            className="reportsTable"
                            onChange={handleTableChange}
                        />
                    </div>

                    <div style={{ textAlign: "right" }}>
                        <CSVLink
                            data={tableData}
                            headers={CSVHeaders}
                            filename={"items.csv"}
                        >
                            <Tooltip title="Export to Excel">
                                <Button
                                    style={{
                                        backgroundColor: "#4CAF50",
                                        color: "#fff",
                                        border: "1px solid #4caf50"
                                    }}
                                >
                                    <Icon type="download" /> Export to CSV
                                </Button>
                            </Tooltip>
                        </CSVLink>
                    </div>
                </>
            )}
        </Card>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsReport);
