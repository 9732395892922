import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Icon,
    Checkbox,
    //Button,
    Divider,
    Tooltip,
    message,
    Tag,
    Card
} from "antd";

import Drawer from "globalComponents/Drawer";
import PatientCardItemsForm from "./components/Forms/PatientCardItemsForm";
import ColorSelectForm from "./components/Forms/ColorForm";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientCard = ({
    PForm,
    breakpoint,
    PCItems,
    userID,
    updateSettings,
    setRequestError
}) => {
    const [patientCardItems, setPatientCardItems] = useState(PCItems);
    const [selectedPatientCardItem, setSelectedPatientCardItem] =
        useState(null);

    const [inputCheckboxes, setInputCheckboxes] = useState({});

    const [isPatientCardItemsFormVisible, setIsPatientCardItemsFormVisible] =
        useState(false);
    const [isColorFormVisible, setIsColorFormVisible] = useState(false);

    const [isPCItemsEdited, setIsPCItemsEdited] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isPCItemsEdited]);

    function beforeunload(e) {
        if (isPCItemsEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleCheckboxChange = e => {
        const { name, checked } = e.target;
        const newInputCheckboxes = {};
        Object.keys(inputCheckboxes).forEach(checkboxName => {
            newInputCheckboxes[checkboxName] = false;
        });
        setInputCheckboxes({ ...newInputCheckboxes, [name]: checked });
        setSelectedPatientCardItem(
            patientCardItems.find(item => item.name === name)
        );
    };

    const togglePatientCardItemsFormVisible = () => {
        setIsPatientCardItemsFormVisible(!isPatientCardItemsFormVisible);
    };

    const toggleColorFormVisible = () => {
        setIsColorFormVisible(!isColorFormVisible);
    };

    const showColorForm = () => {
        if (selectedPatientCardItem) {
            toggleColorFormVisible();
        } else {
            message.error("Please select patient card item!");
        }
    };

    const handlePatientCardItemsFormSubmit = patientCardItem => {
        const isItemAdded = patientCardItems.find(
            item => item.name === patientCardItem.name
        );
        if (isItemAdded) {
            message.error("Item was already added!");
        } else {
            setPatientCardItems([...patientCardItems, patientCardItem]);
            togglePatientCardItemsFormVisible();
            setIsPCItemsEdited(true);
        }
    };

    const handlePatientCardItemDelete = () => {
        if (selectedPatientCardItem) {
            setPatientCardItems(
                patientCardItems.filter(
                    item => item.name !== selectedPatientCardItem.name
                )
            );
            setSelectedPatientCardItem(null);
            setIsPCItemsEdited(true);
        } else {
            message.error("Please select patient card item!");
        }
    };

    const movePatientCardItem = direction => {
        if (selectedPatientCardItem) {
            const newPatientCardItems = [...patientCardItems];

            let formIndex = 0;
            newPatientCardItems.forEach((item, index) => {
                if (item.name === selectedPatientCardItem.name) {
                    formIndex = index;
                }
            });

            if (direction === "up" && formIndex !== 0) {
                const removedItem = newPatientCardItems.splice(formIndex, 1)[0];
                newPatientCardItems.splice(formIndex - 1, 0, removedItem);

                //update
                setPatientCardItems(newPatientCardItems);
                setIsPCItemsEdited(true);
            } else if (
                direction === "down" &&
                formIndex !== newPatientCardItems.length - 1
            ) {
                const removedItem = newPatientCardItems.splice(formIndex, 1)[0];
                newPatientCardItems.splice(formIndex + 1, 0, removedItem);

                //update
                setPatientCardItems(newPatientCardItems);
                setIsPCItemsEdited(true);
            }
        } else {
            message.error("Please select patient card item!");
        }
    };

    const handleColorSelectFormSubmit = color => {
        setPatientCardItems(
            patientCardItems.map(item => {
                if (item.name === selectedPatientCardItem.name) {
                    return {
                        ...item,
                        highlightColor: color
                    };
                }
                return item;
            })
        );

        toggleColorFormVisible();
        setIsPCItemsEdited(true);
    };

    const handlePatientCardItemsSave = async () => {
        try {
            setInputCheckboxes({});
            updateSettings({ patientCardItems });
            setIsPCItemsEdited(false);
            message.success("Patient card settings successfully saved!");
            const PCItems = JSON.stringify(patientCardItems);
            await http.put(`${apiUrl}/userSettings/${userID}`, {
                patient_card_items: PCItems
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <div style={{ width: breakpoint.isNormalMobile ? "100%" : 600 }}>
                <Card
                    actions={[
                        <Tooltip title="Add Patient Card Items">
                            <Icon
                                type="plus"
                                onClick={togglePatientCardItemsFormVisible}
                            />
                        </Tooltip>,
                        <Tooltip title="Set Highlight Color">
                            <Icon type="highlight" onClick={showColorForm} />
                        </Tooltip>,
                        <Tooltip title="Delete">
                            <Icon
                                type="delete"
                                onClick={handlePatientCardItemDelete}
                            />
                        </Tooltip>,
                        <Tooltip title="Move Up">
                            <Icon
                                type="up"
                                onClick={() => movePatientCardItem("up")}
                            />
                        </Tooltip>,
                        <Tooltip title="Move Down">
                            <Icon
                                type="down"
                                onClick={() => movePatientCardItem("down")}
                            />
                        </Tooltip>,
                        <Tooltip title="Save Vital Signs">
                            <Icon
                                type="save"
                                onClick={handlePatientCardItemsSave}
                            />
                        </Tooltip>
                    ]}
                    bodyStyle={{
                        maxHeight: 422,
                        overflow: "auto"
                    }}
                >
                    {patientCardItems.length !== 0 ? (
                        patientCardItems.map(item => (
                            <Row key={item.name} style={{ marginBottom: 3 }}>
                                <Col span={2}>
                                    <Checkbox
                                        name={item.name}
                                        checked={inputCheckboxes[item.name]}
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>
                                <Col span={22}>
                                    {item.highlightColor ? (
                                        <span>
                                            <Tag color={item.highlightColor}>
                                                {item.label}
                                            </Tag>
                                        </span>
                                    ) : (
                                        <span>{item.label}</span>
                                    )}{" "}
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            No Patient Card Items
                        </div>
                    )}
                </Card>
            </div>

            <Drawer
                title="Patient Card Items"
                placement="right"
                visible={isPatientCardItemsFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={togglePatientCardItemsFormVisible}
            >
                <PatientCardItemsForm
                    items={PForm}
                    onSubmit={handlePatientCardItemsFormSubmit}
                />
            </Drawer>

            <Drawer
                title="Select Color"
                placement="right"
                visible={isColorFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={toggleColorFormVisible}
            >
                <ColorSelectForm onSubmit={handleColorSelectFormSubmit} />
            </Drawer>

            {/* {patientCardItems.length !== 0 ? (
                patientCardItems.map(item => (
                    <Row key={item.name} style={{ marginBottom: 3 }}>
                        <Col span={2}>
                            <Checkbox
                                name={item.name}
                                checked={inputCheckboxes[item.name]}
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        <Col span={22}>
                            {item.highlightColor ? (
                                <span>
                                    <Tag color={item.highlightColor}>
                                        {item.label}
                                    </Tag>
                                </span>
                            ) : (
                                <span>{item.label}</span>
                            )}{" "}
                        </Col>
                    </Row>
                ))
            ) : (
                <div style={{ textAlign: "center" }}>No Patient Card Items</div>
            )}

            <Divider />

            <div>
                <div style={{ marginBottom: 10 }}>
                    Patient Card Controls{" "}
                    <Popover
                        placement="right"
                        content="Use below controls to modify the patient card structure above."
                    >
                        <Icon type="info-circle" theme="twoTone" />
                    </Popover>
                </div>
                <ButtonGroup>
                    <Tooltip title="Add Patient Card Items" placement="bottom">
                        <Button
                            icon="plus"
                            onClick={togglePatientCardItemsFormVisible}
                        />
                    </Tooltip>

                    <Tooltip title="Set Highlight Color" placement="bottom">
                        <Button icon="highlight" onClick={showColorForm} />
                    </Tooltip>

                    <Tooltip title="Delete" placement="bottom">
                        <Button
                            icon="delete"
                            onClick={handlePatientCardItemDelete}
                        />
                    </Tooltip>

                    <Tooltip title="Move Up" placement="bottom">
                        <Button
                            icon="up"
                            onClick={() => movePatientCardItem("up")}
                        />
                    </Tooltip>
                    <Tooltip title="Move Down" placement="bottom">
                        <Button
                            icon="down"
                            onClick={() => movePatientCardItem("down")}
                        />
                    </Tooltip>
                </ButtonGroup>
            </div>

            <Divider />

            <Button
                type="primary"
                size="large"
                onClick={handlePatientCardItemsSave}
            >
                Save Patient Card
            </Button>

            <Modal
                title="Patient Card Items"
                style={{ top: 20 }}
                visible={isPatientCardItemsFormVisible}
                destroyOnClose={true}
                width={450}
                onCancel={togglePatientCardItemsFormVisible}
                footer={
                    <Button onClick={togglePatientCardItemsFormVisible}>
                        Cancel
                    </Button>
                }
            >
                <PatientCardItemsForm
                    items={PForm}
                    onSubmit={handlePatientCardItemsFormSubmit}
                />
            </Modal>

            <Modal
                title="Select Color"
                style={{ top: 20 }}
                visible={isColorFormVisible}
                destroyOnClose={true}
                width={450}
                onCancel={toggleColorFormVisible}
                footer={
                    <Button onClick={toggleColorFormVisible}>Cancel</Button>
                }
            >
                <ColorSelectForm onSubmit={handleColorSelectFormSubmit} />
            </Modal> */}

            <Prompt
                when={isPCItemsEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    PForm: state.Settings.patientForm,
    PCItems: state.Settings.patientCardItems,
    userID: state.UserDetails.user_id
});

const mapDispatchToProps = dispatch => ({
    updateSettings: updatedSettings =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);
