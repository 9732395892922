import React, { useState, useEffect } from "react";
import { Form, DatePicker } from "antd";
import moment from "moment";

const FormItemDatePicker = ({ data, error, onInputChange }) => {
    const { name, label, value, required } = data;

    const [formData, setFormData] = useState({
        [name]: value
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error
    });

    useEffect(() => {
        if (value) {
            setFormData({ [name]: value });
        } else {
            setFormData({ [name]: "" });
        }
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleInputChange = (name, value) => {
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
            setFormErrors(errors);
        }

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleInputBlur = e => {
        onInputChange(name, formData[name]);
    };

    return (
        <Form.Item
            label={label}
            required={required}
            hasFeedback
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <DatePicker
                defaultValue={value ? moment(value) : null}
                value={formData[name] ? moment(formData[name]) : null}
                onChange={(date, dateString) =>
                    handleInputChange(name, dateString)
                }
                onBlur={handleInputBlur}
            />
        </Form.Item>
    );
};

export default FormItemDatePicker;
