import React, { useState, useEffect } from "react";
import { Form, Select, Button, Input } from "antd";

const PrescriptionWritingForm = ({ mode, data, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: "name",
        label: "Item Name",
        value: "",
        prefix: "",
        suffix: "",
        style: "normal",
        target: "item",
    });

    useEffect(() => {
        if (mode === "edit") {
            setFormData(data);
        }
    }, []);

    const { Option, OptGroup } = Select;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (name, value, option) => {
        if (value.indexOf("newline") !== -1) {
            setFormData({
                ...formData,
                [name]: value,
                label: option.props.children,
                target: "newline",
                style: "",
            });
        } else if (
            value === "item_name" ||
            value === "generic_name" ||
            value === "description" ||
            value === "formulation"
        ) {
            setFormData({
                ...formData,
                [name]: value,
                label: option.props.children,
                target: "item",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
                label: option.props.children,
                target: "pnote",
            });
        }
    };

    const handleStyleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        onSubmit(formData);
        //console.log(formData);
    };

    return (
        <Form
            layout="vertical"
            labelCol={{
                xl: { span: 6 },
                lg: { span: 8 },
            }}
            wrapperCol={{
                xl: { span: 18 },
                lg: { span: 16 },
            }}
        >
            <Form.Item label="Part">
                <Select
                    onChange={(value, option) =>
                        handleSelectChange("name", value, option)
                    }
                    value={formData.name}
                    disabled={mode === "edit" ? true : false}
                >
                    <OptGroup label="Item">
                        <Option value="name">Item Name</Option>
                        <Option value="generic_name">Generic Name</Option>
                        <Option value="description">Description</Option>
                        <Option value="formulation">Formulation</Option>
                    </OptGroup>
                    <OptGroup label="Prescription Note">
                        <Option value="preparation">Preparation</Option>
                        <Option value="dose">Dose</Option>
                        <Option value="frequency">Frequency</Option>
                        <Option value="duration">Duration</Option>
                        <Option value="indication">Indication</Option>
                        <Option value="number">Number</Option>
                        <Option value="number_unit">Number Unit</Option>
                        <Option value="unit">Unit</Option>
                        <Option value="others">Others</Option>
                    </OptGroup>
                    <OptGroup label="Others">
                        <Option value="newline1">Newline 1</Option>
                        <Option value="newline2">Newline 2</Option>
                        <Option value="newline3">Newline 3</Option>
                        <Option value="newline4">Newline 4</Option>
                    </OptGroup>
                </Select>
            </Form.Item>
            <Form.Item label="Prefix">
                <Input
                    name="prefix"
                    value={formData.prefix}
                    autoComplete="off"
                    onChange={handleInputChange}
                />
            </Form.Item>
            <Form.Item label="Suffix">
                <Input
                    name="suffix"
                    value={formData.suffix}
                    autoComplete="off"
                    onChange={handleInputChange}
                />
            </Form.Item>

            <Form.Item label="Style">
                <Select
                    value={formData.style}
                    onChange={(value) =>
                        handleStyleSelectChange("style", value)
                    }
                >
                    <Option value="normal">No Style</Option>
                    <Option value="bold">Bold Text</Option>
                    <Option value="italic">Italic Text</Option>
                    <Option value="marginLeft20">Margin Left 20</Option>
                    <Option value="marginLeft50">Margin Left 50</Option>
                    <Option value="marginLeft100">Margin Left 100</Option>
                    <Option value="marginLeft200">Margin Left 200</Option>
                </Select>
            </Form.Item>

            {formData.name.indexOf("newline") === -1 && (
                <Form.Item label="Sample Value">
                    <Input
                        name="value"
                        value={formData.value}
                        autoComplete="off"
                        onChange={handleInputChange}
                    />
                </Form.Item>
            )}
            <Form.Item label=" ">
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PrescriptionWritingForm;
