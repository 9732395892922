import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Tag,
    Skeleton,
    Card,
    Icon,
    Divider,
    Button,
    //Menu,
    //Dropdown,
    Tooltip,
    message,
} from "antd";
import moment from "moment";
import PatientCard from "globalComponents/PatientCard";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const CaseDetails = ({
    selectedCase,
    userID,
    breakpoint,
    onPatientCall,
    onCaseStatusChange,
    caseListSettings,
}) => {
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const [appointmentDetails, setAppointmentDetails] = useState({
        details: "",
        appointment_datetime: "",
        user_id: "",
        complaint_datetime: "",
    });

    const [patientID] = useState(selectedCase.patient_id);

    const [
        isAppointmentDetailsLoading,
        setIsAppointmentDetailsLoading,
    ] = useState(false);
    const [
        isAppointmentDetailsVisible,
        setIsAppointmentDetailsVisible,
    ] = useState(false);

    const [
        isAppointmentDetailsLoaded,
        setIsAppointmentDetailsLoaded,
    ] = useState(false);

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const toggleAppointmentDetailsVisible = async (appointmentID) => {
        try {
            setIsAppointmentDetailsVisible(!isAppointmentDetailsVisible);
            if (!isAppointmentDetailsLoaded) {
                setIsAppointmentDetailsLoading(true);
                const response = await http.get(
                    `${apiUrl}/appointment/${appointmentID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                setAppointmentDetails(response.data.appointment);
                setIsAppointmentDetailsLoading(false);
                setIsAppointmentDetailsLoaded(true);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                message.error(error.message);
            }
        }
    };

    const handleRequestDone = () => {
        setIsDetailsVisible(true);
    };

    // const menu = (
    //     <Menu>
    //         <Menu.Item
    //             key="1"
    //             disabled={
    //                 selectedCase.doctor_id !== userID ||
    //                 selectedCase.status === "done"
    //                     ? true
    //                     : false
    //             }
    //             onClick={() => onCaseStatusChange("hold", selectedCase.case_id)}
    //         >
    //             <Icon type="history" /> Hold Case
    //         </Menu.Item>
    //         <Menu.Item
    //             key="2"
    //             disabled={
    //                 selectedCase.doctor_id !== userID ||
    //                 selectedCase.status === "done"
    //                     ? true
    //                     : false
    //             }
    //             onClick={() =>
    //                 onCaseStatusChange("noshow", selectedCase.case_id)
    //             }
    //         >
    //             <Icon type="user-delete" /> Patient No Show
    //         </Menu.Item>
    //     </Menu>
    // );

    const Details = () => {
        const {
            chief_complaint,
            complaint_datetime,
            case_name,
            doctor_id,
            user_id,
            status,
            case_id,
            appointment_id,
            is_from_cwapp,
            created_at,
            updated_at,
        } = selectedCase;

        const {
            details,
            appointment_datetime,
            doctor_id: appointmentDoctorID,
            user_id: appointmentCreatedBy,
            complaint_datetime: appointmentDateCreated,
        } = appointmentDetails;

        let statusColor = "";
        switch (status) {
            case "pending":
                statusColor = caseListSettings.pendingStatusColor;
                break;
            case "hold":
                statusColor = caseListSettings.holdStatusColor;
                break;
            case "no-show":
                statusColor = caseListSettings.noShowStatusColor;
                break;
            case "done":
                statusColor = caseListSettings.doneStatusColor;
                break;
            default:
                statusColor = "#ccc";
        }

        return (
            <div style={{ marginTop: 20 }}>
                <h3>Case Details:</h3>
                <p>
                    Chief Complaint:{" "}
                    <span style={{ fontStyle: "italic" }}>
                        &ldquo;
                        {chief_complaint}
                        &rdquo;
                    </span>
                </p>
                {is_from_cwapp ? (
                    <p>
                        <Tag color="purple">
                            <Icon type="pushpin" /> From Patient Portal
                        </Tag>
                    </p>
                ) : null}

                <Divider />
                <table cellPadding={3}>
                    <tbody>
                        <tr>
                            <td style={{ width: 100 }}>Case Name:</td>
                            <td>{case_name || <span>&mdash;</span>}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td>
                                {moment(complaint_datetime).format(
                                    "MMMM DD, YYYY h:mm: A"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Doctor:</td>
                            <td>{findUserName(doctor_id)}</td>
                        </tr>
                        <tr>
                            <td>Created By:</td>
                            <td>{findUserName(user_id)}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>
                                <Tag color={statusColor}>{status}</Tag>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* APPOINTMENT DETAILS */}
                {appointment_id && (
                    <div>
                        <Icon type="calendar" theme="twoTone" /> Appointed{" "}
                        <Button
                            type="link"
                            onClick={() =>
                                toggleAppointmentDetailsVisible(appointment_id)
                            }
                        >
                            {isAppointmentDetailsVisible ? "Hide" : "Show"}{" "}
                            Details
                        </Button>
                    </div>
                )}

                {isAppointmentDetailsVisible && (
                    <Card title="Appointment Details">
                        {isAppointmentDetailsLoading ? (
                            <Skeleton active />
                        ) : (
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: 100 }}>
                                            <strong>Details:</strong>
                                        </td>
                                        <td>{details}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Date:</strong>
                                        </td>
                                        <td>
                                            {moment(
                                                appointment_datetime
                                            ).format("MMMM DD, YYYY")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Time:</strong>
                                        </td>
                                        <td>
                                            {moment(
                                                appointment_datetime
                                            ).format("h:mm A")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Doctor:</strong>
                                        </td>
                                        <td>
                                            {findUserName(appointmentDoctorID)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Created By:</strong>
                                        </td>
                                        <td>
                                            {findUserName(appointmentCreatedBy)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Date Created:</strong>
                                        </td>
                                        <td>
                                            {moment(
                                                appointmentDateCreated
                                            ).format("MMMM DD, YYYY")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </Card>
                )}

                <Divider />

                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: 100 }}>Date Created:</td>
                            <td>
                                {moment(created_at).format("MMMM DD, YYYY")}
                            </td>
                        </tr>
                        <tr>
                            <td>Last Update:</td>
                            <td>
                                {moment(updated_at).format("MMMM DD, YYYY")}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Divider />

                {/* <Dropdown.Button
                    overlay={menu}
                    onClick={() => onPatientCall(case_id)}
                >
                    <Icon type="notification" /> Call Patient
                </Dropdown.Button> */}
                <div style={{ marginBottom: 16 }}>
                    <Button.Group>
                        <Tooltip title="Hold Case">
                            <Button
                                disabled={
                                    selectedCase.doctor_id !== userID ||
                                    selectedCase.status === "done"
                                        ? true
                                        : false
                                }
                                onClick={() =>
                                    onCaseStatusChange(
                                        "hold",
                                        selectedCase.case_id
                                    )
                                }
                            >
                                <Icon type="history" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Patient No Show">
                            <Button
                                disabled={
                                    selectedCase.doctor_id !== userID ||
                                    selectedCase.status === "done"
                                        ? true
                                        : false
                                }
                                onClick={() =>
                                    onCaseStatusChange(
                                        "noshow",
                                        selectedCase.case_id
                                    )
                                }
                            >
                                <Icon type="user-delete" />
                            </Button>
                        </Tooltip>
                    </Button.Group>

                    <Link to={`/casenote/${case_id}`}>
                        <Button
                            type="primary"
                            icon="folder-open"
                            style={{
                                marginLeft: 5,
                            }}
                        >
                            Open Case
                        </Button>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <>
            {!isDetailsVisible && <Skeleton active />}
            <div style={{ display: isDetailsVisible ? "block" : "none" }}>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <PatientCard
                            patientID={patientID}
                            moreDetailsVisible={true}
                            bordered={false}
                            breakpoint={breakpoint}
                            showEditPatientInfoBtn={true}
                            onRequestDone={handleRequestDone}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: breakpoint.isNormalMobile ? 0 : 24,
                            }}
                        >
                            <Details />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    caseListSettings: state.Settings.caseList,
});

export default connect(mapStateToProps)(CaseDetails);
