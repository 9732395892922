import React, { useState, useEffect } from "react";
import { Tooltip, Icon } from "antd";
import moment from "moment";
import he from "he";
import ReactHtmlParser from "react-html-parser";

const ChatConversations = props => {
    const {
        currentUserID,
        messages,
        moveScrollToBottomWatcher,
        moveScrollWatcher,
        onScrolledToTop,
        height
    } = props;

    let divRef = React.createRef();

    let lastMessageSenderID = "";

    const [lastScrollPos, setLastScrollPos] = useState(0);
    const [isLoadingNewMessages, setIsLoadingNewMessages] = useState(false);

    useEffect(() => {
        scrollToBottom();
        setLastScrollPos(divRef.scrollHeight);
    }, [moveScrollToBottomWatcher]);

    useEffect(() => {
        if (moveScrollWatcher !== 0) {
            const scrollHeight = divRef.scrollHeight;

            divRef.scrollTop = scrollHeight - lastScrollPos;
            setLastScrollPos(divRef.scrollHeight);
            setIsLoadingNewMessages(false);
        }
    }, [moveScrollWatcher]);

    function scrollToBottom() {
        const scrollHeight = divRef.scrollHeight;
        const height = divRef.clientHeight;
        const maxScrollTop = scrollHeight - height;
        divRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    const handleScroll = e => {
        let element = e.target;
        if (element.scrollTop < 1) {
            if (!isLoadingNewMessages) {
                setIsLoadingNewMessages(true);
                onScrolledToTop();
            }
        }
    };

    const replaceString = message => {
        message = he.encode(message, {
            useNamedReferences: true
        });
        message = message.replace(/\n/g, "<br/>");
        return message;
    };

    return (
        <>
            <div
                style={{
                    //height: `calc(100vh - ${height}px)`,
                    height: height,
                    backgroundColor: "#f9f9f9",
                    padding: 10,
                    overflowY: "auto",
                    width: "100%"
                }}
                onScroll={handleScroll}
                ref={div => {
                    divRef = div;
                }}
            >
                {isLoadingNewMessages && (
                    <div style={{ textAlign: "center" }}>
                        <Icon type="loading" />
                    </div>
                )}
                {messages.length !== 0 &&
                    messages.map((messageData, index) => {
                        const {
                            sender_id,
                            message,
                            datetime_sent
                        } = messageData;

                        const className =
                            sender_id === currentUserID ? "rightM" : "leftM";

                        let marginTop = 0;
                        if (index === 0) {
                            lastMessageSenderID = sender_id;
                        } else {
                            if (lastMessageSenderID === sender_id) {
                                marginTop = 4;
                            } else {
                                marginTop = 12;
                            }
                            lastMessageSenderID = sender_id;
                        }

                        return (
                            <Tooltip
                                title={moment(datetime_sent).format("LLLL")}
                                placement="right"
                                key={index}
                            >
                                <div
                                    className={className}
                                    style={{ marginTop }}
                                >
                                    <span>
                                        {ReactHtmlParser(
                                            replaceString(message)
                                        )}
                                    </span>
                                </div>
                            </Tooltip>
                        );
                    })}
            </div>
        </>
    );
};

export default ChatConversations;
