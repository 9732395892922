import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Anchor,
    Row,
    Col,
    PageHeader,
    Popover,
    Icon,
    Button,
    Card,
    message,
    Divider
} from "antd";
import moment from "moment";

import Drawer from "globalComponents/Drawer";

import PersonalInfoForm from "./components/PersonalInfoForm/Index";
import PatientHistoryForm from "./components/PatientHistoryForm";
import GroupForm from "./components/GroupForm";
import HmoForm from "./components/HmoForm";
import AppointmentForm from "views/Appointments/components/Forms/AppointmentForm";

import { b64ImgToFileHelper } from "helpers/image";
import { generateString } from "helpers/randomStringGenerator";
import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

let patientFormDivRef = React.createRef();

const PatientForm = ({
    patientData,
    dataEditing,
    breakpoint,
    inline,
    onAddSuccess, // use if inline prop is true
    onEditSuccess, // use if inline prop is true
    PForm,
    PHForm,
    groups,
    hmo,
    doctors,
    userID,
    ownerID,
    clinicID,
    uploadServer,
    uploadEndpoint,
    setRequestError,
    ...props
}) => {
    const [formErrors, setFormErrors] = useState({});

    const [patientImg, setPatientImg] = useState({
        origSizeImg: "",
        cardSizeImg: "",
        thumbnailSizeImg: ""
    });
    const [isPatientImgChanged, setIsPatientImgChanged] = useState(false);

    const [patientForm, setPatientForm] = useState(PForm);
    const [patientHistoryForm, setPatientHistoryForm] = useState(PHForm);

    const [groupIDs, setGroupIDs] = useState([]);
    const [hmoIDs, setHmoIDs] = useState([]);

    const [isPatientSaving, setIsPatientSaving] = useState(false);
    const [isPatientSavingAndCreateCase, setIsPatientSavingAndCreateCase] =
        useState(false);

    const [appointmentDetails, setAppointmentDetails] = useState(null);
    const [isAppointmentFormVisible, setIsAppointmentFormVisible] =
        useState(false);

    const [isFromLegacy, setIsFromLegacy] = useState(0);

    const toggleAppointmentFormVisible = () => {
        setIsAppointmentFormVisible(!isAppointmentFormVisible);
    };

    const { Link } = Anchor;

    useEffect(() => {
        if (dataEditing) {
            const { PIFormValues, PHFormValues, groups, hmo } = patientData;

            //set image
            let patientOrigSizeImg = PIFormValues.img_path;
            if (patientOrigSizeImg) {
                let patientCardSizeImg = patientOrigSizeImg.replace(
                    ".jpeg",
                    "-card.jpeg"
                );
                let patientThumbnailSizeImg = patientOrigSizeImg.replace(
                    ".jpeg",
                    "-thumb.jpeg"
                );

                setPatientImg({
                    origSizeImg: patientOrigSizeImg,
                    cardSizeImg: patientCardSizeImg,
                    thumbnailSizeImg: patientThumbnailSizeImg
                });
            }

            //supply values to patient form
            setPatientForm(
                patientForm.map(formInput => ({
                    ...formInput,
                    value: PIFormValues[formInput.name]
                        ? PIFormValues[formInput.name]
                        : ""
                }))
            );

            //supply values to patient history form
            setPatientHistoryForm(
                patientHistoryForm.map(formInput => ({
                    ...formInput,
                    value: PHFormValues[formInput.name]
                        ? PHFormValues[formInput.name]
                        : ""
                }))
            );

            setGroupIDs(groups.map(group => group.group_id));
            setHmoIDs(hmo.map(h => h.hmo_id));

            setIsFromLegacy(PHFormValues.is_from_legacy);
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handlePersonalInfoFormImageChange = patientImg => {
        setPatientImg(patientImg);
        setIsPatientImgChanged(true);
        setIsFromLegacy(0);
    };

    const handlePersonalInfoFormInputChange = (name, value) => {
        //update the value of the patient form

        if (name === "town_city") {
            const [town_city, province, zip] = value.split("@");
            const newPatientForm = patientForm.map(formInput => {
                if (formInput.name === "town_city") {
                    return { ...formInput, value: town_city };
                }
                if (formInput.name === "province") {
                    return { ...formInput, value: province };
                }
                if (formInput.name === "zip") {
                    return { ...formInput, value: zip };
                }
                return formInput;
            });
            setPatientForm(newPatientForm);
        } else {
            const newPatientForm = patientForm.map(formInput => {
                if (formInput.name === name) {
                    return { ...formInput, value };
                }
                return formInput;
            });
            setPatientForm(newPatientForm);
        }
    };

    const handlePatientHistoryFormInputChange = (name, value) => {
        const newPatientHistoryForm = patientHistoryForm.map(formInput => {
            if (formInput.name === name) {
                return { ...formInput, value };
            }
            return formInput;
        });
        setPatientHistoryForm(newPatientHistoryForm);
    };

    const handleGroupChange = e => {
        const value = e.target.name;
        let newGroupIDs = [...groupIDs];
        if (newGroupIDs.indexOf(value) !== -1) {
            newGroupIDs.splice(newGroupIDs.indexOf(value), 1);
        } else {
            newGroupIDs.push(value);
        }
        setGroupIDs(newGroupIDs);
    };

    const handleHmoChange = selectedHMOs => {
        setHmoIDs(selectedHMOs);
    };

    const validateInput = () => {
        let errors = {};

        patientForm.forEach(formInput => {
            const { value, name, label } = formInput;
            if (
                name === "birthday" &&
                !moment(value, "YYYY-MM-DD", true).isValid()
            ) {
                errors[name] = `${label} is invalid`;
            }
        });

        patientForm.forEach(formInput => {
            const { required, value, name, label } = formInput;
            if (required && value === "") {
                errors[name] = `${label} is required`;
            }
        });

        patientHistoryForm.forEach(formInput => {
            const { required, value, name, label } = formInput;
            if (required && value === "") {
                errors[name] = `${label} is required`;
            }
        });

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                `Failed to submit! There are ${errorLength} error(s) found in the form. Please correct and submit again`
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const resetFormData = () => {
        setPatientForm(
            patientForm.map(formInput => ({ ...formInput, value: "" }))
        );
        setPatientHistoryForm(
            patientHistoryForm.map(formInput => ({ ...formInput, value: "" }))
        );

        setPatientImg("");
        setFormErrors({});
        setGroupIDs([]);
        setHmoIDs([]);
    };

    const handleSubmit = async action => {
        setFormErrors({});
        const errors = validateInput();
        if (errors) {
            patientFormDivRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
            setTimeout(() => setFormErrors(errors), 100);
        } else {
            try {
                if (action === "save") {
                    setIsPatientSaving(true);
                } else if (action === "saveAndCreate") {
                    setIsPatientSavingAndCreateCase(true);
                }

                let patientImgPath = dataEditing
                    ? patientData.PIFormValues.img_path
                    : "";
                let patientImgSize = dataEditing
                    ? patientData.PIFormValues.img_size
                    : "";

                //upload only if there's a new selected image
                if (isPatientImgChanged) {
                    //create filename
                    const patientOrigSizeImgFilename = Date.now() + ".jpeg";
                    const patientCardSizeImgFilename =
                        patientOrigSizeImgFilename.replace(
                            ".jpeg",
                            "-card.jpeg"
                        );
                    const patientThumbnailImgFilename =
                        patientOrigSizeImgFilename.replace(
                            ".jpeg",
                            "-thumb.jpeg"
                        );

                    //convert b64 img to file
                    const patientOrigSizeImgFile = b64ImgToFileHelper(
                        patientImg.origSizeImg,
                        patientOrigSizeImgFilename
                    );
                    const patientCardSizeImgFile = b64ImgToFileHelper(
                        patientImg.cardSizeImg,
                        patientCardSizeImgFilename
                    );
                    const patientThumbnailSizeImgFile = b64ImgToFileHelper(
                        patientImg.thumbnailSizeImg,
                        patientThumbnailImgFilename
                    );

                    //get file size
                    const patientOrigSizeImgFileSize =
                        patientOrigSizeImgFile.size;
                    const patientCardSizeImgFileSize =
                        patientCardSizeImgFile.size;
                    const patientThumbnailSizeImgFileSize =
                        patientThumbnailSizeImgFile.size;

                    patientImgSize =
                        patientOrigSizeImgFileSize +
                        patientCardSizeImgFileSize +
                        patientThumbnailSizeImgFileSize;

                    //prepare to upload
                    let fd = new FormData();
                    fd.append("uniFile", patientOrigSizeImgFile);
                    fd.append("uniFile", patientCardSizeImgFile);
                    fd.append("uniFile", patientThumbnailSizeImgFile);

                    const uploadResponse = await http.post(
                        `${apiUrl}/patientImage`,
                        fd,
                        {
                            params: {
                                space_server: uploadServer,
                                space_bucket_name: uploadEndpoint,
                                root_dir: cwaUploadRootDir,
                                owner_id: ownerID,
                                clinic_id: clinicID
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    if (uploadResponse.data.error) {
                        message.error(
                            "Failed to save patient data. Upload image error"
                        );
                        if (action === "save") {
                            setIsPatientSaving(false);
                        } else if (action === "saveAndCreate") {
                            setIsPatientSavingAndCreateCase(false);
                        }
                        return false;
                    } else {
                        patientImgPath = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${uploadResponse.data.datePath}/${patientOrigSizeImgFilename}`;
                    }
                }

                const formData = {};
                const patientInfo = {};
                const patientOtherInfo = {};
                const patientHistory = {};

                patientForm.forEach(formInput => {
                    const { name, value, fixed } = formInput;
                    if (fixed) {
                        patientInfo[name] = value;
                    } else {
                        if (value !== "") {
                            patientOtherInfo[name] = value;
                        }
                    }
                });

                patientHistoryForm.forEach(formInput => {
                    const { name, value } = formInput;
                    if (value !== "") {
                        patientHistory[name] = value;
                    }
                });

                patientInfo.img_path = patientImgPath;
                patientInfo.img_size = patientImgSize;
                patientInfo.is_from_legacy = isFromLegacy;
                patientInfo.hmo_ids = hmoIDs;
                patientInfo.group_ids = groupIDs;

                if (dataEditing) {
                    formData.patientInfo = {
                        patient_id: patientData.PIFormValues.patient_id,
                        ...patientInfo
                    };
                } else {
                    patientInfo.is_from_cwapp = 0;
                    formData.patientInfo = patientInfo;
                }

                formData.patientOtherInfo = JSON.stringify(patientOtherInfo);
                formData.patientHistory = JSON.stringify(patientHistory);

                if (dataEditing) {
                    const patientID = patientData.PIFormValues.patient_id;

                    await http.put(`${apiUrl}/patient/${patientID}`, formData, {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    });

                    await http.post(`${apiUrl}/activityLog`, {
                        system_activity_id: 6,
                        data: JSON.stringify({ id: patientID }),
                        user_id: userID
                    });

                    if (action === "save") {
                        if (inline) {
                            message.success("Patient successfully updated!");
                            onEditSuccess();
                        } else {
                            patientFormDivRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start"
                            });
                            message.success("Patient successfully updated!");
                            setIsPatientSaving(false);
                        }
                    } else if (action === "saveAndCreate") {
                        //setIsPatientSavingAndCreateCase(false);
                        props.history.push(`/case/create/${patientID}`);
                    } else {
                        const {
                            patient_id,
                            firstname,
                            middlename,
                            lastname,
                            img_path
                        } = formData.patientInfo;

                        setAppointmentDetails({
                            patient_id,
                            patient_fullname: `${lastname} ${firstname} ${middlename}`,
                            patient_img_path: img_path,
                            appointment_datetime: null,
                            details: "Consultation - Checkup",
                            doctor_id:
                                doctors.length === 1
                                    ? doctors[0].doctor_id
                                    : "",
                            case_id: "",
                            status: "pending",
                            is_from_cwapp: 0
                        });

                        toggleAppointmentFormVisible();
                    }
                } else {
                    const response = await http.post(
                        `${apiUrl}/patient`,
                        formData,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    await http.post(`${apiUrl}/activityLog`, {
                        system_activity_id: 5,
                        data: JSON.stringify({ id: response.data.patientID }),
                        user_id: userID
                    });

                    if (action === "save") {
                        if (inline) {
                            message.success("Patient successfully added!");
                            onAddSuccess();
                        } else {
                            patientFormDivRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start"
                            });
                            message.success("Patient successfully added!");
                            resetFormData();
                            setIsPatientSaving(false);
                        }
                    } else if (action === "saveAndCreate") {
                        props.history.push(
                            `/case/create/${response.data.patientID}`
                        );
                    } else {
                        const { firstname, middlename, lastname, img_path } =
                            formData.patientInfo;

                        setAppointmentDetails({
                            patient_id: response.data.patientID,
                            patient_fullname: `${lastname} ${firstname} ${middlename}`,
                            patient_img_path: img_path,
                            appointment_datetime: null,
                            details: "Consultation - Checkup",
                            doctor_id:
                                doctors.length === 1
                                    ? doctors[0].doctor_id
                                    : "",
                            case_id: "",
                            status: "pending",
                            is_from_cwapp: 0
                        });

                        toggleAppointmentFormVisible();
                        resetFormData();
                    }
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        }
    };

    const handleAppointmentFormSubmit = async formData => {
        try {
            const appointmentID = generateString(10, "a");
            const newFormData = {
                ...formData,
                appointment_id: appointmentID,
                status: "pending",
                is_from_cwapp: 0
            };

            message.success("Patient successfully appointed");
            toggleAppointmentFormVisible();
            await http.post(`${apiUrl}/appointment/`, newFormData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const InformationalNotes = ({ message }) => {
        return (
            <Popover
                placement="right"
                content={
                    <div style={{ textAlign: "left", maxWidth: 320 }}>
                        <p>
                            <Icon type="info-circle" theme="twoTone" />{" "}
                            {message}
                        </p>
                    </div>
                }
            >
                <Icon type="info-circle" theme="twoTone" />
            </Popover>
        );
    };

    return (
        <div ref={patientFormDivRef}>
            <Row gutter={16}>
                {!breakpoint.isTabletLandscape && (
                    <Col lg={6}>
                        <Anchor>
                            <Link href="#pinfo" title="Personal Information" />
                            <Link href="#phistory" title="Patient History" />
                            <Link href="#pgroup" title="Groups" />
                            <Link href="#phmo" title="HMO" />
                        </Anchor>
                    </Col>
                )}
                <Col lg={!breakpoint.isTabletLandscape ? 18 : 24}>
                    <Card
                        style={{ marginBottom: 16 }}
                        bodyStyle={{
                            padding: breakpoint.isNormalMobile
                                ? "8px 8px 16px"
                                : 24
                        }}
                    >
                        {/* patient form */}
                        <div id="pinfo" />
                        <PageHeader
                            title="Personal Information"
                            tags={
                                <InformationalNotes message="Does not meet the form you want? You can modify the structure of personal information form below by just clicking on Settings menu and find Patient Form tab." />
                            }
                        />
                        <PersonalInfoForm
                            patientImg={patientImg.origSizeImg}
                            form={patientForm}
                            errors={formErrors}
                            breakpoint={breakpoint}
                            onInputChange={handlePersonalInfoFormInputChange}
                            onImageChange={handlePersonalInfoFormImageChange}
                        />

                        {/* patient history form */}
                        <div id="phistory" />
                        <PageHeader
                            title="Patient History"
                            tags={
                                <InformationalNotes message="Does not meet the patient history you want? You can modify the structure of patient history form below by just clicking on Settings menu and find Patient History tab." />
                            }
                        />
                        <Divider />
                        <div
                            style={{
                                width: breakpoint.isNormalMobile ? "100%" : 500
                            }}
                        >
                            <PatientHistoryForm
                                form={patientHistoryForm}
                                errors={formErrors}
                                breakpoint={breakpoint}
                                onInputChange={
                                    handlePatientHistoryFormInputChange
                                }
                            />
                        </div>
                        <Divider />
                        {/* patient groups */}
                        <div id="pgroup" />
                        <PageHeader
                            title="Groups"
                            tags={
                                <InformationalNotes message="Groupings is useful for patient filtering. You can group a patient by selecting group(s) listed below. You can manage groups under Others > Groups menu" />
                            }
                        />
                        <GroupForm
                            groups={groups}
                            groupIDs={groupIDs}
                            breakpoint={breakpoint}
                            onGroupChange={handleGroupChange}
                        />

                        {/* patient hmo */}
                        <div id="phmo" />
                        <PageHeader
                            title="HMO"
                            tags={
                                <InformationalNotes message="Below is the list of your accredited HMO. You can select more than one patient HMO. You can manage HMO under Others > HMO menu" />
                            }
                        />
                        <HmoForm
                            hmo={hmo}
                            hmoIDs={hmoIDs}
                            breakpoint={breakpoint}
                            onHmoChange={handleHmoChange}
                        />
                    </Card>
                    <div>
                        <Button
                            type="primary"
                            size="large"
                            loading={isPatientSaving}
                            disabled={isPatientSavingAndCreateCase}
                            onClick={() => handleSubmit("save")}
                        >
                            {isPatientSaving
                                ? "Saving Patient"
                                : "Save Patient"}
                        </Button>
                        <Button
                            size="large"
                            loading={isPatientSavingAndCreateCase}
                            disabled={isPatientSaving}
                            style={{ marginLeft: 5 }}
                            onClick={() => handleSubmit("saveAndCreate")}
                        >
                            {isPatientSavingAndCreateCase
                                ? "Saving Patient"
                                : "Save and Create Case"}
                        </Button>
                        {/* {!inline && (
                            <Button
                                size="large"
                                loading={isSaveAndAppointBtnLoading}
                                disabled={
                                    isPatientSaving ||
                                    isPatientSavingAndCreateCase ||
                                    isSaveAndAppointBtnLoading
                                }
                                style={{ marginLeft: 5 }}
                                onClick={() => handleSubmit("saveAndAppoint")}
                            >
                                Save and Appoint
                            </Button>
                        )} */}
                    </div>
                </Col>
            </Row>

            <Drawer
                title={"Create Appointment"}
                placement="right"
                width={breakpoint.isNormalMobile ? 320 : 400}
                destroyOnClose={true}
                onClose={toggleAppointmentFormVisible}
                visible={isAppointmentFormVisible}
            >
                <AppointmentForm
                    data={appointmentDetails}
                    caseID={null}
                    onSubmit={handleAppointmentFormSubmit}
                />
            </Drawer>
        </div>
    );
};

const mapStateToProps = state => ({
    PForm: state.Settings.patientForm,
    PHForm: state.Settings.patientHistoryForm,
    groups: state.Groups,
    hmo: state.Hmo,
    doctors: state.UserDetails.doctors,
    userID: state.UserDetails.user_id,
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PatientForm)
);
