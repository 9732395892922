import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { message } from "antd";
import ContentLoader from "globalComponents/ContentLoader";
import PatientForm from "./components/Forms/PatientForm/Index";
import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PatientEdit = ({ breakpoint, setRequestError, ...props }) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [patientData, setPatientData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const patientID = props.match.params.id;
                const response = await http.get(
                    `${apiUrl}/patient/edit/${patientID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                if (response.data.error) {
                    message.error(response.data.errorMsg);
                } else {
                    let {
                        patientInfo,
                        patientOtherInfo,
                        patientGroups,
                        patientHmo
                    } = response.data;

                    const otherInfo = JSON.parse(patientOtherInfo.other_info);

                    setPatientData({
                        PIFormValues: { ...patientInfo, ...otherInfo },
                        PHFormValues: JSON.parse(patientOtherInfo.history),
                        groups: patientGroups,
                        hmo: patientHmo
                    });

                    setIsContentLoading(false);
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };
        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    return (
        <>
            {isContentLoading ? (
                <ContentLoader />
            ) : (
                <PatientForm
                    patientData={patientData}
                    dataEditing={true}
                    breakpoint={breakpoint}
                />
            )}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(PatientEdit);
