import {
    SET_PAPER_SUCCESS,
    ADD_PAPER_SUCCESS,
    EDIT_PAPER_SUCCESS,
    DELETE_PAPER_SUCCESS
} from "./paper.types";

export const setPapers = papers => async dispatch => {
    dispatch({
        type: SET_PAPER_SUCCESS,
        payload: { papers }
    });
};

export const addPaper = newPaper => async dispatch => {
    dispatch({
        type: ADD_PAPER_SUCCESS,
        payload: { newPaper }
    });
};

export const editPaper = editedPaper => async dispatch => {
    dispatch({
        type: EDIT_PAPER_SUCCESS,
        payload: { editedPaper }
    });
};

export const deletePaper = paperID => async dispatch => {
    dispatch({
        type: DELETE_PAPER_SUCCESS,
        payload: { paperID }
    });
};
