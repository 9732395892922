import React, { useState } from "react";
import { AutoComplete, Icon, Input } from "antd";
import { useICD10 } from "react-icd10";

const ICD10SearchBar = ({ onSelect, onBlur }) => {
    const [value, setValue] = useState("");
    const [code, setCode] = useState("");

    const { onSearch, ...rest } = useICD10();

    const handleSearch = (value) => {
        onSearch(value);
        setValue(value);
    };

    const handleSelect = (selectedResult) => {
        const data = selectedResult.split(":");
        const code = data[0];
        const value = data[1].trim();
        setCode(code);
        setValue(value);
        onSelect({ target: { name: "chief_complaint", value } });
    };

    const handleBlur = (value) => {
        if (onBlur) {
            onBlur({ target: { name: "chief_complaint", value } });
        }
    };

    const results = [];
    for (const [key, value] of Object.entries(rest.data.results)) {
        results.push(`${key}: ${value}`);
    }

    return (
        <>
            <AutoComplete
                dataSource={value.trim() === "" ? [] : results}
                value={value}
                onSelect={handleSelect}
                onSearch={handleSearch}
                onBlur={handleBlur}
                placeholder="ICD 10: Search diagnosis"
            >
                <Input suffix={<Icon type={rest.fetching && "loading"} />} />
            </AutoComplete>

            {rest.fetchError ? (
                <div>error fethcing diagnosis</div>
            ) : (
                <div>
                    {code && (
                        <div style={{ textAlign: "right" }}>
                            <a
                                href={`https://www.icd10data.com/search?s=${code}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View full details about this diagnosis.
                            </a>
                        </div>
                    )}
                </div>
            )}
        </>

        // <div className="container">
        //     <div className="content">
        //         {/* <pre>
        //             <code>{JSON.stringify(rest, null, 2)}</code>
        //         </pre> */}
        //         <div>
        //             <input
        //                 onChange={onChange}
        //                 type="text"
        //                 placeholder="Search diagnosis"
        //             />
        //         </div>
        //     </div>
        // </div>
    );
};

export default ICD10SearchBar;
