import jwtDecode from "jwt-decode";

export function getSessionData() {
    try {
        const jwt = localStorage.getItem("token");
        const sessionData = jwt ? jwtDecode(jwt) : { loggedIn: false };
        return sessionData;
    } catch (error) {
        return null;
    }
}
