export const SET_USER_DETAILS_SUCCESS = "SET_USER_DETAILS_SUCCESS";
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS";
export const EDIT_DOCTOR_SUCCESS = "EDIT_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const EDIT_CLINIC_SUCCESS = "EDIT_CLINIC_SUCCESS";
export const EDIT_USER_DETAILS_SUCCESS = "EDIT_USER_DETAILS_SUCCESS";
export const EDIT_DOCTOR_PREVILEGES = "EDIT_DOCTOR_PREVILEGES";
