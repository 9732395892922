import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Icon, Select, Checkbox, message } from "antd";
import Drawer from "globalComponents/Drawer";
import { findUserName } from "helpers/userNameFinder";

const PaperDrawer = ({
    breakpoint,
    onPaperAdd,
    papers,
    doctors,
    userID,
    userType,
}) => {
    const [isPaperFormVisible, setIsPaperFormVisible] = useState(false);

    const [categories, setCategories] = useState([]);
    const [labRequests, setLabRequests] = useState([]);
    const [radiologyRequests, setRadiologyRequests] = useState([]);
    const [otherRequests, setOtherRequests] = useState([]);

    const [selectedPaper, setSelectedPaper] = useState(null);
    const [selectedLabRequests, setSelectedLabRequest] = useState([]);
    const [selectedRadiologyRequests, setSelectedRadiologyRequests] = useState(
        []
    );
    const [selectedOtherRequests, setSelectedOtherRequests] = useState([]);
    const [selectedRequestPaper, setSelectedRequestPaper] = useState(null);

    const { Option, OptGroup } = Select;

    useEffect(() => {
        let categories = papers.map((paper) => paper.category);

        //combine the 3 category of request to Requests
        categories = categories.filter(
            (category) => category.indexOf("Request") === -1
        );
        categories.splice(1, 0, "Request");

        categories = [...new Set(categories)];
        categories = categories.filter(
            (category) => category !== "Prescription" && category !== "Invoice"
        );
        setCategories(categories);
    }, []);

    const togglePaperFormVisible = () => {
        setSelectedPaper(null);
        setLabRequests([]);
        setRadiologyRequests([]);
        setOtherRequests([]);
        setSelectedRequestPaper(null);
        setIsPaperFormVisible(!isPaperFormVisible);
    };

    const handleSelectChange = (value) => {
        let selectedPaper = null;
        let labRequests = [];
        let radiologyRequests = [];
        let otherRequests = [];
        let selectedRequestPaper = null;

        papers.forEach((paper) => {
            if (paper.paper_id === value) {
                selectedPaper = paper;
                if (
                    paper.category === "Lab Request" &&
                    paper.other_info !== ""
                ) {
                    labRequests = paper.other_info.split("\n");
                } else if (
                    paper.category === "Radiology Request" &&
                    paper.other_info !== ""
                ) {
                    radiologyRequests = paper.other_info.split("\n");
                } else if (
                    paper.category === "Other Request" &&
                    paper.other_info !== ""
                ) {
                    otherRequests = paper.other_info.split("\n");
                }
                selectedRequestPaper = paper.category;
            }
        });

        setSelectedPaper(selectedPaper);
        setLabRequests(labRequests);
        setRadiologyRequests(radiologyRequests);
        setOtherRequests(otherRequests);
        setSelectedRequestPaper(selectedRequestPaper);
    };

    const handleCheckboxChange = (checkedValues, requestType) => {
        if (requestType === "Lab Request") {
            setSelectedLabRequest(checkedValues);
        } else if (requestType === "Radiology Request") {
            setSelectedRadiologyRequests(checkedValues);
        } else if (requestType === "Other Request") {
            setSelectedOtherRequests(checkedValues);
        }
    };

    const handlePaperAdd = () => {
        if (selectedPaper) {
            if (selectedPaper.category === "Lab Request") {
                if (selectedLabRequests.length !== 0) {
                    onPaperAdd(
                        {
                            ...selectedPaper,
                            selectedLabRequests,
                        },
                        true
                    );
                } else {
                    message.error("Please select lab request");
                }
            } else if (selectedPaper.category === "Radiology Request") {
                if (selectedRadiologyRequests.length !== 0) {
                    onPaperAdd(
                        {
                            ...selectedPaper,
                            selectedRadiologyRequests,
                        },
                        true
                    );
                } else {
                    message.error("Please select radiology request");
                }
            } else if (selectedPaper.category === "Other Request") {
                if (selectedOtherRequests.length !== 0) {
                    onPaperAdd(
                        {
                            ...selectedPaper,
                            selectedOtherRequests,
                        },
                        true
                    );
                } else {
                    message.error("Please select other request");
                }
            } else {
                onPaperAdd(selectedPaper, false);
            }
        } else {
            message.error("Please select a paper");
        }
    };

    return (
        <>
            <Drawer
                title="Papers"
                placement="right"
                visible={isPaperFormVisible}
                onClose={togglePaperFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <div>Search Paper</div>
                <Select
                    showSearch
                    optionLabelProp="label"
                    optionFilterProp="label"
                    onChange={handleSelectChange}
                    style={{ width: "100%" }}
                    placeholder="Search Paper"
                >
                    {categories.map((category) => {
                        return (
                            <OptGroup key={category} label={category}>
                                {papers
                                    .filter(
                                        (paper) =>
                                            (paper.category === category ||
                                                paper.category.indexOf(
                                                    category
                                                ) !== -1) &&
                                            (paper.doctor_id === userID ||
                                                userType === "staff")
                                    )
                                    .filter(
                                        (paper) => paper.status === "active"
                                    )
                                    .map((paper) => (
                                        <Option
                                            key={paper.paper_id}
                                            value={paper.paper_id}
                                            label={paper.name}
                                        >
                                            {paper.name}{" "}
                                            {userType === "staff" &&
                                                `(${findUserName(
                                                    paper.doctor_id
                                                )})`}
                                        </Option>
                                    ))}
                            </OptGroup>
                        );
                    })}
                </Select>
                {selectedRequestPaper === "Lab Request" && (
                    <div>
                        {labRequests.length !== 0 ? (
                            <div>
                                <div style={{ marginTop: 10 }}>
                                    <strong>Select Request</strong>
                                </div>
                                <Checkbox.Group
                                    onChange={(values) =>
                                        handleCheckboxChange(
                                            values,
                                            "Lab Request"
                                        )
                                    }
                                >
                                    {labRequests
                                        .filter(
                                            (labRequest) => labRequest !== ""
                                        )
                                        .map((labRequest, index) => (
                                            <div key={index}>
                                                <Checkbox value={labRequest}>
                                                    {labRequest}
                                                </Checkbox>
                                            </div>
                                        ))}
                                </Checkbox.Group>
                            </div>
                        ) : (
                            selectedPaper &&
                            selectedPaper.category === "Lab Request" && (
                                <div style={{ padding: "10px 0" }}>
                                    No lab request list. You can manage lab
                                    request list on the{" "}
                                    <Link to="/papers">Paper page</Link>.
                                </div>
                            )
                        )}
                    </div>
                )}
                {selectedRequestPaper === "Radiology Request" && (
                    <div>
                        {radiologyRequests.length !== 0 ? (
                            <div>
                                <div style={{ marginTop: 10 }}>
                                    <strong>Select Request</strong>
                                </div>
                                <Checkbox.Group
                                    onChange={(values) =>
                                        handleCheckboxChange(
                                            values,
                                            "Radiology Request"
                                        )
                                    }
                                >
                                    {radiologyRequests
                                        .filter(
                                            (radiologyRequest) =>
                                                radiologyRequest !== ""
                                        )
                                        .map((radiologyRequest, index) => (
                                            <div key={index}>
                                                <Checkbox
                                                    value={radiologyRequest}
                                                >
                                                    {radiologyRequest}
                                                </Checkbox>
                                            </div>
                                        ))}
                                </Checkbox.Group>
                            </div>
                        ) : (
                            selectedPaper &&
                            selectedPaper.category === "Radiology Request" && (
                                <div style={{ padding: "10px 0" }}>
                                    No Radiology Request List. You can manage
                                    radiology request list on the{" "}
                                    <Link to="/papers">Paper page</Link>.
                                </div>
                            )
                        )}
                    </div>
                )}

                {selectedRequestPaper === "Other Request" && (
                    <div>
                        {otherRequests.length !== 0 ? (
                            <div>
                                <div style={{ marginTop: 10 }}>
                                    <strong>Select Request</strong>
                                </div>
                                <Checkbox.Group
                                    onChange={(values) =>
                                        handleCheckboxChange(
                                            values,
                                            "Other Request"
                                        )
                                    }
                                >
                                    {otherRequests
                                        .filter(
                                            (otherRequest) =>
                                                otherRequest !== ""
                                        )
                                        .map((otherRequest, index) => (
                                            <div key={index}>
                                                <Checkbox value={otherRequest}>
                                                    {otherRequest}
                                                </Checkbox>
                                            </div>
                                        ))}
                                </Checkbox.Group>
                            </div>
                        ) : (
                            selectedPaper &&
                            selectedPaper.category === "Other Request" && (
                                <div style={{ padding: "10px 0" }}>
                                    No Other Request List. You can manage other
                                    request list on the{" "}
                                    <Link to="/papers">Paper page</Link>.
                                </div>
                            )
                        )}
                    </div>
                )}

                <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={handlePaperAdd}
                >
                    Add
                </Button>
            </Drawer>
            <div onClick={togglePaperFormVisible}>
                <Icon type="file" />
                <br />
                Add Paper
            </div>
            {/* <Button type="link" block onClick={togglePaperFormVisible}>
                <Icon type="file" /> Add Paper
            </Button> */}
            {/* <Tooltip title="Add Paper">
                <Button size="large" onClick={togglePaperFormVisible}>
                    <Icon type="file" />
                </Button>
            </Tooltip> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    papers: state.Papers,
    userType: state.UserDetails.type,
    userID: state.UserDetails.user_id,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
});

export default connect(mapStateToProps)(PaperDrawer);
