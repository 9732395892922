import React, { useState, useEffect } from "react";
import { Row, Col, Button, Alert, Tag, Icon, Popover, Tooltip } from "antd";
import moment from "moment";

import http from "services/httpService";
import { apiUrl } from "config.json";

const StreamDetails = ({
    caseDetails,
    initStreamTime,
    maxStreamTime,
    timerStop,
    onLocalStreamToggleVisible,
    patientDisconnected,
    isAllowExcessTime: isAET,
    onLeave,
    onPatientDisconnect
}) => {
    const [seconds, setSeconds] = useState(initStreamTime);
    const [isAllowExcessTime, setIsAllowExcessTime] = useState(isAET);
    const [intervalId, setIntervalId] = useState(0);

    useEffect(() => {
        const saveCallLog = async () => {
            try {
                await http.post(
                    `${apiUrl}/videoCallLog`,
                    {
                        appointment_id: caseDetails.appointment_id,
                        start: moment().format("HH:mm:ss"),
                        end: "",
                        action: "started"
                    },
                    {
                        headers: {
                            conn: "pp"
                        }
                    }
                );
            } catch (error) {
                console.log(error);
            }
        };

        const id = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);

        setIntervalId(id);

        if (timerStop) {
            clearInterval(id);
        } else {
            saveCallLog();
        }

        return () => {
            //localStorage.setItem("duration", seconds);
            clearInterval(id);
        };
    }, [timerStop]);

    useEffect(() => {
        if (!isAllowExcessTime && seconds >= maxStreamTime) {
            clearInterval(intervalId);
            onLeave(seconds, "timesup", isAllowExcessTime);
        }

        const saveTime = async () => {
            try {
                await http.put(`${apiUrl}/case/${caseDetails.case_id}`, {
                    call_duration: moment.utc(seconds * 1000).format("HH:mm:ss")
                });
            } catch (error) {
                console.log(error);
            }
        };

        if (seconds % 45 === 1) {
            saveTime();
        }
    }, [seconds]);

    useEffect(() => {
        if (patientDisconnected) {
            onPatientDisconnect(seconds, "disconnect", isAllowExcessTime);
        }
    }, [patientDisconnected]);

    const handleAllowExcessTime = () => {
        setIsAllowExcessTime(true);
    };

    const handleLeave = () => {
        onLeave(seconds, "leaved", isAllowExcessTime);
    };

    return (
        <div
            style={{
                position: "relative",
                backgroundColor: "#fff"
            }}
        >
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    top: -36,
                    left: 0,
                    zIndex: 10
                }}
            >
                {!isAllowExcessTime &&
                maxStreamTime - seconds < 16 &&
                maxStreamTime - seconds >= 0 ? (
                    <Alert
                        message={`Disconnecting stream in ${
                            maxStreamTime - seconds
                        }`}
                        type="error"
                        banner
                        key={1}
                    />
                ) : !isAllowExcessTime && maxStreamTime - seconds < 60 ? (
                    <Alert
                        message="You have 1 min left"
                        type="warning"
                        closable
                        banner
                        key={2}
                    />
                ) : !isAllowExcessTime && maxStreamTime - seconds < 120 ? (
                    <Alert
                        message="You have 2 mins left"
                        type="warning"
                        closable
                        banner
                        key={3}
                    />
                ) : !isAllowExcessTime && maxStreamTime - seconds < 180 ? (
                    <Alert
                        message="You have 3 mins left"
                        type="warning"
                        closable
                        banner
                        key={4}
                    />
                ) : null}
            </div>
            <div
                style={{
                    padding: 10,
                    border: "1px solid #b2b2b2",
                    borderTop: "none"
                }}
            >
                <Row type="flex" justify="space-between">
                    <Col>
                        <h3>{moment.utc(seconds * 1000).format("HH:mm:ss")}</h3>
                    </Col>
                    <Col>
                        {!isAllowExcessTime ? (
                            <Popover
                                content={
                                    <p style={{ maxWidth: 200 }}>
                                        If you allow this, any excess minutes
                                        will be charge to your account
                                        accordingly.
                                    </p>
                                }
                                title="Allow Time Excess"
                                placement="top"
                            >
                                <Button
                                    icon="clock-circle"
                                    style={{
                                        marginRight: 5
                                    }}
                                    onClick={handleAllowExcessTime}
                                ></Button>
                            </Popover>
                        ) : (
                            <Tag color="#87d068">
                                <Icon type="clock-circle" /> Allowed
                            </Tag>
                        )}

                        <Tooltip title="Toggle my local stream visibility">
                            <Button
                                icon="camera"
                                style={{
                                    marginRight: 5
                                }}
                                onClick={() => onLocalStreamToggleVisible()}
                            />
                        </Tooltip>

                        <Button
                            type="danger"
                            icon="phone"
                            disabled={timerStop}
                            onClick={handleLeave}
                        >
                            End Call
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>

        // <div style={{ backgroundColor: "#161616", position: "relative" }}>
        //     <div
        //         style={{
        //             padding: 10,
        //         }}
        //     >
        //         <Row type="flex" justify="space-between">
        //             <Col>
        //                 <h3 style={{ color: "#fff" }}>
        //                     {moment.utc(seconds * 1000).format("HH:mm:ss")}
        //                 </h3>
        //             </Col>
        //             <Col>
        //                 {!isAllowExcessTime ? (
        //                     <Popover
        //                         content={
        //                             <p style={{ maxWidth: 200 }}>
        //                                 If you allow this, any excess minutes
        //                                 will be charge to your account
        //                                 accordingly.
        //                             </p>
        //                         }
        //                         title="Allow Time Excess"
        //                         placement="bottom"
        //                     >
        //                         <Button
        //                             type="primary"
        //                             onClick={handleAllowExcessTime}
        //                         >
        //                             Allow Excess Time
        //                         </Button>
        //                     </Popover>
        //                 ) : (
        //                     <Tag color="#87d068">
        //                         <Icon type="check-circle" /> Excess Time Allowed
        //                     </Tag>
        //                 )}
        //                 <Button
        //                     type="danger"
        //                     style={{ marginLeft: 5 }}
        //                     disabled={timerStop}
        //                     onClick={handleLeave}
        //                 >
        //                     <Icon type="close" />
        //                 </Button>
        //             </Col>
        //         </Row>
        //     </div>
        //     <div
        //         style={{
        //             position: "absolute",
        //             width: "100%",
        //             top: 49,
        //             left: 0,
        //             zIndex: 10,
        //         }}
        //     >

        //     </div>
        // </div>
    );
};

export default StreamDetails;
