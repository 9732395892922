import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Input,
    DatePicker,
    Select,
    Form,
    Button,
    Badge,
    message,
    Tabs,
    Table,
    Typography,
    Alert,
    Spin,
} from "antd";
import moment from "moment";

import PatientSearchBar from "globalComponents/PatientSearchBar";
import PatientCard from "globalComponents/PatientCard";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const AppointmentForm = ({
    data,
    caseID,
    breakpoint,
    onSubmit,
    userID,
    clinicID,
    doctors,
    setRequestError,
}) => {
    const [formData, setFormData] = useState({
        appointment_datetime: null,
        details: "Consultation - Checkup",
        appointment_duration: 0,
        patient_id: "",
        patient_fullname: "",
        patient_img_path: "",
        doctor_id: "",
        clinic_id: clinicID,
        case_id: caseID ? caseID : "",
        status: "pending",
        user_id: userID,
        is_from_cwapp: 0,
        video_call_room_id: "",
    });
    const [formErrors, setFormErrors] = useState({
        patient_id: "",
        details: "",
        appointment_datetime: "",
        doctor_id: "",
    });

    //const [formLoading, setFormLoading] = useState(false);
    const [currentAppointments, setCurrentAppointments] = useState([]);

    const [patientID, setPatientID] = useState(null);

    const [
        isAppointmentsTableLoading,
        setIsAppointmentsTableLoading,
    ] = useState(false);
    const [totalCurrentAppointment, setTotalCurrentAppointment] = useState(0);
    const [
        isDoctorCWAPPSchedFetching,
        setIsDoctorCWAPPSchedFetching,
    ] = useState(false);
    const [CWAPPSched, setCWAPPSched] = useState([]);

    const { TextArea } = Input;
    const { Option } = Select;
    const { TabPane } = Tabs;
    const { Text } = Typography;

    useEffect(() => {
        if (data) {
            setPatientID(data.patient_id);
            setFormData({
                ...data,
                appointment_datetime: data.appointment_datetime
                    ? moment(data.appointment_datetime).format(
                          "YYYY-MM-DD HH:mm"
                      )
                    : null,
                clinic_id: clinicID,
                user_id: userID,
            });
            if (data.is_from_cwapp) {
                getDoctorCWAPPSchedule(data.doctor_id);
            }
        } else {
            if (doctors.length === 1) {
                setFormData({
                    ...formData,
                    doctor_id: doctors[0].doctor_id,
                });
            }
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    async function getDoctorCWAPPSchedule(doctorId) {
        try {
            setIsDoctorCWAPPSchedFetching(true);
            const response = await http.get(
                `${apiUrl}/doctorCwappSched/${doctorId}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                }
            );
            const schedule = JSON.parse(response.data.cwappSched.schedule);
            setCWAPPSched(schedule.schedules);

            setIsDoctorCWAPPSchedFetching(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    }

    const handlePatientSelect = (selectedRecord) => {
        if (selectedRecord) {
            setPatientID(selectedRecord.patient_id);
            setFormData({
                ...formData,
                patient_id: selectedRecord.patient_id,
                patient_fullname: selectedRecord.fullname,
                patient_img_path: selectedRecord.img_path,
            });
        } else {
            setPatientID(null);
            setFormData({
                ...formData,
                patient_id: "",
            });
        }
    };

    const handleDateChange = async (dateString) => {
        let errors = { ...formErrors };
        if (dateString !== "") {
            errors.appointment_datetime = "";
            setFormErrors(errors);
        }
        const appointment_datetime = moment(dateString).format(
            "YYYY-MM-DD HH:mm"
        );
        const date = moment(dateString).format("YYYY-MM-DD");

        setFormData({
            ...formData,
            appointment_datetime,
        });

        try {
            setIsAppointmentsTableLoading(true);
            const response = await http.get(
                `${apiUrl}/dayAppointments/${date}/${clinicID}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                }
            );

            setCurrentAppointments(response.data.appointments);
            setTotalCurrentAppointment(response.data.appointments.length);
            setIsAppointmentsTableLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const handleDoctorChange = (value, option) => {
        let errors = { ...formErrors };
        if (value !== "") {
            errors.doctor_id = "";
            setFormErrors(errors);
        }

        setFormData({
            ...formData,
            doctor_id: value,
        });
    };

    const handleDetailsChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const {
            patient_id,
            details,
            appointment_datetime,
            doctor_id,
        } = formData;
        let errors = {};

        if (details.trim() === "") {
            errors.details = "Details is required";
        }
        if (!appointment_datetime) {
            errors.appointment_datetime = "Date and time is required";
        }
        if (doctor_id === "") {
            errors.doctor_id = "Doctor is required";
        }

        let errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        } else if (patient_id === "") {
            message.error("Please select a patient");
            errorLength = 1;
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (isSaveOnPatientPortal) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            if (isSaveOnPatientPortal) {
                //date validation
                const dateString = moment(formData.appointment_datetime).format(
                    "YYYY-MM-DD"
                );
                const weekDaysName = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];
                const schedDays = CWAPPSched.reduce((acc, item) => {
                    acc.push(item.day);
                    return acc;
                }, []);
                const selectedDay = moment(dateString).day();

                if (!schedDays.includes(selectedDay)) {
                    message.error(
                        <span>
                            Sorry, {weekDaysName[selectedDay]} was not included
                            on the doctor patient portal schedule.
                            <br />
                            You may check your patient portal schedule in My
                            Account &gt; Edit Profile
                        </span>,
                        10
                    );
                } else {
                    onSubmit(formData, isSaveOnPatientPortal);
                }
            } else {
                onSubmit(formData, isSaveOnPatientPortal);
            }
        }
    };

    const appointmentsTableColumns = [
        {
            title: "Details",
            key: "patient",
            width: 150,
            render: (text, record) => (
                <>
                    <strong>{record.patient_fullname}</strong>
                    <br />
                    <Text type="secondary">{record.details}</Text>
                    <br />
                    {findUserName(record.doctor_id)}
                </>
            ),
            filters: [
                ...doctors.map((doctor) => ({
                    text: doctor.doctor_name,
                    value: doctor.doctor_id,
                })),
            ],
            //filteredValue: selectedDoctor ? [selectedDoctor] : null,
            onFilter: (value, record) => {
                return record.doctor_id === value;
            },
        },
        {
            title: "Time",
            key: "time",
            width: 50,
            render: (text, record) => (
                <span>
                    {moment(record.appointment_datetime).format("hh:mm A")}
                </span>
            ),
        },
    ];

    return (
        <Tabs defaultActiveKey="1" animated={false}>
            <TabPane tab="Patient" key="1">
                <div style={{ paddingLeft: 2, marginBottom: 50 }}>
                    {!data && (
                        <>
                            <PatientSearchBar
                                onSelect={handlePatientSelect}
                                showAddPatientBtn
                                breakpoint={breakpoint}
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid #e8e8e8",
                                    margin: "10px 0",
                                }}
                            ></div>
                        </>
                    )}
                    {formData.is_from_cwapp ? (
                        <div>
                            <Alert
                                type="info"
                                message="From Patient Portal"
                                description="The patient with the said chief complaint made the appointment from Patient Portal. By clicking the Save on Patient Portal button, this follow-up appointment will be saved on Patient Portal so that patient can made the decision on the follow-up appointment that you will created."
                                showIcon
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <PatientCard
                        patientID={patientID}
                        bordered={false}
                        breakpoint={breakpoint}
                        showEditPatientInfoBtn
                    />
                    <Spin
                        spinning={isDoctorCWAPPSchedFetching}
                        tip="loading doctor schedule..."
                    >
                        <Form
                            style={{
                                width: "100%",
                                marginTop: 8,
                            }}
                            layout="horizontal"
                            labelCol={{
                                xl: { span: 6 },
                                lg: { span: 6 },
                            }}
                            wrapperCol={{
                                xl: { span: 18 },
                                lg: { span: 18 },
                            }}
                        >
                            <Form.Item
                                label="Date"
                                required
                                hasFeedback
                                validateStatus={
                                    formErrors.appointment_datetime && "error"
                                }
                                help={
                                    formErrors.appointment_datetime &&
                                    formErrors.appointment_datetime
                                }
                            >
                                <DatePicker
                                    value={
                                        formData.appointment_datetime &&
                                        moment(formData.appointment_datetime)
                                    }
                                    showTime={{
                                        use12Hours: true,
                                        format: "hh:mm A",
                                    }}
                                    format="LLLL"
                                    allowClear={false}
                                    placeholder="Select Date"
                                    style={{ width: "100%" }}
                                    onChange={handleDateChange}
                                    disabledDate={(current) =>
                                        current < moment().startOf("day")
                                    }
                                />
                            </Form.Item>
                            {doctors.length > 1 && (
                                <Form.Item
                                    label="Doctor"
                                    required
                                    hasFeedback
                                    validateStatus={
                                        formErrors.doctor_id && "error"
                                    }
                                    help={
                                        formErrors.doctor_id &&
                                        formErrors.doctor_id
                                    }
                                >
                                    <Select
                                        value={formData.doctor_id}
                                        placeholder="Select Doctor"
                                        disabled={
                                            formData.is_from_cwapp
                                                ? true
                                                : false
                                        }
                                        onChange={(value, option) =>
                                            handleDoctorChange(value, option)
                                        }
                                    >
                                        {doctors.map((doctor) => (
                                            <Option
                                                key={doctor.doctor_id}
                                                value={doctor.doctor_id}
                                            >
                                                {findUserName(doctor.doctor_id)}
                                            </Option>
                                        ))}
                                        <Option key={0} value="any">
                                            Any
                                        </Option>
                                    </Select>
                                </Form.Item>
                            )}
                            <Form.Item
                                label="Details"
                                required
                                hasFeedback
                                validateStatus={formErrors.details && "error"}
                                help={formErrors.details && formErrors.details}
                            >
                                <TextArea
                                    name="details"
                                    value={formData.details}
                                    onChange={handleDetailsChange}
                                    placeholder="Controlled autosize"
                                    autoSize={{
                                        minRows: 3,
                                        maxRows: 5,
                                    }}
                                    onBlur={handleInputBlur}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginRight: 5 }}
                                    onClick={() => handleSubmit(false)}
                                >
                                    Save
                                </Button>
                                {formData.is_from_cwapp ? (
                                    <Button onClick={() => handleSubmit(true)}>
                                        Save on Patient Portal
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        Appointment(s) <Badge count={totalCurrentAppointment} />
                    </span>
                }
                key="2"
            >
                <p>List of your Appointments based on the date you selected</p>
                <Table
                    columns={appointmentsTableColumns}
                    dataSource={currentAppointments}
                    bordered
                    loading={isAppointmentsTableLoading}
                    pagination={{
                        pageSize: 8,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    size="middle"
                    rowKey="appointment_id"
                />
            </TabPane>
        </Tabs>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentForm);
