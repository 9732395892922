import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { editClinic } from "redux/userDetails/userDetails.actions";
import { Card, Descriptions, Tag, Button, Icon } from "antd";
import Drawer from "globalComponents/Drawer";
import ClinicForm from "./components/Forms/ClinicForm";
import TeleMedSwitcher from "./components/TeleMedSwitcher";

import http from "services/httpService";
import { apiUrl } from "config.json";

const ProfileManagement = ({
    userDetails,
    breakpoint,
    editClinic,
    setRequestError
}) => {
    const [isClinicFormVisible, setIsClinicFormVisible] = useState(false);

    const {
        firstname,
        lastname,
        owner_id,
        clinicDetails,
        subscriptionDetails,
        type,
        is_data_completed
    } = userDetails;

    const toggleClinicFormVisible = () => {
        setIsClinicFormVisible(!isClinicFormVisible);
    };

    const handleClinicFormSubmit = async formData => {
        try {
            editClinic(formData);
            toggleClinicFormVisible();
            await http.put(
                `${apiUrl}/clinic/${clinicDetails.clinic_id}`,
                formData
            );
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    return (
        <Card title="Profile Summary">
            <Descriptions
                bordered={breakpoint.isNormalMobile ? false : true}
                column={1}
            >
                <Descriptions.Item label="Account Name">
                    {firstname} {lastname}
                </Descriptions.Item>
                <Descriptions.Item label="Account Number">
                    {owner_id}
                </Descriptions.Item>
                <Descriptions.Item label="Clinic">
                    {clinicDetails.name}{" "}
                    {type === "doctor&owner" && (
                        <Button type="link" onClick={toggleClinicFormVisible}>
                            <Icon type="edit" /> Edit Clinic Info
                        </Button>
                    )}
                </Descriptions.Item>
                {(type === "doctor" || type === "doctor&owner") && (
                    <>
                        <Descriptions.Item label="Profile Status">
                            {is_data_completed ? (
                                <Tag color="green">Completed</Tag>
                            ) : (
                                <Tag color="red">Not Completed</Tag>
                            )}
                            <Link to="/profile">
                                <Icon type="edit" />{" "}
                                {is_data_completed
                                    ? "Edit My Profile"
                                    : "Complete My Profile"}
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tele-Medecine">
                            <TeleMedSwitcher
                                isProfileComplete={is_data_completed}
                            />
                        </Descriptions.Item>
                    </>
                )}
                <Descriptions.Item label="Account Status">
                    {subscriptionDetails.isSystemDisabled ? (
                        <Tag color="red">Inactive</Tag>
                    ) : (
                        <Tag color="green">Active</Tag>
                    )}
                </Descriptions.Item>
            </Descriptions>

            <Drawer
                title="Edit Clinic"
                placement="right"
                visible={isClinicFormVisible}
                onClose={toggleClinicFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 500}
            >
                <ClinicForm
                    data={clinicDetails}
                    onSubmit={handleClinicFormSubmit}
                />
            </Drawer>
        </Card>
    );
};

const mapDispatchToProps = dispatch => ({
    editClinic: editedClinic => dispatch(editClinic(editedClinic)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(ProfileManagement);
