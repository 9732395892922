import React from "react";
import { Modal, Button } from "antd";

const ModalDetails = ({ children, ...props }) => {
    return (
        <Modal {...props}>
            <div>{children}</div>
            <div style={{ marginTop: 0, textAlign: "right" }}>
                <Button onClick={props.onCancel}>Close</Button>
            </div>
        </Modal>
    );
};

export default ModalDetails;
