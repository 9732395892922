import {
    SET_ITEM_SUCCESS,
    ADD_ITEM_SUCCESS,
    EDIT_ITEM_SUCCESS,
    DELETE_ITEM_SUCCESS,
    ADJUST_ITEM_STOCK_SUCCESS,
    UPDATE_PRESCRIPTION_NOTE_SUCCESS,
    ADJUST_BULK_ITEM_STOCK_SUCCESS,
} from "./item.types";

const INIT_STATE = [];

const itemReducer = (state = INIT_STATE, action) => {
    let newItems;
    switch (action.type) {
        case SET_ITEM_SUCCESS:
            return action.payload.items;
        case ADD_ITEM_SUCCESS:
            return [...state, action.payload.newItem];
        case EDIT_ITEM_SUCCESS:
            newItems = state.map((item) => {
                if (item.item_id === action.payload.editedItem.item_id) {
                    return action.payload.editedItem;
                }
                return item;
            });
            return newItems;
        case DELETE_ITEM_SUCCESS:
            return state.map((item) => {
                if (item.item_id === action.payload.itemID) {
                    return { ...item, status: "inactive" };
                }
                return item;
            });
        case ADJUST_ITEM_STOCK_SUCCESS:
            const newAdjustedItems = state.map((item) => {
                if (item.item_id === action.payload.item.item_id) {
                    return { ...item, stock_qty: action.payload.item.new_qty };
                }
                return item;
            });
            return newAdjustedItems;
        case UPDATE_PRESCRIPTION_NOTE_SUCCESS:
            newItems = state.map((item) => {
                if (item.item_id === action.payload.itemID) {
                    return {
                        ...item,
                        prescription_note: action.payload.prescriptionNote,
                    };
                }
                return item;
            });

            return newItems;
        case ADJUST_BULK_ITEM_STOCK_SUCCESS:
            const mappedUpdatedItems = action.payload.items.reduce(
                (acc, item) => {
                    acc[item.item_id] = item;
                    return acc;
                },
                {}
            );

            const newAdjustedBulkItems = state.map((item) => {
                if (mappedUpdatedItems[item.item_id]) {
                    return {
                        ...item,
                        stock_qty: mappedUpdatedItems[item.item_id].stock_qty,
                    };
                }
                return item;
            });

            return newAdjustedBulkItems;
        default:
            return state;
    }
};

export default itemReducer;
