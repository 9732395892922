import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Button, Divider, message, Card, Switch, Slider, Select } from "antd";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Actions = ({ queueingDetails, clinicID, setRequestError }) => {
    const [isClinicClosed, setIsClinicClosed] = useState(false);
    const [isDoctorAvailable, setIsDoctorAvailable] = useState(false);
    const [isMessageShow, setIsMessageShow] = useState(false);
    const [isAnnouncementShow, setIsAnnouncementShow] = useState(false);

    const [
        isDisabledShowMessageSwitch,
        setIsDisabledShowMessageSwitch,
    ] = useState(false);

    const [videoVolume, setVideoVolume] = useState(50);
    const [theme, setTheme] = useState("theme-default");

    const [isSaving, setIsSaving] = useState(false);

    const { Option } = Select;

    useEffect(() => {
        setIsClinicClosed(queueingDetails.data.is_clinic_closed ? true : false);
        setIsDoctorAvailable(
            queueingDetails.data.is_doctor_available ? true : false
        );
        setIsMessageShow(queueingDetails.data.is_message_show ? true : false);
        setIsAnnouncementShow(
            queueingDetails.data.is_announcement_show ? true : false
        );
        setVideoVolume(queueingDetails.data.video_volume);
        setTheme(queueingDetails.data.theme);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, [queueingDetails]);

    const handleClinicCloseSwitchChange = (checked) => {
        setIsClinicClosed(checked);
        if (checked) {
            setIsMessageShow(false);
            setIsDisabledShowMessageSwitch(true);
        } else {
            setIsDisabledShowMessageSwitch(false);
        }
    };
    const handleDoctorAvailableSwitchChange = (checked) => {
        setIsDoctorAvailable(checked);
    };
    const handleMessageShowSwitchChange = (checked) => {
        setIsMessageShow(checked);
    };
    const handleAnnouncementShowSwitchChange = (checked) => {
        setIsAnnouncementShow(checked);
    };

    const handleThemeChange = (value) => {
        setTheme(value);
    };

    const handleVolumeChange = (value) => {
        setVideoVolume(value);
    };

    const saveAndApply = async () => {
        try {
            setIsSaving(true);

            const newDetails = {
                is_clinic_closed: isClinicClosed ? 1 : 0,
                is_doctor_available: isDoctorAvailable ? 1 : 0,
                is_message_show: isMessageShow ? 1 : 0,
                is_announcement_show: isAnnouncementShow ? 1 : 0,
                theme: theme,
                video_volume: videoVolume,
            };

            await http.put(`${apiUrl}/queueDetails/${clinicID}`, newDetails, {
                cancelToken: new CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            });
            setIsSaving(false);
            message.success("Successfully Saved");

            socket.emit("queueingNewDetails", { newDetails, clinicID });
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    return (
        <Card title="Actions" style={{ marginBottom: 10 }}>
            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td>Close Clinic:</td>
                        <td>
                            <Switch
                                checked={isClinicClosed}
                                onChange={handleClinicCloseSwitchChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Doctor Available:</td>
                        <td>
                            <Switch
                                checked={isDoctorAvailable}
                                onChange={handleDoctorAvailableSwitchChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Show Message:</td>
                        <td>
                            <Switch
                                checked={isMessageShow}
                                disabled={isDisabledShowMessageSwitch}
                                onChange={handleMessageShowSwitchChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Show Announcement:</td>
                        <td>
                            <Switch
                                checked={isAnnouncementShow}
                                onChange={handleAnnouncementShowSwitchChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Volume:</td>
                        <td>
                            <Slider
                                min={1}
                                max={100}
                                value={videoVolume}
                                onChange={handleVolumeChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Theme:</td>
                        <td style={{ width: 200 }}>
                            <Select
                                value={theme}
                                style={{ width: "100%" }}
                                onChange={handleThemeChange}
                            >
                                <Option value="theme-default">Default</Option>
                            </Select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />
            <Button
                type="primary"
                size="large"
                loading={isSaving}
                onClick={saveAndApply}
            >
                {isSaving ? "Saving" : "Save and Apply"}
            </Button>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
