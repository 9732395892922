import { useState, useEffect } from "react";
import moment from "moment";

const CurrentTime = () => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        const time = setInterval(() => setCurrentTime(moment()), 1000);

        return () => {
            clearInterval(time);
        };
    }, []);

    return moment(currentTime).format("hh:mm A");
};

export default CurrentTime;
