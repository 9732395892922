/**
 * Resize base64 string
 *
 * @param src {String} base64 string
 * @param size {Integer} the width and the height of the conversion output
 * @param format {String} the format of the conversion output
 * @param callback {Function} the function that returns the base64 image
 * @see https://stackoverflow.com/a/33291682
 */
const resizeImgHelper = (src, size, format, callback) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var image = new Image();
    image.onload = function (e) {
        var pixelWidth = size;
        var scale = pixelWidth / image.width;
        canvas.width = scale * image.width;
        canvas.height = scale * image.height;
        ctx.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.width,
            canvas.height
        );
        callback(canvas.toDataURL(format));
    };
    image.src = src;
};

/**
 * Convert base64 string image to blob/image file.
 *
 * @param src {String} base64 string
 * @param name {String} the name of the input form (must be match to name on server)
 * @param filename {String} filename and file extension of the converted file
 * @see https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
 * @return file object
 */
const b64ImgToFileHelper = (src, filename) => {
    const block = src.split(";");
    const contentType = block[0].split(":")[1];
    const b64Data = block[1].split(",")[1];
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const fileOfBlob = new File([blob], filename);

    return fileOfBlob;
};

export { resizeImgHelper, b64ImgToFileHelper };
