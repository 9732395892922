import React from "react";
import { connect } from "react-redux";
import { Tag, Divider, Button } from "antd";
import moment from "moment";

import { findUserName } from "helpers/userNameFinder";

const PatientCaseDetails = ({ selectedCase, onCaseOpen, caseListSettings }) => {
    const {
        chief_complaint,
        complaint_datetime,
        case_name,
        doctor_id,
        user_id,
        status,
        case_id,
        created_at,
        updated_at,
    } = selectedCase;

    let statusColor = "";
    switch (status) {
        case "pending":
            statusColor = caseListSettings.pendingStatusColor;
            break;
        case "hold":
            statusColor = caseListSettings.holdStatusColor;
            break;
        case "no-show":
            statusColor = caseListSettings.noShowStatusColor;
            break;
        case "done":
            statusColor = caseListSettings.doneStatusColor;
            break;
        default:
            statusColor = "#ccc";
    }

    return (
        <div>
            <h3>Chief Complaint:</h3>
            <p>{chief_complaint}</p>
            <Divider />
            <table cellPadding={3}>
                <tbody>
                    <tr>
                        <td style={{ width: 100 }}>Case Name:</td>
                        <td>{case_name || <span>&mdash;</span>}</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>
                            {moment(complaint_datetime).format(
                                "MMMM DD, YYYY h:mm: A"
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Doctor:</td>
                        <td>{findUserName(doctor_id)}</td>
                    </tr>
                    <tr>
                        <td>Created By:</td>
                        <td>{findUserName(user_id)}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>
                            <Tag color={statusColor}>{status}</Tag>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />

            <table>
                <tbody>
                    <tr>
                        <td style={{ width: 100 }}>Date Created:</td>
                        <td>{moment(created_at).format("MMMM DD, YYYY")}</td>
                    </tr>
                    <tr>
                        <td>Last Update:</td>
                        <td>{moment(updated_at).format("MMMM DD, YYYY")}</td>
                    </tr>
                </tbody>
            </table>

            <Divider />

            <Button type="primary" onClick={() => onCaseOpen(case_id)}>
                Open Case
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    caseListSettings: state.Settings.caseList,
});

export default connect(mapStateToProps)(PatientCaseDetails);
