import {
    STREAM_CONTENT_VISIBLE_CHANGE_SUCCESS,
    SET_VIDEO_CALL_DETAILS_SUCCESS,
} from "./videoCaller.types";

const INIT_STATE = {
    isStreamContentVisible: false,
    details: {
        initStreamTime: 0,
        maxStreamTime: 0,
        isAllowExcessTime: false,
        caseDetails: "",
        patientDetails: "",
    },
};

const videoCallReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case STREAM_CONTENT_VISIBLE_CHANGE_SUCCESS:
            return {
                ...state,
                isStreamContentVisible: action.payload.isVisible,
            };
        case SET_VIDEO_CALL_DETAILS_SUCCESS:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.payload.details,
                },
            };
        default:
            return state;
    }
};

export default videoCallReducer;
