const convertToSolidColor = (color) => {
    switch (color) {
        case "magenta":
            return "#eb2f96";
        case "red":
            return "#f5222d";
        case "volcano":
            return "#fa541c";
        case "orange":
            return "#fa8c16";
        case "gold":
            return "#faad14";
        case "lime":
            return "#a0d911";
        case "green":
            return "#52c41a";
        case "cyan":
            return "#13c2c2";
        case "blue":
            return "#1890ff";
        case "geekblue":
            return "#2f54eb";
        case "purple":
            return "#722ed1";
        default:
            return "#838383";
    }
};

export { convertToSolidColor };
