import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Button,
    DatePicker,
    Table,
    Icon,
    Row,
    Col,
    Tag,
    Badge,
    Tooltip,
    Avatar,
    Modal,
    Switch,
    message
} from "antd";

import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import Drawer from "globalComponents/Drawer";
import ModalDetails from "globalComponents/ModalDetails";
import ActionMenu from "globalComponents/ActionMenu";
import AppointmentForm from "./components/Forms/AppointmentForm";
import AppointmentCalendar from "./components/AppointmentCalendar";
import PatientAppointmentDetails from "./components/PatientAppointmentDetails";

import { generateString } from "helpers/randomStringGenerator";
import { findUserName } from "helpers/userNameFinder";
import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Appointments = ({
    breakpoint,
    clinicID,
    doctors,
    appointmentListSettings,
    setRequestError,
    ...props
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);

    const [appointments, setAppointments] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [selectedDate, setSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );

    const [isAppointmentsTableLoading, setIsAppointmentsTableLoading] =
        useState(false);
    const [isAppointmentFormVisible, setIsAppointmentFormVisible] =
        useState(false);
    const [isAppointmentDetailsVisible, setIsAppointmentDetailsVisible] =
        useState(false);

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [newCalendarAppointment, setNewCalendarAppointment] = useState(null); //for calendar optimistic ui update

    const { confirm } = Modal;
    const ButtonGroup = Button.Group;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAppointments(selectedDate);
                setAppointments(response.data.appointments);
                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    useEffect(() => {
        //console.log(selectedDate);
        socket.on("reloadAppointment", async function (data) {
            try {
                setIsAppointmentsTableLoading(true);
                const date = moment(selectedDate).format("YYYY-MM-DD");
                const response = await getAppointments(date);
                setAppointments(response.data.appointments);
                setIsAppointmentsTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        });

        return () => {
            socket.off("reloadAppointment");
        };
    }, [selectedDate]);

    function getAppointments(date) {
        return http.get(`${apiUrl}/dayAppointments/${date}/${clinicID}`, {
            cancelToken: new CancelToken(function executor(c) {
                CancelRequest = c;
            })
        });
    }

    function sortAppointmentByTime(arr) {
        return arr.sort(
            (a, b) =>
                new Date(
                    "1970/01/01 " +
                        moment(a.appointment_datetime).format("hh:mm A")
                ) -
                new Date(
                    "1970/01/01 " +
                        moment(b.appointment_datetime).format("hh:mm A")
                )
        );
    }

    const toggleCalendarVisible = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };

    const toggleAppointmentDetailsVisible = () => {
        setIsAppointmentDetailsVisible(!isAppointmentDetailsVisible);
    };

    const toggleAppointmentFormVisible = () => {
        setIsAppointmentFormVisible(!isAppointmentFormVisible);
    };

    const showAppointmentDetails = selectedRecord => {
        setSelectedAppointment(selectedRecord);
        toggleAppointmentDetailsVisible();
    };

    const showAppointmentForm = (selectedRecord = null) => {
        setSelectedAppointment(selectedRecord);
        toggleAppointmentFormVisible();
    };

    const handleDateChange = async selectedDate => {
        try {
            if (!isAppointmentsTableLoading) {
                setIsAppointmentsTableLoading(true);
                const date = moment(selectedDate).format("YYYY-MM-DD");
                setSelectedDate(date);
                const response = await getAppointments(date);
                setAppointments(response.data.appointments);
                setIsAppointmentsTableLoading(false);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handleAppointmentFormSubmit = async formData => {
        try {
            toggleAppointmentFormVisible();
            if (selectedAppointment) {
                //update
                //remove form the list if the date is not equal
                if (
                    moment(formData.appointment_datetime).format("LL") !==
                    moment(selectedDate).format("LL")
                ) {
                    const newAppointments = appointments.filter(
                        appointment =>
                            appointment.appointment_id !==
                            formData.appointment_id
                    );

                    setAppointments(newAppointments);
                } else {
                    const newAppointments = appointments.map(appointment => {
                        if (
                            appointment.appointment_id ===
                            formData.appointment_id
                        ) {
                            return formData;
                        }
                        return appointment;
                    });
                    setAppointments(sortAppointmentByTime(newAppointments));
                }

                const newFormData = { ...formData };
                delete newFormData.patient_fullname;
                delete newFormData.patient_img_path;

                message.success("Appointment successfully updated!");

                //update db
                await http.put(
                    `${apiUrl}/appointment/${newFormData.appointment_id}`,
                    newFormData
                );
            } else {
                //add
                const appointmentID = generateString(10, "a");
                const newFormData = {
                    ...formData,
                    appointment_id: appointmentID
                };
                if (
                    moment(newFormData.appointment_datetime).format("LL") ===
                    moment(selectedDate).format("LL")
                ) {
                    setAppointments(
                        sortAppointmentByTime([...appointments, newFormData])
                    );
                }

                message.success("Appointment successfully added!");

                setNewCalendarAppointment(newFormData);

                //add to db
                await http.post(`${apiUrl}/appointment`, newFormData);
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleAppointmentDelete = async ({ appointment_id }) => {
        confirm({
            title: `Are you sure do you want to archive this appointment?`,
            //content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    setAppointments([
                        ...appointments.filter(
                            appointment =>
                                appointment.appointment_id !== appointment_id
                        )
                    ]);
                    resolve();
                    try {
                        await http.put(
                            `${apiUrl}/deleteAppointment/${appointment_id}`
                        );
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleAppointmentConfirm = selectedRecord => {
        const { patient_id, appointment_id } = selectedRecord;
        return props.history.push(
            `/case/create/${patient_id}/${appointment_id}`
        );
    };

    const handleAppointmentCancel = async selectedRecord => {
        try {
            const newAppointments = appointments.map(appointment => {
                if (
                    appointment.appointment_id === selectedRecord.appointment_id
                ) {
                    return { ...selectedRecord, status: "canceled" };
                }
                return appointment;
            });

            setAppointments(newAppointments);

            message.success("Appointment successfully canceled!");

            await http.put(
                `${apiUrl}/appointment/${selectedRecord.appointment_id}`,
                { status: "canceled" }
            );
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const appointmentTableColumns = [
        {
            title: "Appointments",
            key: "patient",
            className: "cursorPointer",
            render: (text, record, index) => {
                const {
                    patient_img_path,
                    patient_fullname,
                    details,
                    appointment_datetime,
                    doctor_id,
                    status,
                    is_from_cwapp,
                    is_patient_from_legacy,
                    patient_id
                } = record;

                const avatarProps = patient_img_path
                    ? {
                          size: 32,
                          src: is_patient_from_legacy
                              ? patient_img_path.replace(
                                    `${patient_id}-`,
                                    `thumb/${patient_id}-`
                                )
                              : patient_img_path.replace(".jpeg", "-thumb.jpeg")
                      }
                    : { size: 32, icon: "user" };

                const TimeAndDoctorDetails = () => {
                    return (
                        <span>
                            {/* TIME */}
                            {breakpoint.isTabletLandscape && (
                                <span>
                                    <Icon
                                        type="clock-circle"
                                        theme="twoTone"
                                        style={{ marginRight: 8 }}
                                    />
                                    {moment(appointment_datetime).format(
                                        "hh:mm A"
                                    )}
                                </span>
                            )}

                            {/* DIVIDER */}
                            {breakpoint.isTabletLandscape &&
                                !breakpoint.isNormalMobile &&
                                doctors.length > 1 && (
                                    <span
                                        style={{
                                            color: "#e8e8e8",
                                            margin: "0 10px"
                                        }}
                                    >
                                        |
                                    </span>
                                )}
                            {breakpoint.isNormalMobile && <br />}

                            {/* DOCTOR */}
                            {doctors.length > 1 &&
                                appointmentListSettings.isShowDoctor && (
                                    <span>
                                        <Icon
                                            type="user"
                                            style={{ marginRight: 8 }}
                                        />
                                        {findUserName(doctor_id)}
                                    </span>
                                )}
                        </span>
                    );
                };

                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor =
                            appointmentListSettings.pendingStatusColor;
                        break;
                    case "canceled":
                        statusColor =
                            appointmentListSettings.canceledStatusColor;
                        break;
                    case "confirmed":
                        statusColor =
                            appointmentListSettings.confirmedStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }

                return (
                    <div>
                        <Row
                            type="flex"
                            justify="start"
                            style={{ flexWrap: "nowrap" }}
                        >
                            <Col>
                                {appointmentListSettings.isShowImage && (
                                    <span style={{ marginRight: 16 }}>
                                        <Avatar {...avatarProps} />
                                    </span>
                                )}
                            </Col>
                            <Col>
                                {/* PATIENT NAME */}
                                <h4 className="ant-list-item-meta-title">
                                    {index + 1}. {patient_fullname}
                                </h4>

                                <div>
                                    {/* APPOINTMENT DETAILS */}
                                    {appointmentListSettings.isShowDetails && (
                                        <div>
                                            <span
                                                style={{ fontStyle: "italic" }}
                                            >
                                                &mdash; &ldquo;
                                                {details}
                                                &rdquo;
                                            </span>
                                        </div>
                                    )}

                                    {/* TIME AND DOCTOR */}
                                    <div
                                        style={{
                                            marginTop:
                                                breakpoint.isTabletLandscape
                                                    ? 8
                                                    : doctors.length > 1
                                                    ? 5
                                                    : 0
                                        }}
                                    >
                                        <TimeAndDoctorDetails />
                                    </div>

                                    {/* FROM CWAPP INDICATOR */}
                                    {is_from_cwapp ? (
                                        <div style={{ marginTop: 5 }}>
                                            <Tag color="purple">
                                                <Icon type="pushpin" /> From
                                                Patient Portal
                                            </Tag>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {/* STATUS */}
                                    {breakpoint.isNormalMobile && (
                                        <div style={{ marginTop: 5 }}>
                                            <Tag color={statusColor}>
                                                {status}
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            },
            filters: [
                ...doctors.map(doctor => ({
                    text: doctor.doctor_name,
                    value: doctor.doctor_id
                }))
            ],
            onFilter: (value, record) => {
                return record.doctor_id === value;
            }
        },
        {
            title: "Time",
            dataIndex: "appointment_datetime",
            width: 130,
            render: time => (
                <span>
                    <Icon
                        type="clock-circle"
                        theme="twoTone"
                        style={{ marginRight: 8 }}
                    />
                    {moment(time).format("hh:mm A")}
                </span>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            className: "cursorPointer",
            render: status => {
                let statusColor = "";
                switch (status) {
                    case "pending":
                        statusColor =
                            appointmentListSettings.pendingStatusColor;
                        break;
                    case "canceled":
                        statusColor =
                            appointmentListSettings.canceledStatusColor;
                        break;
                    case "confirmed":
                        statusColor =
                            appointmentListSettings.confirmedStatusColor;
                        break;
                    default:
                        statusColor = "#ccc";
                }
                return <Tag color={statusColor}>{status}</Tag>;
            },
            filters: [
                {
                    text: "Pending",
                    value: "pending"
                },
                {
                    text: "Confirmed",
                    value: "confirmed"
                },
                {
                    text: "Canceled",
                    value: "canceled"
                }
            ],
            onFilter: (value, record) => record.status === value
        },
        {
            title: "",
            key: "action",
            width: 50,
            className: "cursorPointer",
            render: (text, record) => {
                const menu = [
                    {
                        label: "Confirm",
                        icon: "check",
                        onClick: () => handleAppointmentConfirm(record)
                    },
                    {
                        label: "Cancel",
                        icon: "close-circle",
                        onClick: () => handleAppointmentCancel(record)
                    },
                    {
                        label: "Edit",
                        icon: "edit",
                        onClick: () => showAppointmentForm(record)
                    },
                    {
                        label: "Archive",
                        icon: "container",
                        onClick: () => handleAppointmentDelete(record)
                    }
                ];

                if (record.is_from_cwapp) {
                    menu.splice(1, 3);
                    if (record.status === "confirmed") {
                        menu.splice(0, 1);
                    }
                } else {
                    if (record.status === "confirmed") {
                        menu.splice(0, 4);
                    } else if (record.status === "canceled") {
                        menu.splice(1, 2);
                    }
                }

                return (
                    menu.length !== 0 && (
                        <ActionMenu menu={menu} layout="compress" />
                    )
                );
            }
        }
    ];

    if (breakpoint.isNormalMobile) {
        appointmentTableColumns.splice(1, 2);
    } else if (breakpoint.isTabletLandscape) {
        appointmentTableColumns.splice(1, 1);
    }

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <div>
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 16 }}
            >
                <Col>
                    {!breakpoint.isNormalMobile && "Calendar View "}
                    <Switch
                        checkedChildren={
                            breakpoint.isNormalMobile ? (
                                <Icon type="calendar" />
                            ) : null
                        }
                        unCheckedChildren={
                            breakpoint.isNormalMobile ? (
                                <Icon type="unordered-list" />
                            ) : null
                        }
                        checked={isCalendarVisible}
                        onChange={toggleCalendarVisible}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => showAppointmentForm()}
                    >
                        <Icon type="plus" /> Create Appointment
                    </Button>
                </Col>
            </Row>
            {isCalendarVisible ? (
                <AppointmentCalendar
                    breakpoint={breakpoint}
                    newAppointment={newCalendarAppointment}
                    onCalendarEventClick={showAppointmentDetails}
                />
            ) : (
                <div>
                    <Row
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: 16 }}
                    >
                        <Col>
                            <DatePicker
                                format="dddd, MMMM DD, YYYY"
                                allowClear={false}
                                style={{
                                    width: breakpoint.isNormalMobile
                                        ? "calc(100vw - 78px)"
                                        : 270
                                }}
                                defaultValue={moment(selectedDate)}
                                value={moment(selectedDate)}
                                onChange={handleDateChange}
                            />
                            <ButtonGroup
                                style={{
                                    marginLeft: 5
                                }}
                            >
                                <Tooltip title="Previous Date">
                                    <Button
                                        icon="left"
                                        onClick={() =>
                                            handleDateChange(
                                                moment(selectedDate).subtract(
                                                    1,
                                                    "day"
                                                )
                                            )
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title="Next Date">
                                    <Button
                                        icon="right"
                                        onClick={() =>
                                            handleDateChange(
                                                moment(selectedDate).add(
                                                    1,
                                                    "day"
                                                )
                                            )
                                        }
                                    />
                                </Tooltip>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <div
                                style={{
                                    marginTop: breakpoint.isNormalMobile
                                        ? 10
                                        : 5,
                                    marginLeft: 10
                                }}
                            >
                                <span>
                                    Total Appointment:{" "}
                                    <Badge
                                        count={appointments.length}
                                        style={{
                                            backgroundColor: "#673AB7",
                                            color: "#fff",
                                            marginRight: 10
                                        }}
                                    />
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <div
                        style={{
                            backgroundColor: "#fff"
                        }}
                    >
                        <Table
                            columns={appointmentTableColumns}
                            dataSource={appointments}
                            loading={isAppointmentsTableLoading}
                            // pagination={{
                            //     pageSize: 40,
                            //     showTotal: (total, range) =>
                            //         `${range[0]}-${range[1]} of ${total} items`,
                            // }}
                            pagination={false}
                            rowKey="appointment_id"
                            onRow={record => ({
                                onClick: () => {
                                    showAppointmentDetails(record);
                                }
                            })}
                        />
                    </div>
                </div>
            )}

            <Drawer
                title={
                    selectedAppointment
                        ? "Edit Appointment"
                        : "Create Appointment"
                }
                placement="right"
                width={breakpoint.isNormalMobile ? 320 : 400}
                destroyOnClose={true}
                onClose={toggleAppointmentFormVisible}
                visible={isAppointmentFormVisible}
            >
                <AppointmentForm
                    data={selectedAppointment}
                    caseID={null}
                    breakpoint={breakpoint}
                    onSubmit={handleAppointmentFormSubmit}
                />
            </Drawer>

            <ModalDetails
                visible={isAppointmentDetailsVisible}
                width={700}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleAppointmentDetailsVisible}
            >
                <PatientAppointmentDetails
                    selectedAppointment={selectedAppointment}
                    breakpoint={breakpoint}
                />
            </ModalDetails>
        </div>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    doctors: state.UserDetails.doctors.filter(
        doctor => doctor.status === "active"
    ),
    appointmentListSettings: state.Settings.appointmentList
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
