import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Card, Result, Icon } from "antd";
import ContentLoader from "globalComponents/ContentLoader";
import RegisterForm from "./components/Forms/RegisterForm";

import logo from "assets/img/logo.png";
import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Register = ({ setRequestError, ...props }) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [isShowConfirmEmail, setIsShowConfirmEmail] = useState(false);
    const [aops, setAops] = useState([]);
    const [aopsSub, setAopsSub] = useState([]);
    const [registeredEmail, setRegisteredEmail] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`${apiUrl}/aopSubAop`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                });

                const { aops, aopsSub } = response.data;
                setAops(aops);
                setAopsSub(aopsSub);

                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };
        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleSubmit = async email => {
        setRegisteredEmail(email);
        setIsShowConfirmEmail(true);
    };

    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={styles.layout}
            >
                <Col xl={4} lg={1} md={1} />
                <Col xl={16} lg={22} md={22}>
                    <Card style={styles.card}>
                        {isShowConfirmEmail ? (
                            <Result
                                icon={<Icon type="mail" />}
                                status="success"
                                title="Confirm Your Email"
                                subTitle={`Please check your inbox (${registeredEmail}) for a confirmation email. Click the link in the email to confirm your email address`}
                                // extra={[
                                //     <Button type="primary" key="resend">
                                //         Resend
                                //     </Button>
                                // ]}
                            />
                        ) : (
                            <>
                                <div style={styles.logoContainer}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={styles.logo}
                                    />
                                    <h2>Doctor&rsquo;s Registration Form</h2>
                                </div>
                                <div style={styles.loginContent}>
                                    {isContentLoading ? (
                                        <ContentLoader />
                                    ) : (
                                        <>
                                            <RegisterForm
                                                plan={
                                                    props.match.params.plan ===
                                                    "basic"
                                                        ? "basic"
                                                        : props.match.params
                                                              .plan ===
                                                          "standard"
                                                        ? "standard"
                                                        : "free"
                                                }
                                                aops={aops}
                                                aopsSub={aopsSub}
                                                onSuccess={handleSubmit}
                                            />
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: 15
                                                }}
                                            >
                                                Already have an account?{" "}
                                                <Link to="/login">Sign In</Link>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </Card>
                </Col>
                <Col xl={4} lg={1} md={1} />
            </Row>
        </Layout>
    );
};

const styles = {
    layout: {
        height: "100vh"
    },
    logo: {
        width: 100,
        height: 100
    },
    logoContainer: {
        display: "block",
        textAlign: "center"
    },
    loginFooter: {
        marginTop: 20,
        textAlign: "center"
    },
    card: {
        width: "100%"
    },
    loginContent: {
        // maxHeight: 320,
        // overflowY: "auto",
        // overflowX: "hidden"
    }
};

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(null, mapDispatchToProps)(Register);
