import React, { useState } from "react";
import { Table, Input, Button, Icon, Tag } from "antd";
import ActionMenu from "globalComponents/ActionMenu";
import Highlighter from "react-highlight-words";
import { findUserName } from "helpers/userNameFinder";

const SavedCaseNotesList = ({ savedCaseNotes, userID, onUse, onDelete }) => {
    // antd default search table default
    const [searchText, setSearchText] = useState("");
    let searchInput = React.createRef();

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };
    // end of antd default

    const handleCaseNoteUse = selectedRecord => {
        onUse(selectedRecord);
    };

    const handleCaseNoteDelete = selectedRecord => {
        onDelete(selectedRecord);
    };

    const savedCaseNoteTableColumns = [
        {
            title: "Note Name",
            dataIndex: "name",
            ...getColumnSearchProps("name"),
            render: (text, record) => {
                return (
                    <div>
                        <div>
                            <strong>{record.name}</strong>
                        </div>
                        <div style={{ marginTop: 3 }}>
                            <Tag>{findUserName(record.user_id)}</Tag>
                        </div>
                    </div>
                );
            }
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => {
                const menu = [
                    {
                        label: "Use",
                        icon: "check",
                        onClick: () => handleCaseNoteUse(record)
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        onClick: () =>
                            handleCaseNoteDelete(record.saved_case_note_id)
                    }
                ];

                if (record.user_id !== userID) {
                    menu.splice(1, 1);
                }

                return (
                    <ActionMenu menu={menu} layout="compress" size="small" />
                );
            }
        }
    ];

    return (
        <Table
            columns={savedCaseNoteTableColumns}
            dataSource={savedCaseNotes}
            rowKey="saved_case_note_id"
        />
    );
};

export default SavedCaseNotesList;
