import React, { useState } from "react";
import { Row, Col, Tag, Divider, Skeleton, Card, Icon } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import PatientCard from "globalComponents/PatientCard";
import { findUserName } from "helpers/userNameFinder";

const AppoinmentDetails = ({
    selectedAppointment,
    breakpoint,
    appointmentListSettings,
}) => {
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const handleRequestDone = () => {
        setIsDetailsVisible(true);
    };

    const Details = () => {
        const {
            details,
            appointment_datetime,
            doctor_id,
            user_id,
            status,
            is_from_cwapp,
            created_at,
            updated_at,
        } = selectedAppointment;

        let statusColor = "";
        switch (status) {
            case "pending":
                statusColor = appointmentListSettings.pendingStatusColor;
                break;
            case "canceled":
                statusColor = appointmentListSettings.canceledStatusColor;
                break;
            case "confirmed":
                statusColor = appointmentListSettings.confirmedStatusColor;
                break;
            default:
                statusColor = "#ccc";
        }

        return (
            <div style={{ marginTop: 20 }}>
                <h3>Details:</h3>
                <p>{details}</p>
                {is_from_cwapp ? (
                    <p>
                        <Tag color="purple">
                            <Icon type="pushpin" /> From Patient Portal
                        </Tag>
                    </p>
                ) : null}
                <Divider />
                <table cellPadding={3}>
                    <tbody>
                        <tr>
                            <td style={{ width: 100 }}>Date:</td>
                            <td>
                                {moment(appointment_datetime).format(
                                    "MMMM DD, YYYY h:mm: A"
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Doctor:</td>
                            <td>{findUserName(doctor_id)}</td>
                        </tr>
                        <tr>
                            <td>Created By:</td>
                            <td>{findUserName(user_id)}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>
                                <Tag color={statusColor}>{status}</Tag>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Divider />
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: 100 }}>Date Created:</td>
                            <td>
                                {moment(created_at).format("MMMM DD, YYYY")}
                            </td>
                        </tr>
                        <tr>
                            <td>Last Update:</td>
                            <td>
                                {moment(updated_at).format("MMMM DD, YYYY")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            {!isDetailsVisible && <Skeleton active />}
            <div style={{ display: isDetailsVisible ? "block" : "none" }}>
                <Row>
                    <Col xl={12} lg={12} md={12}>
                        <PatientCard
                            patientID={selectedAppointment.patient_id}
                            moreDetailsVisible={true}
                            bordered={false}
                            breakpoint={breakpoint}
                            onRequestDone={handleRequestDone}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: breakpoint.isNormalMobile ? 0 : 24,
                            }}
                        >
                            <Details />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    appointmentListSettings: state.Settings.appointmentList,
});

export default connect(mapStateToProps)(AppoinmentDetails);
