import React, { useState, useEffect } from "react";

import { Layout, Row, Col, Card, Button, Result, Icon, Alert } from "antd";
import http from "services/httpService";
import { apiUrl } from "config.json";
import logo from "assets/img/logo.png";

const EmailVerified = props => {
    const [isAccountCreating, setIsAccountCreating] = useState(true);
    const [isCreatingAccountError, setIsCreatingAccountError] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    useEffect(() => {
        const createAccount = async () => {
            try {
                const verificationID = props.match.params.verification_id;
                const response = await http.post(
                    `${apiUrl}/createAccount/${verificationID}`
                );
                setIsAccountCreating(false);
                if (response.data.isNewUser) {
                    setIsNewUser(true);
                } else {
                    setIsNewUser(false);
                }
            } catch (error) {
                setIsCreatingAccountError(true);
            }
        };

        createAccount();
    }, []);

    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={styles.layout}
            >
                <Col xl={4} lg={1} md={1} />
                <Col xl={16} lg={22} md={22}>
                    <Card style={styles.card}>
                        {isAccountCreating ? (
                            <div style={{ textAlign: "center" }}>
                                <div>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            width: 100,
                                            height: 100
                                        }}
                                    />
                                </div>
                                <h1>Clinic Web Assistant</h1>
                                <div style={{ marginTop: 40 }}>
                                    {isCreatingAccountError ? (
                                        <Alert
                                            message="Failed to create account."
                                            type="error"
                                        />
                                    ) : (
                                        <div>
                                            <Icon
                                                style={{ fontSize: "2rem" }}
                                                type="setting"
                                                theme="twoTone"
                                                spin
                                            />
                                            <br />
                                            <br />
                                            Setting up your account... Please
                                            wait.
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : !isNewUser ? (
                            <div style={{ textAlign: "center" }}>
                                <div>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            width: 100,
                                            height: 100
                                        }}
                                    />
                                </div>
                                <h1>Clinic Web Assistant</h1>
                                <div style={{ marginTop: 40 }}>
                                    <Alert
                                        message="Account not found."
                                        type="error"
                                    />
                                </div>
                            </div>
                        ) : (
                            <Result
                                status="success"
                                title="Your account has been created!"
                                extra={[
                                    <Button
                                        type="link"
                                        key="resend"
                                        onClick={() =>
                                            props.history.push("/login")
                                        }
                                    >
                                        Go to Login Page
                                    </Button>
                                ]}
                            />
                        )}
                    </Card>
                </Col>
                <Col xl={4} lg={1} md={1} />
            </Row>
        </Layout>
    );
};

const styles = {
    layout: {
        height: "100vh"
    },
    logo: {
        width: 100,
        height: 100
    },
    logoContainer: {
        display: "block",
        textAlign: "center"
    },
    loginFooter: {
        marginTop: 20,
        textAlign: "center"
    },
    card: {
        width: "100%"
    },
    loginContent: {
        // maxHeight: 320,
        // overflowY: "auto",
        // overflowX: "hidden"
    }
};

export default EmailVerified;
