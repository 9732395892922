import React from "react";
import { Row, Col, Typography } from "antd";
import ActionMenu from "globalComponents/ActionMenu";

const ServiceList = ({ services, readOnly: isReadOnly, onRemove }) => {
    const { Title } = Typography;

    const handleServiceRemove = (serviceID, serviceName) => {
        onRemove(serviceID, serviceName);
    };

    return (
        <>
            {services.length !== 0 && (
                <div style={{ marginBottom: 24 }}>
                    <Title level={4}>Services</Title>
                    <div style={{ marginTop: 5, marginLeft: 5 }}>
                        {services.map((service, index) => (
                            <Row
                                key={service.item_id}
                                style={{ marginBottom: 8 }}
                            >
                                <Col span={22}>&ndash; {service.name}</Col>
                                <Col span={2}>
                                    <div style={{ textAlign: "right" }}>
                                        {!isReadOnly && (
                                            <ActionMenu
                                                menu={[
                                                    {
                                                        label: "Remove",
                                                        icon: "delete",
                                                        onClick: () =>
                                                            handleServiceRemove(
                                                                service.item_id,
                                                                service.name
                                                            ),
                                                    },
                                                ]}
                                                size="small"
                                                layout="compress"
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ServiceList;
