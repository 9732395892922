import React from "react";
import { Link } from "react-router-dom";
import { Card, Divider, Icon } from "antd";

const ActivityLog = () => {
    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider /> <div>SOON</div>
        </Card>
    );
};

export default ActivityLog;
