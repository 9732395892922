import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Form, Input, InputNumber, Button, message, Select } from "antd";
import { findUserName } from "helpers/userNameFinder";

const PaperForm = ({ data, onSubmit, clinicID, doctors }) => {
    const [formData, setFormData] = useState({
        name: "",
        category: "",
        layout: "",
        is_customized: 0,
        other_info: "",
        print_available: 0,
        price: 0,
        clinic_id: clinicID,
        doctor_id: "",
        status: "active",
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        category: "",
        doctor_id: "",
    });

    const [isFormDisbled, setIsFormDisabled] = useState(false);

    const { Option } = Select;
    const { TextArea } = Input;

    const txtPaperName = React.createRef();

    useEffect(() => {
        if (data) {
            setFormData(data);
            setIsFormDisabled(true);
        } else {
            if (doctors.length === 1) {
                setFormData({
                    ...formData,
                    doctor_id: doctors[0].doctor_id,
                });
            }
        }
        txtPaperName.current.input.focus();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const handleSelectBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const handlePriceChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDoctorChange = (value, option) => {
        let errors = { ...formErrors };
        if (value !== "") {
            errors.doctor_id = "";
            setFormErrors(errors);
        }

        setFormData({
            ...formData,
            doctor_id: value,
        });
    };

    const validateInput = () => {
        const { name, category, doctor_id } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Paper name is required";
        }

        if (category.trim() === "") {
            errors.category = "Paper category is required";
        }

        if (doctor_id === "") {
            errors.doctor_id = "Doctor is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <Form
            layout="vertical"
            labelCol={{
                xl: { span: 8 },
                lg: { span: 9 },
            }}
            wrapperCol={{
                xl: { span: 16 },
                lg: { span: 15 },
            }}
            style={{ width: "100%" }}
        >
            <Form.Item
                label="Paper Name"
                required={true}
                hasFeedback
                validateStatus={formErrors.name && "error"}
                help={formErrors.name && formErrors.name}
            >
                <Input
                    name="name"
                    autoComplete="off"
                    ref={txtPaperName}
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item
                label="Category"
                required={true}
                hasFeedback
                validateStatus={formErrors.category && "error"}
                help={formErrors.category && formErrors.category}
            >
                <Select
                    value={formData.category}
                    onChange={(value) => handleSelectChange("category", value)}
                    onBlur={(value) => handleSelectBlur("category", value)}
                    disabled={isFormDisbled}
                >
                    <Option value="Prescription">Prescription</Option>
                    <Option value="Certificate">Certificate</Option>
                    <Option value="Lab Request">Lab Request</Option>
                    <Option value="Radiology Request">Radiology Request</Option>
                    <Option value="Other Request">Other Request</Option>
                    <Option value="Referral">Referral</Option>
                    <Option value="Special Instruction">
                        Special Instruction
                    </Option>
                    <Option value="Others">Others</Option>
                    <Option value="Invoice">Invoice</Option>
                </Select>
            </Form.Item>
            {(formData.category === "Lab Request" ||
                formData.category === "Radiology Request" ||
                formData.category === "Other Request") && (
                <Form.Item label="Requests">
                    <TextArea
                        name="other_info"
                        value={formData.other_info}
                        onChange={handleInputChange}
                        rows={4}
                        placeholder={`Enter your ${formData.category} requests here and separate it by new line`}
                    />
                </Form.Item>
            )}
            {doctors.length > 1 && (
                <Form.Item
                    label="Doctor"
                    required
                    hasFeedback
                    validateStatus={formErrors.doctor_id && "error"}
                    help={formErrors.doctor_id && formErrors.doctor_id}
                >
                    <Select
                        value={formData.doctor_id}
                        placeholder="Select Doctor"
                        onChange={(value, option) =>
                            handleDoctorChange(value, option)
                        }
                        disabled={isFormDisbled}
                    >
                        {doctors.map((doctor) => (
                            <Option
                                key={doctor.doctor_id}
                                value={doctor.doctor_id}
                            >
                                {findUserName(doctor.doctor_id)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {formData.category === "Certificate" && (
                <Form.Item label="Price">
                    <InputNumber
                        name="price"
                        value={formData.price}
                        onChange={(value) => handlePriceChange("price", value)}
                        placeholder="Enter price"
                    />
                </Form.Item>
            )}

            <Form.Item label=" ">
                <Button type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
});

export default connect(mapStateToProps)(PaperForm);
