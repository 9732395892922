import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    Avatar,
    Badge,
    Menu,
    Dropdown,
    Button,
    Row,
    Col,
    Typography,
    Spin,
    message,
} from "antd";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

import ModalDetails from "globalComponents/ModalDetails";

import { batchInsertQueryFormatter } from "helpers/batchInsertQueryFormatter";
import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

const Notification = ({
    breakpoint,
    userID,
    clinicID,
    plan,
    notificationsSettings,
    ...props
}) => {
    const { Title, Text } = Typography;

    const [isLoadingNotification, setIsLoadingNotification] = useState(true);

    const [topNotifications, setTopNotifications] = useState([]);
    const [displayedNotifications, setDisplayedNotifications] = useState([]);
    const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);

    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const notificationResponse = await http.get(
                    `${apiUrl}/notifications/${clinicID}/${userID}`
                );

                let {
                    notifications,
                    openedNotifications,
                } = notificationResponse.data;

                openedNotifications = openedNotifications.map(
                    (openedNotification) => openedNotification.notification_id
                );
                let openedBatchNotifications = notificationResponse.data
                    .openedBatchNotifications
                    ? JSON.parse(
                          notificationResponse.data.openedBatchNotifications
                              .notifications
                      )
                    : [];

                //appointment notification
                if (notificationsSettings.appointmentCount) {
                    const appointmentResponse = await http.get(
                        `${apiUrl}/appointment/notification/${clinicID}`
                    );
                    const {
                        appointmentNotificationData,
                        isAddApptNotifications,
                    } = appointmentResponse.data;
                    if (isAddApptNotifications) {
                        notifications.push({
                            ...appointmentNotificationData,
                            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }
                }

                //low stock notification
                if (
                    notificationsSettings.lowStockItemCount &&
                    plan !== "basic"
                ) {
                    const itemResponse = await http.get(
                        `${apiUrl}/item/notification/${clinicID}`
                    );

                    const {
                        itemNotificationData,
                        isAddItemNotifications,
                    } = itemResponse.data;
                    if (isAddItemNotifications) {
                        notifications.push({
                            ...itemNotificationData,
                            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }
                }

                //sort by datetime
                notifications = notifications.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                //select top 20 only
                let topNotifications = notifications.slice(0, 20);
                topNotifications = topNotifications.map((topNotification) => ({
                    ...topNotification,
                    is_read:
                        openedNotifications.indexOf(
                            topNotification.notification_id
                        ) === -1
                            ? 0
                            : 1,
                }));

                //count unread notification using data of opened_batch_notifications table
                const unreadNotifCount = topNotifications.filter(
                    (topNotif) =>
                        openedBatchNotifications.indexOf(
                            topNotif.notification_id
                        ) === -1
                ).length;

                //select top 3 to be displayed
                let displayedNotifications = [...topNotifications];
                displayedNotifications = displayedNotifications.splice(0, 3);
                setTopNotifications(topNotifications);
                setDisplayedNotifications(displayedNotifications);
                setTotalUnreadNotifications(unreadNotifCount);
                setIsLoadingNotification(false);
            } catch (error) {
                message.error(
                    error.message ||
                        "Something went wrong while fetching notifications. :("
                );
                setIsLoadingNotification(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        socket.on("newNotification", function (data) {
            let newTopNotifications = [
                {
                    ...data,
                    is_read: 0,
                    created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                },
                ...topNotifications,
            ];
            newTopNotifications = newTopNotifications.splice(0, 20);
            let displayedNotifications = [...newTopNotifications];
            displayedNotifications = displayedNotifications.splice(0, 3);

            setTopNotifications(newTopNotifications);
            setDisplayedNotifications(displayedNotifications);
            setTotalUnreadNotifications(totalUnreadNotifications + 1);
        });

        return () => {
            socket.off("newNotification");
        };
    }, [topNotifications, displayedNotifications, totalUnreadNotifications]);

    const toggleModalVisible = () => {
        setIsModalVisible(!isModalVisible);
    };

    const showModal = (selectedNotification) => {
        setSelectedNotification(selectedNotification);
        toggleModalVisible();
    };

    const handleNotificationVisibleChange = async (visible) => {
        if (visible) {
            //to update time ago
            const newDisplayNotifications = displayedNotifications.map(
                (displayedNotification) => ({
                    ...displayedNotification,
                })
            );
            setDisplayedNotifications(newDisplayNotifications);
        }

        if (visible && totalUnreadNotifications !== 0) {
            try {
                const topNotifIds = topNotifications.map(
                    (topNotif) => topNotif.notification_id
                );

                setTotalUnreadNotifications(0);
                await http.post(`${apiUrl}/openedBatchNotifications`, {
                    user_id: userID,
                    notifications: JSON.stringify(topNotifIds),
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleNotificationClick = async (selectedNotification) => {
        const newTopNotifications = topNotifications.map((topNotification) => {
            if (
                topNotification.notification_id ===
                selectedNotification.notification_id
            ) {
                return {
                    ...topNotification,
                    is_read: 1,
                };
            }
            return topNotification;
        });

        let displayedNotifications = [...newTopNotifications];
        displayedNotifications = displayedNotifications.splice(0, 3);
        setTopNotifications(newTopNotifications);
        setDisplayedNotifications(displayedNotifications);

        switch (selectedNotification.show_in) {
            case "route":
                props.history.push(selectedNotification.content);
                break;
            case "url":
                window.open(selectedNotification.content);
                break;
            default:
                showModal(selectedNotification);
        }

        if (!selectedNotification.is_read) {
            //insert
            await http.post(`${apiUrl}/openedNotification`, {
                notification_id: selectedNotification.notification_id,
                user_id: userID,
            });
        }
    };

    const markAllAsRead = async () => {
        const unOpenedNotifications = topNotifications.filter(
            (topNotification) => topNotification.is_read === 0
        );
        if (unOpenedNotifications.length !== 0) {
            const newTopNotifications = topNotifications.map(
                (topNotification) => ({
                    ...topNotification,
                    is_read: 1,
                })
            );
            let displayedNotifications = [...newTopNotifications];
            displayedNotifications = displayedNotifications.splice(0, 3);
            setTopNotifications(newTopNotifications);
            setDisplayedNotifications(displayedNotifications);

            const unOpenedNotificationIds = unOpenedNotifications.map(
                (unOpenedNotification) => ({
                    user_id: userID,
                    notification_id: unOpenedNotification.notification_id,
                })
            );

            try {
                await http.post(
                    `${apiUrl}/markAllAsRead`,
                    batchInsertQueryFormatter(unOpenedNotificationIds)
                );
            } catch (error) {
                console.log(error);
            }
        }
    };

    const menu = isLoadingNotification ? (
        <Menu>
            <Spin spinning={true}>
                <div style={{ padding: "20px 0", textAlign: "center" }}>
                    <Avatar size={64} icon="bell" />
                </div>
            </Spin>
        </Menu>
    ) : (
        <Menu>
            <div style={{ padding: "10px 20px" }}>
                <Row type="flex" justify="space-between">
                    <Col>
                        <h3 style={{ marginBottom: 0 }}>Notifications</h3>
                    </Col>
                    <Col>
                        <Button
                            size="small"
                            type="link"
                            onClick={markAllAsRead}
                        >
                            Mark all as read
                        </Button>
                    </Col>
                </Row>
            </div>
            <Menu.Divider />
            {displayedNotifications.map((notification) => {
                const {
                    notification_id,
                    icon,
                    avatar_bg_color,
                    title,
                    description,
                    created_at,
                    is_read,
                } = notification;

                return (
                    <Menu.Item
                        key={notification_id}
                        style={{ borderBottom: "1px solid #e8e8e8" }}
                        onClick={() => handleNotificationClick(notification)}
                    >
                        <Row type="flex" gutter={12}>
                            <Col>
                                <Avatar
                                    icon={icon}
                                    style={{
                                        backgroundColor: is_read
                                            ? "#ccc"
                                            : avatar_bg_color,
                                        fontSize: "1.1rem",
                                    }}
                                />
                            </Col>
                            <Col>
                                <div>
                                    <div>
                                        {is_read ? (
                                            <Text type="secondary">
                                                {title}
                                            </Text>
                                        ) : (
                                            <strong>{title}</strong>
                                        )}
                                    </div>
                                    <div>
                                        {is_read ? (
                                            <Text type="secondary">
                                                {description}
                                            </Text>
                                        ) : (
                                            description
                                        )}
                                    </div>
                                    <div>
                                        {is_read ? (
                                            <Text type="secondary">
                                                {moment(created_at).fromNow()}
                                            </Text>
                                        ) : (
                                            moment(created_at).fromNow()
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Menu.Item>
                );
            })}
            <Menu.Item onClick={() => props.history.push("/notifications")}>
                <div style={{ textAlign: "center" }}>
                    <Button type="link">See all</Button>
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
                overlayStyle={{
                    width: breakpoint.isNormalMobile ? "100%" : 300,
                }}
                onVisibleChange={handleNotificationVisibleChange}
            >
                <Button
                    style={{
                        height: 55,
                        border: "none",
                    }}
                >
                    <Badge count={totalUnreadNotifications} overflowCount={3}>
                        <Avatar
                            icon="bell"
                            style={{
                                fontSize: "1.2rem",
                                color: "#595959",
                                backgroundColor: "#fff",
                            }}
                            className="cursorPointer"
                        />
                    </Badge>
                </Button>
            </Dropdown>
            <ModalDetails
                visible={isModalVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleModalVisible}
            >
                {selectedNotification && (
                    <div style={{ padding: 20 }}>
                        <div style={{ textAlign: "center", marginBottom: 20 }}>
                            <Avatar
                                size={64}
                                icon={selectedNotification.icon}
                                style={{
                                    backgroundColor:
                                        selectedNotification.avatar_bg_color,
                                }}
                            />
                        </div>
                        <Title level={4}>{selectedNotification.title}</Title>
                        {ReactHtmlParser(selectedNotification.content)}
                    </div>
                )}
            </ModalDetails>
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    plan: state.UserDetails.subscriptionDetails.plan,
    notificationsSettings: state.Settings.notifications,
});

export default withRouter(connect(mapStateToProps)(Notification));
