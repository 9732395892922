import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Skeleton,
    Table,
    Row,
    Col,
    Descriptions,
    Card,
    Typography,
    Form,
    Input,
    InputNumber,
    Select,
    Button,
    message,
} from "antd";

import http from "services/httpService";
import { formatNumberWithSign } from "helpers/numberFormatter";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PaymentForm = (props) => {
    const { patientBillData, onSubmit, breakpoint, userID, clinicID } = props;

    const [isLoadingBillItems, setIsLoadingBillItems] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [billItems, setBillItems] = useState([]);

    const [formData, setFormData] = useState({
        payment_date: "",
        payment_amount: null,
        payment_type: "cash",
        payment_notes: "",
        patient_id: patientBillData.patient_id,
        bill_id: patientBillData.bill_id,
        previous_balance: patientBillData.amount_balance,
        remaining_balance: patientBillData.amount_balance,
        clinic_id: clinicID,
        user_id: userID,
        amountPaid: patientBillData.amount_paid,
    });
    const [formErrors, setFormErrors] = useState({
        payment_amount: "",
    });

    const { Title } = Typography;
    const { Option } = Select;
    const { TextArea } = Input;

    useEffect(() => {
        const fetchItems = async () => {
            try {
                setIsLoadingBillItems(true);
                const response = await http.get(
                    `${apiUrl}/bill/items/${patientBillData.bill_id}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                setBillItems(response.data.billItems);
                setIsLoadingBillItems(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error(error.message);
                }
            }
        };

        if (patientBillData.billItems) {
            setBillItems(patientBillData.billItems);
        } else {
            fetchItems();
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAmountChange = (e) => {
        if (e !== null) {
            let value = e.toString().replace(/[^0-9.,]/g, "");
            setFormData({
                ...formData,
                payment_amount: value,
                remaining_balance:
                    patientBillData.amount_balance - Number(value),
            });
        } else {
            setFormData({
                ...formData,
                payment_amount: null,
                remaining_balance: patientBillData.amount_balance,
            });
        }
    };

    const handleBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { payment_amount, remaining_balance } = formData;

        let errors = {};

        if (payment_amount === "" || Number(payment_amount) === 0) {
            errors.payment_amount = "Payment amount is required!";
        }

        if (Number(remaining_balance) < 0) {
            errors.payment_amount =
                "Bill balance must be greater or equal zero!";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            setIsPaymentLoading(true);
            onSubmit({ ...formData, status: patientBillData.status });
        }
    };

    const billItemsTableColumns = [
        {
            title: "Item",
            dataIndex: "name",
        },
        {
            title: "Cost",
            dataIndex: "cost",
            render: (text) => formatNumberWithSign(text),
        },
        {
            title: "Qty",
            dataIndex: "qty",
            key: "qty",
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (text) => formatNumberWithSign(text),
        },
    ];

    const billItemsTableColumnsMobile = [
        {
            title: "Items",
            key: "items",
            render: (text, record) => {
                const { name, cost, qty, price } = record;
                return (
                    <>
                        <div>
                            <span>
                                <strong>{name}</strong> {qty} x {cost}
                            </span>
                        </div>
                        <div> {formatNumberWithSign(price)}</div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {isLoadingBillItems ? (
                <Skeleton active />
            ) : (
                <div>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card
                                hoverable
                                cover={
                                    <div
                                        style={{
                                            backgroundColor: "#1890ff",
                                            color: "#fff",
                                            textAlign: "center",
                                            padding: "30px 0",
                                        }}
                                    >
                                        <div>Remaining Balance</div>
                                        <div
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "1.5rem",
                                            }}
                                        >
                                            {formatNumberWithSign(
                                                formData.remaining_balance
                                            )}
                                        </div>
                                    </div>
                                }
                            >
                                <Descriptions bordered size="small" column={1}>
                                    <Descriptions.Item label="Item Count">
                                        {patientBillData.item_count}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Sub Total">
                                        {formatNumberWithSign(
                                            patientBillData.sub_total
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Discount">
                                        {formatNumberWithSign(
                                            patientBillData.discount
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Grand Total">
                                        {formatNumberWithSign(
                                            patientBillData.grand_total
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Amount Paid">
                                        {formatNumberWithSign(
                                            patientBillData.amount_paid
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="HMO Covered">
                                        {formatNumberWithSign(
                                            patientBillData.hmo_amount_covered
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Card style={{ marginTop: 4 }}>
                                <Title level={4}>Payment Details</Title>
                                <Form>
                                    <Form.Item
                                        label="Enter Amount"
                                        required
                                        hasFeedback
                                        validateStatus={
                                            formErrors.payment_amount && "error"
                                        }
                                        help={
                                            formErrors.payment_amount &&
                                            formErrors.payment_amount
                                        }
                                    >
                                        <InputNumber
                                            name="payment_amount"
                                            style={{ width: "100%" }}
                                            value={formData.payment_amount}
                                            formatter={(value) =>
                                                `${value}`
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )
                                                    .replace(/[^0-9.,]/g, "")
                                            }
                                            autoFocus
                                            size="large"
                                            placeholder="Enter amount"
                                            onChange={handleAmountChange}
                                            onBlur={handleBlur}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Payment Type">
                                        <Select
                                            defaultValue="cash"
                                            value={formData.payment_type}
                                            onChange={(value) =>
                                                handleChange(
                                                    "payment_type",
                                                    value
                                                )
                                            }
                                        >
                                            <Option value="cash">Cash</Option>
                                            <Option value="check">Check</Option>
                                            <Option value="creditcard">
                                                Credit Card
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Payment Notes">
                                        <TextArea
                                            rows={2}
                                            value={formData.payment_notes}
                                            onChange={(e) =>
                                                handleChange(
                                                    "payment_notes",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        block
                                        style={{ marginTop: 7 }}
                                        size="large"
                                        onClick={handleSubmit}
                                        loading={isPaymentLoading}
                                    >
                                        {isPaymentLoading
                                            ? "Processing"
                                            : "Pay Now"}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                    <div
                        style={{
                            marginTop: 15,
                        }}
                    >
                        <Card title="Details">
                            <Table
                                columns={
                                    breakpoint.isNormalMobile
                                        ? billItemsTableColumnsMobile
                                        : billItemsTableColumns
                                }
                                dataSource={billItems}
                                rowKey="bill_item_id"
                                pagination={false}
                                className="reportsTable"
                            />
                        </Card>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    //items: state.Items,
    //userName: `${state.UserDetails.firstname} ${state.UserDetails.lastname}`,
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

export default connect(mapStateToProps)(PaymentForm);
