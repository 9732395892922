import {
    SET_USER_DETAILS_SUCCESS,
    ADD_DOCTOR_SUCCESS,
    EDIT_DOCTOR_SUCCESS,
    EDIT_DOCTOR_PREVILEGES,
    DELETE_DOCTOR_SUCCESS,
    ADD_STAFF_SUCCESS,
    EDIT_STAFF_SUCCESS,
    DELETE_STAFF_SUCCESS,
    EDIT_CLINIC_SUCCESS,
    EDIT_USER_DETAILS_SUCCESS,
} from "./userDetails.types";

export const setUserDetails = (userDetails) => async (dispatch) => {
    dispatch({
        type: SET_USER_DETAILS_SUCCESS,
        payload: { userDetails },
    });
};

export const addDoctor = (newDoctor) => async (dispatch) => {
    dispatch({
        type: ADD_DOCTOR_SUCCESS,
        payload: { newDoctor },
    });
};

export const editDoctor = (editedDoctor) => async (dispatch) => {
    dispatch({
        type: EDIT_DOCTOR_SUCCESS,
        payload: { editedDoctor },
    });
};

export const editDoctorPrevileges = (doctorID, newPrevileges) => async (
    dispatch
) => {
    dispatch({
        type: EDIT_DOCTOR_PREVILEGES,
        payload: { doctorID, newPrevileges },
    });
};

export const deleteDoctor = (doctorID) => async (dispatch) => {
    dispatch({
        type: DELETE_DOCTOR_SUCCESS,
        payload: { doctorID },
    });
};

export const addStaff = (newStaff) => async (dispatch) => {
    dispatch({
        type: ADD_STAFF_SUCCESS,
        payload: { newStaff },
    });
};

export const editStaff = (editedStaff) => async (dispatch) => {
    dispatch({
        type: EDIT_STAFF_SUCCESS,
        payload: { editedStaff },
    });
};

export const deleteStaff = (staffID) => async (dispatch) => {
    dispatch({
        type: DELETE_STAFF_SUCCESS,
        payload: { staffID },
    });
};

export const editClinic = (editedClinic) => async (dispatch) => {
    dispatch({
        type: EDIT_CLINIC_SUCCESS,
        payload: { editedClinic },
    });
};

export const editUserDetails = (editedUserDetails) => async (dispatch) => {
    dispatch({
        type: EDIT_USER_DETAILS_SUCCESS,
        payload: { editedUserDetails },
    });
};
