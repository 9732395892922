import React, { useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";

const ChatForm = (props) => {
    const {
        currentUserID,
        clinicID,
        onPressShiftEnter,
        receiverID,
        onSubmit,
    } = props;

    const [formData, setFormData] = useState({
        message: "",
        sender_id: currentUserID,
        receiver_id: receiverID,
        is_read: 0,
        type: "p2p",
        clinic_id: clinicID,
    });

    const { TextArea } = Input;
    const InputGroup = Input.Group;
    const ButtonGroup = Button.Group;

    const handleChange = (e) => {
        onPressShiftEnter(e.target.value);
        setFormData({
            ...formData,
            message: e.target.value,
        });
    };

    const handlePressEnter = (e) => {
        if (e.key === "Enter" && e.shiftKey) {
            onPressShiftEnter(e.target.value);
        } else if (e.keyCode === 13 && !e.shiftKey) {
            handleSubmit();
            e.preventDefault();
            return false;
        }
    };

    const handleSubmit = () => {
        const message = formData.message.trim();
        if (message !== "") {
            onSubmit({ ...formData, message });
            setFormData({ ...formData, message: "" });
            onPressShiftEnter("");
        }
    };

    return (
        <>
            <div style={{ marginTop: 10, padding: "0 5px 10px 10px" }}>
                <Form>
                    <Form.Item>
                        <InputGroup>
                            <Row type="flex" align="bottom">
                                <Col span={/*16*/ 18}>
                                    <TextArea
                                        value={formData.message}
                                        autoSize={{ minRows: 1, maxRows: 4 }}
                                        style={{
                                            borderRadius: 0,
                                            resize: "none",
                                        }}
                                        onChange={handleChange}
                                        onPressEnter={handlePressEnter}
                                    />
                                </Col>
                                <Col span={6}>
                                    <ButtonGroup>
                                        {/* <Button type="link" icon="paper-clip" /> */}
                                        <Button
                                            type="primary"
                                            style={{
                                                borderRadius: 0,
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            Send
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </InputGroup>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default ChatForm;
