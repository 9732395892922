import {
    STREAM_CONTENT_VISIBLE_CHANGE_SUCCESS,
    SET_VIDEO_CALL_DETAILS_SUCCESS,
} from "./videoCaller.types";

export const setIsStreamContentVisible = (isVisible) => async (dispatch) => {
    dispatch({
        type: STREAM_CONTENT_VISIBLE_CHANGE_SUCCESS,
        payload: { isVisible },
    });
};

export const setVideoCallDetails = (details) => async (dispatch) => {
    dispatch({
        type: SET_VIDEO_CALL_DETAILS_SUCCESS,
        payload: { details },
    });
};
