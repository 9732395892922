import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Form, Input, InputNumber, Button, message, Row, Col } from "antd";
import SelectWithCRUD from "../SelectWithCrud";
import ImageUploader from "globalComponents/Uploaders/ImageUploader";
import { resizeImgHelper } from "helpers/image";

const ItemForm = ({
    data,
    onSubmit,
    itemUnits,
    itemGroups,
    onItemUnitSubmit,
    onItemGroupSubmit,
    clinicID,
    ownerID,
    userID,
    plan,
}) => {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        generic_name: "",
        formulation: "",
        stock_qty: 0,
        reorder_qty: 0,
        price: 0,
        item_type: "product",
        item_unit_id: "",
        item_group_id: "",
        clinic_id: clinicID,
        user_id: userID,
        img_path: "",
        img_size: 0,
        status: "active",
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        stock_qty: "",
        reorder_qty: "",
        price: "",
        item_unit_id: "",
        item_group_id: "",
    });

    const [itemImg, setItemImg] = useState({
        origSizeImg: "",
        cardSizeImg: "",
        thumbnailSizeImg: "",
    });
    const [isItemImgChanged, setIsItemImgChanged] = useState(false);

    const [disabledInputs, setDisabledInputs] = useState({
        inputs: false,
        itemUnits: false,
        itemGroups: false,
    });

    //first element focus refs
    const txtItemName = React.createRef();

    useEffect(() => {
        if (data) {
            delete data.user_id;
            setFormData(data);

            // join image path
            let itemOrigSizeImg = data.img_path;
            if (itemOrigSizeImg) {
                let itemCardSizeImg = itemOrigSizeImg.replace(
                    ".jpeg",
                    "-card.jpeg"
                );
                let itemThumbnailSizeImg = itemOrigSizeImg.replace(
                    ".jpeg",
                    "-thumb.jpeg"
                );
                setItemImg({
                    origSizeImg: itemOrigSizeImg,
                    cardSizeImg: itemCardSizeImg,
                    thumbnailSizeImg: itemThumbnailSizeImg,
                });
            }
        }

        txtItemName.current.input.focus();
    }, []);

    const previewImage = (imageSrc) => {
        let origSizeImg = "";
        let cardSizeImg = "";
        let thumbnailSizeImg = "";

        resizeImgHelper(imageSrc, 50, "img/jpg", function (base64Img) {
            thumbnailSizeImg = base64Img;
            resizeImgHelper(imageSrc, 120, "img/jpg", function (base64Img) {
                cardSizeImg = base64Img;
                resizeImgHelper(imageSrc, 200, "img/jpg", function (base64Img) {
                    origSizeImg = base64Img;
                    setItemImg({
                        origSizeImg,
                        cardSizeImg,
                        thumbnailSizeImg,
                    });
                    setIsItemImgChanged(true);
                });
            });
        });
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputNumberChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const handleSelectWithCRUDBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value) {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const handleItemUnitsChange = (value) => {
        setFormData({
            ...formData,
            item_unit_id: value,
        });
    };

    const handleItemGroupsChange = (value) => {
        setFormData({
            ...formData,
            item_group_id: value,
        });
    };

    const handleItemUnitActionClick = (action) => {
        if (action === "addButtonClick" || action === "editButtonClick") {
            setDisabledInputs({
                inputs: true,
                itemUnits: false,
                itemGroups: true,
            });
        } else {
            setDisabledInputs({
                inputs: false,
                itemUnits: false,
                itemGroups: false,
            });
        }
    };

    const handleItemGroupActionClick = (action) => {
        if (action === "addButtonClick" || action === "editButtonClick") {
            setDisabledInputs({
                inputs: true,
                itemUnits: true,
                itemGroups: false,
            });
        } else {
            setDisabledInputs({
                inputs: false,
                itemUnits: false,
                itemGroups: false,
            });
        }
    };

    const validateInput = () => {
        const {
            name,
            stock_qty,
            reorder_qty,
            price,
            item_unit_id,
            item_group_id,
        } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Item name is required";
        }
        if (stock_qty === null) {
            errors.stock_qty = "Stock quantity is required";
        }
        if (reorder_qty === null) {
            errors.reorder_qty = "Reorder quantity is required";
        }
        if (price === null) {
            errors.price = "Item price is required";
        }
        if (!item_unit_id) {
            errors.item_unit_id = "Item unit is required";
        }
        if (!item_group_id) {
            errors.item_group_id = "Item group is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit({ ...formData, itemImg, isItemImgChanged });
        }
    };

    return (
        <>
            <Row>
                <Col lg={9}>
                    <ImageUploader
                        image={itemImg.origSizeImg}
                        previewImage={previewImage}
                    />
                </Col>
                <Col lg={15}>
                    <Form
                        layout="vertical"
                        labelCol={{
                            xl: { span: 8 },
                            lg: { span: 9 },
                        }}
                        wrapperCol={{
                            xl: { span: 16 },
                            lg: { span: 15 },
                        }}
                        style={{ width: "100%" }}
                    >
                        <Form.Item
                            label="Item Name"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.name && "error"}
                            help={formErrors.name && formErrors.name}
                        >
                            <Input
                                ref={txtItemName}
                                name="name"
                                autoComplete="off"
                                value={formData.name}
                                disabled={disabledInputs.inputs}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item label="Generic Name">
                            <Input
                                name="generic_name"
                                autoComplete="off"
                                value={formData.generic_name}
                                disabled={disabledInputs.inputs}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Description">
                            <Input
                                name="description"
                                autoComplete="off"
                                value={formData.description}
                                disabled={disabledInputs.inputs}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Formulation">
                            <Input
                                name="formulation"
                                autoComplete="off"
                                value={formData.formulation}
                                disabled={disabledInputs.inputs}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Item Unit"
                            required={true}
                            validateStatus={formErrors.item_unit_id && "error"}
                            help={
                                formErrors.item_unit_id &&
                                formErrors.item_unit_id
                            }
                        >
                            <SelectWithCRUD
                                data={itemUnits}
                                dataKey="item_unit_id"
                                selectedValue={
                                    formData.item_unit_id === 0
                                        ? null
                                        : formData.item_unit_id
                                }
                                apiRoute="itemUnit"
                                width={161}
                                disabled={disabledInputs.itemUnits}
                                onChange={handleItemUnitsChange} //when select is changing
                                onBlur={handleSelectWithCRUDBlur}
                                onActionClick={handleItemUnitActionClick}
                                onSubmit={onItemUnitSubmit} //source of truth modifier
                            />
                        </Form.Item>
                        {plan !== "basic" && (
                            <Form.Item
                                label="Stock Qty."
                                required={true}
                                hasFeedback
                                validateStatus={formErrors.stock_qty && "error"}
                                help={
                                    formErrors.stock_qty && formErrors.stock_qty
                                }
                            >
                                <InputNumber
                                    name="stock_qty"
                                    value={formData.stock_qty}
                                    formatter={(value) =>
                                        `${value}`
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                            .replace(/[^0-9.,]/g, "")
                                    }
                                    disabled={
                                        (disabledInputs.inputs || data) && true
                                    }
                                    onChange={(value) =>
                                        handleInputNumberChange(
                                            "stock_qty",
                                            value
                                        )
                                    }
                                    onBlur={handleInputBlur}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        )}
                        {plan !== "basic" && (
                            <Form.Item
                                label="Reorder Qty."
                                required={true}
                                hasFeedback
                                validateStatus={
                                    formErrors.reorder_qty && "error"
                                }
                                help={
                                    formErrors.reorder_qty &&
                                    formErrors.reorder_qty
                                }
                            >
                                <InputNumber
                                    name="reorder_qty"
                                    value={formData.reorder_qty}
                                    formatter={(value) =>
                                        `${value}`
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                            .replace(/[^0-9.,]/g, "")
                                    }
                                    disabled={disabledInputs.inputs}
                                    onChange={(value) =>
                                        handleInputNumberChange(
                                            "reorder_qty",
                                            value
                                        )
                                    }
                                    onBlur={handleInputBlur}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        )}
                        {plan !== "basic" && (
                            <Form.Item
                                label="Price"
                                required={true}
                                hasFeedback
                                validateStatus={formErrors.price && "error"}
                                help={formErrors.price && formErrors.price}
                            >
                                <InputNumber
                                    name="price"
                                    value={formData.price}
                                    formatter={(value) =>
                                        `${value}`
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )
                                            .replace(/[^0-9.,]/g, "")
                                    }
                                    disabled={disabledInputs.inputs}
                                    onChange={(value) =>
                                        handleInputNumberChange("price", value)
                                    }
                                    onBlur={handleInputBlur}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            label="Item Group"
                            required={true}
                            validateStatus={formErrors.item_group_id && "error"}
                            help={
                                formErrors.item_group_id &&
                                formErrors.item_group_id
                            }
                        >
                            <SelectWithCRUD
                                data={itemGroups}
                                dataKey="item_group_id"
                                selectedValue={
                                    formData.item_group_id === 0
                                        ? null
                                        : formData.item_group_id
                                }
                                apiRoute="itemGroup"
                                width={161}
                                disabled={disabledInputs.itemGroups}
                                onChange={handleItemGroupsChange} //when select is changing
                                onBlur={handleSelectWithCRUDBlur}
                                onActionClick={handleItemGroupActionClick}
                                onSubmit={onItemGroupSubmit} //source of truth modifier
                            />
                        </Form.Item>
                        <Form.Item label=" ">
                            <Button
                                type="primary"
                                disabled={disabledInputs.inputs}
                                style={{ marginTop: 10 }}
                                onClick={handleSubmit}
                            >
                                Save Item
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => ({
    //userName: `${state.UserDetails.firstname} ${state.UserDetails.lastname}`,
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    ownerID: state.UserDetails.owner_id,
    plan: state.UserDetails.subscriptionDetails.plan,
});

export default connect(mapStateToProps)(ItemForm);
