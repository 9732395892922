import React from "react";
import { Avatar, Button, Icon, Upload } from "antd";

const PatientImageUploader = ({
    image,
    toggleModal,
    previewImage,
    mobile: isMobile,
}) => {
    const ButtonGroup = Button.Group;

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleBeforeUpload = (file) => {
        return false;
    };

    const handleChange = (info) => {
        getBase64(
            info.fileList[info.fileList.length - 1].originFileObj,
            (imageUrl) => {
                previewImage(imageUrl);
            }
        );
    };

    return (
        <div>
            <div>
                {image ? (
                    <Avatar shape="square" size={197} src={image} />
                ) : (
                    <Avatar shape="square" size={197} icon="user" />
                )}
            </div>
            <div>
                <ButtonGroup style={{ marginTop: 5 }}>
                    {!isMobile.any() && (
                        <Button onClick={() => toggleModal()}>
                            <Icon type="camera" /> Camera
                        </Button>
                    )}
                    <Upload
                        onChange={handleChange}
                        beforeUpload={handleBeforeUpload}
                        showUploadList={false}
                    >
                        <Button>
                            <Icon type="upload" /> Upload
                        </Button>
                    </Upload>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default PatientImageUploader;
