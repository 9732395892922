import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";

import "react-quill/dist/quill.snow.css";

import "./assets/css/index.css";
import "./assets/css/antdOveride.css";

import "react-image-lightbox/style.css";

import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

//console.log(store.getState());

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
