import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import { titleCase, upperCase, lowerCase } from "helpers/textTransformer";

const FormItemTextarea = ({ data, error, onInputChange }) => {
    const { name, label, value, placeholder, required, transform } = data;

    const [formData, setFormData] = useState({
        [name]: value
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error
    });

    const { TextArea } = Input;

    useEffect(() => {
        if (value) {
            setFormData({ [name]: value });
        } else {
            setFormData({ [name]: "" });
        }
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        let newValue = value;
        if (transform !== "default") {
            switch (transform) {
                case "titlecase":
                    newValue = titleCase(value);
                    break;
                case "uppercase":
                    newValue = upperCase(value);
                    break;
                case "lowercase":
                    newValue = lowerCase(value);
                    break;
                default:
                    newValue = value;
            }
        }

        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const handleInputBlur = e => {
        const { name, value } = e.target;
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
        onInputChange(name, formData[name]);
    };

    return (
        <Form.Item
            label={label}
            required={required}
            hasFeedback
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <TextArea
                name={name}
                defaultValue={value}
                value={formData[name]}
                placeholder={placeholder}
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            />
        </Form.Item>
    );
};

export default FormItemTextarea;
