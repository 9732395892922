import React, { useState, useEffect } from "react";
import { Avatar, Badge, Table, Spin, message, Row, Col } from "antd";
import moment from "moment";
import ChatHeader from "./ChatHeader";
import ChatConversations from "./ChatConversation";
import ChatForm from "./ChatForm";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

const ChatBox = (props) => {
    const {
        breakpoint,
        currentUserID,
        clinicID,
        conversations,
        selectedConvoRecentMsg,
        serverDate,
        onSelectConversation,
        onMessageSent,
        onChatClose,
    } = props;

    const [convoVisible, setConvoVisible] = useState(true);
    const [selectedConvo, setSelectedConvo] = useState(null);
    const [selectedConvoMessages, setSelectedConvoMessages] = useState([]);
    const [isLoadingConvo, setIsLoadingConvo] = useState(false);

    //for scrolling behavior
    const [moveScrollToBottomWatcher, setMoveScrollToBottomWatcher] = useState(
        0
    );
    const [moveScrollWatcher, setMoveScrollWatcher] = useState(0);

    //convo messages height
    const [convoHeight, setConvoHeight] = useState(
        breakpoint.isNormalMobile ? "calc(100vh - 185px)" : 250
    );

    const [getMessagesOffest, setGetMessagesOffest] = useState(10);

    useEffect(() => {
        //update messages of selected conversation
        //append
        if (selectedConvo && selectedConvoRecentMsg) {
            setSelectedConvoMessages([
                ...selectedConvoMessages,
                {
                    ...selectedConvoRecentMsg,
                    datetime_sent: moment().format("YYYY-MM-DD HH:mm:ss"),
                },
            ]);

            setMoveScrollToBottomWatcher(moveScrollToBottomWatcher + 1);
        }
    }, [conversations]);

    const handleSelectConversation = async (conversation) => {
        const { user_id, totalUnreadMessage } = conversation;
        try {
            setSelectedConvo(conversation);
            setSelectedConvoMessages([]);

            setConvoVisible(!convoVisible);
            setIsLoadingConvo(true);
            const response = await http.get(
                `${apiUrl}/chat/conversation/messages/${clinicID}/${currentUserID}/${user_id}/${totalUnreadMessage}/0`
            );
            setSelectedConvoMessages(
                response.data.messages.sort(
                    (a, b) => a.message_id - b.message_id
                )
            );
            onSelectConversation(conversation);
            setIsLoadingConvo(false);
            setMoveScrollToBottomWatcher(moveScrollToBottomWatcher + 1);
        } catch (error) {}
    };

    const handleShowConversations = () => {
        setSelectedConvo(null);
        setSelectedConvoMessages([]);
        onSelectConversation(null);
        setGetMessagesOffest(10);
        setConvoVisible(!convoVisible);
    };

    const handleChatClose = () => {
        onChatClose();
        //handleShowConversations();
    };

    const handleScrolledToTop = async () => {
        try {
            const response = await http.get(
                `${apiUrl}/chat/conversation/messages/${clinicID}/${currentUserID}/${selectedConvo.user_id}/0/${getMessagesOffest}`
            );

            const messages = response.data.messages.sort(
                (a, b) => a.message_id - b.message_id
            );

            if (messages.length !== 0) {
                setSelectedConvoMessages([
                    ...messages,
                    ...selectedConvoMessages,
                ]);
                setGetMessagesOffest(getMessagesOffest + 10);
            }
            setMoveScrollWatcher(moveScrollWatcher + 1);
        } catch (error) {}
    };

    const handleShiftEnterPress = (value) => {
        const totalLines = value.split("\n").length;

        if (totalLines <= 4) {
            switch (totalLines) {
                case 1:
                    setConvoHeight(
                        breakpoint.isNormalMobile ? "calc(100vh - 185px)" : 250
                    );
                    break;
                case 2:
                    setConvoHeight(
                        breakpoint.isNormalMobile ? "calc(100vh - 199px)" : 236
                    );
                    break;
                case 3:
                    setConvoHeight(
                        breakpoint.isNormalMobile ? "calc(100vh - 215px)" : 215
                    );
                    break;
                case 4:
                    setConvoHeight(
                        breakpoint.isNormalMobile ? "calc(100vh - 236px)" : 194
                    );
                    break;
                default:
                    return;
            }
        }
    };

    const handleSubmit = async (messageData) => {
        //append
        onMessageSent(messageData);
        //insert to db
        try {
            await http.post(`${apiUrl}/chat`, messageData);
            if (messageData.receiver_id === "support") {
                await http.post(`${apiUrl}/chat/admin`, messageData);
            }
            socket.emit("messageSend", messageData);
        } catch (error) {
            message.error("Failed to send message. Something went wrong!");
            //remove last message from selected conversation
        }
    };

    const converstationTableColumns = [
        {
            key: "conversation",
            className: "cursorPointer",
            render: (text, record) => {
                const {
                    user_id,
                    name,
                    recentMessage: recentMessageData,
                } = record;

                // const avatarProps = img_path
                //     ? {
                //           size: 40,
                //           src: `${systemStoragePath}/pictures/${img_path}`,
                //       }
                //     : { size: 40, icon: "user" };

                const avatarProps = { size: 40, icon: "user" };

                let displayTime = "";
                let recentMessage = null;
                if (recentMessageData) {
                    const { datetime_sent, message } = recentMessageData;

                    if (
                        moment(datetime_sent).isSame(moment(serverDate), "day")
                    ) {
                        displayTime = moment(datetime_sent).format("hh:mm A");
                    } else if (
                        moment(datetime_sent).isSame(moment(serverDate), "week")
                    ) {
                        displayTime = moment(datetime_sent).format("ddd");
                    } else if (
                        moment(datetime_sent).isSame(moment(serverDate), "year")
                    ) {
                        displayTime = moment(datetime_sent).format("MMM DD");
                    } else {
                        displayTime = moment(datetime_sent).format("MM/DD/YY");
                    }

                    if (message.length >= 11) {
                        recentMessage = (
                            <span>
                                {message.substring(0, 11)}&hellip; &middot;{" "}
                                {displayTime}
                            </span>
                        );
                    } else {
                        recentMessage = (
                            <span>
                                {message} &middot; {displayTime}
                            </span>
                        );
                    }
                }

                return (
                    <Row gutter={8} style={{ width: "100%" }}>
                        <Col span={5}>
                            {user_id === "support" ? (
                                <Avatar
                                    style={{ backgroundColor: "#87d068" }}
                                    icon="team"
                                    size={40}
                                />
                            ) : (
                                <Avatar {...avatarProps} />
                            )}
                        </Col>
                        <Col span={19}>
                            <div>{name}</div>
                            <div
                                style={{
                                    fontWeight:
                                        recentMessageData &&
                                        recentMessageData.is_read === 0 &&
                                        recentMessageData.sender_id !==
                                            currentUserID
                                            ? "bold"
                                            : "normal",
                                }}
                            >
                                {recentMessage
                                    ? recentMessage
                                    : "Start Conversation"}
                            </div>
                        </Col>
                    </Row>
                );
            },
        },
        {
            key: "totalUnread",
            width: 50,
            className: "cursorPointer",
            render: (text, record) => (
                <Badge count={record.totalUnreadMessage} />
            ),
        },
    ];

    return (
        <>
            <ChatHeader
                data={selectedConvo}
                onBackToConversations={handleShowConversations}
                onChatClose={handleChatClose}
            />
            <div
                style={{
                    width: breakpoint.isNormalMobile ? "100vw" : 320,
                    overflowY: "auto",
                    height: breakpoint.isNormalMobile
                        ? "calc(100vh - 125px)"
                        : 310,
                    backgroundColor: "#fff",
                }}
            >
                {convoVisible ? (
                    <Table
                        columns={converstationTableColumns}
                        dataSource={conversations}
                        pagination={false}
                        showHeader={false}
                        rowKey="user_id"
                        onRow={(record) => ({
                            onClick: () => {
                                handleSelectConversation(record);
                            },
                        })}
                    />
                ) : (
                    <Spin spinning={isLoadingConvo} tip="Loading...">
                        <div className="cwaChat">
                            <ChatConversations
                                currentUserID={currentUserID}
                                messages={selectedConvoMessages}
                                moveScrollToBottomWatcher={
                                    moveScrollToBottomWatcher
                                }
                                moveScrollWatcher={moveScrollWatcher}
                                onScrolledToTop={handleScrolledToTop}
                                height={convoHeight}
                            />
                        </div>
                        <ChatForm
                            currentUserID={currentUserID}
                            clinicID={clinicID}
                            receiverID={selectedConvo.user_id}
                            onPressShiftEnter={handleShiftEnterPress}
                            onSubmit={handleSubmit}
                        />
                    </Spin>
                )}
            </div>
        </>
    );
};

export default ChatBox;
