import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Button, Divider, message, Switch, Card } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const Notifications = ({
    notifs,
    userID,
    plan,
    updateSettings,
    setRequestError,
}) => {
    const [notificationsSettings, setNotificationsSettings] = useState(notifs);
    const [
        isNotificationSettingsEdited,
        setIsNotificationSettingsEdited,
    ] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isNotificationSettingsEdited]);

    function beforeunload(e) {
        if (isNotificationSettingsEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleSwitchChange = (name, checked) => {
        setNotificationsSettings({
            ...notificationsSettings,
            [name]: checked ? true : false,
        });
        setIsNotificationSettingsEdited(true);
    };

    const handleNotificationSettingsSave = async () => {
        try {
            updateSettings({ notifications: notificationsSettings });
            setIsNotificationSettingsEdited(false);
            message.success("Notification settings successfully saved!");
            const notifSettings = JSON.stringify(notificationsSettings);
            await http.put(`${apiUrl}/userSettings/${userID}`, {
                notifications: notifSettings,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message,
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 250 }}>
                            Show Appointment Notification
                        </td>
                        <td>
                            <Switch
                                checked={notificationsSettings.appointmentCount}
                                onChange={(checked) =>
                                    handleSwitchChange(
                                        "appointmentCount",
                                        checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                    {plan !== "basic" && (
                        <tr>
                            <td>Show Low Stock Item Notification</td>
                            <td>
                                <Switch
                                    checked={
                                        notificationsSettings.lowStockItemCount
                                    }
                                    onChange={(checked) =>
                                        handleSwitchChange(
                                            "lowStockItemCount",
                                            checked
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Divider />

            <Button
                type="primary"
                size="large"
                onClick={handleNotificationSettingsSave}
            >
                Save Notification Settings
            </Button>

            <Prompt
                when={isNotificationSettingsEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    notifs: state.Settings.notifications,
    userID: state.UserDetails.user_id,
    plan: state.UserDetails.subscriptionDetails.plan,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
