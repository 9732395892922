import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Avatar, Row, Col, Typography, Table } from "antd";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import ModalDetails from "globalComponents/ModalDetails";
import ActionMenu from "globalComponents/ActionMenu";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Notifications = ({
    breakpoint,
    userID,
    clinicID,
    setRequestError,
    ...props
}) => {
    const [isCotentLoading, setIsContentLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { Title, Text } = Typography;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(
                    `${apiUrl}/notifications/${clinicID}/${userID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                let { openedNotifications, notifications } = response.data;
                openedNotifications = openedNotifications.map(
                    (readNotif) => readNotif.notification_id
                );

                //sort by datetime
                notifications = notifications.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                });

                //select top 20 only
                let topNotifications = notifications.slice(0, 20);
                topNotifications = topNotifications.map((topNotification) => ({
                    ...topNotification,
                    is_read:
                        openedNotifications.indexOf(
                            topNotification.notification_id
                        ) === -1
                            ? 0
                            : 1,
                }));

                setNotifications(topNotifications);

                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const toggleModalVisible = () => {
        setIsModalVisible(!isModalVisible);
    };

    const showModal = (selectedNotification) => {
        setSelectedNotification(selectedNotification);
        toggleModalVisible();
    };

    const markAsRead = async (selectedNotification) => {
        if (!selectedNotification.is_read) {
            const newNotifications = notifications.map((notification) => {
                if (
                    notification.notification_id ===
                    selectedNotification.notification_id
                ) {
                    return {
                        ...notification,
                        is_read: 1,
                    };
                }
                return notification;
            });

            setNotifications(newNotifications);

            //insert
            await http.post(`${apiUrl}/openedNotification`, {
                notification_id: selectedNotification.notification_id,
                user_id: userID,
            });
        }
    };

    const handleNotificationClick = async (selectedNotification) => {
        const newNotifications = notifications.map((notification) => {
            if (
                notification.notification_id ===
                selectedNotification.notification_id
            ) {
                return {
                    ...notification,
                    is_read: 1,
                };
            }
            return notification;
        });

        setNotifications(newNotifications);

        switch (selectedNotification.show_in) {
            case "route":
                props.history.push(selectedNotification.content);
                break;
            case "url":
                window.open(selectedNotification.content);
                break;
            default:
                showModal(selectedNotification);
        }

        if (!selectedNotification.is_read) {
            //insert
            await http.post(`${apiUrl}/openedNotification`, {
                notification_id: selectedNotification.notification_id,
                user_id: userID,
            });
        }
    };

    const notificationTableColums = [
        {
            title: "notifications",
            key: "notifications",
            className: "cursorPointer",
            render: (text, record) => {
                const {
                    icon,
                    avatar_bg_color,
                    title,
                    description,
                    created_at,
                    is_read,
                } = record;

                return (
                    <Row type="flex" gutter={12}>
                        <Col>
                            <Avatar
                                icon={icon}
                                style={{
                                    backgroundColor: is_read
                                        ? "#ccc"
                                        : avatar_bg_color,
                                    fontSize: "1.1rem",
                                }}
                            />
                        </Col>
                        <Col>
                            <div>
                                <div>
                                    {is_read ? (
                                        <Text type="secondary">{title}</Text>
                                    ) : (
                                        <strong>{title}</strong>
                                    )}
                                </div>
                                <div>
                                    {is_read ? (
                                        <Text type="secondary">
                                            {description}
                                        </Text>
                                    ) : (
                                        description
                                    )}
                                </div>
                                <div>
                                    {is_read ? (
                                        <Text type="secondary">
                                            {moment(created_at).fromNow()}
                                        </Text>
                                    ) : (
                                        moment(created_at).fromNow()
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                );
            },
        },
        {
            title: "",
            key: "mar",
            width: 50,
            render: (text, record) => {
                const menu = [
                    {
                        label: "Mark as read",
                        icon: "book",
                        onClick: () => markAsRead(record),
                    },
                ];

                return (
                    !record.is_read && (
                        <ActionMenu menu={menu} layout="compress" />
                    )
                );
            },
        },
    ];

    if (breakpoint.isNormalMobile) {
        notificationTableColums.splice(1, 1);
    }

    return isCotentLoading ? (
        <ContentLoader />
    ) : (
        <div style={{ backgroundColor: "#fff", padding: 16 }}>
            <Table
                columns={notificationTableColums}
                dataSource={notifications}
                pagination={false}
                rowKey="notification_id"
                showHeader={false}
                onRow={(record) => ({
                    onClick: () => {
                        handleNotificationClick(record);
                    },
                })}
            />
            <ModalDetails
                visible={isModalVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleModalVisible}
            >
                {selectedNotification && (
                    <div style={{ padding: 20 }}>
                        <div style={{ textAlign: "center", marginBottom: 20 }}>
                            <Avatar
                                size={64}
                                icon={selectedNotification.icon}
                                style={{
                                    backgroundColor:
                                        selectedNotification.avatar_bg_color,
                                }}
                            />
                        </div>
                        <Title level={4}>{selectedNotification.title}</Title>
                        {ReactHtmlParser(selectedNotification.content)}
                    </div>
                )}
            </ModalDetails>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
