import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    startStateFetching,
    endStateFetching,
    setRequestError
} from "redux/system/system.actions";
import { setUserDetails } from "redux/userDetails/userDetails.actions";
import { setHmo } from "redux/hmo/hmo.actions";
import { setGroups } from "redux/groups/group.actions";
import { setItems } from "redux/items/item.actions";
import { setPapers } from "redux/papers/paper.actions";
import { setSavedCaseNotes } from "redux/savedCaseNotes/savedCaseNotes.actions";
import { setSketchBgImgs } from "redux/sketchBackgroundImgs/sketchBackgroundImg.actions";
import { setSettings } from "redux/settings/settings.actions";

import {
    Layout,
    Result,
    Button,
    //Badge,
    Row,
    Col,
    Icon,
    Alert,
    notification
} from "antd";

import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";
import VideoCaller from "globalComponents/VideoCaller/Index";

import { getSessionData } from "helpers/getSessionData";
import { checkIsUserLogin } from "helpers/viewsProtector";

import isMobile from "helpers/deviceDetector";
import http from "services/httpService";
import { apiUrl, socketUrl } from "config.json";
import logo from "assets/img/logo.png";

import openSocket from "socket.io-client";
import NotifAudio from "assets/sound/beyond-doubt-2.mp3";
import { Sound, isSoundOn, synth, voices } from "globalComponents/ToggleSound";

/* Main Socket Instance */

const socket = openSocket(socketUrl, {
    query: `token=${localStorage.getItem("token")}`
});

socket.on("connect", () => {
    console.log("Connected to server!");
    const sessionData = getSessionData();
    const { clinicID, userID } = sessionData;
    if (userID) {
        socket.emit("addUser", userID, clinicID);
    }
});

socket.on("disconnect", () => {
    console.log("Disconnected to server!");
});

/* End Main Socket Instance */

const isUserLogin = checkIsUserLogin();

const Main = props => {
    const {
        breakpoint,
        children,
        isStateFetching,
        isRequestError,
        requestErrorMsg,
        requestErrorSubMsg,
        startStateFetching,
        endStateFetching,
        setRequestError,
        setUserDetails,
        setHmo,
        setGroups,
        setItems,
        setPapers,
        setSavedCaseNotes,
        setSketchBgImgs,
        setSettings
    } = props;

    const [isDateDueWarningVisible, setIsDateDueWarningVisible] =
        useState(false);
    const [isSystemDisabled, setIsSystemDisabledVisible] = useState(false);
    const [subscriptionDetails, setSuscriptionDetails] = useState(null);

    const { Content } = Layout;

    let pageTitle = "";
    children.props.children[0].forEach(views => {
        let viewsPath = views.props.path;
        viewsPath = viewsPath.replace(/\/:.*/g, "");

        if (props.location.pathname.indexOf(viewsPath) !== -1) {
            pageTitle = views.props.title;
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const sessionData = getSessionData();
            const { ownerID, clinicID, userID } = sessionData;

            if (userID) {
                startStateFetching();

                http.all([
                    http.get(
                        `${apiUrl}/globalStateData/${ownerID}/${clinicID}/${userID}`,
                        {
                            params: {
                                t: new Date().getTime()
                            }
                        }
                    ),
                    http.get(`${apiUrl}/hmo/${clinicID}`),
                    http.get(`${apiUrl}/groups`),
                    http.get(`${apiUrl}/items/${clinicID}`),
                    http.get(`${apiUrl}/papers/${clinicID}`),
                    http.get(`${apiUrl}/savedCaseNotes`),
                    http.get(`${apiUrl}/sketchBackgroundImgs`),
                    http.get(`${apiUrl}/clinicSettings/${clinicID}`),
                    http.get(`${apiUrl}/userSettings/${userID}`)
                ])
                    .then(
                        http.spread(
                            (
                                userDetailsResponse,
                                hmoResponse,
                                groupsResponse,
                                itemsResponse,
                                papersResponse,
                                savedCaseNotesResponse,
                                sketchBackgroundImgsResponse,
                                clinicSettingsResponse,
                                userSettingsResponse
                            ) => {
                                if (userDetailsResponse.data.firstname) {
                                    //parse doctor and staff settings
                                    const userDetails = {
                                        ...userDetailsResponse.data,
                                        doctors:
                                            userDetailsResponse.data.doctors.map(
                                                d => {
                                                    return {
                                                        ...d,
                                                        settings: {
                                                            ...d.settings,
                                                            prescription_writing:
                                                                JSON.parse(
                                                                    d.settings
                                                                        .prescription_writing
                                                                ),
                                                            previleges:
                                                                JSON.parse(
                                                                    d.settings
                                                                        .previleges
                                                                )
                                                        }
                                                    };
                                                }
                                            ),
                                        staffs: userDetailsResponse.data.staffs.map(
                                            s => {
                                                return {
                                                    ...s,
                                                    settings: {
                                                        ...s.settings,
                                                        previleges: JSON.parse(
                                                            s.settings
                                                                .previleges
                                                        )
                                                    }
                                                };
                                            }
                                        )
                                    };

                                    setUserDetails(userDetails);
                                    setHmo(hmoResponse.data.hmo);
                                    setGroups(groupsResponse.data.groups);
                                    setItems(itemsResponse.data.items);
                                    setPapers(papersResponse.data.papers);
                                    setSavedCaseNotes(
                                        savedCaseNotesResponse.data
                                            .savedCaseNotes
                                    );
                                    setSketchBgImgs(
                                        sketchBackgroundImgsResponse.data
                                            .sketchBackgroundImgs
                                    );
                                    //setSettings(settingsResponse.data.settings);
                                    setSettings({
                                        ...clinicSettingsResponse.data.settings,
                                        ...userSettingsResponse.data.settings
                                    });

                                    const { subscriptionDetails } =
                                        userDetailsResponse.data;

                                    if (subscriptionDetails.isSystemDisabled) {
                                        setIsSystemDisabledVisible(true);
                                        setSuscriptionDetails(
                                            subscriptionDetails
                                        );
                                    } else if (subscriptionDetails.isDateDue) {
                                        setIsDateDueWarningVisible(true);
                                        setSuscriptionDetails(
                                            subscriptionDetails
                                        );
                                    }

                                    endStateFetching();
                                } else {
                                    localStorage.clear();
                                    setRequestError({
                                        errorMessage: "Something went wrong :(",
                                        errorSubMessage: "Data undefined"
                                    });
                                }
                            }
                        )
                    )
                    .catch(error => {
                        setRequestError({
                            errorMessage: "Something went wrong :(",
                            errorSubMessage: error.message
                        });
                    });
            } else {
                endStateFetching();
            }
        };

        fetchData();

        socket.on("showNotifNewCaseCreated", function (data) {
            const options = {
                message: "New Case Created!",
                description: (
                    <div>
                        <div>CC: {data.chiefComplaint} </div>
                        <div>Doctor: {data.doctor}</div>
                        <div>Created By: {data.createdBy}</div>
                    </div>
                ),
                duration: 5
            };
            openRealtimeNotification(options, false);
        });

        socket.on("showNotifChangeCaseStatus", function (data) {
            const options = {
                message: "Case Status Changed!",
                description: `Status of case of ${data.patientFullname} with chief complaint: "${data.chiefComplaint}" has been changed from ${data.oldStatus} to ${data.newStatus}`,
                duration: 5
            };
            openRealtimeNotification(options, false);
        });

        socket.on("showNotifCallPatientQueueScreen", function (data) {
            const { firstname, middlename, lastname } = data;
            const fullname = `${firstname} ${middlename} ${lastname}`;
            const options = {
                message: "Calling Patient!",
                description: `Calling patient ${fullname}`,
                duration: 5
            };
            openRealtimeNotification(options, true, fullname);
        });

        socket.on("showNotifCaseAcquired", function (data) {
            const options = {
                message: "Case Acquired!",
                description: `Case of patient ${data.patientFullname} with chief complaint: "${data.chiefComplaint}" has been acquired by doctor ${data.doctorName}`,
                duration: 5
            };
            openRealtimeNotification(options, false);
        });

        socket.on("showNotifCaseCancelled", function (data) {
            const options = {
                message: "Case Cancelled!",
                description: `Case of patient ${data.patientFullname} with chief complaint: "${data.chiefComplaint}" has been cancelled by doctor ${data.doctorName}`,
                duration: 5
            };
            openRealtimeNotification(options, false);
        });

        socket.on("showNotifCaseDeleted", function (data) {
            const options = {
                message: "Case Deleted!",
                description: `Case of patient ${data.patientFullname} with chief complaint: "${data.chiefComplaint}" has been deleted by doctor ${data.doctorName}`,
                duration: 5
            };
            openRealtimeNotification(options, false);
        });

        socket.on("showNotifNewPPAppointment", function (data) {
            const options = {
                message: "New Appointment!",
                description: `${data.patientName} booked an appointment from patient portal amounting PHP ${data.appointmentAmount}, scheduled on ${data.appointmentDate} ${data.appointmentTime}. See more details on appointment page.`,
                duration: 10
            };
            openRealtimeNotification(options, false);
        });

        return () => {
            socket.off("showNotifNewCaseCreated");
            socket.off("showNotifChangeCaseStatus");
            socket.off("showNotifCallPatient");
            socket.off("showNotifCaseAcquired");
            socket.off("showNotifCaseCancelled");
            socket.off("showNotifNewPPAppointment");
        };
    }, []);

    function speak(message) {
        if (synth) {
            if (synth.speaking) {
                console.log("speechSynthesis.speaking");
                return;
            }
            var utterThis = new SpeechSynthesisUtterance(message);
            utterThis.onend = function (event) {
                console.log("SpeechSynthesisUtterance.onend");
            };
            utterThis.onerror = function (event) {
                console.log(event);
                console.error("SpeechSynthesisUtterance.onerror");
            };
            // utterThis.lang = "en-US";
            utterThis.voice = isMobile.any() ? null : voices[9];
            synth.speak(utterThis);
        }
    }

    function openRealtimeNotification(options, isSpeak, patientName = "") {
        notification.info({
            message: options.message,
            description: options.description,
            duration: options.duration
        });
        if (isSoundOn) {
            if (isSpeak) {
                setTimeout(() => speak(`Next patient. ${patientName}`), 1500);
            }
            Sound.src = NotifAudio;
            Sound.play();
        }
    }

    return isRequestError ? (
        <Result
            status="500"
            title={requestErrorMsg}
            subTitle={requestErrorSubMsg}
            extra={
                <Button
                    type="primary"
                    onClick={() => window.location.reload(true)}
                >
                    Refresh Page
                </Button>
            }
        />
    ) : isStateFetching ? (
        <Row>
            <Col xl={6}></Col>
            <Col xl={12}>
                <div style={{ textAlign: "center", marginTop: 120 }}>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                    <h2>
                        <Icon type="loading" /> Loading ...
                    </h2>
                </div>
            </Col>
            <Col xl={6}></Col>
        </Row>
    ) : (
        <Layout>
            <Sidebar activeMenu={pageTitle} breakpoint={breakpoint} />
            <Layout style={styles.layout}>
                {isDateDueWarningVisible && (
                    <Alert
                        message={`Your subscription has expired. You have ${subscriptionDetails.systemDisabledDaysLeft} days before CWA will stop working. Please contact CWA Support Team to update your subscription.`}
                        type="warning"
                        closable
                        banner
                    />
                )}
                {/* <button
                    onClick={() => {
                        socket.emit("paymentFailed", {
                            patient_id: "po0npndxoxccBqntsVcxCz",
                            message: "test",
                        });
                    }}
                >
                    test
                </button> */}
                {isSystemDisabled && (
                    <Alert
                        message={
                            <div>
                                Your account has{" "}
                                {subscriptionDetails.plan === "free"
                                    ? "expired"
                                    : "been deactivated"}
                                . To keep using CWA, go to{" "}
                                <Link to="/account">My Account</Link> page and{" "}
                                {subscriptionDetails.plan === "free"
                                    ? "updgrade your account."
                                    : "settle your remaining balance."}
                            </div>
                        }
                        type="error"
                        banner
                    />
                )}

                <VideoCaller breakpoint={breakpoint} />

                {isUserLogin && (
                    <Topbar title={pageTitle} breakpoint={breakpoint} />
                )}
                {breakpoint.isTabletPortrait && (
                    <div style={{ padding: "16px 16px 0 16px" }}>
                        <h1>{pageTitle}</h1>
                    </div>
                )}
                {isSystemDisabled ? (
                    pageTitle === "My Account" ? (
                        <Content
                            style={
                                breakpoint.isNormalMobile
                                    ? styles.smallDeviceContent
                                    : styles.defaultContent
                            }
                        >
                            {children}
                        </Content>
                    ) : (
                        ""
                    )
                ) : (
                    <Content
                        style={
                            breakpoint.isNormalMobile
                                ? styles.smallDeviceContent
                                : styles.defaultContent
                        }
                    >
                        {children}
                    </Content>
                )}

                <Footer breakpoint={breakpoint} />

                {/* <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        right: 50,
                        //boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.20)",
                    }}
                >
                    <Button size="large" style={{ width: 170 }}>
                        <Icon type="mail" /> Messages&nbsp;&nbsp;
                        <Badge count={2}></Badge>
                    </Button>                    
                </div> */}
                {/* <Chat breakpoint={breakpoint} /> */}
            </Layout>
        </Layout>
    );
};

const styles = {
    layout: { minHeight: "calc(100vh)" },
    defaultContent: {
        margin: "16px 16px 0 16px"
    },
    smallDeviceContent: {
        margin: "8px 0 0 0",
        padding: 5
    }
};

const mapStateToProps = state => ({
    isStateFetching: state.System.isStateFetching,
    isRequestError: state.System.isRequestError,
    requestErrorMsg: state.System.requestErrorMsg,
    requestErrorSubMsg: state.System.requestErrorSubMsg
});

const mapDispatchToProps = dispatch => ({
    startStateFetching: () => dispatch(startStateFetching()),
    endStateFetching: () => dispatch(endStateFetching()),
    setRequestError: data => dispatch(setRequestError(data)),
    setUserDetails: userDetails => dispatch(setUserDetails(userDetails)),
    setHmo: hmo => dispatch(setHmo(hmo)),
    setGroups: groups => dispatch(setGroups(groups)),
    setItems: items => dispatch(setItems(items)),
    setPapers: papers => dispatch(setPapers(papers)),
    setSavedCaseNotes: savedCaseNotes =>
        dispatch(setSavedCaseNotes(savedCaseNotes)),
    setSketchBgImgs: sketchBgImgs => dispatch(setSketchBgImgs(sketchBgImgs)),
    setSettings: settings => dispatch(setSettings(settings))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
export { socket };
