import ReactHtmlParser from "react-html-parser";

const formatNumber = n => {
    let x = Number(n);
    var parts = x.toFixed(2).split(".");
    var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
    return num;
};

const formatNumberWithSign = n => {
    let x = Number(n);
    var parts = x.toFixed(2).split(".");
    var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
    return ReactHtmlParser(`<span>&#8369;${num}</span>`);
};

export { formatNumber, formatNumberWithSign };
