import moment from "moment";

/**
 * Convert age based on format.
 *
 * @param format {String} Y|YM|YMW|YMWD|YMD
 * @param birthdate {date} birthdate
 * @see google.com
 * @return string
 */
const formatAge = (format, birthdate) => {
    let age = "";

    let yearAge = "";
    let monthAge = "";
    let weekAge = "";
    let dayWithWeekAge = "";
    //let dayWithoutWeekAge = "";

    let yearDiff = 0;
    let monthDiff = 0;
    let weekDiff = 0;
    let dayDiffWithWeek = 0;
    //let dayDiffWithoutWeek = 0;

    let Ysuffix = "";
    let Msuffix = "";
    let Wsuffix = "";
    let DWWsuffix = "";
    //let DWOWsuffix = "";

    let today = moment(new Date());
    let bdate = moment(birthdate);

    yearDiff = today.diff(bdate, "year");
    bdate.add(yearDiff, "years");

    monthDiff = today.diff(bdate, "months");
    bdate.add(monthDiff, "months");

    //dayDiffWithoutWeek = today.diff(bdate, "days");

    weekDiff = today.diff(bdate, "weeks");
    bdate.add(weekDiff, "weeks");

    dayDiffWithWeek = today.diff(bdate, "days");

    Ysuffix = yearDiff > 1 ? "years" : "year";
    Msuffix = monthDiff > 1 ? "months" : "month";
    Wsuffix = weekDiff > 1 ? "weeks" : "week";
    DWWsuffix = dayDiffWithWeek > 1 ? "days" : "day";
    //DWOWsuffix = dayDiffWithoutWeek > 1 ? "days" : "day";

    yearAge = `${yearDiff}`;
    monthAge = `${monthDiff}`;
    weekAge = `${weekDiff}`;
    dayWithWeekAge = `${dayDiffWithWeek}`;
    //dayWithoutWeekAge = `${dayDiffWithoutWeek}`;

    //y only
    //y year/s
    //y year/s old
    //y short y
    //y short y old

    //y year/s m month/s
    //y year/s m month/s old
    //y short y short m
    //y short y short m old

    //y year/s m month/s w week/s
    //y year/s m month/s w week/s old
    //y short y short m short w
    //y short y short m short w old

    //y year/s m month/s w week/s d days/s
    //y year/s m month/s w week/s d days/s old
    //y short y short m short w short d
    //y short y short m short w short d old

    //y year/s m month/s d days/s
    //y year/s m month/s d days/s old
    //y short y short m short d
    //y short y short m short d old

    switch (format) {
        case "Y":
            age = `${yearAge}`;
            break;
        case "YY":
            age = `${yearAge} ${Ysuffix}`;
            break;
        case "YYO":
            age = `${yearAge} ${Ysuffix} old`;
            break;
        case "YSY":
            age = `${yearAge}y`;
            break;
        case "YSYO":
            age = `${yearAge}y old`;
            break;

        case "YYM":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix}`;
            break;
        case "YYMO":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix} old`;
            break;
        case "YSYSM":
            age = `${yearAge}y ${monthAge}m`;
            break;
        case "YSYSMO":
            age = `${yearAge}y ${monthAge}m old`;
            break;

        case "YYMW":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix} ${weekAge} ${Wsuffix}`;
            break;
        case "YYMWO":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix} ${weekAge} ${Wsuffix} old`;
            break;
        case "YSYSMSW":
            age = `${yearAge}y ${monthAge}m ${weekAge}w`;
            break;
        case "YSYSMSWO":
            age = `${yearAge}y ${monthAge}m ${weekAge}w old`;
            break;

        case "YYMWD":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix} ${weekAge} ${Wsuffix} ${dayWithWeekAge} ${DWWsuffix}`;
            break;
        case "YYMWDO":
            age = `${yearAge} ${Ysuffix} ${monthAge} ${Msuffix} ${weekAge} ${Wsuffix} ${dayWithWeekAge} ${DWWsuffix} old`;
            break;
        case "YSYSMSWSD":
            age = `${yearAge}y ${monthAge}m ${weekAge}w ${dayWithWeekAge}d`;
            break;
        case "YSYSMSWSDO":
            age = `${yearAge}y ${monthAge}m ${weekAge}w ${dayWithWeekAge}d old`;
            break;
        default:
            age = "No Format Found";
    }

    return age;
};

export { formatAge };
