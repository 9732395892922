import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { List } from "antd";

const Report = ({ breakpoint, userType, plan, userPrivileges }) => {
    const data = [
        {
            title: "Cases",
            //description: "",
            path: "/reports/cases",
        },
        {
            title: "Patients",
            //description: "",
            path: "/reports/patients",
        },
        {
            title: "Appointments",
            //description: "",
            path: "/reports/appointments",
        },
        {
            title: "Items",
            //description: "",
            path: "/reports/items",
        },
        {
            title: "Item Ledger",
            //description: "",
            path: "/reports/itemLedger",
        },
        {
            title: "Billing",
            //description: "",
            path: "/reports/billing",
        },
        {
            title: "HMO",
            //description: "",
            path: "/reports/hmo",
        },
        {
            title: "Patient Portal Payments",
            //description: "",
            path: "/reports/pp/payments",
        },
        {
            title: "Patient Portal Payouts",
            //description: "",
            path: "/reports/pp/payouts",
        },
        {
            title: "Activity Log",
            //description: "",
            path: "/reports/activityLog",
        },
    ];

    if (plan === "basic") {
        data.splice(5, 2);
    }

    return !userPrivileges.isAccessReports ? (
        <div style={{ textAlign: "center" }}>
            Cannot access this page. Please contact the owner.
        </div>
    ) : (
        <div style={{ backgroundColor: "#fff", padding: 16 }}>
            <List
                itemLayout="horizontal"
                size="small"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={<Link to={item.path}>{item.title}</Link>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    userType: state.UserDetails.type,
    userPrivileges: state.Settings.userPrivileges,
    plan: state.UserDetails.subscriptionDetails.plan,
});

export default connect(mapStateToProps)(Report);
