import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Typography, Timeline, Divider } from "antd";
import moment from "moment";

import LazyLoad from "react-lazyload";

import ContentLoader from "globalComponents/ContentLoader";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ChangeLog = ({ breakpoint, ...props }) => {
    const [isCotentLoading, setIsContentLoading] = useState(true);
    const [changeLogs, setChangeLogs] = useState([]);

    const { Title } = Typography;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get(`${apiUrl}/changeLog/`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                });

                const { changeLogs } = response.data;
                const newChangeLogs = changeLogs.map((changeLog) => {
                    let contentList = changeLog.content.split("\n");
                    contentList = contentList.map((c) => {
                        let contentItem = c.replace(/&#x26A1;/g, "⚡");
                        contentItem = contentItem.replace(
                            /&#x1F6E0;&#xFE0F;/g,
                            "🛠️"
                        );
                        contentItem = contentItem.replace(/&#x1F41E;/g, "🐞");
                        contentItem = contentItem.replace(/&#x1F3C6;/g, "🏆");
                        contentItem = contentItem.replace(/&#x1F4E2;/g, "📢");

                        return contentItem;
                    });

                    return {
                        ...changeLog,
                        contentList,
                    };
                });

                setChangeLogs(newChangeLogs);
                setIsContentLoading(false);

                setTimeout(() => {
                    if (props.location.hash) {
                        window.location = props.location.hash;
                    }
                }, 100);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    return isCotentLoading ? (
        <ContentLoader />
    ) : (
        <div style={{ backgroundColor: "#fff", padding: 16 }}>
            <Title level={4}>Release Schedule</Title>
            <p>
                Most of the time release schedule is daily at night but can be
                anytime for urgent bugfix.
            </p>

            <Divider />
            {changeLogs.length === 0 ? (
                <div>No Change Log</div>
            ) : (
                <Timeline>
                    {changeLogs.map((changeLog) => (
                        <Timeline.Item key={changeLog.change_log_id}>
                            <div
                                style={{
                                    marginLeft: breakpoint.isNormalMobile
                                        ? 10
                                        : 40,
                                }}
                                id={changeLog.change_log_id}
                            >
                                <Title level={3}>{changeLog.title}</Title>
                                <p>
                                    {moment(changeLog.created_at).format("LL")}
                                </p>
                                <ul
                                    style={{
                                        marginLeft: breakpoint.isNormalMobile
                                            ? -20
                                            : 0,
                                    }}
                                >
                                    {changeLog.contentList.map(
                                        (item, index) => {
                                            if (item.indexOf("img@") !== -1) {
                                                return (
                                                    // <LazyLoad once key={index}>
                                                    <div
                                                        style={{
                                                            margin: "10px 0",
                                                        }}
                                                        key={index}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            src={`${item.replace(
                                                                "img@",
                                                                ""
                                                            )}?t=${Math.random()}`}
                                                            alt="demo"
                                                        />
                                                    </div>
                                                    // </LazyLoad>
                                                );
                                            } else {
                                                return (
                                                    <li key={index}>{item}</li>
                                                );
                                            }
                                        }
                                    )}
                                </ul>
                            </div>
                        </Timeline.Item>
                    ))}
                </Timeline>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLog);
