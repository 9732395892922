import {
    SET_ITEM_SUCCESS,
    ADD_ITEM_SUCCESS,
    EDIT_ITEM_SUCCESS,
    DELETE_ITEM_SUCCESS,
    ADJUST_ITEM_STOCK_SUCCESS,
    UPDATE_PRESCRIPTION_NOTE_SUCCESS,
    ADJUST_BULK_ITEM_STOCK_SUCCESS
} from "./item.types";

export const setItems = items => async dispatch => {
    dispatch({
        type: SET_ITEM_SUCCESS,
        payload: { items }
    });
};

export const addItem = newItem => async dispatch => {
    dispatch({
        type: ADD_ITEM_SUCCESS,
        payload: { newItem }
    });
};

export const editItem = editedItem => async dispatch => {
    dispatch({
        type: EDIT_ITEM_SUCCESS,
        payload: { editedItem }
    });
};

export const deleteItem = itemID => async dispatch => {
    dispatch({
        type: DELETE_ITEM_SUCCESS,
        payload: { itemID }
    });
};

export const adjustItemStockQty = item => async dispatch => {
    dispatch({
        type: ADJUST_ITEM_STOCK_SUCCESS,
        payload: { item }
    });
};

export const updatePrescriptionNote = prescriptionNote => async dispatch => {
    let stringPrescriptionNote = JSON.stringify(
        prescriptionNote.prescription_note
    );

    dispatch({
        type: UPDATE_PRESCRIPTION_NOTE_SUCCESS,
        payload: {
            itemID: prescriptionNote.item_id,
            prescriptionNote: stringPrescriptionNote
        }
    });
};

export const bulkAdjustItemStockQty = items => async dispatch => {
    dispatch({
        type: ADJUST_BULK_ITEM_STOCK_SUCCESS,
        payload: { items }
    });
};

// export const addPrescriptionNote = itemPrescriptionNote => async dispatch => {
//     try {
//         dispatch({ type: ITEM_PRESCRIPTION_NOTE_FORM_REQUEST });

//         let prescriptionNote = JSON.stringify(
//             itemPrescriptionNote.prescription_note
//         );

//         await http.post(`${apiUrl}/itemPrescriptionNote`, {
//             ...itemPrescriptionNote,
//             prescription_note: prescriptionNote
//         });

//         dispatch({
//             type: ADD_ITEM_PRESCRIPTION_NOTE_SUCCESS,
//             payload: {
//                 itemID: itemPrescriptionNote.item_id,
//                 prescriptionNote
//             }
//         });

//         return {
//             data: {
//                 error: false
//             }
//         };
//     } catch (error) {
//         dispatch({
//             type: ADD_ITEM_PRESCRIPTION_NOTE_ERROR
//         });
//         return {
//             data: {
//                 error: true,
//                 errorMsg: error.message || "Something went wrong :("
//             }
//         };
//     }
// };

// export const editPrescriptionNote = editedItemPrescriptionNote => async dispatch => {
//     try {
//         dispatch({ type: ITEM_PRESCRIPTION_NOTE_FORM_REQUEST });

//         let prescriptionNote = JSON.stringify(
//             editedItemPrescriptionNote.prescriptionNote
//         );

//         await http.put(
//             `${apiUrl}/itemPrescriptionNote/update/${editedItemPrescriptionNote.itemID}`,
//             {
//                 prescription_note: prescriptionNote
//             }
//         );

//         dispatch({
//             type: EDIT_ITEM_PRESCRIPTION_NOTE_SUCCESS,
//             payload: {
//                 itemID: editedItemPrescriptionNote.itemID,
//                 prescriptionNote
//             }
//         });
//         return {
//             data: {
//                 error: false
//             }
//         };
//     } catch (error) {
//         dispatch({
//             type: EDIT_ITEM_PRESCRIPTION_NOTE_ERROR
//         });
//         return {
//             data: {
//                 error: true,
//                 errorMsg: error.message || "Something went wrong :("
//             }
//         };
//     }
// };

// export const uploadItemImg = (ownerID, clinicID, imgFile) => async dispatch => {
//     try {
//         dispatch({ type: ITEM_FORM_REQUEST });
//         const response = await http.post(
//             `${apiUrl}/file/clinicData/${ownerID}/${clinicID}`,
//             imgFile
//         );
//         if (response.data.error) {
//             dispatch({
//                 type: UPLOAD_ITEM_IMG_ERROR
//             });
//             return response;
//         } else {
//             return {
//                 data: {
//                     error: false,
//                     imgPath: response.data.datePath
//                 }
//             };
//         }
//     } catch (error) {
//         return {
//             data: {
//                 error: true,
//                 errorMsg: error.message || "Something went wrong :("
//             }
//         };
//     }
// };
