import { combineReducers } from "redux";
import systemReducer from "./system/system.reducer";
import userDetailsReducer from "./userDetails/userDetails.reducer";
import hmoReducer from "./hmo/hmo.reducer";
import groupsReducer from "./groups/group.reducer";
import itemsReducer from "./items/item.reducer";
import papersReducer from "./papers/paper.reducer";
import savedCaseNotesReducer from "./savedCaseNotes/savedCaseNotes.reducer";
import sketchBackgroundImgReducer from "./sketchBackgroundImgs/sketchBackgroundImg.reducer";
import settingsReducer from "./settings/settings.reducer";
import copiedCaseNoteReducer from "./copiedCaseNote/copiedCaseNote.reducer";
import videoCallerReducer from "./videoCaller/videoCaller.reducer";

const RootReducer = combineReducers({
    System: systemReducer,
    UserDetails: userDetailsReducer,
    Hmo: hmoReducer,
    Groups: groupsReducer,
    Items: itemsReducer,
    Papers: papersReducer,
    SavedCaseNotes: savedCaseNotesReducer,
    SketchBackgroundImgs: sketchBackgroundImgReducer,
    Settings: settingsReducer,
    CopiedCaseNote: copiedCaseNoteReducer,
    VideoCaller: videoCallerReducer,
});

export default RootReducer;
