import React, { useState } from "react";
import { Button, Input, Form } from "antd";

const RenameCaseForm = (props) => {
    const { data, onSubmit } = props;

    const [formData, setFormData] = useState({
        name: data.case_name,
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            name: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        onSubmit(formData);
    };

    return (
        <Form>
            <Form.Item label="Enter case name">
                <Input
                    ref={(c) => c && c.input.focus()}
                    onChange={handleInputChange}
                    value={formData.name}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RenameCaseForm;
