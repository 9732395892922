import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkIsUserLogin } from "helpers/viewsProtector";
import { appBasePath } from "config.json";

const PrivateRoute = ({ breakpoint, component: Component, ...props }) => {
    const isUserLogin = checkIsUserLogin();

    return (
        <Route
            {...props}
            render={(props) => {
                return isUserLogin ? (
                    <Component {...props} breakpoint={breakpoint} />
                ) : (
                    <Redirect to={`${appBasePath}/`} />
                );
            }}
        />
    );
};

export default PrivateRoute;
