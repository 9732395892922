import {
    SET_GROUP_SUCCESS,
    ADD_GROUP_SUCCESS,
    EDIT_GROUP_SUCCESS,
    DELETE_GROUP_SUCCESS
} from "./group.types";

const INIT_STATE = [];

const groupReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_GROUP_SUCCESS:
            return action.payload.group;
        case ADD_GROUP_SUCCESS:
            return [...state, action.payload.newGroup];
        case EDIT_GROUP_SUCCESS:
            const newGroup = state.map(group => {
                if (group.group_id === action.payload.editedGroup.group_id) {
                    return action.payload.editedGroup;
                }
                return group;
            });
            return newGroup;
        case DELETE_GROUP_SUCCESS:
            return state.filter(
                group => group.group_id !== action.payload.groupID
            );
        default:
            return state;
    }
};

export default groupReducer;
