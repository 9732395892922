import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Row, Col, Empty, Button, Divider } from "antd";

import DynamicForm from "globalComponents/DynamicForm/Index";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PrescriptionNoteForm = ({
    item,
    saveOnly: isSaveOnly,
    readOnly: isReadOnly,
    onSave,
    onAdd,
    PNForm,
    isItemAutoAdd,
    clinicID,
    updateSettings,
    setRequestError,
}) => {
    const [prescriptionNoteForm, setPrescriptionNoteForm] = useState(PNForm);

    useEffect(() => {
        //setPrescriptionNoteForm
        if (item && item.prescription_note) {
            const prescriptionNote = JSON.parse(item.prescription_note);
            setPrescriptionNoteForm(
                prescriptionNoteForm.map((formInput) => {
                    return {
                        ...formInput,
                        value: prescriptionNote[formInput.name] || "",
                    };
                })
            );
        } else {
            setPrescriptionNoteForm(
                prescriptionNoteForm.map((formInput) => {
                    return {
                        ...formInput,
                        value: "",
                    };
                })
            );
        }
    }, [item]);

    const handleInputChange = async (name, value) => {
        const newPrescriptionNoteForm = prescriptionNoteForm.map(
            (formInput) => {
                if (formInput.name === name) {
                    //if the value was not on the options auto add it based on settings)
                    if (
                        formInput.options.indexOf(value) === -1 &&
                        isItemAutoAdd === 1 &&
                        value !== ""
                    ) {
                        return {
                            ...formInput,
                            value,
                            options: [...formInput.options, value],
                        };
                    } else {
                        return {
                            ...formInput,
                            value,
                        };
                    }
                }
                return formInput;
            }
        );

        setPrescriptionNoteForm(newPrescriptionNoteForm);

        if (isItemAutoAdd === 1) {
            try {
                const prescriptionNoteForm = newPrescriptionNoteForm.map(
                    (formInput) => ({ ...formInput, value: "" })
                );
                updateSettings({ prescriptionNoteForm });
                const PNForm = JSON.stringify(prescriptionNoteForm);
                await http.put(`${apiUrl}/settings/${clinicID}`, {
                    prescription_note_form: PNForm,
                });
            } catch (error) {
                setRequestError({
                    errorMessage: "Something went wrong on our last :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const handlePrescriptionNoteSave = () => {
        const prescriptionNote = prescriptionNoteForm.reduce((acc, item) => {
            if (item.value) {
                acc[item.name] = item.value;
            }
            return acc;
        }, {});

        onSave({ item_id: item.item_id, prescription_note: prescriptionNote });
    };

    const handlePrescriptionNoteAdd = () => {
        if (onAdd) {
            const prescriptionNote = prescriptionNoteForm.reduce(
                (acc, item) => {
                    if (item.value) {
                        acc[item.name] = item.value;
                    }
                    return acc;
                },
                {}
            );
            onAdd({
                item_id: item.item_id,
                item: item,
                note: prescriptionNote,
            });
        }
    };

    return (
        <div style={{ width: "100%" }}>
            {isSaveOnly && (
                <div>
                    <Row style={{ marginBottom: 5 }}>
                        <Col span={10}>
                            <strong>Item Name:</strong>
                        </Col>
                        <Col span={14}>{item && item.name}</Col>
                    </Row>
                    <Row style={{ marginBottom: 5 }}>
                        <Col span={10}>
                            <strong>Generic Name:</strong>
                        </Col>
                        <Col span={14}>{item && item.generic_name}</Col>
                    </Row>
                    <Divider />
                </div>
            )}
            {item ? (
                <div>
                    {prescriptionNoteForm.length !== 0 ? (
                        <DynamicForm
                            mode={isReadOnly ? "text" : "form"}
                            form={prescriptionNoteForm}
                            errors={{}}
                            layout={null}
                            onInputChange={handleInputChange}
                        />
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            No Prescription Note Form
                        </div>
                    )}
                    {!isReadOnly && (
                        <div style={{ marginTop: 10 }}>
                            <Row>
                                <Col span={8}>&nbsp;</Col>
                                <Col span={16}>
                                    {!isSaveOnly && (
                                        <Button
                                            type="primary"
                                            style={{ marginRight: 5 }}
                                            onClick={handlePrescriptionNoteAdd}
                                        >
                                            Add
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={handlePrescriptionNoteSave}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            ) : (
                <Empty description="No Item Selected" />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    PNForm: state.Settings.prescriptionNoteForm,
    isItemAutoAdd: state.Settings.pnformAutoCompleteAutoAdd,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrescriptionNoteForm);
