import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Select,
    Button,
    Table,
    Avatar,
    Input,
    Icon,
    Tooltip,
    Card,
    Divider,
} from "antd";

import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import http from "services/httpService";
import { formatAge } from "helpers/ageFormatter";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PatientsReport = ({ breakpoint, ageFormat, setRequestError }) => {
    const [isGroupsFetching, setIsGroupFetching] = useState(false);
    const [patientReportTableLoading, setPatientReportTableLoading] = useState(
        false
    );
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [patients, setPatients] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const { Option } = Select;

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsGroupFetching(true);
                const response = await http.get(`${apiUrl}/groups`, {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    }),
                });

                setGroups(response.data.groups);
                setIsGroupFetching(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleChange = (selectedGroups) => {
        setSelectedGroups(selectedGroups);
    };

    const handleShowPatients = async () => {
        try {
            setPatientReportTableLoading(true);
            if (selectedGroups.length === 0) {
                const response = await http.get(
                    `${apiUrl}/report/all/patients`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                setPatients(response.data.patients);
                setTableData(response.data.patients);
            } else {
                const response = await http.get(
                    `${apiUrl}/report/filtered/patients`,
                    {
                        params: { selectedGroups },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                setPatients(response.data.patients);
                setTableData(response.data.patients);
            }
            setPatientReportTableLoading(false);
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong while fetching your data :(",
                    errorSubMessage: error.message,
                });
            }
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    const handleTableChange = (pagination, filters, sorter, extra) => {
        setTableData(extra.currentDataSource);
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const patientReportColumns = [
        {
            title: "",
            key: "img",
            width: 50,
            render: (text, record) => {
                const avatarProps = record.img_path
                    ? {
                          size: 40,
                          src: record.is_from_legacy
                              ? record.img_path.replace(
                                    `${record.patient_id}-`,
                                    `thumb/${record.patient_id}-`
                                )
                              : record.img_path.replace(".jpeg", "-thumb.jpeg"),
                      }
                    : { size: 40, icon: "user" };

                return <Avatar {...avatarProps} />;
            },
        },
        {
            title: "Lastname",
            dataIndex: "lastname",
            ...getColumnSearchProps("lastname"),
        },
        {
            title: "Firstname",
            dataIndex: "firstname",
            ...getColumnSearchProps("firstname"),
        },
        {
            title: "Middlename",
            key: "middlename",
            render: (text, record) => {
                return record.middlename ? (
                    record.middlename
                ) : (
                    <span>&mdash;</span>
                );
            },
        },
        {
            title: "Age",
            key: "age",
            render: (text, record) => {
                return record.birthday ? (
                    formatAge(ageFormat, record.birthday)
                ) : (
                    <span>&mdash;</span>
                );
            },
        },
        {
            title: "Birthday",
            dataIndex: "birthday",

            render: (text, record) => {
                return record.birthday ? (
                    moment(record.birthday).format("LL")
                ) : (
                    <span>&mdash;</span>
                );
            },
        },
        {
            title: "Gender",
            key: "gender",
            render: (text, record) => {
                return record.gender ? record.gender : <span>&mdash;</span>;
            },
        },
        {
            title: "Civil Status",
            key: "civil_status",
            render: (text, record) => {
                return record.civil_status ? (
                    record.civil_status
                ) : (
                    <span>&mdash;</span>
                );
            },
        },
        {
            title: "Mobile",
            key: "mobile",
            render: (text, record) => {
                return record.mobile ? record.mobile : <span>&mdash;</span>;
            },
        },
    ];

    const CSVHeaders = [
        {
            label: "Lastname",
            key: "lastname",
        },
        {
            label: "Firstname",
            key: "firstname",
        },
        {
            label: "Middlename",
            key: "middlename",
        },
        {
            title: "Age",
            key: "age",
        },
        {
            label: "Birthday",
            key: "birthday",
        },
        {
            label: "Gender",
            key: "gender",
        },
        {
            label: "Civil Status",
            key: "civil_status",
        },
        {
            label: "Mobile",
            key: "mobile",
        },
    ];

    const CSVData = tableData.map((patient) => ({
        ...patient,
        age: patient.birthday ? formatAge(ageFormat, patient.birthday) : "-",
        birthday: patient.birthday
            ? moment(patient.birthday).format("LL")
            : "-",
    }));

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>
            <Divider />

            {isGroupsFetching ? (
                <ContentLoader />
            ) : (
                <div>
                    <Button
                        type="primary"
                        style={{ marginBottom: 16 }}
                        onClick={handleShowPatients}
                    >
                        Show Patients
                    </Button>

                    <Select
                        mode="multiple"
                        style={{
                            width: "100%",
                            marginBottom: 16,
                        }}
                        placeholder="Group Filters"
                        onChange={handleChange}
                    >
                        {groups.map((group) => (
                            <Option key={group.group_id}>{group.name}</Option>
                        ))}
                    </Select>

                    <div
                        style={{
                            marginBottom: 16,
                            minWidth: 320,
                            overflow: "auto",
                            backgroundColor: "#fff",
                        }}
                    >
                        <Table
                            columns={patientReportColumns}
                            dataSource={patients}
                            rowKey={"patient_id"}
                            loading={patientReportTableLoading}
                            pagination={{
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} of ${total} items`,
                                simple: isMobileBreakpoint ? true : false,
                            }}
                            className="reportsTable"
                            onChange={handleTableChange}
                        />
                    </div>

                    <div style={{ textAlign: "right" }}>
                        <CSVLink
                            data={CSVData}
                            headers={CSVHeaders}
                            filename={"patients.csv"}
                        >
                            <Tooltip title="Export to Excel">
                                <Button
                                    style={{
                                        backgroundColor: "#4CAF50",
                                        color: "#fff",
                                        border: "1px solid #4caf50",
                                    }}
                                >
                                    <Icon type="download" /> Export to CSV
                                </Button>
                            </Tooltip>
                        </CSVLink>
                    </div>
                </div>
            )}
        </Card>
    );
};

const mapStateToProps = (state) => ({
    ageFormat: state.Settings.ageFormat,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsReport);
