import React, { useState } from "react";
import { Button, Form, Radio, Icon } from "antd";

const FolderSelection = (props) => {
    const { currentSelectedCaseFolder, folders, onOK } = props;

    const [formData, setFormData] = useState({
        caseFolderID: "",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            caseFolderID: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        onOK(formData);
    };

    return (
        <div
            style={{
                margin: "auto",
                display: "block",
                width: 200,
            }}
        >
            <Form>
                <Radio.Group
                    defaultValue={currentSelectedCaseFolder}
                    onChange={handleChange}
                >
                    <Radio.Button value={""} style={{ width: 200 }}>
                        No Folder
                    </Radio.Button>

                    {folders.map((folder) => (
                        <Radio.Button
                            value={folder.case_folder_id}
                            style={{ width: 200 }}
                            key={folder.case_folder_id}
                        >
                            <Icon
                                type="folder-open"
                                theme="twoTone"
                                twoToneColor="#e2cb00"
                            />{" "}
                            {folder.name}
                        </Radio.Button>
                    ))}
                </Radio.Group>

                <Form.Item>
                    <Button type="primary" onClick={handleSubmit}>
                        OK
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default FolderSelection;
