import React from "react";
import { Button, Dropdown, Menu, Icon } from "antd";

const TableActionMenu = ({ breakpoint: isBreakpoint, menu, layout, size }) => {
    const CompressLayoutMenu = () => (
        <Dropdown
            overlay={
                <Menu>
                    {menu.map((item, index) => (
                        <Menu.Item
                            key={index}
                            onClick={({ domEvent }) => {
                                domEvent.stopPropagation();
                                item.onClick();
                            }}
                        >
                            <Icon type={item.icon} /> {item.label}
                        </Menu.Item>
                    ))}
                </Menu>
            }
            trigger={["click"]}
        >
            <Button
                size={size ? size : "default"}
                shape="circle"
                onClick={e => e.stopPropagation()}
            >
                <Icon type="more" />
            </Button>
        </Dropdown>
    );

    const SpreadLayoutMenu = () => (
        <>
            {menu.map((item, index) => (
                <Button key={index} type="link" onClick={item.onClick}>
                    {item.label}
                </Button>
            ))}
        </>
    );

    switch (layout) {
        case "spread":
            return <SpreadLayoutMenu />;
        case "compress":
            return <CompressLayoutMenu />;
        case "responsive":
            return isBreakpoint ? <CompressLayoutMenu /> : <SpreadLayoutMenu />;
        default:
            return <SpreadLayoutMenu />;
    }
};

export default TableActionMenu;
