import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message, Icon, Popover } from "antd";

const ItemStockAdjustForm = ({ data, userID, onSubmit }) => {
    const [formData, setFormData] = useState({
        item_id: "",
        ledger_code: "IA",
        ref_number: "",
        stock_qty: 0,
        additional_qty: 0,
        new_qty: 0,
        reason: "",
        user_id: userID,
        clinic_id: 0,
    });

    const [formErrors, setFormErrors] = useState({
        additional_qty: "",
        reason: "",
    });

    const [itemDetails, setItemDetails] = useState({
        name: "",
        generic_name: "",
    });

    const { TextArea } = Input;

    //first element focus refs
    const txtAdditionalQty = React.createRef();

    useEffect(() => {
        const { item_id, stock_qty, clinic_id, name, generic_name } = data;

        setItemDetails({ ...itemDetails, name, generic_name });
        setFormData({
            ...formData,
            item_id,
            stock_qty,
            clinic_id,
            new_qty: stock_qty,
        });

        txtAdditionalQty.current.input.focus();
    }, []);

    const handleAdditionalQtyInputChange = (e) => {
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if (
            (!isNaN(value) && reg.test(value)) ||
            value === "" ||
            value === "-"
        ) {
            const stockQty = formData.stock_qty;
            const newQty = Number(stockQty) + Number(value);
            setFormData({
                ...formData,
                new_qty: isNaN(newQty) ? 0 : newQty,
                additional_qty: value,
            });
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { additional_qty, reason } = formData;
        let errors = {};

        if (additional_qty.toString().trim() === "") {
            errors.additional_qty = "Additional Stock Qty. is required";
        }
        if (reason === "") {
            errors.reason = "Reason is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{
                    xl: { span: 8 },
                    lg: { span: 9 },
                }}
                wrapperCol={{
                    xl: { span: 16 },
                    lg: { span: 15 },
                }}
                style={{
                    width: "100%",
                }}
            >
                <Form.Item label="Item Name:">
                    <Input readOnly={true} value={itemDetails.name} />
                </Form.Item>
                <Form.Item label="Generic Name:">
                    <Input readOnly={true} value={itemDetails.generic_name} />
                </Form.Item>
                <Form.Item label="Stock Qty.">
                    <Input readOnly={true} value={formData.stock_qty} />
                </Form.Item>
                <Form.Item
                    label="Additional Qty."
                    required={true}
                    validateStatus={formErrors.additional_qty && "error"}
                    help={
                        formErrors.additional_qty && formErrors.additional_qty
                    }
                >
                    <Input
                        name="additional_qty"
                        autoComplete="off"
                        value={formData.additional_qty}
                        ref={txtAdditionalQty}
                        onChange={handleAdditionalQtyInputChange}
                        onBlur={handleInputBlur}
                        addonAfter={
                            <Popover
                                placement="top"
                                content={
                                    <p>
                                        <Icon
                                            type="info-circle"
                                            theme="twoTone"
                                        />{" "}
                                        If you want to decrease the current
                                        stock quantity, use a negative number.
                                    </p>
                                }
                            >
                                <Icon type="info-circle" theme="twoTone" />
                            </Popover>
                        }
                    />
                </Form.Item>
                <Form.Item label="New Stock Qty.">
                    <Input readOnly={true} value={formData.new_qty} />
                </Form.Item>
                <Form.Item
                    label="Reason"
                    required={true}
                    validateStatus={formErrors.reason && "error"}
                    help={formErrors.reason && formErrors.reason}
                >
                    <TextArea
                        name="reason"
                        rows={4}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        value={formData.reason}
                    />
                </Form.Item>
                <Form.Item label=" ">
                    <Button
                        type="primary"
                        style={{
                            marginTop: 10,
                        }}
                        onClick={handleSubmit}
                    >
                        Adjust Item
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
});

export default connect(mapStateToProps)(ItemStockAdjustForm);
