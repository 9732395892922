import React, { useState, useEffect } from "react";
import { Button, Input, Form, Select, message, Checkbox } from "antd";

const FormInputForm = ({ mode, data, onSubmit }) => {
    const [formData, setFormData] = useState({
        type: "",
        name: "", //to be generate at Index
        label: "",
        placeholder: "",
        value: "",
        options: "", //fill depend on element
        required: false,
        //fixed: false, //important on patient builder
        transform: "default", // value: default | titlecase | uppercase | lowercase
        validationRules: "", // regex
    });
    const [formErrors, setFormErrors] = useState({
        type: "",
    });

    const { Option } = Select;
    const { TextArea } = Input;

    useEffect(() => {
        if (mode === "edit") {
            const newFormData = { ...data };
            if (
                newFormData.type === "select" ||
                newFormData.type === "radio" ||
                newFormData.type === "checkbox"
            ) {
                let options = newFormData.options
                    .map((o) => o.value)
                    .join("\n");
                newFormData.options = options;
            } else if (newFormData.type === "autocomplete") {
                let options = newFormData.options.join("\n");
                newFormData.options = options;
            }

            setFormData(newFormData);
        }
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const handleSelectBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { type } = formData;
        let errors = {};

        if (type.trim() === "") {
            errors.type = "Type is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            if (
                formData.type === "input" ||
                formData.type === "textarea" ||
                formData.type === "datepicker" ||
                formData.type === "verticalSpace" ||
                formData.type === "title"
            ) {
                onSubmit({ ...formData, options: "" });
            } else {
                if (
                    formData.options.trim() === "" &&
                    formData.type !== "autocomplete"
                ) {
                    message.error("Please enter an options");
                    return false;
                }

                const options = formData.options.trim().split("\n");

                if (new Set(options).size !== options.length) {
                    message.error("Duplicate options found");
                    return false;
                }

                onSubmit(formData);
            }
        }
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{
                xl: { span: 6 },
                lg: { span: 6 },
            }}
            wrapperCol={{
                xl: { span: 18 },
                lg: { span: 18 },
            }}
            //onSubmit={handleSubmit}
        >
            <Form.Item
                label="Type"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.type && "error"}
                help={formErrors.type && formErrors.type}
            >
                <Select
                    value={formData.type}
                    disabled={formData.fixed}
                    onChange={(value) => handleSelectChange("type", value)}
                    onBlur={(value) => handleSelectBlur("type", value)}
                >
                    <Option value="input">Text</Option>
                    <Option value="textarea">Multi Line Text</Option>
                    <Option value="autocomplete">AutoComplete</Option>
                    <Option value="select">Select Box</Option>
                    <Option value="radio">Options (Single Select)</Option>
                    <Option value="checkbox">Options (Multi Select)</Option>
                    <Option value="datepicker">Datepicker</Option>
                    <Option value="title">Title</Option>
                    <Option value="verticalSpace">Vertical Space</Option>
                </Select>
            </Form.Item>
            {formData.type !== "verticalSpace" && (
                <Form.Item label="Label">
                    <Input
                        name="label"
                        value={formData.label}
                        disabled={formData.fixed}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        autoComplete="off"
                    />
                </Form.Item>
            )}
            {(formData.type === "input" || formData.type === "textarea") && (
                <Form.Item name="placeholder" label="Placeholder">
                    <Input
                        name="placeholder"
                        onChange={handleInputChange}
                        value={formData.placeholder}
                    />
                </Form.Item>
            )}
            {(formData.type === "select" ||
                formData.type === "radio" ||
                formData.type === "autocomplete" ||
                formData.type === "checkbox") && (
                <Form.Item label="Options">
                    <TextArea
                        name="options"
                        rows={6}
                        placeholder="Separate your option by newline"
                        value={formData.options}
                        onChange={handleInputChange}
                    />
                </Form.Item>
            )}
            {(formData.type === "input" || formData.type === "textarea") && (
                <Form.Item label="Transform">
                    <Select
                        value={formData.transform}
                        onChange={(value) =>
                            handleSelectChange("transform", value)
                        }
                    >
                        <Option value="default">Default</Option>
                        <Option value="titlecase">TitleCase</Option>
                        <Option value="uppercase">UPPERCASE</Option>
                        <Option value="lowercase">lowercase</Option>
                    </Select>
                </Form.Item>
            )}
            {formData.type !== "verticalSpace" && formData.type !== "title" && (
                <Form.Item
                    wrapperCol={{
                        sm: {
                            span: 18,
                            offset: 6,
                        },
                    }}
                >
                    <Checkbox
                        name="required"
                        checked={formData.required}
                        onChange={handleCheckboxChange}
                    >
                        Required
                    </Checkbox>
                </Form.Item>
            )}
            <Form.Item
                wrapperCol={{
                    xs: {
                        span: 24,
                        offset: 0,
                    },
                    sm: {
                        span: 18,
                        offset: 6,
                    },
                }}
            >
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FormInputForm;
