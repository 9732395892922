import React from "react";
import { Row, Col, Tooltip, Button, Icon, Tag, Typography } from "antd";
import moment from "moment";

const AppointmentDetails = ({
    details,
    readOnly: isCaseReadOnly,
    onRemove,
}) => {
    const { Title } = Typography;

    let appointmentDatetime = "";
    let appointmentDetails = "";
    let appointmentStatus = "";

    if (details) {
        appointmentDatetime = details.appointment_datetime;
        appointmentDetails = details.details;
        appointmentStatus = details.status;
    }

    return details ? (
        <div style={{ marginBottom: 24 }}>
            <Title level={4}>Next Appointment</Title>
            <div style={{ marginTop: 5, marginLeft: 5 }}>
                <Row type="flex" justify="space-between">
                    <Col>
                        <div>
                            Next appointment will be on{" "}
                            <strong>
                                {moment(appointmentDatetime).format("LLL")}
                            </strong>
                        </div>
                        <div>{appointmentDetails}</div>
                        {appointmentStatus === "followup" && (
                            <div style={{ marginTop: 5 }}>
                                <Tag color="purple">
                                    <Icon type="pushpin" /> Appointment will be
                                    made by patient on Patient Portal
                                </Tag>
                            </div>
                        )}
                    </Col>
                    <Col>
                        {appointmentStatus !== "confirmed" &&
                            !isCaseReadOnly &&
                            appointmentStatus !== "followup" && (
                                <Tooltip title="Delete Appointment">
                                    <Button
                                        size="small"
                                        type="danger"
                                        onClick={onRemove}
                                    >
                                        <Icon type="delete" /> Delete
                                    </Button>
                                </Tooltip>
                            )}
                    </Col>
                </Row>
            </div>
        </div>
    ) : null;
};

export default AppointmentDetails;
