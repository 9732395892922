import React from "react";
import { Row, Col } from "antd";

import PatientImage from "./components/PatientImage";
import DynamicForm from "globalComponents/DynamicForm/Index";

const PersonalInfoForm = ({
    patientImg,
    form,
    errors,
    breakpoint,
    onInputChange,
    onImageChange
}) => {
    return form.length !== 0 ? (
        <div style={{ paddingLeft: breakpoint.isNormalMobile ? 0 : 20 }}>
            <Row>
                <Col xl={8} lg={10} md={10}>
                    <PatientImage
                        image={patientImg}
                        breakpoint={breakpoint}
                        onImageChange={onImageChange}
                    />
                </Col>
                <Col xl={16} lg={14} md={14}>
                    <DynamicForm
                        mode="form"
                        form={form}
                        errors={errors}
                        breakpoint={breakpoint}
                        layout={null}
                        onInputChange={onInputChange}
                    />
                </Col>
            </Row>
        </div>
    ) : (
        <div style={{ textAlign: "center" }}>No Patient Form</div>
    );
};

export default PersonalInfoForm;
