import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    Table,
    Tag,
    Card,
    message,
    Descriptions,
    Input,
    Icon,
    Checkbox,
    Tooltip,
    Select,
    InputNumber,
    Popover,
    Spin,
    notification,
    Divider,
    Menu,
    Dropdown,
} from "antd";

import moment from "moment";
import ReactToPrint from "react-to-print";

import ContentLoader from "globalComponents/ContentLoader";
import SearchItem from "../ItemSearchBar";
import DiscountForm from "./DiscountForm";
import CustomItemForm from "./CustomItemForm";
import InvoicePad from "../InvoicePad";

import http from "services/httpService";
import { formatNumber, formatNumberWithSign } from "helpers/numberFormatter";
import { generateString } from "helpers/randomStringGenerator";
import { findUserName } from "helpers/userNameFinder";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const BillingForm = (props) => {
    const {
        loading: formLoading,
        selectedPatientBill: patientBill,
        patientFullInfo,
        onViewPatientBills,
        onSubmit,
        onPay,
        breakpoint,
        clinics,
        items,
        doctors,
        papers,
        hmoList,
        userID,
        //userName,
        userType,
        clinicID,
        clinicDetails,
    } = props;

    const [hmoFormVisible, setHmoFormVisible] = useState(false);
    const [isItemTablesLoading, setIsItemTablesLoading] = useState(false);

    const [formData, setFormData] = useState({
        bill_date: "",
        item_count: 0,
        sub_total: 0,
        discount: 0,
        grand_total: 0,
        hmo_amount_covered: 0,
        amount_paid: 0,
        amount_balance: 0,
        bill_notes: "",
        patient_id: patientBill.patient_id,
        hmo_id: 0,
        case_id: "",
        clinic_id: clinicID,
        user_id: userID,
        status: "new",
        billItems: [],
        isChanged: false,
    });
    const [selectedPatientBill, setSelectedPatientBill] = useState(patientBill);

    const [mappedItems, setMappedItems] = useState({});

    const [paperData, setPaperData] = useState({
        layout: null,
        content: null,
    });
    const [paperPrintTrigger, setPaperPrintTrigger] = useState(0);

    const invoicePadRef = React.createRef();
    //RTP means react-to-print
    let invoicePadRTPRef = React.createRef();

    const ButtonGroup = Button.Group;
    const { TextArea } = Input;
    const { Option } = Select;

    useEffect(() => {
        const fetchItems = async (selectedPatientBill) => {
            try {
                const billID = selectedPatientBill.bill_id;
                setIsItemTablesLoading(true);
                const response = await http.get(
                    `${apiUrl}/bill/items/${billID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                const newBillItems = response.data.billItems.map((billItem) => {
                    const newBillItem = { ...billItem };
                    delete newBillItem.bill_item_id;
                    delete newBillItem.bill_id;

                    if (newBillItem.item_type === "product") {
                        return { ...newBillItem, sort: 1 };
                    } else if (newBillItem.item_type === "service") {
                        return { ...newBillItem, sort: 2 };
                    } else if (newBillItem.item_type === "paper") {
                        return { ...newBillItem, sort: 3 };
                    } else if (newBillItem.item_type === "discount") {
                        return { ...newBillItem, sort: 4 };
                    } else {
                        return { ...newBillItem, sort: 5 };
                    }
                });

                setFormData({
                    ...formData,
                    ...selectedPatientBill,
                    billItems: newBillItems,
                });
                setSelectedPatientBill(selectedPatientBill);
                setIsItemTablesLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error(error.message);
                }
            }
        };

        const fetchCaseItems = async (selectedPatientBill) => {
            try {
                const caseID = selectedPatientBill.case_id;
                setIsItemTablesLoading(true);

                const response = await http.get(
                    `${apiUrl}/bill/case/items/${caseID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                if (response.data.billExist) {
                    fetchItems(response.data.selectedPatientBill);
                } else {
                    const newBillItems = response.data.caseItems
                        .map((caseItem) => {
                            const newBillItem = { ...caseItem };

                            if (
                                newBillItem.type === "item" &&
                                newBillItem.stock_qty <= 0
                            ) {
                                const { generic_name } = newBillItem;
                                const itemName = `${newBillItem.item_name}${
                                    generic_name !== ""
                                        ? ` (${generic_name})`
                                        : ""
                                }`;

                                notification.info({
                                    message: "Item Out of Stock!",
                                    description: (
                                        <p>
                                            <strong>{itemName}</strong> is out
                                            of stock and removed from the
                                            billing form list.
                                        </p>
                                    ),
                                    duration: 5,
                                });
                                return false;
                            } else if (newBillItem.type === "item") {
                                const { generic_name } = newBillItem;

                                const itemName = `${newBillItem.item_name}${
                                    generic_name !== ""
                                        ? ` (${generic_name})`
                                        : ""
                                }`;

                                return {
                                    item_id: newBillItem.item_id,
                                    name: itemName,
                                    item_type: newBillItem.item_type,
                                    cost: newBillItem.item_price,
                                    price: newBillItem.item_price,
                                    qty: 1,
                                    sort: 1,
                                };
                            } else if (newBillItem.type === "service") {
                                return {
                                    item_id: newBillItem.item_id,
                                    name: newBillItem.item_name,
                                    item_type: newBillItem.item_type,
                                    cost: newBillItem.item_price,
                                    price: newBillItem.item_price,
                                    qty: 1,
                                    sort: 2,
                                };
                            } else if (
                                newBillItem.type === "paper" &&
                                newBillItem.paper_price > 0
                            ) {
                                return {
                                    item_id: newBillItem.paper_id,
                                    name: newBillItem.paper_name,
                                    item_type: "paper",
                                    cost: newBillItem.paper_price,
                                    price: newBillItem.paper_price,
                                    qty: 1,
                                    sort: 3,
                                };
                            } else {
                                return false;
                            }
                        })
                        .filter((newBillItem) => newBillItem);

                    //if (newBillItems.length !== 0) {
                    const {
                        subTotal,
                        discount,
                        grandTotal,
                    } = computeBillItemsSummary(newBillItems);

                    setFormData({
                        ...formData,
                        item_count: newBillItems.length,
                        sub_total: subTotal,
                        discount,
                        grand_total: grandTotal,
                        case_id: caseID,
                        billItems: newBillItems,
                    });
                    //}

                    setIsItemTablesLoading(false);
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error(error.message);
                }
            }
        };

        if (selectedPatientBill.bill_id) {
            fetchItems(selectedPatientBill);
        } else if (selectedPatientBill.case_id) {
            fetchCaseItems(selectedPatientBill);
        } else {
            setFormData({
                ...formData,
                status: "new",
            });
        }

        const mappedItems = items.reduce((acc, item) => {
            acc[item.item_id] = item;
            return acc;
        }, {});

        setMappedItems(mappedItems);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [formData.isChanged]);

    useEffect(() => {
        if (invoicePadRTPRef.current && paperPrintTrigger !== 0) {
            invoicePadRTPRef.current.handlePrint();
        }
    }, [paperPrintTrigger]);

    function beforeunload(e) {
        if (formData.isChanged) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    function computeBillItemsSummary(billItems) {
        let subTotal = 0;
        let discount = 0;
        let grandTotal = 0;

        billItems.forEach((billItem) => {
            if (billItem.item_type === "discount") {
                discount += billItem.price;
            } else {
                subTotal += billItem.price;
            }
        });

        grandTotal = subTotal + discount;

        return {
            subTotal,
            discount,
            grandTotal,
        };
    }

    const handleBillToHmoChange = (e) => {
        setHmoFormVisible(e.target.checked);
    };

    const handleViewPatientBills = () => {
        if (formData.isChanged) {
            let isViewPatientBills = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave?"
            );
            if (isViewPatientBills) {
                onViewPatientBills();
            }
        } else {
            onViewPatientBills();
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
            isChanged: true,
        });
    };

    const handleHMOAmountChange = (e) => {
        if (e !== null) {
            let value = e.toString().replace(/[^0-9.,]/g, "");
            setFormData({
                ...formData,
                hmo_amount_covered: Number(value),
                isChanged: true,
            });
        } else {
            setFormData({
                ...formData,
                hmo_amount_covered: 0,
                isChanged: true,
            });
        }
    };

    const handleItemsSelectChange = (value) => {
        const itemExist = formData.billItems.filter(
            (item) => item.item_id === value
        );
        if (itemExist.length !== 0) {
            message.error(`Item ${itemExist[0].name} was already on the list`);
        } else {
            const selectedItem = mappedItems[value];
            const {
                item_id,
                name,
                generic_name,
                price,
                item_type,
            } = selectedItem;

            const newBillItem = {
                item_id,
                name: `${generic_name}`
                    ? `${name} (${generic_name})`
                    : `${name}`,
                cost: price,
                qty: 1,
                price,
                item_type,
                sort: item_type === "product" ? 1 : 2,
            };

            const newBillItems = [...formData.billItems, newBillItem];

            const subTotal = formData.sub_total + price;
            const discount = formData.discount;
            const grandTotal = subTotal + discount;

            setFormData({
                ...formData,
                item_count: newBillItems.length,
                sub_total: subTotal,
                discount,
                grand_total: grandTotal,
                billItems: newBillItems,
                isChanged: true,
            });
        }
    };

    const handleItemQtyChange = (itemID, qty, cost) => {
        const price = qty * cost;
        const newBillItems = formData.billItems.map((billItem) => {
            if (billItem.item_id === itemID) {
                return {
                    ...billItem,
                    qty,
                    price,
                };
            }
            return billItem;
        });

        const { subTotal, discount, grandTotal } = computeBillItemsSummary(
            newBillItems
        );

        setFormData({
            ...formData,
            sub_total: subTotal,
            discount,
            grand_total: grandTotal,
            billItems: newBillItems,
            isChanged: true,
        });
    };

    const handleRemoveItem = (itemID) => {
        const newBillItems = formData.billItems.filter(
            (item) => item.item_id !== itemID
        );
        const { subTotal, discount, grandTotal } = computeBillItemsSummary(
            newBillItems
        );
        setFormData({
            ...formData,
            item_count: newBillItems.length,
            sub_total: subTotal,
            discount,
            grand_total: grandTotal,
            billItems: newBillItems,
            isChanged: true,
        });
    };

    const handleDiscountFormSubmit = (discountFormData) => {
        const newBillItems = [
            ...formData.billItems,
            {
                item_id: generateString(10, "i"),
                name: discountFormData.description,
                cost: discountFormData.amount,
                qty: 1,
                price: discountFormData.amount * -1,
                item_type: "discount",
                sort: 5,
            },
        ];

        const { subTotal, discount, grandTotal } = computeBillItemsSummary(
            newBillItems
        );

        setFormData({
            ...formData,
            item_count: newBillItems.length,
            sub_total: subTotal,
            discount,
            grand_total: grandTotal,
            billItems: newBillItems,
            isChanged: true,
        });
    };

    const handleCustomItemFormSubmit = (customItemFormData) => {
        const newBillItems = [
            ...formData.billItems,
            {
                item_id: generateString(10, "i"),
                name: customItemFormData.name,
                cost: customItemFormData.amount,
                qty: 1,
                price: customItemFormData.amount,
                item_type: "custom",
                sort: 4,
            },
        ];

        const { subTotal, discount, grandTotal } = computeBillItemsSummary(
            newBillItems
        );

        setFormData({
            ...formData,
            item_count: newBillItems.length,
            sub_total: subTotal,
            discount,
            grand_total: grandTotal,
            billItems: newBillItems,
            isChanged: true,
        });
    };

    const handleSubmit = (action) => {
        const { hmo_id, hmo_amount_covered, grand_total } = formData;
        let decreaseItemQty = false;

        if (hmoFormVisible) {
            if (hmo_id === 0 || hmo_amount_covered === 0) {
                message.error("HMO name and HMO amount are required");
                return false;
            }
        }

        if (grand_total <= 0) {
            message.error("Grand total must not less than or equal to zero");
            return false;
        }

        let newStatus = "unpaid";
        let newRemainingBalance = grand_total;
        if (hmo_amount_covered !== 0) {
            decreaseItemQty = true;
            newStatus = "partial";
            newRemainingBalance = grand_total - hmo_amount_covered;
        }

        if (newRemainingBalance < 0) {
            message.error("Remaining balance must not be less than zero");
            return false;
        }

        if (newRemainingBalance === 0) {
            newStatus = "paid";
        }

        const billID = selectedPatientBill.bill_id || null;

        onSubmit(
            {
                ...formData,
                bill_id: billID,
                bill_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                item_count: formData.billItems.length,
                amount_balance: newRemainingBalance,
                status: newStatus,
                decreaseItemQty,
            },
            action
        );

        setFormData({
            ...formData,
            status: newStatus,
            isChanged: false,
        });
    };

    const triggerPrint = (doctorID) => {
        //get the corresponding paper
        const selectedPaper = papers.find(
            (paper) =>
                paper.category === "Invoice" && paper.doctor_id === doctorID
        );

        if (selectedPaper) {
            if (selectedPaper.print_available !== 0) {
                try {
                    const layout = JSON.parse(selectedPaper.layout);

                    setPaperData({
                        layout,
                        content: {
                            patient: {
                                ...patientFullInfo,
                            },
                            billData: { ...formData },
                            clinic: clinicDetails,
                            general: {
                                currentDate: moment().format("LL"),
                            },
                        },
                    });

                    setPaperPrintTrigger(paperPrintTrigger + 1);
                } catch (erorr) {
                    message.error(
                        "Unabled to parse paper layout. Please contact CWA Team Support"
                    );
                }
            } else {
                message.info(
                    "Paper not available for print. Possible no layout. Please contact CWA Team Support"
                );
            }
        } else {
            message.info(
                `Paper not available for print. Please send your desire design to CWA Support Team`
            );
        }
    };

    const handleInvoicePrint = (doctorID = null) => {
        if (doctorID) {
            triggerPrint(doctorID);
        } else {
            triggerPrint(userID);
        }
    };

    const DoctorsMenu = (
        <Menu>
            {doctors.map((doctor) => (
                <Menu.Item
                    key={doctor.doctor_id}
                    onClick={() => handleInvoicePrint(doctor.doctor_id)}
                >
                    {doctor.doctor_name}
                </Menu.Item>
            ))}
        </Menu>
    );

    const itemTableColumns = [
        {
            title: "Item",
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sortOrder: "ascend",
            sortDirections: ["ascend"],
            sorter: (a, b) => a.sort - b.sort,
            //render: text => <Text style={{ color: "#1890ff" }}>{text}</Text>
        },
        {
            title: "Cost",
            dataIndex: "cost",
            width: 80,
            render: (text, record) => (
                <span>
                    {record.item_type === "product" ||
                    record.item_type === "custom"
                        ? formatNumberWithSign(text)
                        : ""}
                </span>
            ),
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: 60,
            render: (text, record) => {
                let Component = null;
                if (
                    record.item_type === "product" ||
                    record.item_type === "custom"
                ) {
                    if (
                        formData.status === "paid" ||
                        formData.status === "partial" ||
                        formData.status === "deleted"
                    ) {
                        Component = <span>{text}</span>;
                    } else {
                        Component = (
                            <InputNumber
                                min={1}
                                max={99}
                                defaultValue={text}
                                onChange={(value) =>
                                    handleItemQtyChange(
                                        record.item_id,
                                        value,
                                        record.cost
                                    )
                                }
                                style={{ width: 60 }}
                            />
                        );
                    }
                }

                return Component;
            },
        },
        {
            title: "Price",
            dataIndex: "price",
            width: 100,
            render: (text, record) =>
                record.item_type === "discount" ? (
                    <Tag color="geekblue">{formatNumberWithSign(text)}</Tag>
                ) : (
                    <span>{formatNumberWithSign(text)}</span>
                ),
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) =>
                (formData.status === "new" || formData.status === "unpaid") && (
                    <Button
                        type="link"
                        onClick={() => handleRemoveItem(record.item_id)}
                    >
                        <Icon type="close" />
                    </Button>
                ),
        },
    ];

    const itemTableColumnsMobile = [
        {
            title: "Items",
            key: "items",
            defaultSortOrder: "ascend",
            sortOrder: "ascend",
            sortDirections: ["ascend"],
            sorter: (a, b) => a.sort - b.sort,
            render: (text, record) => {
                const { item_id, name, cost, qty, price, item_type } = record;

                const Cost = (
                    <span>
                        {item_type === "product" || item_type === "custom" ? (
                            <span>{formatNumberWithSign(cost)} x</span>
                        ) : (
                            ""
                        )}
                    </span>
                );

                let Qty = null;
                if (item_type === "product" || item_type === "custom") {
                    if (
                        formData.status === "paid" ||
                        formData.status === "partial" ||
                        formData.status === "deleted"
                    ) {
                        Qty = <span>{qty}</span>;
                    } else {
                        Qty = (
                            <InputNumber
                                min={1}
                                max={99}
                                defaultValue={qty}
                                onChange={(value) =>
                                    handleItemQtyChange(item_id, value, cost)
                                }
                                style={{ width: 60 }}
                            />
                        );
                    }
                }

                const Price =
                    item_type === "discount" ? (
                        <Tag color="geekblue">
                            {formatNumberWithSign(price)}
                        </Tag>
                    ) : (
                        <span>{formatNumberWithSign(price)}</span>
                    );

                return (
                    <>
                        <div>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    {name}

                                    <div style={{ marginTop: 20 }}>{Price}</div>
                                </Col>
                                <Col>
                                    <div>
                                        {Cost} {Qty}
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        {(formData.status === "new" ||
                                            formData.status === "unpaid") && (
                                            <Button
                                                type="link"
                                                onClick={() =>
                                                    handleRemoveItem(item_id)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                );
            },
        },
    ];

    const isBillingFormReadOnly =
        formData.status === "partial" ||
        formData.status === "paid" ||
        formData.status === "deleted"
            ? true
            : false;

    const getStatusColor = (status) => {
        let statusColor = "";
        if (status === "new") {
            statusColor = "purple";
        } else if (status === "unpaid") {
            statusColor = "orange";
        } else if (status === "partial") {
            statusColor = "green";
        } else if (status === "deleted") {
            statusColor = "red";
        } else {
            statusColor = "lightgrey";
        }
        return statusColor;
    };

    const discountFormContent = (
        <DiscountForm onSubmit={handleDiscountFormSubmit} />
    );

    const customFormContent = (
        <CustomItemForm onSubmit={handleCustomItemFormSubmit} />
    );

    const BillInformation = () => {
        const {
            clinic_id,
            bill_id,
            bill_date,
            status,
            amount_paid,
            hmo_id,
            hmo_amount_covered,
            grand_total,
            sub_total,
            discount,
            user_id,
        } = formData;

        const remainingBalance =
            grand_total - (amount_paid + hmo_amount_covered);

        let hmoName = "";
        const selectedHmo = hmoList.filter((hmo) => hmo.hmo_id === hmo_id);
        if (selectedHmo.length !== 0) {
            hmoName = selectedHmo[0].name;
        }

        let clinicName = "Clinic not found";
        let filteredClinic = clinics.filter(
            (clinic) => clinic.clinic_id === clinic_id
        );
        if (filteredClinic.length !== 0) {
            clinicName = filteredClinic[0].name;
        }

        return (
            <Card title="Bill Information">
                <div>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <strong>Status:</strong>
                        </Col>
                        <Col>
                            <Tag color={getStatusColor(status)}>{status}</Tag>
                        </Col>
                    </Row>
                </div>

                {bill_id && (
                    <div style={{ marginTop: 10 }}>
                        <Row type="flex" justify="space-between">
                            <Col>
                                <strong>Bill / Invoice #:</strong>
                            </Col>
                            <Col>{bill_id}</Col>
                        </Row>
                    </div>
                )}
                <div style={{ marginTop: 10 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <strong>Bill Date:</strong>
                        </Col>
                        <Col>
                            {bill_date
                                ? moment(bill_date).format("LL")
                                : moment().format("LL")}
                        </Col>
                    </Row>
                </div>

                <div style={{ marginTop: 10 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <strong>Billed By:</strong>
                        </Col>
                        <Col>{findUserName(user_id)}</Col>
                    </Row>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <strong>Billed On:</strong>
                        </Col>
                        <Col>{clinicName}</Col>
                    </Row>
                </div>

                {isBillingFormReadOnly && (
                    <>
                        <Divider />
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>Sub Total:</strong>
                                </Col>
                                <Col>{formatNumberWithSign(sub_total)}</Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>Discount:</strong>
                                </Col>
                                <Col>{formatNumberWithSign(discount)}</Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>Grand Total:</strong>
                                </Col>
                                <Col>{formatNumberWithSign(grand_total)}</Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>Amount Paid:</strong>
                                </Col>
                                <Col>{formatNumberWithSign(amount_paid)}</Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>HMO Covered:</strong>
                                </Col>
                                <Col>
                                    {formatNumberWithSign(hmo_amount_covered)}
                                </Col>
                            </Row>
                            {hmo_amount_covered !== 0 && (
                                <div style={{ textAlign: "right" }}>
                                    ({hmoName})
                                </div>
                            )}
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Row type="flex" justify="space-between">
                                <Col>
                                    <strong>Remaining Bal.:</strong>
                                </Col>
                                <Col>
                                    {formatNumberWithSign(remainingBalance)}
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </Card>
        );
    };

    const billToHMOForm = (
        <>
            <div style={{ marginTop: 10 }}>
                <Select
                    placeholder="Select HMO"
                    style={{
                        width: "100%",
                    }}
                    value={formData.hmo_id !== 0 ? formData.hmo_id : undefined}
                    onChange={(value) => handleInputChange("hmo_id", value)}
                >
                    {hmoList.map((hmo) => (
                        <Option key={hmo.hmo_id} value={hmo.hmo_id}>
                            {hmo.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginTop: 5 }}>
                <InputNumber
                    placeholder="Enter Amount"
                    value={
                        formData.hmo_amount_covered !== 0
                            ? formData.hmo_amount_covered
                            : null
                    }
                    formatter={(value) =>
                        `${value}`
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .replace(/[^0-9.,]/g, "")
                    }
                    onChange={(value) => handleHMOAmountChange(value)}
                    style={{
                        width: "100%",
                    }}
                />
            </div>
        </>
    );

    return (
        <>
            <Spin tip="Saving Bill..." spinning={formLoading}>
                <div style={{ marginBottom: 10 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <h1>&nbsp;&nbsp; Billing Form</h1>
                        </Col>
                        <Col>
                            {!isItemTablesLoading && (
                                <Button
                                    type="link"
                                    onClick={handleViewPatientBills}
                                >
                                    Back to Patient Bills
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
                {isItemTablesLoading ? (
                    <ContentLoader />
                ) : (
                    <Row>
                        <Col xl={24}>
                            <div style={{ marginBottom: 16 }}>
                                <BillInformation />
                            </div>
                            <Card title="Items">
                                {!isBillingFormReadOnly && (
                                    <Row
                                        gutter={6}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Col span={18}>
                                            <SearchItem
                                                onChange={
                                                    handleItemsSelectChange
                                                }
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ textAlign: "right" }}>
                                                <ButtonGroup>
                                                    <Tooltip title="Add Discount">
                                                        <Popover
                                                            title="Custom Discount"
                                                            trigger="click"
                                                            placement="bottomLeft"
                                                            content={
                                                                discountFormContent
                                                            }
                                                        >
                                                            <Button icon="percentage" />
                                                        </Popover>
                                                    </Tooltip>
                                                    <Tooltip title="Add Custom Item">
                                                        <Popover
                                                            title="Custom Item"
                                                            trigger="click"
                                                            placement="bottomLeft"
                                                            content={
                                                                customFormContent
                                                            }
                                                        >
                                                            <Button icon="edit" />
                                                        </Popover>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <div style={{ backgroundColor: "#fff" }}>
                                    <Table
                                        columns={
                                            breakpoint.isNormalMobile
                                                ? itemTableColumnsMobile
                                                : itemTableColumns
                                        }
                                        dataSource={formData.billItems}
                                        pagination={false}
                                        size="small"
                                        bordered={false}
                                        rowKey="item_id"
                                        loading={isItemTablesLoading}
                                    />
                                </div>
                                <div
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                >
                                    Total Items: {formData.item_count}
                                </div>
                            </Card>
                        </Col>
                        <Col xl={24}>
                            {!isBillingFormReadOnly && (
                                <div style={{ marginTop: 10 }}>
                                    <Descriptions bordered column={1}>
                                        <Descriptions.Item label="Sub Total">
                                            {formatNumberWithSign(
                                                formData.sub_total
                                            )}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Discount">
                                            {formatNumberWithSign(
                                                formData.discount
                                            )}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Grand Total">
                                            {formatNumberWithSign(
                                                formData.grand_total
                                            )}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            )}
                            {!isBillingFormReadOnly && (
                                <Card style={{ marginTop: 10 }}>
                                    <div>
                                        <Checkbox
                                            onChange={handleBillToHmoChange}
                                        >
                                            Bill to HMO
                                        </Checkbox>
                                    </div>
                                    {hmoFormVisible && billToHMOForm}
                                </Card>
                            )}
                            <div style={{ marginTop: 10 }}>
                                {!isBillingFormReadOnly ? (
                                    <TextArea
                                        rows={2}
                                        placeholder="Enter payment notes"
                                        value={formData.bill_notes}
                                        onChange={(e) =>
                                            handleInputChange(
                                                "bill_notes",
                                                e.target.value
                                            )
                                        }
                                    />
                                ) : (
                                    <p>
                                        Payment Notes:{" "}
                                        {formData.bill_notes ? (
                                            formData.bill_notes
                                        ) : (
                                            <span>&mdash;</span>
                                        )}
                                    </p>
                                )}
                            </div>
                            {(formData.status === "new" ||
                                clinicID === formData.clinic_id) && (
                                <div>
                                    {!isBillingFormReadOnly && (
                                        <div style={{ marginTop: 10 }}>
                                            <Row gutter={8}>
                                                <Col span={12}>
                                                    {userType === "staff" ? (
                                                        <Dropdown
                                                            overlay={
                                                                DoctorsMenu
                                                            }
                                                            trigger={["click"]}
                                                        >
                                                            <Button block>
                                                                <Icon type="printer" />{" "}
                                                                Print
                                                            </Button>
                                                        </Dropdown>
                                                    ) : (
                                                        <Button
                                                            block
                                                            onClick={() =>
                                                                handleInvoicePrint()
                                                            }
                                                        >
                                                            <Icon type="printer" />{" "}
                                                            Print
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col span={12}>
                                                    <Button
                                                        block
                                                        onClick={() =>
                                                            handleSubmit("save")
                                                        }
                                                    >
                                                        <Icon type="save" />{" "}
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Button
                                                type="primary"
                                                block
                                                size="large"
                                                style={{ marginTop: 8 }}
                                                onClick={() =>
                                                    handleSubmit("saveAndPay")
                                                }
                                            >
                                                <Icon type="check" /> Save and
                                                Pay
                                            </Button>
                                        </div>
                                    )}
                                    {isBillingFormReadOnly && (
                                        <div style={{ marginTop: 10 }}>
                                            {userType === "staff" ? (
                                                <Dropdown
                                                    overlay={DoctorsMenu}
                                                    trigger={["click"]}
                                                >
                                                    <Button block>
                                                        <Icon type="printer" />{" "}
                                                        Print
                                                    </Button>
                                                </Dropdown>
                                            ) : (
                                                <Button
                                                    block
                                                    onClick={() =>
                                                        handleInvoicePrint()
                                                    }
                                                >
                                                    <Icon type="printer" />{" "}
                                                    Print
                                                </Button>
                                            )}

                                            {formData.status === "partial" && (
                                                <Button
                                                    type="primary"
                                                    block
                                                    size="large"
                                                    style={{ marginTop: 8 }}
                                                    onClick={() =>
                                                        onPay(
                                                            selectedPatientBill
                                                        )
                                                    }
                                                >
                                                    <Icon type="check" /> Pay
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
                <Prompt
                    when={formData.isChanged}
                    message="Changes you made may not be saved. Are you sure you want to leave?"
                />
            </Spin>

            <div style={{ display: "none" }}>
                <InvoicePad
                    ref={invoicePadRef}
                    layout={paperData.layout}
                    content={{
                        ...paperData.content,
                        billData: {
                            ...formData,
                            sub_total: formatNumber(formData.sub_total),
                            discount: formatNumber(formData.discount),
                            grand_total: formatNumber(formData.grand_total),
                            hmo_amount_covered: formatNumber(
                                formData.hmo_amount_covered
                            ),
                        },
                    }}
                    formData={formData}
                />
            </div>
            <div style={{ display: "none" }}>
                <ReactToPrint
                    ref={(e) => {
                        invoicePadRTPRef.current = e;
                    }}
                    trigger={() => <button>yey</button>}
                    content={() => invoicePadRef.current}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    //userName: `${state.UserDetails.firstname} ${state.UserDetails.lastname}`,
    userType: state.UserDetails.type,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    clinics: state.UserDetails.clinics,
    items: state.Items,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    hmoList: state.Hmo,
    papers: state.Papers,
    clinicDetails: state.UserDetails.clinicDetails,
});

export default connect(mapStateToProps)(BillingForm);
