import {
    SET_PAPER_SUCCESS,
    ADD_PAPER_SUCCESS,
    EDIT_PAPER_SUCCESS,
    DELETE_PAPER_SUCCESS,
} from "./paper.types";

const INIT_STATE = [];

const papersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAPER_SUCCESS:
            return action.payload.papers;
        case ADD_PAPER_SUCCESS:
            return [...state, action.payload.newPaper];
        case EDIT_PAPER_SUCCESS:
            const newPapers = state.map((paper) => {
                if (paper.paper_id === action.payload.editedPaper.paper_id) {
                    return action.payload.editedPaper;
                }
                return paper;
            });
            return newPapers;
        case DELETE_PAPER_SUCCESS:
            return state.map((paper) => {
                if (paper.paper_id === action.payload.paperID) {
                    return { ...paper, status: "inactive" };
                }
                return paper;
            });
        default:
            return state;
    }
};

export default papersReducer;
