import React from "react";
import ReactHtmlParser from "react-html-parser";
import { formatNumber } from "helpers/numberFormatter";

const InvoicePad = React.forwardRef((props, ref) => {
    const { layout, content, formData } = props;

    const insertDataToHtmlString = (htmlString, queries) => {
        if (queries) {
            queries.forEach((query) => {
                const { source, find } = query;
                let regex = new RegExp('<span id="' + find + '"></span>');
                const patientOtherInfo = content.patient.other_info;

                switch (source) {
                    case "patient":
                        htmlString = htmlString.replace(
                            regex,
                            content.patient[find]
                        );
                        break;
                    case "patient_other_info":
                        htmlString = htmlString.replace(
                            regex,
                            patientOtherInfo[find]
                        );
                        break;
                    case "general":
                        htmlString = htmlString.replace(
                            regex,
                            content.general[find]
                        );
                        break;
                    case "billData":
                        htmlString = htmlString.replace(
                            regex,
                            content.billData[find]
                        );
                        break;
                    case "clinic":
                        htmlString = htmlString.replace(
                            regex,
                            content.clinic[find]
                        );
                        break;
                    default:
                        return;
                }
            });
            return htmlString;
        } else {
            return htmlString;
        }
    };

    const Header = () => {
        const { header } = layout;

        return (
            <div style={{ ...header.containerStyle, height: header.height }}>
                {header.html ? (
                    ReactHtmlParser(
                        insertDataToHtmlString(header.html, header.queries)
                    )
                ) : (
                    // <img
                    //     src={`${systemStoragePath}/${header.imgSrc}`}
                    //     alt="header"
                    // />
                    <div>soon image</div>
                )}
            </div>
        );
    };

    const BillTo = (props) => {
        const { body } = layout;
        const { billTo } = body;
        return (
            <div
                style={{
                    ...billTo.containerStyle,
                    height: billTo.height,
                }}
            >
                {ReactHtmlParser(
                    insertDataToHtmlString(billTo.html, billTo.queries)
                )}
            </div>
        );
    };

    const Items = (props) => {
        const { body } = layout;
        const { itemList } = body;
        return (
            <div
                style={{
                    ...itemList.containerStyle,
                    height: itemList.height,
                }}
            >
                <div style={{ marginTop: 20 }}>
                    <table
                        style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            border: "1px solid #000",
                        }}
                    >
                        <thead>
                            <tr style={{ backgroundColor: "#b9b9b9" }}>
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "5px",
                                    }}
                                >
                                    Name
                                </th>
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "5px",
                                    }}
                                >
                                    Cost
                                </th>
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "5px",
                                    }}
                                >
                                    Qty
                                </th>
                                <th
                                    style={{
                                        textAlign: "left",
                                        padding: "5px",
                                    }}
                                >
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.billItems.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: 5 }}>{item.name}</td>
                                    <td style={{ padding: 5 }}>
                                        {formatNumber(item.cost)}
                                    </td>
                                    <td style={{ padding: 5 }}>
                                        {formatNumber(item.qty)}
                                    </td>
                                    <td style={{ padding: 5 }}>
                                        {formatNumber(item.price)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const Footer = (props) => {
        const { footer } = layout;
        return (
            <div style={{ ...footer.containerStyle, height: footer.height }}>
                {footer.html ? (
                    ReactHtmlParser(
                        insertDataToHtmlString(footer.html, footer.queries)
                    )
                ) : (
                    // <img
                    //     src={`${systemStoragePath}/${footer.imgSrc}`}
                    //     alt="footer"
                    // />
                    <div>soon image</div>
                )}
            </div>
        );
    };

    return (
        <>
            <div>
                <div ref={ref}>
                    {layout && (
                        <div style={{ padding: "50px 50px 0px" }}>
                            <Header />
                            <BillTo />
                            <Items />
                            <Footer />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
});

export default InvoicePad;
