import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Card, Empty } from "antd";

import ContentLoader from "globalComponents/ContentLoader";
import ProfileForm from "./components/Forms/ProfileForm/Index";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ProfileCompletion = ({
    breakpoint,
    userID,
    userDetails,
    setRequestError
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [doctorDetails, setDoctorDetails] = useState(null);
    const [isDoctorNotFound, setIsDoctorFound] = useState(false);
    const [aops, setAops] = useState([]);
    const [aopsSub, setAopsSub] = useState([]);

    const [hospitals, setHospitals] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.all([
                    http.get(`${apiUrl}/doctor/${userID}`, {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }),
                    http.get(`${apiUrl}/hospitals`, {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    })
                ]);

                const [doctorResponse, hospitalsResponse] = response;

                const { doctor } = doctorResponse.data;
                const { hospitals } = hospitalsResponse.data;

                //console.log(doctor);
                if (doctor) {
                    const aopsAndAopSubs = await http.get(
                        `${apiUrl}/aopSubAop`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    const { aops, aopsSub } = aopsAndAopSubs.data;

                    setDoctorDetails(doctor);
                    setAops(aops);
                    setAopsSub(aopsSub);
                    setHospitals(hospitals);
                } else {
                    setIsDoctorFound(true);
                }

                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };
        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    return isContentLoading ? (
        <ContentLoader />
    ) : isDoctorNotFound ? (
        <div style={{ marginTop: 30 }}>
            <Empty description="Doctor Not Found." />
        </div>
    ) : (
        <Card>
            <ProfileForm
                doctorDetails={doctorDetails}
                aops={aops}
                aopsSub={aopsSub}
                hospitals={hospitals}
                breakpoint={breakpoint}
                //onSubmit={handleSubmit}
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompletion);
