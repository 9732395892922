import React from "react";
import DynamicForm from "globalComponents/DynamicForm/Index";

const PatientHistory = ({ form, errors, breakpoint, onInputChange }) => {
    return form.length !== 0 ? (
        <div style={{ paddingLeft: breakpoint.isNormalMobile ? 0 : 20 }}>
            <DynamicForm
                mode="form"
                form={form}
                errors={errors}
                layout={null}
                onInputChange={onInputChange}
            />
        </div>
    ) : (
        <div style={{ textAlign: "center" }}>No Patient History Form</div>
    );
};

export default PatientHistory;
