import React from "react";
import { Icon } from "antd";

const ContentLoader = () => {
    return (
        <div>
            <div
                style={{
                    marginTop: 30,
                    textAlign: "center",
                    fontSize: "3rem",
                }}
            >
                <Icon type="loading" />
            </div>
            <div style={{ textAlign: "center" }}>Loading ...</div>
        </div>
    );
};

export default ContentLoader;
