import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Icon, Select, message } from "antd";
import Drawer from "globalComponents/Drawer";

const ServiceDrawer = ({ breakpoint, onServiceAdd, items: services }) => {
    const { Option } = Select;

    const [isServiceFormVisible, setIsServiceFormVisible] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const toggleServiceFormVisible = () => {
        setIsServiceFormVisible(!isServiceFormVisible);
    };

    const handleSelectChange = (value) => {
        setSelectedService(
            services.filter((service) => service.item_id === value)[0]
        );
    };

    const handleServiceAdd = () => {
        if (selectedService) {
            onServiceAdd(selectedService);
        } else {
            message.error("Please select a service");
        }
    };

    // let isShowIconText = true;
    // if (breakpoint.isNormalMobile) {
    //     isShowIconText = false;
    // } else if (breakpoint.isTabletPortrait) {
    //     isShowIconText = true;
    // } else if (breakpoint.isTabletLandscape) {
    //     isShowIconText = false;
    // }

    return (
        <>
            <Drawer
                title="Services"
                placement="right"
                visible={isServiceFormVisible}
                onClose={toggleServiceFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <div>Search Service</div>
                <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleSelectChange}
                    style={{ width: "100%" }}
                    placeholder="Search Service"
                >
                    {services
                        .filter(
                            (service) =>
                                service.item_type === "service" &&
                                service.status !== "inactive"
                        )
                        .map((service) => {
                            return (
                                <Option
                                    key={service.item_id}
                                    value={service.item_id}
                                >
                                    {service.name}
                                </Option>
                            );
                        })}
                </Select>
                <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={handleServiceAdd}
                >
                    Add
                </Button>
            </Drawer>
            <div onClick={toggleServiceFormVisible}>
                <Icon type="schedule" />
                <br />
                Add Service
            </div>
            {/* <Button type="link" block onClick={toggleServiceFormVisible}>
                <Icon type="schedule" /> Add Service
            </Button> */}
            {/* <Tooltip title="Add Service">
                <Button size="large" onClick={toggleServiceFormVisible}>
                    <Icon type="schedule" />
                </Button>
            </Tooltip> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    items: state.Items,
});

export default connect(mapStateToProps)(ServiceDrawer);
