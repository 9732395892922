import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";

const SidebarMenu = ({ activeMenu, breakpoint, history, onSelect, plan }) => {
    const SubMenu = Menu.SubMenu;

    const changeRoute = (route) => {
        return history.push(route);
    };

    return (
        <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[activeMenu]}
            selectedKeys={[activeMenu]}
            style={{ border: "none" }}
        >
            <Menu.Item
                key="Case List"
                onClick={() => {
                    //hide drawer when menu clicked
                    breakpoint.isTabletPortrait && onSelect();
                    changeRoute("/cases");
                }}
            >
                <Icon type="solution" />
                <span>Cases</span>
            </Menu.Item>
            <Menu.Item
                key="Patients"
                onClick={() => {
                    breakpoint.isTabletPortrait && onSelect();
                    changeRoute("/patients");
                }}
            >
                <Icon type="team" />
                <span>Patients</span>
            </Menu.Item>
            <Menu.Item
                key="Appointments"
                onClick={() => {
                    breakpoint.isTabletPortrait && onSelect();
                    changeRoute("/appointments");
                }}
            >
                <Icon type="calendar" />
                <span>Appointments</span>
            </Menu.Item>
            <Menu.Item
                key="Items and Services"
                onClick={() => {
                    breakpoint.isTabletPortrait && onSelect();
                    changeRoute("/items");
                }}
            >
                <Icon type="database" />
                <span>Items and Services</span>
            </Menu.Item>
            {plan !== "basic" && (
                <Menu.Item
                    key="Billing"
                    onClick={() => {
                        breakpoint.isTabletPortrait && onSelect();
                        changeRoute("/billing");
                    }}
                >
                    <Icon type="credit-card" />
                    <span>Billing</span>
                </Menu.Item>
            )}
            <Menu.Item
                key="Reports"
                onClick={() => {
                    breakpoint.isTabletPortrait && onSelect();
                    changeRoute("/reports");
                }}
            >
                <Icon type="pie-chart" />
                <span>Reports</span>
            </Menu.Item>
            <SubMenu
                key="submenu1"
                title={
                    <span>
                        <Icon type="form" />
                        <span>Others</span>
                    </span>
                }
            >
                <Menu.Item
                    key="Accredited Hmo"
                    onClick={() => {
                        breakpoint.isTabletPortrait && onSelect();
                        changeRoute("/hmo");
                    }}
                >
                    <Icon type="idcard" />
                    HMO
                </Menu.Item>
                <Menu.Item
                    key="Patient Groups"
                    onClick={() => {
                        breakpoint.isTabletPortrait && onSelect();
                        changeRoute("/groups");
                    }}
                >
                    <Icon type="inbox" />
                    Groups
                </Menu.Item>
                <Menu.Item
                    key="Papers"
                    onClick={() => {
                        breakpoint.isTabletPortrait && onSelect();
                        changeRoute("/papers");
                    }}
                >
                    <Icon type="file" />
                    Papers
                </Menu.Item>
            </SubMenu>
        </Menu>
    );
};

const mapStateToProps = (state) => ({
    plan: state.UserDetails.subscriptionDetails
        ? state.UserDetails.subscriptionDetails.plan
        : "",
});

export default withRouter(connect(mapStateToProps)(SidebarMenu));
