import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Form } from "antd";

const AddMessageForm = ({ userID, clinicID, onSubmit }) => {
    const [formData, setFormData] = useState({
        title: "",
        message: "",
        user_id: userID,
        clinic_id: clinicID,
    });
    const [formErrors, setFormErrors] = useState({
        title: "",
        message: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { title, message } = formData;
        let errors = {};

        if (title.trim() === "") {
            errors.title = "Title is required";
        }

        if (message.trim() === "") {
            errors.message = "Message is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <Form>
            <Form.Item
                label="Enter title"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.title && "error"}
                help={formErrors.title && formErrors.title}
            >
                <Input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item
                label="Enter message"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.message && "error"}
                help={formErrors.message && formErrors.message}
            >
                <Input
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

export default connect(mapStateToProps)(AddMessageForm);
