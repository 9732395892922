import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bulkAdjustItemStockQty } from "redux/items/item.actions";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Empty,
    Button,
    Statistic,
    Table,
    Typography,
    Tag,
    Card,
    message,
    Modal,
    Icon
} from "antd";

import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import PatientSearchBar from "globalComponents/PatientSearchBar";
import PatientCard from "globalComponents/PatientCard";
import ActionMenu from "globalComponents/ActionMenu";
import Drawer from "globalComponents/Drawer";

import BillingForm from "./components/Forms/BillingForm";
import PaymentForm from "./components/Forms/PaymentForm";

import http from "services/httpService";
import { formatNumber, formatNumberWithSign } from "helpers/numberFormatter";
import { batchInsertQueryFormatter } from "helpers/batchInsertQueryFormatter";
import { findUserName } from "helpers/userNameFinder";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Billing = ({
    breakpoint,
    items,
    userID,
    userType,
    clinicID,
    clinics,
    bulkAdjustItemStockQty,
    setRequestError,
    userPrivileges,
    ...props
}) => {
    const [isPatientNotFound, setIsPatientNotFound] = useState(false);
    const [isBillsHistoryContentLoading, setIsBillsHistoryContentLoading] =
        useState(false);
    const [isBillingFormContentLoading, setIsBillingFormContentLoading] =
        useState(false);

    const [patientID, setPatientID] = useState(null);
    const [patientFullInfo, setPatientFullInfo] = useState({
        lastname: "",
        firstname: "",
        middlename: ""
    });

    const [patientBills, setPatientBills] = useState([]);
    const [patientPayments, setPatientPayments] = useState({});
    const [selectedPatientBill, setSelectedPatientBill] = useState(null);
    const [patientBillsSummary, setPatientBillsSummary] = useState({
        overallTotal: 0,
        totalAmountPaid: 0,
        totalHmoAmountCovered: 0,
        totalRemainingBalance: 0
    });

    const [isBillingFormVisible, setIsBillingFormVisible] = useState(false);
    const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(false);

    const { Title } = Typography;
    const { confirm } = Modal;

    useEffect(() => {
        const patientID = props.match.params.patient_id;
        if (patientID) {
            setPatientID(patientID);
            getPatientBills(patientID);
        } else {
            setPatientID(null);
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    async function getPatientBills(patientID) {
        setIsBillsHistoryContentLoading(true);
        //reset
        try {
            const response = await http.get(
                `${apiUrl}/bills/patient/${patientID}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            const patientBills = response.data.patientBills;
            const patientPayments = response.data.patientPayments.reduce(
                (acc, payments) => {
                    return {
                        ...acc,
                        [payments.bill_id]: [
                            ...(acc[payments.bill_id] || []),
                            payments
                        ]
                    };
                },
                {}
            );

            setPatientBills(patientBills);
            setPatientPayments(patientPayments);
            computeBillsTotalSummary(patientBills);

            const billID = props.match.params.bill_id;
            const caseID = props.match.params.case_id;

            if (billID) {
                const selectedPatientBill = patientBills.filter(
                    patientBill => patientBill.bill_id === billID
                );
                if (selectedPatientBill.length !== 0) {
                    showBillingForm(selectedPatientBill[0]);
                }
            }

            if (caseID) {
                showBillingForm({
                    patient_id: patientID,
                    case_id: caseID
                });
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                message.error(error.message);
            }
        }

        setIsBillsHistoryContentLoading(false);
    }

    async function changeStockQty(action, billID) {
        try {
            const response = await http.get(`${apiUrl}/bill/items/${billID}`, {
                cancelToken: new CancelToken(function executor(c) {
                    CancelRequest = c;
                })
            });

            const billItems = response.data.billItems;
            const mappedItems = items.reduce((acc, item) => {
                acc[item.item_id] = item;
                return acc;
            }, {});

            const itemLedger = [];

            const updatedQtyItems = billItems
                .map(billItem => {
                    if (billItem.item_type === "product") {
                        const itemID = billItem.item_id;
                        const oldStockQty =
                            mappedItems[billItem.item_id].stock_qty;
                        const billItemQty = billItem.qty;
                        let newItemStockQty = 0;
                        if (action === "decrease") {
                            newItemStockQty = oldStockQty - billItemQty;
                        } else {
                            newItemStockQty = oldStockQty + billItemQty;
                        }

                        itemLedger.push({
                            item_id: itemID,
                            ledger_code: action === "decrease" ? "FPR" : "DEL",
                            ref_number: billItem.bill_id,
                            stock_qty: oldStockQty,
                            additional_qty:
                                billItemQty * (action === "decrease" ? -1 : 1),
                            new_qty: newItemStockQty,
                            reason:
                                action === "decrease"
                                    ? "Item bought"
                                    : "Bill deleted",
                            user_id: userID,
                            clinic_id: clinicID
                        });

                        return {
                            item_id: itemID,
                            stock_qty: newItemStockQty
                        };
                    } else {
                        return false;
                    }
                })
                .filter(billItem => billItem);

            if (updatedQtyItems.length !== 0) {
                bulkAdjustItemStockQty(updatedQtyItems);

                await http.post(
                    `${apiUrl}/bulkItemLedger`,
                    {
                        updatedItems:
                            batchInsertQueryFormatter(updatedQtyItems),
                        itemLedger: batchInsertQueryFormatter(itemLedger)
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    }

    function computeBillsTotalSummary(patientBills) {
        let overallTotal = 0;
        let totalAmountPaid = 0;
        let totalHmoAmountCovered = 0;
        let totalRemainingBalance = 0;

        patientBills
            .filter(patientBill => patientBill.status !== "deleted")
            .forEach(patientBill => {
                overallTotal += patientBill.grand_total;
                totalAmountPaid += patientBill.amount_paid;
                totalHmoAmountCovered += patientBill.hmo_amount_covered;
                totalRemainingBalance += patientBill.amount_balance;
            });

        setPatientBillsSummary({
            overallTotal,
            totalAmountPaid,
            totalHmoAmountCovered,
            totalRemainingBalance
        });
    }

    function showBillingForm(selectedRecord) {
        if (selectedRecord) {
            setSelectedPatientBill(selectedRecord);
        } else {
            setSelectedPatientBill({
                patient_id: patientID
            });
        }
        toggleBillingFormVisible();
    }

    const toggleBillingFormVisible = () => {
        setIsBillingFormVisible(!isBillingFormVisible);
    };

    const togglePaymentFormVisible = () => {
        setIsPaymentFormVisible(!isPaymentFormVisible);
    };

    const showPaymentForm = selectedRecord => {
        setSelectedPatientBill(selectedRecord);
        togglePaymentFormVisible();
    };

    const showConfirmDeleteBill = selectedRecord => {
        confirm({
            title: `Are you sure do you want to delete this bill?`,
            content:
                "deleting a bill will cause a stock quantity increase to the affected product items",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    const billID = selectedRecord.bill_id;
                    resolve(
                        http
                            .put(`${apiUrl}/bill/delete/${billID}`)
                            .then(response => {
                                const newPatientBills = patientBills.map(
                                    patientBill => {
                                        if (patientBill.bill_id === billID) {
                                            return {
                                                ...patientBill,
                                                status: "deleted"
                                            };
                                        }
                                        return patientBill;
                                    }
                                );

                                setPatientBills(newPatientBills);
                                computeBillsTotalSummary(newPatientBills);

                                message.success("Bill successfully deleted!");

                                changeStockQty("increase", billID);
                            })
                            .catch(error => {
                                message.error("Something went wrong!");
                            })
                    );
                });
            },
            onCancel() {}
        });
    };

    const handlePatientSelect = selectedPatient => {
        if (selectedPatient) {
            setPatientID(selectedPatient.patient_id);
            getPatientBills(selectedPatient.patient_id);
        } else {
            setPatientID(null);
            setIsBillingFormVisible(false);
        }
    };

    const handlePatientNotFound = () => {
        setIsPatientNotFound(true);
    };

    const handlePatientCardRequestDone = patientInfo => {
        setPatientFullInfo(patientInfo);
    };

    const handleDeleteBill = selectedRecord => {
        setSelectedPatientBill(selectedRecord);
        showConfirmDeleteBill(selectedRecord);
    };

    const handleBillingFormPay = selectedPatientBill => {
        showPaymentForm(selectedPatientBill);
    };

    const handleBillingFormSubmit = async (formData, action) => {
        try {
            setIsBillingFormContentLoading(true);
            const newBillingFormData = { ...formData };
            const isDecreaseItemQty = newBillingFormData.decreaseItemQty;
            delete newBillingFormData.billItems;
            delete newBillingFormData.isChanged;
            delete newBillingFormData.decreaseItemQty;

            //remove sort property
            const newBillItems = formData.billItems.map(billItem => {
                const newBillItem = { ...billItem };
                delete newBillItem.sort;
                return newBillItem;
            });

            let newPatientBill = { ...newBillingFormData };
            let newPatientBills = [];
            const billID = newBillingFormData.bill_id;

            if (!billID) {
                //insert
                const response = await http.post(
                    `${apiUrl}/bill`,
                    {
                        ...newBillingFormData,
                        billItems: batchInsertQueryFormatter(newBillItems)
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
                newPatientBills = [
                    response.data.newPatientBill,
                    ...patientBills
                ];
                newPatientBill.bill_id = response.data.newPatientBill.bill_id;
            } else {
                //update
                newPatientBills = patientBills.map(patientBill => {
                    if (patientBill.bill_id === billID) {
                        return newBillingFormData;
                    }
                    return patientBill;
                });

                await http.put(
                    `${apiUrl}/bill/update/${billID}`,
                    {
                        ...newBillingFormData,
                        billItems: batchInsertQueryFormatter(newBillItems)
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );
            }

            setPatientBills(newPatientBills);
            computeBillsTotalSummary(newPatientBills);
            setIsBillingFormContentLoading(false);
            if (action === "saveAndPay") {
                if (newPatientBill.amount_balance === 0) {
                    toggleBillingFormVisible();
                } else {
                    showPaymentForm(newPatientBill);
                }
            } else {
                toggleBillingFormVisible();
                message.success("Bill successfully saved!");
            }

            if (isDecreaseItemQty) {
                changeStockQty("decrease", newPatientBill.bill_id);
            }
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const handlePaymentFormSubmit = async formData => {
        try {
            const billID = selectedPatientBill.bill_id;
            const newFormData = { ...formData };
            const status = newFormData.status;
            delete newFormData.status;
            const response = await http.post(
                `${apiUrl}/bill/payment`,
                newFormData,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        CancelRequest = c;
                    })
                }
            );

            const newPatientBills = patientBills.map(patientBill => {
                if (patientBill.bill_id === selectedPatientBill.bill_id) {
                    return {
                        ...patientBill,
                        amount_paid:
                            Number(patientBill.amount_paid) +
                            Number(formData.payment_amount),
                        amount_balance: formData.remaining_balance,
                        status:
                            formData.remaining_balance === 0
                                ? "paid"
                                : "partial"
                    };
                }
                return patientBill;
            });

            setPatientBills(newPatientBills);
            setPatientPayments({
                ...patientPayments,
                [billID]: [
                    response.data.newPayment,
                    ...(patientPayments[billID] || [])
                ]
            });
            computeBillsTotalSummary(newPatientBills);

            togglePaymentFormVisible();
            setIsBillingFormVisible(false);

            if (status === "unpaid") {
                changeStockQty("decrease", billID);
            }

            message.success("Payment Successful");
        } catch (error) {
            if (!error.message.responseCancelled) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        }
    };

    const billsHistoryTableActionMenu = {
        title: "",
        key: "action",
        width: 50,
        render: (text, record) => {
            const menu = [
                {
                    label: "View Details",
                    icon: "folder-open",
                    onClick: () => {
                        showBillingForm(record);
                    }
                },
                {
                    label: "Pay Bill",
                    icon: "check",
                    onClick: () => {
                        showPaymentForm(record);
                    }
                },
                {
                    label: "Delete Bill",
                    icon: "delete",
                    onClick: () => {
                        handleDeleteBill(record);
                    }
                }
            ];

            if (record.status === "paid") {
                menu.splice(1, 1);
            }
            if (record.status === "deleted" || clinicID !== record.clinic_id) {
                menu.splice(1, 2);
            }

            return <ActionMenu menu={menu} layout="compress" />;
        }
    };

    const billsHistoryTableColumns = [
        {
            title: "Status",
            dataIndex: "status",
            width: 120,
            render: (text, record) => {
                let statusColor = "lightgrey";
                if (text === "partial") {
                    statusColor = "green";
                } else if (text === "unpaid") {
                    statusColor = "orange";
                } else if (text === "deleted") {
                    statusColor = "red";
                }

                return <Tag color={statusColor}>{text}</Tag>;
            }
        },
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            width: 150,
            render: (text, record) => moment(text).format("LL")
        },
        {
            title: "Total Amount",
            dataIndex: "grand_total",
            render: (text, record) => formatNumberWithSign(text)
        },
        {
            ...billsHistoryTableActionMenu
        }
    ];

    const billsHistoryTableColumnsMobile = [
        {
            title: "Bills",
            key: "bills",
            render: (text, record) => {
                const { bill_date, grand_total, status } = record;

                let statusColor = "lightgrey";
                if (status === "partial") {
                    statusColor = "green";
                } else if (status === "unpaid") {
                    statusColor = "orange";
                } else if (status === "deleted") {
                    statusColor = "red";
                }

                return (
                    <>
                        <div>
                            <strong>{moment(bill_date).format("LL")}</strong>
                        </div>
                        <div>{formatNumberWithSign(grand_total)}</div>
                        <div style={{ marginTop: 5 }}>
                            <Tag color={statusColor}>{status}</Tag>
                        </div>
                    </>
                );
            }
        },
        {
            ...billsHistoryTableActionMenu
        }
    ];

    const PatientPayments = props => {
        const { bill_id } = props.billData;

        return (
            <div
                style={{
                    padding: 10,
                    marginLeft: breakpoint.isNormalMobile ? -50 : -40
                }}
            >
                <Title level={4}>Payments History</Title>
                {patientPayments[bill_id] ? (
                    <div>
                        {patientPayments[bill_id].map(payment => {
                            const {
                                payment_id,
                                payment_date,
                                previous_balance,
                                payment_amount,
                                remaining_balance,
                                payment_type,
                                user_id,
                                payment_notes,
                                clinic_id
                            } = payment;

                            let clinicName = "Clinic not found";
                            let filteredClinic = clinics.filter(
                                clinic => clinic.clinic_id === clinic_id
                            );
                            if (filteredClinic.length !== 0) {
                                clinicName = filteredClinic[0].name;
                            }

                            return (
                                <Card
                                    key={payment_id}
                                    title={moment(payment_date).format("LLL")}
                                    style={{ marginBottom: 10 }}
                                >
                                    <Row>
                                        <Col xl={12} lg={12} md={12}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: 150
                                                            }}
                                                        >
                                                            <strong>
                                                                Previous
                                                                Balance:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {formatNumberWithSign(
                                                                previous_balance
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Amount Paid:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {formatNumberWithSign(
                                                                payment_amount
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Remaining
                                                                Balance:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {formatNumberWithSign(
                                                                remaining_balance
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col xl={12} lg={12} md={12}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            style={{
                                                                width: 150
                                                            }}
                                                        >
                                                            <strong>
                                                                Payment Type:
                                                            </strong>
                                                        </td>
                                                        <td>{payment_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Payment Notes:
                                                            </strong>
                                                        </td>
                                                        <td className="normalWrap">
                                                            {payment_notes
                                                                ? payment_notes
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Paid To:
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {findUserName(
                                                                user_id
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Paid On:
                                                            </strong>
                                                        </td>
                                                        <td className="normalWrap">
                                                            {clinicName}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })}
                    </div>
                ) : (
                    "No Payments History"
                )}
            </div>
        );
    };

    const PatientBills = () => {
        return (
            <>
                <div style={{ marginBottom: 10 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <h1>&nbsp;&nbsp;Bills History</h1>
                        </Col>
                        <Col>
                            {!isBillsHistoryContentLoading && (
                                <Button
                                    type="primary"
                                    onClick={() => showBillingForm(null)}
                                >
                                    <Icon type="plus" /> Create New Bill
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
                {isBillsHistoryContentLoading ? (
                    <ContentLoader />
                ) : (
                    <>
                        <Row
                            gutter={16}
                            style={{
                                marginBottom: 10
                            }}
                        >
                            <Col xl={6} lg={12} md={12}>
                                <Card>
                                    <Statistic
                                        title="Overall Total"
                                        prefix={<span>&#8369;</span>}
                                        value={formatNumber(
                                            patientBillsSummary.overallTotal
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Card>
                                    <Statistic
                                        title="Total Amount Paid"
                                        prefix={<span>&#8369;</span>}
                                        value={formatNumber(
                                            patientBillsSummary.totalAmountPaid
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Card>
                                    <Statistic
                                        title="Total HMO Covered"
                                        prefix={<span>&#8369;</span>}
                                        value={formatNumber(
                                            patientBillsSummary.totalHmoAmountCovered
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col xl={6} lg={12} md={12}>
                                <Card>
                                    <Statistic
                                        title="Remaining Balance"
                                        prefix={<span>&#8369;</span>}
                                        value={formatNumber(
                                            patientBillsSummary.totalRemainingBalance
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <div
                            style={{
                                marginTop: 10,
                                minWidth: 320,
                                height: "auto",
                                overflowY: "auto",
                                backgroundColor: "#fff"
                            }}
                        >
                            <Table
                                columns={
                                    breakpoint.isNormalMobile
                                        ? billsHistoryTableColumnsMobile
                                        : billsHistoryTableColumns
                                }
                                dataSource={patientBills}
                                rowKey="bill_id"
                                pagination={false}
                                expandedRowRender={record => (
                                    <PatientPayments billData={record} />
                                )}
                                className="reportsTable"
                            />
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {!userPrivileges.isAccessBilling ? (
                <div style={{ textAlign: "center" }}>
                    Cannot access this page. Please contact the owner.
                </div>
            ) : isPatientNotFound ? (
                "Patient not found"
            ) : (
                <Row gutter={16}>
                    <Col xl={8}>
                        <div
                            style={{
                                //backgroundColor: "#fff",
                                //padding: 5,
                                marginBottom: 20
                                //border: "1px solid #e8e8e8"
                            }}
                        >
                            {!props.match.params.patient_id && (
                                <>
                                    <PatientSearchBar
                                        onSelect={handlePatientSelect}
                                        showAddPatientBtn
                                        breakpoint={breakpoint}
                                    />
                                    <div style={{ marginBottom: 3 }}></div>
                                </>
                            )}
                            <PatientCard
                                patientID={patientID}
                                moreDetailsVisible={true}
                                bordered={true}
                                breakpoint={breakpoint}
                                onNotFound={handlePatientNotFound}
                                onRequestDone={handlePatientCardRequestDone}
                            />
                        </div>
                    </Col>
                    <Col xl={16}>
                        {patientID ? (
                            isBillingFormVisible ? (
                                <BillingForm
                                    loading={isBillingFormContentLoading}
                                    patientFullInfo={patientFullInfo}
                                    selectedPatientBill={selectedPatientBill}
                                    onViewPatientBills={
                                        toggleBillingFormVisible
                                    }
                                    onSubmit={handleBillingFormSubmit}
                                    onPay={handleBillingFormPay}
                                    breakpoint={breakpoint}
                                />
                            ) : (
                                //

                                <PatientBills />
                            )
                        ) : (
                            <div
                                style={{
                                    padding: "50px 0",
                                    backgroundColor: "#fff",
                                    border: "1px solid #e8e8e8"
                                }}
                            >
                                <Empty description="No Bill " />
                            </div>
                        )}
                        <Drawer
                            title="Pay Bill"
                            visible={isPaymentFormVisible}
                            onClose={togglePaymentFormVisible}
                            destroyOnClose={true}
                            width={breakpoint.isNormalMobile ? 320 : 700}
                            bodyStyle={
                                breakpoint.isNormalMobile
                                    ? { padding: "24px 12px" }
                                    : null
                            }
                        >
                            <PaymentForm
                                patientBillData={selectedPatientBill}
                                onSubmit={handlePaymentFormSubmit}
                                breakpoint={breakpoint}
                            />
                        </Drawer>
                    </Col>
                </Row>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    userType: state.UserDetails.type,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    clinics: state.UserDetails.clinics,
    items: state.Items,
    userPrivileges: state.Settings.userPrivileges
});

const mapDispatchToProps = dispatch => ({
    bulkAdjustItemStockQty: items => dispatch(bulkAdjustItemStockQty(items)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
