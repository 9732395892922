import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Input, Form, Select } from "antd";

const CaseFolderForm = (props) => {
    const { data, patientID, onSubmit, userID } = props;

    const [formData, setFormData] = useState({
        name: "",
        type: "local",
        patient_id: patientID,
        user_id: userID,
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
    });

    const { Option } = Select;

    useEffect(() => {
        if (data) {
            setFormData({ ...formData, ...data });
        }
    }, []);

    const handleCaseFolderInputChange = (e) => {
        setFormData({
            ...formData,
            name: e.target.value,
        });
    };

    const handleSelectChange = (value) => {
        setFormData({
            ...formData,
            type: value,
            patient_id: value === "global" ? "" : patientID,
        });
    };

    const validateCaseFolderInput = () => {
        const { name } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Name is required";
        }

        if (name.trim().length > 20) {
            errors.name = "Name must not be greater than 20 characters";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateCaseFolderInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
            // try {
            //     //check if foldername already exist
            //     const folderFound = caseFolders.filter(
            //         caseFolder =>
            //             caseFolder.name.toLowerCase() ===
            //             formData.name.trim().toLowerCase()
            //     );

            //     if (folderFound.length !== 0) {
            //         message.error("Folder name already exist");
            //     } else {
            //         setFormLoading(true);
            //         if (dataEditing) {
            //             onEditCaseFolder(formData);
            //             console.log(formData);
            //             await http.put(
            //                 `${apiUrl}/case/folder/update/${selectedCaseFolder.case_folder_id}`,
            //                 formData
            //             );
            //         } else {
            //             const response = await http.post(
            //                 `${apiUrl}/case/folder`,
            //                 formData
            //             );
            //             onAddCaseFolder(response.data.newCaseFolder);
            //         }
            //         setFormLoading(false);
            //     }
            // } catch (error) {
            //     message.error(error.message || "Something went wrong! :(");
            // }
        }
    };

    return (
        <Form>
            <Form.Item
                label="Enter folder name"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.name && "error"}
                help={formErrors.name && formErrors.name}
            >
                <Input
                    ref={(c) => c && c.input.focus()}
                    onChange={handleCaseFolderInputChange}
                    value={formData.name}
                />
            </Form.Item>
            {!data && (
                <Form.Item label="">
                    <Select defaultValue="local" onChange={handleSelectChange}>
                        <Option key={1} value="local">
                            On this patient only
                        </Option>
                        <Option key={2} value="global">
                            Global folder
                        </Option>
                    </Select>
                </Form.Item>
            )}

            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    OK
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
});

export default connect(mapStateToProps)(CaseFolderForm);
