import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    DatePicker,
    Select,
    message,
    Card,
    Tag,
    Icon,
    Switch,
} from "antd";
import moment from "moment";

import ICD10SearchBar from "globalComponents/ICD10SearchBar";
import PatientSearchBar from "globalComponents/PatientSearchBar";
import PatientCard from "globalComponents/PatientCard";
import DynamicForm from "globalComponents/DynamicForm/Index";

import { generateString } from "helpers/randomStringGenerator";
import { batchInsertQueryFormatter } from "helpers/batchInsertQueryFormatter";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";
import { findUserName } from "helpers/userNameFinder";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const CreateCaseForm = ({
    patientID: PID,
    appointmentID,
    appointmentDateTime,
    appointmentDoctor,
    appointmentDetails,
    isFromCwapp,
    clinicID,
    userID,
    userType,
    VSFrom,
    doctors,
    breakpoint,
    setRequestError,
    ...props
}) => {
    const [formData, setFormData] = useState({
        case_name: "",
        chief_complaint: "",
        complaint_datetime: moment().format("YYYY-MM-DD HH:mm"),
        doctor_id: "",
        patient_id: "",
        appointment_id: null,
        clinic_id: clinicID,
        user_id: userID,
        case_folder_id: "",
        starred: 0,
        is_from_cwapp: 0,
        call_duration: "00:00:00",
        status: "pending",
    });
    const [formErrors, setFormErrors] = useState({
        chief_complaint: "",
        complaint_datetime: "",
        doctor_id: "",
    });

    const [patientID, setPatientID] = useState(PID);

    const [vitalSignsForm, setVitalSignsForm] = useState(VSFrom);

    const [isCaseCreating, setIsCaseCreating] = useState(false);
    const [isCaseCreatingAndOpen, setIsCaseCreatingAndOpen] = useState(false);

    const [isPatientNotFound, setIsPatientNotFound] = useState(false);

    const [isUseICD10, setisUseICD10] = useState(false);

    const { TextArea } = Input;
    const { Option } = Select;

    //console.log(moment().format("LLL"));

    useEffect(() => {
        let newFormData = { ...formData };

        if (doctors.length === 1) {
            newFormData = {
                ...newFormData,
                doctor_id: doctors[0].doctor_id,
            };
        } else if (userType !== "staff") {
            newFormData = {
                ...newFormData,
                doctor_id: doctors.find((doctor) => doctor.doctor_id === userID)
                    .doctor_id,
            };
        }

        if (patientID && appointmentID) {
            setPatientID(patientID);

            newFormData = {
                ...newFormData,
                patient_id: patientID,
                appointment_id: appointmentID,
                complaint_datetime: moment(appointmentDateTime).format(
                    "YYYY-MM-DD HH:mm"
                ),
                doctor_id: appointmentDoctor.id,
                chief_complaint: appointmentDetails,
                is_from_cwapp: isFromCwapp,
            };
        } else if (patientID) {
            setPatientID(patientID);

            newFormData = {
                ...newFormData,
                patient_id: patientID,
            };
        }

        setFormData(newFormData);

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handlePatientSelect = (selectedPatient) => {
        if (selectedPatient) {
            setPatientID(selectedPatient.patient_id);
            setFormData({
                ...formData,
                patient_id: selectedPatient ? selectedPatient.patient_id : "",
            });
        } else {
            setPatientID(null);
        }
    };

    const handleDateChange = (dateString) => {
        let errors = { ...formErrors };
        if (dateString !== "") {
            errors.complaint_datetime = "";
            setFormErrors(errors);
        }
        const complaint_datetime = moment(dateString).format(
            "YYYY-MM-DD HH:mm"
        );

        setFormData({
            ...formData,
            complaint_datetime,
        });
    };

    const handleDoctorChange = (value, option) => {
        let errors = { ...formErrors };
        if (value !== "") {
            errors.doctor_id = "";
            setFormErrors(errors);
        }

        setFormData({
            ...formData,
            doctor_id: value,
        });
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const handleSwitchChange = (checked) => {
        setFormData({ ...formData, chief_complaint: "" });
        setisUseICD10(checked);
    };

    const handlePatientNotFound = () => {
        setFormData({ ...formData, patient_id: "" });
        setIsPatientNotFound(true);
    };

    const handleVitalSignsInputChange = (name, value) => {
        //update the value of the patient form
        const newVitalSignsForm = vitalSignsForm.map((formInput) => {
            if (formInput.name === name) {
                return { ...formInput, value };
            }
            return formInput;
        });
        setVitalSignsForm(newVitalSignsForm);
    };

    const validateInput = () => {
        const { chief_complaint, complaint_datetime, doctor_id } = formData;
        let errors = {};

        if (!complaint_datetime) {
            errors.complaint_datetime = "Date and time is required";
        }
        if (chief_complaint === "") {
            errors.chief_complaint = "Chief complaint is required";
        }
        if (!patientID) {
            message.error("Please select a patient");
            errors.patient_id = "Patient is required";
        }
        if (doctor_id === "") {
            errors.doctor_id = "Doctor is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (action) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            try {
                if (action === "create") {
                    setIsCaseCreating(true);
                } else {
                    setIsCaseCreatingAndOpen(true);
                }

                const caseID = generateString(10, "c");
                const newFormData = { ...formData, case_id: caseID };

                const caseVitalSigns = vitalSignsForm
                    .filter((formInput) => formInput.value !== "")
                    .map((formInput) => ({
                        vital_sign_id: formInput.name,
                        value: formInput.value,
                    }));

                if (caseVitalSigns.length !== 0) {
                    newFormData.caseVitalSigns = batchInsertQueryFormatter(
                        caseVitalSigns
                    );
                } else {
                    newFormData.caseVitalSigns = caseVitalSigns;
                }

                const response = await http.post(
                    `${apiUrl}/case`,
                    newFormData,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                if (response.data.patientCaseExist) {
                    message.error(
                        `Your selected patient was already on the ${moment(
                            formData.complaint_datetime
                        ).format("MMMM DD, YYYY")} case list.`
                    );
                    if (action === "create") {
                        setIsCaseCreating(false);
                    } else {
                        setIsCaseCreatingAndOpen(false);
                    }
                } else {
                    await http.post(`${apiUrl}/activityLog`, {
                        system_activity_id: 3,
                        data: JSON.stringify({
                            id: caseID,
                        }),
                        user_id: userID,
                    });

                    socket.emit("newCaseCreated", {
                        chiefComplaint: formData.chief_complaint,
                        doctor: findUserName(formData.doctor_id),
                        createdBy: findUserName(formData.user_id),
                        clinicID,
                    });

                    if (action === "create") {
                        props.history.push("/cases");
                    } else {
                        props.history.push(`/casenote/${caseID}`);
                    }
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        }
    };

    return (
        <>
            {isPatientNotFound ? (
                "Patient not found!"
            ) : (
                <Row gutter={16}>
                    <Col xl={8} lg={8}>
                        {!PID && (
                            <>
                                <PatientSearchBar
                                    onSelect={handlePatientSelect}
                                    showAddPatientBtn
                                    breakpoint={breakpoint}
                                />
                                <div style={{ marginBottom: 3 }}></div>
                            </>
                        )}
                        <div style={{ marginBottom: 16 }}>
                            <PatientCard
                                patientID={patientID}
                                bordered={true}
                                breakpoint={breakpoint}
                                moreDetailsVisible={true}
                                showEditPatientInfoBtn={true}
                                onNotFound={handlePatientNotFound}
                            />
                        </div>
                    </Col>
                    <Col xl={16} lg={16}>
                        <Card title="Details">
                            <div
                                style={{ textAlign: "right", marginBottom: 10 }}
                            >
                                {isFromCwapp ? (
                                    <Tag color="purple">
                                        <Icon type="pushpin" /> From Patient
                                        Portal
                                    </Tag>
                                ) : (
                                    ""
                                )}
                            </div>
                            <Form
                                layout="horizontal"
                                labelCol={{
                                    xl: { span: 8 },
                                    lg: { span: 8 },
                                }}
                                wrapperCol={{
                                    xl: { span: 16 },
                                    lg: { span: 16 },
                                }}
                                style={{
                                    width: "100%",
                                    marginTop: breakpoint.isNormalMoible
                                        ? 10
                                        : 0,
                                }}
                            >
                                <Form.Item
                                    label="Date"
                                    required
                                    hasFeedback
                                    validateStatus={
                                        formErrors.complaint_datetime && "error"
                                    }
                                    help={
                                        formErrors.complaint_datetime &&
                                        formErrors.complaint_datetime
                                    }
                                >
                                    <DatePicker
                                        value={
                                            formData.complaint_datetime &&
                                            moment(formData.complaint_datetime)
                                        }
                                        showTime={{
                                            use12Hours: true,
                                            format: "hh:mm A",
                                        }}
                                        format="LLL"
                                        placeholder="Select Date"
                                        allowClear={false}
                                        style={{ width: "100%" }}
                                        onChange={handleDateChange}
                                        disabled={
                                            isFromCwapp === 0 ? false : true
                                        }
                                        // disabledDate={(current) =>
                                        //     current < moment().startOf("day")
                                        // }
                                    />
                                </Form.Item>
                                {doctors.length > 1 && (
                                    <Form.Item
                                        label="Doctor"
                                        required
                                        hasFeedback
                                        validateStatus={
                                            formErrors.doctor_id && "error"
                                        }
                                        help={
                                            formErrors.doctor_id &&
                                            formErrors.doctor_id
                                        }
                                    >
                                        <Select
                                            value={formData.doctor_id}
                                            placeholder="Select Doctor"
                                            disabled={
                                                isFromCwapp === 0 ? false : true
                                            }
                                            onChange={(value, option) =>
                                                handleDoctorChange(
                                                    value,
                                                    option
                                                )
                                            }
                                        >
                                            {doctors.map((doctor) => (
                                                <Option
                                                    key={doctor.doctor_id}
                                                    value={doctor.doctor_id}
                                                >
                                                    {doctor.doctor_name}
                                                </Option>
                                            ))}
                                            <Option key={0} value="any">
                                                Any
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                )}
                                <div
                                    style={{
                                        textAlign: "right",
                                        margin: "10px 0 10px",
                                    }}
                                >
                                    Look on ICD 10:{" "}
                                    <Switch onChange={handleSwitchChange} />
                                </div>
                                <Form.Item
                                    label="Chief Complaint"
                                    required
                                    hasFeedback
                                    validateStatus={
                                        formErrors.chief_complaint && "error"
                                    }
                                    help={
                                        formErrors.chief_complaint &&
                                        formErrors.chief_complaint
                                    }
                                >
                                    {isUseICD10 ? (
                                        <ICD10SearchBar
                                            onSelect={handleInputChange}
                                            onBlur={handleInputBlur}
                                        />
                                    ) : (
                                        <TextArea
                                            name="chief_complaint"
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            value={formData.chief_complaint}
                                        />
                                    )}
                                </Form.Item>
                            </Form>
                        </Card>
                        <Card title="Vitals" style={{ marginTop: 16 }}>
                            <DynamicForm
                                mode="form"
                                form={vitalSignsForm}
                                errors={{}}
                                layout={null}
                                onInputChange={handleVitalSignsInputChange}
                            />
                        </Card>
                        <div style={{ marginTop: 16 }}>
                            <Button
                                type="primary"
                                loading={isCaseCreating}
                                disabled={isCaseCreatingAndOpen}
                                size="large"
                                style={{ marginRight: 5 }}
                                onClick={() => handleSubmit("create")}
                            >
                                {isCaseCreating
                                    ? "Creating Case"
                                    : "Create Case"}
                            </Button>
                            <Button
                                loading={isCaseCreatingAndOpen}
                                disabled={isCaseCreating}
                                size="large"
                                onClick={() => handleSubmit("createopen")}
                            >
                                {isCaseCreatingAndOpen
                                    ? "Creating"
                                    : "Create and Open Case"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    //userName: `${state.UserDetails.firstname} ${state.UserDetails.lastname}`,
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    userType: state.UserDetails.type,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    VSFrom: state.Settings.vitalSignsForm,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCaseForm);
