import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Form, Input, Button, message, Select, Divider, Skeleton } from "antd";
import { generateString } from "helpers/randomStringGenerator";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const UserForm = ({
    data,
    onSubmit,
    ownerID,
    clinicID,
    userID,
    doctors,
    //areaOfPractice,
    setRequestError,
}) => {
    const [formData, setFormData] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        username: "",
        password: "cwadefault",
        //area_of_practice: areaOfPractice,
        practicing_since: "",
        license_number: "",
        ptr: "",
        school: "",
        degree: "",
        year: "",
        status: "active",
        owner_id: ownerID,
        clinic_id: clinicID,
        user_id: "",
        type: "staff",
    });

    const [formErrors, setFormErrors] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        username: "",
        //area_of_practice: "",
        practicing_since: "",
        license_number: "",
        ptr: "",
        school: "",
        degree: "",
        year: "",
    });

    const [isUserSaving, setIsUserSaving] = useState(false);
    const [isFormContentLoading, setIsFormContentLoading] = useState(false);

    const [selectedUserType, setSelectedUserType] = useState("staff");

    const { Option } = Select;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFormContentLoading(true);
                if (data.type === "doctor") {
                    const response = await http.get(
                        `${apiUrl}/doctor/${data.user_id}`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            }),
                        }
                    );

                    setSelectedUserType("doctor");
                    setFormData({
                        ...formData,
                        ...response.data.doctor,
                        type: "doctor",
                    });

                    setIsFormContentLoading(false);
                } else {
                    const response = await http.get(
                        `${apiUrl}/staff/${data.user_id}`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            }),
                        }
                    );

                    setSelectedUserType("staff");
                    setFormData({
                        ...formData,
                        ...response.data.staff,
                        type: "staff",
                    });
                    setIsFormContentLoading(false);
                }
            } catch (error) {}
        };

        if (data) {
            fetchData();
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const handleUserChange = (value) => {
        setFormData({
            ...formData,
            type: value,
        });
        setSelectedUserType(value);
    };

    const validateInput = () => {
        const {
            firstname,
            middlename,
            lastname,
            email,
            //area_of_practice,
            practicing_since,
            license_number,
            ptr,
            school,
            degree,
            year,
        } = formData;
        let errors = {};
        let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

        if (firstname.trim() === "") {
            errors.firstname = "Firstname is required";
        }
        if (middlename.trim() === "") {
            errors.middlename = "Middlename is required";
        }
        if (lastname.trim() === "") {
            errors.lastname = "Lastname is required";
        }
        if (!emailRegEx.test(String(email).toLowerCase())) {
            errors.email = "Invalid Email";
        }
        if (email.trim() === "") {
            errors.email = "Email is required";
        }

        if (selectedUserType === "doctor") {
            //if (area_of_practice.length === 0) {
            //    errors.area_of_practice = "Area of practice is required";
            //}
            if (practicing_since.length === 0) {
                errors.practicing_since = "Practicing years is required";
            }
            if (license_number.length === 0) {
                errors.license_number = "License number is required";
            }
            if (ptr.length === 0) {
                errors.ptr = "Proffesional tax receipt is required";
            }
            if (school.length === 0) {
                errors.school = "School is required";
            }
            if (degree.length === 0) {
                errors.degree = "Degree is required";
            }
            if (year.length === 0) {
                errors.year = "Year is required";
            }
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            try {
                setIsUserSaving(true);
                if (selectedUserType === "doctor") {
                    if (data) {
                        //update doctor
                        const response = await http.put(
                            `${apiUrl}/doctor/${formData.doctor_id}`,
                            formData,
                            {
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        if (response.data.error) {
                            setFormErrors({
                                ...formErrors,
                                username: response.data.errorMsg,
                            });
                            setIsUserSaving(false);
                        } else {
                            onSubmit(formData);
                        }
                    } else {
                        //add doctor
                        const doctorID = generateString(10, "d", "0123456789");

                        //get aop id of owner
                        const ownerAOPId = doctors.find(
                            (doctor) => doctor.doctor_id === userID
                        ).aop_id;

                        const newFormData = {
                            ...formData,
                            user_id: doctorID,
                            aop_id: ownerAOPId,
                        };

                        const response = await http.post(
                            `${apiUrl}/doctor/`,
                            newFormData,
                            {
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        if (response.data.error) {
                            const errorMsg = response.data.errorMsg;
                            if (errorMsg === "Email already in use.") {
                                setFormErrors({
                                    ...formErrors,
                                    email: errorMsg,
                                });
                            } else if (
                                errorMsg === "Username already in use."
                            ) {
                                setFormErrors({
                                    ...formErrors,
                                    username: errorMsg,
                                });
                            } else {
                                message.error(errorMsg);
                            }
                            setIsUserSaving(false);
                        } else {
                            onSubmit(newFormData);
                        }
                    }
                } else {
                    if (data) {
                        //update staff
                        const response = await http.put(
                            `${apiUrl}/staff/${formData.staff_id}`,
                            formData,
                            {
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        if (response.data.error) {
                            setFormErrors({
                                ...formErrors,
                                username: response.data.errorMsg,
                            });
                            setIsUserSaving(false);
                        } else {
                            onSubmit(formData);
                        }
                    } else {
                        //add staff
                        const staffID = generateString(10, "s");
                        const newFormData = {
                            ...formData,
                            user_id: staffID,
                        };
                        const response = await http.post(
                            `${apiUrl}/staff/`,
                            newFormData,
                            {
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        if (response.data.error) {
                            const errorMsg = response.data.errorMsg;
                            if (errorMsg === "Email already in use.") {
                                setFormErrors({
                                    ...formErrors,
                                    email: errorMsg,
                                });
                            } else if (
                                errorMsg === "Username already in use."
                            ) {
                                setFormErrors({
                                    ...formErrors,
                                    username: errorMsg,
                                });
                            } else {
                                message.error(errorMsg);
                            }
                            setIsUserSaving(false);
                        } else {
                            onSubmit(newFormData);
                        }
                    }
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        }
    };

    return isFormContentLoading ? (
        <Skeleton active />
    ) : (
        <Form
            layout="horizontal"
            labelCol={{
                xl: { span: 7 },
                lg: { span: 6 },
            }}
            wrapperCol={{
                xl: { span: 17 },
                lg: { span: 16 },
            }}
            style={{ width: "100%" }}
        >
            {!data && (
                <>
                    <p>Select User Type</p>
                    <Select defaultValue="staff" onChange={handleUserChange}>
                        <Option value="doctor">Doctor</Option>
                        <Option value="staff">Staff</Option>
                    </Select>
                </>
            )}
            <Divider orientation="center" style={{ fontSize: "0.900em" }}>
                Personal Details
            </Divider>
            <Form.Item
                label="First Name"
                required={true}
                hasFeedback
                validateStatus={formErrors.firstname && "error"}
                help={formErrors.firstname && formErrors.firstname}
            >
                <Input
                    name="firstname"
                    autoComplete="off"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item
                label="Middle Name"
                required={true}
                hasFeedback
                validateStatus={formErrors.middlename && "error"}
                help={formErrors.middlename && formErrors.middlename}
            >
                <Input
                    name="middlename"
                    autoComplete="off"
                    value={formData.middlename}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Form.Item
                label="Last Name"
                required={true}
                hasFeedback
                validateStatus={formErrors.lastname && "error"}
                help={formErrors.lastname && formErrors.lastname}
            >
                <Input
                    name="lastname"
                    autoComplete="off"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            {selectedUserType === "doctor" && (
                <>
                    <Divider
                        orientation="center"
                        style={{ fontSize: "0.900em" }}
                    >
                        Profession Details
                    </Divider>
                    {/* <Form.Item
                        label="Area of practice"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.area_of_practice && "error"}
                        help={
                            formErrors.area_of_practice &&
                            formErrors.area_of_practice
                        }
                    >
                        <Select
                            onChange={value =>
                                handleInputChange({
                                    target: {
                                        name: "area_of_practice",
                                        value
                                    }
                                })
                            }
                            onBlur={value =>
                                handleInputBlur({
                                    target: {
                                        name: "area_of_practice",
                                        value
                                    }
                                })
                            }
                            value={formData.area_of_practice}
                        >
                            <Option value="Anesthesia">Anesthesia</Option>
                            <Option value="Dental Medicine">
                                Dental Medicine
                            </Option>
                            <Option value="Emergency Medicine">
                                Emergency Medicine
                            </Option>
                            <Option value="Family and Community Medicine">
                                Family and Community Medicine
                            </Option>
                            <Option value="Internal Medicine">
                                Internal Medicine
                            </Option>
                            <Option value="Medico Legal Medicine">
                                Medico Legal Medicine
                            </Option>
                            <Option value="Obstetrics and Gynecology">
                                Obstetrics and Gynecology
                            </Option>
                            <Option value="Ophthalmology">Ophthalmology</Option>
                            <Option value="Orthopedics">Orthopedics</Option>
                            <Option value="Otorhinolaryngology (ENT)">
                                Otorhinolaryngology (ENT)
                            </Option>
                            <Option value="Pathology and Clinical Laboratories">
                                Pathology and Clinical Laboratories
                            </Option>
                            <Option value="Pediatrics">Pediatrics</Option>
                            <Option value="Physical Medicine and Rehabilitation">
                                Physical Medicine and Rehabilitation
                            </Option>
                            <Option value="Psychiatry">Psychiatry</Option>
                            <Option value="Radiology">Radiology</Option>
                            <Option value="Surgery">Surgery</Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item
                        label="Practicing Since"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.practicing_since && "error"}
                        help={
                            formErrors.practicing_since &&
                            formErrors.practicing_since
                        }
                    >
                        <Input
                            name="practicing_since"
                            autoComplete="off"
                            value={formData.practicing_since}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="License Number"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.license_number && "error"}
                        help={
                            formErrors.license_number &&
                            formErrors.license_number
                        }
                    >
                        <Input
                            name="license_number"
                            autoComplete="off"
                            value={formData.license_number}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="PTR"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.ptr && "error"}
                        help={formErrors.ptr && formErrors.ptr}
                    >
                        <Input
                            name="ptr"
                            autoComplete="off"
                            value={formData.ptr}
                            placeholder="Proffesional Tax Receipt"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Divider
                        orientation="center"
                        style={{ fontSize: "0.900em" }}
                    >
                        Medical Education
                    </Divider>
                    <Form.Item
                        label="School"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.school && "error"}
                        help={formErrors.school && formErrors.school}
                    >
                        <Input
                            name="school"
                            autoComplete="off"
                            value={formData.school}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Degree"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.degree && "error"}
                        help={formErrors.degree && formErrors.degree}
                    >
                        <Input
                            name="degree"
                            autoComplete="off"
                            value={formData.degree}
                            placeholder="MD, MS, ENT, OB/GYN, PharmD"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Year"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.year && "error"}
                        help={formErrors.year && formErrors.year}
                    >
                        <Input
                            name="year"
                            autoComplete="off"
                            value={formData.year}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                </>
            )}
            <Divider orientation="center" style={{ fontSize: "0.900em" }}>
                Account Details
            </Divider>
            <Form.Item
                label="Email"
                required={true}
                hasFeedback
                validateStatus={formErrors.email && "error"}
                help={formErrors.email && formErrors.email}
            >
                <Input
                    name="email"
                    autoComplete="off"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    disabled={data && true}
                />
            </Form.Item>
            <Form.Item
                label="Username"
                hasFeedback
                validateStatus={formErrors.username && "error"}
                help={formErrors.username && formErrors.username}
            >
                <Input
                    name="username"
                    autoComplete="off"
                    value={formData.username}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    // disabled={data && true}
                />
            </Form.Item>
            {!data && (
                <Form.Item
                    label="Password"
                    hasFeedback
                    validateStatus={null}
                    help={null}
                >
                    {formData.password}
                </Form.Item>
            )}
            <Divider />
            <div style={{ textAlign: "center" }}>
                <Button
                    type="primary"
                    loading={isUserSaving}
                    onClick={handleSubmit}
                >
                    {isUserSaving ? "Saving User" : "Save User"}
                </Button>
            </div>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    doctors: state.UserDetails.doctors,
    //areaOfPractice: state.UserDetails.area_of_practice
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
