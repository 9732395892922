import {
    SET_HMO_SUCCESS,
    ADD_HMO_SUCCESS,
    EDIT_HMO_SUCCESS,
    DELETE_HMO_SUCCESS
} from "./hmo.types";

export const setHmo = hmo => async dispatch => {
    dispatch({
        type: SET_HMO_SUCCESS,
        payload: { hmo }
    });
};

export const addHmo = newHmo => async dispatch => {
    dispatch({
        type: ADD_HMO_SUCCESS,
        payload: { newHmo }
    });
};

export const editHmo = editedHmo => async dispatch => {
    dispatch({
        type: EDIT_HMO_SUCCESS,
        payload: { editedHmo }
    });
};

export const deleteHmo = hmoID => async dispatch => {
    dispatch({
        type: DELETE_HMO_SUCCESS,
        payload: { hmoID }
    });
};
