import axios from "axios";

import { message } from "antd";
import { defaultToken } from "config.json";

const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : defaultToken;

axios.defaults.headers.common["authorization"] = token;

axios.interceptors.response.use(null, error => {
    if (error.message && error.message.responseCancelled) {
        console.log("response cancelled");
    } else {
        const expectedError =
            error.response &&
            error.response.status >= 400 &&
            error.response.status < 500;

        if (!expectedError) {
            message.error("An unexpected error occurred. Please try again.");
        }
    }

    return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    CancelToken: axios.CancelToken,
    all: axios.all,
    spread: axios.spread
};
