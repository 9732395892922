import React, { useState } from "react";
import { connect } from "react-redux";

import { setRequestError } from "redux/system/system.actions";
import { updatePrescriptionNote } from "redux/items/item.actions";
import { Icon, Select, Divider, message } from "antd";

import Drawer from "globalComponents/Drawer";
import PrescriptionNoteForm from "views/Items/components/Forms/PrescriptionNoteForm";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PrescriptionNoteDrawer = ({
    breakpoint,
    onPrescriptionAdd,
    items,
    updatePrescriptionNote,
    setRequestError
}) => {
    const [isPrescriptionNoteFormVisible, setIsPrescriptionNoteFormVisible] =
        useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const { Option } = Select;

    const togglePrescriptionNoteFormVisible = () => {
        setSelectedItem(null);
        setIsPrescriptionNoteFormVisible(!isPrescriptionNoteFormVisible);
    };

    const handleSelectChange = value => {
        setSelectedItem(items.filter(item => item.item_id === value)[0]);
    };

    const handlePrescriptionAdd = prescription => {
        onPrescriptionAdd(prescription);
    };

    const handlePrescriptionNoteFormSave = async formData => {
        try {
            updatePrescriptionNote(formData);

            message.success("Prescription note saved!");

            const itemID = formData.item_id;
            const stringPrescriptionNote = JSON.stringify(
                formData.prescription_note
            );

            if (selectedItem.prescription_note) {
                //update db
                await http.put(`${apiUrl}/prescriptionNote/${itemID}`, {
                    prescription_note: stringPrescriptionNote
                });
            } else {
                //add to db
                await http.post(`${apiUrl}/prescriptionNote`, {
                    item_id: itemID,
                    prescription_note: stringPrescriptionNote
                });
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    // let isShowIconText = true;
    // if (breakpoint.isNormalMobile) {
    //     isShowIconText = false;
    // } else if (breakpoint.isTabletPortrait) {
    //     isShowIconText = true;
    // } else if (breakpoint.isTabletLandscape) {
    //     isShowIconText = false;
    // }

    return (
        <>
            <Drawer
                title="Prescription Notes"
                placement="right"
                visible={isPrescriptionNoteFormVisible}
                onClose={togglePrescriptionNoteFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
            >
                <div>Search Item</div>
                <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleSelectChange}
                    style={{ width: "100%" }}
                    placeholder="Search Item"
                >
                    {items
                        .filter(item => item.status !== "inactive")
                        .map(item => {
                            if (item.item_type === "product") {
                                return (
                                    <Option
                                        key={item.item_id}
                                        value={item.item_id}
                                    >
                                        {item.name}
                                    </Option>
                                );
                            }
                            return null;
                        })}
                </Select>
                <Divider />
                <PrescriptionNoteForm
                    item={selectedItem}
                    onAdd={handlePrescriptionAdd}
                    onSave={handlePrescriptionNoteFormSave}
                />
            </Drawer>
            <div onClick={togglePrescriptionNoteFormVisible}>
                <Icon type="file-ppt" />
                <br />
                Prescribe
            </div>
            {/* <Button
                type="link"
                block
                onClick={togglePrescriptionNoteFormVisible}
            >
                <Icon type="file-ppt" /> Prescribe
            </Button> */}
            {/* <Tooltip title="Add Prescription">
                <Button
                    size="large"
                    onClick={togglePrescriptionNoteFormVisible}
                >
                    <Icon type="file-ppt" />
                </Button>
            </Tooltip> */}
        </>
    );
};

const mapStateToProps = state => ({
    items: state.Items
});

const mapDispatchToProps = dispatch => ({
    updatePrescriptionNote: prescriptionNote =>
        dispatch(updatePrescriptionNote(prescriptionNote)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrescriptionNoteDrawer);
