import React, { useState } from "react";
import { Button, Icon } from "antd";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url }) => {
    const [PDFNumPages, setPDFNumPages] = useState(null);
    const [PDFPageNumber, setPDFPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setPDFNumPages(numPages);
    }

    const showPrevPage = () => {
        setPDFPageNumber((prevState) => prevState - 1);
    };
    const showNextPage = () => {
        setPDFPageNumber((prevState) => prevState + 1);
    };

    return (
        <>
            <div
                style={{
                    padding: 10,
                    backgroundColor: "#000",
                    opacity: "0.5",
                    textAlign: "center",
                }}
            >
                <Button
                    size="small"
                    disabled={PDFPageNumber === 1}
                    style={{ marginRight: 10 }}
                    onClick={showPrevPage}
                >
                    <Icon type="left" />
                </Button>
                <span style={{ color: "#fff" }}>
                    Page {PDFPageNumber} of {PDFNumPages}
                </span>
                <Button
                    size="small"
                    disabled={PDFPageNumber === PDFNumPages}
                    style={{ marginLeft: 10 }}
                    onClick={showNextPage}
                >
                    <Icon type="right" />
                </Button>
            </div>
            <div>
                <div
                    style={{
                        margin: "0 auto",
                        maxWidth: 840,
                        textAlign: "center",
                        paddingTop: 30,
                        paddingBottom: 30,
                    }}
                >
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error) => console.log(error)}
                    >
                        <Page pageNumber={PDFPageNumber} />
                    </Document>
                </div>
            </div>
            {/* 
            <p>
                Page {PDFPageNumber} of {PDFNumPages}
            </p> */}
        </>
    );
};

export default PDFViewer;
