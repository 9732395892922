import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    setIsStreamContentVisible,
    setVideoCallDetails
} from "redux/videoCaller/videoCaller.actions";
import { Button, Icon, Modal, Row, Col, Avatar } from "antd";
import moment from "moment";

import Fullscreen from "react-full-screen";
import Draggable from "react-draggable"; // the default

import ContactPatient from "./components/ContactPatient";
import Streamer from "./components/Streamer/Index";

//nice asd asdas bbb

import { socket } from "layouts/Main";
import ContactingAudio from "assets/sound/contacting.mp3";
import { Sound, isSoundOn } from "globalComponents/ToggleSound";

import http from "services/httpService";
import { apiUrl } from "config.json";

const VideoCaller = ({
    breakpoint,
    userID,
    videoCallDetails,
    isStreamContentVisible,
    setIsStreamContentVisible,
    setVideoCallDetails,
    setRequestError
}) => {
    const [isStreamVisible, setIsStreamVisible] = useState(false);
    const [callingTimeoutID, setCallingTimeoutID] = useState(null);

    const [streamWindowState, setStreamWindowState] = useState("normal");
    //normal, maximized, minimized and fullscreen
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        if (breakpoint.isTabletLandscape) {
            setStreamWindowState("maximized");
        } else {
            setStreamWindowState("normal");
        }
    }, []);

    useEffect(() => {
        let t = null;
        if (isStreamContentVisible) {
            t = setTimeout(() => {
                setIsStreamContentVisible(false);
                Modal.error({
                    title: "Patient Not Responding",
                    content: "No response from the patient. Please try again."
                });
                //cancel the call
                Sound.pause();
                socket.emit("cancelContactPatient", {
                    caller_id: userID,
                    video_call_room_id:
                        videoCallDetails.caseDetails
                            .appointment_video_call_room_id
                });
            }, 30000);
            setCallingTimeoutID(t);
            if (isSoundOn) {
                Sound.src = ContactingAudio;
                Sound.loop = true;
                Sound.play();
            }
        }
        return () => {
            clearTimeout(t);
        };
    }, [isStreamContentVisible]);

    useEffect(() => {
        socket.on("patientAnswered", function () {
            Sound.pause();
            clearTimeout(callingTimeoutID);
            setIsStreamVisible(true);
        });
    }, [callingTimeoutID]);

    const handleCancelPatientCall = () => {
        //cancel the call
        Sound.pause();
        socket.emit("cancelContactPatient", {
            caller_id: userID,
            video_call_room_id:
                videoCallDetails.caseDetails.appointment_video_call_room_id
        });
        clearTimeout(callingTimeoutID);
        setIsStreamVisible(false);
        setIsStreamContentVisible(false);
    };

    const handleStreamLeave = async (duration, action, isAllowExcessTime) => {
        setIsStreamContentVisible(false);
        setIsStreamVisible(false);
        setVideoCallDetails({
            initStreamTime: duration
        });
        setStreamWindowState(
            breakpoint.isTabletLandscape ? "maximized" : "normal"
        );

        if (action === "leaved") {
            Modal.info({
                title: "Call Ended",
                content: (
                    <div>
                        <p>
                            You&rsquo;ve ended your call. Below are your call
                            summary:
                        </p>
                        <div>
                            Call Duration:{" "}
                            {moment.utc(duration * 1000).format("HH:mm:ss")}
                        </div>
                        <div>
                            Max Call Duration:{" "}
                            {videoCallDetails.caseDetails.appointment_duration}{" "}
                            mins
                        </div>
                        <div>
                            Allowed Excess Time:{" "}
                            {isAllowExcessTime ? "Yes" : "No"}
                        </div>
                    </div>
                )
            });
        } else if (action === "timesup") {
            Modal.info({
                title: "Maximum Allowed Time Reached",
                content: (
                    <div>
                        <p>
                            You reached your time limit. Below are your call
                            summary:
                        </p>
                        <div>
                            Call Duration:{" "}
                            {moment.utc(duration * 1000).format("HH:mm:ss")}
                        </div>
                        <div>
                            Max Call Duration:{" "}
                            {videoCallDetails.caseDetails.appointment_duration}{" "}
                            mins
                        </div>
                        <div>
                            Allowed Excess Time:{" "}
                            {isAllowExcessTime ? "Yes" : "No"}
                        </div>
                        <p style={{ marginTop: 10 }}>
                            You can still call the patient but any excess
                            minutes will be charge to your account accordingly.
                        </p>
                    </div>
                )
            });
        } else {
            Modal.info({
                title: "Patient Disconnected",
                content: (
                    <div>
                        <p>Patient has been disconnected. Try to call again.</p>
                    </div>
                )
            });
        }

        try {
            await http.post(
                `${apiUrl}/videoCallLog`,
                {
                    appointment_id: videoCallDetails.caseDetails.appointment_id,
                    start: "",
                    end: moment().format("HH:mm:ss"),
                    action
                },
                {
                    headers: {
                        conn: "pp"
                    }
                }
            );
            await http.put(
                `${apiUrl}/case/${videoCallDetails.caseDetails.case_id}`,
                {
                    call_duration: moment
                        .utc(duration * 1000)
                        .format("HH:mm:ss")
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    const handleWindowStatechange = windowState => {
        if (windowState === "fullscreen") {
            setIsFullScreen(true);
        } else {
            setIsFullScreen(false);
        }
        setStreamWindowState(windowState);
    };

    const handleFullscreenChange = isFull => {
        if (isFull) {
            handleWindowStatechange("fullscreen");
        } else {
            handleWindowStatechange("normal");
        }
    };

    const handleMinimizedSwitcherClick = () => {
        if (breakpoint.isTabletLandscape) {
            setStreamWindowState("maximized");
        } else {
            setStreamWindowState("normal");
        }
    };

    const channelName = `${userID}_${videoCallDetails.caseDetails.appointment_video_call_room_id}`;

    const { img_path: patientImgPath } = videoCallDetails.patientDetails;

    return (
        <>
            {streamWindowState === "minimized" && (
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        padding: 10,
                        backgroundColor: "#000",
                        zIndex: 10
                    }}
                >
                    <Row type="flex" justify="space-between">
                        <Col>
                            <span style={{ color: "#fff", fontSize: "1rem" }}>
                                {patientImgPath ? (
                                    <Avatar
                                        size={32}
                                        shape="circle"
                                        src={patientImgPath.replace(
                                            ".jpeg",
                                            "-thumb.jpeg"
                                        )}
                                        alt="Patient Image"
                                    />
                                ) : (
                                    <Avatar
                                        icon="user"
                                        size={32}
                                        shape="circle"
                                    />
                                )}
                                &nbsp;&nbsp;&nbsp;
                                <Icon type="video-camera" theme="filled" />{" "}
                                <span style={{ color: "#87d068" }}>&bull;</span>
                                &nbsp;&nbsp;Video call ongoing ...
                            </span>
                        </Col>
                        <Col>
                            {/* <Button icon="switcher"></Button> */}
                            {breakpoint.isNormalMobile ? (
                                <Button
                                    type="link"
                                    icon="switcher"
                                    onClick={handleMinimizedSwitcherClick}
                                />
                            ) : (
                                <Button
                                    type="link"
                                    icon="switcher"
                                    onClick={handleMinimizedSwitcherClick}
                                >
                                    Maximized
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            )}

            {isStreamContentVisible && (
                <div
                    style={{
                        zIndex: 10,
                        position: "relative"
                    }}
                >
                    {isStreamVisible ? (
                        <Draggable
                            disabled={
                                streamWindowState === "maximized" ? true : false
                            }
                        >
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left:
                                        streamWindowState === "maximized"
                                            ? "0"
                                            : "calc(50% - 200px)",
                                    width:
                                        streamWindowState === "maximized"
                                            ? "100%"
                                            : 400,
                                    backgroundColor: "#000",
                                    display:
                                        streamWindowState === "minimized"
                                            ? "none"
                                            : "block"
                                }}
                            >
                                <Fullscreen
                                    enabled={isFullScreen}
                                    onChange={handleFullscreenChange}
                                >
                                    <Streamer
                                        channelName={channelName}
                                        videoCallDetails={videoCallDetails}
                                        breakpoint={breakpoint}
                                        streamWindowState={streamWindowState}
                                        onStreamWindowStateChange={
                                            handleWindowStatechange
                                        }
                                        onLeave={handleStreamLeave}
                                    />
                                </Fullscreen>
                            </div>
                        </Draggable>
                    ) : (
                        <ContactPatient
                            videoCallDetails={videoCallDetails}
                            breakpoint={breakpoint}
                            onCancelPatientCall={handleCancelPatientCall}
                        />
                    )}
                </div>
            )}

            {/* {isStreamContentVisible && (
                <div
                    style={{
                        width: 400,
                        height: 400,
                        position: "absolute",
                        bottom: 70,
                        right: 16,
                        zIndex: 10,
                        backgroundColor: "#000",
                        display:
                            streamWindowState === "minimized"
                                ? "none"
                                : "block",
                    }}
                >
                    {isStreamVisible ? (
                        <div>
                            <Streamer
                                channelName={channelName}
                                caseDetails={videoCallDetails.caseDetails}
                                initTime={videoCallDetails.initStreamTime}
                                maxStreamTime={videoCallDetails.maxStreamTime}
                                allowExcessTime={
                                    videoCallDetails.isAllowExcessTime
                                }
                                windowState={streamWindowState}
                                onWindowStateChange={handleWindowStatechange}
                                onLeave={handleStreamLeave}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#000",
                            }}
                        >
                            <div
                                style={{
                                    color: "#fff",
                                    paddingTop: 60,
                                    textAlign: "center",
                                }}
                            >
                                <div>
                                    <Icon type="loading" />
                                    <br />
                                    Contacting Patient ...
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Button
                                        type="danger"
                                        onClick={cancelPatientCall}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )} */}
        </>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    videoCallDetails: state.VideoCaller.details,
    isStreamContentVisible: state.VideoCaller.isStreamContentVisible
});

const mapDispatchToProps = dispatch => ({
    setIsStreamContentVisible: isVisible =>
        dispatch(setIsStreamContentVisible(isVisible)),
    setVideoCallDetails: details => dispatch(setVideoCallDetails(details)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoCaller);
