import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    editDoctorPrevileges,
    editStaff
} from "redux/userDetails/userDetails.actions";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Button, Divider, Card, Table, Modal } from "antd";

import FormPrevilege from "./components/Forms/FormPrevilege";

import http from "services/httpService";
import { apiUrl } from "config.json";

const UserPreviledges = ({
    userID,
    doctors: d,
    staffs: s,
    plan,
    editStaff,
    editDoctorPrevileges,
    setRequestError
}) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isFormPrevilegeVisible, setIsFormPrevilegeVisible] = useState(false);

    useEffect(() => {
        let doctors = d.filter(doctor => doctor.doctor_id !== userID);
        doctors = doctors.map(doctor => {
            return {
                user_id: doctor.doctor_id,
                name: doctor.doctor_name,
                previleges: doctor.settings.previleges,
                type: "doctor"
            };
        });
        let staffs = s.map(staff => {
            return {
                user_id: staff.staff_id,
                name: staff.staff_name,
                previleges: staff.settings.previleges,
                type: "staff"
            };
        });
        //console.log([...doctors, ...staffs]);
        setUsers([...doctors, ...staffs]);
    }, []);

    const toggleFormPrevilegeVisible = () => {
        setIsFormPrevilegeVisible(!isFormPrevilegeVisible);
    };

    const showFormPrevilege = selectedRecord => {
        setSelectedUser(selectedRecord);
        toggleFormPrevilegeVisible();
    };

    const handleFormPrevilegeSubmit = async formData => {
        const newUsers = users.map(user => {
            if (user.user_id === selectedUser.user_id) {
                return {
                    ...user,
                    previleges: formData
                };
            }
            return user;
        });
        setUsers(newUsers);
        toggleFormPrevilegeVisible();

        if (selectedUser.type === "staff") {
            editStaff({
                staff_id: selectedUser.user_id,
                settings: {
                    previleges: formData,
                    user_id: selectedUser.user_id
                }
            });
        } else if (selectedUser.type === "doctor") {
            editDoctorPrevileges(selectedUser.user_id, formData);
        }

        try {
            await http.put(`${apiUrl}/userSettings/${selectedUser.user_id}`, {
                previleges: JSON.stringify(formData)
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const tableColumns = [
        {
            title: "User",
            dataIndex: "name"
        },
        {
            title: "User Type",
            dataIndex: "type"
        },
        {
            title: "Access SOAP",
            render: (text, record) => {
                if (record.previleges.isAccessSOAP) {
                    return (
                        <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: "#52c41a" }}
                        />
                    );
                } else {
                    return (
                        <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: "#f44336" }}
                        />
                    );
                }
            }
        },
        {
            title: "Access Billing",
            render: (text, record) => {
                if (record.previleges.isAccessBilling) {
                    return (
                        <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: "#52c41a" }}
                        />
                    );
                } else {
                    return (
                        <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: "#f44336" }}
                        />
                    );
                }
            }
        },
        {
            title: "Access Reports",
            render: (text, record) => {
                if (record.previleges.isAccessReports) {
                    return (
                        <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: "#52c41a" }}
                        />
                    );
                } else {
                    return (
                        <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: "#f44336" }}
                        />
                    );
                }
            }
        },
        {
            title: "Action",
            render: (text, record) => {
                return (
                    <Button
                        type="link"
                        onClick={() => showFormPrevilege(record)}
                    >
                        Edit
                    </Button>
                );
            }
        }
    ];

    if (plan === "basic") {
        tableColumns.splice(2, 1);
    }

    //const [users, setUsers] = useState()

    return (
        <>
            <Card>
                <div style={{ marginBottom: 10 }}>
                    <Link to="/settings">
                        <Icon type="arrow-left" /> Back to Settings Menu
                    </Link>
                </div>

                <Divider />

                <Table
                    columns={tableColumns}
                    dataSource={users}
                    rowKey={"user_id"}
                    pagination={false}
                />
            </Card>
            <Modal
                title="User Previlege"
                visible={isFormPrevilegeVisible}
                //width={700}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleFormPrevilegeVisible}
            >
                <FormPrevilege
                    user={selectedUser}
                    plan={plan}
                    onSubmit={handleFormPrevilegeSubmit}
                />
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    doctors: state.UserDetails.doctors.filter(
        doctor => doctor.status === "active"
    ),
    staffs: state.UserDetails.staffs.filter(staff => staff.status === "active"),
    // UPreviledges: state.Settings.userPrevileges,
    // clinicID: state.UserDetails.clinicDetails.clinic_id,
    plan: state.UserDetails.subscriptionDetails.plan
});

const mapDispatchToProps = dispatch => ({
    updateSettings: updatedSettings =>
        dispatch(updateSettings(updatedSettings)),
    editDoctorPrevileges: (doctorID, newPrevileges) =>
        dispatch(editDoctorPrevileges(doctorID, newPrevileges)),
    editStaff: editedStaff => dispatch(editStaff(editedStaff)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPreviledges);
