import React, { useState } from "react";
import { Switch, Button, Divider } from "antd";
import { useEffect } from "react";

const FormPrevilege = ({ user, plan, onSubmit }) => {
    const [formData, setFormData] = useState({
        isAccessSOAP: false,
        isAccessBilling: false,
        isAccessReports: false,
        isAccessSettings: false,
    });

    useEffect(() => {
        setFormData(user.previleges);
    }, [user]);

    const handleSwitchChange = (name, checked) => {
        setFormData({
            ...formData,
            [name]: checked ? true : false,
        });
    };

    const previlegeOk = () => {
        onSubmit(formData);
    };

    return (
        <div>
            <div>User: {user.name}</div>
            <Divider />
            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 250 }}>
                            Able to Modify SOAP Content
                        </td>
                        <td>
                            <Switch
                                checked={formData.isAccessSOAP}
                                disabled={user.type === "doctor" ? true : false}
                                onChange={(checked) =>
                                    handleSwitchChange("isAccessSOAP", checked)
                                }
                            />
                        </td>
                    </tr>
                    {plan !== "basic" && (
                        <tr>
                            <td>Able to Access Billing Module</td>
                            <td>
                                <Switch
                                    checked={formData.isAccessBilling}
                                    onChange={(checked) =>
                                        handleSwitchChange(
                                            "isAccessBilling",
                                            checked
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Able to Access Reports Module</td>
                        <td>
                            <Switch
                                checked={formData.isAccessReports}
                                onChange={(checked) =>
                                    handleSwitchChange(
                                        "isAccessReports",
                                        checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                    {/* <tr>
                        <td>Able to Modify System Settings</td>
                        <td>
                            <Switch
                                checked={formData.isAccessSettings}
                                onChange={(checked) =>
                                    handleSwitchChange(
                                        "isAccessSettings",
                                        checked
                                    )
                                }
                            />
                        </td>
                    </tr> */}
                </tbody>
            </table>

            <Divider />

            <Button type="primary" size="large" onClick={previlegeOk}>
                OK
            </Button>
        </div>
    );
};

export default FormPrevilege;
