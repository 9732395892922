import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    DatePicker,
    Table,
    Row,
    Col,
    Button,
    Input,
    Icon,
    Tag,
    Statistic,
    Card,
    Divider,
    Tooltip
} from "antd";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { formatNumber } from "helpers/numberFormatter";
import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const HMOReport = ({ breakpoint, hmoList, clinicID, setRequestError }) => {
    const [billsReportTableLoading, setBillsReportTableLoading] = useState(
        false
    );
    const [bills, setBills] = useState([]);
    const [hmoSummary, setHmoSummary] = useState({
        totalHmoAmount: 0
    });
    const [tableData, setTableData] = useState([]);

    const [searchText, setSearchText] = useState("");

    let searchInput = React.createRef();

    const { RangePicker } = DatePicker;

    const isMobileBreakpoint = breakpoint.isNormalMobile;

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const handleBillingDateRangeChange = async (date, dateString) => {
        const [dateStart, dateEnd] = dateString;
        if (dateStart !== "" && dateEnd !== "") {
            try {
                setBillsReportTableLoading(true);
                const response = await http.get(
                    `${apiUrl}/report/bills/${clinicID}/${dateStart}/${dateEnd}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                const { bills } = response.data;

                let totalHmoAmount = 0;

                bills.forEach(bill => {
                    totalHmoAmount += bill.hmo_amount_covered;
                });

                setHmoSummary({
                    totalHmoAmount
                });

                const newBills = bills.filter(bill => bill.hmo_id !== 0);

                setBills(newBills);
                setTableData(newBills);
                setBillsReportTableLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        } else {
            setHmoSummary({
                totalHmoAmount: 0
            });
            setBills([]);
        }
    };

    const handleTableChange = (pagination, filters, sorter, extra) => {
        const hmoNameFilters = filters.hmo_name;
        if (hmoNameFilters) {
            let totalHmoAmount = 0;
            bills.forEach(bill => {
                if (
                    hmoNameFilters.indexOf(bill.hmo_id) !== -1 ||
                    hmoNameFilters.length === 0
                ) {
                    totalHmoAmount += bill.hmo_amount_covered;
                }
            });
            setHmoSummary({
                totalHmoAmount
            });
        }
        setTableData(extra.currentDataSource);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon
                type="search"
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    const billingReportTableColumns = [
        {
            title: "HMO Name",
            dataIndex: "hmo_name",
            filters: [
                ...hmoList.map(hmo => ({
                    text: hmo.name,
                    value: hmo.hmo_id
                }))
            ],
            onFilter: (value, record) => {
                return record.hmo_id === value;
            },
            render: hmoName => (hmoName ? hmoName : <span>&mdash;</span>)
        },
        {
            title: "HMO Amount",
            dataIndex: "hmo_amount_covered",
            render: hmoAmountCover => formatNumber(hmoAmountCover)
        },
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            ...getColumnSearchProps("patient_name")
        },
        {
            title: "Bill / Invoice No.",
            dataIndex: "bill_id",
            ...getColumnSearchProps("bill_id")
        },
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            sorter: (a, b) =>
                new Date(moment(a.bill_date).format("LLL")) -
                new Date(moment(b.bill_date).format("LLL")),
            render: (text, record) => moment(record.bill_date).format("LL")
        },
        {
            title: "Bill Status",
            dataIndex: "status",
            render: status => {
                let statusColor = "red";
                if (status === "partial") {
                    statusColor = "green";
                } else if (status === "unpaid") {
                    statusColor = "orange";
                } else if (status === "paid") {
                    statusColor = "green";
                }

                return (
                    <span>
                        <Tag color={statusColor}>{status}</Tag>
                    </span>
                );
            },
            filters: [
                {
                    text: "Unpaid",
                    value: "unpaid"
                },
                {
                    text: "Partial",
                    value: "partial"
                },
                {
                    text: "Paid",
                    value: "paid"
                },
                {
                    text: "Deleted",
                    value: "deleted"
                }
            ],
            onFilter: (value, record) => {
                return record.status === value;
            }
        },
        {
            title: "Bill Notes",
            dataIndex: "bill_notes",
            render: (text, record) =>
                record.bill_notes ? record.bill_notes : <span>&mdash;</span>
        }
    ];

    const CSVHeaders = [
        {
            label: "HMO Name",
            key: "hmo_name"
        },
        {
            label: "HMO Amount",
            key: "hmo_amount_covered"
        },
        {
            label: "Patient Name",
            key: "patient_name"
        },
        {
            label: "Bill / Invoice No.",
            key: "bill_id"
        },
        {
            label: "Bill Date",
            key: "bill_date"
        },
        {
            label: "Bill Status",
            key: "status"
        },
        {
            label: "Bill Notes",
            key: "bill_notes"
        }
    ];

    const CSVData = tableData.map(bill => ({
        ...bill,
        bill_date: moment(bill.bill_date).format("LL")
    }));

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/reports">
                    <Icon type="arrow-left" /> Back to Reports Menu
                </Link>
            </div>

            <Divider />

            <div style={{ marginBottom: 16 }}>
                {isMobileBreakpoint ? (
                    <div
                        style={{
                            marginBottom: 5
                        }}
                    >
                        Select Date Range:
                    </div>
                ) : (
                    "Select Date Range: "
                )}
                <RangePicker onChange={handleBillingDateRangeChange} />
            </div>

            <div style={{ marginBottom: 16 }}>
                <Row gutter={8}>
                    <Col xl={4} lg={8} md={8} sm={24} xs={24}>
                        <Card>
                            <Statistic
                                title="Total HMO Amount"
                                value={hmoSummary.totalHmoAmount}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

            <div
                style={{
                    marginBottom: 16,
                    minWidth: 320,
                    overflow: "auto",
                    backgroundColor: "#fff"
                }}
            >
                <Table
                    columns={billingReportTableColumns}
                    dataSource={bills}
                    onChange={handleTableChange}
                    rowKey={"bill_id"}
                    loading={billsReportTableLoading}
                    pagination={{
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        simple: isMobileBreakpoint ? true : false
                    }}
                    className="reportsTable"
                />
            </div>

            <div style={{ textAlign: "right" }}>
                <CSVLink
                    data={CSVData}
                    headers={CSVHeaders}
                    filename={"hmo.csv"}
                >
                    <Tooltip title="Export to Excel">
                        <Button
                            style={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                border: "1px solid #4caf50"
                            }}
                        >
                            <Icon type="download" /> Export to CSV
                        </Button>
                    </Tooltip>
                </CSVLink>
            </div>
        </Card>
    );
};

const mapStateToProps = state => ({
    hmoList: state.Hmo,
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HMOReport)
);
