import React, { useState } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { updateSketchBgImgs } from "redux/sketchBackgroundImgs/sketchBackgroundImg.actions";
import { Upload, message, Icon, Modal } from "antd";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

const SketchBackgroundUploader = ({
    onSelectBgImg,
    ownerID,
    userID,
    clinicID,
    uploadServer,
    uploadEndpoint,
    sketchBackgroundImgs: sketchBgImgs,
    updateSketchBgImgs,
    setRequestError,
}) => {
    const [sketchBackgrounds, setSketchBackgrounds] = useState(
        sketchBgImgs.length !== 0
            ? JSON.parse(sketchBgImgs[0].sketch_backgrounds)
            : []
    );

    const { confirm } = Modal;

    const handleImgDelete = (cb) => {
        confirm({
            title: `Are you sure do you want to remove this image?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            zIndex: 9999,
            onOk() {
                cb(true);
            },
            onCancel() {
                cb(false);
            },
        });
    };

    const uploadProps = {
        name: "uniFile",
        //accept: ".png,.jpg,.jpeg",
        action: `${apiUrl}/sketchBackgroundUpload?space_server=${uploadServer}&space_bucket_name=${uploadEndpoint}&root_dir=${cwaUploadRootDir}&owner_id=${ownerID}`,
        headers: {
            authorization: localStorage.getItem("token"),
        },
        listType: "picture-card",
        showUploadList: { showDownloadIcon: false },
        async onChange(info) {
            let newSketchBackgrounds = [...info.fileList];
            newSketchBackgrounds = newSketchBackgrounds.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    const filename = file.response.newFilename;
                    const fileExt = file.name.split(".").slice(-1);
                    file.url = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/images/sketch_collection/${filename}.${fileExt}`;
                    file.addedBy = userID;
                }
                return file;
            });

            if (info.file.status) {
                setSketchBackgrounds(newSketchBackgrounds);
            }

            if (info.file.status === "done") {
                //refine attachments array and save it to database
                const files = [];
                newSketchBackgrounds.forEach((file) => {
                    const { uid, name, url, size, status, addedBy } = file;
                    files.push({ uid, url, name, size, status, addedBy });
                });

                const editedSketchBgImgs = {
                    sketch_backgrounds: JSON.stringify(files),
                    clinic_id: clinicID,
                };

                updateSketchBgImgs(editedSketchBgImgs);

                try {
                    await http.post(
                        `${apiUrl}/sketchBackgroundImgs`,
                        editedSketchBgImgs
                    );
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        },
        beforeUpload(file) {
            const isValidFile =
                file.type === "image/jpeg" || file.type === "image/png";

            if (!isValidFile) {
                message.error("You can only upload JPEG/PNG file!");
            }
            const isValidSize = file.size / 1024 / 1024 < 5;
            if (!isValidSize) {
                message.error("File must smaller than 5MB!");
            }
            return isValidFile && isValidSize;
        },
        onPreview(file) {
            onSelectBgImg(file.url);
        },
        onRemove(file) {
            if (file.addedBy === userID) {
                return new Promise(async (resolve, reject) => {
                    handleImgDelete(async function (isConfirmed) {
                        if (isConfirmed) {
                            resolve();
                            const newSketchBackgrounds = sketchBackgrounds
                                .map((img) => {
                                    const {
                                        uid,
                                        name,
                                        url,
                                        size,
                                        status,
                                        addedBy,
                                    } = img;
                                    return {
                                        uid,
                                        name,
                                        url,
                                        size,
                                        status,
                                        addedBy,
                                    };
                                })
                                .filter((img) => img.uid !== file.uid);

                            const editedSketchBgImgs = {
                                sketch_backgrounds: JSON.stringify(
                                    newSketchBackgrounds
                                ),
                                clinic_id: clinicID,
                            };

                            updateSketchBgImgs(editedSketchBgImgs);

                            try {
                                await http.post(
                                    `${apiUrl}/sketchBackgroundImgs`,
                                    editedSketchBgImgs
                                );
                                return true;
                            } catch (error) {
                                setRequestError({
                                    errorMessage:
                                        "Something went wrong on your last operation :(",
                                    errorSubMessage: error.message,
                                });
                            }
                        }
                    });
                });
            } else {
                message.error("Only the person who attached it can delete it!");
                return false;
            }
        },
    };

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div
                style={{
                    marginTop: 8,
                    color: "#666",
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div style={{ marginBottom: 14 }}>
            <Upload {...uploadProps} fileList={sketchBackgrounds}>
                {uploadButton}
            </Upload>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ownerID: state.UserDetails.owner_id,
    userID: state.UserDetails.user_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    sketchBackgroundImgs: state.SketchBackgroundImgs,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    updateSketchBgImgs: (editedSketchBgImgs) =>
        dispatch(updateSketchBgImgs(editedSketchBgImgs)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SketchBackgroundUploader);
