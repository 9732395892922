import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { setRequestError } from "redux/system/system.actions";
import { Icon, Divider, Card } from "antd";

import ContentLoader from "globalComponents/ContentLoader";
import Multimedia from "./components/Multimedia/Index";
import Announcement from "./components/Announcement";
import Messages from "./components/Messages/Index";
import Actions from "./components/Actions";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Queueing = ({ breakpoint, clinicID, setRequestError }) => {
    const [isContentLoading, setContentLoading] = useState(true);
    const [queueingDetails, setQueueingDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setContentLoading(true);
                const response = await http.get(
                    `${apiUrl}/queueingDetails/${clinicID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                setQueueingDetails(response.data);
                setContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <Card bodyStyle={{ padding: breakpoint.isNormalMobile ? 10 : 24 }}>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <Actions queueingDetails={queueingDetails} />
            <Messages queueingDetails={queueingDetails} />
            <Announcement
                breakpoint={breakpoint}
                queueingDetails={queueingDetails}
            />
            <Multimedia queueingDetails={queueingDetails} />
        </Card>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Queueing);
