import React, { useState } from "react";
import { Select, Button, Tag } from "antd";

const ColorSelect = ({ onSubmit }) => {
    const [selectedColor, setSelectedColor] = useState(null);

    const { Option } = Select;

    const handleSelectChange = value => {
        setSelectedColor(value);
    };

    const handleSelectOK = () => {
        onSubmit(selectedColor);
    };

    return (
        <div>
            <p>Select Patient Card Item:</p>
            <Select
                defaultValue=""
                style={{ width: "100%" }}
                onChange={handleSelectChange}
            >
                <Option value="">No Color</Option>
                <Option value="magenta">
                    <Tag color="magenta">Magenta</Tag>
                </Option>
                <Option value="red">
                    <Tag color="red">Red</Tag>
                </Option>
                <Option value="volcano">
                    <Tag color="volcano">Volcano</Tag>
                </Option>
                <Option value="orange">
                    <Tag color="orange">Orange</Tag>
                </Option>
                <Option value="gold">
                    <Tag color="gold">Gold</Tag>
                </Option>
                <Option value="lime">
                    <Tag color="lime">Lime</Tag>
                </Option>
                <Option value="green">
                    <Tag color="green">Green</Tag>
                </Option>
                <Option value="cyan">
                    <Tag color="cyan">Cyan</Tag>
                </Option>
                <Option value="blue">
                    <Tag color="blue">Blue</Tag>
                </Option>
                <Option value="geekblue">
                    <Tag color="geekblue">Geek Blue</Tag>
                </Option>
                <Option value="purple">
                    <Tag color="purple">Purple</Tag>
                </Option>
            </Select>
            <div style={{ marginTop: 10 }}>
                <Button type="primary" onClick={handleSelectOK}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default ColorSelect;
