import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Card,
    Button,
    Icon,
    Table,
    Collapse,
    Modal,
    message,
    Tooltip
} from "antd";
import moment from "moment";

import ActionMenu from "globalComponents/ActionMenu";
import ModalDetails from "globalComponents/ModalDetails";

import CaseFolderForm from "./components/Forms/CaseFolderForm";
import RenameCaseForm from "./components/Forms/RenameCaseForm";
import FolderSelection from "./components/Forms/FolderSelection";
import PatientCaseDetails from "./components/PatientCaseDetails";

import { generateString } from "helpers/randomStringGenerator";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientCases = ({
    patientDetails,
    caseID,
    onSelectCase,
    onDeleteCase,
    userID,
    setRequestError
}) => {
    const [patientCases, setPatientCases] = useState(patientDetails.cases);
    const [caseFolders, setCaseFolders] = useState(patientDetails.folders);

    const [selectedCaseFolder, setSelectedCaseFolder] = useState(null);
    const [selectedCase, setSelectedCase] = useState(null); // for rename

    const [isCaseFolderFormVisible, setIsCaseFolderFormVisible] =
        useState(false);
    const [isRenameCaseFormVisible, setIsRenameCaseFormVisible] =
        useState(false);
    const [isFolderSelectionVisible, setIsFolderSelectionVisible] =
        useState(false);
    const [isCaseDetailsVisible, setIsCaseDetailsVisible] = useState(false);

    useEffect(() => {
        setPatientCases(patientDetails.cases);
        setCaseFolders(patientDetails.folders);
    }, [patientDetails.cases]);

    const { Panel } = Collapse;
    const { confirm } = Modal;

    const toggleCaseFolderFormVisible = () => {
        setIsCaseFolderFormVisible(!isCaseFolderFormVisible);
    };

    const toggleRenameCaseFormVisible = () => {
        setIsRenameCaseFormVisible(!isRenameCaseFormVisible);
    };

    const toggleFolderSelectionVisible = () => {
        setIsFolderSelectionVisible(!isFolderSelectionVisible);
    };

    const toggleCaseDetailsVisible = () => {
        setIsCaseDetailsVisible(!isCaseDetailsVisible);
    };

    const showCaseFolderForm = (selectedRecord = null) => {
        setSelectedCaseFolder(selectedRecord);
        toggleCaseFolderFormVisible();
    };

    const showRenameCaseForm = selectedRecord => {
        setSelectedCase(selectedRecord);
        toggleRenameCaseFormVisible();
    };

    const showFolderSelection = selectedRecord => {
        setSelectedCase(selectedRecord);
        toggleFolderSelectionVisible();
    };

    const showCaseDetails = selectedRecord => {
        // setSelectedCase(selectedRecord);
        // toggleCaseDetailsVisible();

        onSelectCase(selectedRecord.case_id);
    };

    const handleCaseFolderFormSubmit = async formData => {
        try {
            //check if foldername already exist
            const folderFound = caseFolders.filter(
                caseFolder =>
                    caseFolder.name.toLowerCase() ===
                    formData.name.trim().toLowerCase()
            );

            if (folderFound.length !== 0) {
                message.error("Folder name already exist");
            } else {
                if (selectedCaseFolder) {
                    //edit
                    const newCaseFolders = caseFolders.map(caseFolder => {
                        if (
                            caseFolder.case_folder_id ===
                            formData.case_folder_id
                        ) {
                            return formData;
                        }
                        return caseFolder;
                    });
                    setCaseFolders(newCaseFolders);
                    toggleCaseFolderFormVisible();

                    await http.put(
                        `${apiUrl}/caseFolder/${formData.case_folder_id}`,
                        formData
                    );
                } else {
                    const folderID = generateString(7, "f");
                    const newFormData = {
                        ...formData,
                        case_folder_id: folderID
                    };
                    setCaseFolders([...caseFolders, newFormData]);
                    toggleCaseFolderFormVisible();

                    await http.post(`${apiUrl}/caseFolder`, newFormData);
                }
            }
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleCaseFolderDelete = selectedRecord => {
        const { type, case_folder_id } = selectedRecord;
        confirm({
            title: `Are you sure do you want to delete this ${
                type === "global" ? "GLOBAL" : ""
            } folder?`,
            content: `${
                type === "global"
                    ? "When deleting a global folder, all cases of the other patients that inside of this global folder will be move to its default list."
                    : ""
            } Dont worry, deleting a folder will not delete the case(s) inside it`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    const newCaseFolders = caseFolders.filter(
                        caseFolder =>
                            caseFolder.case_folder_id !== case_folder_id
                    );

                    const newPatientCases = patientCases.map(patientCase => {
                        if (patientCase.case_folder_id === case_folder_id) {
                            return {
                                ...patientCase,
                                case_folder_id: ""
                            };
                        }
                        return patientCase;
                    });

                    setPatientCases(newPatientCases);
                    setCaseFolders(newCaseFolders);

                    resolve();

                    try {
                        await http.delete(
                            `${apiUrl}/caseFolder/${case_folder_id}`
                        );
                    } catch (error) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message
                        });
                    }
                });
            },
            onCancel() {}
        });
    };

    const handleRenameCaseFormSubmit = async formData => {
        const { name: newCaseName } = formData;
        const caseID = selectedCase.case_id;
        const newPatientCases = patientCases.map(patientCase => {
            if (patientCase.case_id === caseID) {
                return { ...patientCase, case_name: newCaseName.trim() };
            }
            return patientCase;
        });
        setPatientCases(newPatientCases);
        toggleRenameCaseFormVisible();

        try {
            await http.put(`${apiUrl}/case/${caseID}`, {
                case_name: newCaseName
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleFolderSelectionOK = async formData => {
        const newPatientCases = patientCases.map(patientCase => {
            if (patientCase.case_id === selectedCase.case_id) {
                return {
                    ...patientCase,
                    case_folder_id: formData.caseFolderID
                };
            }
            return patientCase;
        });
        setPatientCases(newPatientCases);
        toggleFolderSelectionVisible();

        try {
            await http.put(`${apiUrl}/case/${caseID}`, {
                case_folder_id: formData.caseFolderID
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const patientCasesTableColumns = [
        {
            key: "case_id",
            sortOrder: "descend",
            className: "cursorPointer",
            sorter: (a, b) =>
                new Date(moment(a.complaint_datetime).format("MMMM DD, YYYY")) -
                new Date(moment(b.complaint_datetime).format("MMMM DD, YYYY")),
            render: (text, record) => {
                const { case_id, complaint_datetime, case_name } = record;
                return (
                    <div
                        style={{
                            color: case_id === caseID ? "#000" : "#595959",
                            fontWeight: case_id === caseID ? "bold" : "normal",
                            fontSize: ".8rem"
                        }}
                    >
                        {case_name !== ""
                            ? case_name
                            : moment(complaint_datetime).format(
                                  "MMMM DD, YYYY"
                              )}
                    </div>
                );
            }
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => {
                return record.doctor_id === userID ? (
                    <ActionMenu
                        menu={[
                            {
                                label: "Open Case",
                                icon: "folder-open",
                                onClick: () => onSelectCase(record.case_id)
                            },
                            {
                                label: "Rename",
                                icon: "edit",
                                onClick: () => showRenameCaseForm(record)
                            },
                            {
                                label: "Move to folder",
                                icon: "file-sync",
                                onClick: () => showFolderSelection(record)
                            },
                            {
                                label: "Archive Case",
                                icon: "container",
                                onClick: () => onDeleteCase(record.case_id)
                            }
                        ]}
                        layout="compress"
                        size="small"
                    />
                ) : (
                    <ActionMenu
                        menu={[
                            {
                                label: "Open Case",
                                icon: "folder-open",
                                onClick: () => onSelectCase(record.case_id)
                            }
                        ]}
                        layout="compress"
                        size="small"
                    />
                );
            }
        }
    ];

    const casesNotOnFolders = patientCases.filter(
        patientCase => patientCase.case_folder_id === ""
    );

    const handleCaseOpen = caseID => {
        onSelectCase(caseID);
        toggleCaseDetailsVisible();
    };

    return (
        <div style={{ marginBottom: 16 }}>
            <Card
                title="Patient Cases"
                className="caseNoteCard"
                bodyStyle={{ padding: 0, borderTop: "1px solid #e8e8e8" }}
                extra={
                    <Tooltip title="New Folder">
                        <Button onClick={() => showCaseFolderForm()}>
                            <Icon
                                type="folder-add"
                                theme="twoTone"
                                twoToneColor="#d8c520"
                            />
                        </Button>
                    </Tooltip>
                }
            >
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <Icon
                                type="folder-open"
                                theme="twoTone"
                                twoToneColor="#e2cb00"
                                style={{ fontSize: "1rem" }}
                            />
                        ) : (
                            <Icon
                                type="folder"
                                theme="twoTone"
                                twoToneColor="#e2cb00"
                                style={{ fontSize: "1rem" }}
                            />
                        )
                    }
                >
                    {caseFolders.map(caseFolder => (
                        <Panel
                            header={caseFolder.name}
                            key={caseFolder.case_folder_id}
                            style={styles.customPanelStyle}
                            extra={
                                <>
                                    {caseFolder.user_id === userID && (
                                        <ActionMenu
                                            menu={[
                                                {
                                                    label: "Rename",
                                                    icon: "edit",
                                                    onClick: () =>
                                                        showCaseFolderForm(
                                                            caseFolder
                                                        )
                                                },
                                                {
                                                    label: "Delete",
                                                    icon: "delete",
                                                    onClick: () =>
                                                        handleCaseFolderDelete(
                                                            caseFolder
                                                        )
                                                }
                                            ]}
                                            layout="compress"
                                            size="small"
                                        />
                                    )}
                                </>
                            }
                        >
                            <div style={{ marginTop: -10 }}>
                                <Table
                                    dataSource={patientCases.filter(
                                        patientCase =>
                                            patientCase.case_folder_id ===
                                            caseFolder.case_folder_id
                                    )}
                                    columns={patientCasesTableColumns}
                                    showHeader={false}
                                    pagination={false}
                                    rowKey="case_id"
                                    onRow={record => ({
                                        onClick: () => {
                                            showCaseDetails(record);
                                        }
                                    })}
                                />
                            </div>
                        </Panel>
                    ))}
                </Collapse>
                {casesNotOnFolders.length !== 0 && (
                    <Table
                        dataSource={casesNotOnFolders}
                        columns={patientCasesTableColumns}
                        showHeader={false}
                        pagination={false}
                        rowKey="case_id"
                        onRow={record => ({
                            onClick: () => {
                                showCaseDetails(record);
                            }
                        })}
                    />
                )}
            </Card>

            <Modal
                title={selectedCaseFolder ? "Rename Folder" : "Create Folder"}
                visible={isCaseFolderFormVisible}
                footer={
                    <Button onClick={toggleCaseFolderFormVisible}>
                        Cancel
                    </Button>
                }
                onCancel={toggleCaseFolderFormVisible}
                destroyOnClose={true}
            >
                <CaseFolderForm
                    data={selectedCaseFolder}
                    patientID={patientDetails.patient_id}
                    onSubmit={handleCaseFolderFormSubmit}
                />
            </Modal>

            <Modal
                title="Rename Case"
                visible={isRenameCaseFormVisible}
                footer={
                    <Button onClick={toggleRenameCaseFormVisible}>
                        Cancel
                    </Button>
                }
                onCancel={toggleRenameCaseFormVisible}
                destroyOnClose={true}
            >
                <RenameCaseForm
                    data={selectedCase}
                    onSubmit={handleRenameCaseFormSubmit}
                />
            </Modal>

            <Modal
                title="Select Folder"
                visible={isFolderSelectionVisible}
                width={300}
                footer={
                    <Button onClick={toggleFolderSelectionVisible}>
                        Cancel
                    </Button>
                }
                onCancel={toggleFolderSelectionVisible}
                destroyOnClose={true}
            >
                <FolderSelection
                    currentSelectedCaseFolder={
                        selectedCase ? selectedCase.case_folder_id : ""
                    }
                    folders={caseFolders}
                    onOK={handleFolderSelectionOK}
                />
            </Modal>

            <ModalDetails
                visible={isCaseDetailsVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                width={320}
                onCancel={toggleCaseDetailsVisible}
            >
                <PatientCaseDetails
                    selectedCase={selectedCase}
                    onCaseOpen={handleCaseOpen}
                />
            </ModalDetails>
        </div>
    );
};

const styles = {
    customPanelStyle: {
        background: "#fff",
        //borderTop: "1px solid #e8e8e8",
        borderRadius: 4,
        overflow: "hidden"
    }
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientCases);
