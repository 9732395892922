import {
    GLOBAL_STATE_FETCHING_START,
    GLOBAL_STATE_FETCHING_END,
    REQUEST_ERROR
} from "./system.types";
import { appBasePath } from "config.json";

export const startStateFetching = () => dispatch => {
    dispatch({
        type: GLOBAL_STATE_FETCHING_START
    });
};

export const endStateFetching = () => dispatch => {
    dispatch({
        type: GLOBAL_STATE_FETCHING_END
    });
};

export const setRequestError = ({
    errorMessage,
    errorSubMessage
}) => dispatch => {
    if (errorSubMessage === "Request failed with status code 401") {
        localStorage.clear();
        window.location = `${appBasePath}/`;
    } else {
        dispatch({
            type: REQUEST_ERROR,
            payload: { errorMessage, errorSubMessage }
        });
    }
};
