import React, { useState } from "react";
import { Card, Button, Descriptions } from "antd";
import moment from "moment";
import ModalDetails from "globalComponents/ModalDetails";
import ContactContent from "./components/ContactContent";

const SubscriptionManagement = ({ userDetails }) => {
    const [isContactVisible, setIsContactVisible] = useState(false);

    const toggleContactVisible = () => {
        setIsContactVisible(!isContactVisible);
    };

    const { subscriptionDetails } = userDetails;
    const { plan, planDescription, planAmount, dateStart, dateDue } =
        subscriptionDetails;

    return (
        <div>
            <Card
                cover={
                    <div
                        style={{
                            textAlign: "center",
                            paddingTop: 30,
                            paddingBottom: 30,
                            backgroundColor:
                                plan === "free"
                                    ? "#949494"
                                    : plan === "standard"
                                    ? "#ff9800"
                                    : "#1890ff",
                            color: "#fff"
                        }}
                    >
                        <h1
                            style={{
                                color: "#fff",
                                fontSize: "2rem"
                            }}
                        >
                            {plan === "free"
                                ? "FREE VERSION"
                                : plan === "standard"
                                ? "STANDARD"
                                : "BASIC"}
                        </h1>
                        <p style={{ padding: "0 50px" }}>{planDescription}</p>
                    </div>
                }
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Date Registered">
                        {moment(dateStart).format("LL")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Due Date">
                        {moment(dateDue).format("LL")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Monthly">
                        PHP {planAmount}
                    </Descriptions.Item>
                </Descriptions>

                <div style={{ marginTop: 50 }}>
                    <Button
                        type="primary"
                        size="large"
                        block
                        onClick={toggleContactVisible}
                    >
                        {plan === "free" ? "Upgrade Now!" : "Pay Now!"}
                    </Button>
                </div>
            </Card>
            <ModalDetails
                visible={isContactVisible}
                width={400}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleContactVisible}
            >
                <ContactContent />
            </ModalDetails>
        </div>
    );
};

export default SubscriptionManagement;
