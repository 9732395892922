import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    setIsStreamContentVisible,
    setVideoCallDetails,
} from "redux/videoCaller/videoCaller.actions";
import { setRequestError } from "redux/system/system.actions";
import { Button, Icon, Alert, Tag, Tooltip, Modal } from "antd";
import moment from "moment";

import { socket } from "layouts/Main";

const VideoCaller = ({
    patientDetails,
    caseDetails,
    onDurationChange,
    userID,
    firstname,
    lastname,
    degree,
    userImgPath,
    isStreamContentVisible,
    videoCallDetails,
    setIsStreamContentVisible,
    setVideoCallDetails,
    setRequestError,
}) => {
    const { confirm } = Modal;

    useEffect(() => {
        const appointmentDuration = caseDetails.appointment_duration;
        const callDuration = caseDetails.call_duration;

        const maxDuration = `00:${
            appointmentDuration > 9
                ? appointmentDuration
                : `0${appointmentDuration}`
        }:00`;

        const maxDurationInSeconds = moment.duration(maxDuration).asSeconds();
        const callDurationInSeconds = moment.duration(callDuration).asSeconds();

        setVideoCallDetails({
            initStreamTime: callDurationInSeconds,
            maxStreamTime: maxDurationInSeconds,
            isAllowExcessTime: false,
            patientDetails,
            caseDetails,
        });
    }, [caseDetails.case_id]);

    useEffect(() => {
        if (!isStreamContentVisible && videoCallDetails.initStreamTime !== 0) {
            onDurationChange(
                moment
                    .utc(videoCallDetails.initStreamTime * 1000)
                    .format("HH:mm:ss")
            );
        }
    }, [isStreamContentVisible]);

    function callPatient() {
        setIsStreamContentVisible(true);
        socket.emit("contactPatient", {
            caller_id: userID,
            caller_name: `${firstname} ${lastname} ${degree}`,
            caller_img_path: userImgPath,
            //patient_id: caseDetails.patient_id,
            video_call_room_id: caseDetails.appointment_video_call_room_id,
        });
    }

    const handleCallPatient = () => {
        //error checking if pwede pa magcall
        if (videoCallDetails.initStreamTime >= videoCallDetails.maxStreamTime) {
            confirm({
                title: "Maximum Allowed Time Reached",
                content:
                    "Any excess minutes will be charge to your account accordingly. Do you want to continue?",
                okText: "Continue",
                onOk() {
                    //setIsAllowExcessTime(true);
                    setVideoCallDetails({
                        isAllowExcessTime: true,
                    });
                    callPatient();
                },
            });
        } else {
            const scheduledTime = moment(caseDetails.complaint_datetime).format(
                "HH:mm:ss"
            );
            const isEarlyCall =
                moment(scheduledTime, "HH:mm:ss").diff(moment(), "minutes") >=
                15
                    ? true
                    : false;
            const isLateCall =
                moment().diff(moment(scheduledTime, "HH:mm:ss"), "minutes") >=
                15
                    ? true
                    : false;

            //early and late call
            if (isEarlyCall && videoCallDetails.initStreamTime === 0) {
                confirm({
                    title: "Early Call",
                    content:
                        "You are trying to call the patient earlier than 15 mins before the scheduled time. Continue call?",
                    okText: "Continue",
                    onOk() {
                        callPatient();
                    },
                });
            } else if (isLateCall && videoCallDetails.initStreamTime === 0) {
                confirm({
                    title: "Late Call",
                    content:
                        "You are trying to call the patient 15 mins late on the scheduled time. Continue call?",
                    okText: "Continue",
                    onOk() {
                        callPatient();
                    },
                });
            } else {
                callPatient();
            }
        }
    };

    return (
        <div style={{ marginBottom: 16 }}>
            {videoCallDetails.initStreamTime !== 0 ? (
                <Alert
                    message="Call Summary"
                    type="success"
                    description={
                        <div>
                            <div>
                                Max Duration: {caseDetails.appointment_duration}{" "}
                                mins
                            </div>
                            <div>
                                Call Duration:{" "}
                                {moment
                                    .utc(videoCallDetails.initStreamTime * 1000)
                                    .format("HH:mm:ss")}
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {!isStreamContentVisible ? (
                                    <Button
                                        type="primary"
                                        onClick={handleCallPatient}
                                    >
                                        <Icon type="phone" /> Call Again
                                    </Button>
                                ) : (
                                    <div>Call Ongoing...</div>
                                )}
                            </div>
                            <p style={{ marginTop: 16 }}>
                                Always tell to your patient where your{" "}
                                prescription, requests and papers can be found
                                before you end the call. It will show on{" "}
                                <strong>My Records</strong> menu on Patient
                                Portal after you click the{" "}
                                <strong>End Visit</strong> button.
                            </p>
                            <p>
                                To update the prescription, requests or papers
                                from CWA to Patient Portal, click{" "}
                                <strong>Save to Patient Portal</strong> located
                                on the right side of each printable papers
                                menu). Patient will see an image version of your
                                prescription, requests and papers.
                            </p>
                        </div>
                    }
                    showIcon
                />
            ) : (
                <Alert
                    message="Video Call Available"
                    description={
                        <div>
                            <div style={{ marginBottom: 8 }}>
                                <Tooltip title="Schedule">
                                    <Tag color="blue">
                                        {moment(
                                            caseDetails.complaint_datetime
                                        ).format("hh:mm A")}
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Max Duration">
                                    <Tag color="blue">
                                        {caseDetails.appointment_duration} mins
                                    </Tag>
                                </Tooltip>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                {!isStreamContentVisible ? (
                                    <Button
                                        type="primary"
                                        onClick={handleCallPatient}
                                    >
                                        <Icon type="phone" /> Call Patient
                                    </Button>
                                ) : (
                                    <div>Call Ongoing...</div>
                                )}
                            </div>
                            <p style={{ marginTop: 16 }}>
                                Always tell to your patient where your{" "}
                                prescription, requests and papers can be found
                                before you end the call. It will show on{" "}
                                <strong>My Records</strong> menu on Patient
                                Portal after you click the{" "}
                                <strong>End Visit</strong> button.
                            </p>
                            <p>
                                To update the prescription, requests or papers
                                from CWA to Patient Portal, click{" "}
                                <strong>Save to Patient Portal</strong> located
                                on the right side of each printable papers
                                menu). Patient will see an image version of your
                                prescription, requests and papers.
                            </p>
                        </div>
                    }
                    showIcon
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    firstname: state.UserDetails.firstname,
    lastname: state.UserDetails.lastname,
    degree: state.UserDetails.degree,
    userImgPath: state.UserDetails.user_img_path,
    isStreamContentVisible: state.VideoCaller.isStreamContentVisible,
    videoCallDetails: state.VideoCaller.details,
});

const mapDispatchToProps = (dispatch) => ({
    setIsStreamContentVisible: (isVisible) =>
        dispatch(setIsStreamContentVisible(isVisible)),
    setVideoCallDetails: (details) => dispatch(setVideoCallDetails(details)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoCaller);
