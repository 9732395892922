import React, { useState, useEffect } from "react";

import { Switch, Icon } from "antd";
import DefaultSound from "assets/sound/nosound.mp3";
import isMobile from "helpers/deviceDetector";

let Sound = new Audio(DefaultSound);
let isSoundOn = false;
const synth = window.speechSynthesis || null;
let voices = [];

const ToggleSound = ({ breakpoint }) => {
    const [switchOn, setSwitchOn] = useState(false);

    useEffect(() => {
        if (synth) {
            voices = synth.getVoices().sort(function (a, b) {
                const aname = a.name.toUpperCase(),
                    bname = b.name.toUpperCase();
                if (aname < bname) return -1;
                else if (aname === bname) return 0;
                else return +1;
            });
        }

        if (!isMobile.iOS()) {
            toggleSound();
        }
    }, []);

    function speak(message) {
        if (synth.speaking) {
            console.log("speechSynthesis.speaking");
            return;
        }
        var utterThis = new SpeechSynthesisUtterance(message);
        utterThis.onend = function (event) {
            //console.log("SpeechSynthesisUtterance.onend");
        };
        utterThis.onerror = function (event) {
            console.log(event);
            console.error("SpeechSynthesisUtterance.onerror");
        };

        utterThis.rate = 0.9;
        utterThis.voice = null;
        synth.speak(utterThis);
    }

    const toggleSound = () => {
        Sound.src = DefaultSound;
        let playPromise = Sound.play();

        if (playPromise !== undefined) {
            playPromise
                .then((res) => {
                    // Automatic playback started!
                    // Show playing UI.
                    isSoundOn = !isSoundOn;
                    if (isSoundOn) {
                        setSwitchOn(true);
                        speak("");
                    } else {
                        setSwitchOn(false);
                    }
                })
                .catch((error) => {});
        }
        if (isMobile.iOS()) {
            if (!isSoundOn) {
                speak("");
            }
        }
    };

    return (
        <Switch
            checkedChildren={<Icon type="sound" />}
            unCheckedChildren={<Icon type="sound" />}
            onChange={(c, e) => {
                e.stopPropagation();
                toggleSound();
            }}
            checked={switchOn}
            style={{ marginRight: breakpoint.isNormalMobile ? 0 : 16 }}
        />
    );
};

export default ToggleSound;

export { Sound, isSoundOn, synth, voices };
