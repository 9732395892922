import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Row, Col, Button, message, Spin, Typography } from "antd";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import domtoimage from "dom-to-image";

import ActionMenu from "globalComponents/ActionMenu";
import ModalDetails from "globalComponents/ModalDetails";

import { formatAge } from "helpers/ageFormatter";
import { b64ImgToFileHelper } from "helpers/image";
import { printPaper } from "helpers/printPaper";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir, cwappUploadRootDir } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PaperList = ({
    selectedPapers,
    patientDetails,
    caseDetails,
    readOnly: isReadOnly,
    onRemove,
    ownerID,
    isSignatureUploaded,
    clinicDetails,
    doctors,
    papers,
    ageFormat,
    uploadServer,
    uploadEndpoint,
    setRequestError,
}) => {
    const [HTMLOutput, setHTMLOutput] = useState("");
    const [isHTMLOutputVisible, setIsHTMLOutputVisible] = useState(false);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [selectedPaperLayout, setSelectedPaperLayout] = useState(null);
    const [isPaperSavingToPP, setIsPaperSavingToPP] = useState(false);

    const { Title } = Typography;

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const toggleHTMLOutputVisible = () => {
        setIsHTMLOutputVisible(!isHTMLOutputVisible);
    };

    const handlePaperPrintPreview = (
        paperID,
        doctorID,
        isDirectPrint = true,
        isSaveToPatientPortal = false
    ) => {
        //get the corresponding paper
        const selectedPaper = papers.find(
            (paper) => paper.paper_id === paperID
        );
        const doctorDetails = doctors.find(
            (doctor) => doctor.doctor_id === doctorID
        );

        if (selectedPaper) {
            if (selectedPaper.print_available !== 0) {
                try {
                    const paperLayout = JSON.parse(selectedPaper.layout);
                    const allDetails = {
                        patient: {
                            ...patientDetails,
                            age: formatAge(ageFormat, patientDetails.birthday),
                            fullname: `${patientDetails.lastname}, ${patientDetails.firstname} ${patientDetails.middlename}`,
                            full_address: `${patientDetails.street} ${patientDetails.town_city}, ${patientDetails.province}`,
                            birthday: moment(patientDetails.birthday).format(
                                "MMMM DD, YYYY"
                            ),
                        },
                        doctor: {
                            ...doctorDetails,
                            signature: isSignatureUploaded
                                ? `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/images/signatures/${doctorID}-thumb.jpeg`
                                : "",
                        },
                        clinic: clinicDetails,
                        general: {
                            currentDate: moment().format("MMMM DD, YYYY"),
                        },
                        case: {
                            ...caseDetails,
                            complaint_datetime: moment(
                                caseDetails.complaint_datetime
                            ).format("MMMM DD, YYYY"),
                        },
                    };

                    if (isSaveToPatientPortal) {
                        const HTMLOutput = printPaper(
                            paperLayout,
                            allDetails,
                            isDirectPrint,
                            isSaveToPatientPortal
                        );
                        setSelectedPaper(selectedPaper);
                        setSelectedPaperLayout(paperLayout);
                        setHTMLOutput(HTMLOutput);
                        toggleHTMLOutputVisible();
                    } else {
                        printPaper(
                            paperLayout,
                            allDetails,
                            isDirectPrint,
                            isSaveToPatientPortal
                        );
                    }
                } catch (error) {
                    message.error(
                        "Unabled to parse paper layout. Please contact CWA Team Support"
                    );
                }
            } else {
                message.info(
                    "Paper not available for print. Possible no layout. Please contact CWA Team Support"
                );
            }
        } else {
            message.info(
                `Paper not available for print. Please send your desire design to CWA Support Team`
            );
        }
    };

    const handlePaperRemove = (paperID, paperName) => {
        onRemove(paperID, paperName);
    };

    const saveToPatientPortal = () => {
        var node = document.getElementById("paperPad");
        setIsPaperSavingToPP(true);
        domtoimage
            .toJpeg(node, {
                quality: 0.95,
                //height: paperLayout.paper.style.height.replace("px", ""),
                width: selectedPaperLayout.props.width,
            })
            .then(async function (dataUrl) {
                try {
                    //create filename
                    const filename = Date.now() + ".jpeg";

                    //convert b64 img to file
                    const imgPaper = b64ImgToFileHelper(dataUrl, filename);

                    //prepare to upload
                    let fd = new FormData();
                    fd.append("uniFile", imgPaper);

                    const uploadResponse = await http.post(
                        `${apiUrl}/cwappPaperImg`,
                        fd,
                        {
                            params: {
                                space_server: uploadServer,
                                space_bucket_name: uploadEndpoint,
                                root_dir: cwappUploadRootDir,
                            },
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            }),
                        }
                    );

                    if (uploadResponse.data.error) {
                        message.error(
                            "Failed to save patient data. Please try again"
                        );
                        setIsPaperSavingToPP(false);
                    } else {
                        const img_path = `https://${uploadServer}/${uploadEndpoint}/${cwappUploadRootDir}/pictures/${uploadResponse.data.datePath}/${filename}`;

                        await http.post(
                            `${apiUrl}/ppPaper`,
                            {
                                appointment_id: caseDetails.appointment_id,
                                paperData: {
                                    paperId: selectedPaper.paper_id,
                                    label: selectedPaper.name,
                                    type: selectedPaper.category,
                                    imgPath: img_path,
                                },
                            },
                            {
                                headers: { conn: "pp" },
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        setIsPaperSavingToPP(false);
                        toggleHTMLOutputVisible();

                        message.success(
                            "Paper successfully saved on Patient Portal As Image"
                        );

                        socket.emit(
                            "reloadPPRecordList",
                            caseDetails.appointment_video_call_room_id
                        );
                        socket.emit(
                            "reloadPPRecordView",
                            caseDetails.appointment_video_call_room_id
                        );
                    }
                } catch (error) {
                    if (!error.message.responseCancelled) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong on your last operation :(",
                            errorSubMessage: error.message,
                        });
                    }
                }
            });
    };

    return (
        <>
            {selectedPapers.length !== 0 && (
                <div style={{ marginBottom: 24 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Title level={4}>Papers</Title>
                        </Col>
                        <Col></Col>
                    </Row>
                    <div style={{ marginTop: 5, marginLeft: 5 }}>
                        {selectedPapers.map((paper, index) => {
                            const paperMenu = [
                                {
                                    label: "Print",
                                    icon: "printer",
                                    onClick: () =>
                                        handlePaperPrintPreview(
                                            paper.paper_id,
                                            paper.doctor_id,
                                            true
                                        ),
                                },
                                {
                                    label: "Edit before Print",
                                    icon: "edit",
                                    onClick: () =>
                                        handlePaperPrintPreview(
                                            paper.paper_id,
                                            paper.doctor_id,
                                            false
                                        ),
                                },
                                {
                                    label: "Save to Patient Portal",
                                    icon: "save",
                                    onClick: () =>
                                        handlePaperPrintPreview(
                                            paper.paper_id,
                                            paper.doctor_id,
                                            false,
                                            true
                                        ),
                                },
                                {
                                    label: "Remove",
                                    icon: "delete",
                                    onClick: () =>
                                        handlePaperRemove(
                                            paper.paper_id,
                                            paper.name
                                        ),
                                },
                            ];

                            if (caseDetails.is_from_cwapp !== 1) {
                                paperMenu.splice(2, 1);
                            }

                            return (
                                <Row
                                    key={paper.paper_id}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Col span={22}>
                                        &ndash; {paper.name} ({paper.category})
                                    </Col>
                                    <Col span={2}>
                                        <div style={{ textAlign: "right" }}>
                                            {!isReadOnly && (
                                                <>
                                                    <ActionMenu
                                                        menu={paperMenu}
                                                        size="small"
                                                        layout="compress"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>

                    <ModalDetails
                        title="Save paper to Patient Portal"
                        visible={isHTMLOutputVisible}
                        footer={null}
                        destroyOnClose={true}
                        style={{ top: 20 }}
                        bodyStyle={{ padding: 0 }}
                        width={
                            selectedPaperLayout
                                ? selectedPaperLayout.props.width
                                : 816
                        }
                        onCancel={toggleHTMLOutputVisible}
                    >
                        <Spin
                            spinning={isPaperSavingToPP}
                            tip="Saving paper ..."
                        >
                            <div style={{ padding: 20 }}>
                                <Button
                                    type="primary"
                                    style={{ marginBottom: 10 }}
                                    onClick={saveToPatientPortal}
                                >
                                    Save to Patient Portal
                                </Button>
                            </div>
                            <div
                                id="paperPad"
                                suppressContentEditableWarning
                                contentEditable
                                className="printPaperContainer"
                                style={{ marginBottom: 20 }}
                            >
                                {ReactHtmlParser(HTMLOutput)}
                            </div>
                        </Spin>
                    </ModalDetails>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    ownerID: state.UserDetails.owner_id,
    isSignatureUploaded: state.UserDetails.is_signature_uploaded,
    clinicDetails: state.UserDetails.clinicDetails,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    papers: state.Papers,
    ageFormat: state.Settings.ageFormat,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperList);
