import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    addDoctor,
    editDoctor,
    deleteDoctor,
    addStaff,
    editStaff,
    deleteStaff
} from "redux/userDetails/userDetails.actions";
import { setRequestError } from "redux/system/system.actions";

//import { Card, Button, Table, Modal } from "antd";
import { Card, Button, Table } from "antd";

import Drawer from "globalComponents/Drawer";
import ActionMenu from "globalComponents/ActionMenu";
import UserForm from "./components/Forms/UserForm";

// import http from "services/httpService";
// import { apiUrl } from "config.json";

const UserManagement = ({
    userDetails,
    breakpoint,
    userID,
    addDoctor,
    editDoctor,
    deleteDoctor,
    addStaff,
    editStaff
    // deleteStaff,
    // setRequestError
}) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isUserFormVisible, setIsUserFormVisible] = useState(false);

    //const { confirm } = Modal;

    useEffect(() => {
        const users = [];
        userDetails.doctors
            .filter(doctor => doctor.status !== "inactive")
            .filter(doctor => doctor.doctor_id !== userID)
            .forEach(doctor => {
                users.push({
                    user_id: doctor.doctor_id,
                    name: doctor.doctor_name,
                    type: "doctor"
                });
            });
        userDetails.staffs
            .filter(staff => staff.status !== "inactive")
            .forEach(staff => {
                users.push({
                    user_id: staff.staff_id,
                    name: staff.staff_name,
                    type: "staff"
                });
            });
        setUsers(users);
    }, [userDetails.doctors, userDetails.staffs]);

    const toggleUserFormVisible = () => {
        setIsUserFormVisible(!isUserFormVisible);
    };

    const showUserForm = (selectedRecord = null) => {
        setSelectedUser(selectedRecord);
        toggleUserFormVisible();
    };

    const handleUserFormSubmit = formData => {
        if (formData.type === "doctor") {
            const {
                doctor_id,
                //user_id,
                firstname,
                lastname,
                license_number,
                area_of_practice,
                ptr,
                degree
            } = formData;

            if (selectedUser) {
                //edit doctor
                editDoctor({
                    doctor_id,
                    license_number,
                    ptr,
                    degree,
                    area_of_practice,
                    doctor_name: `${firstname} ${lastname} ${degree}`,
                    status: "active"
                });
                toggleUserFormVisible();
            } else {
                //add doctor
                // addDoctor({
                //     doctor_id: user_id,
                //     license_number,
                //     ptr,
                //     degree,
                //     area_of_practice,
                //     doctor_name: `${firstname} ${lastname} ${degree}`,
                //     status: "active",
                // });
                //toggleUserFormVisible();
                window.location.reload();
            }
        } else {
            const {
                staff_id,
                //user_id,
                firstname,
                lastname
            } = formData;
            if (selectedUser) {
                //edit staff
                editStaff({
                    staff_id,
                    staff_name: `${firstname} ${lastname}`,
                    status: "active"
                });
                toggleUserFormVisible();
            } else {
                //add staff
                // addStaff({
                //     staff_id: user_id,
                //     staff_name: `${firstname} ${lastname}`,
                //     status: "active",
                // });
                //toggleUserFormVisible();
                window.location.reload();
            }
        }
    };

    // const handleUserDelete = ({ user_id, name, type }) => {
    //     confirm({
    //         title: `Are you sure do you want to delete ${type} ${name}?`,
    //         content: "This cannot be undone",
    //         okText: "Yes",
    //         okType: "danger",
    //         cancelText: "No",
    //         onOk() {
    //             return new Promise(async (resolve, reject) => {
    //                 if (type === "doctor") {
    //                     resolve(deleteDoctor(user_id));
    //                     try {
    //                         await http.put(
    //                             `${apiUrl}/doctor/delete/${user_id}`
    //                         );
    //                     } catch (error) {
    //                         setRequestError({
    //                             errorMessage:
    //                                 "Something went wrong on your last operation :(",
    //                             errorSubMessage: error.message
    //                         });
    //                     }
    //                 } else {
    //                     resolve(deleteStaff(user_id));
    //                     try {
    //                         await http.put(`${apiUrl}/staff/delete/${user_id}`);
    //                     } catch (error) {
    //                         setRequestError({
    //                             errorMessage:
    //                                 "Something went wrong on your last operation :(",
    //                             errorSubMessage: error.message
    //                         });
    //                     }
    //                 }
    //             });
    //         },
    //         onCancel() {}
    //     });
    // };

    const userTableColumns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Type",
            dataIndex: "type"
        },
        {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => {
                let menu = [
                    {
                        label: "Edit",
                        icon: "edit",
                        onClick: () => showUserForm(record)
                    }
                    // {
                    //     label: "Delete",
                    //     icon: "delete",
                    //     onClick: () => handleUserDelete(record)
                    // }
                ];

                // if (record.user_id === userDetails.user_id) {
                //     menu.splice(1, 1);
                // }

                return <ActionMenu menu={menu} layout="compress" />;
            }
        }
    ];

    return (
        <div style={{ marginTop: 15 }}>
            <Card title="User Management">
                <div style={{ textAlign: "right", marginBottom: 10 }}>
                    <Button type="primary" onClick={() => showUserForm()}>
                        Add User
                    </Button>
                </div>
                <Table
                    columns={userTableColumns}
                    dataSource={users}
                    rowKey="user_id"
                />
            </Card>
            <Drawer
                title={selectedUser ? "Edit User" : "Create User"}
                placement="right"
                visible={isUserFormVisible}
                onClose={toggleUserFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 500}
            >
                <UserForm data={selectedUser} onSubmit={handleUserFormSubmit} />
            </Drawer>
        </div>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id
});

const mapDispatchToProps = dispatch => ({
    addDoctor: newDoctor => dispatch(addDoctor(newDoctor)),
    editDoctor: editedDoctor => dispatch(editDoctor(editedDoctor)),
    deleteDoctor: doctorID => dispatch(deleteDoctor(doctorID)),
    addStaff: newStaff => dispatch(addStaff(newStaff)),
    editStaff: editedStaff => dispatch(editStaff(editedStaff)),
    deleteStaff: staffID => dispatch(deleteStaff(staffID)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
