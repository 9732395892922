import {
    SET_SKETCH_BACKGROUND_IMG_SUCCESS,
    EDIT_SKETCH_BACKGROUND_IMG_SUCCESS
} from "./sketchBackgroundImg.types";

const INIT_STATE = [];

const sketchBackgroundImgReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SKETCH_BACKGROUND_IMG_SUCCESS:
            return action.payload.sketchBgImgs;
        case EDIT_SKETCH_BACKGROUND_IMG_SUCCESS:
            return [action.payload.editedSketchBgImgs];
        default:
            return state;
    }
};

export default sketchBackgroundImgReducer;
