import {
    SET_SKETCH_BACKGROUND_IMG_SUCCESS,
    EDIT_SKETCH_BACKGROUND_IMG_SUCCESS
} from "./sketchBackgroundImg.types";

export const setSketchBgImgs = sketchBgImgs => async dispatch => {
    dispatch({
        type: SET_SKETCH_BACKGROUND_IMG_SUCCESS,
        payload: { sketchBgImgs }
    });
};
export const updateSketchBgImgs = editedSketchBgImgs => async dispatch => {
    dispatch({
        type: EDIT_SKETCH_BACKGROUND_IMG_SUCCESS,
        payload: { editedSketchBgImgs }
    });
};
