import React, { useState, useEffect } from "react";
import { Form, Select, Spin, message } from "antd";

import { titleCase } from "helpers/textTransformer";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const FormItemSelect = ({ data, error, onInputChange }) => {
    const { name, label, value, options: ops, required } = data;

    const [options, setOptions] = useState(ops);
    const [isFetching, setIsFetching] = useState(false);
    const [formData, setFormData] = useState({
        [name]: value,
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error,
    });

    const { Option } = Select;

    useEffect(() => {
        if (value) {
            setFormData({ [name]: value });
        } else {
            setFormData({ [name]: "" });
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleSearch = async (value) => {
        if (value && name === "town_city") {
            setIsFetching(true);
            //search postal table
            try {
                const response = await http.get(
                    `${apiUrl}/searchPlace/${value}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                const { cities } = response.data;

                const newOptions = cities.map((city) => ({
                    id: city.rec,
                    label: `${city.city_mun}, ${titleCase(city.province)}`,
                    value: `${city.city_mun}@${titleCase(city.province)}@${
                        city.zip
                    }`,
                }));

                setOptions(newOptions);
                setIsFetching(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error(error.message || "Something went wrong!");
                }
            }
        } else {
            setIsFetching(false);
            setOptions([]);
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
        setIsFetching(false);
        onInputChange(name, value);
    };

    const handleInputBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    return (
        <Form.Item
            label={label}
            required={required}
            hasFeedback
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <Select
                name={name}
                defaultValue={value}
                value={formData[name]}
                style={{ width: "100%" }}
                showSearch={name === "town_city" ? true : false}
                notFoundContent={isFetching ? <Spin size="small" /> : null}
                onSearch={handleSearch}
                onChange={(value) => handleInputChange(name, value)}
                onBlur={(value) => handleInputBlur(name, value)}
            >
                {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default FormItemSelect;
