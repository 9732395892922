import React, { useState } from "react";
//import { Link } from "react-router-dom";
import { Avatar, Icon, message, Button } from "antd";

import { components } from "react-select";
import AsyncSelect from "react-select/async";

import Drawer from "globalComponents/Drawer";
import PatientForm from "views/Patients/components/Forms/PatientForm/Index";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientSearchBar = ({ breakpoint, onSelect, showAddPatientBtn }) => {
    const [isPatientFormVisible, setIsPatientFormVisible] = useState(false);

    const togglePatientFormVisible = () => {
        setIsPatientFormVisible(!isPatientFormVisible);
    };

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            resolve(
                http
                    .get(`${apiUrl}/patients/`, {
                        params: {
                            countPatients: false,
                            limit: 10,
                            currentPage: 1,
                            searchingPatientTable: true,
                            patientName: `${inputValue}`
                        }
                    })
                    .then(function (response) {
                        if (response.data.error) {
                            return [];
                        } else {
                            return response.data.patients;
                        }
                    })
                    .catch(function (error) {
                        message.error(error.message);
                    })
            );
        });

    const optionFormat = props => {
        const { innerProps, innerRef, data } = props;

        let patientFullname = props.value.trim();
        const patientImg = data.img_path;
        const isFromLegacy = data.is_from_legacy;
        const patientID = data.patient_id;

        const avatarProps = patientImg
            ? {
                  size: 40,
                  src: isFromLegacy
                      ? patientImg.replace(
                            `${patientID}-`,
                            `thumb/${patientID}-`
                        )
                      : patientImg.replace(".jpeg", "-thumb.jpeg")
              }
            : { size: 40, icon: "user" };

        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                    margin: 5,
                    cursor: "pointer",
                    backgroundColor: props.isFocused ? "#e6f7ff" : "inherit"
                }}
            >
                <Avatar {...avatarProps} /> {patientFullname}
            </div>
        );
    };

    const customTheme = theme => {
        return {
            ...theme,
            borderRadius: 4,
            colors: {
                ...theme.colors,
                primary: "#e6f7ff",
                primary25: "#e6f7ff",
                primary50: "#e6f7ff",
                neutral30: "#40a9ff",
                neutral190: "#595959"
            },
            spacing: {
                ...theme.spacing
            }
        };
    };

    const dropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <Icon type="search" />
                </components.DropdownIndicator>
            )
        );
    };

    const handleChange = option => {
        onSelect(option);
    };

    const handlePatientFormSubmitSuccess = () => {
        togglePatientFormVisible();
    };

    return (
        <>
            <AsyncSelect
                components={{
                    DropdownIndicator: dropdownIndicator,
                    Option: optionFormat
                }}
                //cacheOptions
                loadOptions={promiseOptions}
                noOptionsMessage={() => (
                    <>
                        {"No patient found"}{" "}
                        {showAddPatientBtn && (
                            <>
                                <br />{" "}
                                <Button
                                    type="link"
                                    onClick={togglePatientFormVisible}
                                >
                                    Add Patient
                                </Button>
                            </>
                        )}
                    </>
                )}
                isClearable={true}
                escapeClearsValue={true}
                theme={customTheme}
                styles={customStyles}
                onChange={handleChange}
                getOptionLabel={option => option.fullname}
                getOptionValue={option => option.fullname}
                placeholder="Search Patient Name"
                menuContainerStyle={{ zIndex: 999 }}
            />

            <Drawer
                title="Add Patient"
                placement="right"
                visible={isPatientFormVisible}
                onClose={togglePatientFormVisible}
                destroyOnClose={true}
                width={
                    breakpoint.isNormalMobile
                        ? 320
                        : breakpoint.isTabletPortrait
                        ? 700
                        : 1000
                }
                bodyStyle={
                    breakpoint.isNormalMobile ? { padding: "24px 12px" } : null
                }
            >
                <PatientForm
                    patientData={null}
                    dataEditing={false}
                    breakpoint={breakpoint}
                    inline={true}
                    onAddSuccess={handlePatientFormSubmitSuccess}
                />
            </Drawer>
        </>
    );
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "#595959"
    }),
    control: (provided, state) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,

        "&:hover": {
            border: "1px solid #40a9ff"
        },

        border: "1px solid #d9d9d9"
    }),
    input: provided => ({
        ...provided,
        color: "#595959",
        fontSize: 14
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#595959"
    }),
    valueContainer: provided => ({
        ...provided,
        zIndex: 2
    }),
    menu: provided => ({
        ...provided,
        zIndex: 99
    })
};

export default PatientSearchBar;
