import store from "store";

const findUserName = userID => {
    const { UserDetails } = store.getState();
    const { staffs, doctors } = UserDetails;
    const users = [
        ...staffs.map(staff => ({
            user_id: staff.staff_id,
            name: staff.staff_name
        })),
        ...doctors.map(doctor => ({
            user_id: doctor.doctor_id,
            name: doctor.doctor_name
        }))
    ];
    const user = users.find(user => user.user_id === userID);
    return user ? user.name : userID;
};

export { findUserName };
