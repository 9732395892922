import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setRequestError } from "redux/system/system.actions";
import { editDoctor } from "redux/userDetails/userDetails.actions";
import { connect } from "react-redux";
import {
    Form,
    Input,
    Select,
    Button,
    Divider,
    message,
    Alert,
    Row,
    Col,
    Slider,
    Calendar,
    InputNumber,
    Modal,
    Icon,
    Popover
} from "antd";

import moment from "moment";

import DoctorImage from "./components/DoctorImage";
import TeleSched from "./components/TeleSched/Index";

import { b64ImgToFileHelper } from "helpers/image";
import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const ProfileForm = ({
    doctorDetails,
    aops,
    aopsSub,
    hospitals,
    breakpoint,
    appMiscPercent,
    clinicID,
    clinicName,
    ownerID,
    uploadServer,
    uploadEndpoint,
    editDoctor,
    setRequestError
}) => {
    const [isProfileSaving, setIsProfileSaving] = useState(false);
    const [doctorImg, setDoctorImg] = useState({
        origSizeImg: "",
        cardSizeImg: "",
        thumbnailSizeImg: ""
    });
    const [isDoctorImageChange, setIsDoctorImageChange] = useState(false);

    const [formData, setFormData] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        username: "",
        aop: "",
        aop_sub_ids: [],
        practicing_since: "",
        license_number: "",
        ptr: "",
        school: "",
        degree: "",
        year: "",
        img_path: "",
        img_size: 0,
        //other info
        bank_name: "",
        bank_branch: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_type: "",
        consultation_desc: "",
        consultation_price: 0,
        primary_clinic_id: "",
        primary_hospital_id: "",
        calendar_today_margin: 0,
        calendar_max_days_select: 0,
        is_data_completed: 0
    });

    const [formErrors, setFormErrors] = useState({
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        username: "",
        practicing_since: "",
        license_number: "",
        ptr: "",
        school: "",
        degree: "",
        year: ""
        //other info,
    });

    const [isProfileNeedToSave, setIsProfileNeedToSave] = useState(false);
    const [portalSchedule, setPortaSchedule] = useState(null);

    const { Option } = Select;

    useEffect(() => {
        const {
            firstname,
            middlename,
            lastname,
            email,
            username,
            aop_id,
            aop_sub_ids,
            practicing_since,
            license_number,
            ptr,
            school,
            degree,
            year,
            img_path,
            img_size,
            //bank details
            bank_name,
            bank_branch,
            bank_account_number,
            bank_account_name,
            bank_account_type,
            //other info
            schedule,
            consultation_desc,
            consultation_price,
            primary_clinic_id,
            primary_hospital_id,
            calendar_max_days_select,
            calendar_today_margin,
            is_data_completed
        } = doctorDetails;

        setFormData({
            firstname,
            middlename,
            lastname,
            email,
            username,
            aop_id,
            aop_sub_ids: JSON.parse(aop_sub_ids),
            practicing_since,
            license_number,
            ptr,
            school,
            degree,
            year,
            img_path,
            img_size,
            tags: "",
            //bank details
            bank_name: bank_name || "",
            bank_branch: bank_branch || "",
            bank_account_number: bank_account_number || "",
            bank_account_name: bank_account_name || "",
            bank_account_type: bank_account_type || "",
            //other info
            consultation_desc,
            consultation_price,
            primary_clinic_id,
            primary_hospital_id,
            calendar_max_days_select,
            calendar_today_margin,
            is_data_completed
        });

        if (img_path) {
            setDoctorImg({
                ...doctorImg,
                origSizeImg: img_path
            });
        }
        if (schedule) {
            setPortaSchedule(JSON.parse(schedule));
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    function datePickerDisabledDate(current) {
        if (current) {
            const { calendar_today_margin, calendar_max_days_select } =
                formData;
            const dayStart =
                calendar_today_margin === 0
                    ? moment().startOf("day")
                    : moment()
                          .startOf("day")
                          .add(calendar_today_margin, "days");

            if (calendar_max_days_select === 0) {
                return current < dayStart;
            } else {
                const dayEnd = moment()
                    .startOf("day")
                    .add(calendar_today_margin, "days")
                    .add(calendar_max_days_select, "days");
                return !(
                    dayStart.isSameOrBefore(current) && dayEnd.isAfter(current)
                );
            }
        }
    }

    const handleInputChange = e => {
        const { name, value } = e.target;

        if (name === "consultation_desc" && value.trim() === "") {
            setFormData({
                ...formData,
                [name]: "Consultation Price"
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
        setIsProfileNeedToSave(true);
    };

    const handlePriceChange = price => {
        setFormData({
            ...formData,
            consultation_price: price || 0
        });
        setIsProfileNeedToSave(true);
    };

    const handleImageChange = doctorImg => {
        setDoctorImg(doctorImg);
        setIsDoctorImageChange(true);
        setIsProfileNeedToSave(true);
    };

    const handlePortalSchedChange = sched => {
        setPortaSchedule(sched);
        setIsProfileNeedToSave(true);
    };

    const handleInputBlur = e => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const {
            firstname,
            middlename,
            lastname,
            email,
            practicing_since,
            license_number,
            ptr,
            school,
            degree,
            year
        } = formData;

        let errors = {};
        let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

        if (firstname.trim() === "") {
            errors.firstname = "Firstname is required";
        }
        if (middlename.trim() === "") {
            errors.middlename = "Middlename is required";
        }
        if (lastname.trim() === "") {
            errors.lastname = "Lastname is required";
        }
        if (!emailRegEx.test(String(email).toLowerCase())) {
            errors.email = "Invalid Email";
        }
        if (email.trim() === "") {
            errors.email = "Email is required";
        }
        if (practicing_since.trim() === "") {
            errors.practicing_since = "Practicing years is required";
        }
        if (license_number.trim() === "") {
            errors.license_number = "License number is required";
        }
        if (ptr.trim() === "") {
            errors.ptr = "Proffesional tax receipt is required";
        }
        if (school.trim() === "") {
            errors.school = "School is required";
        }
        if (degree.trim() === "") {
            errors.degree = "Degree is required";
        }
        if (year.trim() === "") {
            errors.year = "Year is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async () => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            //check if username exist

            if (formData.consultation_price < 300) {
                Modal.info({
                    title: "Minimun fee must be PHP 300",
                    content: `PHP 300 is the minumun fee so that we can cater the payment portal usage fee, video server usage fee, software maintenance etc. Your original Professional/Consultation fee will remain intact on your payment/payout list.`,
                    onOk() {}
                });
            } else {
                setIsProfileSaving(true);
                try {
                    const response = await http.get(
                        `${apiUrl}/checkUsernameExist/${
                            formData.username || "nousername"
                        }/${formData.email}`,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                CancelRequest = c;
                            })
                        }
                    );

                    const { isExist } = response.data;

                    if (isExist) {
                        setFormErrors({
                            ...formErrors,
                            username: "username already exist"
                        });
                        setIsProfileSaving(false);
                    } else {
                        console.log(formData);
                        //upload photo
                        //upload only if there's a selected image
                        let doctorImgPath = formData.img_path;
                        let doctorImgSize = formData.img_size;

                        if (isDoctorImageChange) {
                            //create filename
                            const doctorOrigSizeImgFilename =
                                Date.now() + ".jpeg";
                            const doctorCardSizeImgFilename =
                                doctorOrigSizeImgFilename.replace(
                                    ".jpeg",
                                    "-card.jpeg"
                                );
                            const doctorThumbnailImgFilename =
                                doctorOrigSizeImgFilename.replace(
                                    ".jpeg",
                                    "-thumb.jpeg"
                                );

                            //convert b64 img to file
                            const doctorOrigSizeImgFile = b64ImgToFileHelper(
                                doctorImg.origSizeImg,
                                doctorOrigSizeImgFilename
                            );
                            const doctorCardSizeImgFile = b64ImgToFileHelper(
                                doctorImg.cardSizeImg,
                                doctorCardSizeImgFilename
                            );
                            const doctorThumbnailSizeImgFile =
                                b64ImgToFileHelper(
                                    doctorImg.thumbnailSizeImg,
                                    doctorThumbnailImgFilename
                                );

                            //get file size
                            const doctorOrigSizeImgFileSize =
                                doctorOrigSizeImgFile.size;
                            const doctorCardSizeImgFileSize =
                                doctorCardSizeImgFile.size;
                            const doctorThumbnailSizeImgFileSize =
                                doctorThumbnailSizeImgFile.size;

                            doctorImgSize =
                                doctorOrigSizeImgFileSize +
                                doctorCardSizeImgFileSize +
                                doctorThumbnailSizeImgFileSize;

                            //prepare to upload
                            let fd = new FormData();
                            fd.append("uniFile", doctorOrigSizeImgFile);
                            fd.append("uniFile", doctorCardSizeImgFile);
                            fd.append("uniFile", doctorThumbnailSizeImgFile);

                            const uploadResponse = await http.post(
                                `${apiUrl}/doctorImage`,
                                fd,
                                {
                                    params: {
                                        space_server: uploadServer,
                                        space_bucket_name: uploadEndpoint,
                                        root_dir: cwaUploadRootDir,
                                        owner_id: ownerID,
                                        clinic_id: clinicID
                                    },
                                    cancelToken: new CancelToken(
                                        function executor(c) {
                                            CancelRequest = c;
                                        }
                                    )
                                }
                            );

                            if (uploadResponse.data.error) {
                                message.error(
                                    "Failed to save doctor data. Upload image error"
                                );
                            } else {
                                doctorImgPath = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${uploadResponse.data.datePath}/${doctorOrigSizeImgFilename}`;
                            }
                        }

                        const isBankDetailsCompleted =
                            formData.bank_name.trim() &&
                            formData.bank_branch.trim() &&
                            formData.bank_account_name.trim() &&
                            formData.bank_account_number.trim() &&
                            formData.bank_account_type.trim();

                        const isDataCompleted =
                            isBankDetailsCompleted &&
                            portalSchedule &&
                            doctorImgPath
                                ? 1
                                : 0;

                        //build new tag
                        let firstname = formData.firstname.toLowerCase();
                        let middlename = formData.middlename.toLowerCase();
                        let lastname = formData.lastname.toLowerCase();
                        let aopName = aops
                            .find(aop => aop.aop_id === formData.aop_id)
                            .aop_name.toLowerCase();

                        let aopsSubTags = aopsSub
                            .filter(
                                aopSub =>
                                    formData.aop_sub_ids.indexOf(
                                        aopSub.aop_sub_id
                                    ) !== -1
                            )
                            .reduce((acc, item) => {
                                acc += ` ${item.aop_sub_name.toLowerCase()}`;
                                return acc;
                            }, "");

                        const tags = `${firstname} ${middlename} ${lastname} ${aopName}${aopsSubTags}`;

                        const profileData = {
                            ...formData,
                            img_path: doctorImgPath,
                            img_size: doctorImgSize,
                            aop_sub_ids: JSON.stringify(formData.aop_sub_ids),
                            tags,
                            schedule: JSON.stringify(portalSchedule),
                            is_data_completed: isDataCompleted
                        };

                        await http.put(
                            `${apiUrl}/doctor/profile/${doctorDetails.doctor_id}`,
                            profileData,
                            {
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                })
                            }
                        );

                        if (isDataCompleted) {
                            setFormData({
                                ...formData,
                                is_data_completed: 1
                            });
                        }

                        editDoctor({
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            degree: formData.degree,
                            is_data_completed: isDataCompleted
                        });

                        message.success("Profile Successfully Updated");
                        setIsProfileNeedToSave(false);
                        setIsProfileSaving(false);
                    }
                } catch (error) {
                    if (!error.message.responseCancelled) {
                        setRequestError({
                            errorMessage:
                                "Something went wrong while fetching your data :(",
                            errorSubMessage: error.message
                        });
                    }
                }
            }
        }
    };

    return (
        <>
            {isProfileNeedToSave ? (
                <Alert
                    message="There is a data changes in your profile!"
                    description="You need to save your profile in order to apply the changes."
                    showIcon
                    type="warning"
                />
            ) : formData.is_data_completed ? (
                <Alert
                    message="Profile Complete!"
                    description={
                        <div>
                            Enable your Tele-Medecine feature on{" "}
                            <Link to="/account">My Account</Link> so that the
                            patient can choose you.
                        </div>
                    }
                    showIcon
                    type="success"
                />
            ) : (
                <Alert
                    message="Your profile is not complete!"
                    description="Please complete the required fields, bank details, upload your image and create your schedule in order to complete your profile."
                    showIcon
                    type="error"
                />
            )}

            <Row gutter={16}>
                <Col lg={12} md={12}>
                    <Form
                        layout="horizontal"
                        labelCol={{
                            xl: { span: 7 },
                            lg: { span: 6 }
                        }}
                        wrapperCol={{
                            xl: { span: 17 },
                            lg: { span: 16 }
                        }}
                        style={{ width: "100%" }}
                        //onSubmit={handleSubmit}
                    >
                        <Divider
                            orientation="center"
                            style={{ fontSize: "1rem" }}
                        >
                            Personal Details
                        </Divider>
                        <div style={{ textAlign: "center" }}>
                            <DoctorImage
                                image={doctorImg.origSizeImg}
                                breakpoint={breakpoint}
                                onImageChange={handleImageChange}
                            />
                        </div>

                        <Form.Item
                            label="First Name"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.firstname && "error"}
                            help={formErrors.firstname && formErrors.firstname}
                        >
                            <Input
                                name="firstname"
                                autoComplete="off"
                                value={formData.firstname}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Middle Name"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.middlename && "error"}
                            help={
                                formErrors.middlename && formErrors.middlename
                            }
                        >
                            <Input
                                name="middlename"
                                autoComplete="off"
                                value={formData.middlename}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.lastname && "error"}
                            help={formErrors.lastname && formErrors.lastname}
                        >
                            <Input
                                name="lastname"
                                autoComplete="off"
                                value={formData.lastname}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Divider
                            orientation="center"
                            style={{ fontSize: "1rem" }}
                        >
                            Profession Details
                        </Divider>
                        <Form.Item label="Area Of Practice">
                            <Select
                                disabled
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "aop_id",
                                            value
                                        }
                                    })
                                }
                                value={formData.aop_id}
                            >
                                {aops.map(aop => (
                                    <Option key={aop.aop_id} value={aop.aop_id}>
                                        {aop.aop_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Sub Area of Practice">
                            <Select
                                value={formData.aop_sub_ids}
                                mode="multiple"
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "aop_sub_ids",
                                            value
                                        }
                                    })
                                }
                            >
                                {aopsSub.map(aopSub => (
                                    <Option
                                        key={aopSub.aop_sub_id}
                                        value={aopSub.aop_sub_id}
                                    >
                                        {aopSub.aop_sub_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Practicing Since"
                            required={true}
                            hasFeedback
                            validateStatus={
                                formErrors.practicing_since && "error"
                            }
                            help={
                                formErrors.practicing_since &&
                                formErrors.practicing_since
                            }
                        >
                            <Input
                                name="practicing_since"
                                autoComplete="off"
                                value={formData.practicing_since}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="License Number"
                            required={true}
                            hasFeedback
                            validateStatus={
                                formErrors.license_number && "error"
                            }
                            help={
                                formErrors.license_number &&
                                formErrors.license_number
                            }
                        >
                            <Input
                                name="license_number"
                                autoComplete="off"
                                value={formData.license_number}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="PTR"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.ptr && "error"}
                            help={formErrors.ptr && formErrors.ptr}
                        >
                            <Input
                                name="ptr"
                                autoComplete="off"
                                value={formData.ptr}
                                placeholder="Proffesional Tax Receipt"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Divider
                            orientation="center"
                            style={{ fontSize: "1rem" }}
                        >
                            Medical Education
                        </Divider>
                        <Form.Item
                            label="School"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.school && "error"}
                            help={formErrors.school && formErrors.school}
                        >
                            <Input
                                name="school"
                                autoComplete="off"
                                value={formData.school}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Degree"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.degree && "error"}
                            help={formErrors.degree && formErrors.degree}
                        >
                            <Input
                                name="degree"
                                autoComplete="off"
                                value={formData.degree}
                                placeholder="MD, MS, ENT, OB/GYN, PharmD"
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Year"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.year && "error"}
                            help={formErrors.year && formErrors.year}
                        >
                            <Input
                                name="year"
                                autoComplete="off"
                                value={formData.year}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Divider
                            orientation="center"
                            style={{ fontSize: "1rem" }}
                        >
                            Bank Details
                        </Divider>
                        <Form.Item label="Bank Name">
                            <Input
                                name="bank_name"
                                autoComplete="off"
                                value={formData.bank_name}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Account Name">
                            <Input
                                name="bank_account_name"
                                autoComplete="off"
                                value={formData.bank_account_name}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Account Number">
                            <Input
                                name="bank_account_number"
                                autoComplete="off"
                                value={formData.bank_account_number}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Account Type">
                            <Input
                                name="bank_account_type"
                                autoComplete="off"
                                value={formData.bank_account_type}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Form.Item label="Bank Branch">
                            <Input
                                name="bank_branch"
                                autoComplete="off"
                                value={formData.bank_branch}
                                onChange={handleInputChange}
                            />
                        </Form.Item>
                        <Divider
                            orientation="center"
                            style={{ fontSize: "1rem" }}
                        >
                            Account Details
                        </Divider>
                        <Form.Item
                            label="Email"
                            required={true}
                            hasFeedback
                            validateStatus={formErrors.email && "error"}
                            help={formErrors.email && formErrors.email}
                        >
                            <Input
                                name="email"
                                autoComplete="off"
                                value={formData.email}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                disabled={true}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Username"
                            hasFeedback
                            validateStatus={formErrors.username && "error"}
                            help={formErrors.username && formErrors.username}
                        >
                            <Input
                                name="username"
                                autoComplete="off"
                                value={formData.username}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col lg={12} md={12}>
                    <Divider orientation="center" style={{ fontSize: "1rem" }}>
                        Tele-Medicine Schedule
                    </Divider>
                    <TeleSched
                        portalSchedule={portalSchedule}
                        onChange={handlePortalSchedChange}
                    />
                    <Divider orientation="center" style={{ fontSize: "1rem" }}>
                        Other Tele-Medicine Info
                    </Divider>
                    <Form
                        layout="horizontal"
                        labelCol={{
                            xl: { span: 12 },
                            lg: { span: 12 }
                        }}
                        wrapperCol={{
                            xl: { span: 12 },
                            lg: { span: 12 }
                        }}
                        style={{ width: "100%" }}
                    >
                        <Form.Item label="Fee Description">
                            <Input
                                name="consultation_desc"
                                autoComplete="off"
                                value={formData.consultation_desc}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                        <Form.Item label={formData.consultation_desc} required>
                            <InputNumber
                                min={0}
                                step={1}
                                value={formData.consultation_price}
                                onChange={handlePriceChange}
                            />
                            <span style={{ marginLeft: 10 }}>
                                <Popover
                                    placement="top"
                                    content={
                                        <div
                                            style={{
                                                textAlign: "left",
                                                maxWidth: 320
                                            }}
                                        >
                                            <p>
                                                <Icon
                                                    type="info-circle"
                                                    theme="twoTone"
                                                />{" "}
                                                PHP 300 is the minumun fee so
                                                that we can cater the payment
                                                portal usage fee, video server
                                                usage fee, software maintenance
                                                etc. Your original
                                                Professional/Consultation fee
                                                will remain intact on your
                                                payment/payout list.
                                            </p>
                                        </div>
                                    }
                                >
                                    <Icon type="info-circle" theme="twoTone" />
                                </Popover>
                            </span>
                        </Form.Item>
                        <Form.Item label="Price in Patient Portal">
                            <InputNumber
                                value={
                                    formData.consultation_price *
                                    (appMiscPercent / 100 + 1)
                                }
                                readOnly
                            />
                            <span style={{ marginLeft: 10 }}>
                                <Popover
                                    placement="top"
                                    content={
                                        <div
                                            style={{
                                                textAlign: "left",
                                                maxWidth: 320
                                            }}
                                        >
                                            <p>
                                                <Icon
                                                    type="info-circle"
                                                    theme="twoTone"
                                                />{" "}
                                                Professional/Consultation fee
                                                will increase {appMiscPercent}%
                                                on the actual patient app view,
                                                this percentage is intended for
                                                payment portal usage fee, video
                                                server usage fee, software
                                                maintenance etc. Your original
                                                Professional/Consultation fee
                                                will remain intact on your
                                                payment/payout list.
                                            </p>
                                        </div>
                                    }
                                >
                                    <Icon type="info-circle" theme="twoTone" />
                                </Popover>
                            </span>
                        </Form.Item>
                        <Form.Item label="Display Clinic Name" required>
                            <Select
                                name="primary_clinic_id"
                                autoComplete="off"
                                value={formData.primary_clinic_id}
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "primary_clinic_id",
                                            value
                                        }
                                    })
                                }
                            >
                                {/* <Option value="">None</Option> */}
                                <Option value={clinicID}>{clinicName}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Display Hospital Name" required>
                            <Select
                                name="primary_hospital_id"
                                autoComplete="off"
                                value={formData.primary_hospital_id}
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "primary_hospital_id",
                                            value
                                        }
                                    })
                                }
                            >
                                <Option value="">None</Option>
                                {hospitals.map(hospital => (
                                    <Option
                                        key={hospital.hospital_id}
                                        value={hospital.hospital_id}
                                    >
                                        {hospital.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Calendar Current Date Margin"
                            style={{ marginTop: 10 }}
                        >
                            <Slider
                                max={20}
                                value={formData.calendar_today_margin}
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "calendar_today_margin",
                                            value
                                        }
                                    })
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Calendar Days Enabled">
                            <Slider
                                max={31}
                                value={formData.calendar_max_days_select}
                                onChange={value =>
                                    handleInputChange({
                                        target: {
                                            name: "calendar_max_days_select",
                                            value
                                        }
                                    })
                                }
                            />
                        </Form.Item>
                    </Form>
                    <div
                        style={{
                            textAlign: breakpoint.isNormalMobile
                                ? "left"
                                : "center",
                            marginTop: 20
                        }}
                    >
                        <h4>Your Patient Portal Calendar Preview</h4>
                        <div
                            style={{
                                width: breakpoint.isNormalMobile ? "100%" : 350,
                                marginLeft: breakpoint.isNormalMobile ? 0 : 100
                            }}
                        >
                            <Calendar
                                fullscreen={false}
                                headerRender={() => null}
                                disabledDate={datePickerDisabledDate}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <div style={{ marginTop: 20, textAlign: "center" }}>
                <Button
                    type="primary"
                    size="large"
                    loading={isProfileSaving}
                    onClick={handleSubmit}
                >
                    {isProfileSaving ? "Saving Profile" : "Save My Profile"}
                </Button>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    userID: state.UserDetails.user_id,
    appMiscPercent: state.UserDetails.masterSettings.app_misc_percent,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    clinicName: state.UserDetails.clinicDetails.name,
    ownerID: state.UserDetails.owner_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint
});

const mapDispatchToProps = dispatch => ({
    editDoctor: editedDoctor => dispatch(editDoctor(editedDoctor)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
