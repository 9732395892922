import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Checkbox, Select, Popover, Icon, Slider } from "antd";
import moment from "moment";

const TimeManager = ({ schedule, onChange, maxDuration }) => {
    const { Option } = Select;

    const [operation, setOperation] = useState([undefined, undefined]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedTimeSlotDuration, setSelectedTimeSlotDuration] = useState(
        15
    );
    const [selectedTimeSlotGap, setSelectedTimeSlotGap] = useState(5);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);

    const [endTimeOptions, setEndTimeOptions] = useState([
        { label: "6:00AM", value: "06:00:00", key: 1 },
        { label: "7:00AM", value: "07:00:00", key: 2 },
        { label: "8:00AM", value: "08:00:00", key: 3 },
        { label: "9:00AM", value: "09:00:00", key: 4 },
        { label: "10:00AM", value: "10:00:00", key: 5 },
        { label: "11:00AM", value: "11:00:00", key: 6 },
        { label: "12:00PM", value: "12:00:00", key: 7 },
        { label: "1:00PM", value: "13:00:00", key: 8 },
        { label: "2:00PM", value: "14:00:00", key: 9 },
        { label: "3:00PM", value: "15:00:00", key: 10 },
        { label: "4:00PM", value: "16:00:00", key: 11 },
        { label: "5:00PM", value: "17:00:00", key: 12 },
        { label: "6:00PM", value: "18:00:00", key: 13 },
        { label: "7:00PM", value: "19:00:00", key: 14 },
        { label: "8:00PM", value: "20:00:00", key: 15 },
    ]);
    const [startTimeOptions] = useState([
        { label: "6:00AM", value: "06:00:00", key: 1 },
        { label: "7:00AM", value: "07:00:00", key: 2 },
        { label: "8:00AM", value: "08:00:00", key: 3 },
        { label: "9:00AM", value: "09:00:00", key: 4 },
        { label: "10:00AM", value: "10:00:00", key: 5 },
        { label: "11:00AM", value: "11:00:00", key: 6 },
        { label: "12:00PM", value: "12:00:00", key: 7 },
        { label: "1:00PM", value: "13:00:00", key: 8 },
        { label: "2:00PM", value: "14:00:00", key: 9 },
        { label: "3:00PM", value: "15:00:00", key: 10 },
        { label: "4:00PM", value: "16:00:00", key: 11 },
        { label: "5:00PM", value: "17:00:00", key: 12 },
        { label: "6:00PM", value: "18:00:00", key: 13 },
        { label: "7:00PM", value: "19:00:00", key: 14 },
        { label: "8:00PM", value: "20:00:00", key: 15 },
    ]);

    useEffect(() => {
        if (schedule.operation[0] !== undefined) {
            const { operation, timeSlotDuration, timeSlotGap } = schedule;

            setOperation(operation);
            setSelectedTimeSlotDuration(timeSlotDuration);
            setSelectedTimeSlotGap(timeSlotGap);
            setTimeSlots(
                generateTimeSlots(
                    operation[0],
                    operation[1],
                    timeSlotDuration,
                    timeSlotGap
                ).timeSlots
            );
            setSelectedTimeSlots(
                schedule.timeSlots.map((timeSlot) => timeSlot.key)
            );
        }
    }, []);

    function generateTimeSlots(opStartTime, opEndTime, duration, gap) {
        const startTime = moment(opStartTime, "HH:mm:ss");
        const endTime = moment(opEndTime, "HH:mm:ss");

        //split time
        const splittedTime = [];
        var current = moment(startTime);
        let counter = 0;
        while (current <= endTime) {
            splittedTime.push(current.format("HH:mm:ss"));
            if (counter === 1) {
                current.add(gap, "minutes");
                counter = 0;
            } else {
                current.add(duration, "minutes");
                counter++;
            }
        }

        //group time
        let timeSlots = [];
        let timeSlot = {};
        let timeSlotKeys = [];
        counter = 0;
        splittedTime.forEach((time, index) => {
            if (counter === 2) {
                timeSlots.push(timeSlot);
                timeSlot = {};
                timeSlot.start = time;
                counter = 1;
            } else {
                if (counter === 1) {
                    timeSlot.end = time;
                    timeSlot.duration = duration;
                    timeSlot.key = index;
                    timeSlotKeys.push(index);
                } else {
                    timeSlot.start = time;
                }
                counter++;
            }
        });

        return {
            timeSlots,
            timeSlotKeys,
        };
    }

    const handleStartTimeChange = (value, option) => {
        setOperation([value, undefined]);
        setEndTimeOptions(
            endTimeOptions.filter((time) => time.key > Number(option.key))
        );
        setTimeSlots([]);
    };

    const handleEndTimeChange = (value) => {
        const { timeSlots, timeSlotKeys } = generateTimeSlots(
            operation[0],
            value,
            selectedTimeSlotDuration,
            selectedTimeSlotGap
        );

        setOperation([operation[0], value]);
        setTimeSlots(timeSlots);
        setSelectedTimeSlots(timeSlotKeys);

        onChange(
            schedule.day,
            [operation[0], value],
            selectedTimeSlotDuration,
            selectedTimeSlotGap,
            timeSlotKeys
        );
    };

    const handleTimeSlotDurationChange = (value) => {
        const { timeSlots, timeSlotKeys } = generateTimeSlots(
            operation[0],
            operation[1],
            value,
            selectedTimeSlotGap
        );

        setSelectedTimeSlotDuration(value);
        setTimeSlots(timeSlots);
        setSelectedTimeSlots(timeSlotKeys);

        onChange(
            schedule.day,
            [operation[0], operation[1]],
            value,
            selectedTimeSlotGap,
            timeSlotKeys
        );
    };

    const handleTimeSlotGapChange = (value) => {
        const { timeSlots, timeSlotKeys } = generateTimeSlots(
            operation[0],
            operation[1],
            selectedTimeSlotDuration,
            value
        );

        setSelectedTimeSlotGap(value);
        setTimeSlots(timeSlots);
        setSelectedTimeSlots(timeSlotKeys);

        onChange(
            schedule.day,
            [operation[0], operation[1]],
            selectedTimeSlotDuration,
            value,
            timeSlotKeys
        );
    };

    const handleSelectedTimeSlotsChage = (values) => {
        onChange(
            schedule.day,
            operation,
            selectedTimeSlotDuration,
            selectedTimeSlotGap,
            values
        );
        setSelectedTimeSlots(values);
    };

    return (
        <>
            <Row gutter={8}>
                <Col span={14}>
                    <h4>Time Slot Duration</h4>
                </Col>
                <Col span={10}>
                    {/* <Select 							
						style={{width: "100%"}}
						value={selectedTimeSlotDuration}
						onChange={handleTimeSlotDurationChange}
					>
						<Option value={5}>5 mins</Option>
						<Option value={10}>10 mins</Option>
						<Option value={15}>15 mins</Option>
					</Select> */}
                    <Slider
                        value={selectedTimeSlotDuration}
                        max={maxDuration}
                        min={1}
                        tipFormatter={(v) => `${v} minutes`}
                        onChange={handleTimeSlotDurationChange}
                    />
                </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: 5 }}>
                <Col span={14}>
                    <h4>Time Slot Gap</h4>
                </Col>
                <Col span={10}>
                    <Select
                        style={{ width: "100%" }}
                        value={selectedTimeSlotGap}
                        onChange={handleTimeSlotGapChange}
                    >
                        <Option value={1}>1 mins</Option>
                        <Option value={2}>2 mins</Option>
                        <Option value={3}>3 mins</Option>
                        <Option value={5}>5 mins</Option>
                        <Option value={10}>10 mins</Option>
                        <Option value={20}>20 mins</Option>
                        <Option value={25}>25 mins</Option>
                        <Option value={30}>30 mins</Option>
                    </Select>
                </Col>
            </Row>
            <h4>
                Select Time Range{" "}
                <Popover content="Selecting time range will create time slots based on the above settings.">
                    <Icon type="info-circle" theme="twoTone" />
                </Popover>
            </h4>
            <Row>
                <Col span={10}>
                    <Select
                        value={operation[0]}
                        placeholder="Start Time"
                        style={{ width: "100%" }}
                        onChange={handleStartTimeChange}
                    >
                        {startTimeOptions.map((time) => (
                            <Option key={time.key} value={time.value}>
                                {time.label}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <div style={{ textAlign: "center" }}>To</div>
                </Col>
                <Col span={10}>
                    <Select
                        value={operation[1]}
                        placeholder="End Time"
                        style={{ width: "100%" }}
                        onChange={handleEndTimeChange}
                    >
                        {endTimeOptions.map((time) => (
                            <Option key={time.key} value={time.value}>
                                {time.label}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <div style={{ marginTop: 10 }}>
                <Checkbox.Group
                    value={selectedTimeSlots}
                    onChange={handleSelectedTimeSlotsChage}
                >
                    {timeSlots.map((timeSlot) => (
                        <div key={timeSlot.key}>
                            <Checkbox value={timeSlot.key}>
                                {moment(timeSlot.start, "HH:mm:ss").format(
                                    "hh:mm A"
                                )}{" "}
                                -{" "}
                                {moment(timeSlot.end, "HH:mm:ss").format(
                                    "hh:mm A"
                                )}
                            </Checkbox>
                        </div>
                    ))}
                </Checkbox.Group>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    maxDuration: state.UserDetails.masterSettings.video_minute_max,
});

export default connect(mapStateToProps)(TimeManager);
