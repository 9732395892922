import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";

const ClinicForm = ({ data, onSubmit }) => {
    const [formData, setFormData] = useState({
        name: "",
        legal_name: "",
        description: "",
        address: "",
        telephone: "",
        email: "",
        mobile: "",
        schedule: "",
        website: "",
        img_path: "",
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
        email: "",
    });

    useEffect(() => {
        setFormData(data);
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name, email } = formData;
        let errors = {};
        let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

        if (name.trim() === "") {
            errors.name = "Clinic name is required";
        }
        if (
            email.trim() !== "" &&
            !emailRegEx.test(String(email).toLowerCase())
        ) {
            errors.email = "Invalid Email";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{
                    xl: { span: 7 },
                    lg: { span: 7 },
                }}
                wrapperCol={{
                    xl: { span: 17 },
                    lg: { span: 17 },
                }}
                style={{ width: "100%" }}
            >
                <Form.Item
                    label="Clinic Name"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.name && "error"}
                    help={formErrors.name && formErrors.name}
                >
                    <Input
                        name="name"
                        autoComplete="off"
                        value={formData.name}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Legal Name">
                    <Input
                        name="legal_name"
                        autoComplete="off"
                        value={formData.legal_name}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Address">
                    <Input
                        name="address"
                        autoComplete="off"
                        value={formData.address}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Telephone">
                    <Input
                        name="telephone"
                        autoComplete="off"
                        value={formData.telephone}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    hasFeedback
                    validateStatus={formErrors.email && "error"}
                    help={formErrors.email && formErrors.email}
                >
                    <Input
                        name="email"
                        autoComplete="off"
                        value={formData.email}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Mobile">
                    <Input
                        name="mobile"
                        autoComplete="off"
                        value={formData.mobile}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Schedule">
                    <Input
                        name="schedule"
                        autoComplete="off"
                        value={formData.schedule}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label="Website">
                    <Input
                        name="website"
                        autoComplete="off"
                        value={formData.website}
                        onChange={handleInputChange}
                    />
                </Form.Item>
                <Form.Item label=" ">
                    <Button
                        type="primary"
                        style={{ marginTop: 10 }}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default ClinicForm;
