import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button } from "antd";

const CustomItemForm = props => {
    const { onSubmit } = props;

    const [formData, setFormData] = useState({
        name: "",
        amount: 0
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        amount: ""
    });

    const txtName = React.createRef();

    useEffect(() => {
        txtName.current.input.focus();
    }, []);

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAmountChange = e => {
        if (e !== null) {
            let value = e.toString().replace(/[^0-9.,]/g, "");
            setFormData({
                ...formData,
                amount: Number(value)
            });
        } else {
            setFormData({
                ...formData,
                amount: 0
            });
        }
    };

    const handleBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.toString().trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const resetFormData = () => {
        setFormData({
            name: "",
            amount: 0
        });

        setFormErrors({
            name: "",
            amount: ""
        });

        txtName.current.input.focus();
    };

    const validateInput = () => {
        const { name, amount } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Item name is required";
        }

        if (amount === null) {
            errors.amount = "Amount is required";
        }

        const errorLength = Object.keys(errors).length;

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
            resetFormData();
        }
    };

    return (
        <>
            <Form>
                <Form.Item
                    label="Item Name"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.name && "error"}
                    help={formErrors.name && formErrors.name}
                >
                    <Input
                        autoComplete="off"
                        ref={txtName}
                        value={formData.name}
                        onChange={e => handleChange("name", e.target.value)}
                        onBlur={e => handleBlur("name", e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Amount"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.amount && "error"}
                    help={formErrors.amount && formErrors.amount}
                >
                    <InputNumber
                        name="amount"
                        autoComplete="off"
                        value={formData.amount}
                        formatter={value =>
                            `${value}`
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                .replace(/[^0-9.,]/g, "")
                        }
                        onChange={value => handleAmountChange(value)}
                        onBlur={e => handleBlur("amount", e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Button
                    type="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: 10 }}
                >
                    Add to List
                </Button>
            </Form>
        </>
    );
};

export default CustomItemForm;
