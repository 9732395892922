import {
    SET_SAVED_CASE_NOTES_SUCCESS,
    ADD_SAVED_CASE_NOTE_SUCCESS,
    DELETE_SAVED_CASE_NOTE_SUCCESS
} from "./savedCaseNotes.types";

const INIT_STATE = [];

const savedCaseNotesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SAVED_CASE_NOTES_SUCCESS:
            return action.payload.savedCaseNotes;

        case ADD_SAVED_CASE_NOTE_SUCCESS:
            return [...state, action.payload.savedCaseNote];

        case DELETE_SAVED_CASE_NOTE_SUCCESS:
            return state.filter(
                savedCaseNote =>
                    savedCaseNote.saved_case_note_id !==
                    action.payload.savedCaseNoteID
            );

        default:
            return state;
    }
};

export default savedCaseNotesReducer;
