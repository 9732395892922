import React, { useState, useEffect } from "react";
//import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Button,
    Divider,
    message,
    Card,
    Radio,
    Checkbox,
    Row,
    Col,
    Menu,
    Dropdown,
    Icon
} from "antd";
import ModalDetails from "globalComponents/ModalDetails";
import AddYTPlaylistForm from "./components/Forms/AddYTPlaylistForm";
import AddVideoForm from "./components/Forms/AddVideoForm";
import AddPhotoForm from "./components/Forms/AddPhotoForm";

import { generateString } from "helpers/randomStringGenerator";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Multimedia = ({ clinicID, queueingDetails, setRequestError }) => {
    const [YTPlaylist, setYTPlaylist] = useState([]);
    const [uploadedVids, setUploadedVids] = useState([]);
    const [uploadedPhotos, setUploadedPhotos] = useState([]);

    const [selectedMultimedia, setSelectedMultimedia] = useState("video");
    const [selectedPlayingVideo, setSelectedPlayingVideo] = useState("yt");
    const [selectedYTPlaylist, setSelectedYTPlaylist] = useState("");
    const [selectedUploadedVid, setSelectedUploadedVid] = useState("");
    const [selectedPhotos, setSelectedPhotos] = useState([]);

    const [isYTFormVisible, setIsYTFormVisible] = useState(false);
    const [isVideoFormVisible, setIsVideoFormVisible] = useState(false);
    const [isPhotoFormVisible, setIsPhotoFormVisible] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (queueingDetails) {
            setYTPlaylist(queueingDetails.YTPlaylist);
            setUploadedVids(queueingDetails.uploadedVids);
            setUploadedPhotos(queueingDetails.uploadedPhotos);

            setSelectedMultimedia(
                queueingDetails.data.is_use_photo ? "photo" : "video"
            );
            setSelectedPlayingVideo(
                queueingDetails.data.is_use_video ? "uploadedVids" : "yt"
            );

            setSelectedYTPlaylist(queueingDetails.data.queueing_yt_playlist_id);
            setSelectedUploadedVid(
                queueingDetails.data.queueing_uploaded_vid_id
            );
            setSelectedPhotos(
                queueingDetails.uploadedPhotos
                    .filter(p => p.is_selected === 1)
                    .map(p => p.queueing_uploaded_photo_id)
            );
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, [queueingDetails]);

    const toggleYTFormVisible = () => {
        setIsYTFormVisible(!isYTFormVisible);
    };

    const toggleVideoFormVisible = () => {
        setIsVideoFormVisible(!isVideoFormVisible);
    };

    const togglePhotoFormVisible = () => {
        setIsPhotoFormVisible(!isPhotoFormVisible);
    };

    const handleMultimediaChange = e => {
        setSelectedMultimedia(e.target.value);
    };

    const handlePlayingVideoChange = e => {
        setSelectedPlayingVideo(e.target.value);
    };

    const handleYTPlaylistChange = e => {
        setSelectedYTPlaylist(e.target.value);
    };

    const handleUploadedVidChange = e => {
        setSelectedUploadedVid(e.target.value);
    };

    const handlePhotoChange = values => {
        setSelectedPhotos(values);
    };

    const handleYTPlaylistFormSubmit = async formData => {
        const YTPlaylistID = generateString(11);
        const newFormData = {
            queueing_yt_playlist_id: YTPlaylistID,
            ...formData
        };

        setYTPlaylist([...YTPlaylist, newFormData]);
        toggleYTFormVisible();

        try {
            await http.post(`${apiUrl}/YTPlaylist`, newFormData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handlePhotoFormSubmit = async formData => {
        const uploadedPhotoID = generateString(11);
        const newFormData = {
            queueing_uploaded_photo_id: uploadedPhotoID,
            ...formData
        };

        setUploadedPhotos([...uploadedPhotos, newFormData]);
        togglePhotoFormVisible();

        try {
            await http.post(`${apiUrl}/uploadedPhoto`, newFormData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleVideoFormSubmit = async formData => {
        const uploadedVidID = generateString(11);
        const newFormData = {
            queueing_uploaded_vid_id: uploadedVidID,
            ...formData
        };

        setUploadedVids([...uploadedVids, newFormData]);
        toggleVideoFormVisible();

        try {
            await http.post(`${apiUrl}/uploadedVid`, newFormData);
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    const handleYTPlaylistDelete = async () => {
        if (selectedYTPlaylist) {
            const newYTPlaylist = YTPlaylist.filter(
                ytp => ytp.queueing_yt_playlist_id !== selectedYTPlaylist
            );

            setYTPlaylist(newYTPlaylist);
            setSelectedYTPlaylist("");

            try {
                await http.delete(`${apiUrl}/YTPlaylist/${selectedYTPlaylist}`);
                if (newYTPlaylist.length === 0) {
                    await http.put(`${apiUrl}/queueDetails/${clinicID}`, {
                        queueing_yt_playlist_id: ""
                    });
                }
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("No Selected Playlist");
        }
    };

    const handleVideoDelete = async () => {
        if (selectedUploadedVid) {
            const newUploadedVids = uploadedVids.filter(
                uploadedVid =>
                    uploadedVid.queueing_uploaded_vid_id !== selectedUploadedVid
            );

            setUploadedVids(newUploadedVids);
            setSelectedUploadedVid("");

            try {
                await http.delete(
                    `${apiUrl}/uploadedVid/${selectedUploadedVid}`
                );

                if (newUploadedVids.length === 0) {
                    await http.put(`${apiUrl}/queueDetails/${clinicID}`, {
                        queueing_uploaded_vid_id: ""
                    });
                }
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("No Selected Video");
        }
    };

    const handlePhotoDelete = async () => {
        if (selectedPhotos.length !== 0) {
            setUploadedPhotos(
                uploadedPhotos.filter(
                    uploadedPhoto =>
                        selectedPhotos.indexOf(
                            uploadedPhoto.queueing_uploaded_photo_id
                        ) === -1
                )
            );

            try {
                await http.post(`${apiUrl}/deletePhotos`, selectedPhotos);
            } catch (error) {
                setRequestError({
                    errorMessage:
                        "Something went wrong on your last operation :(",
                    errorSubMessage: error.message
                });
            }
        } else {
            message.error("No Selected Photo(s)");
        }
    };

    const saveAndApply = async () => {
        if (selectedMultimedia === "photo" && selectedPhotos.length === 0) {
            message.error("Please select photo(s) to be displayed");
        } else if (
            selectedMultimedia === "video" &&
            selectedPlayingVideo === "yt" &&
            selectedYTPlaylist === ""
        ) {
            message.error("Please select Youtube playlist");
        } else if (
            selectedMultimedia === "video" &&
            selectedPlayingVideo === "uploadedVids" &&
            selectedUploadedVid === ""
        ) {
            message.error("Please select uploaded video");
        } else {
            try {
                setIsSaving(true);
                await http.put(
                    `${apiUrl}/queueDetails/${clinicID}`,
                    {
                        queueing_yt_playlist_id: selectedYTPlaylist,
                        queueing_uploaded_vid_id: selectedUploadedVid,
                        is_use_photo: selectedMultimedia === "photo" ? 1 : 0,
                        is_use_video: selectedPlayingVideo === "yt" ? 0 : 1
                    },
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                await http.put(
                    `${apiUrl}/selectedPhotos/${clinicID}`,
                    selectedPhotos,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                setIsSaving(false);
                message.success("Successfully Saved");

                socket.emit("reloadQueueing", clinicID);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        }
    };

    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px"
    };

    const YTPlaylistMenu = (
        <Menu>
            <Menu.Item key="0" onClick={toggleYTFormVisible}>
                Add Playlist
            </Menu.Item>
            <Menu.Item key="1" onClick={handleYTPlaylistDelete}>
                Delete Selected
            </Menu.Item>
        </Menu>
    );

    const UploadedVideoMenu = (
        <Menu>
            <Menu.Item key="0" onClick={toggleVideoFormVisible}>
                Add Video
            </Menu.Item>
            <Menu.Item key="1" onClick={handleVideoDelete}>
                Delete Selected
            </Menu.Item>
        </Menu>
    );

    const UploadedPhotoMenu = (
        <Menu>
            <Menu.Item key="0" onClick={togglePhotoFormVisible}>
                Add Photo
            </Menu.Item>
            <Menu.Item key="1" onClick={handlePhotoDelete}>
                Delete Selected
            </Menu.Item>
        </Menu>
    );

    return (
        <Card title="Multimedia" style={{ marginBottom: 10 }}>
            <Row>
                <Col lg={4}>
                    <div>
                        <strong>I want to display:</strong>
                    </div>
                    <Radio.Group
                        value={selectedMultimedia}
                        onChange={handleMultimediaChange}
                    >
                        <Radio style={radioStyle} value={"video"}>
                            Video
                        </Radio>
                        <Radio style={radioStyle} value={"photo"}>
                            Photo
                        </Radio>
                    </Radio.Group>
                </Col>
                <Col lg={20}>
                    {selectedMultimedia === "video" ? (
                        <Card title="Video">
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <strong>I want to play:</strong>
                                    </div>
                                    <Radio.Group
                                        value={selectedPlayingVideo}
                                        onChange={handlePlayingVideoChange}
                                    >
                                        <Radio style={radioStyle} value={"yt"}>
                                            Youtube Playlist
                                        </Radio>
                                        <Radio
                                            style={radioStyle}
                                            value={"uploadedVids"}
                                        >
                                            Uploaded Video
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                                <Col lg={18}>
                                    {selectedPlayingVideo === "yt" ? (
                                        <Card
                                            title="Your Youtube Playlist"
                                            extra={
                                                <Dropdown
                                                    overlay={YTPlaylistMenu}
                                                    trigger={["click"]}
                                                >
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={e =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Options{" "}
                                                        <Icon type="down" />
                                                    </Button>
                                                </Dropdown>
                                            }
                                        >
                                            <div>
                                                <strong>
                                                    Select Youtube Playlist
                                                </strong>
                                            </div>
                                            {YTPlaylist.length !== 0 ? (
                                                <Radio.Group
                                                    value={selectedYTPlaylist}
                                                    onChange={
                                                        handleYTPlaylistChange
                                                    }
                                                >
                                                    {YTPlaylist.map(list => (
                                                        <Radio
                                                            style={radioStyle}
                                                            key={
                                                                list.queueing_yt_playlist_id
                                                            }
                                                            value={
                                                                list.queueing_yt_playlist_id
                                                            }
                                                        >
                                                            {list.name}{" "}
                                                            <a
                                                                href={list.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View in Youtube
                                                            </a>
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            ) : (
                                                <div>No Youtube playlist</div>
                                            )}
                                        </Card>
                                    ) : (
                                        <Card
                                            title="Your Uploaded Videos"
                                            extra={
                                                <Dropdown
                                                    overlay={UploadedVideoMenu}
                                                    trigger={["click"]}
                                                >
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={e =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Options{" "}
                                                        <Icon type="down" />
                                                    </Button>
                                                </Dropdown>
                                            }
                                        >
                                            <div>
                                                <strong>Select Video</strong>
                                            </div>
                                            {uploadedVids.length !== 0 ? (
                                                <Radio.Group
                                                    value={selectedUploadedVid}
                                                    onChange={
                                                        handleUploadedVidChange
                                                    }
                                                >
                                                    {uploadedVids.map(vid => (
                                                        <Radio
                                                            style={radioStyle}
                                                            key={
                                                                vid.queueing_uploaded_vid_id
                                                            }
                                                            value={
                                                                vid.queueing_uploaded_vid_id
                                                            }
                                                        >
                                                            {vid.name} (
                                                            {vid.video_size /
                                                                1024 >
                                                            1024
                                                                ? `${(
                                                                      vid.video_size /
                                                                      1024 /
                                                                      1024
                                                                  ).toFixed(
                                                                      1
                                                                  )} MB`
                                                                : `${(
                                                                      vid.video_size /
                                                                      1024
                                                                  ).toFixed(
                                                                      1
                                                                  )} KB`}
                                                            )
                                                        </Radio>
                                                    ))}
                                                </Radio.Group>
                                            ) : (
                                                <div>No Uploaded video</div>
                                            )}
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    ) : (
                        <Card
                            title="Photo"
                            extra={
                                <Dropdown
                                    overlay={UploadedPhotoMenu}
                                    trigger={["click"]}
                                >
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={e => e.preventDefault()}
                                    >
                                        Options <Icon type="down" />
                                    </Button>
                                </Dropdown>
                            }
                        >
                            <div>
                                <strong>Select Photo(s)</strong>
                            </div>
                            {uploadedPhotos.length !== 0 ? (
                                <Checkbox.Group
                                    style={{ width: "100%" }}
                                    value={selectedPhotos}
                                    onChange={handlePhotoChange}
                                >
                                    {uploadedPhotos.map(uploadedPhoto => (
                                        <div
                                            key={
                                                uploadedPhoto.queueing_uploaded_photo_id
                                            }
                                        >
                                            <Checkbox
                                                value={
                                                    uploadedPhoto.queueing_uploaded_photo_id
                                                }
                                            >
                                                {uploadedPhoto.name} (
                                                {uploadedPhoto.img_size / 1024 >
                                                1024
                                                    ? `${(
                                                          uploadedPhoto.img_size /
                                                          1024 /
                                                          1024
                                                      ).toFixed(1)} MB`
                                                    : `${(
                                                          uploadedPhoto.img_size /
                                                          1024
                                                      ).toFixed(1)} KB`}
                                                ){" "}
                                                <a
                                                    href={uploadedPhoto.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View Photo
                                                </a>
                                            </Checkbox>
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            ) : (
                                <div>No Uploaded photo</div>
                            )}
                        </Card>
                    )}
                </Col>
            </Row>
            <Divider />
            <Button
                type="primary"
                size="large"
                loading={isSaving}
                onClick={saveAndApply}
            >
                {isSaving ? "Saving" : "Save and Apply"}
            </Button>

            <ModalDetails
                title="Add Youtube Playlist"
                visible={isYTFormVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleYTFormVisible}
            >
                <AddYTPlaylistForm onSubmit={handleYTPlaylistFormSubmit} />
            </ModalDetails>
            <ModalDetails
                title="Add Video"
                visible={isVideoFormVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleVideoFormVisible}
            >
                <AddVideoForm onSubmit={handleVideoFormSubmit} />
            </ModalDetails>
            <ModalDetails
                title="Add Phpto"
                visible={isPhotoFormVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={togglePhotoFormVisible}
            >
                <AddPhotoForm onSubmit={handlePhotoFormSubmit} />
            </ModalDetails>
        </Card>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Multimedia);
