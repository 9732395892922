import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Divider, Icon, Alert, message, Card } from "antd";

import DynamicForm from "globalComponents/DynamicForm/Index";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientForm = ({
    breakpoint,
    patientForm,
    clinicID,
    updateSettings,
    setRequestError
}) => {
    const [isPEdited, setIsPEdited] = useState(false); //patient form edited?

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isPEdited]);

    function beforeunload(e) {
        if (isPEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleStuctureChange = () => {
        setIsPEdited(true);
    };

    const handlePatientFormSave = async form => {
        try {
            updateSettings({ patientForm: form });
            setIsPEdited(false);
            message.success("Patient form settings successfully saved!");

            const PForm = JSON.stringify(form);

            await http.put(`${apiUrl}/settings/${clinicID}`, {
                patient_form: PForm
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>
            <Divider />
            <Alert
                message="Informational Notes"
                description={
                    <div>
                        <div>
                            - If you are not sure how to build/modify the
                            Patient Form, you can send the photo of your desired
                            Patient Form to us and we will build it for you.
                        </div>
                        <div>
                            - Use the Form Controls at the bottom to modify the
                            Patient Form structure.
                        </div>
                        <div>
                            - Some of the form input is a <strong>FIXED</strong>{" "}
                            form input meaning you cannot delete it and you
                            cannot modifed some of its options.
                        </div>
                        <div>
                            - You can set/write a default value in the form.
                        </div>
                        <div>
                            - If you start using the Patient Form, please{" "}
                            <strong>AVOID</strong> deletion on form.
                            <br />
                            &nbsp;&nbsp;Deleting an input form and adding a new
                            one with the same name will not recognize the old
                            one.
                        </div>
                        <div>- Click save button once done.</div>
                    </div>
                }
                type="info"
                showIcon
            />
            <Divider />
            <div style={{ width: breakpoint.isNormalMobile ? "100%" : 600 }}>
                <DynamicForm
                    mode="builder"
                    form={patientForm}
                    errors={{}}
                    layout={null}
                    breakpoint={breakpoint}
                    onInputChange={null}
                    onStructureChange={handleStuctureChange}
                    onSave={handlePatientFormSave}
                />
            </div>
            <Prompt
                when={isPEdited}
                message="Changes you made on Patient Form may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    patientForm: state.Settings.patientForm,
    clinicID: state.UserDetails.clinicDetails.clinic_id
});

const mapDispatchToProps = dispatch => ({
    updateSettings: updatedSettings =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
