import React from "react";
import { Avatar, Button, Row, Col } from "antd";
//import { systemStoragePath } from "config.json";

const ChatHeader = (props) => {
    const { data, onBackToConversations, onChatClose } = props;

    if (data) {
        const { name, user_id } = data;

        // const avatarProps = img_path
        //     ? {
        //           size: 40,
        //           src: `${systemStoragePath}/pictures/${img_path}`
        //       }
        //     : { size: 40, icon: "user" };
        const avatarProps = { size: 40, icon: "user" };

        return (
            <div
                style={{
                    height: 57,
                    backgroundColor: "#1890ff",
                    color: "#fff",
                }}
            >
                <div
                    style={{
                        float: "left",
                        marginTop: 8,
                    }}
                >
                    <Button
                        type="link"
                        size="large"
                        icon="left"
                        onClick={onBackToConversations}
                        style={{ color: "#fff" }}
                    />
                </div>
                <div
                    style={{
                        float: "left",
                        marginRight: 10,
                        marginTop: 8,
                    }}
                >
                    {user_id === "support" ? (
                        <Avatar
                            style={{ backgroundColor: "#87d068" }}
                            icon="team"
                            size={40}
                        />
                    ) : (
                        <Avatar
                            {...avatarProps}
                            style={{ border: "2px solid #fff" }}
                        />
                    )}
                </div>
                <div
                    style={{
                        float: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        marginTop: 16,
                    }}
                >
                    <div>{name}</div>
                </div>

                <div
                    style={{
                        float: "right",
                        marginTop: 18,
                        marginRight: 15,
                    }}
                >
                    <Button
                        type="link"
                        size="small"
                        icon="close"
                        style={{ color: "#fff" }}
                        onClick={onChatClose}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    padding: 15,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    backgroundColor: "#1890ff",
                    color: "#fff",
                }}
            >
                <Row type="flex" justify="space-between">
                    <Col>Conversations</Col>
                    <Col>
                        <Button
                            type="link"
                            icon="close"
                            size="small"
                            style={{ color: "#fff", fontWeight: "bold" }}
                            onClick={onChatClose}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
};

export default ChatHeader;
