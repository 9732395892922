import React, { useState } from "react";
import { connect } from "react-redux";
import { editUserDetails } from "redux/userDetails/userDetails.actions";
import { setRequestError } from "redux/system/system.actions";
import { Alert, Switch } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const TeleMedSwitcher = ({
    isProfileComplete,
    is_allow_patient_portal,
    userID,
    editUserDetails,
    setRequestError,
}) => {
    const [isEnabled, setIsEnabled] = useState(is_allow_patient_portal);

    const handleSwitchChange = async (checked) => {
        setIsEnabled(checked);

        try {
            editUserDetails({
                is_allow_patient_portal: checked ? 1 : 0,
            });
            await http.put(`${apiUrl}/allowPatientPortal/${userID}`, {
                is_allow_patient_portal: checked ? 1 : 0,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message,
            });
        }
    };

    return isProfileComplete ? (
        <Alert
            message="Tele-Medecine"
            description={
                <div>
                    <p>
                        Patient appointments can now be done online through CWA
                        Patient Portal. Enabling Tele-Medecine means you will be
                        included on the doctors that patient can be choose.
                        Conversation will be done thru video call and payments
                        can be credit/debit cards or epayments like gcash.
                    </p>
                    <p>
                        Enabled:{" "}
                        <Switch
                            checked={isEnabled}
                            onChange={handleSwitchChange}
                        />
                    </p>
                </div>
            }
            type="info"
            showIcon
        />
    ) : (
        <Alert
            description="Please complete your Profile to enabled the Tele-Medecine feature. Tele-Medecine allows the patient to appoint you and consult to you online through CWA Patient Portal."
            type="error"
        />
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    is_allow_patient_portal:
        state.UserDetails.is_allow_patient_portal === 0 ? false : true,
});

const mapDispatchToProps = (dispatch) => ({
    editUserDetails: (editedUserDetails) =>
        dispatch(editUserDetails(editedUserDetails)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeleMedSwitcher);
