import React, { useState, useEffect } from "react";
import RichTextEditor from "globalComponents/RichTextEditor";

const Subjective = ({ caseDetails, readOnly: isCaseReadOnly, onChange }) => {
    const [subjectiveContent, setSubjectiveContent] = useState("");

    useEffect(() => {
        setSubjectiveContent(caseDetails.subjective);
    }, [caseDetails]);

    const handleEditorSave = async value => {
        setSubjectiveContent(value);
        onChange("subjective", value);
    };

    return (
        <RichTextEditor
            value={subjectiveContent}
            readOnly={isCaseReadOnly}
            onSave={handleEditorSave}
        />
    );
};

export default Subjective;
