import React, { useState, useEffect } from "react";
import { Empty, Button, Table, notification } from "antd";
import moment from "moment";

import Drawer from "globalComponents/Drawer";
import TeleSchedBuilder from "./components/TeleSchedBuilder/Index";

const TeleSched = ({ portalSchedule: pS, onChange }) => {
    const [portalSchedule, setPortalSchedule] = useState(null);
    const [isSchedBuilderVisible, setIsSchedBuilderVisible] = useState(false);

    useEffect(() => {
        setPortalSchedule(pS);
    }, [pS]);

    const toggleSchedBuilderVisible = () => {
        setIsSchedBuilderVisible(!isSchedBuilderVisible);
    };

    const openNotificationWithIcon = () => {
        notification.success({
            message: "Schedule Updated",
            description:
                "Please save your profile in order to reflect it on the Patient Portal",
            duration: 7
        });
    };

    const handleSchedChange = schedule => {
        onChange(schedule);
        toggleSchedBuilderVisible();
        openNotificationWithIcon();
    };

    const schedTableColums = [
        {
            title: "Day",
            dataIndex: "label"
        },
        {
            title: "Operation",
            key: "Operation",
            render: (text, record) => {
                return `${moment(record.operation[0], "HH:mm:ss").format(
                    "hh:mm A"
                )} - ${moment(record.operation[1], "HH:mm:ss").format(
                    "hh:mm A"
                )}`;
            }
        },
        {
            title: "Time Slots",
            key: "timeslots",
            render: (text, record) => {
                return record.timeSlots.length;
            }
        }
    ];

    return (
        <>
            {portalSchedule ? (
                <div>
                    <Table
                        columns={schedTableColums}
                        dataSource={portalSchedule.schedules}
                        rowKey="day"
                        pagination={false}
                    />
                </div>
            ) : (
                <Empty
                    description={
                        <span>
                            No Schedule Found.
                            <br />
                            Tele-Medecine Schedule is required to complete your
                            profile.
                        </span>
                    }
                />
            )}

            <div
                style={{ textAlign: "center", marginTop: 10, marginBottom: 50 }}
            >
                <Button type="primary" onClick={toggleSchedBuilderVisible}>
                    {portalSchedule ? "Edit Schedule" : "Create Schedule"}
                </Button>
            </div>

            <Drawer
                title="Schedule Builder"
                visible={isSchedBuilderVisible}
                //footer={null}
                destroyOnClose={true}
                width={500}
                onClose={toggleSchedBuilderVisible}
            >
                <TeleSchedBuilder
                    portalSchedule={portalSchedule}
                    onOk={handleSchedChange}
                />
            </Drawer>
        </>
    );
};

export default TeleSched;
