import {
    SET_HMO_SUCCESS,
    ADD_HMO_SUCCESS,
    EDIT_HMO_SUCCESS,
    DELETE_HMO_SUCCESS
} from "./hmo.types";

const INIT_STATE = [];

const hmoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_HMO_SUCCESS:
            return action.payload.hmo;
        case ADD_HMO_SUCCESS:
            return [...state, action.payload.newHmo];
        case EDIT_HMO_SUCCESS:
            const newHmo = state.map(hmo => {
                if (hmo.hmo_id === action.payload.editedHmo.hmo_id) {
                    return action.payload.editedHmo;
                }
                return hmo;
            });
            return newHmo;
        case DELETE_HMO_SUCCESS:
            return state.filter(hmo => hmo.hmo_id !== action.payload.hmoID);
        default:
            return state;
    }
};

export default hmoReducer;
