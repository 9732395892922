import React, { useState } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Upload, message, Button, Icon, Card, Modal, Divider } from "antd";

import moment from "moment";
import ModalDetails from "globalComponents/ModalDetails";
import PDFViewer from "./components/PDFViewer";

import { findUserName } from "helpers/userNameFinder";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

const PatientAttachments = ({
    patientDetails,
    caseID,
    breakpoint,
    onSelectCase,
    ownerID,
    clinicID,
    userID,
    uploadServer,
    uploadEndpoint,
    setRequestError
}) => {
    const [patientFiles, setPatientFiles] = useState(
        patientDetails.files ? JSON.parse(patientDetails.files) : []
    );
    const [isAttachmentDetailsVisible, setIsAttachmentDetailsVisible] =
        useState(false);

    const [isPDFViewerVisible, setIsPDFViewerVisible] = useState(false);

    const [attachmentDetails, setAttachmentDetails] = useState({
        patientFullname: `${patientDetails.lastname}, ${patientDetails.firstname} ${patientDetails.middlename}`,
        attachedBy: "",
        createdAt: "",
        caseID: "",
        url: "",
        filename: "",
        size: 0
    });

    const { confirm } = Modal;

    const handleAttachmentDelete = cb => {
        confirm({
            title: `Are you sure do you want to remove this file?`,
            content: "this cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            maskClosable: true,
            onOk() {
                cb(true);
            },
            onCancel() {
                cb(false);
            }
        });
    };

    const toggleAttachmentDetailsVisible = () => {
        setIsAttachmentDetailsVisible(!isAttachmentDetailsVisible);
    };

    const togglePDFViewerVisible = () => {
        setIsPDFViewerVisible(!isPDFViewerVisible);
    };

    const handleCaseIDClick = (caseID, fileType) => {
        if (fileType === "pdf") {
            togglePDFViewerVisible();
        } else {
            toggleAttachmentDetailsVisible();
        }

        setTimeout(() => onSelectCase(caseID), 0);
    };

    const uploadProps = {
        name: "uniFile",
        //accept: ".png,.jpg,.jpeg,.pdf",
        action: `${apiUrl}/patientFileUpload?space_server=${uploadServer}&space_bucket_name=${uploadEndpoint}&root_dir=${cwaUploadRootDir}&owner_id=${ownerID}&clinic_id=${clinicID}`,
        headers: {
            authorization: localStorage.getItem("token")
        },
        async onChange(info) {
            let newPatientFiles = [...info.fileList];
            newPatientFiles = newPatientFiles.map(file => {
                if (file.response) {
                    // Component will show file.url as link
                    const datePath = file.response.datePath;
                    const filename = file.response.newFilename;
                    const fileExt = file.name.split(".").slice(-1);
                    const createdAt = file.response.createdAt;
                    file.url = `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/${datePath}/${filename}.${fileExt}`;
                    file.attachedBy = userID;
                    file.createdAt = createdAt;
                    file.caseID = caseID;
                }
                return file;
            });

            if (info.file.status) {
                setPatientFiles(newPatientFiles);
            }

            if (info.file.status === "done") {
                //refine attachments array and save it to database
                const files = [];
                newPatientFiles.forEach(file => {
                    const {
                        uid,
                        name,
                        url,
                        size,
                        status,
                        attachedBy,
                        createdAt,
                        caseID
                    } = file;
                    files.push({
                        uid,
                        name,
                        url,
                        size,
                        status,
                        attachedBy,
                        createdAt,
                        caseID
                    });
                });

                try {
                    await http.post(`${apiUrl}/patientFiles`, {
                        patient_id: patientDetails.patient_id,
                        files: JSON.stringify(files)
                    });
                } catch (error) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message
                    });
                }
            }
        },
        beforeUpload(file) {
            const isValidFile =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "application/pdf";
            if (!isValidFile) {
                message.error("You can only upload JPG/PNG and PDF file!");
            }
            const isValidSize = file.size / 1024 / 1024 < 5;
            if (!isValidSize) {
                message.error("File must smaller than 5MB!");
            }
            return isValidFile && isValidSize;
        },
        onPreview(file) {
            setAttachmentDetails({
                ...attachmentDetails,
                attachedBy: file.attachedBy,
                createdAt: file.createdAt,
                caseID: file.caseID,
                url: file.url,
                filename: file.name,
                size: file.size
            });

            const fileExt =
                file.name.split(".")[file.name.split(".").length - 1];
            if (fileExt === "pdf") {
                togglePDFViewerVisible();
            } else {
                toggleAttachmentDetailsVisible();
            }
        },
        onRemove(file) {
            if (file.attachedBy === userID) {
                return new Promise(async (resolve, reject) => {
                    handleAttachmentDelete(async function (isConfirmed) {
                        if (isConfirmed) {
                            resolve();
                            const newPatientFiles = patientFiles.filter(
                                patientFile => patientFile.uid !== file.uid
                            );

                            try {
                                await http.post(`${apiUrl}/patientFiles`, {
                                    patient_id: patientDetails.patient_id,
                                    files: JSON.stringify(newPatientFiles)
                                });
                            } catch (error) {
                                setRequestError({
                                    errorMessage:
                                        "Something went wrong on your last operation :(",
                                    errorSubMessage: error.message
                                });
                            }
                        }
                    });
                });
            } else {
                message.error("Only the person who attached it can delete it!");
                return false;
            }
        }
    };

    return (
        <div style={{ marginBottom: 16 }}>
            <Card title="Attachments">
                <Upload {...uploadProps} fileList={patientFiles}>
                    <Button>
                        <Icon type="upload" /> Upload File
                    </Button>
                </Upload>
            </Card>

            <ModalDetails
                title="Attachment Details"
                visible={isAttachmentDetailsVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 20 }}
                onCancel={toggleAttachmentDetailsVisible}
            >
                <div>
                    <div>Filename: {attachmentDetails.filename}</div>
                    <div>
                        Size:{" "}
                        {attachmentDetails.size ? (
                            <span>
                                {attachmentDetails.size / 1024 > 1024
                                    ? `${(
                                          attachmentDetails.size /
                                          1024 /
                                          1024
                                      ).toFixed(1)} MB`
                                    : `${(
                                          attachmentDetails.size / 1024
                                      ).toFixed(1)} KB`}
                            </span>
                        ) : (
                            "0 KB"
                        )}
                    </div>
                    <Divider />
                    <div>Patient: {attachmentDetails.patientFullname}</div>
                    <div>
                        Added By: {findUserName(attachmentDetails.attachedBy)}
                    </div>
                    <div>
                        Date Added:{" "}
                        {moment(attachmentDetails.createdAt).format("LLL")}
                    </div>

                    <div>
                        Case ID:{" "}
                        <Button
                            type="link"
                            size="small"
                            onClick={() =>
                                handleCaseIDClick(attachmentDetails.caseID, "")
                            }
                        >
                            {attachmentDetails.caseID}
                        </Button>
                    </div>
                    <div style={{ marginTop: 30 }}>(Preview not available)</div>
                </div>
            </ModalDetails>

            <Modal
                title={"Attachment Details"}
                visible={isPDFViewerVisible}
                footer={null}
                destroyOnClose={true}
                style={{ top: 10 }}
                width={breakpoint.isNormalMobile ? null : "80%"}
                bodyStyle={{
                    padding: 0,
                    backgroundColor: "#ccc",
                    height: "calc(100vh - 100px)",
                    overflow: "auto"
                }}
                onCancel={togglePDFViewerVisible}
            >
                <div style={{ padding: 20, backgroundColor: "#fff" }}>
                    <div>Filename: {attachmentDetails.filename}</div>
                    <div>
                        Size:{" "}
                        {attachmentDetails.size ? (
                            <span>
                                {attachmentDetails.size / 1024 > 1024
                                    ? `${(
                                          attachmentDetails.size /
                                          1024 /
                                          1024
                                      ).toFixed(1)} MB`
                                    : `${(
                                          attachmentDetails.size / 1024
                                      ).toFixed(1)} KB`}
                            </span>
                        ) : (
                            "0 KB"
                        )}
                    </div>
                    <Divider />
                    <div>Patient: {attachmentDetails.patientFullname}</div>
                    <div>
                        Added By: {findUserName(attachmentDetails.attachedBy)}
                    </div>
                    <div>
                        Date Added:{" "}
                        {moment(attachmentDetails.createdAt).format("LLL")}
                    </div>

                    <div>
                        Case ID:{" "}
                        <Button
                            type="link"
                            size="small"
                            onClick={() =>
                                handleCaseIDClick(
                                    attachmentDetails.caseID,
                                    "pdf"
                                )
                            }
                        >
                            {attachmentDetails.caseID}
                        </Button>
                    </div>
                </div>
                <PDFViewer url={attachmentDetails.url} />
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    userID: state.UserDetails.user_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttachments);
