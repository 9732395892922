import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message } from "antd";

const HmoForm = ({ data, onSubmit, clinicID }) => {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        address: "",
        mobile: "",
        phone: "",
        email: "",
        contact_person: "",
        website: "",
        img_path: "",
        status: "active",
        clinic_id: clinicID,
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
    });

    const txtHmoName = React.createRef();

    useEffect(() => {
        if (data) {
            setFormData(data);
        }
        txtHmoName.current.input.focus();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Hmo name is required";
        }

        const errorLength = Object.keys(errors).length;
        if (errorLength > 0) {
            message.error(
                "Failed to submit! Please correct the following error(s) in the form"
            );
        }

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                labelCol={{
                    xl: { span: 8 },
                    lg: { span: 9 },
                }}
                wrapperCol={{
                    xl: { span: 16 },
                    lg: { span: 15 },
                }}
                style={{
                    width: "100%",
                }}
            >
                <Form.Item
                    label="HMO Name"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.name && "error"}
                    help={formErrors.name && formErrors.name}
                >
                    <Input
                        name="name"
                        autoComplete="off"
                        ref={txtHmoName}
                        value={formData.name}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Email">
                    <Input
                        name="email"
                        autoComplete="off"
                        value={formData.email}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Address">
                    <Input
                        name="address"
                        autoComplete="off"
                        value={formData.address}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Mobile">
                    <Input
                        name="mobile"
                        autoComplete="off"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Phone">
                    <Input
                        name="phone"
                        autoComplete="off"
                        value={formData.phone}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Contact Person">
                    <Input
                        name="contact_person"
                        autoComplete="off"
                        value={formData.contact_person}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Website">
                    <Input
                        name="website"
                        autoComplete="off"
                        value={formData.website}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        autoComplete="off"
                        value={formData.description}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </Form.Item>

                <Form.Item label=" ">
                    <Button type="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

const mapStateToProps = (state) => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

export default connect(mapStateToProps)(HmoForm);
