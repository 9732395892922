import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Button, Divider, message, Select, Card } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const General = ({ GSettings, userID, updateSettings, setRequestError }) => {
    const { Option } = Select;

    const [generalSettings, setGeneralSettings] = useState(GSettings);
    const [isGSettingsEdited, setIsGSettingsEdited] = useState(false);

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isGSettingsEdited]);

    function beforeunload(e) {
        if (isGSettingsEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleSelectChange = (name, value) => {
        setGeneralSettings({
            ...generalSettings,
            [name]: value,
        });
        setIsGSettingsEdited(true);
    };

    const handleGeneralSettingsSave = async () => {
        try {
            updateSettings({ ...generalSettings });
            setIsGSettingsEdited(false);
            message.success("General settings successfully saved!");
            await http.put(`${apiUrl}/userSettings/${userID}`, {
                theme: generalSettings.theme,
                age_format: generalSettings.ageFormat,
                default_displayed_case_note:
                    generalSettings.defaultDisplayedCaseNote,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message,
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />

            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 200 }}>Theme:</td>
                        <td>
                            <Select value="default" style={{ width: 250 }}>
                                <Option value="default">Default</Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td>Age Format:</td>
                        <td>
                            <Select
                                value={generalSettings.ageFormat}
                                style={{ width: 250 }}
                                onChange={(value) =>
                                    handleSelectChange("ageFormat", value)
                                }
                            >
                                <Option value="Y">Year Age Only</Option>
                                <Option value="YY">Year</Option>
                                <Option value="YYO">Year Old</Option>
                                <Option value="YSY">Short Year</Option>
                                <Option value="YSYO">Short Year Old</Option>
                                <Option value="YYM">Year Month</Option>
                                <Option value="YYMO">Year Month Old</Option>
                                <Option value="YSYSM">Short Year Month</Option>
                                <Option value="YSYSMO">
                                    Short Year Month Old
                                </Option>
                                <Option value="YYMW">Year Month Week</Option>
                                <Option value="YYMWO">
                                    Year Month Week Old
                                </Option>
                                <Option value="YSYSMSW">
                                    Short Year Month Week
                                </Option>
                                <Option value="YSYSMSWO">
                                    Short Year Month Week Old
                                </Option>
                                <Option value="YYMWD">
                                    Year Month Week Day
                                </Option>
                                <Option value="YYMWDO">
                                    Year Month Week Day Old
                                </Option>
                                <Option value="YSYSMSWSD">
                                    Short Year Month Week Day
                                </Option>
                                <Option value="YSYSMSWSDO">
                                    Short Year Month Week Day Old
                                </Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 150 }}>Default Case Note:</td>
                        <td>
                            <Select
                                value={generalSettings.defaultDisplayedCaseNote}
                                style={{ width: 250 }}
                                onChange={(value) =>
                                    handleSelectChange(
                                        "defaultDisplayedCaseNote",
                                        value
                                    )
                                }
                            >
                                <Option value="soap">SOAP</Option>
                                <Option value="plain">Plain Notes</Option>
                            </Select>
                        </td>
                    </tr>
                </tbody>
            </table>

            <Divider />

            <Button
                type="primary"
                size="large"
                onClick={handleGeneralSettingsSave}
            >
                Save General Settings
            </Button>

            <Prompt
                when={isGSettingsEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    GSettings: {
        theme: state.Settings.theme,
        ageFormat: state.Settings.ageFormat,
        defaultDisplayedCaseNote: state.Settings.defaultDisplayedCaseNote,
    },
    userID: state.UserDetails.user_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
