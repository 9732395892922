import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import {
    Row,
    Col,
    //Button,
    Icon,
    Tooltip,
    message,
    Menu,
    Dropdown,
    Spin,
    Typography,
} from "antd";

import moment from "moment";
import domtoimage from "dom-to-image";
import ReactHtmlParser from "react-html-parser";

import ActionMenu from "globalComponents/ActionMenu";

import { formatAge } from "helpers/ageFormatter";
import { b64ImgToFileHelper } from "helpers/image";
import { printRequests } from "helpers/printPaper";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir, cwappUploadRootDir } from "config.json";

import { socket } from "layouts/Main";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const OtherRequestList = ({
    otherRequests,
    caseDetails,
    patientDetails,
    readOnly: isReadOnly,
    onRemove,
    userID,
    userType,
    ownerID,
    isSignatureUploaded,
    clinicDetails,
    doctors,
    papers,
    ageFormat,
    uploadServer,
    uploadEndpoint,
    setRequestError,
}) => {
    //states below will be used in save to patient portal
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [selectedPaperLayout, setSelectedPaperLayout] = useState(null);
    const [HTMLOutput, setHTMLOutput] = useState("");
    const [isHTMLOutputVisible, setIsHTMLOutputVisible] = useState(false);
    const [saveToPPTrigger, setSaveToPPTrigger] = useState(0);

    const { Title } = Typography;

    useEffect(() => {
        //html to canvas
        if (HTMLOutput !== "") {
            var node = document.getElementById("otherRequestPad");
            domtoimage
                .toJpeg(node, {
                    quality: 0.95,
                    // height: pageCount * 800,
                    width: selectedPaperLayout.props.width,
                })
                .then(async function (dataUrl) {
                    try {
                        //create filename
                        const filename = Date.now() + ".jpeg";

                        //convert b64 img to file
                        const imgOtherRequest = b64ImgToFileHelper(
                            dataUrl,
                            filename
                        );

                        //prepare to upload
                        let fd = new FormData();
                        fd.append("uniFile", imgOtherRequest);

                        const uploadResponse = await http.post(
                            `${apiUrl}/cwappPaperImg`,
                            fd,
                            {
                                params: {
                                    space_server: uploadServer,
                                    space_bucket_name: uploadEndpoint,
                                    root_dir: cwappUploadRootDir,
                                },
                                cancelToken: new CancelToken(function executor(
                                    c
                                ) {
                                    CancelRequest = c;
                                }),
                            }
                        );

                        if (uploadResponse.data.error) {
                            message.error(
                                "Failed to save patient data. Please try again"
                            );
                            setIsHTMLOutputVisible(false);
                        } else {
                            const img_path = `https://${uploadServer}/${uploadEndpoint}/${cwappUploadRootDir}/pictures/${uploadResponse.data.datePath}/${filename}`;

                            await http.post(
                                `${apiUrl}/ppPaper`,
                                {
                                    appointment_id: caseDetails.appointment_id,
                                    paperData: {
                                        paperId: selectedPaper.paper_id,
                                        label: selectedPaper.name,
                                        type: selectedPaper.category,
                                        imgPath: img_path,
                                    },
                                },
                                {
                                    headers: { conn: "pp" },
                                    cancelToken: new CancelToken(
                                        function executor(c) {
                                            CancelRequest = c;
                                        }
                                    ),
                                }
                            );

                            message.success(
                                "Other request successfully saved on Patient Portal As Image"
                            );

                            socket.emit(
                                "reloadPPRecordList",
                                caseDetails.appointment_video_call_room_id
                            );
                            socket.emit(
                                "reloadPPRecordView",
                                caseDetails.appointment_video_call_room_id
                            );
                            setIsHTMLOutputVisible(false);
                        }
                    } catch (error) {
                        if (!error.message.responseCancelled) {
                            setRequestError({
                                errorMessage:
                                    "Something went wrong on your last operation :(",
                                errorSubMessage: error.message,
                            });
                        }
                    }
                });
        }

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, [saveToPPTrigger]);

    const handleOtherRequestPrint = (
        doctorID = null,
        isDirectPrint = true,
        isSaveToPatientPortal = false
    ) => {
        //who click the print button
        // if (!doctorID) {
        //     if (userType === "staff") {
        //         doctorID = doctors[0].doctor_id;
        //     } else {
        //         doctorID = userID;
        //     }
        // }

        //get the corresponding paper and doctor details
        const selectedPaper = papers.find(
            (paper) =>
                paper.category === "Other Request" &&
                paper.doctor_id === doctorID
        );
        const doctorDetails = doctors.find(
            (doctor) => doctor.doctor_id === doctorID
        );

        if (selectedPaper) {
            if (selectedPaper.print_available !== 0) {
                try {
                    const paperLayout = JSON.parse(selectedPaper.layout);
                    const allDetails = {
                        patient: {
                            ...patientDetails,
                            age: formatAge(ageFormat, patientDetails.birthday),
                            fullname: `${patientDetails.lastname}, ${patientDetails.firstname} ${patientDetails.middlename}`,
                            full_address: `${patientDetails.street} ${patientDetails.town_city}, ${patientDetails.province}`,
                        },
                        doctor: {
                            ...doctorDetails,
                            signature: isSignatureUploaded
                                ? `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/images/signatures/${doctorID}-thumb.jpeg`
                                : "",
                        },
                        clinic: clinicDetails,
                        general: {
                            currentDate: moment().format("MMMM DD, YYYY"),
                        },
                    };

                    if (isSaveToPatientPortal) {
                        const HTMLOuput = printRequests(
                            paperLayout,
                            allDetails,
                            isDirectPrint,
                            otherRequests,
                            isSaveToPatientPortal
                        );
                        setSelectedPaper(selectedPaper);
                        setSelectedPaperLayout(paperLayout);
                        setHTMLOutput(HTMLOuput);
                        setIsHTMLOutputVisible(true);
                        setTimeout(
                            () => setSaveToPPTrigger(Math.random()),
                            3000
                        );
                    } else {
                        printRequests(
                            paperLayout,
                            allDetails,
                            isDirectPrint,
                            otherRequests,
                            isSaveToPatientPortal
                        );
                    }
                } catch (error) {
                    console.log(error);
                    message.error(
                        "Unabled to parse paper layout. Please contact CWA Team Support"
                    );
                }
            } else {
                message.info(
                    "Paper not available for print/save. Possible no layout. Please contact CWA Team Support"
                );
            }
        } else {
            message.info(
                `No prescription paper found for doctor ${doctorDetails.doctor_name}. Please add a paper and send your desire design to CWA Support Team`
            );
        }
    };

    const handleOtherRequestRemove = (otherRequest) => {
        onRemove(otherRequest, "otherRequest");
    };

    // const DoctorsMenu = (
    //     <Menu>
    //         {doctors.map((doctor) => (
    //             <Menu.Item
    //                 key={doctor.doctor_id}
    //                 onClick={() => handleOtherRequestPrint(doctor.doctor_id)}
    //             >
    //                 {doctor.doctor_name}
    //             </Menu.Item>
    //         ))}
    //     </Menu>
    // );

    const PaperMenu = (
        <Menu>
            {caseDetails.is_from_cwapp === 1 && (
                <Menu.Item
                    key={1}
                    onClick={() =>
                        handleOtherRequestPrint(
                            caseDetails.doctor_id,
                            false,
                            true
                        )
                    }
                >
                    <Icon type="save" /> Save to Patient Portal
                </Menu.Item>
            )}
            <Menu.Item
                key={2}
                onClick={() =>
                    handleOtherRequestPrint(caseDetails.doctor_id, false)
                }
            >
                <Icon type="edit" /> Edit before Print
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {otherRequests.length !== 0 && (
                <div style={{ marginBottom: 24 }}>
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Title level={4}>Other Requests</Title>
                        </Col>
                        <Col>
                            {!isReadOnly && (
                                <Tooltip title="Print Other Request">
                                    <Dropdown.Button
                                        type="primary"
                                        size="small"
                                        onClick={() =>
                                            handleOtherRequestPrint(
                                                caseDetails.doctor_id
                                            )
                                        }
                                        overlay={PaperMenu}
                                        trigger={["click"]}
                                    >
                                        <Icon type="printer" /> Print
                                    </Dropdown.Button>
                                </Tooltip>
                            )}
                        </Col>
                    </Row>

                    <div style={{ marginTop: 5, marginLeft: 5 }}>
                        {otherRequests.map((otherRequest, index) => (
                            <Row key={index} style={{ marginBottom: 8 }}>
                                <Col span={22}>&ndash; {otherRequest}</Col>
                                <Col span={2}>
                                    {!isReadOnly && (
                                        <div style={{ textAlign: "right" }}>
                                            <ActionMenu
                                                menu={[
                                                    {
                                                        label: "Remove",
                                                        icon: "delete",
                                                        onClick: () =>
                                                            handleOtherRequestRemove(
                                                                otherRequest
                                                            ),
                                                    },
                                                ]}
                                                size="small"
                                                layout="compress"
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>

                    <Spin
                        spinning={isHTMLOutputVisible}
                        tip="Saving Other Requests on Patient Portal ..."
                    >
                        <div
                            id="otherRequestPad"
                            className="printPaperContainer"
                            style={{
                                display: isHTMLOutputVisible ? "block" : "none",
                            }}
                        >
                            {ReactHtmlParser(HTMLOutput)}
                        </div>
                    </Spin>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    userType: state.UserDetails.type,
    clinicDetails: state.UserDetails.clinicDetails,
    ownerID: state.UserDetails.owner_id,
    isSignatureUploaded: state.UserDetails.is_signature_uploaded,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
    papers: state.Papers,
    ageFormat: state.Settings.ageFormat,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherRequestList);
