import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import AgoraRTC from "agora-rtc-sdk";

import StreamHeader from "./components/StreamHeader";
import StreamDetails from "./components/StreamDetails";

import { agoraAppId } from "config.json";

import { socket } from "layouts/Main";

const USER_ID = Math.floor(Math.random() * 1000000001);

const Streamer = ({
    channelName,
    videoCallDetails,
    streamWindowState,
    onStreamWindowStateChange,
    onLeave
}) => {
    const [localStream] = useState(
        AgoraRTC.createStream({
            streamID: USER_ID,
            audio: true,
            video: true,
            screen: false,
            mirror: true
        })
    );
    const [client] = useState(
        AgoraRTC.createClient({
            mode: "live",
            codec: "h264"
        })
    );

    const [remoteStreams, setRemoteStreams] = useState([]);
    const [subscribedRemoteStreams, setSubscribedRemoteStreams] = useState({});
    const [isTimerStop, setIsTimerStop] = useState(true);
    const [isPatientDisconnected, setIsPatientDisconnect] = useState(false);

    const [countDownSeconds, setCountDownSeconds] = useState(20);
    const [connectingIntervalID, setconnectingIntervalID] = useState(null);

    const [isLocalStreamVisible, setIsLocalStreamVisible] = useState(true);

    const { caseDetails, initStreamTime, maxStreamTime, isAllowExcessTime } =
        videoCallDetails;

    useEffect(() => {
        let t = null;
        t = setInterval(() => {
            setCountDownSeconds(countDownSeconds => countDownSeconds - 1);
        }, 1000);
        setconnectingIntervalID(t);
        return () => {
            clearInterval(t);
        };
    }, []);

    useEffect(() => {
        if (countDownSeconds === 0) {
            clearInterval(connectingIntervalID);
            handleLeave(initStreamTime, "leaved", isAllowExcessTime);
            socket.emit("forcePatientStreamClose", {
                video_call_room_id: caseDetails.appointment_video_call_room_id
            });
        }
    }, [countDownSeconds]);

    useEffect(() => {
        //client stream
        client.init(
            agoraAppId,
            function () {
                console.log("AgoraRTC client initialized");
                client.join(
                    null,
                    channelName,
                    USER_ID,
                    function (uid) {
                        console.log("--------------------------------------");
                        console.log(
                            "User " + uid + " join channel successfully"
                        );
                        console.log("-------------------------------------");

                        //self or local stream
                        localStream.init(
                            function () {
                                console.log("getUserMedia successfully");
                                localStream.play("agora_local");

                                client.publish(localStream, function (err) {
                                    console.log(
                                        "Publish local stream error: " + err
                                    );
                                });

                                client.on("stream-published", function (evt) {
                                    console.log(
                                        "Publish local stream successfully"
                                    );
                                });

                                socket.emit("doctorJoinedChannel", {
                                    video_call_room_id:
                                        caseDetails.appointment_video_call_room_id
                                });
                            },
                            function (err) {
                                console.log("getUserMedia failed", err);
                                socket.emit("forcePatientStreamClose", {
                                    video_call_room_id:
                                        caseDetails.appointment_video_call_room_id
                                });
                            }
                        );
                    },
                    function (err) {
                        console.log("Join channel failed", err);
                    }
                );

                client.on("stream-added", onStreamAdded);
                client.on("stream-subscribed", onRemoteClientAdded);
                client.on("stream-removed", onStreamRemoved);
                client.on("peer-leave", onPeerLeave);
                client.on("errror", err => console.warn(err));
            },
            function (err) {
                console.log("AgoraRTC client init failed", err);
            }
        );
    }, []);

    useEffect(() => {
        let subscribedStreamID = null;
        remoteStreams.forEach(remoteStream => {
            if (!subscribedRemoteStreams[remoteStream.id]) {
                subscribedStreamID = remoteStream.id;
                client.subscribe(remoteStream.stream, function (err) {
                    console.log("Subscribe stream failed", err);
                });
            }
        });

        if (subscribedStreamID) {
            setSubscribedRemoteStreams({
                ...subscribedRemoteStreams,
                [subscribedStreamID]: true
            });
        }
        console.log("----------------------------------------------------");
        console.log("REMOTE STREAM USE EFFECT");
        console.log("remoteStreams", remoteStreams);
        console.log("streamAdded", subscribedStreamID);
        console.log("----------------------------------------------------");

        client.on("stream-subscribed", onRemoteClientAdded);
    }, [remoteStreams]);

    function onStreamAdded(evt) {
        console.log("----------------------------------------------------");
        console.log("NEW STREAM ADDED");
        console.log("----------------------------------------------------");
        let stream = evt.stream;
        const streamID = stream.getId();
        console.log("----------------------------------------------------");
        console.log("NEW STREAM ADDED: " + streamID);
        console.log("----------------------------------------------------");
        setRemoteStreams([...remoteStreams, { id: streamID, stream }]);
        setSubscribedRemoteStreams({
            ...subscribedRemoteStreams,
            [streamID]: false
        });
    }

    function onRemoteClientAdded(evt) {
        let stream = evt.stream;
        //console.log("----------------------------------------------------")
        //console.log("OnRemoteClientAdded")
        //console.log("remoteStreams", remoteStreams);
        let remoteStreamID = stream.getId();
        //console.log("stream.getId()", remoteStreamID);
        //console.log("remoteStreams", remoteStreams);
        let remoteStream = remoteStreams.find(rS => rS.id === remoteStreamID);
        //console.log("remoteStreamPlay", remoteStream)
        if (remoteStream) {
            clearInterval(connectingIntervalID);
            setIsTimerStop(false);
            remoteStream.stream.play(`agora_remote_${remoteStreamID}`);
        }
        //console.log("----------------------------------------------------")
    }

    function onStreamRemoved(evt) {
        let stream = evt.stream;
        if (stream) {
            let remoteStreamID = stream.getId();
            stream.stop();

            console.log("----------------------------------------------------");
            console.log("onStreamRemoved");
            console.log("remoteStreams", remoteStreams);
            const newRemoteStreams = remoteStreams.filter(
                remoteStream => remoteStream.id !== remoteStreamID
            );
            setRemoteStreams(newRemoteStreams);
            console.log("Remote stream is removed " + remoteStreamID);
            console.log("newRemoteStreams " + newRemoteStreams);
            console.log("----------------------------------------------------");
            setIsPatientDisconnect(true);
        }
    }

    function onPeerLeave(evt) {
        let stream = evt.stream;
        if (stream) {
            let remoteStreamID = stream.getId();
            stream.stop();

            console.log("----------------------------------------------------");
            console.log("onPeerLeave");
            console.log("remoteStreams", remoteStreams);
            const newRemoteStreams = remoteStreams.filter(
                remoteStream => remoteStream.id !== remoteStreamID
            );
            setRemoteStreams(newRemoteStreams);
            console.log("Remote stream is removed " + remoteStreamID);
            console.log("newRemoteStreams " + newRemoteStreams);
            console.log("----------------------------------------------------");
            setIsPatientDisconnect(true);
            //handleLeave(null, "disconnect", null);
        }
    }

    function handleLeave(duration, action, isAllowExcessTime) {
        if (client) {
            client.unpublish(localStream, function (err) {
                console.log("Publish local stream error: " + err);
            });

            if (localStream) {
                localStream.close();
            }

            client.leave(
                function () {
                    console.log("Client succeed to leave.");
                    onStreamWindowStateChange("normal");
                    onLeave(duration, action, isAllowExcessTime);
                    socket.emit("forcePatientStreamClose", {
                        video_call_room_id:
                            caseDetails.appointment_video_call_room_id
                    });
                },
                function () {
                    console.log("Client failed to leave.");
                }
            );
        }
    }

    const toggleLocalStreamVisible = () => {
        setIsLocalStreamVisible(!isLocalStreamVisible);
    };

    const handlePatientDisconnect = (duration, action, isAllowExcessTime) => {
        handleLeave(duration, action, isAllowExcessTime);
    };

    return (
        <div>
            {/* stream header */}
            <StreamHeader
                videoCallDetails={videoCallDetails}
                streamWindowState={streamWindowState}
                onStreamWindowStateChange={onStreamWindowStateChange}
            />

            {/* streamer video */}
            <div
                style={{
                    position: "relative",
                    backgroundColor: "#000",
                    height:
                        streamWindowState === "maximized"
                            ? "calc(100vh - 106px)"
                            : streamWindowState === "fullscreen"
                            ? "calc(100vh - 107px)"
                            : 400,
                    maxWidth: 900,
                    margin: "auto"
                }}
            >
                {/* local stream */}
                <div
                    id="agora_local"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        height: 80,
                        width: 120,
                        zIndex: 10,
                        backgroundColor: "#000",
                        display: isLocalStreamVisible ? "block" : "none"
                    }}
                ></div>

                {/* client stream  */}
                {remoteStreams.length !== 0 ? (
                    <div
                        key={remoteStreams[0].id}
                        id={`agora_remote_${remoteStreams[0].id}`}
                        style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#000"
                            //transform: "rotateY(180deg)",
                        }}
                    ></div>
                ) : (
                    <div
                        style={{
                            color: "#fff",
                            textAlign: "center",
                            padding: 10,
                            paddingTop: 20
                        }}
                    >
                        <p>
                            Connecting ... <Icon type="loading" />
                        </p>
                        {isTimerStop && (
                            <p>
                                This will automatically close in{" "}
                                {countDownSeconds} seconds if you don&rsquo;t
                                allow your chrome access the camera or
                                localstream failed to load or patient failure to
                                connect.
                            </p>
                        )}
                    </div>
                )}
            </div>

            {/* stream details */}
            <StreamDetails
                caseDetails={caseDetails}
                initStreamTime={initStreamTime}
                maxStreamTime={maxStreamTime}
                isAllowExcessTime={isAllowExcessTime}
                timerStop={isTimerStop}
                onLocalStreamToggleVisible={toggleLocalStreamVisible}
                patientDisconnected={isPatientDisconnected}
                onPatientDisconnect={handlePatientDisconnect}
                onLeave={handleLeave}
            />
        </div>

        // <div
        //     style={{
        //         width: isFullScreen ? "100%" : 400,
        //         height: isFullScreen ? "100%" : 400,
        //         //backgroundColor: "#000",
        //         position: "relative",
        //     }}
        // >
        //     {/* stream header */}
        //     {/* <StreamHeader
        //         caseDetails={caseDetails}
        //         initStreamTime={initStreamTime}
        //         maxStreamTime={maxStreamTime}
        //         timerStop={isTimerStop}
        //         patientDisconnected={isPatientDisconnected}
        //         isAllowExcessTime={isAllowExcessTime}
        //         onLeave={handleLeave}
        //         onPatientDisconnect={handlePatientDisconnect}
        //     /> */}

        //     {/* stream */}
        //     <div
        //         style={{
        //             position: "relative",
        //             backgroundColor: "#000",
        //             height: "100%",
        //             width: "100%",
        //             marginTop: -1,
        //         }}
        //     >
        //         {/* local stream */}
        //         <div
        //             id="agora_local"
        //             style={{
        //                 position: "absolute",
        //                 bottom: isFullScreen ? 53 : 0,
        //                 right: 0,
        //                 height: 150,
        //                 width: 200,
        //                 zIndex: 10,
        //                 backgroundColor: "#000",
        //             }}
        //         ></div>

        //         {/* client stream  */}
        //         {remoteStreams.length !== 0 ? (
        //             <div
        //                 key={remoteStreams[0].id}
        //                 id={`agora_remote_${remoteStreams[0].id}`}
        //                 style={{
        //                     width: "100%",
        //                     height: "100%",
        //                     backgroundColor: "#000",
        //                     //transform: "rotateY(180deg)",
        //                 }}
        //             ></div>
        //         ) : (
        //             <div
        //                 style={{
        //                     color: "#fff",
        //                     textAlign: "center",
        //                     padding: 10,
        //                     paddingTop: 20,
        //                 }}
        //             >
        //                 <p>
        //                     Connecting ... <Icon type="loading" />
        //                 </p>
        //                 {isTimerStop && (
        //                     <p>
        //                         This will automatically close in{" "}
        //                         {countDownSeconds} seconds if you don&rsquo;t
        //                         allow your chrome access the camera or
        //                         localstream failed to load or patient failure to
        //                         connect.
        //                     </p>
        //                 )}
        //             </div>
        //         )}
        //     </div>
        // </div>
    );
};

export default Streamer;
