import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import { Icon, Divider, Tooltip, message, Modal, Card } from "antd";

import Drawer from "globalComponents/Drawer";
import PrescriptionWritingTable from "./components/PrescriptionWritingTable";
import PrescriptionWritingForm from "./components/Forms/PrescriptionWritingForm";
import PrescriptionWriter from "globalComponents/PrescriptionWriter";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PrescriptionWriting = ({
    breakpoint,
    PWriting,
    userID,
    updateSettings,
    setRequestError
}) => {
    const [prescriptionWriting, setPrescriptionWriting] = useState(PWriting);
    const [selectedPrescriptionWriting, setSelectedPrescriptionWriting] =
        useState(null);
    const [prescriptionWritingFormMode, setPrescriptionWritingFormMode] =
        useState("add");

    const [
        isPrescriptionWritingFormVisible,
        setIsPrescriptionWritingFormVisible
    ] = useState(false);

    const [isPWritingEdited, setIsPWritingEdited] = useState(false);

    const { confirm } = Modal;

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isPWritingEdited]);

    function beforeunload(e) {
        if (isPWritingEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const togglePrescriptionWritingFormVisible = () => {
        setIsPrescriptionWritingFormVisible(!isPrescriptionWritingFormVisible);
    };

    const showPrescriptionWritingForm = action => {
        if (action === "add") {
            setPrescriptionWritingFormMode("add");
            togglePrescriptionWritingFormVisible();
        } else {
            if (selectedPrescriptionWriting) {
                setPrescriptionWritingFormMode("edit");
                togglePrescriptionWritingFormVisible();
            } else {
                message.error("Please select prescription writing");
            }
        }
    };

    const handleTableRowSelect = prescriptionWritingName => {
        setSelectedPrescriptionWriting(
            prescriptionWriting.find(
                writing => writing.name === prescriptionWritingName
            )
        );
    };

    const handlePrescriptionWritingForm = formData => {
        if (prescriptionWritingFormMode === "add") {
            const isWritingAdded = prescriptionWriting.find(
                writing =>
                    writing.name === formData.name &&
                    formData.name !== "newline"
            );
            if (isWritingAdded) {
                message.error("Prescription writing was already added!");
            } else {
                const newPrescriptionWriting = [
                    ...prescriptionWriting,
                    formData
                ];
                setPrescriptionWriting(newPrescriptionWriting);

                updateSettings({ prescriptionWriting: newPrescriptionWriting });
                togglePrescriptionWritingFormVisible();
                setIsPWritingEdited(true);
            }
        } else {
            const newPrescriptionWriting = prescriptionWriting.map(writing => {
                if (writing.name === formData.name) {
                    return {
                        ...writing,
                        ...formData
                    };
                }
                return writing;
            });
            setPrescriptionWriting(newPrescriptionWriting);
            setSelectedPrescriptionWriting({
                ...selectedPrescriptionWriting,
                ...formData
            });
            updateSettings({ prescriptionWriting: newPrescriptionWriting });
            togglePrescriptionWritingFormVisible();
            setIsPWritingEdited(true);
        }
    };

    const handlePrescriptionWritingDelete = () => {
        if (selectedPrescriptionWriting) {
            confirm({
                title: `Are you sure do you want to delete the selected prescription writing?`,
                content: "This cannot be undone",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    const newPrescriptionWriting = prescriptionWriting.filter(
                        writing =>
                            writing.name !== selectedPrescriptionWriting.name
                    );
                    setPrescriptionWriting(newPrescriptionWriting);
                    updateSettings({
                        prescriptionWriting: newPrescriptionWriting
                    });
                    setSelectedPrescriptionWriting(null);
                    setIsPWritingEdited(true);
                }
            });
        } else {
            message.error("Please select prescription writing");
        }
    };

    const movePrescriptionWriting = direction => {
        if (selectedPrescriptionWriting) {
            const newPrescriptionWriting = [...prescriptionWriting];

            let formIndex = 0;
            newPrescriptionWriting.forEach((formInput, index) => {
                if (formInput.name === selectedPrescriptionWriting.name) {
                    formIndex = index;
                }
            });

            if (direction === "up" && formIndex !== 0) {
                const removedItem = newPrescriptionWriting.splice(
                    formIndex,
                    1
                )[0];
                newPrescriptionWriting.splice(formIndex - 1, 0, removedItem);

                //update
                setPrescriptionWriting(newPrescriptionWriting);
                updateSettings({ prescriptionWriting: newPrescriptionWriting });
                setIsPWritingEdited(true);
            } else if (
                direction === "down" &&
                formIndex !== newPrescriptionWriting.length - 1
            ) {
                const removedItem = newPrescriptionWriting.splice(
                    formIndex,
                    1
                )[0];
                newPrescriptionWriting.splice(formIndex + 1, 0, removedItem);

                //update
                setPrescriptionWriting(newPrescriptionWriting);
                updateSettings({ prescriptionWriting: newPrescriptionWriting });
                setIsPWritingEdited(true);
            }
        } else {
            message.error("Please select prescription writing");
        }
    };

    const handlePrescriptionWritingSave = async () => {
        try {
            updateSettings({ prescriptionWriting });
            setIsPWritingEdited(false);
            message.success(
                "Prescription writing settings successfully saved!"
            );
            const PWriting = JSON.stringify(prescriptionWriting);
            await http.put(`${apiUrl}/userSettings/${userID}`, {
                prescription_writing: PWriting
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>
            <Divider />
            <div>
                <p>
                    <strong>Prescription Writing Preview:</strong>
                </p>
                <PrescriptionWriter mode="preview" />
            </div>

            <Divider />
            <Card
                actions={[
                    <Tooltip title="Add Item">
                        <Icon
                            type="plus"
                            onClick={() => showPrescriptionWritingForm("add")}
                        />
                    </Tooltip>,
                    <Tooltip title="Edit Item">
                        <Icon
                            type="edit"
                            onClick={() => showPrescriptionWritingForm("edit")}
                        />
                    </Tooltip>,
                    <Tooltip title="Delete Item">
                        <Icon
                            type="delete"
                            onClick={handlePrescriptionWritingDelete}
                        />
                    </Tooltip>,
                    <Tooltip title="Move Up">
                        <Icon
                            type="up"
                            onClick={() => movePrescriptionWriting("up")}
                        />
                    </Tooltip>,
                    <Tooltip title="Move Down">
                        <Icon
                            type="down"
                            onClick={() => movePrescriptionWriting("down")}
                        />
                    </Tooltip>,
                    <Tooltip title="Save Vital Signs">
                        <Icon
                            type="save"
                            onClick={handlePrescriptionWritingSave}
                        />
                    </Tooltip>
                ]}
                bodyStyle={{
                    maxHeight: 422,
                    overflow: "auto"
                }}
            >
                <PrescriptionWritingTable
                    writings={prescriptionWriting}
                    onRowSelect={handleTableRowSelect}
                />
            </Card>

            <Drawer
                title={
                    prescriptionWritingFormMode === "edit"
                        ? "Edit Writing"
                        : "Create Writing"
                }
                placement="right"
                visible={isPrescriptionWritingFormVisible}
                destroyOnClose={true}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={togglePrescriptionWritingFormVisible}
            >
                <PrescriptionWritingForm
                    mode={prescriptionWritingFormMode}
                    data={selectedPrescriptionWriting}
                    onSubmit={handlePrescriptionWritingForm}
                />
            </Drawer>

            <Prompt
                when={isPWritingEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = state => ({
    PWriting: state.Settings.prescriptionWriting,
    userID: state.UserDetails.user_id
});

const mapDispatchToProps = dispatch => ({
    updateSettings: updatedSettings =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrescriptionWriting);
