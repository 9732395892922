import React, { useState, useEffect } from "react";
import RichTextEditor from "globalComponents/RichTextEditor";

const Plan = ({ caseDetails, readOnly: isCaseReadOnly, onChange }) => {
    const [planContent, setPlanContent] = useState("");

    useEffect(() => {
        setPlanContent(caseDetails.plan);
    }, [caseDetails]);

    const handleEditorSave = async value => {
        setPlanContent(value);
        onChange("plan", value);
    };

    return (
        <RichTextEditor
            value={planContent}
            readOnly={isCaseReadOnly}
            onSave={handleEditorSave}
        />
    );
};

export default Plan;
