import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RootReducer from "./redux";

const middlewares = [thunk];

const store = createStore(
    RootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
