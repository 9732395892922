import React from "react";
import { Row, Col, Divider, Avatar } from "antd";
import PrescriptionNoteForm from "./Forms/PrescriptionNoteForm";

const ItemDetails = ({ selectedItem, clinicID, ownerID, plan }) => {
    const {
        name,
        generic_name,
        img_path,
        description,
        formulation,
        stock_qty,
        reorder_qty,
        price,
        item_unit_name,
        item_group_name,
    } = selectedItem;

    return (
        <Row gutter={24}>
            <Col xl={12} lg={12} md={12}>
                <div
                    style={{
                        marginBottom: 15,
                        textAlign: "center",
                    }}
                >
                    {img_path ? (
                        <Avatar
                            size={100}
                            src={img_path.replace(".jpeg", "-card.jpeg")}
                        />
                    ) : (
                        <Avatar size={100} icon="medicine-box" />
                    )}
                </div>
                <div style={{ textAlign: "center" }}>
                    <h3>{name}</h3>
                    <h4>{generic_name || "No generic name defined"}</h4>
                </div>
                <Divider />
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: 150 }}>Description:</td>
                            <td>{description || "-"}</td>
                        </tr>
                        <tr>
                            <td>Formulation:</td>
                            <td>{formulation || "-"}</td>
                        </tr>
                        <tr>
                            <td>Item Unit:</td>
                            <td>{item_unit_name || "-"}</td>
                        </tr>
                        {plan !== "basic" && (
                            <tr>
                                <td>Stock Quantity:</td>
                                <td>{stock_qty}</td>
                            </tr>
                        )}
                        {plan !== "basic" && (
                            <tr>
                                <td>Reorder Quantity:</td>
                                <td>{reorder_qty}</td>
                            </tr>
                        )}
                        {plan !== "basic" && (
                            <tr>
                                <td>Price:</td>
                                <td>{price}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Item Group:</td>
                            <td>{item_group_name || "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </Col>
            <Col xl={12} lg={12} md={12}>
                <br />
                <h3>Prescription Note:</h3>
                <PrescriptionNoteForm item={selectedItem} readOnly />
            </Col>
        </Row>
    );
};

export default ItemDetails;
