import React, { useState } from "react";
import { Select, Button } from "antd";

const PatientCardItemsForm = ({ items, onSubmit }) => {
    const [selectedItemValue, setSelectedItemValue] = useState(null);

    const { Option } = Select;

    //add item age, groups and hmo
    items = [
        {
            type: "input",
            name: "age",
            label: "Age"
        },
        ...items,
        {
            type: "array",
            name: "groups",
            label: "Groups",
            fixed: true
        },
        {
            type: "array",
            name: "hmo",
            label: "HMO",
            fixed: true
        },
        {
            type: "divider",
            name: "divider-1",
            label: "--vertical-space-1--"
        },
        {
            type: "divider",
            name: "divider-2",
            label: "--vertical-space-2--"
        },
        {
            type: "divider",
            name: "divider-3",
            label: "--vertical-space-3--"
        },
        {
            type: "divider",
            name: "divider-4",
            label: "--vertical-space-4--"
        }
    ];

    const handleSelectChange = value => {
        setSelectedItemValue(value);
    };

    const handleSelectOK = () => {
        const patientCardItem = items.find(
            item => item.name === selectedItemValue
        );

        const { type, name, label, fixed } = patientCardItem;

        switch (name) {
            case "age":
                onSubmit({
                    type: "input",
                    name: "age",
                    label: "Age",
                    target: "custom",
                    highlightColor: ""
                });
                break;
            case "divider":
                onSubmit({
                    type: "divider",
                    name,
                    label: "--vertical-space--"
                });
                break;
            default:
                onSubmit({
                    type,
                    name,
                    label,
                    target: fixed ? "fixed" : "notFixed",
                    highlightColor: ""
                });
        }
    };

    return (
        <div>
            <p>Select Patient Card Item:</p>
            <Select style={{ width: "100%" }} onChange={handleSelectChange}>
                {items
                    .filter(
                        item =>
                            item.name !== "firstname" &&
                            item.name !== "middlename" &&
                            item.name !== "lastname" &&
                            item.type !== "verticalSpace" &&
                            item.type !== "title"
                    )
                    .map(item => (
                        <Option key={item.name} value={item.name}>
                            {item.label}
                        </Option>
                    ))}
            </Select>
            <div style={{ marginTop: 10 }}>
                <Button type="primary" onClick={handleSelectOK}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default PatientCardItemsForm;
