import React from "react";
import PatientSearchBar from "globalComponents/PatientSearchBar";

const SearchPatient = ({ breakpoint, onSelect }) => {
    const handlePatientSelect = (selectedPatient) => {
        if (selectedPatient) {
            onSelect(selectedPatient.patient_id);
        }
    };

    return (
        <div style={{ marginBottom: 16 }}>
            <PatientSearchBar
                onSelect={handlePatientSelect}
                //showAddPatientBtn
                breakpoint={breakpoint}
            />
        </div>
    );
};

export default SearchPatient;
