import React, { memo } from "react";
import { Drawer, Button } from "antd";

const CustomDrawer = ({ children, ...rest }) => {
    const DrawerFooter = () => (
        <div
            style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px"
            }}
        >
            <Button style={{ marginRight: 8 }} onClick={rest.onClose}>
                Close
            </Button>
        </div>
    );

    return (
        <Drawer {...rest}>
            {children}
            <br />
            <br />
            <DrawerFooter />
        </Drawer>
    );
};

export default memo(CustomDrawer);
