import {
    SET_SAVED_CASE_NOTES_SUCCESS,
    ADD_SAVED_CASE_NOTE_SUCCESS,
    DELETE_SAVED_CASE_NOTE_SUCCESS
} from "./savedCaseNotes.types";

export const setSavedCaseNotes = savedCaseNotes => async dispatch => {
    dispatch({
        type: SET_SAVED_CASE_NOTES_SUCCESS,
        payload: { savedCaseNotes }
    });
};

export const addSavedCaseNote = savedCaseNote => async dispatch => {
    dispatch({
        type: ADD_SAVED_CASE_NOTE_SUCCESS,
        payload: { savedCaseNote }
    });
};

export const deleteSavedCaseNote = savedCaseNoteID => async dispatch => {
    dispatch({
        type: DELETE_SAVED_CASE_NOTE_SUCCESS,
        payload: { savedCaseNoteID }
    });
};
