import React from "react";
import { connect } from "react-redux";
import { Menu, Alert, Button, Dropdown, Icon } from "antd";

const AcquireCase = ({ caseDetails, onAcquire, doctors, userID, userType }) => {
    const DoctorsMenu = (
        <Menu>
            {doctors.map((doctor) => (
                <Menu.Item
                    key={doctor.doctor_id}
                    onClick={() => onAcquire(doctor.doctor_id)}
                >
                    {doctor.doctor_name}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Alert
            message="Acquire Case"
            description={
                <>
                    <p>
                        There was no assigned doctor on this case. Click the
                        Acquire button to acquire this case
                    </p>
                    <p>
                        <strong>Cheif Complaint:</strong>{" "}
                        {caseDetails.chief_complaint}
                    </p>
                    {userType === "staff" ? (
                        <Dropdown overlay={DoctorsMenu} trigger={["click"]}>
                            <Button type="primary">
                                Acquire <Icon type="down" />
                            </Button>
                        </Dropdown>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => onAcquire(userID)}
                        >
                            <Icon type="login" /> Acquire
                        </Button>
                    )}
                </>
            }
            type="info"
            showIcon
            style={{ marginBottom: 10 }}
        />
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    userType: state.UserDetails.type,
    doctors: state.UserDetails.doctors.filter(
        (doctor) => doctor.status === "active"
    ),
});

export default connect(mapStateToProps)(AcquireCase);
