import React, { useState, useEffect } from "react";
import { Row, Col, Form, Checkbox } from "antd";

const FormItemCheckbox = ({ data, error, onInputChange }) => {
    const { name, label, value, options, required } = data;

    const [formData, setFormData] = useState({
        [name]: value
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error
    });

    useEffect(() => {
        if (value) {
            setFormData({ [name]: value });
        } else {
            setFormData({ [name]: "" });
        }
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleInputChange = (name, value) => {
        let errors = { ...formErrors };
        if (value.length !== 0) {
            errors[name] = "";
            setFormErrors(errors);
        }

        setFormData({
            ...formData,
            [name]: value.join("X@X")
        });

        onInputChange(name, value.join("X@X"));
    };

    return (
        <Form.Item
            label={label}
            required={required}
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <Checkbox.Group
                style={{ width: "100%" }}
                defaultValue={value.split("X@X")}
                value={formData[name].split("X@X")}
                onChange={value => handleInputChange(name, value)}
            >
                <Row>
                    {options.map(checkbox => {
                        const { label, value } = checkbox;
                        return (
                            <Col span={12} key={value}>
                                <Checkbox value={value}>{label}</Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group>
        </Form.Item>
    );
};

export default FormItemCheckbox;
