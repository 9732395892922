import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button } from "antd";

const DiscountForm = props => {
    const { onSubmit } = props;

    const [formData, setFormData] = useState({
        description: "",
        amount: 0
    });

    const [formErrors, setFormErrors] = useState({
        description: "",
        amount: ""
    });

    const txtDescriptionName = React.createRef();

    useEffect(() => {
        txtDescriptionName.current.input.focus();
    }, []);

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAmountChange = e => {
        if (e !== null) {
            let value = e.toString().replace(/[^0-9.,]/g, "");
            setFormData({
                ...formData,
                amount: Number(value)
            });
        } else {
            setFormData({
                ...formData,
                amount: 0
            });
        }
    };

    const handleBlur = (name, value) => {
        let errors = { ...formErrors };
        if (value.toString().trim() !== "") {
            errors[name] = "";
        }
        setFormErrors(errors);
    };

    const resetFormData = () => {
        setFormData({
            description: "",
            amount: 0
        });

        setFormErrors({
            description: "",
            amount: ""
        });

        txtDescriptionName.current.input.focus();
    };

    const validateInput = () => {
        const { description, amount } = formData;
        let errors = {};

        if (description.trim() === "") {
            errors.description = "Discount description is required";
        }

        if (amount === null) {
            errors.amount = "Amount is required";
        }

        const errorLength = Object.keys(errors).length;

        return errorLength === 0 ? null : errors;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            onSubmit(formData);
            resetFormData();
        }
    };

    return (
        <>
            <Form>
                <Form.Item
                    label="Description"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.description && "error"}
                    help={formErrors.description && formErrors.description}
                >
                    <Input
                        autoComplete="off"
                        ref={txtDescriptionName}
                        value={formData.description}
                        onChange={e =>
                            handleChange("description", e.target.value)
                        }
                        onBlur={e => handleBlur("description", e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label="Amount"
                    required={true}
                    hasFeedback
                    validateStatus={formErrors.amount && "error"}
                    help={formErrors.amount && formErrors.amount}
                >
                    <InputNumber
                        name="amount"
                        autoComplete="off"
                        value={formData.amount}
                        formatter={value =>
                            `${value}`
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                .replace(/[^0-9.,]/g, "")
                        }
                        onChange={value => handleAmountChange(value)}
                        onBlur={e => handleBlur("amount", e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Button
                    type="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: 10 }}
                >
                    Add to List
                </Button>
            </Form>
        </>
    );
};

export default DiscountForm;
