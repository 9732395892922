import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, List } from "antd";

const Settings = ({ userType, userPrivileges, plan }) => {
    let settingsCol1 = [
        {
            title: "General",
            description: "theme, age format, default case notes, etc.",
            path: "/settings/generalSettings",
        },
        {
            title: "Patient Form",
            description: "change the structure of your patient form",
            path: "/settings/patientForm",
            ownerSettings: true,
        },
        {
            title: "Patient History",
            description: "change the structure of your patient history",
            path: "/settings/patientHistoryForm",
            ownerSettings: true,
        },
        {
            title: "Vital Signs",
            description: "select vital signs that you need",
            path: "/settings/vitalSigns",
            ownerSettings: true,
        },
        {
            title: "Patient Card",
            description: "change the appearance of patient card",
            path: "/settings/patientCard",
        },
        {
            title: "Prescription Note",
            description: "change the arrangment of your prescription note",
            path: "/settings/prescriptionNote",
            ownerSettings: true,
        },
        {
            title: "Prescription Writing",
            description: "change the way you write your prescription ",
            path: "/settings/prescriptionWriting",
        },
    ];

    let settingsCol2 = [
        {
            title: "Notifications",
            description: "choose what will show in notification",
            path: "/settings/notifications",
        },
        {
            title: "Doctor's Signature",
            description: "upload your signature for papers",
            path: "/settings/doctorsSignature",
        },

        {
            title: "SOAP Image Manager/Uploader",
            description: "select where image manager/uploader can be visible",
            path: "/settings/soapImgManager",
            ownerSettings: true,
        },
        {
            title: "Case List",
            description: "modify how the case list looks like",
            path: "/settings/caseList",
            ownerSettings: true,
        },
        {
            title: "Appointment List",
            description: "modify how the appointment list looks like",
            path: "/settings/appointmentList",
            ownerSettings: true,
        },
        {
            title: "User Privileges",
            description: "what's your staff can do",
            path: "/settings/userPrivileges",
            ownerSettings: true,
        },
    ];

    if (userType !== "doctor&owner") {
        settingsCol1 = settingsCol1.filter((setting) => !setting.ownerSettings);
        settingsCol2 = settingsCol2.filter((setting) => !setting.ownerSettings);
    }

    if (userType === "staff") {
        settingsCol1 = settingsCol1.filter(
            (setting) => setting.title !== "Prescription Writing"
        );
        settingsCol2 = settingsCol2.filter(
            (setting) => setting.title !== "Doctor's Signature"
        );
    }

    settingsCol2.push({
        title: "Queueing Display",
        description: "manage/modify the display on queueing screen",
        path: "/settings/queueing",
    });

    if (plan === "basic") {
        settingsCol2.splice(settingsCol2.length - 1, 1);
    }

    return userPrivileges.isAccessSettings ? (
        <div style={{ backgroundColor: "#fff", padding: 16 }}>
            <Row gutter={24}>
                <Col lg={12} md={12}>
                    <List
                        itemLayout="horizontal"
                        size="small"
                        dataSource={settingsCol1}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <Link to={item.path}>{item.title}</Link>
                                    }
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
                <Col lg={12} md={12}>
                    <List
                        itemLayout="horizontal"
                        size="small"
                        dataSource={settingsCol2}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={
                                        <Link to={item.path}>{item.title}</Link>
                                    }
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </div>
    ) : (
        <div style={{ textAlign: "center" }}>
            Cannot access this page. Please contact the owner.
        </div>
    );
};

const mapStateToProps = (state) => ({
    userType: state.UserDetails.type,
    userPrivileges: state.Settings.userPrivileges,
    plan: state.UserDetails.subscriptionDetails.plan,
});

export default connect(mapStateToProps)(Settings);
