import React, { useState, useEffect } from "react";
import { Button, Tooltip, Typography, Icon } from "antd";
import ReactQuill from "react-quill";
import ReactHtmlParser from "react-html-parser";

const RichTextEditor = ({ value, onSave, readOnly: isReadOnly }) => {
    const [editorValue, setEditorValue] = useState("");
    const [prevEditorValue, setPrevEditorValue] = useState(null);
    const [isShowEditor, setIsShowEditor] = useState(false);

    const ButtonGroup = Button.Group;
    const { Text } = Typography;

    const modules = {
        toolbar: {
            container: [["bold", "italic", "underline"], [{ list: "bullet" }]]
        }
    };
    const formats = ["bold", "italic", "underline", "list", "bullet", "color"];

    useEffect(() => {
        setEditorValue(value ? value : "");
        setIsShowEditor(false);
    }, [value]);

    const handleEditorContentClick = () => {
        setPrevEditorValue(editorValue);
        setIsShowEditor(!isShowEditor);
    };

    const handleEditorChange = value => {
        setEditorValue(value);
    };

    const handleEditorSave = () => {
        let text = editorValue.replace(/<p>\s+/g, "<p>");
        text = text.replace(/\s+<\/p>/g, "</p>");
        text = text.replace(/<p><\/p>/g, "<p><br></p>");
        text = text.replace(/<\/p><p><br><\/p><p>/g, "</p>@<newline>@<p>");
        text = text.replace(/<p><br><\/p>/g, "");
        text = text.replace(/@<newline>@/g, "<p><br></p>");
        if (text === "") {
            text = "<p><br></p>";
        }

        onSave(text);
        setEditorValue(text);
        setIsShowEditor(!isShowEditor);
    };

    const handleCancelEditing = () => {
        setEditorValue(prevEditorValue);
        setIsShowEditor(!isShowEditor);
    };

    const replaceHTML = HTMLString => {
        //HTMLString = HTMLString.replace(/<p><br><\/p>/g, "");
        HTMLString = HTMLString.replace(/<p>/g, "<div>");
        HTMLString = HTMLString.replace(/<\/p>/g, "</div>");
        HTMLString = HTMLString.replace(/></g, "><@newline@><");

        HTMLString = HTMLString.split("<@newline@>")
            .map(line =>
                line.replace(
                    /<div>(.*?):/g,
                    "<div><span class='editorContentHightLight'>$1:</span>"
                )
            )
            .join("");
        return HTMLString;
    };

    return (
        <>
            {isShowEditor ? (
                <>
                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={editorValue}
                        onChange={handleEditorChange}
                        ref={e => e && e.focus()}
                    />
                    <div>
                        <ButtonGroup style={{ marginTop: 3 }}>
                            <Button type="primary" onClick={handleEditorSave}>
                                Save
                            </Button>
                            <Button onClick={handleCancelEditing}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </div>
                </>
            ) : isReadOnly ? (
                ReactHtmlParser(replaceHTML(editorValue))
            ) : (
                <Tooltip title="Click to Edit" placement="left">
                    <div
                        onClick={handleEditorContentClick}
                        className="editorContent"
                    >
                        {editorValue === "" || editorValue === "<p><br></p>" ? (
                            <Text type="secondary">
                                <Icon type="edit" /> click to edit ...
                            </Text>
                        ) : (
                            ReactHtmlParser(replaceHTML(editorValue))
                        )}
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default RichTextEditor;
