import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, message } from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const PasswordManagement = ({ userDetails }) => {
    const [isPasswordChanging, setIsPasswordChanging] = useState(false);
    const [formData, setFormData] = useState({
        userID: userDetails.user_id,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        userType: userDetails.type,
    });
    const [formErrors, setFormErrors] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { oldPassword, newPassword, confirmPassword } = formData;
        let errors = {};

        if (oldPassword.trim() === "") {
            errors.oldPassword = "Old password is required";
        }

        if (newPassword.trim() === "") {
            errors.newPassword = "New password is required";
        }

        if (confirmPassword.trim() === "") {
            errors.confirmPassword = "Confirm password is required";
        }

        if (newPassword.trim() !== confirmPassword.trim()) {
            errors.confirmPassword = "Password Mistach";
        }

        if (newPassword.length < 6) {
            errors.newPassword = "Password must be atleast 6 characters";
        }

        const errorLength = Object.keys(errors).length;
        // if (errorLength > 0) {
        //     message.error(
        //         "Failed to submit! Please correct the following error(s) in the form"
        //     );
        // }

        return errorLength === 0 ? null : errors;
    };

    const resetForm = () => {
        setFormData({
            ...formData,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
        setFormErrors({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            try {
                setIsPasswordChanging(true);
                const response = await http.post(
                    `${apiUrl}/changePassword`,
                    formData,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                if (response.data.error) {
                    setFormErrors({
                        ...formErrors,
                        oldPassword: response.data.errorMsg,
                    });
                    setIsPasswordChanging(false);
                } else {
                    resetForm();
                    message.success("Password successfully changed!");
                    setIsPasswordChanging(false);
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    message.error("Something went wrong!");
                    resetForm();
                    setIsPasswordChanging(false);
                }
            }
        }
    };

    return (
        <div style={{ marginTop: 15, marginBottom: 15 }}>
            <Card title="Change Password">
                <Form
                    layout="vertical"
                    labelCol={{
                        xl: { span: 6 },
                        lg: { span: 6 },
                    }}
                    wrapperCol={{
                        xl: { span: 18 },
                        lg: { span: 18 },
                    }}
                    style={{
                        width: "100%",
                    }}
                >
                    <Form.Item
                        label="Old Password"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.oldPassword && "error"}
                        help={formErrors.oldPassword && formErrors.oldPassword}
                    >
                        <Input
                            name="oldPassword"
                            type="password"
                            value={formData.oldPassword}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="New Password"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.newPassword && "error"}
                        help={formErrors.newPassword && formErrors.newPassword}
                    >
                        <Input
                            name="newPassword"
                            type="password"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        required={true}
                        hasFeedback
                        validateStatus={formErrors.confirmPassword && "error"}
                        help={
                            formErrors.confirmPassword &&
                            formErrors.confirmPassword
                        }
                    >
                        <Input
                            name="confirmPassword"
                            type="password"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={isPasswordChanging}
                        >
                            {isPasswordChanging
                                ? "Changing Password"
                                : "Change Password"}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default PasswordManagement;
