import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Icon, message } from "antd";

import Lightbox from "react-image-lightbox";
import moment from "moment";
import { findUserName } from "helpers/userNameFinder";

const SketchPictureWall = ({ target, SOAPImgs, onEdit, onDelete, userID }) => {
    const [isPreviewImgVisible, setIsPreviewImgVisible] = useState(false);
    const [selectedSketch, setSelectedSketch] = useState(null);

    const togglePreviewImgVisible = () => {
        setIsPreviewImgVisible(!isPreviewImgVisible);
        //remove scollbar when lightbox is open
        if (!isPreviewImgVisible) {
            document.body.style.position = "relative";
            document.body.style.overflow = "hidden";
            document.body.style.width = "100%";
        } else {
            document.body.removeAttribute("style");
        }
    };

    const handleImgPreview = (selectedRecord) => {
        setSelectedSketch(selectedRecord);
        togglePreviewImgVisible();
    };

    const handleImgEdit = (selectedRecord) => {
        if (selectedRecord.is_from_legacy) {
            message.info("Images from legacy app cannot be edited.");
        } else {
            onEdit(selectedRecord);
        }
    };

    const lightBoxCaption = selectedSketch && (
        <Row type="flex" gutter={24}>
            <Col>Updated By: {findUserName(selectedSketch.user_id)}</Col>
            <Col>
                Size:{" "}
                {selectedSketch.img_size / 1024 > 1024
                    ? `${(selectedSketch.img_size / 1024 / 1024).toFixed(1)} MB`
                    : `${(selectedSketch.img_size / 1024).toFixed(1)} KB`}
            </Col>
            <Col>
                Created At: {moment(selectedSketch.created_at).format("LLL")}
            </Col>
            <Col>
                Last Update: {moment(selectedSketch.updated_at).format("LLL")}
            </Col>
        </Row>
    );

    const PictureWallActions = ({ imageData }) => {
        return (
            <Row type="flex" justify="center" style={{ flexWrap: "nowrap" }}>
                <Col>
                    <Button
                        size="small"
                        title="Preview image"
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#fff",
                        }}
                        onClick={() => handleImgPreview(imageData)}
                    >
                        <Icon type="eye" />
                    </Button>
                </Col>
                <Col>
                    {userID === imageData.user_id && (
                        <Button
                            size="small"
                            title="Edit image"
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#fff",
                            }}
                            onClick={() => handleImgEdit(imageData)}
                        >
                            <Icon type="edit" />
                        </Button>
                    )}
                </Col>
                <Col>
                    {userID === imageData.user_id && (
                        <Button
                            size="small"
                            title="Delete image"
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#fff",
                            }}
                            onClick={() => onDelete(imageData.case_soap_img_id)}
                        >
                            <Icon type="delete" />
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <>
            {SOAPImgs.filter((SOAPImg) => SOAPImg.target === target).map(
                (SOAPImg) => {
                    const thumbPath = SOAPImg.is_from_legacy
                        ? SOAPImg.img_path.replace("sketches", "sketches/thumb")
                        : SOAPImg.img_path.replace(".jpeg", "-thumb.jpeg");

                    return (
                        <div
                            className="image-overlay-container"
                            key={SOAPImg.case_soap_img_id}
                        >
                            <div className="image-overlay-content">
                                <div className="image-overlay-content-overlay"></div>
                                <img
                                    className="image-overlay-content-image"
                                    src={thumbPath}
                                    alt="SOAPImg"
                                />
                                <div className="image-overlay-content-details fadeIn-top">
                                    <PictureWallActions imageData={SOAPImg} />
                                </div>
                            </div>
                        </div>
                    );
                }
            )}

            {isPreviewImgVisible && (
                <Lightbox
                    mainSrc={selectedSketch && selectedSketch.img_path}
                    imageTitle="Sketch Preview"
                    imageCaption={lightBoxCaption}
                    onCloseRequest={togglePreviewImgVisible}
                    clickOutsideToClose={false}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
});

export default connect(mapStateToProps)(SketchPictureWall);
