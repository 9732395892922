import React from "react";
import { connect } from "react-redux";

const PrescriptionWriter = ({
    mode,
    item,
    prescriptionNote,
    doctorId,
    userID,
    doctors,
    prescriptionWriting: pW,
}) => {
    let prescriptionWriting = pW;
    if (doctorId !== userID && mode !== "preview") {
        prescriptionWriting = doctors.find(
            (doctor) => doctor.doctor_id === doctorId
        ).settings.prescription_writing;
    }

    return (
        <span>
            {prescriptionWriting.map((writing) => {
                const {
                    name,
                    value: previewValue,
                    prefix,
                    suffix,
                    style,
                    target,
                } = writing;
                let value = "";

                //build styles
                let newStyle = null;
                switch (style) {
                    case "bold":
                        newStyle = { fontWeight: "bold" };
                        break;
                    case "italic":
                        newStyle = { fontStyle: "italic" };
                        break;
                    case "marginLeft20":
                        newStyle = { marginLeft: 20 };
                        break;
                    case "marginLeft50":
                        newStyle = { marginLeft: 50 };
                        break;
                    case "marginLeft100":
                        newStyle = { marginLeft: 100 };
                        break;
                    case "marginLeft200":
                        newStyle = { marginLeft: 200 };
                        break;
                    default:
                        newStyle = null;
                }

                //get value
                if (mode === "preview") {
                    value = previewValue;
                } else {
                    if (target === "item") {
                        value = item[name] ? item[name] : "";
                    } else {
                        value = prescriptionNote[name]
                            ? prescriptionNote[name]
                            : "";
                    }
                }

                if (name.indexOf("newline") !== -1) {
                    return (
                        <span key={name} style={newStyle}>
                            {prefix}
                            <br />
                            {suffix}
                        </span>
                    );
                } else {
                    return (
                        <span key={name} style={newStyle}>
                            {value.trim() ? `${prefix}${value}${suffix} ` : ""}
                        </span>
                    );
                }
            })}
        </span>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    doctors: state.UserDetails.doctors,
    prescriptionWriting: state.Settings.prescriptionWriting,
});

export default connect(mapStateToProps)(PrescriptionWriter);
