import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Icon } from "antd";
import SidebarMenu from "./SidebarMenu";
import isMobile from "helpers/deviceDetector";
import logo from "assets/img/logo50x50.png";

const Sidebar = ({ activeMenu, breakpoint }) => {
    //must be from settings table
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { Sider } = Layout;

    const handleCollapse = (collapsed) => {
        setIsCollapsed(collapsed);
        //update settings
    };

    return (
        <Sider
            trigger={
                breakpoint.isTabletPortrait ? null : (
                    <Icon type={isCollapsed ? "right" : "left"} />
                )
            }
            collapsible
            collapsedWidth={breakpoint.isTabletPortrait ? 0 : 79}
            collapsed={breakpoint.isTabletPortrait ? true : isCollapsed}
            onCollapse={handleCollapse}
            theme="light"
            style={{
                height: "100vh",
                position: isMobile.iOS() ? "-webkit-sticky" : "sticky",
                top: 0,
                left: 0,
            }}
        >
            {/* SIDEBAR LOGO */}
            <Link to="/dashboard">
                <div style={{ padding: 5 }} title="Dashboard">
                    <Row type="flex" justify="space-between">
                        <Col>
                            <div
                                style={{
                                    paddingTop: 4,
                                    paddingLeft: isCollapsed ? 8 : 13,
                                }}
                            >
                                <img src={logo} alt="logo" />
                            </div>
                        </Col>
                        <Col>
                            {!isCollapsed && (
                                <div
                                    style={{
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        paddingTop: 4,
                                        paddingRight: 30,
                                        color: "#265992",
                                    }}
                                >
                                    Clinic Web
                                    <br />
                                    Assistant
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </Link>
            {/* END SIDEBAR LOGO */}

            <SidebarMenu activeMenu={activeMenu} breakpoint={breakpoint} />
        </Sider>
    );
};

export default Sidebar;
