import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";

import { Row, Col, Card, Typography, Statistic, Avatar, Table } from "antd";
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";
import ModalDetails from "globalComponents/ModalDetails";
import StorageChecker from "./components/StorageChecker";

//import { batchInsertQueryFormatter } from "helpers/batchInsertQueryFormatter";

import http from "services/httpService";
import { apiUrl, version } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Dashboard = ({
    breakpoint,
    clinicID,
    clinicName,
    setRequestError,
    subscription
}) => {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState({
        statistics: {
            totalPatients: 0,
            totalCasesToday: 0,
            totalAppointmentsToday: 0
        },
        lowStockItems: [],
        casesAndAppointmentChartData: [],
        topServicesChartData: []
    });

    const [isUpdatesContentVisible, setUpdatesContentVisible] = useState(false);

    const { Title } = Typography;

    useEffect(() => {
        const dateToday = moment().format("YYYY-MM-DD");
        const fetchData = async () => {
            try {
                setIsContentLoading(true);
                const response = await http.get(
                    `${apiUrl}/dashboardData/${dateToday}/${clinicID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        })
                    }
                );

                // START REMOVE HERE

                // const r = await http.get(`${apiUrl}/dashboard/test`, {
                //     cancelToken: new CancelToken(function executor(c) {
                //         CancelRequest = c;
                //     }),
                // });

                // console.log(r.data.examData);

                // let soap = [];
                // let cases = [];

                // let caseID = null;
                // let currentIndex = 0;
                // r.data.examData.forEach((item, index) => {
                //     const {
                //         ExamID,
                //         Type,
                //         Item,
                //         Cont,
                //         PUID,
                //         DateExam,
                //         LastUpda,
                //     } = item;

                //     let chiefComplaint = "No Chief Complaint";
                //     let subjective = "";
                //     let objective = "";
                //     let assessment = "";
                //     let plan = "";
                //     let plain_notes = "";

                //     let html = "";
                //     if (Item.trim() === "") {
                //         html = `<p>${Cont}</p>`;
                //     } else {
                //         html = `<p>${Item}${
                //             Cont.trim() !== "" ? `: ${Cont}` : ""
                //         }</p>`;
                //     }

                //     switch (Type) {
                //         case "+":
                //             chiefComplaint = Cont;
                //             break;
                //         case "1":
                //             subjective = html;
                //             break;
                //         case "2":
                //             objective = html;
                //             break;
                //         case "3":
                //             assessment = html;
                //             break;
                //         case "4":
                //             plan = html;
                //             break;
                //         default:
                //             console.log(Type);
                //     }

                //     if (caseID !== ExamID) {
                //         caseID = ExamID;
                //         soap.push({
                //             case_id: caseID,
                //             subjective,
                //             objective,
                //             assessment,
                //             plan,
                //             plain_notes,
                //         });
                //         cases.push({
                //             case_id: caseID,
                //             case_name: "",
                //             chief_complaint: chiefComplaint,
                //             complaint_datetime: DateExam,
                //             doctor_id: "d0777947490",
                //             patient_id: PUID,
                //             appointment_id: "",
                //             clinic_id: "c8482531380",
                //             case_folder_id: "",
                //             starred: "no",
                //             status: "done",
                //             user_id: "d0777947490",
                //             is_from_cwapp: 0,
                //             call_duration: "00:00:00",
                //             created_at: DateExam,
                //             updated_at: LastUpda,
                //         });

                //         if (index !== 0) {
                //             currentIndex++;
                //         }
                //     } else {
                //         //console.log(assessment, Cont);
                //         soap[currentIndex] = {
                //             ...soap[currentIndex],
                //             subjective: subjective
                //                 ? soap[currentIndex].subjective + subjective
                //                 : soap[currentIndex].subjective,
                //             objective: objective
                //                 ? soap[currentIndex].objective + objective
                //                 : soap[currentIndex].objective,
                //             assessment: assessment
                //                 ? soap[currentIndex].assessment + assessment
                //                 : soap[currentIndex].assessment,
                //             plan: plan
                //                 ? soap[currentIndex].plan + plan
                //                 : soap[currentIndex].plan,
                //         };
                //     }
                // });

                // console.log("cases", cases);
                // console.log("soap", soap);
                // console.log(batchInsertQueryFormatter(cases));
                // console.log(batchInsertQueryFormatter(soap));

                // for (var i = 0; i < cases.length; i++) {
                //     const arr1 = [cases[i]];
                //     const arr2 = [soap[i]];

                //     await http.post(`${apiUrl}/dashboard/test`, {
                //         cases: batchInsertQueryFormatter(arr1),
                //         soap: batchInsertQueryFormatter(arr2),
                //     });

                //     console.log(i + 1);
                // }

                // END REMOVE HERE

                const {
                    totalPatients,
                    totalCasesToday,
                    totalAppointmentsToday,
                    casesThisYear,
                    appointmentsThisYear,
                    lowStockItems,
                    topServices
                } = response.data;

                //group by date (current year data)
                const monthlyCaseCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                const monthlyAppointmentsCount = [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                ];

                casesThisYear.forEach(patientCase => {
                    switch (
                        moment(patientCase.complaint_datetime).format("MMMM")
                    ) {
                        case "January":
                            monthlyCaseCount[0]++;
                            break;
                        case "February":
                            monthlyCaseCount[1]++;
                            break;
                        case "March":
                            monthlyCaseCount[2]++;
                            break;
                        case "April":
                            monthlyCaseCount[3]++;
                            break;
                        case "May":
                            monthlyCaseCount[4]++;
                            break;
                        case "June":
                            monthlyCaseCount[5]++;
                            break;
                        case "July":
                            monthlyCaseCount[6]++;
                            break;
                        case "August":
                            monthlyCaseCount[7]++;
                            break;
                        case "September":
                            monthlyCaseCount[8]++;
                            break;
                        case "October":
                            monthlyCaseCount[9]++;
                            break;
                        case "November":
                            monthlyCaseCount[10]++;
                            break;
                        case "December":
                            monthlyCaseCount[11]++;
                            break;
                        default:
                            return;
                    }
                });

                appointmentsThisYear.forEach(appointment => {
                    switch (
                        moment(appointment.appointment_datetime).format("MMMM")
                    ) {
                        case "January":
                            monthlyAppointmentsCount[0]++;
                            break;
                        case "February":
                            monthlyAppointmentsCount[1]++;
                            break;
                        case "March":
                            monthlyAppointmentsCount[2]++;
                            break;
                        case "April":
                            monthlyAppointmentsCount[3]++;
                            break;
                        case "May":
                            monthlyAppointmentsCount[4]++;
                            break;
                        case "June":
                            monthlyAppointmentsCount[5]++;
                            break;
                        case "July":
                            monthlyAppointmentsCount[6]++;
                            break;
                        case "August":
                            monthlyAppointmentsCount[7]++;
                            break;
                        case "September":
                            monthlyAppointmentsCount[8]++;
                            break;
                        case "October":
                            monthlyAppointmentsCount[9]++;
                            break;
                        case "November":
                            monthlyAppointmentsCount[10]++;
                            break;
                        case "December":
                            monthlyAppointmentsCount[11]++;
                            break;
                        default:
                            return;
                    }
                });

                const casesAndAppointmentChartData = [
                    {
                        name: "Jan.",
                        cases: monthlyCaseCount[0],
                        appointments: monthlyAppointmentsCount[0]
                    },
                    {
                        name: "Feb.",
                        cases: monthlyCaseCount[1],
                        appointments: monthlyAppointmentsCount[1]
                    },
                    {
                        name: "Mar.",
                        cases: monthlyCaseCount[2],
                        appointments: monthlyAppointmentsCount[2]
                    },
                    {
                        name: "Apr.",
                        cases: monthlyCaseCount[3],
                        appointments: monthlyAppointmentsCount[3]
                    },
                    {
                        name: "May",
                        cases: monthlyCaseCount[4],
                        appointments: monthlyAppointmentsCount[4]
                    },
                    {
                        name: "Jun.",
                        cases: monthlyCaseCount[5],
                        appointments: monthlyAppointmentsCount[5]
                    },
                    {
                        name: "Jul.",
                        cases: monthlyCaseCount[6],
                        appointments: monthlyAppointmentsCount[6]
                    },
                    {
                        name: "Aug.",
                        cases: monthlyCaseCount[7],
                        appointments: monthlyAppointmentsCount[7]
                    },
                    {
                        name: "Sept.",
                        cases: monthlyCaseCount[8],
                        appointments: monthlyAppointmentsCount[8]
                    },
                    {
                        name: "Oct.",
                        cases: monthlyCaseCount[9],
                        appointments: monthlyAppointmentsCount[9]
                    },
                    {
                        name: "Nov.",
                        cases: monthlyCaseCount[10],
                        appointments: monthlyAppointmentsCount[10]
                    },
                    {
                        name: "Dec.",
                        cases: monthlyCaseCount[11],
                        appointments: monthlyAppointmentsCount[11]
                    }
                ];

                const topServicesChartData = topServices.map(service => ({
                    name: service.value,
                    services: service.total_count
                }));

                setDashboardData({
                    ...dashboardData,
                    statistics: {
                        totalPatients,
                        totalCasesToday,
                        totalAppointmentsToday
                    },
                    casesAndAppointmentChartData,
                    lowStockItems,
                    topServicesChartData
                });

                setIsContentLoading(false);
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong while fetching your data :(",
                        errorSubMessage: error.message
                    });
                }
            }
        };

        const delay = setTimeout(
            () => fetchData(),
            breakpoint.isNormalMobile ? 500 : 0
        );

        return () => {
            clearTimeout(delay);
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true
                });
            }
        };
    }, []);

    const toggleUpdatesContentVisible = () => {
        setUpdatesContentVisible(!isUpdatesContentVisible);
    };

    const lowStocksItemColumns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Generic Name",
            dataIndex: "generic_name"
        },
        {
            title: "Stock",
            dataIndex: "stock_qty"
        }
    ];

    const AppCardStatistics = props => {
        const { title, value, icon, iconBgColor } = props;

        let avatarSize = 54;
        let cardMarginTop = 0;
        if (breakpoint.isSmallMobile) {
            avatarSize = 48;
            cardMarginTop = 5;
        } else if (breakpoint.isNormalMobile) {
            avatarSize = 60;
            cardMarginTop = 5;
        } else if (breakpoint.isPortraitTablet) {
            avatarSize = 44;
        }
        // } else if (isPortraitTabletBreakpoint) {
        //     avatarSize = 32;
        // }

        return (
            <Card style={{ marginTop: cardMarginTop }}>
                <Row>
                    <Col xl={10} lg={10} md={10} sm={6} xs={6}>
                        <Avatar
                            icon={icon}
                            size={avatarSize}
                            style={{
                                backgroundColor: iconBgColor
                            }}
                        />
                    </Col>
                    <Col xl={14} lg={14} md={14} sm={18} xs={18}>
                        <Statistic title={title} value={value} />
                    </Col>
                </Row>
            </Card>
        );
    };

    return (
        <>
            {isContentLoading ? (
                <ContentLoader />
            ) : (
                <div>
                    <Row gutter={16}>
                        <Col xl={16}>
                            <Row gutter={16}>
                                <Col xl={8} lg={8} md={8}>
                                    <AppCardStatistics
                                        title={
                                            <div>
                                                Registered
                                                <br />
                                                Patients
                                            </div>
                                        }
                                        value={
                                            dashboardData.statistics
                                                .totalPatients
                                        }
                                        icon="team"
                                        iconBgColor="#1890ff"
                                    />
                                </Col>
                                <Col xl={8} lg={8} md={8}>
                                    <AppCardStatistics
                                        title={
                                            <div>
                                                Today&rsquo;s
                                                <br />
                                                Cases
                                            </div>
                                        }
                                        value={
                                            dashboardData.statistics
                                                .totalCasesToday
                                        }
                                        icon="solution"
                                        iconBgColor="#0150ac"
                                    />
                                </Col>
                                <Col xl={8} lg={8} md={8}>
                                    <AppCardStatistics
                                        title={
                                            <div>
                                                Today&rsquo;s
                                                <br />
                                                Appointments
                                            </div>
                                        }
                                        value={
                                            dashboardData.statistics
                                                .totalAppointmentsToday
                                        }
                                        icon="calendar"
                                        iconBgColor="#b8d8ff"
                                    />
                                </Col>
                            </Row>
                            {breakpoint.isTabletLandscape && (
                                <Card style={{ marginTop: 16 }}>
                                    <Title level={4}>Clinic</Title>
                                    <p style={{ fontSize: "1rem" }}>
                                        <span role="img" aria-label="house">
                                            🏠
                                        </span>{" "}
                                        {clinicName}
                                    </p>
                                </Card>
                            )}
                            {subscription.plan !== "basic" && (
                                <Card
                                    title="Low on Stock Items"
                                    style={{
                                        marginTop: 16
                                    }}
                                >
                                    <Table
                                        columns={lowStocksItemColumns}
                                        dataSource={dashboardData.lowStockItems}
                                        rowKey="item_id"
                                        pagination={{
                                            pageSize: 5
                                        }}
                                    />
                                </Card>
                            )}
                            <Card
                                title={`Cases and Appointments of ${moment().format(
                                    "YYYY"
                                )}`}
                                style={{ marginTop: 16 }}
                            >
                                <div
                                    style={{
                                        maxWidth: 890,
                                        overflow: "auto"
                                    }}
                                >
                                    <ComposedChart
                                        width={890}
                                        height={300}
                                        data={
                                            dashboardData.casesAndAppointmentChartData
                                        }
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 0,
                                            left: 0
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {/* <Area type="monotone" dataKey="bills" fill="#b8d8ff" stroke="#b8d8ff" /> */}
                                        <Bar
                                            dataKey="cases"
                                            barSize={20}
                                            fill="#0150ac"
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="appointments"
                                            stroke="#b8d8ff"
                                            strokeWidth={3}
                                        />
                                        {/* <Scatter dataKey="cnt" fill="red" /> */}
                                    </ComposedChart>
                                </div>
                            </Card>
                            <Card
                                title={`Top Services of ${moment().format(
                                    "YYYY"
                                )}`}
                                style={{
                                    marginTop: 16,
                                    marginBottom: 20
                                }}
                            >
                                <div
                                    style={{
                                        maxWidth: 890,
                                        overflow: "auto"
                                    }}
                                >
                                    <ComposedChart
                                        width={890}
                                        height={300}
                                        data={
                                            dashboardData.topServicesChartData
                                        }
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 0,
                                            left: 0
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {/* <Area type="monotone" dataKey="bills" fill="#b8d8ff" stroke="#b8d8ff" /> */}
                                        <Line
                                            type="monotone"
                                            dataKey="services"
                                            stroke="#b8d8ff"
                                            strokeWidth={3}
                                        />
                                        {/* <Scatter dataKey="cnt" fill="red" /> */}
                                    </ComposedChart>
                                </div>
                            </Card>
                        </Col>
                        <Col xl={8}>
                            <div
                                style={{
                                    marginTop: breakpoint.isPortraitTablet
                                        ? 16
                                        : 0
                                }}
                            >
                                {!breakpoint.isTabletLandscape && (
                                    <Card style={{ marginBottom: 16 }}>
                                        <Title level={4}>Clinic</Title>
                                        <p style={{ fontSize: "1rem" }}>
                                            <span role="img" aria-label="house">
                                                🏠
                                            </span>{" "}
                                            {clinicName}
                                        </p>
                                    </Card>
                                )}
                                <Card style={{ marginBottom: 16 }}>
                                    <Title level={4}>Subscription</Title>
                                    <p>
                                        Your {subscription.description}{" "}
                                        subscription due date is on
                                        <br />{" "}
                                        {moment(subscription.dateDue).format(
                                            "LL"
                                        )}
                                    </p>
                                    <p>
                                        <Link to="/account">See your plan</Link>
                                    </p>
                                </Card>
                                <Card style={{ marginBottom: 16 }}>
                                    <Title level={4}>Storage</Title>
                                    <StorageChecker />
                                </Card>
                                {/* <Card style={{ marginBottom: 16 }}>
                                    <Title level={4}>Whats New?</Title>
                                    <p>
                                        &#10004; New User Interface
                                        <br />
                                        &#10004; More Responsive
                                        <br />
                                        &#10004; Scalable
                                    </p>
                                    <p>
                                        <Button
                                            type="link"
                                            onClick={
                                                toggleUpdatesContentVisible
                                            }
                                        >
                                            See updates
                                        </Button>
                                    </p>
                                </Card> */}
                                <Card style={{ marginBottom: 16 }}>
                                    <Title level={4}>App Version</Title>
                                    <p>
                                        <span role="img" aria-label="trophy">
                                            🏆
                                        </span>{" "}
                                        v{version}
                                    </p>
                                    <Link to="/changeLog">See Change Log</Link>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    <ModalDetails
                        visible={isUpdatesContentVisible}
                        width={320}
                        footer={null}
                        destroyOnClose={true}
                        style={{ top: 20 }}
                        onCancel={toggleUpdatesContentVisible}
                    >
                        No Updates
                    </ModalDetails>
                </div>
            )}
        </>
    );
};

const mapStateToProps = state => ({
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    clinicName: state.UserDetails.clinicDetails.name,
    subscription: state.UserDetails.subscriptionDetails
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
