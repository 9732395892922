import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Layout,
    Row,
    Col,
    Card,
    Form,
    Input,
    Checkbox,
    Button,
    message
} from "antd";

//import ClinicSelectionList from "./components/ClinicSelectionList";

import { checkIsUserLogin } from "helpers/viewsProtector";
import http from "services/httpService";
import { apiUrl, appBasePath, platform } from "config.json";
import logo from "assets/img/logo.png";

const Login = props => {
    const [formData, setFormData] = useState({
        username: "",
        password: ""
    });
    const [formErrors, setFormErrors] = useState({
        username: "",
        password: ""
    });
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [isShowClinicSelection, setIsShowClinicSelection] = useState(false);
    const [loginDetails, setLoginDetails] = useState(null);

    //first element focus refs
    const txtUsername = React.createRef();

    useEffect(() => {
        /*
         * back to dashboard if the user attempt to go to log-in page
         * even the user was currently logged-in
         */
        const isUserLogin = checkIsUserLogin();
        if (isUserLogin) {
            return props.history.push(`/dashboard`);
        }
    }, []);

    const handleInputChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleInputBlur = e => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { username, password } = formData;
        let errors = {};
        if (username.trim() === "") {
            errors.username = "Username is required";
        }
        if (password.trim() === "") {
            errors.password = "Password is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const showAuthenticationError = msg => {
        setFormErrors({
            ...formErrors,
            username: msg
        });
        setFormData({ ...formData, password: "" });
        setIsAuthenticating(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else {
            setIsAuthenticating(true);
            try {
                const response = await http.post(`${apiUrl}/signin`, formData);
                const { isSignInError, signInErrorMsg, loginDetails, token } =
                    response.data;

                if (isSignInError) {
                    showAuthenticationError(signInErrorMsg);
                } else if (
                    loginDetails.userType === "admin" ||
                    loginDetails.userType === "patient"
                ) {
                    showAuthenticationError("Incorrect Username or Password");
                } else {
                    const { hasClinic, isShowClinicSelection } = loginDetails;

                    if (hasClinic) {
                        if (isShowClinicSelection) {
                            setLoginDetails(loginDetails);
                            setIsShowClinicSelection(true);
                            setIsAuthenticating(false);
                        } else {
                            localStorage.setItem("token", token);

                            await http.post(
                                `${apiUrl}/activityLog`,
                                {
                                    system_activity_id: 1,
                                    data: null,
                                    user_id: loginDetails.userID
                                },
                                {
                                    headers: {
                                        qd: "qqq",
                                        conn: loginDetails.ownerID
                                    }
                                }
                            );

                            if (platform === "web") {
                                console.log(loginDetails);
                                window.location = `${appBasePath}/dashboard`;
                            } else {
                                window.location.reload();
                            }
                        }
                    } else {
                        message.error("This account has no clinic assigned");
                        setIsAuthenticating(false);
                    }
                }
            } catch (error) {
                message.error(error.message || "Something went wrong");
                setIsAuthenticating(false);
            }
        }
    };

    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                    height: "100vh"
                }}
            >
                <Col xl={8} md={6} xs={1} />
                <Col xl={8} md={12} xs={22}>
                    <Card
                        style={{
                            width: "100%"
                        }}
                    >
                        <div
                            style={{
                                display: "block",
                                textAlign: "center"
                            }}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                            <h2>
                                {isShowClinicSelection
                                    ? "Select Clinic"
                                    : "User Login"}
                            </h2>
                        </div>
                        <div
                            style={{
                                maxHeight: 320,
                                overflowY: "auto",
                                overflowX: "hidden"
                            }}
                        >
                            {isShowClinicSelection ? (
                                // <ClinicSelectionList
                                //     loginDetails={loginDetails}
                                // />
                                <div>
                                    Multi clinic selection currently not
                                    available
                                </div>
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Item
                                        label="Email/Username"
                                        hasFeedback
                                        validateStatus={
                                            formErrors.username && "error"
                                        }
                                        help={
                                            formErrors.username &&
                                            formErrors.username
                                        }
                                    >
                                        <Input
                                            name="username"
                                            ref={txtUsername}
                                            value={formData.username}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                        hasFeedback
                                        validateStatus={
                                            formErrors.password && "error"
                                        }
                                        help={
                                            formErrors.password &&
                                            formErrors.password
                                        }
                                    >
                                        <Input
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                        />
                                    </Form.Item>
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            <Checkbox>Remember me</Checkbox>
                                        </Col>
                                        <Col>
                                            <Link to="/forgotPassword">
                                                Forgot Password?
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Form.Item>
                                        <br />
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            loading={isAuthenticating}
                                        >
                                            {isAuthenticating
                                                ? "Signing In"
                                                : "Sign In"}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                textAlign: "center"
                            }}
                        >
                            Don&rsquo;t have an account?{" "}
                            <Link to="/register/free">Create Account</Link>
                        </div>
                    </Card>
                </Col>
                <Col xl={8} md={6} xs={1} />
            </Row>
        </Layout>
    );
};

export default Login;
