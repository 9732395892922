import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import moment from "moment"

const FormItemBirthday = ({ data, error, breakpoint, onInputChange }) => {
    const { name, label, value, required } = data;
    const [formData, setFormData] = useState({
        month: "",
        day: "",
        year: "",
    });

    const [formErrors, setFormErrors] = useState({
        [name]: error,
    });

    const { Option } = Select;

    
    const years = [];
    const latestYear = moment().year();
    for(let ii=latestYear; ii>=1920; ii--){
        years.push(ii)
    }
    

    useEffect(() => {
        if (value) {
            const [year, month, day] = value.split("-");
            setFormData({
                month,
                day,
                year,
            });
        } else {
            setFormData({
                month: "",
                day: "",
                year: "",
            });
        }
    }, [value]);

    useEffect(() => {
        setFormErrors({ [name]: error });
    }, [error]);

    const handleSelectChange = (sname, value) => {
        setFormData({ ...formData, [sname]: value });
        const { year, month, day } = { ...formData, [sname]: value };
        onInputChange(name, `${year}-${month}-${day}`);
        setFormErrors({
            [name]: "",
        });
    };

    return (
        <Form.Item
            label={label}
            required={required}
            hasFeedback
            validateStatus={formErrors[name] && "error"}
            help={formErrors[name] && formErrors[name]}
        >
            <Select
                placeholder="Month"
                value={formData.month}
                style={{
                    width: breakpoint.isTabletLandscape ? 102 : 110,
                    marginRight: 3,
                }}
                onChange={(value) => handleSelectChange("month", value)}
            >
                <Option value="01">January</Option>
                <Option value="02">February</Option>
                <Option value="03">March</Option>
                <Option value="04">April</Option>
                <Option value="05">May</Option>
                <Option value="06">June</Option>
                <Option value="07">July</Option>
                <Option value="08">August</Option>
                <Option value="09">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
            </Select>

            <Select
                placeholder="Day"
                value={formData.day}
                showSearch
                style={{
                    width: breakpoint.isTabletLandscape ? 57 : 70,
                    marginRight: 3,
                }}
                onChange={(value) => handleSelectChange("day", value)}
            >
                <Option value="01">1</Option>
                <Option value="02">2</Option>
                <Option value="03">3</Option>
                <Option value="04">4</Option>
                <Option value="05">5</Option>
                <Option value="06">6</Option>
                <Option value="07">7</Option>
                <Option value="08">8</Option>
                <Option value="09">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
                <Option value="13">13</Option>
                <Option value="14">14</Option>
                <Option value="15">15</Option>
                <Option value="16">16</Option>
                <Option value="17">17</Option>
                <Option value="18">18</Option>
                <Option value="19">19</Option>
                <Option value="20">20</Option>
                <Option value="21">21</Option>
                <Option value="22">22</Option>
                <Option value="23">23</Option>
                <Option value="24">24</Option>
                <Option value="25">25</Option>
                <Option value="26">26</Option>
                <Option value="27">27</Option>
                <Option value="28">28</Option>
                <Option value="29">29</Option>
                <Option value="30">30</Option>
                <Option value="31">31</Option>
            </Select>

            <Select
                placeholder="Year"
                value={formData.year}
                showSearch
                style={{ width: breakpoint.isTabletLandscape ? 73 : 90 }}
                onChange={(value) => handleSelectChange("year", value)}
            >
                {years.map(year => <Option key={year} value={year}>{year}</Option>)}
                {/* <Option value="2021">2021</Option>
                <Option value="2020">2020</Option>
                <Option value="2019">2019</Option>
                <Option value="2018">2018</Option>
                <Option value="2017">2017</Option>
                <Option value="2016">2016</Option>
                <Option value="2015">2015</Option>
                <Option value="2014">2014</Option>
                <Option value="2013">2013</Option>
                <Option value="2012">2012</Option>
                <Option value="2011">2011</Option>
                <Option value="2010">2010</Option>
                <Option value="2009">2009</Option>
                <Option value="2008">2008</Option>
                <Option value="2007">2007</Option>
                <Option value="2006">2006</Option>
                <Option value="2005">2005</Option>
                <Option value="2004">2004</Option>
                <Option value="2003">2003</Option>
                <Option value="2002">2002</Option>
                <Option value="2001">2001</Option>
                <Option value="2000">2000</Option>
                <Option value="1999">1999</Option>
                <Option value="1998">1998</Option>
                <Option value="1997">1997</Option>
                <Option value="1996">1996</Option>
                <Option value="1995">1995</Option>
                <Option value="1994">1994</Option>
                <Option value="1993">1993</Option>
                <Option value="1992">1992</Option>
                <Option value="1991">1991</Option>
                <Option value="1990">1990</Option>
                <Option value="1989">1989</Option>
                <Option value="1988">1988</Option>
                <Option value="1987">1987</Option>
                <Option value="1986">1986</Option>
                <Option value="1985">1985</Option>
                <Option value="1984">1984</Option>
                <Option value="1983">1983</Option>
                <Option value="1982">1982</Option>
                <Option value="1981">1981</Option>
                <Option value="1980">1980</Option>
                <Option value="1979">1979</Option>
                <Option value="1978">1978</Option>
                <Option value="1977">1977</Option>
                <Option value="1976">1976</Option>
                <Option value="1975">1975</Option>
                <Option value="1974">1974</Option>
                <Option value="1973">1973</Option>
                <Option value="1972">1972</Option>
                <Option value="1971">1971</Option>
                <Option value="1970">1970</Option>
                <Option value="1969">1969</Option>
                <Option value="1968">1968</Option>
                <Option value="1967">1967</Option>
                <Option value="1966">1966</Option>
                <Option value="1965">1965</Option>
                <Option value="1964">1964</Option>
                <Option value="1963">1963</Option>
                <Option value="1962">1962</Option>
                <Option value="1961">1961</Option>
                <Option value="1960">1960</Option>
                <Option value="1959">1959</Option>
                <Option value="1958">1958</Option>
                <Option value="1957">1957</Option>
                <Option value="1956">1956</Option>
                <Option value="1955">1955</Option>
                <Option value="1954">1954</Option>
                <Option value="1953">1953</Option>
                <Option value="1952">1952</Option>
                <Option value="1951">1951</Option>
                <Option value="1950">1950</Option>
                <Option value="1949">1949</Option>
                <Option value="1948">1948</Option>
                <Option value="1947">1947</Option>
                <Option value="1946">1946</Option>
                <Option value="1945">1945</Option>
                <Option value="1944">1944</Option>
                <Option value="1943">1943</Option>
                <Option value="1942">1942</Option>
                <Option value="1941">1941</Option>
                <Option value="1940">1940</Option>
                <Option value="1939">1939</Option>
                <Option value="1938">1938</Option>
                <Option value="1937">1937</Option>
                <Option value="1936">1936</Option>
                <Option value="1935">1935</Option>
                <Option value="1934">1934</Option>
                <Option value="1933">1933</Option>
                <Option value="1932">1932</Option>
                <Option value="1931">1931</Option>
                <Option value="1930">1930</Option>
                <Option value="1929">1929</Option>
                <Option value="1928">1928</Option>
                <Option value="1927">1927</Option>
                <Option value="1926">1926</Option>
                <Option value="1925">1925</Option>
                <Option value="1924">1924</Option>
                <Option value="1923">1923</Option>
                <Option value="1922">1922</Option>
                <Option value="1921">1921</Option>
                <Option value="1920">1920</Option> */}
            </Select>
        </Form.Item>
    );
};

export default FormItemBirthday;
