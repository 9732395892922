import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Tooltip, Tag, Button, Row, Col } from "antd";
import Drawer from "globalComponents/Drawer";
import VitalsForm from "./components/Forms/VitalsForm";

import { batchInsertQueryFormatter } from "helpers/batchInsertQueryFormatter";
import http from "services/httpService";
import { apiUrl } from "config.json";

const VitalSigns = ({
    caseDetails,
    readOnly: isCaseReadOnly,
    breakpoint,
    onChange,
    VSForm,
    setRequestError
}) => {
    const [vitalSignsForm, setVitalSignsForm] = useState(VSForm);
    const [vitalSigns, setVitalSigns] = useState([]); //this will be the display vital signs

    const [isPatientVitalsFormVisible, setIsPatientVitalsFormVisible] =
        useState(false);

    useEffect(() => {
        const vitalSigns = caseDetails.vitalSigns;

        //supply values to vital signs form
        const newVitalSignsForm = vitalSignsForm.map(formInput => {
            const vs = vitalSigns.find(
                vitalSign => vitalSign.vital_sign_id === formInput.name
            );
            return { ...formInput, value: vs ? vs.value : "" };
        });

        //sort display case vitals based on vitals form order on settings
        const newVitalSigns = [];
        vitalSignsForm.forEach(formInput => {
            const vitalSign = vitalSigns.find(
                vitalSign => vitalSign.vital_sign_id === formInput.name
            );
            if (vitalSign) {
                newVitalSigns.push(vitalSign);
            }
        });

        setVitalSignsForm(newVitalSignsForm);
        setVitalSigns(newVitalSigns);
    }, [caseDetails]);

    const togglePatientVitalsForm = () => {
        setIsPatientVitalsFormVisible(!isPatientVitalsFormVisible);
    };

    const showPatientVitalsForm = () => {
        togglePatientVitalsForm();
    };

    const handleVitalSignsFormSubmit = async vitalSignsForm => {
        //re-create display vital signs based on submitted form data
        const newVitalSigns = vitalSignsForm
            .filter(formInput => formInput.value !== "")
            .map(formInput => ({
                vital_sign_id: formInput.name,
                name: formInput.label,
                value: formInput.value,
                measurement: formInput.placeholder
            }));

        //ready for save to db
        const caseVitalSigns = newVitalSigns.map(formInput => ({
            vital_sign_id: formInput.vital_sign_id,
            value: formInput.value
        }));

        let newCaseVitalSigns = [];

        if (caseVitalSigns.length !== 0) {
            newCaseVitalSigns = batchInsertQueryFormatter(caseVitalSigns);
        }

        setVitalSignsForm(vitalSignsForm);
        setVitalSigns(newVitalSigns);
        togglePatientVitalsForm();
        onChange("vitalSigns", newVitalSigns);

        try {
            await http.post(`${apiUrl}/caseVitalSigns`, {
                caseID: caseDetails.case_id,
                patientID: caseDetails.patient_id,
                newCaseVitalSigns
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on your last operation :(",
                errorSubMessage: error.message
            });
        }
    };

    return (
        <>
            <Row type="flex" style={{ marginBottom: 10 }}>
                <Col>
                    <h4 className="ant-list-item-meta-title">
                        Vital Signs:&nbsp;
                    </h4>
                </Col>
                <Col>
                    {vitalSigns.map(vitalSign => {
                        let vitalSignIcon = null;
                        switch (vitalSign.name) {
                            case "Blood Pressure":
                                vitalSignIcon = (
                                    <span
                                        role="img"
                                        aria-label="blood pressure"
                                    >
                                        🅱️
                                    </span>
                                );
                                break;
                            case "Pulse Rate":
                                vitalSignIcon = (
                                    <span role="img" aria-label="heart rate">
                                        💝
                                    </span>
                                );
                                break;
                            case "Height":
                                vitalSignIcon = (
                                    <span role="img" aria-label="height">
                                        📏
                                    </span>
                                );
                                break;
                            case "Weight":
                                vitalSignIcon = (
                                    <span role="img" aria-label="weight">
                                        ⏲️
                                    </span>
                                );
                                break;
                            case "Temperature":
                                vitalSignIcon = (
                                    <span role="img" aria-label="weight">
                                        🌡️
                                    </span>
                                );
                                break;
                            case "Respiratory Rate":
                                vitalSignIcon = (
                                    <span role="img" aria-label="weight">
                                        💙
                                    </span>
                                );
                                break;
                            default:
                                vitalSignIcon = null;
                        }

                        return (
                            <Tooltip
                                title={vitalSign.name}
                                key={vitalSign.vital_sign_id}
                            >
                                <Tag>
                                    {vitalSignIcon && vitalSignIcon}{" "}
                                    {`${vitalSign.value}${vitalSign.measurement}`}
                                </Tag>
                            </Tooltip>
                        );
                    })}
                    {!isCaseReadOnly && (
                        <Tooltip title="Edit Vitals">
                            <Button
                                size="small"
                                type="link"
                                icon="edit"
                                onClick={showPatientVitalsForm}
                            />
                        </Tooltip>
                    )}
                </Col>
            </Row>

            <Drawer
                title="Edit Patient Vitals"
                visible={isPatientVitalsFormVisible}
                width={breakpoint.isNormalMobile ? 320 : 400}
                onClose={togglePatientVitalsForm}
                destroyOnClose={true}
            >
                <VitalsForm
                    form={vitalSignsForm}
                    onSubmit={handleVitalSignsFormSubmit}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = state => ({
    VSForm: state.Settings.vitalSignsForm
});

const mapDispatchToProps = dispatch => ({
    setRequestError: data => dispatch(setRequestError(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(VitalSigns);
