import React, { useState, useEffect } from "react";
import { Prompt, Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateSettings } from "redux/settings/settings.actions";
import { setRequestError } from "redux/system/system.actions";
import {
    Icon,
    Button,
    Divider,
    message,
    Switch,
    Card,
    Typography,
    Select,
    Tag,
} from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const CaseList = ({ CList, clinicID, updateSettings, setRequestError }) => {
    const [caseList, setCaseList] = useState(CList);
    const [isCListEdited, setIsCListEdited] = useState(false);

    const { Title } = Typography;
    const { Option } = Select;

    useEffect(() => {
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [isCListEdited]);

    function beforeunload(e) {
        if (isCListEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    const handleSwitchChange = (name, checked) => {
        setCaseList({
            ...caseList,
            [name]: checked ? true : false,
        });
        setIsCListEdited(true);
    };

    const handleSelectChange = (name, value) => {
        setCaseList({
            ...caseList,
            [name]: value,
        });
        setIsCListEdited(true);
    };

    const handleCaseListSave = async () => {
        try {
            updateSettings({ caseList });
            setIsCListEdited(false);
            message.success("Case list settings successfully saved!");
            const CList = JSON.stringify(caseList);
            await http.put(`${apiUrl}/settings/${clinicID}`, {
                case_list: CList,
            });
        } catch (error) {
            setRequestError({
                errorMessage: "Something went wrong on our last :(",
                errorSubMessage: error.message,
            });
        }
    };

    return (
        <Card>
            <div style={{ marginBottom: 10 }}>
                <Link to="/settings">
                    <Icon type="arrow-left" /> Back to Settings Menu
                </Link>
            </div>

            <Divider />
            <Title level={4}>Case Information</Title>
            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 200 }}>Show Patient Image:</td>
                        <td>
                            <Switch
                                checked={caseList.isShowImage}
                                onChange={(checked) =>
                                    handleSwitchChange("isShowImage", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Show Chief Complaint:</td>
                        <td>
                            <Switch
                                checked={caseList.isShowChiefComplaint}
                                onChange={(checked) =>
                                    handleSwitchChange(
                                        "isShowChiefComplaint",
                                        checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Show Assigned Doctor:</td>
                        <td>
                            <Switch
                                checked={caseList.isShowDoctor}
                                onChange={(checked) =>
                                    handleSwitchChange("isShowDoctor", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Show Case Tags:</td>
                        <td>
                            <Switch
                                disabled
                                checked={caseList.isShowCaseTag}
                                onChange={(checked) =>
                                    handleSwitchChange("isShowCaseTag", checked)
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Show Case Star:</td>
                        <td>
                            <Switch
                                disabled
                                checked={caseList.isShowCaseStar}
                                onChange={(checked) =>
                                    handleSwitchChange(
                                        "isShowCaseStar",
                                        checked
                                    )
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />
            <Title level={4}>Status Color</Title>
            <table cellPadding={5}>
                <tbody>
                    <tr>
                        <td style={{ width: 200 }}>Pending:</td>
                        <td>
                            <Select
                                value={caseList.pendingStatusColor}
                                style={{ width: 150 }}
                                onChange={(value) =>
                                    handleSelectChange(
                                        "pendingStatusColor",
                                        value
                                    )
                                }
                            >
                                <Option value="magenta">
                                    <Tag color="magenta">Magenta</Tag>
                                </Option>
                                <Option value="red">
                                    <Tag color="red">Red</Tag>
                                </Option>
                                <Option value="volcano">
                                    <Tag color="volcano">Volcano</Tag>
                                </Option>
                                <Option value="orange">
                                    <Tag color="orange">Orange</Tag>
                                </Option>
                                <Option value="gold">
                                    <Tag color="gold">Gold</Tag>
                                </Option>
                                <Option value="lime">
                                    <Tag color="lime">Lime</Tag>
                                </Option>
                                <Option value="green">
                                    <Tag color="green">Green</Tag>
                                </Option>
                                <Option value="cyan">
                                    <Tag color="cyan">Cyan</Tag>
                                </Option>
                                <Option value="blue">
                                    <Tag color="blue">Blue</Tag>
                                </Option>
                                <Option value="geekblue">
                                    <Tag color="geekblue">Geek Blue</Tag>
                                </Option>
                                <Option value="purple">
                                    <Tag color="purple">Purple</Tag>
                                </Option>
                                <Option value="">
                                    <Tag>Grey</Tag>
                                </Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Hold:</td>
                        <td>
                            <Select
                                value={caseList.holdStatusColor}
                                style={{ width: 150 }}
                                onChange={(value) =>
                                    handleSelectChange("holdStatusColor", value)
                                }
                            >
                                <Option value="magenta">
                                    <Tag color="magenta">Magenta</Tag>
                                </Option>
                                <Option value="red">
                                    <Tag color="red">Red</Tag>
                                </Option>
                                <Option value="volcano">
                                    <Tag color="volcano">Volcano</Tag>
                                </Option>
                                <Option value="orange">
                                    <Tag color="orange">Orange</Tag>
                                </Option>
                                <Option value="gold">
                                    <Tag color="gold">Gold</Tag>
                                </Option>
                                <Option value="lime">
                                    <Tag color="lime">Lime</Tag>
                                </Option>
                                <Option value="green">
                                    <Tag color="green">Green</Tag>
                                </Option>
                                <Option value="cyan">
                                    <Tag color="cyan">Cyan</Tag>
                                </Option>
                                <Option value="blue">
                                    <Tag color="blue">Blue</Tag>
                                </Option>
                                <Option value="geekblue">
                                    <Tag color="geekblue">Geek Blue</Tag>
                                </Option>
                                <Option value="purple">
                                    <Tag color="purple">Purple</Tag>
                                </Option>
                                <Option value="">
                                    <Tag>Grey</Tag>
                                </Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No Show:</td>
                        <td>
                            <Select
                                value={caseList.noShowStatusColor}
                                style={{ width: 150 }}
                                onChange={(value) =>
                                    handleSelectChange(
                                        "noShowStatusColor",
                                        value
                                    )
                                }
                            >
                                <Option value="magenta">
                                    <Tag color="magenta">Magenta</Tag>
                                </Option>
                                <Option value="red">
                                    <Tag color="red">Red</Tag>
                                </Option>
                                <Option value="volcano">
                                    <Tag color="volcano">Volcano</Tag>
                                </Option>
                                <Option value="orange">
                                    <Tag color="orange">Orange</Tag>
                                </Option>
                                <Option value="gold">
                                    <Tag color="gold">Gold</Tag>
                                </Option>
                                <Option value="lime">
                                    <Tag color="lime">Lime</Tag>
                                </Option>
                                <Option value="green">
                                    <Tag color="green">Green</Tag>
                                </Option>
                                <Option value="cyan">
                                    <Tag color="cyan">Cyan</Tag>
                                </Option>
                                <Option value="blue">
                                    <Tag color="blue">Blue</Tag>
                                </Option>
                                <Option value="geekblue">
                                    <Tag color="geekblue">Geek Blue</Tag>
                                </Option>
                                <Option value="purple">
                                    <Tag color="purple">Purple</Tag>
                                </Option>
                                <Option value="">
                                    <Tag>Grey</Tag>
                                </Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Done:</td>
                        <td>
                            <Select
                                value={caseList.doneStatusColor}
                                style={{ width: 150 }}
                                onChange={(value) =>
                                    handleSelectChange("doneStatusColor", value)
                                }
                            >
                                <Option value="magenta">
                                    <Tag color="magenta">Magenta</Tag>
                                </Option>
                                <Option value="red">
                                    <Tag color="red">Red</Tag>
                                </Option>
                                <Option value="volcano">
                                    <Tag color="volcano">Volcano</Tag>
                                </Option>
                                <Option value="orange">
                                    <Tag color="orange">Orange</Tag>
                                </Option>
                                <Option value="gold">
                                    <Tag color="gold">Gold</Tag>
                                </Option>
                                <Option value="lime">
                                    <Tag color="lime">Lime</Tag>
                                </Option>
                                <Option value="green">
                                    <Tag color="green">Green</Tag>
                                </Option>
                                <Option value="cyan">
                                    <Tag color="cyan">Cyan</Tag>
                                </Option>
                                <Option value="blue">
                                    <Tag color="blue">Blue</Tag>
                                </Option>
                                <Option value="geekblue">
                                    <Tag color="geekblue">Geek Blue</Tag>
                                </Option>
                                <Option value="purple">
                                    <Tag color="purple">Purple</Tag>
                                </Option>
                                <Option value="">
                                    <Tag>Grey</Tag>
                                </Option>
                            </Select>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Divider />

            <Button type="primary" size="large" onClick={handleCaseListSave}>
                Save Case List Settings
            </Button>

            <Prompt
                when={isCListEdited}
                message="Changes you made may not be saved. Are you sure you want to leave?"
            />
        </Card>
    );
};

const mapStateToProps = (state) => ({
    CList: state.Settings.caseList,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateSettings: (updatedSettings) =>
        dispatch(updateSettings(updatedSettings)),
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseList);
