import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Button, Input, Form, Upload, Icon, message } from "antd";

import http from "services/httpService";
import { apiUrl, cwaUploadRootDir } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const AddPhotoForm = ({
    onSubmit,
    userID,
    ownerID,
    clinicID,
    uploadServer,
    uploadEndpoint,
}) => {
    const [formData, setFormData] = useState({
        name: "",
        filename: "",
        url: "",
        img_size: 0,
        user_id: userID,
        clinic_id: clinicID,
    });

    const [formErrors, setFormErrors] = useState({
        name: "",
        url: "",
    });

    const [photoFile, setPhotoFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputBlur = (e) => {
        let errors = { ...formErrors };
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        setFormErrors(errors);
    };

    const validateInput = () => {
        const { name } = formData;
        let errors = {};

        if (name.trim() === "") {
            errors.name = "Name is required";
        }

        return Object.keys(errors).length === 0 ? null : errors;
    };

    const handleSubmit = async (e) => {
        const errors = validateInput();
        if (errors) {
            setFormErrors(errors);
        } else if (!photoFile) {
            message.error("Please select a photo file");
        } else {
            try {
                setIsUploading(true);

                const fd = new FormData();
                fd.append("uniFile", photoFile);

                const response = await http.post(
                    `${apiUrl}/queueingImage`,
                    fd,
                    {
                        params: {
                            space_server: uploadServer,
                            space_bucket_name: uploadEndpoint,
                            root_dir: cwaUploadRootDir,
                            owner_id: ownerID,
                            clinic_id: clinicID,
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                if (response.data.error) {
                    message.error("Upload failed please try again.");
                    setIsUploading(true);
                } else {
                    const { newFilename } = response.data;
                    const fileExt = photoFile.name.split(".").slice(-1);
                    setIsUploading(false);
                    onSubmit({
                        ...formData,
                        filename: newFilename,
                        url: `https://${uploadServer}/${uploadEndpoint}/${cwaUploadRootDir}/system/${ownerID}/clinics/${clinicID}/images/queueing/${newFilename}.${fileExt}`,
                        img_size: photoFile.size,
                        is_selected: 0,
                    });
                }
            } catch (error) {
                if (!error.message.responseCancelled) {
                    setRequestError({
                        errorMessage:
                            "Something went wrong on your last operation :(",
                        errorSubMessage: error.message,
                    });
                }
            }
        }
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            const isValidFile =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isValidFile) {
                message.error(
                    "You can only upload photo in JPEG and PNG format!"
                );
            }
            const isValidSize = file.size / 1024 / 1024 < 3;
            if (!isValidSize) {
                message.error("File must smaller than 3MB!");
            }
            if (isValidFile && isValidSize) {
                setPhotoFile(file);
                return false;
            } else {
                return false;
            }
        },
    };

    return (
        <Form>
            <Form.Item
                label="Enter photo name"
                required={true}
                hasFeedback={true}
                validateStatus={formErrors.name && "error"}
                help={formErrors.name && formErrors.name}
            >
                <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </Form.Item>
            <Upload {...uploadProps}>
                <Button>
                    <Icon type="upload" /> Upload
                </Button>
            </Upload>
            <div>{photoFile && photoFile.name}</div>
            <Form.Item>
                <Button
                    type="primary"
                    loading={isUploading}
                    style={{ marginTop: 10 }}
                    onClick={handleSubmit}
                >
                    {isUploading ? "Uploading" : "OK"}
                </Button>
            </Form.Item>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    userID: state.UserDetails.user_id,
    ownerID: state.UserDetails.owner_id,
    clinicID: state.UserDetails.clinicDetails.clinic_id,
    uploadServer: state.UserDetails.uploadDetails.server,
    uploadEndpoint: state.UserDetails.uploadDetails.endpoint,
});

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPhotoForm);
